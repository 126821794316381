export const defaultTitle = 'Carrot - Save, Shop, Deal Hop'
export const description =
  'Shop smarter & save up to 90% with Carrot AI! Also access a visual feed of all your shopping and buy without FOMO.'

export default {
  defaultTitle,
  titleTemplate: '%s | Carrot',
  description,
  openGraph: {
    title: defaultTitle,
    description: description,
    type: 'website',
    site_name: 'Carrot',
    images: [
      {
        url: 'https://ik.imagekit.io/carrot/og_images/invie_og_K_mWc3aFc.png?updatedAt=1691610185589',
      },
    ],
  },
  twitter: {
    handle: '@carrotdotlink',
    site: '@carrotdotlink',
    cardType: 'summary_large_image',
  },
  facebook: {
    appId: process.env.FACEBOOK_APP_ID,
  },
}
