import {
  browserLocalPersistence,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'
import firebaseApp from '@carrotcart/client-common/lib/firebase/firebaseApp'

/*
 * We have to check the context of the import as the Safari Extension
 * requires us to manually initialize Auth without the popup/redirect resolver
 * https://github.com/firebase/firebase-js-sdk/issues/5548#issuecomment-931579871
 */

const auth =
  process.env.SAFARI_EXTENSION === 'true'
    ? initializeAuth(firebaseApp, {
        persistence: [indexedDBLocalPersistence, browserLocalPersistence],
      })
    : getAuth(firebaseApp)

export default auth
