const base64Encode = (signature: ArrayBuffer): string => {
  let binary = ''
  const bytes = new Uint8Array(signature)
  for (let i = 0; i < bytes.byteLength; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

export default base64Encode
