export enum Collection_Invite_Status_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
  Viewed = 'viewed',
}
export enum Collection_State_Enum {
  /** Draft */
  Draft = 'draft',
  /** Published */
  Published = 'published',
}
export enum Collection_Type_Enum {
  /** Contest collection type (ie, I-to-I) */
  Contest = 'contest',
  /** Default collection type */
  Default = 'default',
  /** Landing Page */
  LandingPage = 'landing_page',
  /** Shared collection type */
  Shared = 'shared',
}
export enum Commentable_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
}
export enum Contact_Info_Type_Enum {
  Email = 'email',
  Phone = 'phone',
}
export enum Feed_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
  CollectionCartItem = 'collection_cart_item',
  FeaturedShop = 'featured_shop',
}
export enum Link_Type_Enum {
  /** Instagram */
  Instagram = 'instagram',
  /** Other */
  Other = 'other',
  /** TikTok */
  Tiktok = 'tiktok',
  /** Twitter */
  Twitter = 'twitter',
  /** YouTube */
  Youtube = 'youtube',
}
export enum Media_Type_Enum {
  /** Instagram Media */
  Instagram = 'instagram',
  /** TikTok Video */
  Tiktok = 'tiktok',
  /** Twitch Video */
  Twitch = 'twitch',
  /** YouTube Video */
  Youtube = 'youtube',
  /** YouTube Short media type */
  YoutubeShort = 'youtube_short',
}
export enum Notification_Center_Item_Type_Enum {
  Communication = 'communication',
  Informational = 'informational',
  Task = 'task',
}
export enum Shop_Category_Enum {
  /** Arts & Crafts */
  ArtsCrafts = 'arts_crafts',
  /** Baby & Kids */
  BabyKids = 'baby_kids',
  /** Beauty & Cosmetics */
  BeautyCosmetics = 'beauty_cosmetics',
  /** Books */
  Books = 'books',
  /** DIY */
  Dyi = 'dyi',
  /** Electronics & Media */
  ElectronicsMedia = 'electronics_media',
  /** Eyewear */
  Eyewear = 'eyewear',
  /** Fashion & Apparel */
  FashionApparel = 'fashion_apparel',
  /** Food & Beverage */
  FoodBeverage = 'food_beverage',
  /** Footwear & Shoes */
  FootwearShoes = 'footwear_shoes',
  /** Gaming & Entertainment */
  GamingEntertainment = 'gaming_entertainment',
  /** Haute Couture */
  HauteCouture = 'haute_couture',
  /** Health, Fitness & Nutrition */
  HealthFitnessNutrition = 'health_fitness_nutrition',
  /** Home, Kitchen & Decor */
  HomeKitchenDecor = 'home_kitchen_decor',
  /** Intimates */
  Intimates = 'intimates',
  /** Jewelry */
  Jewelry = 'jewelry',
  /** Office */
  Office = 'office',
  /** Omnishop */
  Omnishop = 'omnishop',
  /** Outdoor & Adventure */
  OutdoorAdventure = 'outdoor_adventure',
  /** Pets & Animals */
  PetsAnimals = 'pets_animals',
  /** Plants & Flowers */
  PlantsFlowers = 'plants_flowers',
  /** Sleep */
  Sleep = 'sleep',
  /** Social Platform */
  SocialPlatform = 'social_platform',
  /** Sports & Athletics */
  SportsAthletics = 'sports_athletics',
  /** Tobacco and Related Products */
  TobaccoRelatedProducts = 'tobacco_related_products',
  /** Travel */
  Travel = 'travel',
  /** Well-being */
  Wellbeing = 'wellbeing',
}
export enum User_Account_Type_Enum {
  /** Creator account type */
  Creator = 'creator',
  /** Curator user type */
  Curator = 'curator',
  /** Regular user account */
  Regular = 'regular',
}
export enum User_App_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  VersionPkey = 'version_pkey',
  /** unique or primary key constraint on columns "user_id", "build_number", "platform", "version", "name" */
  VersionUserIdNameVersionBuildNumberPlatformKey = 'version_user_id_name_version_build_number_platform_key',
}
export enum User_App_Version_Update_Column {
  /** column name */
  BuildNumber = 'build_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Version = 'version',
}
export enum User_Feedback_Type_Enum {
  SimilarProducts = 'similar_products',
}
export enum User_Shopping_Preferences_Enum {
  /** Everything */
  Everything = 'everything',
  /** Men's */
  Mens = 'mens',
  /** Women's */
  Womens = 'womens',
}
export enum Workflow_Status_Enum {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded',
}
export enum Rewardable_Action_Names_Enum {
  BrandSelection = 'brand_selection',
  CategorySelection = 'category_selection',
  CompleteProfile = 'complete_profile',
  EnableDesktopExtension = 'enable_desktop_extension',
  EnablePushNotifications = 'enable_push_notifications',
  EnableSafariExtension = 'enable_safari_extension',
  /** 100 */
  OnboardingPromo = 'onboarding_promo',
  ReferFriend = 'refer_friend',
  /** 5 */
  RevealDeal = 'reveal_deal',
  Transaction = 'transaction',
  UpgradePro = 'upgrade_pro',
}
export enum Rewardable_Item_Type_Enum {
  AffiliateTransaction = 'affiliate_transaction',
  CartItem = 'cart_item',
  CollectionCartItem = 'collection_cart_item',
  User = 'user',
}
