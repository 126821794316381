import pino from 'pino'
import merge from 'lodash/merge'
import { formatError } from '@elastic/ecs-helpers/lib/error-formatters'
import baseFields from './baseFields'

const createLogEventPayload = ({
  messages,
  bindings,
  level,
  ts,
}: pino.LogEvent): Record<string, any> => {
  const message = messages[0]
  let log = bindings.reduce<Record<string, any>>(
    (memo, binding) => merge(memo, binding),
    {
      ...baseFields,
      'log.level': level.label,
      '@timestamp': new Date(ts).toISOString(),
    }
  )

  if (typeof message === 'string') log = { ...log, message }
  if (typeof message === 'object') log = merge(log, message)

  const { err, ...ecsObj } = log

  if (err !== undefined) {
    formatError(ecsObj, err)
  }

  return ecsObj
}

export default createLogEventPayload
