import * as Sentry from '@sentry/browser'
import noop from 'lodash/noop'
import type { User as AuthUser, IdTokenResult } from 'firebase/auth'
import EventEmitter from 'events'
import { hasHasuraClaim } from '@carrotcart/common/lib/hasuraClaimHelpers'
import { createCustomToken } from '@carrotcart/client-common/lib/firebase/createCustomToken'

const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000
let eventEmitter: EventEmitter | undefined

const shouldForceRefresh = (expirationTime: string): boolean => {
  return (
    Date.now() + ONE_MINUTE_IN_MILLISECONDS > new Date(expirationTime).valueOf()
  )
}

const getUserToken = async (
  authUser: AuthUser | undefined | null,
  forceRefresh = false
): Promise<IdTokenResult | undefined> => {
  if (!authUser) {
    return undefined
  }

  try {
    const idTokenResult = await authUser.getIdTokenResult(forceRefresh)

    if (hasHasuraClaim(idTokenResult)) {
      if (eventEmitter && eventEmitter.listenerCount('message') === 0) {
        eventEmitter = undefined
      }

      if (!forceRefresh && shouldForceRefresh(idTokenResult.expirationTime)) {
        return await authUser.getIdTokenResult(true)
      }

      return idTokenResult
    }

    if (eventEmitter) {
      let listener: (...args: any[]) => void = noop
      const tokenResult = await new Promise<IdTokenResult>((resolve) => {
        listener = (value: IdTokenResult) => {
          resolve(value)
        }
        eventEmitter?.addListener('message', listener)
      })
      // Remove the listener
      eventEmitter?.removeListener('message', listener)
      return tokenResult
    }

    eventEmitter = new EventEmitter()
    await createCustomToken({
      uid: authUser.uid,
      accessToken: idTokenResult.token,
    })
    const tokenResult = await authUser.getIdTokenResult(true)
    eventEmitter.emit('message', tokenResult)
    return tokenResult
  } catch (err) {
    Sentry.captureException(err)
    return undefined
  }
}

export default getUserToken
