import type { RedirectObjectType } from '@carrotcart/common/types'
import type {
  CartItemDataFragment,
  ItemDataFragment,
  ShopDataFragment,
} from '@carrotcart/data/generated'
import slugifyName from '@carrotcart/common/lib/slugifyName'
import { API_ENDPOINT } from '@carrotcart/common/lib/constants'

export interface TrendingSearch {
  label: string
  query: string
  image?: string
  order?: number
}

export const createPublicUrl = (
  path: string,
  {
    excludeProtocol,
    publicUrlOverride,
    local,
  }: {
    excludeProtocol?: boolean
    publicUrlOverride?: string
    local?: boolean
  } = {}
): string => {
  const url = `${(local
    ? ''
    : publicUrlOverride ||
      process.env.PUBLIC_URL ||
      process.env.APP_PUBLIC_URL ||
      ''
  ).replace(/\/$/, '')}/${path.replace(/^\//, '')}`

  // exclude protocol if requested
  if (url && excludeProtocol) {
    return url.replace(/^https?:\/\//, '')
  }

  return url
}

// is a valid image set
// Should also update in the data docs in the postgres trigger function
// LINK ../../data/docs/custom-sql-trigger-functions.md:1252
export const IMAGES_TO_OVERRIDE = new Set<string>([
  'https://www.sephora.com/images/Sephora_logo.jpg',
  'https://media.ulta.com/i/ulta/',
  'https://assets.weimgs.com/weimgs/ab/images/dp/ecm/202141/1826/001/062.png',
  'https://assets.weimgs.com/weimgs/ab/images/dp/ecm/202247/0922/001/023.png',
  'https://www.gap.com/Asset_Archive/GPWeb/content/DPG/3-F3/gapkids_horizontal.png',
  'https://www.gap.com/Asset_Archive/GPWeb/content/DPG/2V1/2v1-babygap-logo.png',
  '/images/Sephora_logo.jpg',
  'https://www.goat.com/images/icons/goat-logo-512.png',
  'https://www.victoriassecret.co.uk/nxtcms/resource/blob/5152178/0f36296edc650ef8eee0f6da7b938f5e/pink-logo-data.png',
  'https://images.ctfassets.net/5de70he6op10/164J0VjwrQLyBGkJmdruCI/d0a2f741c63bcac07216b49dc0c8c77e/EN_Extra20Sale_GlobalHeader_SS.jpg',
  'https://images.ctfassets.net/*/*/*/EN_*.jpg',
  'https://images.ctfassets.net/5de70he6op10/5ICF56KwLeZY0FNVeL54hV/36fd94841ac923d90b4b7cb8a18cddc9/EN_SpendStretch_GH_SS_ENDS.jpg',
  'https://assets.wfcdn.com/im/33758501/resize-h630-w1200%5Ecompr-r85/3949/39499404/.jpg',
  '',
])

export const domainFromHostname = (hostname: string | undefined): string => {
  const site = hostname || ''
  // remove www. from hostname and http(s)://
  return site.replace(/^www\./, '').replace(/^https?:\/\//, '')
}

export const domainFromUrl = (url: string | undefined): string => {
  const site = url || ''
  // remove www. from hostname and http(s):// and remove any trailing slashes
  return site
    .replace(/^www\./, '')
    .replace(/^https?:\/\//, '')
    .replace(/\/$/, '')
}

export const slugifyTitle = (title: string): string => {
  // title to max of 50 characters
  const trimmedTitle = slugifyName(title, true)
    .slice(0, 50)
    .replace(/-+$/, '')
    .toLowerCase()

  return trimmedTitle
}

export interface CreatePDPUrlParams {
  cartItem?: Pick<CartItemDataFragment, 'id'>
  shopItem?: Pick<ItemDataFragment, 'id' | 'display_title'>
  shop?: Pick<ShopDataFragment, 'has_pdp' | 'slug'>
  back?: string
  uid?: string
  cid?: string | null | undefined
  deals?: string
}

export interface CreatePDPUrlOptions {
  excludeProtocol?: boolean
  local?: boolean
  overrideShopHasPdp?: boolean
  queryParams?: Record<string, string>
}

export const createDeepCartItemUrl = ({
  collectionId,
  itemId,
}: {
  itemId: string
  collectionId?: string
}): string => {
  const url = collectionId
    ? `/collection/${collectionId}/${itemId}`
    : `/pdp/${itemId}`
  return createPublicUrl(url)
}

export const createDeepCollectionUrl = (collectionId: string): string => {
  return createPublicUrl(`/collection/${collectionId}`)
}

export const createPDPUrl = (
  { cartItem, shopItem, shop, back, uid, cid, deals }: CreatePDPUrlParams,
  {
    excludeProtocol,
    local,
    overrideShopHasPdp,
    queryParams,
  }: CreatePDPUrlOptions = {}
): string => {
  const title = slugifyTitle(shopItem?.display_title || '')

  if (!shop?.has_pdp && !overrideShopHasPdp) {
    return cartItem
      ? createAffiliateRedirectLink('cart-item', cartItem.id, {
          uid,
          cid,
        })
      : createAffiliateRedirectLink('item', shopItem?.id, {
          uid,
        })
  }

  const params = new URLSearchParams(queryParams)
  if (back) {
    params.append('back', back)
  }
  if (deals) {
    params.append('deals', deals)
  }
  const queryString = params.toString()

  return local
    ? `/shop/${shop?.slug}/${title}/${shopItem?.id}${
        queryString ? `?${queryString}` : ''
      }`
    : createPublicUrl(
        `/shop/${shop?.slug}/${title}/${shopItem?.id}${
          queryString ? `?${queryString}` : ''
        }`,
        {
          excludeProtocol,
        }
      )
}

export const createCollectionUrl = (
  username: string,
  slug: string,
  {
    local,
    excludeProtocol,
  }: { local?: boolean; excludeProtocol?: boolean } = {}
): string =>
  local
    ? `/${username}/${slug}`
    : createPublicUrl(`/${username}/${slug}`, { excludeProtocol })

export const createShopUrl = (
  slug?: string,
  {
    local,
    excludeProtocol,
  }: { local?: boolean; excludeProtocol?: boolean } = {}
): string =>
  local
    ? `/shop${slug ? `/${slug}` : ''}`
    : createPublicUrl(`/shop${slug ? `/${slug}` : ''}`, { excludeProtocol })

export const createCategoryUrl = (
  slug?: string,
  {
    local,
    excludeProtocol,
  }: { local?: boolean; excludeProtocol?: boolean } = {}
): string =>
  local
    ? `/categories${slug ? `/${slug}` : ''}`
    : createPublicUrl(`/categories${slug ? `/${slug}` : ''}`, {
        excludeProtocol,
      })

export interface CreateAffiliateRedirectParams {
  local?: boolean
  excludeProtocol?: boolean
  publicUrlOverride?: string
  cid?: string | null
  uid?: string | null
  link?: string | null
}

const SKIP_URL_PARAMS: string[] = ['undefined', 'null']

export const createApiUrl = (
  path: string,
  params: Record<string, string> = {}
): string => {
  if (!path) return ''

  let urlString = `${(API_ENDPOINT || '').replace(/\/$/, '')}/${path.replace(
    /^\//,
    ''
  )}`

  try {
    const url = new URL(urlString.replace(/\/$/, ''))

    Object.entries(params).forEach(([key, value]) => {
      if (!SKIP_URL_PARAMS.includes(String(value).toLowerCase()))
        url.searchParams.append(key, value)
    })

    urlString = url.toString()
  } catch (error) {
    return ''
  }

  return urlString
}

export const createDirectAffiliateLink = (
  type: RedirectObjectType,
  uuid: string,
  { cid, uid }: CreateAffiliateRedirectParams = {}
): string => {
  const _cid = cid || ''
  const _uid = uid || ''
  const redirectUrl = createApiUrl(`/r/${type}/${uuid}`, {
    uid: _uid,
    cid: _cid,
  })
  return redirectUrl
}

export const createAffiliateRedirectLink = (
  type: RedirectObjectType,
  uuid: string,
  {
    local,
    excludeProtocol,
    publicUrlOverride,
    cid,
    uid,
    link,
  }: CreateAffiliateRedirectParams = {}
): string => {
  const params = new URLSearchParams()
  if (cid) {
    params.append('cid', cid)
  }
  if (uid) {
    params.append('uid', uid)
  }
  if (link) {
    params.append('link', encodeURIComponent(link))
  }
  const queryString = params.toString()

  return local
    ? `/r/${type}/${uuid}${queryString ? `?${queryString}` : ''}`
    : createPublicUrl(
        `/r/${type}/${uuid}${queryString ? `?${queryString}` : ''}`,
        { excludeProtocol, publicUrlOverride }
      )
}

export interface TrendingSearchResponse {
  data: TrendingSearch[]
  timestamp: number
}

export const validateYoutubeVideoId = (id: string): boolean => {
  const pattern = /^[a-zA-Z0-9_-]{9,13}$/
  return pattern.test(id)
}

export const youtubeIdFromUrl = (url: string): string => {
  const [a, , b] = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/|\/shorts\/)/)
  if (b !== undefined) {
    return b.split(/[^0-9a-z_-]/i)[0]
  } else {
    return a
  }
}

export const stringifyArrayForGraphqlInput = (arr: string[] = []): string => {
  return JSON.stringify(arr || [])
    .replace('[', '{')
    .replace(']', '}')
}

export const formatNumber = (num: number): number | string => {
  try {
    return new Intl.NumberFormat('en-US').format(num)
  } catch (e) {
    return num
  }
}

export const formatNumberWithCurrency = (
  num?: string | number,
  currency?: string
): string => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(Number(num))
  } catch (e) {
    return (num || '').toString()
  }
}

export const extractFormattedTakeRate = (
  parsed_take_rate: Record<string, number> | null | undefined
): {
  value: number
  formatedValue: string
} => {
  const rates = new Set<number>(Object.values(parsed_take_rate || {}))
  const maxValue = Math.max(...rates) || 0
  const value = maxValue > 0 ? maxValue : 0
  const formatedValue = Number(value).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return {
    value,
    formatedValue,
  }
}
