import { OnlineStatusDetails } from '@carrotcart/client-common/lib/QueueUpGroup'

const getOnLineStatus = (): OnlineStatusDetails => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.onLine !== 'boolean'
  ) {
    return { isOnLine: 'unknown' }
  }

  return { isOnLine: navigator.onLine ? 'yes' : 'no' }
}

export default getOnLineStatus
