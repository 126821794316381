import slugify from 'slugify'
import startCase from 'lodash/startCase'
import psl, { ParsedDomain } from 'psl'

const isParseDomain = (parsed: any): parsed is ParsedDomain => {
  return typeof parsed.sld === 'string'
}

export const getShopName = (hostname?: string): string => {
  if (!hostname) return ''
  const parsed = psl.parse(hostname)
  const sldName = isParseDomain(parsed) ? parsed.sld : hostname
  return startCase(sldName || hostname)
}

export const getShopSlug = (name?: string): string => {
  if (!name) return ''
  return slugify(name, { lower: true, strict: true })
}

export const getShopKey = (url?: string): string => {
  if (!url) return ''
  try {
    const shopUrl = new URL(url)
    // Force https protocol
    shopUrl.protocol = 'https:'
    return shopUrl.origin
  } catch (e) {
    // noop
    return ''
  }
}

export const getShopSlugFromUrl = (url?: string): string => {
  const hostname = getShopHostname(url)
  const shopName = getShopName(hostname)
  const shopSlug = getShopSlug(shopName)
  return shopSlug
}

export const getShopHostname = (url?: string): string => {
  if (!url) return ''
  try {
    const shopUrl = new URL(url)
    return shopUrl.hostname
  } catch (e) {
    // noop
    return ''
  }
}
