export const EBAY_EPN_PARAMS_MAPPER = {
  siteid: '0', // this param does not appear in the doc but the EPN tool adds it
  mkcid: '1',
  mkrid: '711-53200-19255-0',
  campid: '5339045275', // carrot campaign created in the EPN
  toolid: '10001',
  mkevt: '1',
}

export const AF_APP_INVITE_ONE_LINK_ID = 'HywZ'

export const APP_STORE_REVIEW_DEEP_LINK =
  'itms-apps://itunes.apple.com/app/viewContentsUserReviews/id1605692440?action=write-review&starRating=5'

export const THIRTY_SECONDS_IN_MS = 30000

export const IS_SAFARI_EXTENSION = process.env.SAFARI_EXTENSION === 'true'

export const CARROT_OFFICIAL_ACCOUNT_ID = '5nXPBMJqqUWn6LNehDjSlvwJrS92'

export const AFFILIATE_TAB_ID_KEY = '__CARROT_AFFILIATE_TAB_ID_KEY'
export const AFFILIATE_TAB_ID_ARRAY_KEY = '__CARROT_AFFILIATE_TAB_ID_ARRAY_KEY'
export const AFFILIATE_TAB_SHOP_KEY = '__CARROT_AFFILIATE_TAB_SHOP_KEY'
export const AFFILIATE_WINDOW_ID_KEY = '__CARROT_AFFILIATE_WINDOW_ID_KEY'
export const CASHBACK_STORED_SEARCH_RESULT_KEY =
  '__CARROT_CASHBACK_STORED_SEARCH_RESULT_KEY'

export const CARROT_IDENTIFY_USER_KEY = '_carrot_identify_user'
export const CARROT_AFFILIATE_TABS_CLEANUP_KEY =
  '_carrot_affiliate_tabs_cleanup'

export const ANALYTICS_ANON_ID_KEY = '__CARROT_ANON_ID_KEY'
export const PDP_STORAGE_KEY = '__CARROT_PDP_STORAGE_KEY'

export const SAFARI_INITIAL_OPEN_TABS_KEY =
  '__CARROT_SAFARI_INITIAL_OPEN_TABS_KEY'

export const COLLAB_INVITE_REDIRECT_PATH =
  '__carrot_collab_invite_redirect_path'

export const CHROME_WEB_STORE_URL =
  'https://chrome.google.com/webstore/detail/carrot-shopping-save-comp/jkihnaflmidekdffapcafldfcplbbjaj'
export const MOBILE_FAQS_URL = 'https://help.carrot.link'

export const APP_SOURCE_BACKGROUND = 'background'
export const APP_SOURCE_POPUP = 'popup'
export const APP_SOURCE_CONTENT = 'content'
export const APP_SOURCE_DETECT = 'detect'

export const ONPAGE_SAVE_BUTTON_ID = '__carrot_onpage_save'
export const ONPAGE_SHADOW_ROOT_ID = '__carrot_root'
export const ONPAGE_SCRIPT_ID = '__carrot_onpage_script'
export const ONPAGE_APP_ROOT_ID = '__carrot_onpage'
export const ONPAGE_ARCHIE_ELEMENT_ID = '__carrot_archie'

export const ONBOARDING_CART_TIMEOUT = 10500

export const BADGE_COLOR_RECOGNIZED_SHOP = '#C84F0E'
export const BADGE_COLOR_UNKNOWN_SHOP = '#63636b'

export const PERSIST_UI_TTL = 60

export const ANONYMOUS_AUTHORIZATION_HEADER = 'x-anonymous-authorization'

export const CARROT_EXTENSION_DATA_ELEMENT_ID = '__carrot_extension_data'
export const CURRENT_EXTENSION_VERSION_DATA_ATTRIBUTE = 'version'
export const RUNTIME_DATA_ATTRIBUTE = 'runtime'

export const PUSH_NOTIFICATION_CTA_REF = 'carrotPush'

export const FALLBACK_DISPLAY_NAME = 'Unnamed User'
export const ANONYMOUS_USER_DISPLAY_NAME = 'Anonymous User'

export const TYPESENSE_HOST = process.env.TYPESENSE_HOST
export const TYPESENSE_API_KEY = process.env.TYPESENSE_API_KEY
export const TYPESENSE_PRODUCTS_INDEX = 'Products-prod'
export const TYPESENSE_COLLECTION_INDEX = 'Collection-prod'
export const TYPESENSE_USERS_INDEX = 'Users-prod'
export const TYPESENSE_QUERY_SUGGESTIONS_INDEX =
  'Collection-prod_query_suggestions'

export enum MessageListenerRuntime {
  BackgroundScript = 'BACKGROUND_SCRIPT',
  ContentScript = 'CONTENT_SCRIPT',
  OnPageApp = 'ON_PAGE_APP',
  WebApp = 'WEB_APP',
  DetectScript = 'DETECT_SCRIPT',
}

export enum ApolloQueryName {
  GetMostRecentActiveCartItemsForUser = 'GetMostRecentActiveCartItemsForUser',
}

export enum BackgroundMessageType {
  AddNewCollection = 'MESSAGE_ADD_NEW_COLLECTION',
  AddItemToNewCollection = 'MESSAGE_ADD_ITEM_TO_NEW_COLLECTION',
  AddToCollection = 'MESSAGE_ADD_TO_COLLECTION',
  AddNoteToCartItem = 'MESSAGE_ADD_NOTE_TO_CART_ITEM',
  AnalyticsTrack = 'MESSAGE_ANALYTICS_TRACK',
  AuthSignin = 'MESSAGE_AUTH_SIGNIN',
  AuthSignout = 'MESSAGE_AUTH_SIGNOUT',
  PopupOpened = 'MESSAGE_POPUP_OPENED',
  CheckIsUpdateAvailable = 'CHECK_IS_UPDATE_AVAILABLE',
  GetUserCollections = 'MESSAGE_GET_COLLECTIONS',
  InitShop = 'MESSAGE_INIT_SHOP',
  InitUnknownShop = 'MESSAGE_INIT_UNKNOWN_SHOP',
  SaveCart = 'MESSAGE_SAVE_CART',
  SaveCheckout = 'MESSAGE_SAVE_CHECKOUT',
  PreSaveCart = 'MESSAGE_PRE_SAVE_CART',
  SaveCartSaved = 'MESSAGE_SAVE_CART_SAVED',
  StartCartOnboarding = 'START_CART_ONBOARDING',
  TriggerExtensionUpdate = 'TRIGGER_EXTENSION_UPDATE',
  CheckIfSignedIn = 'CHECK_IF_SIGNED_IN',
  OpenShopCartPage = 'OPEN_CART_ON_DIFFERENT_DOMAIN',
  ClosingShopCartPage = 'CLOSING_CART_ON_DIFFERENT_DOMAIN',
  PushNotification = 'MESSAGE_PUSH_NOTIFICATION',
  CheckForNotification = 'CHECK_FOR_NOTIFICATION',
  SetNotificationViewed = 'SET_NOTIFICATION_VIEWED',
  SetNotificationClicked = 'SET_NOTIFICATION_CLICKED',
  SetNotificationClickedRemindMe = 'SET_NOTIFICATION_CLICKED_REMIND_ME',
  SendApolloQuery = 'SEND_APOLLO_QUERY',
  SendApolloMutation = 'SEND_APOLLO_MUTATION',
  CheckSaveButtonEnabled = 'CHECK_SAVE_BUTTON_ENABLED',
  GetShopData = 'GET_SHOP_DATA',
  IsFeatureEnabled = 'IS_FEATURE_ENABLED',
  GetUserCollectionsAggregate = 'GET_USER_COLLECTIONS_AGGREGATE',
  SafariExtGetUser = 'SAFARI_EXT_GET_USER',
  DisplayOnPageSave = 'DISPLAY_ON_PAGE_SAVE',
  TriggerDisplayOnPageSave = 'TRIGGER_DISPLAY_ON_PAGE_SAVE',
  GetUserUID = 'GET_USER_UID',
  DisplayOnPageExtension = 'DISPLAY_ON_PAGE_EXTENSION',
  TriggerOnPageExtension = 'TRIGGER_ON_PAGE_EXTENSION',
  OpenTab = 'OPEN_TAB',
  OpenAffiliateTab = 'OPEN_AFFILIATE_TAB',
  CloseAffiliateTab = 'CLOSE_AFFILIATE_TAB',
  UpdateSnoozeOptions = 'UPDATE_SNOOZE_OPTIONS',
  ExtensionCancelSnooze = 'EXTENSION_CANCEL_SNOOZE',
  GetSnoozeOptions = 'GET_SNOOZE_OPTIONS',
  UpdateOnPageUser = 'UPDATE_ON_PAGE_USER',
  TriggerFetchMySavedCartData = 'TRIGGER_FETCH_MY_SAVED_CART_DATA',
  FetchMySavedCartData = 'FETCH_MY_SAVED_CART_DATA',
  ShuffleInfluencerPicks = 'SHUFFLE_INFLUENCER_PICKS',
  SetSavedPosition = 'SET_SAVED_POSITION',
  SafariCollectionsListUpdated = 'SAFARI_COLLECTIONS_LIST_UPDATED',
  SafariAddedToColllectionConfirmation = 'SAFARI_ADDED_TO_COLLECTION_CONFIRMATION',
  SetCartItemOverrideImage = 'SET_CART_ITEM_OVERRIDE_IMAGE',
  FetchSimilarProducts = 'FETCH_SIMILAR_PRODUCTS',
  CheckItemAlreadySaved = 'CHECK_ITEM_ALREADY_SAVED',
  IsFeatureEnabledMsgActive = 'IS_FEATURE_ENABLED_MSG_ACTIVE',
  ForceCheckAuthStatus = 'FORCE_CHECK_AUTH_STATUS',
  GetDealHopGateFlags = 'GET_DEAL_HOP_GATE_FLAGS',
  UpdateDealHopPendingTimestamp = 'UPDATE_DEAL_HOP_PENDING_TIMESTAMP',
  ShopTakeRate = 'SHOP_TAKE_RATE',
  ShopPointsAverage = 'SHOP_POINTS_AVERAGE',
  VisitAffiliateLink = 'VISIT_AFFILIATE_LINK',
  ToggleAutoCashback = 'TOGGLE_AUTO_CASHBACK',
  GetUserFlags = 'GET_USER_FLAGS',
  CheckCookiesChanged = 'CHECK_COOKIES_CHANGED',
  GetSaveButtonOptions = 'GET_SAVE_BUTTON_OPTIONS',
  GetSafariExtUserDetails = 'GET_SAFARI_EXT_USER_DETAILS',
}

export enum ContentMessageType {
  MessageGetDetectedShop = 'MESSAGE_GET_DETECTED_SHOP',
  ContextMenuAdd = 'MESSAGE_CONTEXT_MENU_ADD',
  RefreshSaveCart = 'MESSAGE_REFRESH_SAVE_CART',
  SafariBookmark = 'MESSAGE_SAFARI_BOOKMARK',
  SendToWebApp = 'SEND_TO_WEB_APP',
  SafariPopupDealHop = 'MESSAGE_SAFARI_POPUP_DEAL_HOP',
}

export const CARROT_CREATOR_TYPEFORM_URL =
  'https://t1wa9wn4w2x.typeform.com/carroteer'

export const WINDOW_SOURCE_PARAM = 'carrotSource'

export enum WINDOW_SOURCE_PARAM_TYPES {
  SaveCartWindow = 'carrot_save_cart',
}

export type DATA_SOURCE_CARROT_TYPE = 'DATA_SOURCE_CARROT'
export const DATA_SOURCE_CARROT: DATA_SOURCE_CARROT_TYPE = 'DATA_SOURCE_CARROT'

export const STORE_AUTH_PROCESSING = '__CARROT_STORE_AUTH_PROCESSING'
export const STORE_AUTH_ERROR = '__CARROT_STORE_AUTH_ERROR'

export const SAFARI_POPUP_FIRST_OPEN = '__CARROT_SAFARI_POPUP_FIRST_OPEN'
export const CUSTOM_RETURN_DEEP_LINK = '__CARROT_CUSTOM_RETURN_DEEP_LINK'

export const HASURA_EXTENSION_CODES = {
  CONSTRAINT_VIOLATION: 'constraint-violation',
}

export enum WebViewSource {
  Button = 'button',
  SideMenu = 'side_menu',
}

export const RedirectLinkKey = 'CARROT_ACTIVE_REDIRECT_LINK'

export const SAFARI_ONBOARDING_PERMISSIONS_URL = `${process.env.PUBLIC_URL}/mobile-safari/permissions`

export enum AnalyticsEventName {
  DealHopGateTriggered = 'Deal Hop Gate Triggered',
  DealHopGateViewed = 'Deal Hop Gate Viewed',
  DealHopGateUnlocked = 'Deal Hop Gate Unlocked',
  DealHopGateInvite = 'Deal Hop Gate Invite',
  DealHopGateInviteViewed = 'Deal Hop Gate Invite Viewed',
  ClickedGetCarrotiOS = 'Clicked Get Carrot iOS',
  ClickedFeatureBannerCTA = 'Clicked Feature Banner CTA',
  ClickedFeatureBannerVideo = 'Clicked Feature Banner Video',
  ClickedFeatureBannerExit = 'Clicked Feature Banner Exit',
  ClickedCollectionDealCTA = 'Clicked Collection Deal CTA',
  ClickedCollectionDealRow = 'Clicked Collection Deal Row',
  ClickedCollectionDealItem = 'Clicked Collection Deal Item',
  ClickedPriceHop = 'Clicked Price Hop',
  ClickedAiSuggestions = 'Clicked Ai Suggestions',
  ClickedPriceHopResult = 'Clicked Price Hop Result',
  CreatorProfileModalSubscribed = 'Creator Profile Modal Subscribed',
  CreatorProfileModalClicked = 'Creator Profile Modal Clicked',
  CreatorProfileModalShown = 'Creator Profile Modal Shown',
  CreatorProfileModalSubButtonClicked = 'Creator Profile Modal Sub Button Clicked',
  CreatorProfileModalLaterButtonClicked = 'Creator Profile Modal Later Button Clicked',
  ClickedSubscribeCTA = 'Clicked Subscribe CTA',
  SubscribedToCreator = 'Subscribed To Creator',
  ClaimedCartItem = 'Claimed Cart Item',
  CommentAdded = 'Comment Added',
  PageRedirect = 'Page Redirect',
  JoinBannerNoThanks = 'Join Banner No Thanks',
  ViewCollectionFirst = 'View Collection First',
  ClickedPairsWithResults = 'Clicked Pairs With Results',
  ClickedHomeShuffleButton = 'Home Shuffle Button',
  ClickedHomeTrendingSearch = 'Home Trending Search',
  ShopShareClicked = 'Shop Share Clicked',
  ShopFAQClicked = 'Shop FAQ Clicked',
  LandingFAQClicked = 'Landing FAQ Clicked',
  Search = 'Search',
  SearchFetchMore = 'Search Fetch More',
  ClickedDiscoveryItem = 'Clicked Discovery Item',
  ClickedAddToCarrot = 'Clicked Add to Carrot',
  ClickedFollowCollectionCTA = 'Clicked Follow Collection CTA',
  FollowCollection = 'Follow Collection',
  UnfollowCollection = 'Unfollow Collection',
  ViewedCollectionInvitePage = 'Viewed Collection Invite Page',
  SignupVideoClick = 'Signup Video Click',
  AddedToCollection = 'Added To Collection',
  AddedToRabbithole = 'Added To Rabbithole',
  BookmarkedViaTextFailed = 'Bookmarked via Text Failed',
  BookmarkedViaText = 'Bookmarked via Text',
  BookmarkedViaShareSheet = 'Bookmarked via Share Sheet',
  BookmarkedViaShareSheetFailed = 'Bookmarked via Share Sheet Failed',
  CartSaved = 'Cart Saved',
  CheckoutSaved = 'Checkout Saved',
  CopiedCartUrl = 'Copied Cart Url',
  CopiedShareUrl = 'Copied Share Url',
  CopiedCouponCode = 'Copied Coupon Code',
  CopiedOpenCollaborationUrl = 'Copied Open Collanboration Url',
  CopiedOnboardingUrl = 'Copied Onboarding Url',
  CopiedProfileUrl = 'Copied Profile Url',
  ShareToSocialMedia = 'Share to Social Media',
  CreatedCollection = 'Created Collection',
  EditedCollection = 'Edited Collection',
  CollectionCollaborateCTAClick = 'Collection Collaborate CTA Click',
  CollectionCollaboratorInvited = 'Collection Collaborator Invited',
  CollectionCollaboratorRemoveInvite = 'Collection Collaborator Remove Invite',
  CollectionViewAll = 'Collection View All',
  CollectionClickOtherCollection = 'Collection Click Other Collection',
  CollectionClickDiscoveryCollection = 'Collection Click Discovery Collection',
  CollectionPublishModal = 'Collection Publish Modal',
  ExtensionOpened = 'Extension Opened',
  FinishedOnboarding = 'Finished Onboarding',
  GoToCart = 'Go To Cart',
  InstalledExtension = 'Installed Extension',
  OpenedCollection = 'Opened Collection',
  OpenedItem = 'Opened Item',
  ArchiveCartItem = 'Archive Cart Item',
  UnarchiveCartItem = 'Unarchive Cart Item',
  RemovedCartItem = 'Removed Cart Item',
  RemovedCollection = 'Removed Collection',
  RemovedFromCollection = 'Removed From Collection',
  SignedOut = 'Signed Out',
  SignedIn = 'Signed In',
  SignedUp = 'Signed Up',
  SignedUpAnonymous = 'Signed Up Anonymous',
  AnonymousUserUpdated = 'Anonymous User Updated',
  ToggledCollectionPrivacy = 'Toggled Collection Privacy',
  ToggledCollectionGiftlist = 'Toggled Collection Giftlist',
  SavingNote = 'Saving Note',
  ClearedNote = 'Cleared Note',
  AddingNote = 'Cicked Add Note',
  OnboardingVideoSkipped = 'Onboarding Video Skipped',
  OnboardingOneTapShareInitiated = 'Onboarding One Tap Share Initiated',
  OnboardingOneTapGuideReset = 'Onboarding One Tap Guide Reset',
  OnboardingOneTapGuideSkip = 'Onboarding Skip One Tap',
  OnboardingOneTapEnabled = 'Onboarding One Tap Enabled',
  OnboardingOneTapShareSheetClosed = 'Onboarding One Tap Share Sheet Closed',
  OnboardingExtensionEmailAction = 'Onboarding Extension Email Action',
  OnboardingExtensionEmailSkipped = 'Onboarding Extension Email Skipped',
  OnboardingDrawerPickedProduct = 'Onboarding Drawer Picked Product',
  OnboardingDrawerAddedToCart = 'Onboarding Drawer Added To Cart',
  OnboardingDrawerFinish = 'Onboarding Drawer Finish',
  OnboardingDrawerButtonClick = 'Onboarding Drawer Button Click',
  OnboardingUsernameClaimed = 'Onboarding Username Claimed',
  UsernameClaimed = 'Username Claimed',
  PushNotificationCTAClick = 'Push Notification CTA Click',
  PushNotificationViewed = 'Push Notification Viewed',
  PushNotificationDismissed = 'Push Notification Dismissed',
  PushNotificationClickedRemindMe = 'Push Notification Clicked Remind Me',
  PDPShareClick = 'PDP Share Click',
  ViewedSignupModal = 'Viewed Signup Modal',
  ViewedPDPModal = 'Viewed PDP Modal',
  VersionUpdated = 'Version Updated',
  FeatureFlagDetermination = 'Feature Flag Determination',
  WatchedOnboardingVideo = 'Watched Onboarding Video',
  SavedOnboardingSource = 'Saved Onboarding Source',
  ShopSectionImpression = 'Shop Section Impression',
  ShowCashback = 'Show Cash Back',
  ActivateCashback = 'Activate Cash Back',
  CashbackModalDismissed = 'Cash Back Modal Dismissed',

  //Safari Extension Events
  MobileExtensionNotificationTappedShare = 'Mobile Extension Notification Tapped Share',
  MobileExtensionNotificationTappedShareDeals = 'MobileExtensionNotificationTappedShareDeals',
  MobileExtensionNotificationOpenCarrot = 'Mobile Extension Notification Open Carrot',
  MobileExtensionNotificationDismissed = 'Mobile Extension Notification Dismissed',
  MobileExtensionArchieIconTapped = 'Mobile Extension Archie Icon Tapped',
  MobileSafariTappedReviewBarNotShown = 'Mobile Safari Tapped Review Bar Not Shown',
  MobileSafariEnableScreenCTATapped = 'Mobile Safari Enable Screen CTA Tapped',
  MobileSafariEnableScreenDismissed = 'Mobile Safari Enable Screen Dismissed',
  MobileSafariEnableScreenShown = 'Mobile Safari Enable Screen Shown',
  MobileExtensionInviteTapped = 'Mobile Extension Invite Tapped',
  MobileSafariHostPermissions = 'Mobile Safari Host Permissions',

  // Mobile Events
  TappedBrandDealCard = 'Tapped Brand Deal Card',
  TappedDealOfTheDay = 'Tapped Deal Of The Day',
  RevealedDealOfTheDay = 'Revealed Deal Of The Day',
  TappedEarnCashback = 'Tapped Earn Cashback',
  AFInvite = 'af_invite',
  ApplicationInstalled = 'Application Installed',
  ApplicationInstallConversion = 'Application Install Conversion',
  ApplicationOpened = 'Application Opened',
  AdvertiserTrackingEnabled = 'Advertiser Tracking Enabled',
  TappedDismissShareCollectionHighlight = 'Tapped Dismiss Share Highlight',
  TappedShareCollectionHighlight = 'Tapped Share Collection Highlight',
  TappedDismissCollabCollectionHighlight = 'Tapped Dismiss Collab Highlight',
  TappedCollabCollectionHighlight = 'Tapped Collab Collection Highlight',
  TappedOnCartItem = 'Tapped on Cart Item',
  TappedOnCartItemOptions = 'Tapped on Cart Item Options',
  TappedCollection = 'Tapped Collection',
  TappedDiscoveryCard = 'Tapped Discovery Card',
  TappedCreateCollection = 'Tapped Create Collection',
  TappedCartItemRemove = 'Tapped Cart Item Remove',
  CancelledRemoveCartItem = 'Cancelled Remove Cart Item',
  TappedRemoveCollection = 'Tapped Remove Collection',
  RemoveItemFromCollection = 'Remove Item From Collection',
  CancelledRemoveCollection = 'Cancelled Remove Collection',
  TappedProviderButton = 'Tapped Provider Button',
  TappedAddToCollection = 'Tapped Add To Collection',
  TappedCartItemShare = 'Tapped Cart Item Share',
  CollectionPrivacyToggle = 'Collection Privacy Toggle',
  TappedCollectionOptions = 'Tapped Collection Options',
  TappedLogIn = 'Tapped Log In',
  TappedSignUp = 'Tapped Sign Up',
  TappedShareProfile = 'Tapped Share Profile',
  TappedEditProfile = 'Tapped Edit Profile',
  TappedShareCollection = 'Tapped Share Collection',
  TappedShareCollectionLink = 'Tapped Share Collection Link',
  TappedProfileURL = 'Tapped Profile URL',
  TappedProfileLink = 'Tapped Profile Link',
  TappedFeedback = 'Tapped Feedback',
  TappedLogOut = 'Tapped Log Out',
  TappedEnableOneTapEnableShortcut = 'Tapped Enable One Tap Enable Shortcut',
  TappedDeleteAccount = 'Tapped Delete Account',
  TappedDeleteAccountSubmit = 'Tapped Delete Account Submit',
  TappedFeaturedCollection = 'Tapped Featured Collection',
  TappedShopperHighlights = 'Tapped Shopper Highlights',
  TappedPublishCollection = 'Tapped Publish Collection',
  DeletedAccountSuccess = 'Deleted Account Success',
  WebViewAddToCarrot = 'Web View Add To Carrot',
  WebViewGoBack = 'Web View Go Back',
  WebViewGoForward = 'Web View Go Forward',
  WebViewFeedback = 'Web View Feedback',
  ShareSheetDeepLink = 'Share Sheet Deep Link',
  TappedShareWebView = 'Tapped Share Web View',
  DiscoveryPullToRefresh = 'Discovery Pull To Refresh',
  DiscoveryScroll = 'Discovery Scroll',
  DiscoveryFetchMore = 'Discovery Fetch More',
  DiscoveryCardImpression = 'Discovery Card Impression',
  ZeroStateVideoPlayed = 'Zero State Video Played',
  VideoGuidePlayed = 'Video Guide Played',
  ZeroStateFeaturedShopTapped = 'Zero State Featured Shop Tapped',
  MobileOnboardingNameEntered = 'Mobile Onboarding Name Entered',
  MobileOnboardingRequestPermission = 'Mobile Onboarding Request Permission',
  MobilePushNotificationReceived = 'Mobile push notification received',
  MobilePushNotificationReceivedInForeground = 'Mobile push notification received in the foreground',
  MobilePushNotificationSuccess = 'Mobile push notification success received',
  MobilePushNotificationError = 'Mobile push notification received error',
  MobilePushNotificationInteraction = 'Mobile push notification interaction',
  MobilePushNotificationSent = 'Mobile push notification sent',
  MobileOnboardingEmailEntered = 'Mobile Onboarding Email Entered',
  TappedOpenItemLink = 'Tapped Open Item Link',
  TappedCardActionButton = 'Tapped Card Action Button',
  TappedCartItemOptions = 'Tapped Cart Item Options',
  TappedYourFindsVideoCTA = 'Tapped Your Finds Video CTA',
  TappedShareSheetModalVideoCTA = 'Tapped Share Sheet Modal Video CTA',
  MobileContactInvited = 'Mobile Contact Invited',
  LikedFeedItem = 'Liked Feed Item',
  LikedCartItem = 'Liked Cart Item',
  HomeTabTapped = 'Home Tab Tapped',
  ShopTabTapped = 'Shop Tab Tapped',
  CollectionTabTapped = 'Collection Tab Tapped',
  ShopsFilterOpened = 'Shops Filter Opened',
  ShopFilterCategorySelected = 'Shop Filter Category Selected',
  AppStoreReviewRequested = 'App Store Review Requested',
  AddToChrome = 'Add To Chrome',
  TappedFAQs = 'Tapped FAQs',
  TappedInviteFriends = 'Tapped Invite Friends',
  TappedEnableSafariKeyAction = 'Tapped Enable Safari Key Action',
  ContactPermissionRequested = 'Contact Permission Requested',
  NotificationCenterItemTap = 'Notification Center Item Tap',
  TappedGetRecommendations = 'Tapped Get Reccomendations',
  TappedProductShuffle = 'Tapped Product Shuffle',
  TappedUserProfile = 'Tapped User Proifle',
  LoginVideoIntroTapped = 'Login Video Intro Tapped',
  ShopListTapped = 'Shop List Tapped',
  OnboardingCarrotOnCampusWebViewClosed = 'Onboarding Carrot on Campus Webview Closed',
  OneTapEnableShortcutShare = 'One Tap Enable Shortcut Share',
  // Search-related analytics event names
  ClickedASearchFilter = 'Clicked A Search Filter',
  ViewedASearchFilter = 'Viewed A Search Filter',
  ViewedObjectIDsInSearch = 'Viewed Object IDs In Search',
  ConvertedFiltersInSearch = 'Converted Filters In Search',
  ConvertedObjectIDsInSearch = 'Converted Object IDs In Search',
  ConvertedObjectIDsAfterSearch = 'Converted Object IDs After Search',
  ClickedCollectionFromSearch = 'Clicked Collection From Search',
  PasswordResetTriggered = 'Password Reset Triggered',
  WrongPasswordEntered = 'Wrong Password Entered',
  ExtensionOpenUrl = 'Extension Open Url',
  ExtensionCancelSnooze = 'Extension Cancel Snooze',
  ExtensionUpdateSnoozeOptions = 'Extension Update Snooze Options',
  ClickedInfluencerLink = 'Clicked Influencer Link',
  ClickedViewPicks = 'Clicked View Picks',
  ExtensionPicksImpression = 'Extension Picks Impression',
  ExtensionClosed = 'Extension Closed',
  TappedShareSearchItem = 'Tapped Share Search Item',
  LoginGetStartedTapped = 'Login Get Started Tapped',
  TappedShareCollectionToInstagram = 'Tapped Share Collection To Instagram',
  TappedShareCollectionToPinterest = 'Tapped Share Collection To Pinterest',
  TappedSaveCollectionImage = 'Tapped Save Collection Image',
  TappedCopyLinkCollection = 'Tapped Copy Link Collection',
  TappedShareCollectionSMS = 'Tapped Share Collection SMS',
  TappedShareCollectionEmail = 'Tapped Share Collection Email',
  MobileOnboardingSubscribe = 'Mobile Onboarding Subscribe',
  ClickedArchieTab = 'Clicked Archie Tab',
  PDPEditImage = 'PDP Edit Image',
  PDPEditItem = 'PDP Edit Item',
  PDPOnPageShown = 'PDP On Page Shown',
  TappedShareDeals = 'Tapped Share Deals',
  MobileInviteResponse = 'Mobile Invite Response',
  AffiliateTabOpened = 'Affiliate Tab Opened',
  AffiliateTabClosed = 'Affiliate Tab Closed',
  CashBackSearchResultButtonClicked = 'Cashback Search Result Button Clicked',
  AcceptBoostOffer = 'Accept Boost Offer',
}

export enum SignupCampaign {
  AdminWall = 'admin-wall',
  GiftSignupWall = 'gift-signup-wall',
  InfluencerSignupWall = 'influencer-signup-wall',
  SignupWall = 'signup-wall',
  CollectionCollaborationInvite = 'collection-collaboration-invite',
  CollectionJoinBanner = 'collection-join-banner',
  Feedback = 'feedback',
  Signin = 'signin',
  Signup = 'signup',
  ContactInvite = 'contact-invite',
  SignupFlipped = 'signup-flipped',
  PhoneLogin = 'phone-login',
}

export const API_ENDPOINT = (
  process.env.API_ENDPOINT || 'https://api.crrt.dev'
).replace(/^\/$/, '')
export const CUSTOM_TOKEN_API_ENDPOINT = `${API_ENDPOINT}/api/create-custom-token`
export const TOGGLE_BILLING_MODE_API_ENDPOINT = `${API_ENDPOINT}/api/toggle-billing-mode`
export const SAVE_BUTTON_OPIONS_API_ENDPOINT = `${API_ENDPOINT}/api/save-button-options`
export const ORPHAN_ANONYMOUS_USER_API_ENDPOINT = `${API_ENDPOINT}/api/anonymous-cleanup`
export const VALIDATE_USERNAME_API_ENDPOINT = `${API_ENDPOINT}/api/valid-username`
export const DELETE_ACCOUNT_API_ENDPOINT = `${API_ENDPOINT}/api/delete-account`
export const VALIDATE_IAP_RECEIPT_API_ENDPOINT = `${API_ENDPOINT}/api/iap-validate-receipt`
export const FETCH_IAP_SKUS_API_ENDPOINT = `${API_ENDPOINT}/api/iap-sku-details`
export enum ApiErrorCode {
  Unauthorized = 'unauthorized',
  BadRequest = 'bad_request',
  NoUserUid = 'no_user_uid',
  UserNotInDatabase = 'user_not_in_database',
  IdTokenExpired = 'id_token_expired',
  Unauthenticated = 'unauthenticated',
  RedirectUrlNotFound = 'redirect_url_not_found',
  InvalidMessageBirdSignature = 'invalid_message_bird_signature',
  InvalidEasRequestSignature = 'invalid_eas_request_signature',
  WorkflowIdNotFound = 'workflow_id_not_found',
  MissingAppleAuthorizationCode = 'missing_apple_authorization_code',
  InvalidAuthorizationCode = 'invalid_authorization_code',
  InvalidUrl = 'invalid_url',
  MissingRequiredParameter = 'missing_required_parameter',
  BillingAccountNotInDatabase = 'billing_account_not_in_database',
  BillingAccountAlreadyCreated = 'billing_account_already_created',
  OutOfSyncStripeUpdate = 'out_of_sync_stripe_update',
  UserAlreadyHasPaidSubscription = 'user_already_has_paid_subscription',
  CannotCreateBillingAccount = 'cannot_create_billing_account',
  UserCannotBeImpersonated = 'user_cannot_be_impersonated',
}

export const HASURA_CLAIM_KEY = 'https://hasura.io/jwt/claims'

export enum ShopTableMetadataKeys {
  PepperjamAdvertiserId = 'pepperjam_advertiser_id',
  PepperjamDeepLink = 'pepperjam_deep_link',
  PepperjamTakeRate = 'pepperjam_take_rate',
  RakutenAdvertiserId = 'rakuten_advertiser_id',
  RakutenTakeRate = 'rakuten_take_rate',
  CjAdvertiserId = 'cj_advertiser_id',
  CjParentShopKey = 'cj_parent_shop_key',
  CjDeepLink = 'cj_deep_link',
  CjTakeRate = 'cj_take_rate',
  RedirectQueryParam = 'redirect_query_param',
  RedirectUrlMatch = 'redirect_url_match',
  LinkConnectorDeepLink = 'lc_deep_link',
  LinkConnectorMerchantId = 'lc_merchant_id',
  LinkConnectorTakeRate = 'lc_take_rate',
  ScrapingStrategies = 'scraping_strategies',
  ImpactDeepLink = 'impact_deep_link',
  ImpactAdvertiserId = 'impact_advertiser_id',
  ImpactTakeRate = 'impact_take_rate',
  AwinAdvertiserId = 'awin_advertiser_id',
  AwinTakeRate = 'awin_take_rate',
  ShareASaleDeepLink = 'share_a_sale_deep_link',
  ShareASaleMerchantId = 'share_a_sale_merchant_id',
  ShareASaleTakeRate = 'share_a_sale_take_rate',
  AvantLinkMerchantId = 'avantlink_merchant_id',
  AvantLinkTakeRate = 'avantlink_take_rate',
  PartnerizeCampaignId = 'partnerize_campaign_id',
  PartnerizeTakeRate = 'partnerize_take_rate',
  PartnerizeTrackingLink = 'partnerize_tracking_link',
  SovrnTakeRate = 'sovrn_take_rate',
  SovrnMerchantId = 'sovrn_merchant_id',
  FlexOffersAdvertiserId = 'flex_offers_advertiser_id',
  FlexOffersDeepLink = 'flex_offers_deep_link',
  FlexOffersTakeRate = 'flex_offers_take_rate',
}

export const X_HASURA_DEFAULT_ROLE = 'x-hasura-default-role'
export const X_HASURA_ALLOWED_ROLES = 'x-hasura-allowed-roles'
export const X_HASURA_ROLE = 'x-hasura-role'
export const X_HASURA_USER_ID = 'x-hasura-user-id'
export const X_HASURA_BILLING_MODE = 'x-hasura-billing-mode' as const

export const IS_PROD = process.env.NODE_ENV === 'production'

export const GRAPHQL_HTTP_ENDPOINT =
  process.env.GRAPHQL_HTTP_ENDPOINT || 'https://graph.crrt.dev'
export const GRAPHQL_WS_ENDPOINT =
  process.env.GRAPHQL_WS_ENDPOINT || 'wss://db.crrt.dev/v1/graphql'

export enum AppName {
  Extension = 'Carrot Extension',
  SafariExtension = 'Safari Extension',
  MobileApp = 'Carrot Mobile',
}

export const RN_WEBVIEW_MESSAGE_CLOSE = 'carrot:close:page'

export const SOCKET_IO_ENDPOINT =
  process.env.SOCKET_IO_ENDPOINT || 'https://api.crrt.dev'

export const SOCKET_IO_FRONTEND_TYPE = 'client' as const
export const SOCKET_IO_BACKEND_TYPE = 'server' as const
export const SOCKET_IO_CLIENT_TYPES = [
  SOCKET_IO_FRONTEND_TYPE,
  SOCKET_IO_BACKEND_TYPE,
] as const
export type SocketIoClientType = typeof SOCKET_IO_CLIENT_TYPES[number]

export const NETWORK_ERROR_MESSAGE_REGEX = /Network Error/

export const IMAGEKIT_PUBLIC_KEY = process.env.IMAGEKIT_PUBLIC_KEY
export const IMAGEKIT_URL_ENDPOINT = 'https://ik.imagekit.io/carrot/'
export const IMAGEKIT_AUTHENTICATION_ENDPOINT = `${API_ENDPOINT}/api/imagekit/auth`

export const EXCLUDE_USERNAMES = [
  'carrot',
  'rustyzone',
  'russell',
  'raminb',
  'ramin',
  'carrot.ricky',
  'amir.carrot',
  'amiramiri',
  'kellycarrot',
  'dogsofinstagram',
  'zoe',
  'maddiemitt',
  'nickstamas',
  'billy',
  'carrot4college',
  'carrot4interiordesign',
  'archie',
]

export const DEFAULT_COUNTRY_CODE = 'US'

export const DEAL_HOP_GATE_UNLOCK_TIMER_MS = 3600000
