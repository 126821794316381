import emojiRegex from 'emoji-regex'
import slugify from 'slugify'
import generateShortId from '@carrotcart/common/lib/generateShortId'

export const slugifyName = (name: string, excludeEmoji = false): string => {
  const slug = slugify(name, { lower: true, strict: true })
  const emojiMatches = name.match(emojiRegex())
  // if string contains emoji, convert to shortid 4 characters long
  if (emojiMatches) {
    return slug
      ? `${slug}-${excludeEmoji ? '' : generateShortId(4)}`
      : generateShortId(4)
  }

  return slug
}

export default slugifyName
