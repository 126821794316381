import React, { useCallback, useEffect, useRef, useState } from 'react'
import noop from 'lodash/noop'
import EventEmitter from 'events'
import { useRouter } from 'next/router'
import logger from '@carrotcart/client-common/lib/logger/web'

export const eventEmitter = new EventEmitter()
export const EVENT_NAME = 'set_set_query'

interface SetQueryFn {
  (query: string, reset?: boolean): void
}

export interface EventEmitterListenerValue {
  fn: SetQueryFn | null
}

interface EventEmitterListener {
  (value: EventEmitterListenerValue): void
}

interface ISearchQueryProviderContext {
  setQuery: SetQueryFn
}

const defaultContext: ISearchQueryProviderContext = {
  setQuery: noop,
}

export const SearchQueryContext = React.createContext(defaultContext)

export const useSearchQueryContext = (): ISearchQueryProviderContext => {
  return React.useContext<ISearchQueryProviderContext>(SearchQueryContext)
}

const SearchQueryProvider: React.FC = ({ children }) => {
  const router = useRouter()
  const navigableSetQuery = useCallback(
    (q: string, reset?: boolean) => {
      if (reset) return
      router.push(`/search?q=${q}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const eventListenerRef = useRef<EventEmitterListener>()
  const [setQuery, setSetQuery] = useState<SetQueryFn>(() => navigableSetQuery)

  useEffect(() => {
    const eventListener: EventEmitterListener = ({ fn }) => {
      logger.debug({ message: 'Changing the setQuery fn', fn })
      if (fn === null) {
        setSetQuery(() => navigableSetQuery)
      } else {
        setSetQuery(() => fn)
      }
    }

    // Shouldn't really happen but doing this in case this useEffect
    // gets re-run
    if (eventListenerRef.current) {
      eventEmitter.removeListener(EVENT_NAME, eventListenerRef.current)
    }
    eventListenerRef.current = eventListener

    eventEmitter.addListener(EVENT_NAME, eventListener)

    return () => {
      eventEmitter.removeListener(EVENT_NAME, eventListener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchQueryContext.Provider
      value={{
        setQuery,
      }}
    >
      {children}
    </SearchQueryContext.Provider>
  )
}

export default SearchQueryProvider
