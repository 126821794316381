import pino, { levels, Level, LogEvent, Logger, Bindings } from 'pino'
import {
  commonConfig,
  sentrifiedLogger,
  BrowserMixin,
} from '@carrotcart/common/lib/logger'
import { IS_PROD } from '@carrotcart/common/lib/constants'
import type QueueUpGroup from '@carrotcart/client-common/lib/QueueUpGroup'
import auth from '@carrotcart/client-common/lib/firebase/auth'

const TRANSMIT_LOCAL_DEV_LOGS = process.env.TRANSMIT_LOCAL_DEV_LOGS === 'true'

const LOG_COLOR_MAPPING: Record<Level, string> = {
  info: '\x1b[36m',
  debug: '\x1b[32m',
  trace: '\x1b[32m',
  warn: '\x1b[33m',
  error: '\x1b[31m',
  fatal: '\x1b[31m',
}

const getLogColor = (level: Level): string =>
  `${LOG_COLOR_MAPPING[level]}%s\x1b[0m`

interface Args {
  queue: QueueUpGroup<LogEvent>
  mixin?: BrowserMixin
  stacktraceInDevelopment?: boolean
}

const base = ({
  queue,
  mixin,
  stacktraceInDevelopment = false,
}: Args): Logger =>
  sentrifiedLogger({
    logger: pino({
      ...commonConfig,
      messageKey: 'message',
      browser: {
        asObject: true,
        serialize: true,
        write: (o) => {
          // Do not log to the console in production
          if (!IS_PROD) {
            const logObject = o as unknown as { level: number }
            const levelNum: number = logObject['level']
            const labels = levels.labels as { [level: number]: Level }
            const levelLabel = labels[levelNum]
            let fn: Level | 'log' = levelLabel || 'log'
            fn = fn === 'fatal' ? 'error' : 'log'
            const consoleColor = getLogColor(levelLabel)
            const objectToLog: Record<string, any> = {
              ...logObject,
              level_label: levelLabel,
            }

            const consoleArgs: any[] = [
              consoleColor,
              `[${levelLabel.toUpperCase()}] Local Development Log`,
              objectToLog,
            ]

            if (stacktraceInDevelopment && levelNum > 30) {
              const stack = (new Error().stack || '')
                .split('\n')
                .map((el) => `    ${el}`)
                .join('\n')
              consoleArgs.push(`\n${LOG_COLOR_MAPPING[levelLabel]}`)
              consoleArgs.push(stack)
            }

            console[fn](...consoleArgs)
          }
        },
        transmit: {
          send: (_level, logEvent) => {
            if (IS_PROD || TRANSMIT_LOCAL_DEV_LOGS) {
              // The below is an async function but we don't want to await it
              queue.add(logEvent)
            }
          },
        },
      },
    }),
    mixin: () => {
      const mixinData: Bindings = typeof mixin === 'function' ? mixin() : {}
      const userId = auth.currentUser?.uid
      if (userId) mixinData['user.id'] = userId

      return mixinData
    },
  })

export default base
