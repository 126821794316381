import merge from 'lodash/merge'
import { AllowedEcsFields } from './types'

const BASE_DEFAULT_FIELDS: AllowedEcsFields = Object.fromEntries(
  Object.entries({
    'ecs.version': '1.6.0',
    'service.version': process.env.RELEASE_VERSION,
    'service.environment': process.env.APP_ENV || process.env.NODE_ENV,
  }).filter(([_, val]) => val !== null && val !== undefined)
)

let baseFields = BASE_DEFAULT_FIELDS

export const initBaseLoggerFields = (fields: AllowedEcsFields): void => {
  baseFields = merge(baseFields, fields)
}

export const resetBaseLoggerFields = (): void => {
  baseFields = BASE_DEFAULT_FIELDS
}

export default baseFields
