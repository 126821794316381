import type { IdTokenResult } from '@firebase/auth'
import type { JWTVerifyResult } from 'jose'
import {
  HASURA_CLAIM_KEY,
  X_HASURA_DEFAULT_ROLE,
  X_HASURA_ALLOWED_ROLES,
  X_HASURA_USER_ID,
  X_HASURA_BILLING_MODE,
} from '@carrotcart/common/lib/constants'
import type { AllowedHasuraRole, BillingMode } from '@carrotcart/common/types'
import {
  DEFAULT_ANONYMOUS_BILLING_MODE,
  ALLOWABLE_BILLING_MODES,
} from '@carrotcart/common/billingApiSharedConstants'

export const ALL_HASURA_ROLES = new Set([
  'user',
  'user_admin',
] as AllowedHasuraRole[])

export const DEFAULT_ROLE: AllowedHasuraRole = 'user'

export interface HasuraClaimDetails {
  [X_HASURA_DEFAULT_ROLE]: AllowedHasuraRole
  [X_HASURA_ALLOWED_ROLES]: AllowedHasuraRole[]
  [X_HASURA_USER_ID]: string
  [X_HASURA_BILLING_MODE]: BillingMode
}

export interface HasuraClaim {
  [HASURA_CLAIM_KEY]: HasuraClaimDetails
}

const generateAllowedRoles = (
  roles: AllowedHasuraRole[]
): AllowedHasuraRole[] => {
  const defaultRoles = new Set([DEFAULT_ROLE])
  return Array.from(
    roles.reduce<Set<AllowedHasuraRole>>((memo, role) => {
      if (!ALL_HASURA_ROLES.has(role)) return memo
      memo.add(role)
      return memo
    }, defaultRoles)
  )
}

const getBillingMode = (billingMode: BillingMode): BillingMode => {
  if (ALLOWABLE_BILLING_MODES.has(billingMode)) return billingMode
  return DEFAULT_ANONYMOUS_BILLING_MODE
}

export const generateHasuraClaim = (
  userId: string,
  roles: AllowedHasuraRole[],
  billingMode: BillingMode = DEFAULT_ANONYMOUS_BILLING_MODE
): HasuraClaim => {
  const allowedRoles = generateAllowedRoles(roles)
  const canHaveBillingTestMode = allowedRoles.includes('user_admin')

  return {
    [HASURA_CLAIM_KEY]: {
      [X_HASURA_DEFAULT_ROLE]: DEFAULT_ROLE,
      [X_HASURA_ALLOWED_ROLES]: generateAllowedRoles(roles),
      [X_HASURA_USER_ID]: userId,
      [X_HASURA_BILLING_MODE]: canHaveBillingTestMode
        ? getBillingMode(billingMode)
        : DEFAULT_ANONYMOUS_BILLING_MODE,
    },
  }
}

export const hasHasuraClaim = (idTokenResult: IdTokenResult): boolean => {
  return !!idTokenResult.claims[HASURA_CLAIM_KEY]
}

export const getRoleFromIdTokenResult = (
  idTokenResult: IdTokenResult,
  role: AllowedHasuraRole | undefined
): AllowedHasuraRole | undefined => {
  if (!role) return undefined

  const hasuraClaimDetails = idTokenResult.claims[
    HASURA_CLAIM_KEY
  ] as unknown as HasuraClaimDetails

  if (!hasuraClaimDetails) return undefined

  const valid = new Set(hasuraClaimDetails[X_HASURA_ALLOWED_ROLES] || []).has(
    role
  )

  return valid ? role : undefined
}

export const getBillingModeFromIdTokenResult = (
  idTokenResult: IdTokenResult
): BillingMode => {
  const hasuraClaimDetails = idTokenResult.claims[
    HASURA_CLAIM_KEY
  ] as unknown as HasuraClaimDetails

  const maybeBillingMode = hasuraClaimDetails?.[X_HASURA_BILLING_MODE]
  if (!maybeBillingMode) return DEFAULT_ANONYMOUS_BILLING_MODE

  return ALLOWABLE_BILLING_MODES.has(maybeBillingMode)
    ? maybeBillingMode
    : DEFAULT_ANONYMOUS_BILLING_MODE
}

export const hasRole = (
  jwt: JWTVerifyResult,
  role: AllowedHasuraRole
): boolean => {
  const hasuraClaimDetails = jwt.payload?.[
    HASURA_CLAIM_KEY
  ] as unknown as HasuraClaimDetails

  if (!hasuraClaimDetails) return false

  return new Set(hasuraClaimDetails[X_HASURA_ALLOWED_ROLES] || []).has(role)
}

export function shouldGenerateHasuraClaims(
  claimKey: HasuraClaimDetails | undefined | null
): boolean {
  if (!claimKey) return true
  if (typeof claimKey !== 'object') return true

  const billingMode = claimKey[X_HASURA_BILLING_MODE]
  const allowedRoles = claimKey[X_HASURA_ALLOWED_ROLES]

  if (!billingMode) return true
  if (billingMode === 'test' && !allowedRoles.includes('user_admin'))
    return true

  return false
}
