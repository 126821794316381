import axios, { AxiosError } from 'axios'
import { StatusCodes } from 'http-status-codes'
import * as Sentry from '@sentry/core'
import {
  ApiErrorCode,
  CUSTOM_TOKEN_API_ENDPOINT,
} from '@carrotcart/common/lib/constants'
import { ApiErrorResponse, UserAuthData } from '@carrotcart/common/types'

export const createCustomToken = async (
  userAuthData: UserAuthData
): Promise<string | 'expired' | undefined> => {
  try {
    const res = await axios.post(CUSTOM_TOKEN_API_ENDPOINT, null, {
      headers: {
        authorization: `Bearer ${userAuthData.accessToken}`,
      },
    })
    const customToken = res?.data?.customToken
    if (!customToken) {
      Sentry.captureMessage(
        'Unexpected response from the API endpoint. No custom token returned.',
        {
          user: {
            id: userAuthData.uid,
          },
          extra: {
            responseData: res.data,
          },
        }
      )
      return undefined
    }

    return customToken
  } catch (err) {
    if (typeof err === 'object' && err !== null) {
      const axiosErr = err as AxiosError<ApiErrorResponse>
      if (
        axiosErr.isAxiosError &&
        axiosErr.response?.status === StatusCodes.UNAUTHORIZED
      ) {
        const errRes = axiosErr.response?.data
        if (errRes?.code === ApiErrorCode.IdTokenExpired) {
          return 'expired'
        }
      }
    }

    Sentry.captureException(err, {
      user: {
        id: userAuthData.uid,
      },
    })
    return undefined
  }
}
