import { SignFn } from '../types'
import base64Encode from './base64Encode'

// Creates a Base64 encoded signature for the request tha the log collection can use
// to verify whether the user is authenticated to send as many client-side logs as it
// would like to
const sign: SignFn = async (data, secret) => {
  const enc = new TextEncoder()
  const algorithm = { name: 'HMAC', hash: 'SHA-512' }

  const key = await crypto.subtle.importKey(
    'raw',
    enc.encode(secret),
    algorithm,
    false,
    ['sign', 'verify']
  )

  const signature = await crypto.subtle.sign(
    algorithm.name,
    key,
    enc.encode(data)
  )

  return base64Encode(signature)
}

export default sign
