import React, { useEffect, useState } from 'react'
import localforage from 'localforage'
import Script from 'next/script'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { DefaultSeo } from 'next-seo'
import DEFAULT_SEO from '@carrotcart/app/next-seo.config'
import { onAuthStateChanged } from '@firebase/auth'
import ErrorBoundary from '@carrotcart/client-common/components/ErrorBoundary'
import { auth } from '@carrotcart/client-common/lib/firebase'
import { GenericAnalyticsForSplit } from '@carrotcart/client-common/types'
import logger from '@carrotcart/client-common/lib/logger/web'
import { init as initBillingMode } from '@carrotcart/client-common/lib/billingMode'
import {
  AllowedEcsFields,
  initBaseLoggerFields,
} from '@carrotcart/client-common/lib/logger'
import * as gtag from '@carrotcart/app/lib/ga'
import SplitProvider from '@carrotcart/client-common/context/SplitProvider'
import SearchQueryProvider from '@carrotcart/app/context/SearchQueryProvider'
import emitSplitTrackingEvents from '@carrotcart/app/lib/emitSplitTrackingEvents'
import useTriggerPageView from '@carrotcart/app/hooks/useTriggerPageView'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'
import {
  IMPERSONATED_VALUE,
  PERSISTED_IMPERSONATION_DATA_KEY,
} from '@carrotcart/app/hooks/useImpersonateUser'
//
import 'react-toastify/dist/ReactToastify.min.css'
import '@carrotcart/client-common/assets/toastify.css'
// Search Engine
import '@carrotcart/app/styles/algolia-custom.css'
//
import '@carrotcart/client-common/assets/styles.css'
import '@carrotcart/app/styles/global.css'
// Tremor
import '@tremor/react/dist/esm/tremor.css'
// Tippy
import '@carrotcart/app/styles/tippy.css'

// create custom script component with tb_customer_id attribute
interface CustomScriptProps extends React.ComponentProps<'script'> {
  tb_customer_id: string
}

const CustomScript: React.FC<CustomScriptProps> = ({ ...props }) => {
  return <Script {...props} />
}

initBillingMode(localforage)

const baseLoggerFields: AllowedEcsFields = {
  'service.name': 'next-web-app',
}

if (typeof navigator !== 'undefined' && navigator.userAgent) {
  baseLoggerFields['user_agent.original'] = navigator.userAgent
}

initBaseLoggerFields(baseLoggerFields)

const setupSplit = async () => {
  try {
    const { default: split } = await import(
      '@carrotcart/client-common/lib/split'
    )
    // As early as possible, set the storage and the logger for split
    split.setStorage(localforage)
    split.setLogger(logger)
  } catch (err) {
    logger.error({ err })
  }
}

setupSplit()

if (typeof window !== 'undefined') {
  // as soon as we have an authenticated user
  // we want to call `analytics.identify()`. This
  // also happens when we go from Anonymous to Logged in
  onAuthStateChanged(auth, async (authUser) => {
    window.analytics.ready(() => {
      if (authUser) {
        let persistedImpersonationData = {}
        let impersonated = false

        try {
          persistedImpersonationData = JSON.parse(
            window.localStorage.getItem(PERSISTED_IMPERSONATION_DATA_KEY) ||
              '{}'
          ) as Record<string, string>
          impersonated = !!(persistedImpersonationData || {})[
            authUser?.uid
          ]?.indexOf(IMPERSONATED_VALUE)
        } catch (err) {
          logger.debug({ err })
        }

        if (impersonated) {
          logger.info({
            message: 'Impersonated user. Not sending analytics.identify()',
            'context.authUser.uid': authUser.uid,
          })
        } else {
          analytics.identify(authUser.uid, {
            email: authUser.email,
            phone: authUser.phoneNumber,
            name: authUser.displayName,
            avatar: authUser.photoURL,
          })
        }
      }
    })
  })
}

// suppress useLayoutEffect warnings when running outside a browser
// https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85#gistcomment-3937704
if (!process.browser) React.useLayoutEffect = React.useEffect

interface AnalyticsInit {
  ready: boolean
  analytics?: GenericAnalyticsForSplit | undefined
  anonymousId?: string
}

const DEFAULT_ANALYTICS_INIT: AnalyticsInit = {
  ready: false,
  analytics: undefined,
}

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const [analyticsInit, setAnalyticsInit] = useState<AnalyticsInit>(
    DEFAULT_ANALYTICS_INIT
  )

  const { persistedAuthUserId } = useAuthUser()

  const [tbCustomerId, setTbCustomerId] = useState<string | null>(null)

  useEffect(() => {
    if (!analyticsInit.ready) return
    ;(async () => {
      const anonId = window.analytics?.user()?.anonymousId()
      // get auth user id
      const uid = persistedAuthUserId
      const customerId = uid || anonId

      setTbCustomerId(customerId)
    })()
  }, [analyticsInit.ready, persistedAuthUserId])

  // trigger page view event for logged in users
  useTriggerPageView()

  // set the analytics anonymousId (if present)
  useEffect(() => {
    if (!analyticsInit.ready) return

    const aidParam = new URLSearchParams(window.location.search).get('aid')
    const anonymousId = aidParam

    if (anonymousId) {
      const prevAnonymousId = window.analytics.user().anonymousId()
      window.analytics.setAnonymousId(anonymousId)

      if (prevAnonymousId && prevAnonymousId !== anonymousId) {
        window.analytics.alias(anonymousId, prevAnonymousId)
      }
    }
  }, [analyticsInit.ready])

  // initialize analytics object
  useEffect(() => {
    window.analytics.ready(() => {
      setAnalyticsInit({
        ready: true,
        analytics: window.analytics,
        // Need better logic for here; this should probably be the value from the cookie
        // but we have issues with putting a top-level middleware that sets this cookie
        // because vercel doesn't really handle multiple Set-Cookie response headers well.
        // Currently, we don't really have any client-side experiments in the web app that would require
        // these to be right. But, if we do start, we should probably address. Adding this in for now
        // for parity with the extension.
        anonymousId: window.analytics?.user()?.anonymousId(),
      })
    })

    // setup GA autolink cross-domain tracking
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/linker
    if (typeof window.ga !== 'undefined') {
      // Loads the Linker plugin
      window.ga('require', 'linker')

      // Instructs the Linker plugin to automatically add linker parameters
      // to all links and forms pointing to the domains listed below
      window.ga('linker:autoLink', [
        'addtocarrot.com',
        'carrot.link',
        'help.carrot.link',
        'crrt.link',
        'crrt.co',
        'chrome.google.com',
      ])
    }

    // trigger page view event for all users
    const handleRouteChange = (url: string) => gtag.pageview(url)
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <ErrorBoundary logger={logger}>
      <SplitProvider
        analyticsReady={analyticsInit.ready}
        analytics={analyticsInit.analytics}
        anonymousId={analyticsInit.anonymousId}
        onAnonymousClientReady={emitSplitTrackingEvents}
      >
        <DefaultSeo {...DEFAULT_SEO} />
        <Head>
          <meta name="msapplication-TileColor" content="#da532c" />

          {/* client hints */}
          <meta
            httpEquiv="Accept-CH"
            content="DPR, Width, Save-Data, Viewport-Width"
          />

          {/* disable mobile client zooming on inputs */}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        </Head>

        {/* GA */}
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />

        {/* Tinybird */}
        {tbCustomerId && (
          <CustomScript
            defer
            src="/vendor/flock.js@1.4.0.js"
            data-host="https://api.us-east.tinybird.co"
            tb_customer_id={tbCustomerId}
            data-token="p.eyJ1IjogIjQ5Y2Q0YWU1LTU3M2MtNDZiMC1hOGM0LWY5MzNiZWM3MDFhNCIsICJpZCI6ICJhNWNmMWE5MS1jYmMwLTQyZmMtYTgyOC05NWU2MThjZmI0ZDMifQ.DhXEz-pgQ9PvWomXlk94vpLNCRUqyP833uriRFzCAGE"
          />
        )}

        {/* Sovrn */}
        <Script
          id="sovrn"
          dangerouslySetInnerHTML={{
            __html: `
            var vglnk = {key: '64a40a637954f3f4b3eedb91cf963511'};
            (function(d, t) {var s = d.createElement(t);
              s.type = 'text/javascript';s.async = true;
              s.src = '//clickcdn.sovrn.com/api/sovrncm.js';
              var r = d.getElementsByTagName(t)[0];
              r.parentNode.insertBefore(s, r);
            }(document, 'script'));
            `,
          }}
        />

        {/* WeCanTrack */}
        <Script
          id="wct"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w, c, t, u) {
              w._wct = w._wct || {}; w._wct = u;
              var s = c.createElement(t);
              s.type = 'text/javascript'; s.async = true; s.src = 'https://wct-2.com/wct.js?type=session';
              var r = c.getElementsByTagName(t)[0];
              r.parentNode.insertBefore(s, r);
            }(window, document, 'script', {
              'uid': 'JswVnJ',
              'proxy': 'https://wct-2.com',
              'auto_tagging': false
            }));
          `,
          }}
        />
        <SearchQueryProvider>
          <Component {...pageProps} />
        </SearchQueryProvider>
      </SplitProvider>
    </ErrorBoundary>
  )
}

export default React.memo(MyApp)
