import { useEffect } from 'react'
import { useRouter } from 'next/router'
import logger from '@carrotcart/client-common/lib/logger/web'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'

const useTriggerPageView = (): void => {
  const router = useRouter()
  const { authUser, loading: authLoading } = useAuthUser()

  // trigger page view event for logged in users
  // (anonymous page views are handled by GA (in _app.tsx))
  useEffect(() => {
    if (!router.isReady) return
    if (authLoading) return
    if (!authUser?.uid) return
    if (typeof window.analytics === 'undefined') return

    // skip analytics page view for certain routes
    if (/\/(og-image|r|api\/)/.test(router.pathname)) return

    window.analytics.ready(() => {
      const pageName = router.pathname

      const properties = {
        asPath: router.asPath,
        pathname: router.pathname,
      }

      logger.debug('triggering page view event to Segment', {
        pageName,
        properties,
      })

      window.analytics.page(pageName, properties)
    })
  }, [
    authLoading,
    authUser?.uid,
    router.asPath,
    router.isReady,
    router.pathname,
  ])
}

export default useTriggerPageView
