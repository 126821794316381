export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  _text: any
  _uuid: any
  bigint: any
  cidr: any
  date: any
  float8: any
  json: any
  jsonb: any
  name: any
  numeric: any
  seed_float: any
  smallint: any
  timestamp: any
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>
  _gt?: Maybe<Scalars['Boolean']>
  _gte?: Maybe<Scalars['Boolean']>
  _in?: Maybe<Array<Scalars['Boolean']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Boolean']>
  _lte?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Scalars['Boolean']>
  _nin?: Maybe<Array<Scalars['Boolean']>>
}

export type CollectionCollaboratorInviteInput = {
  collection_id: Scalars['uuid']
  email?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

export type CollectionInviteOutput = {
  __typename?: 'CollectionInviteOutput'
  collection_invite?: Maybe<Collection_Invite>
  collection_invite_id?: Maybe<Scalars['uuid']>
}

export type ContactConnection = {
  __typename?: 'ContactConnection'
  contact_id: Scalars['String']
  friends_on_platform: Scalars['Int']
}

export type ContactSubscriptionSuggestion = {
  __typename?: 'ContactSubscriptionSuggestion'
  contact_id?: Maybe<Scalars['String']>
  degrees_of_separation: Scalars['Int']
  mutual_connections?: Maybe<Scalars['Int']>
  user?: Maybe<User>
  user_id: Scalars['String']
}

export type CreateSubscriptionOutput = {
  __typename?: 'CreateSubscriptionOutput'
  user_subscription?: Maybe<User_Subscription>
  user_subscription_id: Scalars['uuid']
}

export type CreatorSubscriberChartDataOutput = {
  __typename?: 'CreatorSubscriberChartDataOutput'
  day?: Maybe<Scalars['String']>
  paid_subscribers?: Maybe<Scalars['Int']>
  subscribers?: Maybe<Scalars['Int']>
  total_paid_subscribers?: Maybe<Scalars['Int']>
  total_subscribers?: Maybe<Scalars['Int']>
}

export type FeedItemLikeInput = {
  feed_item_id: Scalars['uuid']
  value: Scalars['Boolean']
}

export type FeedItemLikeOutput = {
  __typename?: 'FeedItemLikeOutput'
  feed_item_like?: Maybe<Feed_Item_Like>
  feed_item_like_id: Scalars['uuid']
}

export type FullyUnsubscribeOutput = {
  __typename?: 'FullyUnsubscribeOutput'
  user_subscription_id: Scalars['String']
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>
  _gt?: Maybe<Scalars['Int']>
  _gte?: Maybe<Scalars['Int']>
  _in?: Maybe<Array<Scalars['Int']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Int']>
  _lte?: Maybe<Scalars['Int']>
  _neq?: Maybe<Scalars['Int']>
  _nin?: Maybe<Array<Scalars['Int']>>
}

export enum RecurringInterval {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Year = 'year',
}

export type SearchProductsOutput = {
  __typename?: 'SearchProductsOutput'
  id?: Maybe<Scalars['uuid']>
  pagination?: Maybe<Scalars['String']>
  search_query?: Maybe<Search_Query>
}

export type ShoppingProductOutput = {
  __typename?: 'ShoppingProductOutput'
  delivery?: Maybe<Scalars['String']>
  extracted_price?: Maybe<Scalars['Float']>
  link?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['String']>
  product_link?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  tag?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SimilarProductOutput = {
  __typename?: 'SimilarProductOutput'
  cart_Item?: Maybe<Cart_Item>
  cart_item_id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  max_take_rate?: Maybe<Scalars['Int']>
  original_link?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  position?: Maybe<Scalars['Int']>
  price?: Maybe<SimilarProductPrice>
  shop_item?: Maybe<Shop_Item>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_popularity?: Maybe<Scalars['Int']>
  source?: Maybe<Scalars['String']>
  source_icon?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
}

export type SimilarProductPrice = {
  __typename?: 'SimilarProductPrice'
  currency?: Maybe<Scalars['String']>
  extracted_value?: Maybe<Scalars['Int']>
  value?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['String']>>
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['String']>>
  _eq?: Maybe<Array<Scalars['String']>>
  _gt?: Maybe<Array<Scalars['String']>>
  _gte?: Maybe<Array<Scalars['String']>>
  _in?: Maybe<Array<Array<Scalars['String']>>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Array<Scalars['String']>>
  _lte?: Maybe<Array<Scalars['String']>>
  _neq?: Maybe<Array<Scalars['String']>>
  _nin?: Maybe<Array<Array<Scalars['String']>>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>
  _gt?: Maybe<Scalars['String']>
  _gte?: Maybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>
  _in?: Maybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>
  _is_null?: Maybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>
  _lt?: Maybe<Scalars['String']>
  _lte?: Maybe<Scalars['String']>
  _neq?: Maybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>
  _nin?: Maybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  currency: Scalars['String']
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  interval: RecurringInterval
  name: Scalars['String']
  trial_period_days?: Maybe<Scalars['Int']>
  unit_amount: Scalars['Int']
}

export type WrappedNotificationCenterItem = {
  __typename?: 'WrappedNotificationCenterItem'
  body?: Maybe<Scalars['String']>
  created_at: Scalars['String']
  id: Scalars['String']
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  navigation?: Maybe<Scalars['json']>
  read_at?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id: Scalars['Int']
  type: Scalars['String']
}

/** Account deletion reasons enum table */
export type Account_Deletion_Reason = {
  __typename?: 'account_deletion_reason'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "account_deletion_reason" */
export type Account_Deletion_Reason_Aggregate = {
  __typename?: 'account_deletion_reason_aggregate'
  aggregate?: Maybe<Account_Deletion_Reason_Aggregate_Fields>
  nodes: Array<Account_Deletion_Reason>
}

/** aggregate fields of "account_deletion_reason" */
export type Account_Deletion_Reason_Aggregate_Fields = {
  __typename?: 'account_deletion_reason_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Account_Deletion_Reason_Max_Fields>
  min?: Maybe<Account_Deletion_Reason_Min_Fields>
}

/** aggregate fields of "account_deletion_reason" */
export type Account_Deletion_Reason_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "account_deletion_reason". All fields are combined with a logical 'AND'. */
export type Account_Deletion_Reason_Bool_Exp = {
  _and?: Maybe<Array<Account_Deletion_Reason_Bool_Exp>>
  _not?: Maybe<Account_Deletion_Reason_Bool_Exp>
  _or?: Maybe<Array<Account_Deletion_Reason_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "account_deletion_reason" */
export enum Account_Deletion_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountDeletionReasonPkey = 'account_deletion_reason_pkey',
}

/** input type for inserting data into table "account_deletion_reason" */
export type Account_Deletion_Reason_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Account_Deletion_Reason_Max_Fields = {
  __typename?: 'account_deletion_reason_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Account_Deletion_Reason_Min_Fields = {
  __typename?: 'account_deletion_reason_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "account_deletion_reason" */
export type Account_Deletion_Reason_Mutation_Response = {
  __typename?: 'account_deletion_reason_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Account_Deletion_Reason>
}

/** on_conflict condition type for table "account_deletion_reason" */
export type Account_Deletion_Reason_On_Conflict = {
  constraint: Account_Deletion_Reason_Constraint
  update_columns?: Array<Account_Deletion_Reason_Update_Column>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

/** Ordering options when selecting data from "account_deletion_reason". */
export type Account_Deletion_Reason_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: account_deletion_reason */
export type Account_Deletion_Reason_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "account_deletion_reason" */
export enum Account_Deletion_Reason_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "account_deletion_reason" */
export type Account_Deletion_Reason_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "account_deletion_reason" */
export type Account_Deletion_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Deletion_Reason_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Account_Deletion_Reason_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "account_deletion_reason" */
export enum Account_Deletion_Reason_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Account_Deletion_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Account_Deletion_Reason_Set_Input>
  /** filter the rows which have to be updated */
  where: Account_Deletion_Reason_Bool_Exp
}

/** columns and relationships of "affiliate" */
export type Affiliate = {
  __typename?: 'affiliate'
  description?: Maybe<Scalars['String']>
  /** An object relationship */
  meta?: Maybe<Affiliate_Meta>
  value: Scalars['String']
}

/** This table maps the affillite with a shop_key and advertiser_id */
export type Affiliate_Advertiser_Id = {
  __typename?: 'affiliate_advertiser_id'
  advertiser_id: Scalars['String']
  affiliate: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Aggregate = {
  __typename?: 'affiliate_advertiser_id_aggregate'
  aggregate?: Maybe<Affiliate_Advertiser_Id_Aggregate_Fields>
  nodes: Array<Affiliate_Advertiser_Id>
}

/** aggregate fields of "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Aggregate_Fields = {
  __typename?: 'affiliate_advertiser_id_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Affiliate_Advertiser_Id_Max_Fields>
  min?: Maybe<Affiliate_Advertiser_Id_Min_Fields>
}

/** aggregate fields of "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "affiliate_advertiser_id". All fields are combined with a logical 'AND'. */
export type Affiliate_Advertiser_Id_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Advertiser_Id_Bool_Exp>>
  _not?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Advertiser_Id_Bool_Exp>>
  advertiser_id?: Maybe<String_Comparison_Exp>
  affiliate?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "affiliate_advertiser_id" */
export enum Affiliate_Advertiser_Id_Constraint {
  /** unique or primary key constraint on columns "shop_key", "affiliate", "advertiser_id" */
  AffiliateAdvertiserIdAffiliateShopKeyAdvertiserIdKey = 'affiliate_advertiser_id_affiliate_shop_key_advertiser_id_key',
  /** unique or primary key constraint on columns "id" */
  AffiliateAdvertiserIdPkey = 'affiliate_advertiser_id_pkey',
}

/** input type for inserting data into table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Insert_Input = {
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Affiliate_Advertiser_Id_Max_Fields = {
  __typename?: 'affiliate_advertiser_id_max_fields'
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Affiliate_Advertiser_Id_Min_Fields = {
  __typename?: 'affiliate_advertiser_id_min_fields'
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Mutation_Response = {
  __typename?: 'affiliate_advertiser_id_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate_Advertiser_Id>
}

/** on_conflict condition type for table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_On_Conflict = {
  constraint: Affiliate_Advertiser_Id_Constraint
  update_columns?: Array<Affiliate_Advertiser_Id_Update_Column>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate_advertiser_id". */
export type Affiliate_Advertiser_Id_Order_By = {
  advertiser_id?: Maybe<Order_By>
  affiliate?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: affiliate_advertiser_id */
export type Affiliate_Advertiser_Id_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "affiliate_advertiser_id" */
export enum Affiliate_Advertiser_Id_Select_Column {
  /** column name */
  AdvertiserId = 'advertiser_id',
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Set_Input = {
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "affiliate_advertiser_id" */
export type Affiliate_Advertiser_Id_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Advertiser_Id_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Advertiser_Id_Stream_Cursor_Value_Input = {
  advertiser_id?: Maybe<Scalars['String']>
  affiliate?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "affiliate_advertiser_id" */
export enum Affiliate_Advertiser_Id_Update_Column {
  /** column name */
  AdvertiserId = 'advertiser_id',
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Affiliate_Advertiser_Id_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Advertiser_Id_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Advertiser_Id_Bool_Exp
}

/** aggregated selection of "affiliate" */
export type Affiliate_Aggregate = {
  __typename?: 'affiliate_aggregate'
  aggregate?: Maybe<Affiliate_Aggregate_Fields>
  nodes: Array<Affiliate>
}

/** aggregate fields of "affiliate" */
export type Affiliate_Aggregate_Fields = {
  __typename?: 'affiliate_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Affiliate_Max_Fields>
  min?: Maybe<Affiliate_Min_Fields>
}

/** aggregate fields of "affiliate" */
export type Affiliate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "affiliate". All fields are combined with a logical 'AND'. */
export type Affiliate_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Bool_Exp>>
  _not?: Maybe<Affiliate_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  meta?: Maybe<Affiliate_Meta_Bool_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** columns and relationships of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary = {
  __typename?: 'affiliate_commission_summary'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Aggregate = {
  __typename?: 'affiliate_commission_summary_aggregate'
  aggregate?: Maybe<Affiliate_Commission_Summary_Aggregate_Fields>
  nodes: Array<Affiliate_Commission_Summary>
}

/** aggregate fields of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Aggregate_Fields = {
  __typename?: 'affiliate_commission_summary_aggregate_fields'
  avg?: Maybe<Affiliate_Commission_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Affiliate_Commission_Summary_Max_Fields>
  min?: Maybe<Affiliate_Commission_Summary_Min_Fields>
  stddev?: Maybe<Affiliate_Commission_Summary_Stddev_Fields>
  stddev_pop?: Maybe<Affiliate_Commission_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Affiliate_Commission_Summary_Stddev_Samp_Fields>
  sum?: Maybe<Affiliate_Commission_Summary_Sum_Fields>
  var_pop?: Maybe<Affiliate_Commission_Summary_Var_Pop_Fields>
  var_samp?: Maybe<Affiliate_Commission_Summary_Var_Samp_Fields>
  variance?: Maybe<Affiliate_Commission_Summary_Variance_Fields>
}

/** aggregate fields of "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Affiliate_Commission_Summary_Avg_Fields = {
  __typename?: 'affiliate_commission_summary_avg_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "affiliate_commission_summary". All fields are combined with a logical 'AND'. */
export type Affiliate_Commission_Summary_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Commission_Summary_Bool_Exp>>
  _not?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Commission_Summary_Bool_Exp>>
  count_commission_cleared?: Maybe<Bigint_Comparison_Exp>
  count_commission_pending?: Maybe<Bigint_Comparison_Exp>
  sum_commission?: Maybe<Numeric_Comparison_Exp>
  sum_commission_cleared?: Maybe<Numeric_Comparison_Exp>
  sum_commission_pending?: Maybe<Numeric_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Affiliate_Commission_Summary_Max_Fields = {
  __typename?: 'affiliate_commission_summary_max_fields'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Affiliate_Commission_Summary_Min_Fields = {
  __typename?: 'affiliate_commission_summary_min_fields'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "affiliate_commission_summary". */
export type Affiliate_Commission_Summary_Order_By = {
  count_commission_cleared?: Maybe<Order_By>
  count_commission_pending?: Maybe<Order_By>
  sum_commission?: Maybe<Order_By>
  sum_commission_cleared?: Maybe<Order_By>
  sum_commission_pending?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "affiliate_commission_summary" */
export enum Affiliate_Commission_Summary_Select_Column {
  /** column name */
  CountCommissionCleared = 'count_commission_cleared',
  /** column name */
  CountCommissionPending = 'count_commission_pending',
  /** column name */
  SumCommission = 'sum_commission',
  /** column name */
  SumCommissionCleared = 'sum_commission_cleared',
  /** column name */
  SumCommissionPending = 'sum_commission_pending',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Affiliate_Commission_Summary_Stddev_Fields = {
  __typename?: 'affiliate_commission_summary_stddev_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Affiliate_Commission_Summary_Stddev_Pop_Fields = {
  __typename?: 'affiliate_commission_summary_stddev_pop_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Affiliate_Commission_Summary_Stddev_Samp_Fields = {
  __typename?: 'affiliate_commission_summary_stddev_samp_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "affiliate_commission_summary" */
export type Affiliate_Commission_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Commission_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Commission_Summary_Stream_Cursor_Value_Input = {
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Affiliate_Commission_Summary_Sum_Fields = {
  __typename?: 'affiliate_commission_summary_sum_fields'
  count_commission_cleared?: Maybe<Scalars['bigint']>
  count_commission_pending?: Maybe<Scalars['bigint']>
  sum_commission?: Maybe<Scalars['numeric']>
  sum_commission_cleared?: Maybe<Scalars['numeric']>
  sum_commission_pending?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Affiliate_Commission_Summary_Var_Pop_Fields = {
  __typename?: 'affiliate_commission_summary_var_pop_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Affiliate_Commission_Summary_Var_Samp_Fields = {
  __typename?: 'affiliate_commission_summary_var_samp_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Affiliate_Commission_Summary_Variance_Fields = {
  __typename?: 'affiliate_commission_summary_variance_fields'
  count_commission_cleared?: Maybe<Scalars['Float']>
  count_commission_pending?: Maybe<Scalars['Float']>
  sum_commission?: Maybe<Scalars['Float']>
  sum_commission_cleared?: Maybe<Scalars['Float']>
  sum_commission_pending?: Maybe<Scalars['Float']>
}

/** unique or primary key constraints on table "affiliate" */
export enum Affiliate_Constraint {
  /** unique or primary key constraint on columns "value" */
  AffiliatePkey = 'affiliate_pkey',
}

export enum Affiliate_Enum {
  /** Amazon affiliate network */
  Amazon = 'amazon',
  /** AvantLink affiliate network */
  Avantlink = 'avantlink',
  /** AWIN affiliate network */
  Awin = 'awin',
  /** Commission Junction affiliate network */
  Cj = 'cj',
  /** Ebay affiliate network */
  Ebay = 'ebay',
  /** FlexOffers affiliate network */
  FlexOffers = 'flex_offers',
  /** Impact affiliate network */
  Impact = 'impact',
  /** LinkConnector affiliate network */
  Linkconnector = 'linkconnector',
  /** Partnerize affiliate network */
  Partnerize = 'partnerize',
  /** Pepperjam affiliate network */
  Pepperjam = 'pepperjam',
  /** Rakuten affiliate network */
  Rakuten = 'rakuten',
  /** Share-A-Sale affiliate network */
  ShareASale = 'share_a_sale',
  /** Sovrn affiliate network */
  Sovrn = 'sovrn',
}

/** Boolean expression to compare columns of type "affiliate_enum". All fields are combined with logical 'AND'. */
export type Affiliate_Enum_Comparison_Exp = {
  _eq?: Maybe<Affiliate_Enum>
  _in?: Maybe<Array<Affiliate_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Affiliate_Enum>
  _nin?: Maybe<Array<Affiliate_Enum>>
}

/** input type for inserting data into table "affiliate" */
export type Affiliate_Insert_Input = {
  description?: Maybe<Scalars['String']>
  meta?: Maybe<Affiliate_Meta_Obj_Rel_Insert_Input>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Affiliate_Max_Fields = {
  __typename?: 'affiliate_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** columns and relationships of "affiliate_meta" */
export type Affiliate_Meta = {
  __typename?: 'affiliate_meta'
  affiliate: Affiliate_Enum
  created_at: Scalars['timestamptz']
  data: Scalars['jsonb']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "affiliate_meta" */
export type Affiliate_MetaDataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "affiliate_meta" */
export type Affiliate_Meta_Aggregate = {
  __typename?: 'affiliate_meta_aggregate'
  aggregate?: Maybe<Affiliate_Meta_Aggregate_Fields>
  nodes: Array<Affiliate_Meta>
}

/** aggregate fields of "affiliate_meta" */
export type Affiliate_Meta_Aggregate_Fields = {
  __typename?: 'affiliate_meta_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Affiliate_Meta_Max_Fields>
  min?: Maybe<Affiliate_Meta_Min_Fields>
}

/** aggregate fields of "affiliate_meta" */
export type Affiliate_Meta_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Meta_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Meta_Append_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "affiliate_meta". All fields are combined with a logical 'AND'. */
export type Affiliate_Meta_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Meta_Bool_Exp>>
  _not?: Maybe<Affiliate_Meta_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Meta_Bool_Exp>>
  affiliate?: Maybe<Affiliate_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  data?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "affiliate_meta" */
export enum Affiliate_Meta_Constraint {
  /** unique or primary key constraint on columns "affiliate" */
  AffiliateMetaPkey = 'affiliate_meta_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Affiliate_Meta_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Affiliate_Meta_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Affiliate_Meta_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "affiliate_meta" */
export type Affiliate_Meta_Insert_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Affiliate_Meta_Max_Fields = {
  __typename?: 'affiliate_meta_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Affiliate_Meta_Min_Fields = {
  __typename?: 'affiliate_meta_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "affiliate_meta" */
export type Affiliate_Meta_Mutation_Response = {
  __typename?: 'affiliate_meta_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate_Meta>
}

/** input type for inserting object relation for remote table "affiliate_meta" */
export type Affiliate_Meta_Obj_Rel_Insert_Input = {
  data: Affiliate_Meta_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Affiliate_Meta_On_Conflict>
}

/** on_conflict condition type for table "affiliate_meta" */
export type Affiliate_Meta_On_Conflict = {
  constraint: Affiliate_Meta_Constraint
  update_columns?: Array<Affiliate_Meta_Update_Column>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate_meta". */
export type Affiliate_Meta_Order_By = {
  affiliate?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  data?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: affiliate_meta */
export type Affiliate_Meta_Pk_Columns_Input = {
  affiliate: Affiliate_Enum
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Meta_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "affiliate_meta" */
export enum Affiliate_Meta_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "affiliate_meta" */
export type Affiliate_Meta_Set_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "affiliate_meta" */
export type Affiliate_Meta_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Meta_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Meta_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "affiliate_meta" */
export enum Affiliate_Meta_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Affiliate_Meta_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Affiliate_Meta_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Affiliate_Meta_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Affiliate_Meta_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Affiliate_Meta_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Affiliate_Meta_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Meta_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Meta_Bool_Exp
}

/** aggregate min on columns */
export type Affiliate_Min_Fields = {
  __typename?: 'affiliate_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "affiliate" */
export type Affiliate_Mutation_Response = {
  __typename?: 'affiliate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate>
}

/** input type for inserting object relation for remote table "affiliate" */
export type Affiliate_Obj_Rel_Insert_Input = {
  data: Affiliate_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Affiliate_On_Conflict>
}

/** on_conflict condition type for table "affiliate" */
export type Affiliate_On_Conflict = {
  constraint: Affiliate_Constraint
  update_columns?: Array<Affiliate_Update_Column>
  where?: Maybe<Affiliate_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate". */
export type Affiliate_Order_By = {
  description?: Maybe<Order_By>
  meta?: Maybe<Affiliate_Meta_Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: affiliate */
export type Affiliate_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "affiliate" */
export enum Affiliate_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "affiliate" */
export type Affiliate_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "affiliate" */
export type Affiliate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** This table stores the trasactions done of every affiliate */
export type Affiliate_Transaction = {
  __typename?: 'affiliate_transaction'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  affiliate: Affiliate_Enum
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at: Scalars['timestamptz']
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata: Scalars['jsonb']
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date: Scalars['timestamptz']
  transaction_id: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
}

/** This table stores the trasactions done of every affiliate */
export type Affiliate_TransactionMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate = {
  __typename?: 'affiliate_transaction_aggregate'
  aggregate?: Maybe<Affiliate_Transaction_Aggregate_Fields>
  nodes: Array<Affiliate_Transaction>
}

export type Affiliate_Transaction_Aggregate_Bool_Exp = {
  count?: Maybe<Affiliate_Transaction_Aggregate_Bool_Exp_Count>
}

export type Affiliate_Transaction_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Affiliate_Transaction_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate_Fields = {
  __typename?: 'affiliate_transaction_aggregate_fields'
  avg?: Maybe<Affiliate_Transaction_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Affiliate_Transaction_Max_Fields>
  min?: Maybe<Affiliate_Transaction_Min_Fields>
  stddev?: Maybe<Affiliate_Transaction_Stddev_Fields>
  stddev_pop?: Maybe<Affiliate_Transaction_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Affiliate_Transaction_Stddev_Samp_Fields>
  sum?: Maybe<Affiliate_Transaction_Sum_Fields>
  var_pop?: Maybe<Affiliate_Transaction_Var_Pop_Fields>
  var_samp?: Maybe<Affiliate_Transaction_Var_Samp_Fields>
  variance?: Maybe<Affiliate_Transaction_Variance_Fields>
}

/** aggregate fields of "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "affiliate_transaction" */
export type Affiliate_Transaction_Aggregate_Order_By = {
  avg?: Maybe<Affiliate_Transaction_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Affiliate_Transaction_Max_Order_By>
  min?: Maybe<Affiliate_Transaction_Min_Order_By>
  stddev?: Maybe<Affiliate_Transaction_Stddev_Order_By>
  stddev_pop?: Maybe<Affiliate_Transaction_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Affiliate_Transaction_Stddev_Samp_Order_By>
  sum?: Maybe<Affiliate_Transaction_Sum_Order_By>
  var_pop?: Maybe<Affiliate_Transaction_Var_Pop_Order_By>
  var_samp?: Maybe<Affiliate_Transaction_Var_Samp_Order_By>
  variance?: Maybe<Affiliate_Transaction_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Transaction_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "affiliate_transaction" */
export type Affiliate_Transaction_Arr_Rel_Insert_Input = {
  data: Array<Affiliate_Transaction_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Affiliate_Transaction_On_Conflict>
}

/** aggregate avg on columns */
export type Affiliate_Transaction_Avg_Fields = {
  __typename?: 'affiliate_transaction_avg_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Avg_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "affiliate_transaction". All fields are combined with a logical 'AND'. */
export type Affiliate_Transaction_Bool_Exp = {
  _and?: Maybe<Array<Affiliate_Transaction_Bool_Exp>>
  _not?: Maybe<Affiliate_Transaction_Bool_Exp>
  _or?: Maybe<Array<Affiliate_Transaction_Bool_Exp>>
  adjusted_commission?: Maybe<Numeric_Comparison_Exp>
  affiliate?: Maybe<Affiliate_Enum_Comparison_Exp>
  clicking_user_id?: Maybe<String_Comparison_Exp>
  commission?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  currency?: Maybe<String_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<String_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  order_id?: Maybe<String_Comparison_Exp>
  redeemed_at?: Maybe<Timestamptz_Comparison_Exp>
  redirect_ids_id?: Maybe<Uuid_Comparison_Exp>
  sale_amount?: Maybe<Numeric_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  transaction_date?: Maybe<Timestamptz_Comparison_Exp>
  transaction_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "affiliate_transaction" */
export enum Affiliate_Transaction_Constraint {
  /** unique or primary key constraint on columns "affiliate", "transaction_id" */
  AffiliateTransactionAffiliateTransactionIdKey = 'affiliate_transaction_affiliate_transaction_id_key',
  /** unique or primary key constraint on columns "id" */
  AffiliateTransactionPkey = 'affiliate_transaction_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Affiliate_Transaction_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Affiliate_Transaction_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Affiliate_Transaction_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "affiliate_transaction" */
export type Affiliate_Transaction_Inc_Input = {
  commission?: Maybe<Scalars['numeric']>
  sale_amount?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "affiliate_transaction" */
export type Affiliate_Transaction_Insert_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Affiliate_Transaction_Max_Fields = {
  __typename?: 'affiliate_transaction_max_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Max_Order_By = {
  clicking_user_id?: Maybe<Order_By>
  commission?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  redirect_ids_id?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  status?: Maybe<Order_By>
  transaction_date?: Maybe<Order_By>
  transaction_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Affiliate_Transaction_Min_Fields = {
  __typename?: 'affiliate_transaction_min_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Min_Order_By = {
  clicking_user_id?: Maybe<Order_By>
  commission?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  redirect_ids_id?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  status?: Maybe<Order_By>
  transaction_date?: Maybe<Order_By>
  transaction_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "affiliate_transaction" */
export type Affiliate_Transaction_Mutation_Response = {
  __typename?: 'affiliate_transaction_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Affiliate_Transaction>
}

/** on_conflict condition type for table "affiliate_transaction" */
export type Affiliate_Transaction_On_Conflict = {
  constraint: Affiliate_Transaction_Constraint
  update_columns?: Array<Affiliate_Transaction_Update_Column>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

/** Ordering options when selecting data from "affiliate_transaction". */
export type Affiliate_Transaction_Order_By = {
  adjusted_commission?: Maybe<Order_By>
  affiliate?: Maybe<Order_By>
  clicking_user_id?: Maybe<Order_By>
  commission?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  redirect_ids_id?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  status?: Maybe<Order_By>
  transaction_date?: Maybe<Order_By>
  transaction_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
}

/** primary key columns input for table: affiliate_transaction */
export type Affiliate_Transaction_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Affiliate_Transaction_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "affiliate_transaction" */
export enum Affiliate_Transaction_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  ClickingUserId = 'clicking_user_id',
  /** column name */
  Commission = 'commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RedirectIdsId = 'redirect_ids_id',
  /** column name */
  SaleAmount = 'sale_amount',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "affiliate_transaction" */
export type Affiliate_Transaction_Set_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Affiliate_Transaction_Stddev_Fields = {
  __typename?: 'affiliate_transaction_stddev_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Stddev_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Affiliate_Transaction_Stddev_Pop_Fields = {
  __typename?: 'affiliate_transaction_stddev_pop_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Stddev_Pop_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Affiliate_Transaction_Stddev_Samp_Fields = {
  __typename?: 'affiliate_transaction_stddev_samp_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Stddev_Samp_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** Streaming cursor of the table "affiliate_transaction" */
export type Affiliate_Transaction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Affiliate_Transaction_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Affiliate_Transaction_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  clicking_user_id?: Maybe<Scalars['String']>
  commission?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  order_id?: Maybe<Scalars['String']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  redirect_ids_id?: Maybe<Scalars['uuid']>
  sale_amount?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transaction_date?: Maybe<Scalars['timestamptz']>
  transaction_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Affiliate_Transaction_Sum_Fields = {
  __typename?: 'affiliate_transaction_sum_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['numeric']>
  sale_amount?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Sum_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** update columns of table "affiliate_transaction" */
export enum Affiliate_Transaction_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  ClickingUserId = 'clicking_user_id',
  /** column name */
  Commission = 'commission',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RedirectIdsId = 'redirect_ids_id',
  /** column name */
  SaleAmount = 'sale_amount',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Status = 'status',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Affiliate_Transaction_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Affiliate_Transaction_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Affiliate_Transaction_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Affiliate_Transaction_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Affiliate_Transaction_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Affiliate_Transaction_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Affiliate_Transaction_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Transaction_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Transaction_Bool_Exp
}

/** aggregate var_pop on columns */
export type Affiliate_Transaction_Var_Pop_Fields = {
  __typename?: 'affiliate_transaction_var_pop_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Var_Pop_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Affiliate_Transaction_Var_Samp_Fields = {
  __typename?: 'affiliate_transaction_var_samp_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Var_Samp_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Affiliate_Transaction_Variance_Fields = {
  __typename?: 'affiliate_transaction_variance_fields'
  /** A computed field on `affiliate_transaction` that calculates the adjusted commission for a user */
  adjusted_commission?: Maybe<Scalars['numeric']>
  commission?: Maybe<Scalars['Float']>
  sale_amount?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "affiliate_transaction" */
export type Affiliate_Transaction_Variance_Order_By = {
  commission?: Maybe<Order_By>
  sale_amount?: Maybe<Order_By>
}

/** update columns of table "affiliate" */
export enum Affiliate_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Affiliate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Affiliate_Set_Input>
  /** filter the rows which have to be updated */
  where: Affiliate_Bool_Exp
}

/** columns and relationships of "amazon_categories" */
export type Amazon_Categories = {
  __typename?: 'amazon_categories'
  name?: Maybe<Scalars['String']>
}

/** aggregated selection of "amazon_categories" */
export type Amazon_Categories_Aggregate = {
  __typename?: 'amazon_categories_aggregate'
  aggregate?: Maybe<Amazon_Categories_Aggregate_Fields>
  nodes: Array<Amazon_Categories>
}

/** aggregate fields of "amazon_categories" */
export type Amazon_Categories_Aggregate_Fields = {
  __typename?: 'amazon_categories_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Amazon_Categories_Max_Fields>
  min?: Maybe<Amazon_Categories_Min_Fields>
}

/** aggregate fields of "amazon_categories" */
export type Amazon_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Amazon_Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "amazon_categories". All fields are combined with a logical 'AND'. */
export type Amazon_Categories_Bool_Exp = {
  _and?: Maybe<Array<Amazon_Categories_Bool_Exp>>
  _not?: Maybe<Amazon_Categories_Bool_Exp>
  _or?: Maybe<Array<Amazon_Categories_Bool_Exp>>
  name?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Amazon_Categories_Max_Fields = {
  __typename?: 'amazon_categories_max_fields'
  name?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Amazon_Categories_Min_Fields = {
  __typename?: 'amazon_categories_min_fields'
  name?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "amazon_categories". */
export type Amazon_Categories_Order_By = {
  name?: Maybe<Order_By>
}

/** select columns of table "amazon_categories" */
export enum Amazon_Categories_Select_Column {
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "amazon_categories" */
export type Amazon_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Amazon_Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Amazon_Categories_Stream_Cursor_Value_Input = {
  name?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>
  _gt?: Maybe<Scalars['bigint']>
  _gte?: Maybe<Scalars['bigint']>
  _in?: Maybe<Array<Scalars['bigint']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['bigint']>
  _lte?: Maybe<Scalars['bigint']>
  _neq?: Maybe<Scalars['bigint']>
  _nin?: Maybe<Array<Scalars['bigint']>>
}

/** A table to hold the value of billing mode that we can have in our database */
export type Billing_Mode = {
  __typename?: 'billing_mode'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "billing_mode" */
export type Billing_Mode_Aggregate = {
  __typename?: 'billing_mode_aggregate'
  aggregate?: Maybe<Billing_Mode_Aggregate_Fields>
  nodes: Array<Billing_Mode>
}

/** aggregate fields of "billing_mode" */
export type Billing_Mode_Aggregate_Fields = {
  __typename?: 'billing_mode_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Mode_Max_Fields>
  min?: Maybe<Billing_Mode_Min_Fields>
}

/** aggregate fields of "billing_mode" */
export type Billing_Mode_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Mode_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "billing_mode". All fields are combined with a logical 'AND'. */
export type Billing_Mode_Bool_Exp = {
  _and?: Maybe<Array<Billing_Mode_Bool_Exp>>
  _not?: Maybe<Billing_Mode_Bool_Exp>
  _or?: Maybe<Array<Billing_Mode_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_mode" */
export enum Billing_Mode_Constraint {
  /** unique or primary key constraint on columns "value" */
  BillingModePkey = 'billing_mode_pkey',
}

export enum Billing_Mode_Enum {
  Live = 'live',
  Test = 'test',
}

/** Boolean expression to compare columns of type "billing_mode_enum". All fields are combined with logical 'AND'. */
export type Billing_Mode_Enum_Comparison_Exp = {
  _eq?: Maybe<Billing_Mode_Enum>
  _in?: Maybe<Array<Billing_Mode_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Billing_Mode_Enum>
  _nin?: Maybe<Array<Billing_Mode_Enum>>
}

/** input type for inserting data into table "billing_mode" */
export type Billing_Mode_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Mode_Max_Fields = {
  __typename?: 'billing_mode_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Mode_Min_Fields = {
  __typename?: 'billing_mode_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_mode" */
export type Billing_Mode_Mutation_Response = {
  __typename?: 'billing_mode_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Mode>
}

/** on_conflict condition type for table "billing_mode" */
export type Billing_Mode_On_Conflict = {
  constraint: Billing_Mode_Constraint
  update_columns?: Array<Billing_Mode_Update_Column>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

/** Ordering options when selecting data from "billing_mode". */
export type Billing_Mode_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: billing_mode */
export type Billing_Mode_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "billing_mode" */
export enum Billing_Mode_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "billing_mode" */
export type Billing_Mode_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "billing_mode" */
export type Billing_Mode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Mode_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Mode_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "billing_mode" */
export enum Billing_Mode_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Billing_Mode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Mode_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Mode_Bool_Exp
}

/** A table to hold connected account  */
export type Billing_Platform_Connected_Account = {
  __typename?: 'billing_platform_connected_account'
  account_id: Scalars['String']
  billing_mode: Billing_Mode_Enum
  created_at: Scalars['timestamptz']
  platform_type: Scalars['String']
  status: Billing_Platform_Connected_Account_Status_Enum
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Aggregate = {
  __typename?: 'billing_platform_connected_account_aggregate'
  aggregate?: Maybe<Billing_Platform_Connected_Account_Aggregate_Fields>
  nodes: Array<Billing_Platform_Connected_Account>
}

/** aggregate fields of "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Aggregate_Fields = {
  __typename?: 'billing_platform_connected_account_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Platform_Connected_Account_Max_Fields>
  min?: Maybe<Billing_Platform_Connected_Account_Min_Fields>
}

/** aggregate fields of "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "billing_platform_connected_account". All fields are combined with a logical 'AND'. */
export type Billing_Platform_Connected_Account_Bool_Exp = {
  _and?: Maybe<Array<Billing_Platform_Connected_Account_Bool_Exp>>
  _not?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
  _or?: Maybe<Array<Billing_Platform_Connected_Account_Bool_Exp>>
  account_id?: Maybe<String_Comparison_Exp>
  billing_mode?: Maybe<Billing_Mode_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  platform_type?: Maybe<String_Comparison_Exp>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_platform_connected_account" */
export enum Billing_Platform_Connected_Account_Constraint {
  /** unique or primary key constraint on columns "platform_type", "user_id", "billing_mode" */
  BillingPlatformConnectedAccountPkey = 'billing_platform_connected_account_pkey',
}

/** input type for inserting data into table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Insert_Input = {
  account_id?: Maybe<Scalars['String']>
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Platform_Connected_Account_Max_Fields = {
  __typename?: 'billing_platform_connected_account_max_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Platform_Connected_Account_Min_Fields = {
  __typename?: 'billing_platform_connected_account_min_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Mutation_Response = {
  __typename?: 'billing_platform_connected_account_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Platform_Connected_Account>
}

/** on_conflict condition type for table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_On_Conflict = {
  constraint: Billing_Platform_Connected_Account_Constraint
  update_columns?: Array<Billing_Platform_Connected_Account_Update_Column>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

/** Ordering options when selecting data from "billing_platform_connected_account". */
export type Billing_Platform_Connected_Account_Order_By = {
  account_id?: Maybe<Order_By>
  billing_mode?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  platform_type?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: billing_platform_connected_account */
export type Billing_Platform_Connected_Account_Pk_Columns_Input = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** select columns of table "billing_platform_connected_account" */
export enum Billing_Platform_Connected_Account_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to hold the different statuses of our billing platform connected accounts */
export type Billing_Platform_Connected_Account_Status = {
  __typename?: 'billing_platform_connected_account_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Aggregate = {
  __typename?: 'billing_platform_connected_account_status_aggregate'
  aggregate?: Maybe<Billing_Platform_Connected_Account_Status_Aggregate_Fields>
  nodes: Array<Billing_Platform_Connected_Account_Status>
}

/** aggregate fields of "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Aggregate_Fields = {
  __typename?: 'billing_platform_connected_account_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Platform_Connected_Account_Status_Max_Fields>
  min?: Maybe<Billing_Platform_Connected_Account_Status_Min_Fields>
}

/** aggregate fields of "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Aggregate_FieldsCountArgs =
  {
    columns?: Maybe<
      Array<Billing_Platform_Connected_Account_Status_Select_Column>
    >
    distinct?: Maybe<Scalars['Boolean']>
  }

/** Boolean expression to filter rows from the table "billing_platform_connected_account_status". All fields are combined with a logical 'AND'. */
export type Billing_Platform_Connected_Account_Status_Bool_Exp = {
  _and?: Maybe<Array<Billing_Platform_Connected_Account_Status_Bool_Exp>>
  _not?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  _or?: Maybe<Array<Billing_Platform_Connected_Account_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_platform_connected_account_status" */
export enum Billing_Platform_Connected_Account_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  BillingPlatformConnectedAccountStatusPkey = 'billing_platform_connected_account_status_pkey',
}

export enum Billing_Platform_Connected_Account_Status_Enum {
  Active = 'active',
  Disabled = 'disabled',
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "billing_platform_connected_account_status_enum". All fields are combined with logical 'AND'. */
export type Billing_Platform_Connected_Account_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  _in?: Maybe<Array<Billing_Platform_Connected_Account_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  _nin?: Maybe<Array<Billing_Platform_Connected_Account_Status_Enum>>
}

/** input type for inserting data into table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Platform_Connected_Account_Status_Max_Fields = {
  __typename?: 'billing_platform_connected_account_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Platform_Connected_Account_Status_Min_Fields = {
  __typename?: 'billing_platform_connected_account_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Mutation_Response = {
  __typename?: 'billing_platform_connected_account_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Platform_Connected_Account_Status>
}

/** on_conflict condition type for table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_On_Conflict = {
  constraint: Billing_Platform_Connected_Account_Status_Constraint
  update_columns?: Array<Billing_Platform_Connected_Account_Status_Update_Column>
  where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
}

/** Ordering options when selecting data from "billing_platform_connected_account_status". */
export type Billing_Platform_Connected_Account_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: billing_platform_connected_account_status */
export type Billing_Platform_Connected_Account_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "billing_platform_connected_account_status" */
export enum Billing_Platform_Connected_Account_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "billing_platform_connected_account_status" */
export type Billing_Platform_Connected_Account_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Platform_Connected_Account_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Platform_Connected_Account_Status_Stream_Cursor_Value_Input =
  {
    description?: Maybe<Scalars['String']>
    value?: Maybe<Scalars['String']>
  }

/** update columns of table "billing_platform_connected_account_status" */
export enum Billing_Platform_Connected_Account_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Billing_Platform_Connected_Account_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Platform_Connected_Account_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Platform_Connected_Account_Status_Bool_Exp
}

/** Streaming cursor of the table "billing_platform_connected_account" */
export type Billing_Platform_Connected_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Platform_Connected_Account_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Platform_Connected_Account_Stream_Cursor_Value_Input = {
  account_id?: Maybe<Scalars['String']>
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  platform_type?: Maybe<Scalars['String']>
  status?: Maybe<Billing_Platform_Connected_Account_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "billing_platform_connected_account" */
export enum Billing_Platform_Connected_Account_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Billing_Platform_Connected_Account_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Platform_Connected_Account_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Platform_Connected_Account_Bool_Exp
}

/** A table to hold the relationship between our users and those of our billing platform */
export type Billing_Platform_Customer = {
  __typename?: 'billing_platform_customer'
  billing_mode: Billing_Mode_Enum
  created_at: Scalars['timestamptz']
  metadata: Scalars['jsonb']
  platform_id: Scalars['String']
  platform_type: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** A table to hold the relationship between our users and those of our billing platform */
export type Billing_Platform_CustomerMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "billing_platform_customer" */
export type Billing_Platform_Customer_Aggregate = {
  __typename?: 'billing_platform_customer_aggregate'
  aggregate?: Maybe<Billing_Platform_Customer_Aggregate_Fields>
  nodes: Array<Billing_Platform_Customer>
}

/** aggregate fields of "billing_platform_customer" */
export type Billing_Platform_Customer_Aggregate_Fields = {
  __typename?: 'billing_platform_customer_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Billing_Platform_Customer_Max_Fields>
  min?: Maybe<Billing_Platform_Customer_Min_Fields>
}

/** aggregate fields of "billing_platform_customer" */
export type Billing_Platform_Customer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Billing_Platform_Customer_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "billing_platform_customer". All fields are combined with a logical 'AND'. */
export type Billing_Platform_Customer_Bool_Exp = {
  _and?: Maybe<Array<Billing_Platform_Customer_Bool_Exp>>
  _not?: Maybe<Billing_Platform_Customer_Bool_Exp>
  _or?: Maybe<Array<Billing_Platform_Customer_Bool_Exp>>
  billing_mode?: Maybe<Billing_Mode_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  platform_id?: Maybe<String_Comparison_Exp>
  platform_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "billing_platform_customer" */
export enum Billing_Platform_Customer_Constraint {
  /** unique or primary key constraint on columns "platform_type", "user_id", "billing_mode" */
  BillingPlatformCustomerPkey = 'billing_platform_customer_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Billing_Platform_Customer_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Billing_Platform_Customer_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Billing_Platform_Customer_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "billing_platform_customer" */
export type Billing_Platform_Customer_Insert_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Billing_Platform_Customer_Max_Fields = {
  __typename?: 'billing_platform_customer_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Billing_Platform_Customer_Min_Fields = {
  __typename?: 'billing_platform_customer_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "billing_platform_customer" */
export type Billing_Platform_Customer_Mutation_Response = {
  __typename?: 'billing_platform_customer_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Platform_Customer>
}

/** on_conflict condition type for table "billing_platform_customer" */
export type Billing_Platform_Customer_On_Conflict = {
  constraint: Billing_Platform_Customer_Constraint
  update_columns?: Array<Billing_Platform_Customer_Update_Column>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

/** Ordering options when selecting data from "billing_platform_customer". */
export type Billing_Platform_Customer_Order_By = {
  billing_mode?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  platform_id?: Maybe<Order_By>
  platform_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: billing_platform_customer */
export type Billing_Platform_Customer_Pk_Columns_Input = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Billing_Platform_Customer_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "billing_platform_customer" */
export enum Billing_Platform_Customer_Select_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "billing_platform_customer" */
export type Billing_Platform_Customer_Set_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "billing_platform_customer" */
export type Billing_Platform_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Platform_Customer_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Billing_Platform_Customer_Stream_Cursor_Value_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  platform_id?: Maybe<Scalars['String']>
  platform_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "billing_platform_customer" */
export enum Billing_Platform_Customer_Update_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PlatformId = 'platform_id',
  /** column name */
  PlatformType = 'platform_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Billing_Platform_Customer_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Billing_Platform_Customer_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Billing_Platform_Customer_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Billing_Platform_Customer_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Billing_Platform_Customer_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Billing_Platform_Customer_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Billing_Platform_Customer_Set_Input>
  /** filter the rows which have to be updated */
  where: Billing_Platform_Customer_Bool_Exp
}

/** columns and relationships of "cart" */
export type Cart = {
  __typename?: 'cart'
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  cleared_at?: Maybe<Scalars['date']>
  created_at: Scalars['timestamptz']
  hidden: Scalars['Boolean']
  id: Scalars['uuid']
  /** Retrieves the last cart item's created_at */
  last_cart_item_created_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** columns and relationships of "cart" */
export type CartCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart" */
export type CartCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** aggregated selection of "cart" */
export type Cart_Aggregate = {
  __typename?: 'cart_aggregate'
  aggregate?: Maybe<Cart_Aggregate_Fields>
  nodes: Array<Cart>
}

export type Cart_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Cart_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Cart_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Cart_Aggregate_Bool_Exp_Count>
}

export type Cart_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Cart_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart" */
export type Cart_Aggregate_Fields = {
  __typename?: 'cart_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Cart_Max_Fields>
  min?: Maybe<Cart_Min_Fields>
}

/** aggregate fields of "cart" */
export type Cart_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cart" */
export type Cart_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Cart_Max_Order_By>
  min?: Maybe<Cart_Min_Order_By>
}

/** input type for inserting array relation for remote table "cart" */
export type Cart_Arr_Rel_Insert_Input = {
  data: Array<Cart_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** Boolean expression to filter rows from the table "cart". All fields are combined with a logical 'AND'. */
export type Cart_Bool_Exp = {
  _and?: Maybe<Array<Cart_Bool_Exp>>
  _not?: Maybe<Cart_Bool_Exp>
  _or?: Maybe<Array<Cart_Bool_Exp>>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  cleared_at?: Maybe<Date_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  hidden?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_cart_item_created_at?: Maybe<Timestamptz_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  short_id?: Maybe<Name_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "cart" */
export enum Cart_Constraint {
  /** unique or primary key constraint on columns "id" */
  CartPkey = 'cart_pkey',
  /** unique or primary key constraint on columns "shop_key", "user_id" */
  CartUserIdShopKeyKey = 'cart_user_id_shop_key_key',
}

/** input type for inserting data into table "cart" */
export type Cart_Insert_Input = {
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_Item = {
  __typename?: 'cart_item'
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark: Scalars['Boolean']
  /** An object relationship */
  cart: Cart
  cart_id: Scalars['uuid']
  /** An array relationship */
  cart_item_likes: Array<Cart_Item_Like>
  /** An aggregate relationship */
  cart_item_likes_aggregate: Cart_Item_Like_Aggregate
  categories_override?: Maybe<Array<Scalars['String']>>
  /** An object relationship */
  checkout?: Maybe<Checkout>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  /** An array relationship */
  collection_cart_items: Array<Collection_Cart_Item>
  /** An aggregate relationship */
  collection_cart_items_aggregate: Collection_Cart_Item_Aggregate
  /** An object relationship */
  collection_item_claim?: Maybe<Collection_Item_Claim>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  commentable_item_join?: Maybe<Commentable_Item>
  created_at: Scalars['timestamptz']
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** Computed field that returns the description override or the real description of the item. */
  description?: Maybe<Scalars['String']>
  /** Computed field that returns the tittle override or the real title. */
  display_title?: Maybe<Scalars['String']>
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  hidden: Scalars['Boolean']
  id: Scalars['uuid']
  /** Computed field on `cart_item` that returns the image for the cart item. */
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  /** Computed field that returns true if cart_item is in any collections */
  in_collection?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  item: Shop_Item
  item_description_override?: Maybe<Scalars['String']>
  item_id: Scalars['uuid']
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "cart_item_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  metadata: Scalars['jsonb']
  /** An object relationship */
  note?: Maybe<Cart_Item_Note>
  notifiable_item_type: Notifiable_Item_Type_Enum
  /** A computed field, executes function "originating_cart_item" */
  originating_cart_item?: Maybe<Array<Cart_Item>>
  /** A computed field, executes function "originating_collection" */
  originating_collection?: Maybe<Array<Collection>>
  /** A computed field, executes function "originating_user" */
  originating_user?: Maybe<Array<User>>
  pinned: Scalars['Boolean']
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  polymorphic_type: Commentable_Item_Type_Enum
  /** Computed field that returns the pricing override or the real pricing of the item. */
  pricing?: Maybe<Scalars['jsonb']>
  quantity: Scalars['numeric']
  remind_me_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  remove_reason?: Maybe<Remove_Reason>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  /** An array relationship */
  similar_products_histories: Array<Similar_Products_History>
  /** An aggregate relationship */
  similar_products_histories_aggregate: Similar_Products_History_Aggregate
  source?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user?: Maybe<Array<User>>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCart_Item_LikesArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCart_Item_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCollection_Cart_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemCollection_Cart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemImage_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemItem_Pricing_OverrideArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemOriginating_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemOriginating_CollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemOriginating_UserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemSimilar_Products_HistoriesArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemSimilar_Products_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "cart_item" */
export type Cart_ItemUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** aggregated selection of "cart_item" */
export type Cart_Item_Aggregate = {
  __typename?: 'cart_item_aggregate'
  aggregate?: Maybe<Cart_Item_Aggregate_Fields>
  nodes: Array<Cart_Item>
}

export type Cart_Item_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Cart_Item_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Cart_Item_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Cart_Item_Aggregate_Bool_Exp_Count>
}

export type Cart_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Cart_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_Fields = {
  __typename?: 'cart_item_aggregate_fields'
  avg?: Maybe<Cart_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Cart_Item_Max_Fields>
  min?: Maybe<Cart_Item_Min_Fields>
  stddev?: Maybe<Cart_Item_Stddev_Fields>
  stddev_pop?: Maybe<Cart_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Cart_Item_Stddev_Samp_Fields>
  sum?: Maybe<Cart_Item_Sum_Fields>
  var_pop?: Maybe<Cart_Item_Var_Pop_Fields>
  var_samp?: Maybe<Cart_Item_Var_Samp_Fields>
  variance?: Maybe<Cart_Item_Variance_Fields>
}

/** aggregate fields of "cart_item" */
export type Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cart_item" */
export type Cart_Item_Aggregate_Order_By = {
  avg?: Maybe<Cart_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Cart_Item_Max_Order_By>
  min?: Maybe<Cart_Item_Min_Order_By>
  stddev?: Maybe<Cart_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Cart_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Cart_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Cart_Item_Sum_Order_By>
  var_pop?: Maybe<Cart_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Cart_Item_Var_Samp_Order_By>
  variance?: Maybe<Cart_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Append_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "cart_item" */
export type Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Cart_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Cart_Item_Avg_Fields = {
  __typename?: 'cart_item_avg_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "cart_item" */
export type Cart_Item_Avg_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "cart_item". All fields are combined with a logical 'AND'. */
export type Cart_Item_Bool_Exp = {
  _and?: Maybe<Array<Cart_Item_Bool_Exp>>
  _not?: Maybe<Cart_Item_Bool_Exp>
  _or?: Maybe<Array<Cart_Item_Bool_Exp>>
  affiliate_redirect_link_override?: Maybe<String_Comparison_Exp>
  archived?: Maybe<Boolean_Comparison_Exp>
  archived_at?: Maybe<Timestamptz_Comparison_Exp>
  bookmark?: Maybe<Boolean_Comparison_Exp>
  cart?: Maybe<Cart_Bool_Exp>
  cart_id?: Maybe<Uuid_Comparison_Exp>
  cart_item_likes?: Maybe<Cart_Item_Like_Bool_Exp>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Bool_Exp>
  categories_override?: Maybe<String_Array_Comparison_Exp>
  checkout?: Maybe<Checkout_Bool_Exp>
  checkout_id?: Maybe<Uuid_Comparison_Exp>
  client_sources?: Maybe<String_Array_Comparison_Exp>
  collection_cart_items?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_cart_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Bool_Exp>
  collection_item_claim?: Maybe<Collection_Item_Claim_Bool_Exp>
  color_thief_processed?: Maybe<Boolean_Comparison_Exp>
  commentable_item_join?: Maybe<Commentable_Item_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator_rating?: Maybe<Numeric_Comparison_Exp>
  deal_ends_at?: Maybe<Timestamp_Comparison_Exp>
  deleted?: Maybe<Boolean_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  display_title?: Maybe<String_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  hidden?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  image_color_palette?: Maybe<Smallint_Array_Comparison_Exp>
  image_metadata?: Maybe<Jsonb_Comparison_Exp>
  image_primary_b?: Maybe<Smallint_Comparison_Exp>
  image_primary_g?: Maybe<Smallint_Comparison_Exp>
  image_primary_r?: Maybe<Smallint_Comparison_Exp>
  in_collection?: Maybe<Boolean_Comparison_Exp>
  item?: Maybe<Shop_Item_Bool_Exp>
  item_description_override?: Maybe<String_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_image_override?: Maybe<String_Comparison_Exp>
  item_pricing_override?: Maybe<Jsonb_Comparison_Exp>
  item_title_override?: Maybe<String_Comparison_Exp>
  item_video_orientation?: Maybe<String_Comparison_Exp>
  item_video_url?: Maybe<String_Comparison_Exp>
  like_count?: Maybe<Int_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  note?: Maybe<Cart_Item_Note_Bool_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  originating_cart_item?: Maybe<Cart_Item_Bool_Exp>
  originating_collection?: Maybe<Collection_Bool_Exp>
  originating_user?: Maybe<User_Bool_Exp>
  pinned?: Maybe<Boolean_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  quantity?: Maybe<Numeric_Comparison_Exp>
  remind_me_at?: Maybe<Timestamptz_Comparison_Exp>
  remove_reason?: Maybe<Remove_Reason_Bool_Exp>
  removed?: Maybe<Boolean_Comparison_Exp>
  removed_at?: Maybe<Timestamptz_Comparison_Exp>
  removed_reason?: Maybe<Remove_Reason_Enum_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  similar_products_histories?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Bool_Exp>
  source?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "cart_item" */
export enum Cart_Item_Constraint {
  /** unique or primary key constraint on columns "cart_id", "shop_key", "item_id" */
  CartItemItemIdShopKeyCartIdKey = 'cart_item_item_id_shop_key_cart_id_key',
  /** unique or primary key constraint on columns "id" */
  CartItemPkey = 'cart_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cart_Item_Delete_At_Path_Input = {
  image_metadata?: Maybe<Array<Scalars['String']>>
  item_pricing_override?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cart_Item_Delete_Elem_Input = {
  image_metadata?: Maybe<Scalars['Int']>
  item_pricing_override?: Maybe<Scalars['Int']>
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cart_Item_Delete_Key_Input = {
  image_metadata?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "cart_item" */
export type Cart_Item_Inc_Input = {
  creator_rating?: Maybe<Scalars['numeric']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  quantity?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "cart_item" */
export type Cart_Item_Insert_Input = {
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark?: Maybe<Scalars['Boolean']>
  cart?: Maybe<Cart_Obj_Rel_Insert_Input>
  cart_id?: Maybe<Scalars['uuid']>
  cart_item_likes?: Maybe<Cart_Item_Like_Arr_Rel_Insert_Input>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout?: Maybe<Checkout_Obj_Rel_Insert_Input>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  collection_cart_items?: Maybe<Collection_Cart_Item_Arr_Rel_Insert_Input>
  collection_item_claim?: Maybe<Collection_Item_Claim_Obj_Rel_Insert_Input>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  commentable_item_join?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  note?: Maybe<Cart_Item_Note_Obj_Rel_Insert_Input>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  pinned?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  remove_reason?: Maybe<Remove_Reason_Obj_Rel_Insert_Input>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  similar_products_histories?: Maybe<Similar_Products_History_Arr_Rel_Insert_Input>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "cart_item_like" */
export type Cart_Item_Like = {
  __typename?: 'cart_item_like'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  value: Scalars['Int']
}

/** aggregated selection of "cart_item_like" */
export type Cart_Item_Like_Aggregate = {
  __typename?: 'cart_item_like_aggregate'
  aggregate?: Maybe<Cart_Item_Like_Aggregate_Fields>
  nodes: Array<Cart_Item_Like>
}

export type Cart_Item_Like_Aggregate_Bool_Exp = {
  count?: Maybe<Cart_Item_Like_Aggregate_Bool_Exp_Count>
}

export type Cart_Item_Like_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Cart_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Cart_Item_Like_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "cart_item_like" */
export type Cart_Item_Like_Aggregate_Fields = {
  __typename?: 'cart_item_like_aggregate_fields'
  avg?: Maybe<Cart_Item_Like_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Cart_Item_Like_Max_Fields>
  min?: Maybe<Cart_Item_Like_Min_Fields>
  stddev?: Maybe<Cart_Item_Like_Stddev_Fields>
  stddev_pop?: Maybe<Cart_Item_Like_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Cart_Item_Like_Stddev_Samp_Fields>
  sum?: Maybe<Cart_Item_Like_Sum_Fields>
  var_pop?: Maybe<Cart_Item_Like_Var_Pop_Fields>
  var_samp?: Maybe<Cart_Item_Like_Var_Samp_Fields>
  variance?: Maybe<Cart_Item_Like_Variance_Fields>
}

/** aggregate fields of "cart_item_like" */
export type Cart_Item_Like_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "cart_item_like" */
export type Cart_Item_Like_Aggregate_Order_By = {
  avg?: Maybe<Cart_Item_Like_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Cart_Item_Like_Max_Order_By>
  min?: Maybe<Cart_Item_Like_Min_Order_By>
  stddev?: Maybe<Cart_Item_Like_Stddev_Order_By>
  stddev_pop?: Maybe<Cart_Item_Like_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Cart_Item_Like_Stddev_Samp_Order_By>
  sum?: Maybe<Cart_Item_Like_Sum_Order_By>
  var_pop?: Maybe<Cart_Item_Like_Var_Pop_Order_By>
  var_samp?: Maybe<Cart_Item_Like_Var_Samp_Order_By>
  variance?: Maybe<Cart_Item_Like_Variance_Order_By>
}

/** input type for inserting array relation for remote table "cart_item_like" */
export type Cart_Item_Like_Arr_Rel_Insert_Input = {
  data: Array<Cart_Item_Like_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_Like_On_Conflict>
}

/** aggregate avg on columns */
export type Cart_Item_Like_Avg_Fields = {
  __typename?: 'cart_item_like_avg_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "cart_item_like" */
export type Cart_Item_Like_Avg_Order_By = {
  value?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "cart_item_like". All fields are combined with a logical 'AND'. */
export type Cart_Item_Like_Bool_Exp = {
  _and?: Maybe<Array<Cart_Item_Like_Bool_Exp>>
  _not?: Maybe<Cart_Item_Like_Bool_Exp>
  _or?: Maybe<Array<Cart_Item_Like_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  value?: Maybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "cart_item_like" */
export enum Cart_Item_Like_Constraint {
  /** unique or primary key constraint on columns "id" */
  CartItemLikeIdKey = 'cart_item_like_id_key',
  /** unique or primary key constraint on columns "cart_item_id", "user_id" */
  CartItemLikePkey = 'cart_item_like_pkey',
}

/** input type for incrementing numeric columns in table "cart_item_like" */
export type Cart_Item_Like_Inc_Input = {
  value?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "cart_item_like" */
export type Cart_Item_Like_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Cart_Item_Like_Max_Fields = {
  __typename?: 'cart_item_like_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "cart_item_like" */
export type Cart_Item_Like_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Item_Like_Min_Fields = {
  __typename?: 'cart_item_like_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "cart_item_like" */
export type Cart_Item_Like_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** response of any mutation on the table "cart_item_like" */
export type Cart_Item_Like_Mutation_Response = {
  __typename?: 'cart_item_like_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item_Like>
}

/** on_conflict condition type for table "cart_item_like" */
export type Cart_Item_Like_On_Conflict = {
  constraint: Cart_Item_Like_Constraint
  update_columns?: Array<Cart_Item_Like_Update_Column>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** Ordering options when selecting data from "cart_item_like". */
export type Cart_Item_Like_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: cart_item_like */
export type Cart_Item_Like_Pk_Columns_Input = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** select columns of table "cart_item_like" */
export enum Cart_Item_Like_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "cart_item_like" */
export type Cart_Item_Like_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Cart_Item_Like_Stddev_Fields = {
  __typename?: 'cart_item_like_stddev_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "cart_item_like" */
export type Cart_Item_Like_Stddev_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Cart_Item_Like_Stddev_Pop_Fields = {
  __typename?: 'cart_item_like_stddev_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "cart_item_like" */
export type Cart_Item_Like_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Cart_Item_Like_Stddev_Samp_Fields = {
  __typename?: 'cart_item_like_stddev_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "cart_item_like" */
export type Cart_Item_Like_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** Streaming cursor of the table "cart_item_like" */
export type Cart_Item_Like_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Item_Like_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Item_Like_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Cart_Item_Like_Sum_Fields = {
  __typename?: 'cart_item_like_sum_fields'
  value?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "cart_item_like" */
export type Cart_Item_Like_Sum_Order_By = {
  value?: Maybe<Order_By>
}

/** update columns of table "cart_item_like" */
export enum Cart_Item_Like_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

export type Cart_Item_Like_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Cart_Item_Like_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Item_Like_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Item_Like_Bool_Exp
}

/** aggregate var_pop on columns */
export type Cart_Item_Like_Var_Pop_Fields = {
  __typename?: 'cart_item_like_var_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "cart_item_like" */
export type Cart_Item_Like_Var_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Cart_Item_Like_Var_Samp_Fields = {
  __typename?: 'cart_item_like_var_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "cart_item_like" */
export type Cart_Item_Like_Var_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Cart_Item_Like_Variance_Fields = {
  __typename?: 'cart_item_like_variance_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "cart_item_like" */
export type Cart_Item_Like_Variance_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Cart_Item_Max_Fields = {
  __typename?: 'cart_item_max_fields'
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived_at?: Maybe<Scalars['timestamptz']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** Computed field that returns the description override or the real description of the item. */
  description?: Maybe<Scalars['String']>
  /** Computed field that returns the tittle override or the real title. */
  display_title?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  /** Computed field on `cart_item` that returns the image for the cart item. */
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "cart_item" */
export type Cart_Item_Max_Order_By = {
  affiliate_redirect_link_override?: Maybe<Order_By>
  archived_at?: Maybe<Order_By>
  cart_id?: Maybe<Order_By>
  categories_override?: Maybe<Order_By>
  checkout_id?: Maybe<Order_By>
  client_sources?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_rating?: Maybe<Order_By>
  deal_ends_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_description_override?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_image_override?: Maybe<Order_By>
  item_title_override?: Maybe<Order_By>
  item_video_orientation?: Maybe<Order_By>
  item_video_url?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
  remind_me_at?: Maybe<Order_By>
  removed_at?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Item_Min_Fields = {
  __typename?: 'cart_item_min_fields'
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived_at?: Maybe<Scalars['timestamptz']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  /** Computed field that returns the description override or the real description of the item. */
  description?: Maybe<Scalars['String']>
  /** Computed field that returns the tittle override or the real title. */
  display_title?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  /** Computed field on `cart_item` that returns the image for the cart item. */
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "cart_item" */
export type Cart_Item_Min_Order_By = {
  affiliate_redirect_link_override?: Maybe<Order_By>
  archived_at?: Maybe<Order_By>
  cart_id?: Maybe<Order_By>
  categories_override?: Maybe<Order_By>
  checkout_id?: Maybe<Order_By>
  client_sources?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator_rating?: Maybe<Order_By>
  deal_ends_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_description_override?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_image_override?: Maybe<Order_By>
  item_title_override?: Maybe<Order_By>
  item_video_orientation?: Maybe<Order_By>
  item_video_url?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
  remind_me_at?: Maybe<Order_By>
  removed_at?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "cart_item" */
export type Cart_Item_Mutation_Response = {
  __typename?: 'cart_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item>
}

/** columns and relationships of "cart_item_note" */
export type Cart_Item_Note = {
  __typename?: 'cart_item_note'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  note?: Maybe<Scalars['String']>
  private: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "cart_item_note" */
export type Cart_Item_Note_Aggregate = {
  __typename?: 'cart_item_note_aggregate'
  aggregate?: Maybe<Cart_Item_Note_Aggregate_Fields>
  nodes: Array<Cart_Item_Note>
}

/** aggregate fields of "cart_item_note" */
export type Cart_Item_Note_Aggregate_Fields = {
  __typename?: 'cart_item_note_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Cart_Item_Note_Max_Fields>
  min?: Maybe<Cart_Item_Note_Min_Fields>
}

/** aggregate fields of "cart_item_note" */
export type Cart_Item_Note_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cart_Item_Note_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "cart_item_note". All fields are combined with a logical 'AND'. */
export type Cart_Item_Note_Bool_Exp = {
  _and?: Maybe<Array<Cart_Item_Note_Bool_Exp>>
  _not?: Maybe<Cart_Item_Note_Bool_Exp>
  _or?: Maybe<Array<Cart_Item_Note_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  note?: Maybe<String_Comparison_Exp>
  private?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "cart_item_note" */
export enum Cart_Item_Note_Constraint {
  /** unique or primary key constraint on columns "cart_item_id" */
  CartItemNoteCartItemIdKey = 'cart_item_note_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CartItemNoteIdKey = 'cart_item_note_id_key',
  /** unique or primary key constraint on columns "id" */
  CartItemNotePkey = 'cart_item_note_pkey',
}

/** input type for inserting data into table "cart_item_note" */
export type Cart_Item_Note_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Cart_Item_Note_Max_Fields = {
  __typename?: 'cart_item_note_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Cart_Item_Note_Min_Fields = {
  __typename?: 'cart_item_note_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "cart_item_note" */
export type Cart_Item_Note_Mutation_Response = {
  __typename?: 'cart_item_note_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart_Item_Note>
}

/** input type for inserting object relation for remote table "cart_item_note" */
export type Cart_Item_Note_Obj_Rel_Insert_Input = {
  data: Cart_Item_Note_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_Note_On_Conflict>
}

/** on_conflict condition type for table "cart_item_note" */
export type Cart_Item_Note_On_Conflict = {
  constraint: Cart_Item_Note_Constraint
  update_columns?: Array<Cart_Item_Note_Update_Column>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

/** Ordering options when selecting data from "cart_item_note". */
export type Cart_Item_Note_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  note?: Maybe<Order_By>
  private?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: cart_item_note */
export type Cart_Item_Note_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "cart_item_note" */
export enum Cart_Item_Note_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Private = 'private',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cart_item_note" */
export type Cart_Item_Note_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "cart_item_note" */
export type Cart_Item_Note_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Item_Note_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Item_Note_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  note?: Maybe<Scalars['String']>
  private?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "cart_item_note" */
export enum Cart_Item_Note_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  Private = 'private',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cart_Item_Note_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Item_Note_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Item_Note_Bool_Exp
}

/** input type for inserting object relation for remote table "cart_item" */
export type Cart_Item_Obj_Rel_Insert_Input = {
  data: Cart_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** on_conflict condition type for table "cart_item" */
export type Cart_Item_On_Conflict = {
  constraint: Cart_Item_Constraint
  update_columns?: Array<Cart_Item_Update_Column>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** Ordering options when selecting data from "cart_item". */
export type Cart_Item_Order_By = {
  affiliate_redirect_link_override?: Maybe<Order_By>
  archived?: Maybe<Order_By>
  archived_at?: Maybe<Order_By>
  bookmark?: Maybe<Order_By>
  cart?: Maybe<Cart_Order_By>
  cart_id?: Maybe<Order_By>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Order_By>
  categories_override?: Maybe<Order_By>
  checkout?: Maybe<Checkout_Order_By>
  checkout_id?: Maybe<Order_By>
  client_sources?: Maybe<Order_By>
  collection_cart_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
  collection_item_claim?: Maybe<Collection_Item_Claim_Order_By>
  color_thief_processed?: Maybe<Order_By>
  commentable_item_join?: Maybe<Commentable_Item_Order_By>
  created_at?: Maybe<Order_By>
  creator_rating?: Maybe<Order_By>
  deal_ends_at?: Maybe<Order_By>
  deleted?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_title?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  hidden?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_metadata?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  in_collection?: Maybe<Order_By>
  item?: Maybe<Shop_Item_Order_By>
  item_description_override?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_image_override?: Maybe<Order_By>
  item_pricing_override?: Maybe<Order_By>
  item_title_override?: Maybe<Order_By>
  item_video_orientation?: Maybe<Order_By>
  item_video_url?: Maybe<Order_By>
  like_count?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  note?: Maybe<Cart_Item_Note_Order_By>
  notifiable_item_type?: Maybe<Order_By>
  originating_cart_item_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  originating_collection_aggregate?: Maybe<Collection_Aggregate_Order_By>
  originating_user_aggregate?: Maybe<User_Aggregate_Order_By>
  pinned?: Maybe<Order_By>
  points?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
  remind_me_at?: Maybe<Order_By>
  remove_reason?: Maybe<Remove_Reason_Order_By>
  removed?: Maybe<Order_By>
  removed_at?: Maybe<Order_By>
  removed_reason?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_aggregate?: Maybe<User_Aggregate_Order_By>
}

/** primary key columns input for table: cart_item */
export type Cart_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cart_Item_Prepend_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "cart_item" */
export enum Cart_Item_Select_Column {
  /** column name */
  AffiliateRedirectLinkOverride = 'affiliate_redirect_link_override',
  /** column name */
  Archived = 'archived',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CategoriesOverride = 'categories_override',
  /** column name */
  CheckoutId = 'checkout_id',
  /** column name */
  ClientSources = 'client_sources',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorRating = 'creator_rating',
  /** column name */
  DealEndsAt = 'deal_ends_at',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  ItemDescriptionOverride = 'item_description_override',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageOverride = 'item_image_override',
  /** column name */
  ItemPricingOverride = 'item_pricing_override',
  /** column name */
  ItemTitleOverride = 'item_title_override',
  /** column name */
  ItemVideoOrientation = 'item_video_orientation',
  /** column name */
  ItemVideoUrl = 'item_video_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RemindMeAt = 'remind_me_at',
  /** column name */
  Removed = 'removed',
  /** column name */
  RemovedAt = 'removed_at',
  /** column name */
  RemovedReason = 'removed_reason',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "cart_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cart_item" */
export enum Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  Removed = 'removed',
}

/** select "cart_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cart_item" */
export enum Cart_Item_Select_Column_Cart_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Archived = 'archived',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  Removed = 'removed',
}

/** input type for updating data in table "cart_item" */
export type Cart_Item_Set_Input = {
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark?: Maybe<Scalars['Boolean']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  pinned?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Cart_Item_Stddev_Fields = {
  __typename?: 'cart_item_stddev_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "cart_item" */
export type Cart_Item_Stddev_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'cart_item_stddev_pop_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "cart_item" */
export type Cart_Item_Stddev_Pop_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'cart_item_stddev_samp_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "cart_item" */
export type Cart_Item_Stddev_Samp_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** Streaming cursor of the table "cart_item" */
export type Cart_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Item_Stream_Cursor_Value_Input = {
  affiliate_redirect_link_override?: Maybe<Scalars['String']>
  archived?: Maybe<Scalars['Boolean']>
  archived_at?: Maybe<Scalars['timestamptz']>
  bookmark?: Maybe<Scalars['Boolean']>
  cart_id?: Maybe<Scalars['uuid']>
  categories_override?: Maybe<Array<Scalars['String']>>
  checkout_id?: Maybe<Scalars['uuid']>
  client_sources?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_rating?: Maybe<Scalars['numeric']>
  deal_ends_at?: Maybe<Scalars['timestamp']>
  deleted?: Maybe<Scalars['Boolean']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_description_override?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['uuid']>
  item_image_override?: Maybe<Scalars['String']>
  item_pricing_override?: Maybe<Scalars['jsonb']>
  item_title_override?: Maybe<Scalars['String']>
  item_video_orientation?: Maybe<Scalars['String']>
  item_video_url?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  pinned?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  quantity?: Maybe<Scalars['numeric']>
  remind_me_at?: Maybe<Scalars['timestamptz']>
  removed?: Maybe<Scalars['Boolean']>
  removed_at?: Maybe<Scalars['timestamptz']>
  removed_reason?: Maybe<Remove_Reason_Enum>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Cart_Item_Sum_Fields = {
  __typename?: 'cart_item_sum_fields'
  creator_rating?: Maybe<Scalars['numeric']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "cart_item" */
export type Cart_Item_Sum_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** update columns of table "cart_item" */
export enum Cart_Item_Update_Column {
  /** column name */
  AffiliateRedirectLinkOverride = 'affiliate_redirect_link_override',
  /** column name */
  Archived = 'archived',
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CartId = 'cart_id',
  /** column name */
  CategoriesOverride = 'categories_override',
  /** column name */
  CheckoutId = 'checkout_id',
  /** column name */
  ClientSources = 'client_sources',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorRating = 'creator_rating',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  ItemDescriptionOverride = 'item_description_override',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemImageOverride = 'item_image_override',
  /** column name */
  ItemPricingOverride = 'item_pricing_override',
  /** column name */
  ItemTitleOverride = 'item_title_override',
  /** column name */
  ItemVideoOrientation = 'item_video_orientation',
  /** column name */
  ItemVideoUrl = 'item_video_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Pinned = 'pinned',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RemindMeAt = 'remind_me_at',
  /** column name */
  Removed = 'removed',
  /** column name */
  RemovedAt = 'removed_at',
  /** column name */
  RemovedReason = 'removed_reason',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Cart_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Cart_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Cart_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Cart_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Cart_Item_Var_Pop_Fields = {
  __typename?: 'cart_item_var_pop_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "cart_item" */
export type Cart_Item_Var_Pop_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Cart_Item_Var_Samp_Fields = {
  __typename?: 'cart_item_var_samp_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "cart_item" */
export type Cart_Item_Var_Samp_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Cart_Item_Variance_Fields = {
  __typename?: 'cart_item_variance_fields'
  creator_rating?: Maybe<Scalars['Float']>
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "cart_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A function used to determine the number of points for a `cart_item` based on the pricing. */
  points?: Maybe<Scalars['numeric']>
  quantity?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "cart_item" */
export type Cart_Item_Variance_Order_By = {
  creator_rating?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  quantity?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Cart_Max_Fields = {
  __typename?: 'cart_max_fields'
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  /** Retrieves the last cart item's created_at */
  last_cart_item_created_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "cart" */
export type Cart_Max_Order_By = {
  cleared_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Cart_Min_Fields = {
  __typename?: 'cart_min_fields'
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  /** Retrieves the last cart item's created_at */
  last_cart_item_created_at?: Maybe<Scalars['timestamptz']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "cart" */
export type Cart_Min_Order_By = {
  cleared_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "cart" */
export type Cart_Mutation_Response = {
  __typename?: 'cart_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Cart>
}

/** input type for inserting object relation for remote table "cart" */
export type Cart_Obj_Rel_Insert_Input = {
  data: Cart_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** on_conflict condition type for table "cart" */
export type Cart_On_Conflict = {
  constraint: Cart_Constraint
  update_columns?: Array<Cart_Update_Column>
  where?: Maybe<Cart_Bool_Exp>
}

/** Ordering options when selecting data from "cart". */
export type Cart_Order_By = {
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  cleared_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  hidden?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_cart_item_created_at?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: cart */
export type Cart_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "cart" */
export enum Cart_Select_Column {
  /** column name */
  ClearedAt = 'cleared_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "cart_aggregate_bool_exp_bool_and_arguments_columns" columns of table "cart" */
export enum Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** select "cart_aggregate_bool_exp_bool_or_arguments_columns" columns of table "cart" */
export enum Cart_Select_Column_Cart_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** input type for updating data in table "cart" */
export type Cart_Set_Input = {
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "cart" */
export type Cart_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cart_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Cart_Stream_Cursor_Value_Input = {
  cleared_at?: Maybe<Scalars['date']>
  created_at?: Maybe<Scalars['timestamptz']>
  hidden?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  short_id?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "cart" */
export enum Cart_Update_Column {
  /** column name */
  ClearedAt = 'cleared_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Cart_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Cart_Set_Input>
  /** filter the rows which have to be updated */
  where: Cart_Bool_Exp
}

/** This table contains the categories tree */
export type Categories = {
  __typename?: 'categories'
  /** Creates a JSONB array of the breadcrumb list for a given child category */
  breadcrumb_list?: Maybe<Scalars['jsonb']>
  /** An array relationship */
  child_categories: Array<Categories>
  /** An aggregate relationship */
  child_categories_aggregate: Categories_Aggregate
  created_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  name: Scalars['String']
  /** An object relationship */
  parent_category?: Maybe<Categories>
  parent_id?: Maybe<Scalars['uuid']>
  source: Scalars['String']
  source_category_id: Scalars['String']
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** This table contains the categories tree */
export type CategoriesBreadcrumb_ListArgs = {
  path?: Maybe<Scalars['String']>
}

/** This table contains the categories tree */
export type CategoriesChild_CategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

/** This table contains the categories tree */
export type CategoriesChild_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate'
  aggregate?: Maybe<Categories_Aggregate_Fields>
  nodes: Array<Categories>
}

export type Categories_Aggregate_Bool_Exp = {
  count?: Maybe<Categories_Aggregate_Bool_Exp_Count>
}

export type Categories_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Categories_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Categories_Max_Fields>
  min?: Maybe<Categories_Min_Fields>
}

/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Categories_Max_Order_By>
  min?: Maybe<Categories_Min_Order_By>
}

/** input type for inserting array relation for remote table "categories" */
export type Categories_Arr_Rel_Insert_Input = {
  data: Array<Categories_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: Maybe<Array<Categories_Bool_Exp>>
  _not?: Maybe<Categories_Bool_Exp>
  _or?: Maybe<Array<Categories_Bool_Exp>>
  breadcrumb_list?: Maybe<Jsonb_Comparison_Exp>
  child_categories?: Maybe<Categories_Bool_Exp>
  child_categories_aggregate?: Maybe<Categories_Aggregate_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  parent_category?: Maybe<Categories_Bool_Exp>
  parent_id?: Maybe<Uuid_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  source_category_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey',
}

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  child_categories?: Maybe<Categories_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_category?: Maybe<Categories_Obj_Rel_Insert_Input>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  parent_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  source_category_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  parent_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  source_category_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Categories>
}

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint
  update_columns?: Array<Categories_Update_Column>
  where?: Maybe<Categories_Bool_Exp>
}

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  breadcrumb_list?: Maybe<Order_By>
  child_categories_aggregate?: Maybe<Categories_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  parent_category?: Maybe<Categories_Order_By>
  parent_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  source_category_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceCategoryId = 'source_category_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  parent_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  source_category_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Source = 'source',
  /** column name */
  SourceCategoryId = 'source_category_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Categories_Set_Input>
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp
}

/** Associates a shop item to a category in our database */
export type Category_Shop_Item = {
  __typename?: 'category_shop_item'
  category_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  match_percentage: Scalars['float8']
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "category_shop_item" */
export type Category_Shop_Item_Aggregate = {
  __typename?: 'category_shop_item_aggregate'
  aggregate?: Maybe<Category_Shop_Item_Aggregate_Fields>
  nodes: Array<Category_Shop_Item>
}

/** aggregate fields of "category_shop_item" */
export type Category_Shop_Item_Aggregate_Fields = {
  __typename?: 'category_shop_item_aggregate_fields'
  avg?: Maybe<Category_Shop_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Category_Shop_Item_Max_Fields>
  min?: Maybe<Category_Shop_Item_Min_Fields>
  stddev?: Maybe<Category_Shop_Item_Stddev_Fields>
  stddev_pop?: Maybe<Category_Shop_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Category_Shop_Item_Stddev_Samp_Fields>
  sum?: Maybe<Category_Shop_Item_Sum_Fields>
  var_pop?: Maybe<Category_Shop_Item_Var_Pop_Fields>
  var_samp?: Maybe<Category_Shop_Item_Var_Samp_Fields>
  variance?: Maybe<Category_Shop_Item_Variance_Fields>
}

/** aggregate fields of "category_shop_item" */
export type Category_Shop_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Category_Shop_Item_Avg_Fields = {
  __typename?: 'category_shop_item_avg_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "category_shop_item". All fields are combined with a logical 'AND'. */
export type Category_Shop_Item_Bool_Exp = {
  _and?: Maybe<Array<Category_Shop_Item_Bool_Exp>>
  _not?: Maybe<Category_Shop_Item_Bool_Exp>
  _or?: Maybe<Array<Category_Shop_Item_Bool_Exp>>
  category_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  match_percentage?: Maybe<Float8_Comparison_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "category_shop_item" */
export enum Category_Shop_Item_Constraint {
  /** unique or primary key constraint on columns "category_id", "shop_item_id" */
  CategoryShopItemCategoryIdShopItemIdKey = 'category_shop_item_category_id_shop_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CategoryShopItemPkey = 'category_shop_item_pkey',
}

/** input type for incrementing numeric columns in table "category_shop_item" */
export type Category_Shop_Item_Inc_Input = {
  match_percentage?: Maybe<Scalars['float8']>
}

/** input type for inserting data into table "category_shop_item" */
export type Category_Shop_Item_Insert_Input = {
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Category_Shop_Item_Max_Fields = {
  __typename?: 'category_shop_item_max_fields'
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Category_Shop_Item_Min_Fields = {
  __typename?: 'category_shop_item_min_fields'
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "category_shop_item" */
export type Category_Shop_Item_Mutation_Response = {
  __typename?: 'category_shop_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Category_Shop_Item>
}

/** on_conflict condition type for table "category_shop_item" */
export type Category_Shop_Item_On_Conflict = {
  constraint: Category_Shop_Item_Constraint
  update_columns?: Array<Category_Shop_Item_Update_Column>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

/** Ordering options when selecting data from "category_shop_item". */
export type Category_Shop_Item_Order_By = {
  category_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  match_percentage?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: category_shop_item */
export type Category_Shop_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "category_shop_item" */
export enum Category_Shop_Item_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MatchPercentage = 'match_percentage',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "category_shop_item" */
export type Category_Shop_Item_Set_Input = {
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Category_Shop_Item_Stddev_Fields = {
  __typename?: 'category_shop_item_stddev_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Category_Shop_Item_Stddev_Pop_Fields = {
  __typename?: 'category_shop_item_stddev_pop_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Category_Shop_Item_Stddev_Samp_Fields = {
  __typename?: 'category_shop_item_stddev_samp_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "category_shop_item" */
export type Category_Shop_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Category_Shop_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Category_Shop_Item_Stream_Cursor_Value_Input = {
  category_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  match_percentage?: Maybe<Scalars['float8']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Category_Shop_Item_Sum_Fields = {
  __typename?: 'category_shop_item_sum_fields'
  match_percentage?: Maybe<Scalars['float8']>
}

/** update columns of table "category_shop_item" */
export enum Category_Shop_Item_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MatchPercentage = 'match_percentage',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Category_Shop_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Category_Shop_Item_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Category_Shop_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Category_Shop_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Category_Shop_Item_Var_Pop_Fields = {
  __typename?: 'category_shop_item_var_pop_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Category_Shop_Item_Var_Samp_Fields = {
  __typename?: 'category_shop_item_var_samp_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Category_Shop_Item_Variance_Fields = {
  __typename?: 'category_shop_item_variance_fields'
  match_percentage?: Maybe<Scalars['Float']>
}

/** columns and relationships of "checkout" */
export type Checkout = {
  __typename?: 'checkout'
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  created_at: Scalars['timestamptz']
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id: Scalars['String']
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price: Scalars['numeric']
  total_price: Scalars['numeric']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** columns and relationships of "checkout" */
export type CheckoutCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "checkout" */
export type CheckoutCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "checkout" */
export type CheckoutMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "checkout" */
export type Checkout_Aggregate = {
  __typename?: 'checkout_aggregate'
  aggregate?: Maybe<Checkout_Aggregate_Fields>
  nodes: Array<Checkout>
}

/** aggregate fields of "checkout" */
export type Checkout_Aggregate_Fields = {
  __typename?: 'checkout_aggregate_fields'
  avg?: Maybe<Checkout_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Checkout_Max_Fields>
  min?: Maybe<Checkout_Min_Fields>
  stddev?: Maybe<Checkout_Stddev_Fields>
  stddev_pop?: Maybe<Checkout_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Checkout_Stddev_Samp_Fields>
  sum?: Maybe<Checkout_Sum_Fields>
  var_pop?: Maybe<Checkout_Var_Pop_Fields>
  var_samp?: Maybe<Checkout_Var_Samp_Fields>
  variance?: Maybe<Checkout_Variance_Fields>
}

/** aggregate fields of "checkout" */
export type Checkout_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Checkout_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Checkout_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Checkout_Avg_Fields = {
  __typename?: 'checkout_avg_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "checkout". All fields are combined with a logical 'AND'. */
export type Checkout_Bool_Exp = {
  _and?: Maybe<Array<Checkout_Bool_Exp>>
  _not?: Maybe<Checkout_Bool_Exp>
  _or?: Maybe<Array<Checkout_Bool_Exp>>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  currency?: Maybe<String_Comparison_Exp>
  customer_id?: Maybe<String_Comparison_Exp>
  html_output?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  order_created_at?: Maybe<Timestamptz_Comparison_Exp>
  order_id?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  subtotal_price?: Maybe<Numeric_Comparison_Exp>
  total_price?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "checkout" */
export enum Checkout_Constraint {
  /** unique or primary key constraint on columns "id" */
  CheckoutPkey = 'checkout_pkey',
  /** unique or primary key constraint on columns "shop_key", "order_id" */
  CheckoutShopKeyOrderIdKey = 'checkout_shop_key_order_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Checkout_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Checkout_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Checkout_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "checkout" */
export type Checkout_Inc_Input = {
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "checkout" */
export type Checkout_Insert_Input = {
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Checkout_Max_Fields = {
  __typename?: 'checkout_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Checkout_Min_Fields = {
  __typename?: 'checkout_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "checkout" */
export type Checkout_Mutation_Response = {
  __typename?: 'checkout_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Checkout>
}

/** input type for inserting object relation for remote table "checkout" */
export type Checkout_Obj_Rel_Insert_Input = {
  data: Checkout_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Checkout_On_Conflict>
}

/** on_conflict condition type for table "checkout" */
export type Checkout_On_Conflict = {
  constraint: Checkout_Constraint
  update_columns?: Array<Checkout_Update_Column>
  where?: Maybe<Checkout_Bool_Exp>
}

/** Ordering options when selecting data from "checkout". */
export type Checkout_Order_By = {
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  customer_id?: Maybe<Order_By>
  html_output?: Maybe<Order_By>
  id?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  order_created_at?: Maybe<Order_By>
  order_id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  subtotal_price?: Maybe<Order_By>
  total_price?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: checkout */
export type Checkout_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Checkout_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "checkout" */
export enum Checkout_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  HtmlOutput = 'html_output',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderCreatedAt = 'order_created_at',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  SubtotalPrice = 'subtotal_price',
  /** column name */
  TotalPrice = 'total_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "checkout" */
export type Checkout_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Checkout_Stddev_Fields = {
  __typename?: 'checkout_stddev_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Checkout_Stddev_Pop_Fields = {
  __typename?: 'checkout_stddev_pop_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Checkout_Stddev_Samp_Fields = {
  __typename?: 'checkout_stddev_samp_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "checkout" */
export type Checkout_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Checkout_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Checkout_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  customer_id?: Maybe<Scalars['String']>
  html_output?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  order_created_at?: Maybe<Scalars['timestamptz']>
  order_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Checkout_Sum_Fields = {
  __typename?: 'checkout_sum_fields'
  subtotal_price?: Maybe<Scalars['numeric']>
  total_price?: Maybe<Scalars['numeric']>
}

/** update columns of table "checkout" */
export enum Checkout_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  HtmlOutput = 'html_output',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OrderCreatedAt = 'order_created_at',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  SubtotalPrice = 'subtotal_price',
  /** column name */
  TotalPrice = 'total_price',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Checkout_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Checkout_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Checkout_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Checkout_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Checkout_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Checkout_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Checkout_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Checkout_Set_Input>
  /** filter the rows which have to be updated */
  where: Checkout_Bool_Exp
}

/** aggregate var_pop on columns */
export type Checkout_Var_Pop_Fields = {
  __typename?: 'checkout_var_pop_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Checkout_Var_Samp_Fields = {
  __typename?: 'checkout_var_samp_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Checkout_Variance_Fields = {
  __typename?: 'checkout_variance_fields'
  subtotal_price?: Maybe<Scalars['Float']>
  total_price?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "cidr". All fields are combined with logical 'AND'. */
export type Cidr_Comparison_Exp = {
  _eq?: Maybe<Scalars['cidr']>
  _gt?: Maybe<Scalars['cidr']>
  _gte?: Maybe<Scalars['cidr']>
  _in?: Maybe<Array<Scalars['cidr']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['cidr']>
  _lte?: Maybe<Scalars['cidr']>
  _neq?: Maybe<Scalars['cidr']>
  _nin?: Maybe<Array<Scalars['cidr']>>
}

/** columns and relationships of "collection" */
export type Collection = {
  __typename?: 'collection'
  all: Scalars['Boolean']
  announce_collection: Scalars['Boolean']
  bookmarks: Scalars['Boolean']
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  /** An array relationship */
  collection_collaborators: Array<Collection_Collaborator>
  /** An aggregate relationship */
  collection_collaborators_aggregate: Collection_Collaborator_Aggregate
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  /** An array relationship */
  collection_invites: Array<Collection_Invite>
  /** An aggregate relationship */
  collection_invites_aggregate: Collection_Invite_Aggregate
  /** An array relationship */
  collection_items: Array<Collection_Cart_Item>
  /** An aggregate relationship */
  collection_items_aggregate: Collection_Cart_Item_Aggregate
  collection_link?: Maybe<Scalars['String']>
  /** An array relationship */
  collection_sections: Array<Collection_Section>
  /** An aggregate relationship */
  collection_sections_aggregate: Collection_Section_Aggregate
  /** An array relationship */
  collection_slug_histories: Array<Collection_Slug_History>
  /** An aggregate relationship */
  collection_slug_histories_aggregate: Collection_Slug_History_Aggregate
  /** An object relationship */
  collection_state?: Maybe<Collection_State>
  collection_type: Collection_Type_Enum
  /** An object relationship */
  commentable_item_join?: Maybe<Commentable_Item>
  /** A computed field, executes function "collection_is_visible" */
  computed_is_visible?: Maybe<Scalars['Boolean']>
  cover_image?: Maybe<Scalars['String']>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cover_image_cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation: Scalars['Boolean']
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  followable_item_type: Followable_Item_Type_Enum
  /** A computed field, executes function "collection_followed_by_user" */
  followed_by_user?: Maybe<Scalars['Boolean']>
  giftlist: Scalars['Boolean']
  has_collaborators: Scalars['Boolean']
  /** A computed field, executes function "collection_has_valid_preview_images" */
  has_valid_preview_images_computed?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** A computed field, executes function "collection_is_a_collaborator" */
  is_a_collaborator?: Maybe<Scalars['Boolean']>
  item_notifications: Scalars['Boolean']
  /** A computed field, executes function "collection_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  media_enabled_join?: Maybe<Media_Enabled_Item>
  metadata?: Maybe<Scalars['jsonb']>
  name: Scalars['name']
  notifiable_item_type: Notifiable_Item_Type_Enum
  notification_preferences: Scalars['jsonb']
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_open_collab_link" */
  open_collab_link_computed?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid: Scalars['Boolean']
  /** A computed field, executes function "collection_path" */
  path?: Maybe<Scalars['String']>
  polymorphic_type: Commentable_Item_Type_Enum
  /** A computed field, executes function "collection_preview_collection_items" */
  preview_collection_items?: Maybe<Array<Collection_Cart_Item>>
  private: Scalars['Boolean']
  searchable_name?: Maybe<Scalars['String']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug: Scalars['name']
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_viewable_collection_items" */
  viewable_collection_items?: Maybe<Array<Collection_Cart_Item>>
}

/** columns and relationships of "collection" */
export type CollectionCollection_CollaboratorsArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Collaborators_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_InvitesArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Invites_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_SectionsArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Sections_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Slug_HistoriesArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionCollection_Slug_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection" */
export type CollectionNotification_PreferencesArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection" */
export type CollectionPreview_Collection_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection" */
export type CollectionSeo_FieldsArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection" */
export type CollectionViewable_Collection_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** aggregated selection of "collection" */
export type Collection_Aggregate = {
  __typename?: 'collection_aggregate'
  aggregate?: Maybe<Collection_Aggregate_Fields>
  nodes: Array<Collection>
}

export type Collection_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Collection_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Collection_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Collection_Aggregate_Bool_Exp_Count>
}

export type Collection_Aggregate_Bool_Exp_Bool_And = {
  arguments: Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection" */
export type Collection_Aggregate_Fields = {
  __typename?: 'collection_aggregate_fields'
  avg?: Maybe<Collection_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Max_Fields>
  min?: Maybe<Collection_Min_Fields>
  stddev?: Maybe<Collection_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Sum_Fields>
  var_pop?: Maybe<Collection_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Var_Samp_Fields>
  variance?: Maybe<Collection_Variance_Fields>
}

/** aggregate fields of "collection" */
export type Collection_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection" */
export type Collection_Aggregate_Order_By = {
  avg?: Maybe<Collection_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Max_Order_By>
  min?: Maybe<Collection_Min_Order_By>
  stddev?: Maybe<Collection_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Sum_Order_By>
  var_pop?: Maybe<Collection_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Var_Samp_Order_By>
  variance?: Maybe<Collection_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Collection_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  notification_preferences?: Maybe<Scalars['jsonb']>
  seo_fields?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "collection" */
export type Collection_Arr_Rel_Insert_Input = {
  data: Array<Collection_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Avg_Fields = {
  __typename?: 'collection_avg_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by avg() on columns of table "collection" */
export type Collection_Avg_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection". All fields are combined with a logical 'AND'. */
export type Collection_Bool_Exp = {
  _and?: Maybe<Array<Collection_Bool_Exp>>
  _not?: Maybe<Collection_Bool_Exp>
  _or?: Maybe<Array<Collection_Bool_Exp>>
  all?: Maybe<Boolean_Comparison_Exp>
  announce_collection?: Maybe<Boolean_Comparison_Exp>
  bookmarks?: Maybe<Boolean_Comparison_Exp>
  budget?: Maybe<Numeric_Comparison_Exp>
  cart_item_max_updated_at?: Maybe<Timestamptz_Comparison_Exp>
  cart_items_count?: Maybe<Numeric_Comparison_Exp>
  cart_items_quantity?: Maybe<Numeric_Comparison_Exp>
  collaborators_count?: Maybe<Int_Comparison_Exp>
  collection_collaborators?: Maybe<Collection_Collaborator_Bool_Exp>
  collection_collaborators_aggregate?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp>
  collection_cover_image?: Maybe<String_Comparison_Exp>
  collection_cover_images?: Maybe<String_Array_Comparison_Exp>
  collection_invites?: Maybe<Collection_Invite_Bool_Exp>
  collection_invites_aggregate?: Maybe<Collection_Invite_Aggregate_Bool_Exp>
  collection_items?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Bool_Exp>
  collection_link?: Maybe<String_Comparison_Exp>
  collection_sections?: Maybe<Collection_Section_Bool_Exp>
  collection_sections_aggregate?: Maybe<Collection_Section_Aggregate_Bool_Exp>
  collection_slug_histories?: Maybe<Collection_Slug_History_Bool_Exp>
  collection_slug_histories_aggregate?: Maybe<Collection_Slug_History_Aggregate_Bool_Exp>
  collection_state?: Maybe<Collection_State_Bool_Exp>
  collection_type?: Maybe<Collection_Type_Enum_Comparison_Exp>
  commentable_item_join?: Maybe<Commentable_Item_Bool_Exp>
  computed_is_visible?: Maybe<Boolean_Comparison_Exp>
  cover_image?: Maybe<String_Comparison_Exp>
  cover_image_cached?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  curation?: Maybe<Boolean_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  email_excerpt?: Maybe<String_Comparison_Exp>
  exclusive?: Maybe<Boolean_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  followable_item_type?: Maybe<Followable_Item_Type_Enum_Comparison_Exp>
  followed_by_user?: Maybe<Boolean_Comparison_Exp>
  giftlist?: Maybe<Boolean_Comparison_Exp>
  has_collaborators?: Maybe<Boolean_Comparison_Exp>
  has_valid_preview_images_computed?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_a_collaborator?: Maybe<Boolean_Comparison_Exp>
  item_notifications?: Maybe<Boolean_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  media_enabled_join?: Maybe<Media_Enabled_Item_Bool_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  name?: Maybe<Name_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  notification_preferences?: Maybe<Jsonb_Comparison_Exp>
  ogimage_fingerprint?: Maybe<String_Comparison_Exp>
  ogimage_fingerprint_updated?: Maybe<String_Comparison_Exp>
  open_collab_link_computed?: Maybe<String_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  paid?: Maybe<Boolean_Comparison_Exp>
  path?: Maybe<String_Comparison_Exp>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
  preview_collection_items?: Maybe<Collection_Cart_Item_Bool_Exp>
  private?: Maybe<Boolean_Comparison_Exp>
  searchable_name?: Maybe<String_Comparison_Exp>
  seo_fields?: Maybe<Jsonb_Comparison_Exp>
  short_id?: Maybe<Name_Comparison_Exp>
  slug?: Maybe<Name_Comparison_Exp>
  state?: Maybe<Collection_State_Enum_Comparison_Exp>
  steal_their_look?: Maybe<Boolean_Comparison_Exp>
  total_comments_count?: Maybe<Numeric_Comparison_Exp>
  total_likes_count?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  viewable_collection_items?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** columns and relationships of "collection_cart_item" */
export type Collection_Cart_Item = {
  __typename?: 'collection_cart_item'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  /** An object relationship */
  collection_section?: Maybe<Collection_Section>
  /** An object relationship */
  commentable_item_join?: Maybe<Commentable_Item>
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  id: Scalars['uuid']
  /** A computed field, executes function "collection_cart_item_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type: Notifiable_Item_Type_Enum
  order?: Maybe<Scalars['numeric']>
  polymorphic_type: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "collection_cart_item" */
export type Collection_Cart_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "collection_cart_item" */
export type Collection_Cart_Item_Aggregate = {
  __typename?: 'collection_cart_item_aggregate'
  aggregate?: Maybe<Collection_Cart_Item_Aggregate_Fields>
  nodes: Array<Collection_Cart_Item>
}

export type Collection_Cart_Item_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Cart_Item_Aggregate_Bool_Exp_Count>
}

export type Collection_Cart_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Cart_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_cart_item" */
export type Collection_Cart_Item_Aggregate_Fields = {
  __typename?: 'collection_cart_item_aggregate_fields'
  avg?: Maybe<Collection_Cart_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Cart_Item_Max_Fields>
  min?: Maybe<Collection_Cart_Item_Min_Fields>
  stddev?: Maybe<Collection_Cart_Item_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Cart_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Cart_Item_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Cart_Item_Sum_Fields>
  var_pop?: Maybe<Collection_Cart_Item_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Cart_Item_Var_Samp_Fields>
  variance?: Maybe<Collection_Cart_Item_Variance_Fields>
}

/** aggregate fields of "collection_cart_item" */
export type Collection_Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_cart_item" */
export type Collection_Cart_Item_Aggregate_Order_By = {
  avg?: Maybe<Collection_Cart_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Cart_Item_Max_Order_By>
  min?: Maybe<Collection_Cart_Item_Min_Order_By>
  stddev?: Maybe<Collection_Cart_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Cart_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Cart_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Cart_Item_Sum_Order_By>
  var_pop?: Maybe<Collection_Cart_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Cart_Item_Var_Samp_Order_By>
  variance?: Maybe<Collection_Cart_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Collection_Cart_Item_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "collection_cart_item" */
export type Collection_Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Collection_Cart_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Cart_Item_Avg_Fields = {
  __typename?: 'collection_cart_item_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection_cart_item". All fields are combined with a logical 'AND'. */
export type Collection_Cart_Item_Bool_Exp = {
  _and?: Maybe<Array<Collection_Cart_Item_Bool_Exp>>
  _not?: Maybe<Collection_Cart_Item_Bool_Exp>
  _or?: Maybe<Array<Collection_Cart_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  collection_section?: Maybe<Collection_Section_Bool_Exp>
  commentable_item_join?: Maybe<Commentable_Item_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  polymorphic_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_cart_item" */
export enum Collection_Cart_Item_Constraint {
  /** unique or primary key constraint on columns "cart_item_id", "collection_id" */
  CollectionCartItemCollectionIdCartItemIdKey = 'collection_cart_item_collection_id_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionCartItemIdKey = 'collection_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionCartItemPkey = 'collection_cart_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Collection_Cart_Item_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Collection_Cart_Item_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Collection_Cart_Item_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "collection_cart_item" */
export type Collection_Cart_Item_Inc_Input = {
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection_cart_item" */
export type Collection_Cart_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  collection_section?: Maybe<Collection_Section_Obj_Rel_Insert_Input>
  commentable_item_join?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Cart_Item_Max_Fields = {
  __typename?: 'collection_cart_item_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Cart_Item_Min_Fields = {
  __typename?: 'collection_cart_item_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_cart_item" */
export type Collection_Cart_Item_Mutation_Response = {
  __typename?: 'collection_cart_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Cart_Item>
}

/** input type for inserting object relation for remote table "collection_cart_item" */
export type Collection_Cart_Item_Obj_Rel_Insert_Input = {
  data: Collection_Cart_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** on_conflict condition type for table "collection_cart_item" */
export type Collection_Cart_Item_On_Conflict = {
  constraint: Collection_Cart_Item_Constraint
  update_columns?: Array<Collection_Cart_Item_Update_Column>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

/** Ordering options when selecting data from "collection_cart_item". */
export type Collection_Cart_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  collection_section?: Maybe<Collection_Section_Order_By>
  commentable_item_join?: Maybe<Commentable_Item_Order_By>
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  order?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_cart_item */
export type Collection_Cart_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Collection_Cart_Item_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "collection_cart_item" */
export enum Collection_Cart_Item_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Order = 'order',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_cart_item" */
export type Collection_Cart_Item_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Collection_Cart_Item_Stddev_Fields = {
  __typename?: 'collection_cart_item_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'collection_cart_item_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'collection_cart_item_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection_cart_item" */
export type Collection_Cart_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Cart_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Cart_Item_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  order?: Maybe<Scalars['numeric']>
  polymorphic_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Collection_Cart_Item_Sum_Fields = {
  __typename?: 'collection_cart_item_sum_fields'
  order?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "collection_cart_item" */
export enum Collection_Cart_Item_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  Order = 'order',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Cart_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Collection_Cart_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Collection_Cart_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Collection_Cart_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Collection_Cart_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Cart_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Collection_Cart_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Cart_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Cart_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Cart_Item_Var_Pop_Fields = {
  __typename?: 'collection_cart_item_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Cart_Item_Var_Samp_Fields = {
  __typename?: 'collection_cart_item_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Cart_Item_Variance_Fields = {
  __typename?: 'collection_cart_item_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "collection_cart_item" */
export type Collection_Cart_Item_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** columns and relationships of "collection_collaborator" */
export type Collection_Collaborator = {
  __typename?: 'collection_collaborator'
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  collection_invite_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  manager: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "collection_collaborator" */
export type Collection_Collaborator_Aggregate = {
  __typename?: 'collection_collaborator_aggregate'
  aggregate?: Maybe<Collection_Collaborator_Aggregate_Fields>
  nodes: Array<Collection_Collaborator>
}

export type Collection_Collaborator_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp_Count>
}

export type Collection_Collaborator_Aggregate_Bool_Exp_Bool_And = {
  arguments: Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Collaborator_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Collaborator_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Collection_Collaborator_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Collaborator_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Collaborator_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_collaborator" */
export type Collection_Collaborator_Aggregate_Fields = {
  __typename?: 'collection_collaborator_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Collaborator_Max_Fields>
  min?: Maybe<Collection_Collaborator_Min_Fields>
}

/** aggregate fields of "collection_collaborator" */
export type Collection_Collaborator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Collaborator_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_collaborator" */
export type Collection_Collaborator_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Collaborator_Max_Order_By>
  min?: Maybe<Collection_Collaborator_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_collaborator" */
export type Collection_Collaborator_Arr_Rel_Insert_Input = {
  data: Array<Collection_Collaborator_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_collaborator". All fields are combined with a logical 'AND'. */
export type Collection_Collaborator_Bool_Exp = {
  _and?: Maybe<Array<Collection_Collaborator_Bool_Exp>>
  _not?: Maybe<Collection_Collaborator_Bool_Exp>
  _or?: Maybe<Array<Collection_Collaborator_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  collection_invite_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  manager?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_collaborator" */
export enum Collection_Collaborator_Constraint {
  /** unique or primary key constraint on columns "user_id", "collection_id" */
  CollectionCollaboratorCollectionIdUserIdKey = 'collection_collaborator_collection_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionCollaboratorPkey = 'collection_collaborator_pkey',
}

/** input type for inserting data into table "collection_collaborator" */
export type Collection_Collaborator_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  manager?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Collaborator_Max_Fields = {
  __typename?: 'collection_collaborator_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "collection_collaborator" */
export type Collection_Collaborator_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  collection_invite_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Collaborator_Min_Fields = {
  __typename?: 'collection_collaborator_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "collection_collaborator" */
export type Collection_Collaborator_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  collection_invite_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_collaborator" */
export type Collection_Collaborator_Mutation_Response = {
  __typename?: 'collection_collaborator_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Collaborator>
}

/** input type for inserting object relation for remote table "collection_collaborator" */
export type Collection_Collaborator_Obj_Rel_Insert_Input = {
  data: Collection_Collaborator_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** on_conflict condition type for table "collection_collaborator" */
export type Collection_Collaborator_On_Conflict = {
  constraint: Collection_Collaborator_Constraint
  update_columns?: Array<Collection_Collaborator_Update_Column>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** Ordering options when selecting data from "collection_collaborator". */
export type Collection_Collaborator_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  collection_invite_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  manager?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: collection_collaborator */
export type Collection_Collaborator_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_collaborator" */
export enum Collection_Collaborator_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionInviteId = 'collection_invite_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Manager = 'manager',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "collection_collaborator_aggregate_bool_exp_bool_and_arguments_columns" columns of table "collection_collaborator" */
export enum Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Manager = 'manager',
}

/** select "collection_collaborator_aggregate_bool_exp_bool_or_arguments_columns" columns of table "collection_collaborator" */
export enum Collection_Collaborator_Select_Column_Collection_Collaborator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Manager = 'manager',
}

/** input type for updating data in table "collection_collaborator" */
export type Collection_Collaborator_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  manager?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_collaborator" */
export type Collection_Collaborator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Collaborator_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Collaborator_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  collection_invite_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  manager?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "collection_collaborator" */
export enum Collection_Collaborator_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionInviteId = 'collection_invite_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Manager = 'manager',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Collection_Collaborator_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Collaborator_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Collaborator_Bool_Exp
}

/** unique or primary key constraints on table "collection" */
export enum Collection_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionIdKey = 'collection_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionPkey = 'collection_pkey',
  /** unique or primary key constraint on columns "short_id" */
  CollectionShortIdKey = 'collection_short_id_key',
  /** unique or primary key constraint on columns "slug", "user_id" */
  CollectionUserIdSlugKey = 'collection_user_id_slug_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Collection_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
  notification_preferences?: Maybe<Array<Scalars['String']>>
  seo_fields?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Collection_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
  notification_preferences?: Maybe<Scalars['Int']>
  seo_fields?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Collection_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
  notification_preferences?: Maybe<Scalars['String']>
  seo_fields?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "collection" */
export type Collection_Inc_Input = {
  budget?: Maybe<Scalars['numeric']>
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection" */
export type Collection_Insert_Input = {
  all?: Maybe<Scalars['Boolean']>
  announce_collection?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['Boolean']>
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  collection_collaborators?: Maybe<Collection_Collaborator_Arr_Rel_Insert_Input>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_invites?: Maybe<Collection_Invite_Arr_Rel_Insert_Input>
  collection_items?: Maybe<Collection_Cart_Item_Arr_Rel_Insert_Input>
  collection_link?: Maybe<Scalars['String']>
  collection_sections?: Maybe<Collection_Section_Arr_Rel_Insert_Input>
  collection_slug_histories?: Maybe<Collection_Slug_History_Arr_Rel_Insert_Input>
  collection_state?: Maybe<Collection_State_Obj_Rel_Insert_Input>
  collection_type?: Maybe<Collection_Type_Enum>
  commentable_item_join?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  followable_item_type?: Maybe<Followable_Item_Type_Enum>
  giftlist?: Maybe<Scalars['Boolean']>
  has_collaborators?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  item_notifications?: Maybe<Scalars['Boolean']>
  media_enabled_join?: Maybe<Media_Enabled_Item_Obj_Rel_Insert_Input>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  notification_preferences?: Maybe<Scalars['jsonb']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  private?: Maybe<Scalars['Boolean']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug?: Maybe<Scalars['name']>
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** columns and relationships of "collection_invite" */
export type Collection_Invite = {
  __typename?: 'collection_invite'
  /** An object relationship */
  collection: Collection
  /** An object relationship */
  collection_collaborator?: Maybe<Collection_Collaborator>
  collection_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  /** A computed field, executes function "collection_invite_for_new_user" */
  for_new_user?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** An object relationship */
  invitee?: Maybe<User>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type: Notifiable_Item_Type_Enum
  /** An object relationship */
  requester: User
  requester_id: Scalars['String']
  status: Collection_Invite_Status_Enum
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "collection_invite" */
export type Collection_Invite_Aggregate = {
  __typename?: 'collection_invite_aggregate'
  aggregate?: Maybe<Collection_Invite_Aggregate_Fields>
  nodes: Array<Collection_Invite>
}

export type Collection_Invite_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Invite_Aggregate_Bool_Exp_Count>
}

export type Collection_Invite_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Invite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Invite_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_invite" */
export type Collection_Invite_Aggregate_Fields = {
  __typename?: 'collection_invite_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Invite_Max_Fields>
  min?: Maybe<Collection_Invite_Min_Fields>
}

/** aggregate fields of "collection_invite" */
export type Collection_Invite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Invite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_invite" */
export type Collection_Invite_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Invite_Max_Order_By>
  min?: Maybe<Collection_Invite_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_invite" */
export type Collection_Invite_Arr_Rel_Insert_Input = {
  data: Array<Collection_Invite_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_invite". All fields are combined with a logical 'AND'. */
export type Collection_Invite_Bool_Exp = {
  _and?: Maybe<Array<Collection_Invite_Bool_Exp>>
  _not?: Maybe<Collection_Invite_Bool_Exp>
  _or?: Maybe<Array<Collection_Invite_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_collaborator?: Maybe<Collection_Collaborator_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  for_new_user?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  invitee?: Maybe<User_Bool_Exp>
  invitee_email?: Maybe<String_Comparison_Exp>
  invitee_id?: Maybe<String_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  requester?: Maybe<User_Bool_Exp>
  requester_id?: Maybe<String_Comparison_Exp>
  status?: Maybe<Collection_Invite_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_invite" */
export enum Collection_Invite_Constraint {
  /** unique or primary key constraint on columns "invitee_id", "collection_id" */
  CollectionInviteCollectionIdInviteeIdKey = 'collection_invite_collection_id_invitee_id_key',
  /** unique or primary key constraint on columns "invitee_email", "invitee_id", "collection_id" */
  CollectionInviteEmailCollectionIdInviteeIdNotNullKey = 'collection_invite_email_collection_id_invitee_id_not_null_key',
  /** unique or primary key constraint on columns "invitee_email", "invitee_id", "collection_id" */
  CollectionInviteEmailCollectionIdInviteeIdNullKey = 'collection_invite_email_collection_id_invitee_id_null_key',
  /** unique or primary key constraint on columns "invitee_email", "collection_id" */
  CollectionInviteInviteeEmailCollectionIdKey = 'collection_invite_invitee_email_collection_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionInvitePkey = 'collection_invite_pkey',
}

/** input type for inserting data into table "collection_invite" */
export type Collection_Invite_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_collaborator?: Maybe<Collection_Collaborator_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee?: Maybe<User_Obj_Rel_Insert_Input>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  requester?: Maybe<User_Obj_Rel_Insert_Input>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Collection_Invite_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Invite_Max_Fields = {
  __typename?: 'collection_invite_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_invite" */
export type Collection_Invite_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee_email?: Maybe<Order_By>
  invitee_id?: Maybe<Order_By>
  requester_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Invite_Min_Fields = {
  __typename?: 'collection_invite_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_invite" */
export type Collection_Invite_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee_email?: Maybe<Order_By>
  invitee_id?: Maybe<Order_By>
  requester_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_invite" */
export type Collection_Invite_Mutation_Response = {
  __typename?: 'collection_invite_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Invite>
}

/** input type for inserting object relation for remote table "collection_invite" */
export type Collection_Invite_Obj_Rel_Insert_Input = {
  data: Collection_Invite_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** on_conflict condition type for table "collection_invite" */
export type Collection_Invite_On_Conflict = {
  constraint: Collection_Invite_Constraint
  update_columns?: Array<Collection_Invite_Update_Column>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

/** Ordering options when selecting data from "collection_invite". */
export type Collection_Invite_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_collaborator?: Maybe<Collection_Collaborator_Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  for_new_user?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee?: Maybe<User_Order_By>
  invitee_email?: Maybe<Order_By>
  invitee_id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  requester?: Maybe<User_Order_By>
  requester_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_invite */
export type Collection_Invite_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_invite" */
export enum Collection_Invite_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_invite" */
export type Collection_Invite_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Collection_Invite_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "collection_invite_status" */
export type Collection_Invite_Status = {
  __typename?: 'collection_invite_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "collection_invite_status" */
export type Collection_Invite_Status_Aggregate = {
  __typename?: 'collection_invite_status_aggregate'
  aggregate?: Maybe<Collection_Invite_Status_Aggregate_Fields>
  nodes: Array<Collection_Invite_Status>
}

/** aggregate fields of "collection_invite_status" */
export type Collection_Invite_Status_Aggregate_Fields = {
  __typename?: 'collection_invite_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Invite_Status_Max_Fields>
  min?: Maybe<Collection_Invite_Status_Min_Fields>
}

/** aggregate fields of "collection_invite_status" */
export type Collection_Invite_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_invite_status". All fields are combined with a logical 'AND'. */
export type Collection_Invite_Status_Bool_Exp = {
  _and?: Maybe<Array<Collection_Invite_Status_Bool_Exp>>
  _not?: Maybe<Collection_Invite_Status_Bool_Exp>
  _or?: Maybe<Array<Collection_Invite_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_invite_status" */
export enum Collection_Invite_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionInviteStatusPkey = 'collection_invite_status_pkey',
}

export enum Collection_Invite_Status_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
  Viewed = 'viewed',
}

/** Boolean expression to compare columns of type "collection_invite_status_enum". All fields are combined with logical 'AND'. */
export type Collection_Invite_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_Invite_Status_Enum>
  _in?: Maybe<Array<Collection_Invite_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_Invite_Status_Enum>
  _nin?: Maybe<Array<Collection_Invite_Status_Enum>>
}

/** input type for inserting data into table "collection_invite_status" */
export type Collection_Invite_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Invite_Status_Max_Fields = {
  __typename?: 'collection_invite_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_Invite_Status_Min_Fields = {
  __typename?: 'collection_invite_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_invite_status" */
export type Collection_Invite_Status_Mutation_Response = {
  __typename?: 'collection_invite_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Invite_Status>
}

/** on_conflict condition type for table "collection_invite_status" */
export type Collection_Invite_Status_On_Conflict = {
  constraint: Collection_Invite_Status_Constraint
  update_columns?: Array<Collection_Invite_Status_Update_Column>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

/** Ordering options when selecting data from "collection_invite_status". */
export type Collection_Invite_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_invite_status */
export type Collection_Invite_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_invite_status" */
export enum Collection_Invite_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_invite_status" */
export type Collection_Invite_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_invite_status" */
export type Collection_Invite_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Invite_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Invite_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_invite_status" */
export enum Collection_Invite_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_Invite_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Invite_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Invite_Status_Bool_Exp
}

/** Streaming cursor of the table "collection_invite" */
export type Collection_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Invite_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Invite_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_email?: Maybe<Scalars['String']>
  invitee_id?: Maybe<Scalars['String']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Collection_Invite_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "collection_invite" */
export enum Collection_Invite_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Invite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Invite_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Invite_Bool_Exp
}

/** Used for claiming collections items on wishlists */
export type Collection_Item_Claim = {
  __typename?: 'collection_item_claim'
  /** An object relationship */
  cart_item: Cart_Item
  cart_item_id: Scalars['uuid']
  /** An object relationship */
  claim_status: Collection_Item_Claim_Status
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  status: Collection_Item_Claim_Status_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "collection_item_claim" */
export type Collection_Item_Claim_Aggregate = {
  __typename?: 'collection_item_claim_aggregate'
  aggregate?: Maybe<Collection_Item_Claim_Aggregate_Fields>
  nodes: Array<Collection_Item_Claim>
}

export type Collection_Item_Claim_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Item_Claim_Aggregate_Bool_Exp_Count>
}

export type Collection_Item_Claim_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Item_Claim_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_item_claim" */
export type Collection_Item_Claim_Aggregate_Fields = {
  __typename?: 'collection_item_claim_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Item_Claim_Max_Fields>
  min?: Maybe<Collection_Item_Claim_Min_Fields>
}

/** aggregate fields of "collection_item_claim" */
export type Collection_Item_Claim_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_item_claim" */
export type Collection_Item_Claim_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Item_Claim_Max_Order_By>
  min?: Maybe<Collection_Item_Claim_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_item_claim" */
export type Collection_Item_Claim_Arr_Rel_Insert_Input = {
  data: Array<Collection_Item_Claim_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_item_claim". All fields are combined with a logical 'AND'. */
export type Collection_Item_Claim_Bool_Exp = {
  _and?: Maybe<Array<Collection_Item_Claim_Bool_Exp>>
  _not?: Maybe<Collection_Item_Claim_Bool_Exp>
  _or?: Maybe<Array<Collection_Item_Claim_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  claim_status?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<Collection_Item_Claim_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_item_claim" */
export enum Collection_Item_Claim_Constraint {
  /** unique or primary key constraint on columns "cart_item_id" */
  CollectionItemClaimCartItemIdKey = 'collection_item_claim_cart_item_id_key',
  /** unique or primary key constraint on columns "id" */
  CollectionItemClaimPkey = 'collection_item_claim_pkey',
}

/** input type for inserting data into table "collection_item_claim" */
export type Collection_Item_Claim_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  claim_status?: Maybe<Collection_Item_Claim_Status_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  status?: Maybe<Collection_Item_Claim_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Item_Claim_Max_Fields = {
  __typename?: 'collection_item_claim_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "collection_item_claim" */
export type Collection_Item_Claim_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Item_Claim_Min_Fields = {
  __typename?: 'collection_item_claim_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "collection_item_claim" */
export type Collection_Item_Claim_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_item_claim" */
export type Collection_Item_Claim_Mutation_Response = {
  __typename?: 'collection_item_claim_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Item_Claim>
}

/** input type for inserting object relation for remote table "collection_item_claim" */
export type Collection_Item_Claim_Obj_Rel_Insert_Input = {
  data: Collection_Item_Claim_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** on_conflict condition type for table "collection_item_claim" */
export type Collection_Item_Claim_On_Conflict = {
  constraint: Collection_Item_Claim_Constraint
  update_columns?: Array<Collection_Item_Claim_Update_Column>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

/** Ordering options when selecting data from "collection_item_claim". */
export type Collection_Item_Claim_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  claim_status?: Maybe<Collection_Item_Claim_Status_Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: collection_item_claim */
export type Collection_Item_Claim_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_item_claim" */
export enum Collection_Item_Claim_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "collection_item_claim" */
export type Collection_Item_Claim_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  status?: Maybe<Collection_Item_Claim_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Enum table to hold collection item claim statuses */
export type Collection_Item_Claim_Status = {
  __typename?: 'collection_item_claim_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Aggregate = {
  __typename?: 'collection_item_claim_status_aggregate'
  aggregate?: Maybe<Collection_Item_Claim_Status_Aggregate_Fields>
  nodes: Array<Collection_Item_Claim_Status>
}

/** aggregate fields of "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Aggregate_Fields = {
  __typename?: 'collection_item_claim_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Item_Claim_Status_Max_Fields>
  min?: Maybe<Collection_Item_Claim_Status_Min_Fields>
}

/** aggregate fields of "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_item_claim_status". All fields are combined with a logical 'AND'. */
export type Collection_Item_Claim_Status_Bool_Exp = {
  _and?: Maybe<Array<Collection_Item_Claim_Status_Bool_Exp>>
  _not?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
  _or?: Maybe<Array<Collection_Item_Claim_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_item_claim_status" */
export enum Collection_Item_Claim_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionItemClaimStatusPkey = 'collection_item_claim_status_pkey',
}

export enum Collection_Item_Claim_Status_Enum {
  /** Collection item claimed */
  Claimed = 'claimed',
  /** Default collection item claim status */
  Unclaimed = 'unclaimed',
}

/** Boolean expression to compare columns of type "collection_item_claim_status_enum". All fields are combined with logical 'AND'. */
export type Collection_Item_Claim_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_Item_Claim_Status_Enum>
  _in?: Maybe<Array<Collection_Item_Claim_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_Item_Claim_Status_Enum>
  _nin?: Maybe<Array<Collection_Item_Claim_Status_Enum>>
}

/** input type for inserting data into table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Item_Claim_Status_Max_Fields = {
  __typename?: 'collection_item_claim_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_Item_Claim_Status_Min_Fields = {
  __typename?: 'collection_item_claim_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Mutation_Response = {
  __typename?: 'collection_item_claim_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Item_Claim_Status>
}

/** input type for inserting object relation for remote table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Obj_Rel_Insert_Input = {
  data: Collection_Item_Claim_Status_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Item_Claim_Status_On_Conflict>
}

/** on_conflict condition type for table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_On_Conflict = {
  constraint: Collection_Item_Claim_Status_Constraint
  update_columns?: Array<Collection_Item_Claim_Status_Update_Column>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

/** Ordering options when selecting data from "collection_item_claim_status". */
export type Collection_Item_Claim_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_item_claim_status */
export type Collection_Item_Claim_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_item_claim_status" */
export enum Collection_Item_Claim_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_item_claim_status" */
export type Collection_Item_Claim_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Item_Claim_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Item_Claim_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_item_claim_status" */
export enum Collection_Item_Claim_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_Item_Claim_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Item_Claim_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Item_Claim_Status_Bool_Exp
}

/** Streaming cursor of the table "collection_item_claim" */
export type Collection_Item_Claim_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Item_Claim_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Item_Claim_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  status?: Maybe<Collection_Item_Claim_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "collection_item_claim" */
export enum Collection_Item_Claim_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Collection_Item_Claim_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Item_Claim_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Item_Claim_Bool_Exp
}

/** aggregate max on columns */
export type Collection_Max_Fields = {
  __typename?: 'collection_max_fields'
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cover_image_cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_open_collab_link" */
  open_collab_link_computed?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "collection_path" */
  path?: Maybe<Scalars['String']>
  searchable_name?: Maybe<Scalars['String']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "collection" */
export type Collection_Max_Order_By = {
  budget?: Maybe<Order_By>
  cart_item_max_updated_at?: Maybe<Order_By>
  collection_cover_image?: Maybe<Order_By>
  collection_cover_images?: Maybe<Order_By>
  collection_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  email_excerpt?: Maybe<Order_By>
  id?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  order?: Maybe<Order_By>
  searchable_name?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Min_Fields = {
  __typename?: 'collection_min_fields'
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cover_image_cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  /** A computed field, executes function "collection_open_collab_link" */
  open_collab_link_computed?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "collection_path" */
  path?: Maybe<Scalars['String']>
  searchable_name?: Maybe<Scalars['String']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "collection" */
export type Collection_Min_Order_By = {
  budget?: Maybe<Order_By>
  cart_item_max_updated_at?: Maybe<Order_By>
  collection_cover_image?: Maybe<Order_By>
  collection_cover_images?: Maybe<Order_By>
  collection_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  email_excerpt?: Maybe<Order_By>
  id?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  order?: Maybe<Order_By>
  searchable_name?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "collection" */
export type Collection_Mutation_Response = {
  __typename?: 'collection_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection>
}

/** input type for inserting object relation for remote table "collection" */
export type Collection_Obj_Rel_Insert_Input = {
  data: Collection_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** on_conflict condition type for table "collection" */
export type Collection_On_Conflict = {
  constraint: Collection_Constraint
  update_columns?: Array<Collection_Update_Column>
  where?: Maybe<Collection_Bool_Exp>
}

/** Ordering options when selecting data from "collection". */
export type Collection_Order_By = {
  all?: Maybe<Order_By>
  announce_collection?: Maybe<Order_By>
  bookmarks?: Maybe<Order_By>
  budget?: Maybe<Order_By>
  cart_item_max_updated_at?: Maybe<Order_By>
  cart_items_count?: Maybe<Order_By>
  cart_items_quantity?: Maybe<Order_By>
  collaborators_count?: Maybe<Order_By>
  collection_collaborators_aggregate?: Maybe<Collection_Collaborator_Aggregate_Order_By>
  collection_cover_image?: Maybe<Order_By>
  collection_cover_images?: Maybe<Order_By>
  collection_invites_aggregate?: Maybe<Collection_Invite_Aggregate_Order_By>
  collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
  collection_link?: Maybe<Order_By>
  collection_sections_aggregate?: Maybe<Collection_Section_Aggregate_Order_By>
  collection_slug_histories_aggregate?: Maybe<Collection_Slug_History_Aggregate_Order_By>
  collection_state?: Maybe<Collection_State_Order_By>
  collection_type?: Maybe<Order_By>
  commentable_item_join?: Maybe<Commentable_Item_Order_By>
  computed_is_visible?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  cover_image_cached?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  curation?: Maybe<Order_By>
  description?: Maybe<Order_By>
  email_excerpt?: Maybe<Order_By>
  exclusive?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  followed_by_user?: Maybe<Order_By>
  giftlist?: Maybe<Order_By>
  has_collaborators?: Maybe<Order_By>
  has_valid_preview_images_computed?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_a_collaborator?: Maybe<Order_By>
  item_notifications?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  media_enabled_join?: Maybe<Media_Enabled_Item_Order_By>
  metadata?: Maybe<Order_By>
  name?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  notification_preferences?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  open_collab_link_computed?: Maybe<Order_By>
  order?: Maybe<Order_By>
  paid?: Maybe<Order_By>
  path?: Maybe<Order_By>
  polymorphic_type?: Maybe<Order_By>
  preview_collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
  private?: Maybe<Order_By>
  searchable_name?: Maybe<Order_By>
  seo_fields?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  state?: Maybe<Order_By>
  steal_their_look?: Maybe<Order_By>
  total_comments_count?: Maybe<Order_By>
  total_likes_count?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  viewable_collection_items_aggregate?: Maybe<Collection_Cart_Item_Aggregate_Order_By>
}

/** primary key columns input for table: collection */
export type Collection_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Collection_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  notification_preferences?: Maybe<Scalars['jsonb']>
  seo_fields?: Maybe<Scalars['jsonb']>
}

export type Collection_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** Used for Collection sections */
export type Collection_Section = {
  __typename?: 'collection_section'
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  /** An array relationship */
  collection_section_cart_items: Array<Collection_Section_Cart_Item>
  /** An aggregate relationship */
  collection_section_cart_items_aggregate: Collection_Section_Cart_Item_Aggregate
  created_at: Scalars['timestamptz']
  description?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  name: Scalars['String']
  order: Scalars['numeric']
  slug: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** Used for Collection sections */
export type Collection_SectionCollection_Section_Cart_ItemsArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

/** Used for Collection sections */
export type Collection_SectionCollection_Section_Cart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

/** aggregated selection of "collection_section" */
export type Collection_Section_Aggregate = {
  __typename?: 'collection_section_aggregate'
  aggregate?: Maybe<Collection_Section_Aggregate_Fields>
  nodes: Array<Collection_Section>
}

export type Collection_Section_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Section_Aggregate_Bool_Exp_Count>
}

export type Collection_Section_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Section_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Section_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_section" */
export type Collection_Section_Aggregate_Fields = {
  __typename?: 'collection_section_aggregate_fields'
  avg?: Maybe<Collection_Section_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Section_Max_Fields>
  min?: Maybe<Collection_Section_Min_Fields>
  stddev?: Maybe<Collection_Section_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Section_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Section_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Section_Sum_Fields>
  var_pop?: Maybe<Collection_Section_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Section_Var_Samp_Fields>
  variance?: Maybe<Collection_Section_Variance_Fields>
}

/** aggregate fields of "collection_section" */
export type Collection_Section_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Section_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_section" */
export type Collection_Section_Aggregate_Order_By = {
  avg?: Maybe<Collection_Section_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Section_Max_Order_By>
  min?: Maybe<Collection_Section_Min_Order_By>
  stddev?: Maybe<Collection_Section_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Section_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Section_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Section_Sum_Order_By>
  var_pop?: Maybe<Collection_Section_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Section_Var_Samp_Order_By>
  variance?: Maybe<Collection_Section_Variance_Order_By>
}

/** input type for inserting array relation for remote table "collection_section" */
export type Collection_Section_Arr_Rel_Insert_Input = {
  data: Array<Collection_Section_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Section_Avg_Fields = {
  __typename?: 'collection_section_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "collection_section" */
export type Collection_Section_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection_section". All fields are combined with a logical 'AND'. */
export type Collection_Section_Bool_Exp = {
  _and?: Maybe<Array<Collection_Section_Bool_Exp>>
  _not?: Maybe<Collection_Section_Bool_Exp>
  _or?: Maybe<Array<Collection_Section_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  collection_section_cart_items?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
  collection_section_cart_items_aggregate?: Maybe<Collection_Section_Cart_Item_Aggregate_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  slug?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** Join table for collection cart items and collection sections */
export type Collection_Section_Cart_Item = {
  __typename?: 'collection_section_cart_item'
  /** An object relationship */
  collection_cart_item: Collection_Cart_Item
  collection_cart_item_id: Scalars['uuid']
  /** An object relationship */
  collection_section: Collection_Section
  collection_section_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  order?: Maybe<Scalars['numeric']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate = {
  __typename?: 'collection_section_cart_item_aggregate'
  aggregate?: Maybe<Collection_Section_Cart_Item_Aggregate_Fields>
  nodes: Array<Collection_Section_Cart_Item>
}

export type Collection_Section_Cart_Item_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Section_Cart_Item_Aggregate_Bool_Exp_Count>
}

export type Collection_Section_Cart_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate_Fields = {
  __typename?: 'collection_section_cart_item_aggregate_fields'
  avg?: Maybe<Collection_Section_Cart_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Collection_Section_Cart_Item_Max_Fields>
  min?: Maybe<Collection_Section_Cart_Item_Min_Fields>
  stddev?: Maybe<Collection_Section_Cart_Item_Stddev_Fields>
  stddev_pop?: Maybe<Collection_Section_Cart_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Collection_Section_Cart_Item_Stddev_Samp_Fields>
  sum?: Maybe<Collection_Section_Cart_Item_Sum_Fields>
  var_pop?: Maybe<Collection_Section_Cart_Item_Var_Pop_Fields>
  var_samp?: Maybe<Collection_Section_Cart_Item_Var_Samp_Fields>
  variance?: Maybe<Collection_Section_Cart_Item_Variance_Fields>
}

/** aggregate fields of "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Aggregate_Order_By = {
  avg?: Maybe<Collection_Section_Cart_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Section_Cart_Item_Max_Order_By>
  min?: Maybe<Collection_Section_Cart_Item_Min_Order_By>
  stddev?: Maybe<Collection_Section_Cart_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Collection_Section_Cart_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Collection_Section_Cart_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Collection_Section_Cart_Item_Sum_Order_By>
  var_pop?: Maybe<Collection_Section_Cart_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Collection_Section_Cart_Item_Var_Samp_Order_By>
  variance?: Maybe<Collection_Section_Cart_Item_Variance_Order_By>
}

/** input type for inserting array relation for remote table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Arr_Rel_Insert_Input = {
  data: Array<Collection_Section_Cart_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Section_Cart_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Collection_Section_Cart_Item_Avg_Fields = {
  __typename?: 'collection_section_cart_item_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "collection_section_cart_item". All fields are combined with a logical 'AND'. */
export type Collection_Section_Cart_Item_Bool_Exp = {
  _and?: Maybe<Array<Collection_Section_Cart_Item_Bool_Exp>>
  _not?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
  _or?: Maybe<Array<Collection_Section_Cart_Item_Bool_Exp>>
  collection_cart_item?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_cart_item_id?: Maybe<Uuid_Comparison_Exp>
  collection_section?: Maybe<Collection_Section_Bool_Exp>
  collection_section_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  order?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_section_cart_item" */
export enum Collection_Section_Cart_Item_Constraint {
  /** unique or primary key constraint on columns "collection_section_id", "collection_cart_item_id" */
  CollectionSectionCartItemCartItemIdCollectionSectioKey = 'collection_section_cart_item_cart_item_id_collection_sectio_key',
  /** unique or primary key constraint on columns "id" */
  CollectionSectionCartItemPkey = 'collection_section_cart_item_pkey',
}

/** input type for incrementing numeric columns in table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Inc_Input = {
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Insert_Input = {
  collection_cart_item?: Maybe<Collection_Cart_Item_Obj_Rel_Insert_Input>
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section?: Maybe<Collection_Section_Obj_Rel_Insert_Input>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Section_Cart_Item_Max_Fields = {
  __typename?: 'collection_section_cart_item_max_fields'
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Max_Order_By = {
  collection_cart_item_id?: Maybe<Order_By>
  collection_section_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Section_Cart_Item_Min_Fields = {
  __typename?: 'collection_section_cart_item_min_fields'
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Min_Order_By = {
  collection_cart_item_id?: Maybe<Order_By>
  collection_section_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Mutation_Response = {
  __typename?: 'collection_section_cart_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Section_Cart_Item>
}

/** on_conflict condition type for table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_On_Conflict = {
  constraint: Collection_Section_Cart_Item_Constraint
  update_columns?: Array<Collection_Section_Cart_Item_Update_Column>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

/** Ordering options when selecting data from "collection_section_cart_item". */
export type Collection_Section_Cart_Item_Order_By = {
  collection_cart_item?: Maybe<Collection_Cart_Item_Order_By>
  collection_cart_item_id?: Maybe<Order_By>
  collection_section?: Maybe<Collection_Section_Order_By>
  collection_section_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  order?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_section_cart_item */
export type Collection_Section_Cart_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_section_cart_item" */
export enum Collection_Section_Cart_Item_Select_Column {
  /** column name */
  CollectionCartItemId = 'collection_cart_item_id',
  /** column name */
  CollectionSectionId = 'collection_section_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Set_Input = {
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Collection_Section_Cart_Item_Stddev_Fields = {
  __typename?: 'collection_section_cart_item_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Section_Cart_Item_Stddev_Pop_Fields = {
  __typename?: 'collection_section_cart_item_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Section_Cart_Item_Stddev_Samp_Fields = {
  __typename?: 'collection_section_cart_item_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Section_Cart_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Section_Cart_Item_Stream_Cursor_Value_Input = {
  collection_cart_item_id?: Maybe<Scalars['uuid']>
  collection_section_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  order?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Collection_Section_Cart_Item_Sum_Fields = {
  __typename?: 'collection_section_cart_item_sum_fields'
  order?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "collection_section_cart_item" */
export enum Collection_Section_Cart_Item_Update_Column {
  /** column name */
  CollectionCartItemId = 'collection_cart_item_id',
  /** column name */
  CollectionSectionId = 'collection_section_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Section_Cart_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Section_Cart_Item_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Section_Cart_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Section_Cart_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Section_Cart_Item_Var_Pop_Fields = {
  __typename?: 'collection_section_cart_item_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Section_Cart_Item_Var_Samp_Fields = {
  __typename?: 'collection_section_cart_item_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Section_Cart_Item_Variance_Fields = {
  __typename?: 'collection_section_cart_item_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "collection_section_cart_item" */
export type Collection_Section_Cart_Item_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** unique or primary key constraints on table "collection_section" */
export enum Collection_Section_Constraint {
  /** unique or primary key constraint on columns "id" */
  CollectionSectionPkey = 'collection_section_pkey',
}

/** input type for incrementing numeric columns in table "collection_section" */
export type Collection_Section_Inc_Input = {
  order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "collection_section" */
export type Collection_Section_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  collection_section_cart_items?: Maybe<Collection_Section_Cart_Item_Arr_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Section_Max_Fields = {
  __typename?: 'collection_section_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_section" */
export type Collection_Section_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  order?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Section_Min_Fields = {
  __typename?: 'collection_section_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_section" */
export type Collection_Section_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  order?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_section" */
export type Collection_Section_Mutation_Response = {
  __typename?: 'collection_section_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Section>
}

/** input type for inserting object relation for remote table "collection_section" */
export type Collection_Section_Obj_Rel_Insert_Input = {
  data: Collection_Section_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** on_conflict condition type for table "collection_section" */
export type Collection_Section_On_Conflict = {
  constraint: Collection_Section_Constraint
  update_columns?: Array<Collection_Section_Update_Column>
  where?: Maybe<Collection_Section_Bool_Exp>
}

/** Ordering options when selecting data from "collection_section". */
export type Collection_Section_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  collection_section_cart_items_aggregate?: Maybe<Collection_Section_Cart_Item_Aggregate_Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  order?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_section */
export type Collection_Section_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "collection_section" */
export enum Collection_Section_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_section" */
export type Collection_Section_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Collection_Section_Stddev_Fields = {
  __typename?: 'collection_section_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "collection_section" */
export type Collection_Section_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Section_Stddev_Pop_Fields = {
  __typename?: 'collection_section_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "collection_section" */
export type Collection_Section_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Section_Stddev_Samp_Fields = {
  __typename?: 'collection_section_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "collection_section" */
export type Collection_Section_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection_section" */
export type Collection_Section_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Section_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Section_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  slug?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Collection_Section_Sum_Fields = {
  __typename?: 'collection_section_sum_fields'
  order?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection_section" */
export type Collection_Section_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "collection_section" */
export enum Collection_Section_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Section_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Section_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Section_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Section_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Section_Var_Pop_Fields = {
  __typename?: 'collection_section_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "collection_section" */
export type Collection_Section_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Section_Var_Samp_Fields = {
  __typename?: 'collection_section_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "collection_section" */
export type Collection_Section_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Section_Variance_Fields = {
  __typename?: 'collection_section_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "collection_section" */
export type Collection_Section_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** select columns of table "collection" */
export enum Collection_Select_Column {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Budget = 'budget',
  /** column name */
  CartItemMaxUpdatedAt = 'cart_item_max_updated_at',
  /** column name */
  CollectionCoverImage = 'collection_cover_image',
  /** column name */
  CollectionCoverImages = 'collection_cover_images',
  /** column name */
  CollectionLink = 'collection_link',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Curation = 'curation',
  /** column name */
  Description = 'description',
  /** column name */
  EmailExcerpt = 'email_excerpt',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  Id = 'id',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  NotificationPreferences = 'notification_preferences',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Order = 'order',
  /** column name */
  Paid = 'paid',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Private = 'private',
  /** column name */
  SearchableName = 'searchable_name',
  /** column name */
  SeoFields = 'seo_fields',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  StealTheirLook = 'steal_their_look',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "collection_aggregate_bool_exp_bool_and_arguments_columns" columns of table "collection" */
export enum Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Curation = 'curation',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Paid = 'paid',
  /** column name */
  Private = 'private',
  /** column name */
  StealTheirLook = 'steal_their_look',
}

/** select "collection_aggregate_bool_exp_bool_or_arguments_columns" columns of table "collection" */
export enum Collection_Select_Column_Collection_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Curation = 'curation',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Paid = 'paid',
  /** column name */
  Private = 'private',
  /** column name */
  StealTheirLook = 'steal_their_look',
}

/** input type for updating data in table "collection" */
export type Collection_Set_Input = {
  all?: Maybe<Scalars['Boolean']>
  announce_collection?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['Boolean']>
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  collection_type?: Maybe<Collection_Type_Enum>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  followable_item_type?: Maybe<Followable_Item_Type_Enum>
  giftlist?: Maybe<Scalars['Boolean']>
  has_collaborators?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  item_notifications?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  notification_preferences?: Maybe<Scalars['jsonb']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  private?: Maybe<Scalars['Boolean']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug?: Maybe<Scalars['name']>
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Table to keep track of collection slug changes */
export type Collection_Slug_History = {
  __typename?: 'collection_slug_history'
  /** An object relationship */
  collection: Collection
  collection_id: Scalars['uuid']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  slug: Scalars['name']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "collection_slug_history" */
export type Collection_Slug_History_Aggregate = {
  __typename?: 'collection_slug_history_aggregate'
  aggregate?: Maybe<Collection_Slug_History_Aggregate_Fields>
  nodes: Array<Collection_Slug_History>
}

export type Collection_Slug_History_Aggregate_Bool_Exp = {
  count?: Maybe<Collection_Slug_History_Aggregate_Bool_Exp_Count>
}

export type Collection_Slug_History_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Collection_Slug_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Collection_Slug_History_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "collection_slug_history" */
export type Collection_Slug_History_Aggregate_Fields = {
  __typename?: 'collection_slug_history_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Slug_History_Max_Fields>
  min?: Maybe<Collection_Slug_History_Min_Fields>
}

/** aggregate fields of "collection_slug_history" */
export type Collection_Slug_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Slug_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "collection_slug_history" */
export type Collection_Slug_History_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Collection_Slug_History_Max_Order_By>
  min?: Maybe<Collection_Slug_History_Min_Order_By>
}

/** input type for inserting array relation for remote table "collection_slug_history" */
export type Collection_Slug_History_Arr_Rel_Insert_Input = {
  data: Array<Collection_Slug_History_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Collection_Slug_History_On_Conflict>
}

/** Boolean expression to filter rows from the table "collection_slug_history". All fields are combined with a logical 'AND'. */
export type Collection_Slug_History_Bool_Exp = {
  _and?: Maybe<Array<Collection_Slug_History_Bool_Exp>>
  _not?: Maybe<Collection_Slug_History_Bool_Exp>
  _or?: Maybe<Array<Collection_Slug_History_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  collection_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  slug?: Maybe<Name_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_slug_history" */
export enum Collection_Slug_History_Constraint {
  /** unique or primary key constraint on columns "slug", "collection_id" */
  CollectionSlugHistoryPkey = 'collection_slug_history_pkey',
}

/** input type for inserting data into table "collection_slug_history" */
export type Collection_Slug_History_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Collection_Slug_History_Max_Fields = {
  __typename?: 'collection_slug_history_max_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "collection_slug_history" */
export type Collection_Slug_History_Max_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Collection_Slug_History_Min_Fields = {
  __typename?: 'collection_slug_history_min_fields'
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "collection_slug_history" */
export type Collection_Slug_History_Min_Order_By = {
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "collection_slug_history" */
export type Collection_Slug_History_Mutation_Response = {
  __typename?: 'collection_slug_history_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Slug_History>
}

/** on_conflict condition type for table "collection_slug_history" */
export type Collection_Slug_History_On_Conflict = {
  constraint: Collection_Slug_History_Constraint
  update_columns?: Array<Collection_Slug_History_Update_Column>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

/** Ordering options when selecting data from "collection_slug_history". */
export type Collection_Slug_History_Order_By = {
  collection?: Maybe<Collection_Order_By>
  collection_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: collection_slug_history */
export type Collection_Slug_History_Pk_Columns_Input = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

/** select columns of table "collection_slug_history" */
export enum Collection_Slug_History_Select_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collection_slug_history" */
export type Collection_Slug_History_Set_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "collection_slug_history" */
export type Collection_Slug_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Slug_History_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Slug_History_Stream_Cursor_Value_Input = {
  collection_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  slug?: Maybe<Scalars['name']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "collection_slug_history" */
export enum Collection_Slug_History_Update_Column {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Collection_Slug_History_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Slug_History_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Slug_History_Bool_Exp
}

/** Collection state enum (ie, Draft, Published) */
export type Collection_State = {
  __typename?: 'collection_state'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "collection_state" */
export type Collection_State_Aggregate = {
  __typename?: 'collection_state_aggregate'
  aggregate?: Maybe<Collection_State_Aggregate_Fields>
  nodes: Array<Collection_State>
}

/** aggregate fields of "collection_state" */
export type Collection_State_Aggregate_Fields = {
  __typename?: 'collection_state_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_State_Max_Fields>
  min?: Maybe<Collection_State_Min_Fields>
}

/** aggregate fields of "collection_state" */
export type Collection_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_State_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_state". All fields are combined with a logical 'AND'. */
export type Collection_State_Bool_Exp = {
  _and?: Maybe<Array<Collection_State_Bool_Exp>>
  _not?: Maybe<Collection_State_Bool_Exp>
  _or?: Maybe<Array<Collection_State_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_state" */
export enum Collection_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionStatePkey = 'collection_state_pkey',
}

export enum Collection_State_Enum {
  /** Draft */
  Draft = 'draft',
  /** Published */
  Published = 'published',
}

/** Boolean expression to compare columns of type "collection_state_enum". All fields are combined with logical 'AND'. */
export type Collection_State_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_State_Enum>
  _in?: Maybe<Array<Collection_State_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_State_Enum>
  _nin?: Maybe<Array<Collection_State_Enum>>
}

/** input type for inserting data into table "collection_state" */
export type Collection_State_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_State_Max_Fields = {
  __typename?: 'collection_state_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_State_Min_Fields = {
  __typename?: 'collection_state_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_state" */
export type Collection_State_Mutation_Response = {
  __typename?: 'collection_state_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_State>
}

/** input type for inserting object relation for remote table "collection_state" */
export type Collection_State_Obj_Rel_Insert_Input = {
  data: Collection_State_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Collection_State_On_Conflict>
}

/** on_conflict condition type for table "collection_state" */
export type Collection_State_On_Conflict = {
  constraint: Collection_State_Constraint
  update_columns?: Array<Collection_State_Update_Column>
  where?: Maybe<Collection_State_Bool_Exp>
}

/** Ordering options when selecting data from "collection_state". */
export type Collection_State_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_state */
export type Collection_State_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_state" */
export enum Collection_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_state" */
export type Collection_State_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_state" */
export type Collection_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_State_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_state" */
export enum Collection_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_State_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_State_Bool_Exp
}

/** aggregate stddev on columns */
export type Collection_Stddev_Fields = {
  __typename?: 'collection_stddev_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by stddev() on columns of table "collection" */
export type Collection_Stddev_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Collection_Stddev_Pop_Fields = {
  __typename?: 'collection_stddev_pop_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by stddev_pop() on columns of table "collection" */
export type Collection_Stddev_Pop_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Collection_Stddev_Samp_Fields = {
  __typename?: 'collection_stddev_samp_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by stddev_samp() on columns of table "collection" */
export type Collection_Stddev_Samp_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "collection" */
export type Collection_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Stream_Cursor_Value_Input = {
  all?: Maybe<Scalars['Boolean']>
  announce_collection?: Maybe<Scalars['Boolean']>
  bookmarks?: Maybe<Scalars['Boolean']>
  budget?: Maybe<Scalars['numeric']>
  cart_item_max_updated_at?: Maybe<Scalars['timestamptz']>
  collection_cover_image?: Maybe<Scalars['String']>
  collection_cover_images?: Maybe<Array<Scalars['String']>>
  collection_link?: Maybe<Scalars['String']>
  collection_type?: Maybe<Collection_Type_Enum>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  curation?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  email_excerpt?: Maybe<Scalars['String']>
  exclusive?: Maybe<Scalars['Boolean']>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  followable_item_type?: Maybe<Followable_Item_Type_Enum>
  giftlist?: Maybe<Scalars['Boolean']>
  has_collaborators?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  item_notifications?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  notification_preferences?: Maybe<Scalars['jsonb']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['numeric']>
  paid?: Maybe<Scalars['Boolean']>
  polymorphic_type?: Maybe<Commentable_Item_Type_Enum>
  private?: Maybe<Scalars['Boolean']>
  searchable_name?: Maybe<Scalars['String']>
  seo_fields?: Maybe<Scalars['jsonb']>
  short_id?: Maybe<Scalars['name']>
  slug?: Maybe<Scalars['name']>
  state?: Maybe<Collection_State_Enum>
  steal_their_look?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Collection_Sum_Fields = {
  __typename?: 'collection_sum_fields'
  budget?: Maybe<Scalars['numeric']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "collection" */
export type Collection_Sum_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Collection type enum table */
export type Collection_Type = {
  __typename?: 'collection_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "collection_type" */
export type Collection_Type_Aggregate = {
  __typename?: 'collection_type_aggregate'
  aggregate?: Maybe<Collection_Type_Aggregate_Fields>
  nodes: Array<Collection_Type>
}

/** aggregate fields of "collection_type" */
export type Collection_Type_Aggregate_Fields = {
  __typename?: 'collection_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Collection_Type_Max_Fields>
  min?: Maybe<Collection_Type_Min_Fields>
}

/** aggregate fields of "collection_type" */
export type Collection_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Collection_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "collection_type". All fields are combined with a logical 'AND'. */
export type Collection_Type_Bool_Exp = {
  _and?: Maybe<Array<Collection_Type_Bool_Exp>>
  _not?: Maybe<Collection_Type_Bool_Exp>
  _or?: Maybe<Array<Collection_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "collection_type" */
export enum Collection_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  CollectionTypePkey = 'collection_type_pkey',
}

export enum Collection_Type_Enum {
  /** Contest collection type (ie, I-to-I) */
  Contest = 'contest',
  /** Default collection type */
  Default = 'default',
  /** Landing Page */
  LandingPage = 'landing_page',
  /** Shared collection type */
  Shared = 'shared',
}

/** Boolean expression to compare columns of type "collection_type_enum". All fields are combined with logical 'AND'. */
export type Collection_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Collection_Type_Enum>
  _in?: Maybe<Array<Collection_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Collection_Type_Enum>
  _nin?: Maybe<Array<Collection_Type_Enum>>
}

/** input type for inserting data into table "collection_type" */
export type Collection_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Collection_Type_Max_Fields = {
  __typename?: 'collection_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Collection_Type_Min_Fields = {
  __typename?: 'collection_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "collection_type" */
export type Collection_Type_Mutation_Response = {
  __typename?: 'collection_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Collection_Type>
}

/** on_conflict condition type for table "collection_type" */
export type Collection_Type_On_Conflict = {
  constraint: Collection_Type_Constraint
  update_columns?: Array<Collection_Type_Update_Column>
  where?: Maybe<Collection_Type_Bool_Exp>
}

/** Ordering options when selecting data from "collection_type". */
export type Collection_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: collection_type */
export type Collection_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "collection_type" */
export enum Collection_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "collection_type" */
export type Collection_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "collection_type" */
export type Collection_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Collection_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Collection_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "collection_type" */
export enum Collection_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Collection_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Type_Bool_Exp
}

/** update columns of table "collection" */
export enum Collection_Update_Column {
  /** column name */
  All = 'all',
  /** column name */
  AnnounceCollection = 'announce_collection',
  /** column name */
  Bookmarks = 'bookmarks',
  /** column name */
  Budget = 'budget',
  /** column name */
  CartItemMaxUpdatedAt = 'cart_item_max_updated_at',
  /** column name */
  CollectionCoverImage = 'collection_cover_image',
  /** column name */
  CollectionCoverImages = 'collection_cover_images',
  /** column name */
  CollectionLink = 'collection_link',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Curation = 'curation',
  /** column name */
  Description = 'description',
  /** column name */
  EmailExcerpt = 'email_excerpt',
  /** column name */
  Exclusive = 'exclusive',
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Giftlist = 'giftlist',
  /** column name */
  HasCollaborators = 'has_collaborators',
  /** column name */
  Id = 'id',
  /** column name */
  ItemNotifications = 'item_notifications',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  NotificationPreferences = 'notification_preferences',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Order = 'order',
  /** column name */
  Paid = 'paid',
  /** column name */
  PolymorphicType = 'polymorphic_type',
  /** column name */
  Private = 'private',
  /** column name */
  SeoFields = 'seo_fields',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  State = 'state',
  /** column name */
  StealTheirLook = 'steal_their_look',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Collection_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Collection_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Collection_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Collection_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Collection_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Collection_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Collection_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Collection_Set_Input>
  /** filter the rows which have to be updated */
  where: Collection_Bool_Exp
}

/** aggregate var_pop on columns */
export type Collection_Var_Pop_Fields = {
  __typename?: 'collection_var_pop_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by var_pop() on columns of table "collection" */
export type Collection_Var_Pop_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Collection_Var_Samp_Fields = {
  __typename?: 'collection_var_samp_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by var_samp() on columns of table "collection" */
export type Collection_Var_Samp_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Collection_Variance_Fields = {
  __typename?: 'collection_variance_fields'
  budget?: Maybe<Scalars['Float']>
  /** returns count of collection cart items on a collection */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** Computes the SUM of the collection cart items quantity */
  cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A function used to determine the number of collaborators on a collection */
  collaborators_count?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Float']>
  /** A function used to determine the total number of comments for a `collection`. */
  total_comments_count?: Maybe<Scalars['numeric']>
  /** A function used to determine the total number of likes for a `collection`. */
  total_likes_count?: Maybe<Scalars['numeric']>
}

/** order by variance() on columns of table "collection" */
export type Collection_Variance_Order_By = {
  budget?: Maybe<Order_By>
  order?: Maybe<Order_By>
}

/** Generalized polymorphic comment table */
export type Comment = {
  __typename?: 'comment'
  comment: Scalars['String']
  /** An object relationship */
  commentable_item?: Maybe<Commentable_Item>
  commentable_item_id: Scalars['uuid']
  commentable_item_type: Commentable_Item_Type_Enum
  created_at: Scalars['timestamptz']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  notifiable_item_type: Notifiable_Item_Type_Enum
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "comment" */
export type Comment_Aggregate = {
  __typename?: 'comment_aggregate'
  aggregate?: Maybe<Comment_Aggregate_Fields>
  nodes: Array<Comment>
}

export type Comment_Aggregate_Bool_Exp = {
  count?: Maybe<Comment_Aggregate_Bool_Exp_Count>
}

export type Comment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Comment_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Comment_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "comment" */
export type Comment_Aggregate_Fields = {
  __typename?: 'comment_aggregate_fields'
  avg?: Maybe<Comment_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Comment_Max_Fields>
  min?: Maybe<Comment_Min_Fields>
  stddev?: Maybe<Comment_Stddev_Fields>
  stddev_pop?: Maybe<Comment_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Comment_Stddev_Samp_Fields>
  sum?: Maybe<Comment_Sum_Fields>
  var_pop?: Maybe<Comment_Var_Pop_Fields>
  var_samp?: Maybe<Comment_Var_Samp_Fields>
  variance?: Maybe<Comment_Variance_Fields>
}

/** aggregate fields of "comment" */
export type Comment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Comment_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "comment" */
export type Comment_Aggregate_Order_By = {
  avg?: Maybe<Comment_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Comment_Max_Order_By>
  min?: Maybe<Comment_Min_Order_By>
  stddev?: Maybe<Comment_Stddev_Order_By>
  stddev_pop?: Maybe<Comment_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Comment_Stddev_Samp_Order_By>
  sum?: Maybe<Comment_Sum_Order_By>
  var_pop?: Maybe<Comment_Var_Pop_Order_By>
  var_samp?: Maybe<Comment_Var_Samp_Order_By>
  variance?: Maybe<Comment_Variance_Order_By>
}

/** input type for inserting array relation for remote table "comment" */
export type Comment_Arr_Rel_Insert_Input = {
  data: Array<Comment_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** aggregate avg on columns */
export type Comment_Avg_Fields = {
  __typename?: 'comment_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "comment" */
export type Comment_Avg_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "comment". All fields are combined with a logical 'AND'. */
export type Comment_Bool_Exp = {
  _and?: Maybe<Array<Comment_Bool_Exp>>
  _not?: Maybe<Comment_Bool_Exp>
  _or?: Maybe<Array<Comment_Bool_Exp>>
  comment?: Maybe<String_Comparison_Exp>
  commentable_item?: Maybe<Commentable_Item_Bool_Exp>
  commentable_item_id?: Maybe<Uuid_Comparison_Exp>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "comment" */
export enum Comment_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentPkey = 'comment_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  CommentSequentialIdKey = 'comment_sequential_id_key',
}

/** input type for incrementing numeric columns in table "comment" */
export type Comment_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "comment" */
export type Comment_Insert_Input = {
  comment?: Maybe<Scalars['String']>
  commentable_item?: Maybe<Commentable_Item_Obj_Rel_Insert_Input>
  commentable_item_id?: Maybe<Scalars['uuid']>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Comment_Max_Fields = {
  __typename?: 'comment_max_fields'
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "comment" */
export type Comment_Max_Order_By = {
  comment?: Maybe<Order_By>
  commentable_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Comment_Min_Fields = {
  __typename?: 'comment_min_fields'
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "comment" */
export type Comment_Min_Order_By = {
  comment?: Maybe<Order_By>
  commentable_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "comment" */
export type Comment_Mutation_Response = {
  __typename?: 'comment_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Comment>
}

/** input type for inserting object relation for remote table "comment" */
export type Comment_Obj_Rel_Insert_Input = {
  data: Comment_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** on_conflict condition type for table "comment" */
export type Comment_On_Conflict = {
  constraint: Comment_Constraint
  update_columns?: Array<Comment_Update_Column>
  where?: Maybe<Comment_Bool_Exp>
}

/** Ordering options when selecting data from "comment". */
export type Comment_Order_By = {
  comment?: Maybe<Order_By>
  commentable_item?: Maybe<Commentable_Item_Order_By>
  commentable_item_id?: Maybe<Order_By>
  commentable_item_type?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: comment */
export type Comment_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "comment" */
export enum Comment_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CommentableItemId = 'commentable_item_id',
  /** column name */
  CommentableItemType = 'commentable_item_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "comment" */
export type Comment_Set_Input = {
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Comment_Stddev_Fields = {
  __typename?: 'comment_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "comment" */
export type Comment_Stddev_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Comment_Stddev_Pop_Fields = {
  __typename?: 'comment_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "comment" */
export type Comment_Stddev_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Comment_Stddev_Samp_Fields = {
  __typename?: 'comment_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "comment" */
export type Comment_Stddev_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Streaming cursor of the table "comment" */
export type Comment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Comment_Stream_Cursor_Value_Input = {
  comment?: Maybe<Scalars['String']>
  commentable_item_id?: Maybe<Scalars['uuid']>
  commentable_item_type?: Maybe<Commentable_Item_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Comment_Sum_Fields = {
  __typename?: 'comment_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "comment" */
export type Comment_Sum_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** update columns of table "comment" */
export enum Comment_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CommentableItemId = 'commentable_item_id',
  /** column name */
  CommentableItemType = 'commentable_item_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Comment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Comment_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Comment_Set_Input>
  /** filter the rows which have to be updated */
  where: Comment_Bool_Exp
}

/** aggregate var_pop on columns */
export type Comment_Var_Pop_Fields = {
  __typename?: 'comment_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "comment" */
export type Comment_Var_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Comment_Var_Samp_Fields = {
  __typename?: 'comment_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "comment" */
export type Comment_Var_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Comment_Variance_Fields = {
  __typename?: 'comment_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "comment" */
export type Comment_Variance_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** A table to keep track of all the allowable polymorphic commentable items */
export type Commentable_Item = {
  __typename?: 'commentable_item'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  /** An object relationship */
  collection?: Maybe<Collection>
  /** An array relationship */
  comments: Array<Comment>
  /** An aggregate relationship */
  comments_aggregate: Comment_Aggregate
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

/** A table to keep track of all the allowable polymorphic commentable items */
export type Commentable_ItemCommentsArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

/** A table to keep track of all the allowable polymorphic commentable items */
export type Commentable_ItemComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

/** aggregated selection of "commentable_item" */
export type Commentable_Item_Aggregate = {
  __typename?: 'commentable_item_aggregate'
  aggregate?: Maybe<Commentable_Item_Aggregate_Fields>
  nodes: Array<Commentable_Item>
}

/** aggregate fields of "commentable_item" */
export type Commentable_Item_Aggregate_Fields = {
  __typename?: 'commentable_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Commentable_Item_Max_Fields>
  min?: Maybe<Commentable_Item_Min_Fields>
}

/** aggregate fields of "commentable_item" */
export type Commentable_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Commentable_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "commentable_item". All fields are combined with a logical 'AND'. */
export type Commentable_Item_Bool_Exp = {
  _and?: Maybe<Array<Commentable_Item_Bool_Exp>>
  _not?: Maybe<Commentable_Item_Bool_Exp>
  _or?: Maybe<Array<Commentable_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  comments?: Maybe<Comment_Bool_Exp>
  comments_aggregate?: Maybe<Comment_Aggregate_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Commentable_Item_Type_Enum_Comparison_Exp>
}

/** unique or primary key constraints on table "commentable_item" */
export enum Commentable_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  CommentableItemPkey = 'commentable_item_pkey',
}

/** input type for inserting data into table "commentable_item" */
export type Commentable_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  comments?: Maybe<Comment_Arr_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Commentable_Item_Type_Enum>
}

/** aggregate max on columns */
export type Commentable_Item_Max_Fields = {
  __typename?: 'commentable_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Commentable_Item_Min_Fields = {
  __typename?: 'commentable_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "commentable_item" */
export type Commentable_Item_Mutation_Response = {
  __typename?: 'commentable_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Commentable_Item>
}

/** input type for inserting object relation for remote table "commentable_item" */
export type Commentable_Item_Obj_Rel_Insert_Input = {
  data: Commentable_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Commentable_Item_On_Conflict>
}

/** on_conflict condition type for table "commentable_item" */
export type Commentable_Item_On_Conflict = {
  constraint: Commentable_Item_Constraint
  update_columns?: Array<Commentable_Item_Update_Column>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

/** Ordering options when selecting data from "commentable_item". */
export type Commentable_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  collection?: Maybe<Collection_Order_By>
  comments_aggregate?: Maybe<Comment_Aggregate_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
}

/** primary key columns input for table: commentable_item */
export type Commentable_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

/** select columns of table "commentable_item" */
export enum Commentable_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "commentable_item" */
export type Commentable_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Commentable_Item_Type_Enum>
}

/** Streaming cursor of the table "commentable_item" */
export type Commentable_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commentable_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Commentable_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Commentable_Item_Type_Enum>
}

/** An enum table to hold the different commentable item types */
export type Commentable_Item_Type = {
  __typename?: 'commentable_item_type'
  value: Scalars['String']
}

/** aggregated selection of "commentable_item_type" */
export type Commentable_Item_Type_Aggregate = {
  __typename?: 'commentable_item_type_aggregate'
  aggregate?: Maybe<Commentable_Item_Type_Aggregate_Fields>
  nodes: Array<Commentable_Item_Type>
}

/** aggregate fields of "commentable_item_type" */
export type Commentable_Item_Type_Aggregate_Fields = {
  __typename?: 'commentable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Commentable_Item_Type_Max_Fields>
  min?: Maybe<Commentable_Item_Type_Min_Fields>
}

/** aggregate fields of "commentable_item_type" */
export type Commentable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "commentable_item_type". All fields are combined with a logical 'AND'. */
export type Commentable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Commentable_Item_Type_Bool_Exp>>
  _not?: Maybe<Commentable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Commentable_Item_Type_Bool_Exp>>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "commentable_item_type" */
export enum Commentable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  CommentableItemTypePkey = 'commentable_item_type_pkey',
}

export enum Commentable_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
}

/** Boolean expression to compare columns of type "commentable_item_type_enum". All fields are combined with logical 'AND'. */
export type Commentable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Commentable_Item_Type_Enum>
  _in?: Maybe<Array<Commentable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Commentable_Item_Type_Enum>
  _nin?: Maybe<Array<Commentable_Item_Type_Enum>>
}

/** input type for inserting data into table "commentable_item_type" */
export type Commentable_Item_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Commentable_Item_Type_Max_Fields = {
  __typename?: 'commentable_item_type_max_fields'
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Commentable_Item_Type_Min_Fields = {
  __typename?: 'commentable_item_type_min_fields'
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "commentable_item_type" */
export type Commentable_Item_Type_Mutation_Response = {
  __typename?: 'commentable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Commentable_Item_Type>
}

/** on_conflict condition type for table "commentable_item_type" */
export type Commentable_Item_Type_On_Conflict = {
  constraint: Commentable_Item_Type_Constraint
  update_columns?: Array<Commentable_Item_Type_Update_Column>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "commentable_item_type". */
export type Commentable_Item_Type_Order_By = {
  value?: Maybe<Order_By>
}

/** primary key columns input for table: commentable_item_type */
export type Commentable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "commentable_item_type" */
export enum Commentable_Item_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "commentable_item_type" */
export type Commentable_Item_Type_Set_Input = {
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "commentable_item_type" */
export type Commentable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Commentable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Commentable_Item_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>
}

/** update columns of table "commentable_item_type" */
export enum Commentable_Item_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type Commentable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Commentable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Commentable_Item_Type_Bool_Exp
}

/** update columns of table "commentable_item" */
export enum Commentable_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Commentable_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Commentable_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Commentable_Item_Bool_Exp
}

/** An enum table to keep track of the different notification templates. These templates can have 1-to-1 associations with mobile push notifications and/or email templates */
export type Communication_Template = {
  __typename?: 'communication_template'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "communication_template" */
export type Communication_Template_Aggregate = {
  __typename?: 'communication_template_aggregate'
  aggregate?: Maybe<Communication_Template_Aggregate_Fields>
  nodes: Array<Communication_Template>
}

/** aggregate fields of "communication_template" */
export type Communication_Template_Aggregate_Fields = {
  __typename?: 'communication_template_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Communication_Template_Max_Fields>
  min?: Maybe<Communication_Template_Min_Fields>
}

/** aggregate fields of "communication_template" */
export type Communication_Template_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Communication_Template_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "communication_template". All fields are combined with a logical 'AND'. */
export type Communication_Template_Bool_Exp = {
  _and?: Maybe<Array<Communication_Template_Bool_Exp>>
  _not?: Maybe<Communication_Template_Bool_Exp>
  _or?: Maybe<Array<Communication_Template_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_template" */
export enum Communication_Template_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationTemplatePkey = 'notification_template_pkey',
}

export enum Communication_Template_Enum {
  AddProductsToCarrotTask = 'add_products_to_carrot_task',
  /** Used when a user saves a cart item from another's collection */
  CartItemSaved = 'cart_item_saved',
  /** Inform the user the deals hop for the recent cart item saved */
  CartItemSavedDealHop = 'cart_item_saved_deal_hop',
  /** Users who have saved passively cart items from partner sites */
  CartItemsSavedFromPartners = 'cart_items_saved_from_partners',
  /** A template identifying the communication going out to users that a user has accepted their collaboration request */
  CollaborationInviteAccepted = 'collaboration_invite_accepted',
  /** A template identifying the communication going out to collaborators of a collection that another collaborator has added an item to a collection */
  CollaborativeCollectionItemAdded = 'collaborative_collection_item_added',
  /** A template identifying the communication going out about a collection collaboration invite request */
  CollectionCollaborationInvite = 'collection_collaboration_invite',
  CreateCollectionsTask = 'create_collections_task',
  /** Inform the user that deal hop feature was unlocked */
  DealHopUnlocked = 'deal_hop_unlocked',
  EnableSafariExtensionTask = 'enable_safari_extension_task',
  /** Used when a user has a collection that is featured in the discovery feed */
  FeaturedCollectionInDiscoveryFeed = 'featured_collection_in_discovery_feed',
  FeedItemLike = 'feed_item_like',
  InviteFriendsTask = 'invite_friends_task',
  NewFollower = 'new_follower',
  /** A template identifying the communication going out to subscribers of a creator of newly published collections */
  SubscriberNotifiedOfNewPublishedCollection = 'subscriber_notified_of_new_published_collection',
  /** Used when a user makes a comment on something */
  UserCommented = 'user_commented',
  /** User mentioned in one comment */
  UserMentioned = 'user_mentioned',
}

/** Boolean expression to compare columns of type "communication_template_enum". All fields are combined with logical 'AND'. */
export type Communication_Template_Enum_Comparison_Exp = {
  _eq?: Maybe<Communication_Template_Enum>
  _in?: Maybe<Array<Communication_Template_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Communication_Template_Enum>
  _nin?: Maybe<Array<Communication_Template_Enum>>
}

/** A table to hold all of our kebabed-case communication template enums */
export type Communication_Template_Full_List = {
  __typename?: 'communication_template_full_list'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "communication_template_full_list" */
export type Communication_Template_Full_List_Aggregate = {
  __typename?: 'communication_template_full_list_aggregate'
  aggregate?: Maybe<Communication_Template_Full_List_Aggregate_Fields>
  nodes: Array<Communication_Template_Full_List>
}

/** aggregate fields of "communication_template_full_list" */
export type Communication_Template_Full_List_Aggregate_Fields = {
  __typename?: 'communication_template_full_list_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Communication_Template_Full_List_Max_Fields>
  min?: Maybe<Communication_Template_Full_List_Min_Fields>
}

/** aggregate fields of "communication_template_full_list" */
export type Communication_Template_Full_List_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "communication_template_full_list". All fields are combined with a logical 'AND'. */
export type Communication_Template_Full_List_Bool_Exp = {
  _and?: Maybe<Array<Communication_Template_Full_List_Bool_Exp>>
  _not?: Maybe<Communication_Template_Full_List_Bool_Exp>
  _or?: Maybe<Array<Communication_Template_Full_List_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "communication_template_full_list" */
export enum Communication_Template_Full_List_Constraint {
  /** unique or primary key constraint on columns "value" */
  CommunicationTemplateFullListPkey = 'communication_template_full_list_pkey',
}

/** input type for inserting data into table "communication_template_full_list" */
export type Communication_Template_Full_List_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Communication_Template_Full_List_Max_Fields = {
  __typename?: 'communication_template_full_list_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Communication_Template_Full_List_Min_Fields = {
  __typename?: 'communication_template_full_list_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "communication_template_full_list" */
export type Communication_Template_Full_List_Mutation_Response = {
  __typename?: 'communication_template_full_list_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Template_Full_List>
}

/** on_conflict condition type for table "communication_template_full_list" */
export type Communication_Template_Full_List_On_Conflict = {
  constraint: Communication_Template_Full_List_Constraint
  update_columns?: Array<Communication_Template_Full_List_Update_Column>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

/** Ordering options when selecting data from "communication_template_full_list". */
export type Communication_Template_Full_List_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: communication_template_full_list */
export type Communication_Template_Full_List_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "communication_template_full_list" */
export enum Communication_Template_Full_List_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "communication_template_full_list" */
export type Communication_Template_Full_List_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "communication_template_full_list" */
export type Communication_Template_Full_List_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Template_Full_List_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Template_Full_List_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "communication_template_full_list" */
export enum Communication_Template_Full_List_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Communication_Template_Full_List_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Communication_Template_Full_List_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Template_Full_List_Bool_Exp
}

/** input type for inserting data into table "communication_template" */
export type Communication_Template_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Communication_Template_Max_Fields = {
  __typename?: 'communication_template_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Communication_Template_Min_Fields = {
  __typename?: 'communication_template_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "communication_template" */
export type Communication_Template_Mutation_Response = {
  __typename?: 'communication_template_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Communication_Template>
}

/** on_conflict condition type for table "communication_template" */
export type Communication_Template_On_Conflict = {
  constraint: Communication_Template_Constraint
  update_columns?: Array<Communication_Template_Update_Column>
  where?: Maybe<Communication_Template_Bool_Exp>
}

/** Ordering options when selecting data from "communication_template". */
export type Communication_Template_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: communication_template */
export type Communication_Template_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "communication_template" */
export enum Communication_Template_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "communication_template" */
export type Communication_Template_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "communication_template" */
export type Communication_Template_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Communication_Template_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Communication_Template_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "communication_template" */
export enum Communication_Template_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Communication_Template_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Communication_Template_Set_Input>
  /** filter the rows which have to be updated */
  where: Communication_Template_Bool_Exp
}

/** columns and relationships of "contact_info_type" */
export type Contact_Info_Type = {
  __typename?: 'contact_info_type'
  value: Scalars['String']
}

/** aggregated selection of "contact_info_type" */
export type Contact_Info_Type_Aggregate = {
  __typename?: 'contact_info_type_aggregate'
  aggregate?: Maybe<Contact_Info_Type_Aggregate_Fields>
  nodes: Array<Contact_Info_Type>
}

/** aggregate fields of "contact_info_type" */
export type Contact_Info_Type_Aggregate_Fields = {
  __typename?: 'contact_info_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Info_Type_Max_Fields>
  min?: Maybe<Contact_Info_Type_Min_Fields>
}

/** aggregate fields of "contact_info_type" */
export type Contact_Info_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Info_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_info_type". All fields are combined with a logical 'AND'. */
export type Contact_Info_Type_Bool_Exp = {
  _and?: Maybe<Array<Contact_Info_Type_Bool_Exp>>
  _not?: Maybe<Contact_Info_Type_Bool_Exp>
  _or?: Maybe<Array<Contact_Info_Type_Bool_Exp>>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_info_type" */
export enum Contact_Info_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  ContactInfoTypePkey = 'contact_info_type_pkey',
}

export enum Contact_Info_Type_Enum {
  Email = 'email',
  Phone = 'phone',
}

/** Boolean expression to compare columns of type "contact_info_type_enum". All fields are combined with logical 'AND'. */
export type Contact_Info_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Contact_Info_Type_Enum>
  _in?: Maybe<Array<Contact_Info_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Contact_Info_Type_Enum>
  _nin?: Maybe<Array<Contact_Info_Type_Enum>>
}

/** input type for inserting data into table "contact_info_type" */
export type Contact_Info_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Contact_Info_Type_Max_Fields = {
  __typename?: 'contact_info_type_max_fields'
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Contact_Info_Type_Min_Fields = {
  __typename?: 'contact_info_type_min_fields'
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "contact_info_type" */
export type Contact_Info_Type_Mutation_Response = {
  __typename?: 'contact_info_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Info_Type>
}

/** on_conflict condition type for table "contact_info_type" */
export type Contact_Info_Type_On_Conflict = {
  constraint: Contact_Info_Type_Constraint
  update_columns?: Array<Contact_Info_Type_Update_Column>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

/** Ordering options when selecting data from "contact_info_type". */
export type Contact_Info_Type_Order_By = {
  value?: Maybe<Order_By>
}

/** primary key columns input for table: contact_info_type */
export type Contact_Info_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "contact_info_type" */
export enum Contact_Info_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "contact_info_type" */
export type Contact_Info_Type_Set_Input = {
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "contact_info_type" */
export type Contact_Info_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Info_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Info_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>
}

/** update columns of table "contact_info_type" */
export enum Contact_Info_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type Contact_Info_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Info_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Info_Type_Bool_Exp
}

/** columns and relationships of "contact_invite" */
export type Contact_Invite = {
  __typename?: 'contact_invite'
  contact_info: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  invitee?: Maybe<User>
  invitee_id?: Maybe<Scalars['String']>
  /** An object relationship */
  requester: User
  requester_id: Scalars['String']
  status: Contact_Invite_Status_Enum
  type: Contact_Invite_Type_Enum
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "contact_invite" */
export type Contact_Invite_Aggregate = {
  __typename?: 'contact_invite_aggregate'
  aggregate?: Maybe<Contact_Invite_Aggregate_Fields>
  nodes: Array<Contact_Invite>
}

/** aggregate fields of "contact_invite" */
export type Contact_Invite_Aggregate_Fields = {
  __typename?: 'contact_invite_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Invite_Max_Fields>
  min?: Maybe<Contact_Invite_Min_Fields>
}

/** aggregate fields of "contact_invite" */
export type Contact_Invite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Invite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_invite". All fields are combined with a logical 'AND'. */
export type Contact_Invite_Bool_Exp = {
  _and?: Maybe<Array<Contact_Invite_Bool_Exp>>
  _not?: Maybe<Contact_Invite_Bool_Exp>
  _or?: Maybe<Array<Contact_Invite_Bool_Exp>>
  contact_info?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  invitee?: Maybe<User_Bool_Exp>
  invitee_id?: Maybe<String_Comparison_Exp>
  requester?: Maybe<User_Bool_Exp>
  requester_id?: Maybe<String_Comparison_Exp>
  status?: Maybe<Contact_Invite_Status_Enum_Comparison_Exp>
  type?: Maybe<Contact_Invite_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_invite" */
export enum Contact_Invite_Constraint {
  /** unique or primary key constraint on columns "id" */
  ContactInvitePkey = 'contact_invite_pkey',
  /** unique or primary key constraint on columns "requester_id", "contact_info" */
  ContactInviteRequesterIdContactInfoKey = 'contact_invite_requester_id_contact_info_key',
}

/** input type for inserting data into table "contact_invite" */
export type Contact_Invite_Insert_Input = {
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee?: Maybe<User_Obj_Rel_Insert_Input>
  invitee_id?: Maybe<Scalars['String']>
  requester?: Maybe<User_Obj_Rel_Insert_Input>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Contact_Invite_Status_Enum>
  type?: Maybe<Contact_Invite_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Contact_Invite_Max_Fields = {
  __typename?: 'contact_invite_max_fields'
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Contact_Invite_Min_Fields = {
  __typename?: 'contact_invite_min_fields'
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "contact_invite" */
export type Contact_Invite_Mutation_Response = {
  __typename?: 'contact_invite_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Invite>
}

/** on_conflict condition type for table "contact_invite" */
export type Contact_Invite_On_Conflict = {
  constraint: Contact_Invite_Constraint
  update_columns?: Array<Contact_Invite_Update_Column>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

/** Ordering options when selecting data from "contact_invite". */
export type Contact_Invite_Order_By = {
  contact_info?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invitee?: Maybe<User_Order_By>
  invitee_id?: Maybe<Order_By>
  requester?: Maybe<User_Order_By>
  requester_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: contact_invite */
export type Contact_Invite_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "contact_invite" */
export enum Contact_Invite_Select_Column {
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "contact_invite" */
export type Contact_Invite_Set_Input = {
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Contact_Invite_Status_Enum>
  type?: Maybe<Contact_Invite_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "contact_invite_status" */
export type Contact_Invite_Status = {
  __typename?: 'contact_invite_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "contact_invite_status" */
export type Contact_Invite_Status_Aggregate = {
  __typename?: 'contact_invite_status_aggregate'
  aggregate?: Maybe<Contact_Invite_Status_Aggregate_Fields>
  nodes: Array<Contact_Invite_Status>
}

/** aggregate fields of "contact_invite_status" */
export type Contact_Invite_Status_Aggregate_Fields = {
  __typename?: 'contact_invite_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Invite_Status_Max_Fields>
  min?: Maybe<Contact_Invite_Status_Min_Fields>
}

/** aggregate fields of "contact_invite_status" */
export type Contact_Invite_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_invite_status". All fields are combined with a logical 'AND'. */
export type Contact_Invite_Status_Bool_Exp = {
  _and?: Maybe<Array<Contact_Invite_Status_Bool_Exp>>
  _not?: Maybe<Contact_Invite_Status_Bool_Exp>
  _or?: Maybe<Array<Contact_Invite_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_invite_status" */
export enum Contact_Invite_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserInviteStatusPkey = 'user_invite_status_pkey',
}

export enum Contact_Invite_Status_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "contact_invite_status_enum". All fields are combined with logical 'AND'. */
export type Contact_Invite_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Contact_Invite_Status_Enum>
  _in?: Maybe<Array<Contact_Invite_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Contact_Invite_Status_Enum>
  _nin?: Maybe<Array<Contact_Invite_Status_Enum>>
}

/** input type for inserting data into table "contact_invite_status" */
export type Contact_Invite_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Contact_Invite_Status_Max_Fields = {
  __typename?: 'contact_invite_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Contact_Invite_Status_Min_Fields = {
  __typename?: 'contact_invite_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "contact_invite_status" */
export type Contact_Invite_Status_Mutation_Response = {
  __typename?: 'contact_invite_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Invite_Status>
}

/** on_conflict condition type for table "contact_invite_status" */
export type Contact_Invite_Status_On_Conflict = {
  constraint: Contact_Invite_Status_Constraint
  update_columns?: Array<Contact_Invite_Status_Update_Column>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

/** Ordering options when selecting data from "contact_invite_status". */
export type Contact_Invite_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: contact_invite_status */
export type Contact_Invite_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "contact_invite_status" */
export enum Contact_Invite_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "contact_invite_status" */
export type Contact_Invite_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "contact_invite_status" */
export type Contact_Invite_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Invite_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Invite_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "contact_invite_status" */
export enum Contact_Invite_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Contact_Invite_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Invite_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Invite_Status_Bool_Exp
}

/** Streaming cursor of the table "contact_invite" */
export type Contact_Invite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Invite_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Invite_Stream_Cursor_Value_Input = {
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invitee_id?: Maybe<Scalars['String']>
  requester_id?: Maybe<Scalars['String']>
  status?: Maybe<Contact_Invite_Status_Enum>
  type?: Maybe<Contact_Invite_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "contact_invite_type" */
export type Contact_Invite_Type = {
  __typename?: 'contact_invite_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "contact_invite_type" */
export type Contact_Invite_Type_Aggregate = {
  __typename?: 'contact_invite_type_aggregate'
  aggregate?: Maybe<Contact_Invite_Type_Aggregate_Fields>
  nodes: Array<Contact_Invite_Type>
}

/** aggregate fields of "contact_invite_type" */
export type Contact_Invite_Type_Aggregate_Fields = {
  __typename?: 'contact_invite_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Contact_Invite_Type_Max_Fields>
  min?: Maybe<Contact_Invite_Type_Min_Fields>
}

/** aggregate fields of "contact_invite_type" */
export type Contact_Invite_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "contact_invite_type". All fields are combined with a logical 'AND'. */
export type Contact_Invite_Type_Bool_Exp = {
  _and?: Maybe<Array<Contact_Invite_Type_Bool_Exp>>
  _not?: Maybe<Contact_Invite_Type_Bool_Exp>
  _or?: Maybe<Array<Contact_Invite_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "contact_invite_type" */
export enum Contact_Invite_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserInviteTypePkey = 'user_invite_type_pkey',
}

export enum Contact_Invite_Type_Enum {
  Email = 'email',
  Phone = 'phone',
}

/** Boolean expression to compare columns of type "contact_invite_type_enum". All fields are combined with logical 'AND'. */
export type Contact_Invite_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Contact_Invite_Type_Enum>
  _in?: Maybe<Array<Contact_Invite_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Contact_Invite_Type_Enum>
  _nin?: Maybe<Array<Contact_Invite_Type_Enum>>
}

/** input type for inserting data into table "contact_invite_type" */
export type Contact_Invite_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Contact_Invite_Type_Max_Fields = {
  __typename?: 'contact_invite_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Contact_Invite_Type_Min_Fields = {
  __typename?: 'contact_invite_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "contact_invite_type" */
export type Contact_Invite_Type_Mutation_Response = {
  __typename?: 'contact_invite_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Contact_Invite_Type>
}

/** on_conflict condition type for table "contact_invite_type" */
export type Contact_Invite_Type_On_Conflict = {
  constraint: Contact_Invite_Type_Constraint
  update_columns?: Array<Contact_Invite_Type_Update_Column>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

/** Ordering options when selecting data from "contact_invite_type". */
export type Contact_Invite_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: contact_invite_type */
export type Contact_Invite_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "contact_invite_type" */
export enum Contact_Invite_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "contact_invite_type" */
export type Contact_Invite_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "contact_invite_type" */
export type Contact_Invite_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Contact_Invite_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Contact_Invite_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "contact_invite_type" */
export enum Contact_Invite_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Contact_Invite_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Invite_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Invite_Type_Bool_Exp
}

/** update columns of table "contact_invite" */
export enum Contact_Invite_Update_Column {
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InviteeId = 'invitee_id',
  /** column name */
  RequesterId = 'requester_id',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Contact_Invite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Contact_Invite_Set_Input>
  /** filter the rows which have to be updated */
  where: Contact_Invite_Bool_Exp
}

export type Creator_Subscribers_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** columns and relationships of "creator_user_subscriptions" */
export type Creator_User_Subscriptions = {
  __typename?: 'creator_user_subscriptions'
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  creator?: Maybe<User>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  /** An object relationship */
  subscriber?: Maybe<User>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Aggregate = {
  __typename?: 'creator_user_subscriptions_aggregate'
  aggregate?: Maybe<Creator_User_Subscriptions_Aggregate_Fields>
  nodes: Array<Creator_User_Subscriptions>
}

/** aggregate fields of "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Aggregate_Fields = {
  __typename?: 'creator_user_subscriptions_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Creator_User_Subscriptions_Max_Fields>
  min?: Maybe<Creator_User_Subscriptions_Min_Fields>
}

/** aggregate fields of "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "creator_user_subscriptions". All fields are combined with a logical 'AND'. */
export type Creator_User_Subscriptions_Bool_Exp = {
  _and?: Maybe<Array<Creator_User_Subscriptions_Bool_Exp>>
  _not?: Maybe<Creator_User_Subscriptions_Bool_Exp>
  _or?: Maybe<Array<Creator_User_Subscriptions_Bool_Exp>>
  billing_mode?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator?: Maybe<User_Bool_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  interval?: Maybe<String_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  subscriber?: Maybe<User_Bool_Exp>
  subscriber_email?: Maybe<String_Comparison_Exp>
  subscriber_phone?: Maybe<String_Comparison_Exp>
  subscription_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Creator_User_Subscriptions_Max_Fields = {
  __typename?: 'creator_user_subscriptions_max_fields'
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Creator_User_Subscriptions_Min_Fields = {
  __typename?: 'creator_user_subscriptions_min_fields'
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "creator_user_subscriptions". */
export type Creator_User_Subscriptions_Order_By = {
  billing_mode?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator?: Maybe<User_Order_By>
  creator_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  interval?: Maybe<Order_By>
  status?: Maybe<Order_By>
  subscriber?: Maybe<User_Order_By>
  subscriber_email?: Maybe<Order_By>
  subscriber_phone?: Maybe<Order_By>
  subscription_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "creator_user_subscriptions" */
export enum Creator_User_Subscriptions_Select_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriberEmail = 'subscriber_email',
  /** column name */
  SubscriberPhone = 'subscriber_phone',
  /** column name */
  SubscriptionType = 'subscription_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "creator_user_subscriptions" */
export type Creator_User_Subscriptions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Creator_User_Subscriptions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Creator_User_Subscriptions_Stream_Cursor_Value_Input = {
  billing_mode?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  interval?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  subscriber_email?: Maybe<Scalars['String']>
  subscriber_phone?: Maybe<Scalars['String']>
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>
  _gt?: Maybe<Scalars['date']>
  _gte?: Maybe<Scalars['date']>
  _in?: Maybe<Array<Scalars['date']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['date']>
  _lte?: Maybe<Scalars['date']>
  _neq?: Maybe<Scalars['date']>
  _nin?: Maybe<Array<Scalars['date']>>
}

/** A table to track deal hop gated status */
export type Deal_Hop_Gate = {
  __typename?: 'deal_hop_gate'
  created_at: Scalars['timestamptz']
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked: Scalars['Boolean']
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** A table to track deal hop gated status */
export type Deal_Hop_GateDeal_Hop_InvitesArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "deal_hop_gate" */
export type Deal_Hop_Gate_Aggregate = {
  __typename?: 'deal_hop_gate_aggregate'
  aggregate?: Maybe<Deal_Hop_Gate_Aggregate_Fields>
  nodes: Array<Deal_Hop_Gate>
}

/** aggregate fields of "deal_hop_gate" */
export type Deal_Hop_Gate_Aggregate_Fields = {
  __typename?: 'deal_hop_gate_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Deal_Hop_Gate_Max_Fields>
  min?: Maybe<Deal_Hop_Gate_Min_Fields>
}

/** aggregate fields of "deal_hop_gate" */
export type Deal_Hop_Gate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Deal_Hop_Gate_Append_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "deal_hop_gate". All fields are combined with a logical 'AND'. */
export type Deal_Hop_Gate_Bool_Exp = {
  _and?: Maybe<Array<Deal_Hop_Gate_Bool_Exp>>
  _not?: Maybe<Deal_Hop_Gate_Bool_Exp>
  _or?: Maybe<Array<Deal_Hop_Gate_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gate_pending?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gated?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invited?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invites?: Maybe<Jsonb_Comparison_Exp>
  deal_hop_unlocked?: Maybe<Boolean_Comparison_Exp>
  paywall_gated_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "deal_hop_gate" */
export enum Deal_Hop_Gate_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  DealHopGatePkey = 'deal_hop_gate_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Deal_Hop_Gate_Delete_At_Path_Input = {
  deal_hop_invites?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Deal_Hop_Gate_Delete_Elem_Input = {
  deal_hop_invites?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Deal_Hop_Gate_Delete_Key_Input = {
  deal_hop_invites?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "deal_hop_gate" */
export type Deal_Hop_Gate_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Deal_Hop_Gate_Max_Fields = {
  __typename?: 'deal_hop_gate_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Deal_Hop_Gate_Min_Fields = {
  __typename?: 'deal_hop_gate_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "deal_hop_gate" */
export type Deal_Hop_Gate_Mutation_Response = {
  __typename?: 'deal_hop_gate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Deal_Hop_Gate>
}

/** on_conflict condition type for table "deal_hop_gate" */
export type Deal_Hop_Gate_On_Conflict = {
  constraint: Deal_Hop_Gate_Constraint
  update_columns?: Array<Deal_Hop_Gate_Update_Column>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

/** Ordering options when selecting data from "deal_hop_gate". */
export type Deal_Hop_Gate_Order_By = {
  created_at?: Maybe<Order_By>
  deal_hop_gate_pending?: Maybe<Order_By>
  deal_hop_gated?: Maybe<Order_By>
  deal_hop_invited?: Maybe<Order_By>
  deal_hop_invites?: Maybe<Order_By>
  deal_hop_unlocked?: Maybe<Order_By>
  paywall_gated_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: deal_hop_gate */
export type Deal_Hop_Gate_Pk_Columns_Input = {
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Deal_Hop_Gate_Prepend_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
}

/** select columns of table "deal_hop_gate" */
export enum Deal_Hop_Gate_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  PaywallGatedAt = 'paywall_gated_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "deal_hop_gate" */
export type Deal_Hop_Gate_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "deal_hop_gate" */
export type Deal_Hop_Gate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deal_Hop_Gate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Deal_Hop_Gate_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  paywall_gated_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "deal_hop_gate" */
export enum Deal_Hop_Gate_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  PaywallGatedAt = 'paywall_gated_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Deal_Hop_Gate_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Deal_Hop_Gate_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Deal_Hop_Gate_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Deal_Hop_Gate_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Deal_Hop_Gate_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Deal_Hop_Gate_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Deal_Hop_Gate_Set_Input>
  /** filter the rows which have to be updated */
  where: Deal_Hop_Gate_Bool_Exp
}

/** columns and relationships of "device_client_data" */
export type Device_Client_Data = {
  __typename?: 'device_client_data'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  metadata: Scalars['json']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** columns and relationships of "device_client_data" */
export type Device_Client_DataMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "device_client_data" */
export type Device_Client_Data_Aggregate = {
  __typename?: 'device_client_data_aggregate'
  aggregate?: Maybe<Device_Client_Data_Aggregate_Fields>
  nodes: Array<Device_Client_Data>
}

/** aggregate fields of "device_client_data" */
export type Device_Client_Data_Aggregate_Fields = {
  __typename?: 'device_client_data_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Device_Client_Data_Max_Fields>
  min?: Maybe<Device_Client_Data_Min_Fields>
}

/** aggregate fields of "device_client_data" */
export type Device_Client_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Device_Client_Data_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "device_client_data". All fields are combined with a logical 'AND'. */
export type Device_Client_Data_Bool_Exp = {
  _and?: Maybe<Array<Device_Client_Data_Bool_Exp>>
  _not?: Maybe<Device_Client_Data_Bool_Exp>
  _or?: Maybe<Array<Device_Client_Data_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  metadata?: Maybe<Json_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "device_client_data" */
export enum Device_Client_Data_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeviceClientDataPkey = 'device_client_data_pkey',
}

/** input type for inserting data into table "device_client_data" */
export type Device_Client_Data_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['json']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Device_Client_Data_Max_Fields = {
  __typename?: 'device_client_data_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Device_Client_Data_Min_Fields = {
  __typename?: 'device_client_data_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "device_client_data" */
export type Device_Client_Data_Mutation_Response = {
  __typename?: 'device_client_data_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Device_Client_Data>
}

/** on_conflict condition type for table "device_client_data" */
export type Device_Client_Data_On_Conflict = {
  constraint: Device_Client_Data_Constraint
  update_columns?: Array<Device_Client_Data_Update_Column>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

/** Ordering options when selecting data from "device_client_data". */
export type Device_Client_Data_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: device_client_data */
export type Device_Client_Data_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "device_client_data" */
export enum Device_Client_Data_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "device_client_data" */
export type Device_Client_Data_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['json']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "device_client_data" */
export type Device_Client_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Device_Client_Data_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Device_Client_Data_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['json']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "device_client_data" */
export enum Device_Client_Data_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Device_Client_Data_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Device_Client_Data_Set_Input>
  /** filter the rows which have to be updated */
  where: Device_Client_Data_Bool_Exp
}

/** columns and relationships of "discovery_backlog" */
export type Discovery_Backlog = {
  __typename?: 'discovery_backlog'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "discovery_backlog" */
export type Discovery_Backlog_Aggregate = {
  __typename?: 'discovery_backlog_aggregate'
  aggregate?: Maybe<Discovery_Backlog_Aggregate_Fields>
  nodes: Array<Discovery_Backlog>
}

/** aggregate fields of "discovery_backlog" */
export type Discovery_Backlog_Aggregate_Fields = {
  __typename?: 'discovery_backlog_aggregate_fields'
  avg?: Maybe<Discovery_Backlog_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Discovery_Backlog_Max_Fields>
  min?: Maybe<Discovery_Backlog_Min_Fields>
  stddev?: Maybe<Discovery_Backlog_Stddev_Fields>
  stddev_pop?: Maybe<Discovery_Backlog_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Discovery_Backlog_Stddev_Samp_Fields>
  sum?: Maybe<Discovery_Backlog_Sum_Fields>
  var_pop?: Maybe<Discovery_Backlog_Var_Pop_Fields>
  var_samp?: Maybe<Discovery_Backlog_Var_Samp_Fields>
  variance?: Maybe<Discovery_Backlog_Variance_Fields>
}

/** aggregate fields of "discovery_backlog" */
export type Discovery_Backlog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discovery_Backlog_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Discovery_Backlog_Avg_Fields = {
  __typename?: 'discovery_backlog_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "discovery_backlog". All fields are combined with a logical 'AND'. */
export type Discovery_Backlog_Bool_Exp = {
  _and?: Maybe<Array<Discovery_Backlog_Bool_Exp>>
  _not?: Maybe<Discovery_Backlog_Bool_Exp>
  _or?: Maybe<Array<Discovery_Backlog_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "discovery_backlog" */
export enum Discovery_Backlog_Constraint {
  /** unique or primary key constraint on columns "feed_item_id" */
  DiscoveryBacklogFeedItemIdKey = 'discovery_backlog_feed_item_id_key',
  /** unique or primary key constraint on columns "id" */
  DiscoveryBacklogPkey = 'discovery_backlog_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  DiscoveryBacklogSequentialIdKey = 'discovery_backlog_sequential_id_key',
}

/** input type for incrementing numeric columns in table "discovery_backlog" */
export type Discovery_Backlog_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "discovery_backlog" */
export type Discovery_Backlog_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Discovery_Backlog_Max_Fields = {
  __typename?: 'discovery_backlog_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Discovery_Backlog_Min_Fields = {
  __typename?: 'discovery_backlog_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "discovery_backlog" */
export type Discovery_Backlog_Mutation_Response = {
  __typename?: 'discovery_backlog_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Discovery_Backlog>
}

/** on_conflict condition type for table "discovery_backlog" */
export type Discovery_Backlog_On_Conflict = {
  constraint: Discovery_Backlog_Constraint
  update_columns?: Array<Discovery_Backlog_Update_Column>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

/** Ordering options when selecting data from "discovery_backlog". */
export type Discovery_Backlog_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: discovery_backlog */
export type Discovery_Backlog_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "discovery_backlog" */
export enum Discovery_Backlog_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "discovery_backlog" */
export type Discovery_Backlog_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Discovery_Backlog_Stddev_Fields = {
  __typename?: 'discovery_backlog_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Discovery_Backlog_Stddev_Pop_Fields = {
  __typename?: 'discovery_backlog_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Discovery_Backlog_Stddev_Samp_Fields = {
  __typename?: 'discovery_backlog_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "discovery_backlog" */
export type Discovery_Backlog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discovery_Backlog_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Discovery_Backlog_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Discovery_Backlog_Sum_Fields = {
  __typename?: 'discovery_backlog_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "discovery_backlog" */
export enum Discovery_Backlog_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Discovery_Backlog_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Discovery_Backlog_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Discovery_Backlog_Set_Input>
  /** filter the rows which have to be updated */
  where: Discovery_Backlog_Bool_Exp
}

/** aggregate var_pop on columns */
export type Discovery_Backlog_Var_Pop_Fields = {
  __typename?: 'discovery_backlog_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Discovery_Backlog_Var_Samp_Fields = {
  __typename?: 'discovery_backlog_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Discovery_Backlog_Variance_Fields = {
  __typename?: 'discovery_backlog_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** A table of items for the discovery feed */
export type Discovery_Feed = {
  __typename?: 'discovery_feed'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "discovery_feed" */
export type Discovery_Feed_Aggregate = {
  __typename?: 'discovery_feed_aggregate'
  aggregate?: Maybe<Discovery_Feed_Aggregate_Fields>
  nodes: Array<Discovery_Feed>
}

/** aggregate fields of "discovery_feed" */
export type Discovery_Feed_Aggregate_Fields = {
  __typename?: 'discovery_feed_aggregate_fields'
  avg?: Maybe<Discovery_Feed_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Discovery_Feed_Max_Fields>
  min?: Maybe<Discovery_Feed_Min_Fields>
  stddev?: Maybe<Discovery_Feed_Stddev_Fields>
  stddev_pop?: Maybe<Discovery_Feed_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Discovery_Feed_Stddev_Samp_Fields>
  sum?: Maybe<Discovery_Feed_Sum_Fields>
  var_pop?: Maybe<Discovery_Feed_Var_Pop_Fields>
  var_samp?: Maybe<Discovery_Feed_Var_Samp_Fields>
  variance?: Maybe<Discovery_Feed_Variance_Fields>
}

/** aggregate fields of "discovery_feed" */
export type Discovery_Feed_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discovery_Feed_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Discovery_Feed_Avg_Fields = {
  __typename?: 'discovery_feed_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "discovery_feed". All fields are combined with a logical 'AND'. */
export type Discovery_Feed_Bool_Exp = {
  _and?: Maybe<Array<Discovery_Feed_Bool_Exp>>
  _not?: Maybe<Discovery_Feed_Bool_Exp>
  _or?: Maybe<Array<Discovery_Feed_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "discovery_feed" */
export enum Discovery_Feed_Constraint {
  /** unique or primary key constraint on columns "id" */
  DiscoveryFeedPkey = 'discovery_feed_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  DiscoveryFeedSequentialIdKey = 'discovery_feed_sequential_id_key',
}

/** input type for incrementing numeric columns in table "discovery_feed" */
export type Discovery_Feed_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "discovery_feed" */
export type Discovery_Feed_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Discovery_Feed_Max_Fields = {
  __typename?: 'discovery_feed_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Discovery_Feed_Min_Fields = {
  __typename?: 'discovery_feed_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "discovery_feed" */
export type Discovery_Feed_Mutation_Response = {
  __typename?: 'discovery_feed_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Discovery_Feed>
}

/** on_conflict condition type for table "discovery_feed" */
export type Discovery_Feed_On_Conflict = {
  constraint: Discovery_Feed_Constraint
  update_columns?: Array<Discovery_Feed_Update_Column>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

/** Ordering options when selecting data from "discovery_feed". */
export type Discovery_Feed_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: discovery_feed */
export type Discovery_Feed_Pk_Columns_Input = {
  id: Scalars['uuid']
}

export type Discovery_Feed_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** select columns of table "discovery_feed" */
export enum Discovery_Feed_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "discovery_feed" */
export type Discovery_Feed_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Discovery_Feed_Stddev_Fields = {
  __typename?: 'discovery_feed_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Discovery_Feed_Stddev_Pop_Fields = {
  __typename?: 'discovery_feed_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Discovery_Feed_Stddev_Samp_Fields = {
  __typename?: 'discovery_feed_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "discovery_feed" */
export type Discovery_Feed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discovery_Feed_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Discovery_Feed_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Discovery_Feed_Sum_Fields = {
  __typename?: 'discovery_feed_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "discovery_feed" */
export enum Discovery_Feed_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Discovery_Feed_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Discovery_Feed_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Discovery_Feed_Set_Input>
  /** filter the rows which have to be updated */
  where: Discovery_Feed_Bool_Exp
}

/** aggregate var_pop on columns */
export type Discovery_Feed_Var_Pop_Fields = {
  __typename?: 'discovery_feed_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Discovery_Feed_Var_Samp_Fields = {
  __typename?: 'discovery_feed_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Discovery_Feed_Variance_Fields = {
  __typename?: 'discovery_feed_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** columns and relationships of "discovery_staging" */
export type Discovery_Staging = {
  __typename?: 'discovery_staging'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  sequential_id: Scalars['Int']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "discovery_staging" */
export type Discovery_Staging_Aggregate = {
  __typename?: 'discovery_staging_aggregate'
  aggregate?: Maybe<Discovery_Staging_Aggregate_Fields>
  nodes: Array<Discovery_Staging>
}

/** aggregate fields of "discovery_staging" */
export type Discovery_Staging_Aggregate_Fields = {
  __typename?: 'discovery_staging_aggregate_fields'
  avg?: Maybe<Discovery_Staging_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Discovery_Staging_Max_Fields>
  min?: Maybe<Discovery_Staging_Min_Fields>
  stddev?: Maybe<Discovery_Staging_Stddev_Fields>
  stddev_pop?: Maybe<Discovery_Staging_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Discovery_Staging_Stddev_Samp_Fields>
  sum?: Maybe<Discovery_Staging_Sum_Fields>
  var_pop?: Maybe<Discovery_Staging_Var_Pop_Fields>
  var_samp?: Maybe<Discovery_Staging_Var_Samp_Fields>
  variance?: Maybe<Discovery_Staging_Variance_Fields>
}

/** aggregate fields of "discovery_staging" */
export type Discovery_Staging_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Discovery_Staging_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Discovery_Staging_Avg_Fields = {
  __typename?: 'discovery_staging_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "discovery_staging". All fields are combined with a logical 'AND'. */
export type Discovery_Staging_Bool_Exp = {
  _and?: Maybe<Array<Discovery_Staging_Bool_Exp>>
  _not?: Maybe<Discovery_Staging_Bool_Exp>
  _or?: Maybe<Array<Discovery_Staging_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "discovery_staging" */
export enum Discovery_Staging_Constraint {
  /** unique or primary key constraint on columns "feed_item_id" */
  DiscoveryStagingFeedItemIdKey = 'discovery_staging_feed_item_id_key',
  /** unique or primary key constraint on columns "id" */
  DiscoveryStagingPkey = 'discovery_staging_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  DiscoveryStagingSequentialIdKey = 'discovery_staging_sequential_id_key',
}

/** input type for incrementing numeric columns in table "discovery_staging" */
export type Discovery_Staging_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "discovery_staging" */
export type Discovery_Staging_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Discovery_Staging_Max_Fields = {
  __typename?: 'discovery_staging_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Discovery_Staging_Min_Fields = {
  __typename?: 'discovery_staging_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "discovery_staging" */
export type Discovery_Staging_Mutation_Response = {
  __typename?: 'discovery_staging_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Discovery_Staging>
}

/** on_conflict condition type for table "discovery_staging" */
export type Discovery_Staging_On_Conflict = {
  constraint: Discovery_Staging_Constraint
  update_columns?: Array<Discovery_Staging_Update_Column>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

/** Ordering options when selecting data from "discovery_staging". */
export type Discovery_Staging_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: discovery_staging */
export type Discovery_Staging_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "discovery_staging" */
export enum Discovery_Staging_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "discovery_staging" */
export type Discovery_Staging_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Discovery_Staging_Stddev_Fields = {
  __typename?: 'discovery_staging_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Discovery_Staging_Stddev_Pop_Fields = {
  __typename?: 'discovery_staging_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Discovery_Staging_Stddev_Samp_Fields = {
  __typename?: 'discovery_staging_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "discovery_staging" */
export type Discovery_Staging_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Discovery_Staging_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Discovery_Staging_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Discovery_Staging_Sum_Fields = {
  __typename?: 'discovery_staging_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "discovery_staging" */
export enum Discovery_Staging_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Discovery_Staging_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Discovery_Staging_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Discovery_Staging_Set_Input>
  /** filter the rows which have to be updated */
  where: Discovery_Staging_Bool_Exp
}

/** aggregate var_pop on columns */
export type Discovery_Staging_Var_Pop_Fields = {
  __typename?: 'discovery_staging_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Discovery_Staging_Var_Samp_Fields = {
  __typename?: 'discovery_staging_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Discovery_Staging_Variance_Fields = {
  __typename?: 'discovery_staging_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** A shop that is being featured within the discovery feed */
export type Featured_Shop = {
  __typename?: 'featured_shop'
  /** An array relationship */
  featured_shop_items: Array<Featured_Shop_Item>
  /** An aggregate relationship */
  featured_shop_items_aggregate: Featured_Shop_Item_Aggregate
  /** An object relationship */
  feed_item?: Maybe<Feed_Item>
  feed_item_type: Feed_Item_Type_Enum
  id: Scalars['uuid']
  /** A computed field, executes function "featured_shop_liked_by_user" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
}

/** A shop that is being featured within the discovery feed */
export type Featured_ShopFeatured_Shop_ItemsArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

/** A shop that is being featured within the discovery feed */
export type Featured_ShopFeatured_Shop_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

/** aggregated selection of "featured_shop" */
export type Featured_Shop_Aggregate = {
  __typename?: 'featured_shop_aggregate'
  aggregate?: Maybe<Featured_Shop_Aggregate_Fields>
  nodes: Array<Featured_Shop>
}

/** aggregate fields of "featured_shop" */
export type Featured_Shop_Aggregate_Fields = {
  __typename?: 'featured_shop_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Featured_Shop_Max_Fields>
  min?: Maybe<Featured_Shop_Min_Fields>
}

/** aggregate fields of "featured_shop" */
export type Featured_Shop_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Featured_Shop_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "featured_shop". All fields are combined with a logical 'AND'. */
export type Featured_Shop_Bool_Exp = {
  _and?: Maybe<Array<Featured_Shop_Bool_Exp>>
  _not?: Maybe<Featured_Shop_Bool_Exp>
  _or?: Maybe<Array<Featured_Shop_Bool_Exp>>
  featured_shop_items?: Maybe<Featured_Shop_Item_Bool_Exp>
  featured_shop_items_aggregate?: Maybe<Featured_Shop_Item_Aggregate_Bool_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "featured_shop" */
export enum Featured_Shop_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturedShopPkey = 'featured_shop_pkey',
  /** unique or primary key constraint on columns "shop_key" */
  FeaturedShopShopKeyKey = 'featured_shop_shop_key_key',
}

/** input type for inserting data into table "featured_shop" */
export type Featured_Shop_Insert_Input = {
  featured_shop_items?: Maybe<Featured_Shop_Item_Arr_Rel_Insert_Input>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
}

/** A table to hold references to the items of a shop that are featured */
export type Featured_Shop_Item = {
  __typename?: 'featured_shop_item'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  featured_shop: Featured_Shop
  id: Scalars['uuid']
  /** An object relationship */
  shop_item?: Maybe<Shop_Item>
  shop_item_id: Scalars['uuid']
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "featured_shop_item" */
export type Featured_Shop_Item_Aggregate = {
  __typename?: 'featured_shop_item_aggregate'
  aggregate?: Maybe<Featured_Shop_Item_Aggregate_Fields>
  nodes: Array<Featured_Shop_Item>
}

export type Featured_Shop_Item_Aggregate_Bool_Exp = {
  count?: Maybe<Featured_Shop_Item_Aggregate_Bool_Exp_Count>
}

export type Featured_Shop_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Featured_Shop_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "featured_shop_item" */
export type Featured_Shop_Item_Aggregate_Fields = {
  __typename?: 'featured_shop_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Featured_Shop_Item_Max_Fields>
  min?: Maybe<Featured_Shop_Item_Min_Fields>
}

/** aggregate fields of "featured_shop_item" */
export type Featured_Shop_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "featured_shop_item" */
export type Featured_Shop_Item_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Featured_Shop_Item_Max_Order_By>
  min?: Maybe<Featured_Shop_Item_Min_Order_By>
}

/** input type for inserting array relation for remote table "featured_shop_item" */
export type Featured_Shop_Item_Arr_Rel_Insert_Input = {
  data: Array<Featured_Shop_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Featured_Shop_Item_On_Conflict>
}

/** Boolean expression to filter rows from the table "featured_shop_item". All fields are combined with a logical 'AND'. */
export type Featured_Shop_Item_Bool_Exp = {
  _and?: Maybe<Array<Featured_Shop_Item_Bool_Exp>>
  _not?: Maybe<Featured_Shop_Item_Bool_Exp>
  _or?: Maybe<Array<Featured_Shop_Item_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  featured_shop?: Maybe<Featured_Shop_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "featured_shop_item" */
export enum Featured_Shop_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturedShopItemPkey = 'featured_shop_item_pkey',
  /** unique or primary key constraint on columns "shop_item_id" */
  FeaturedShopItemShopItemIdKey = 'featured_shop_item_shop_item_id_key',
  /** unique or primary key constraint on columns "shop_key", "shop_item_id" */
  FeaturedShopItemShopKeyShopItemIdKey = 'featured_shop_item_shop_key_shop_item_id_key',
}

/** input type for inserting data into table "featured_shop_item" */
export type Featured_Shop_Item_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  featured_shop?: Maybe<Featured_Shop_Obj_Rel_Insert_Input>
  id?: Maybe<Scalars['uuid']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Featured_Shop_Item_Max_Fields = {
  __typename?: 'featured_shop_item_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "featured_shop_item" */
export type Featured_Shop_Item_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Featured_Shop_Item_Min_Fields = {
  __typename?: 'featured_shop_item_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "featured_shop_item" */
export type Featured_Shop_Item_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "featured_shop_item" */
export type Featured_Shop_Item_Mutation_Response = {
  __typename?: 'featured_shop_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Featured_Shop_Item>
}

/** on_conflict condition type for table "featured_shop_item" */
export type Featured_Shop_Item_On_Conflict = {
  constraint: Featured_Shop_Item_Constraint
  update_columns?: Array<Featured_Shop_Item_Update_Column>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

/** Ordering options when selecting data from "featured_shop_item". */
export type Featured_Shop_Item_Order_By = {
  created_at?: Maybe<Order_By>
  featured_shop?: Maybe<Featured_Shop_Order_By>
  id?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: featured_shop_item */
export type Featured_Shop_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "featured_shop_item" */
export enum Featured_Shop_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "featured_shop_item" */
export type Featured_Shop_Item_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "featured_shop_item" */
export type Featured_Shop_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Featured_Shop_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Featured_Shop_Item_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "featured_shop_item" */
export enum Featured_Shop_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Featured_Shop_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Featured_Shop_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Featured_Shop_Item_Bool_Exp
}

/** aggregate max on columns */
export type Featured_Shop_Max_Fields = {
  __typename?: 'featured_shop_max_fields'
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Featured_Shop_Min_Fields = {
  __typename?: 'featured_shop_min_fields'
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "featured_shop" */
export type Featured_Shop_Mutation_Response = {
  __typename?: 'featured_shop_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Featured_Shop>
}

/** input type for inserting object relation for remote table "featured_shop" */
export type Featured_Shop_Obj_Rel_Insert_Input = {
  data: Featured_Shop_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Featured_Shop_On_Conflict>
}

/** on_conflict condition type for table "featured_shop" */
export type Featured_Shop_On_Conflict = {
  constraint: Featured_Shop_Constraint
  update_columns?: Array<Featured_Shop_Update_Column>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

/** Ordering options when selecting data from "featured_shop". */
export type Featured_Shop_Order_By = {
  featured_shop_items_aggregate?: Maybe<Featured_Shop_Item_Aggregate_Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
}

/** primary key columns input for table: featured_shop */
export type Featured_Shop_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "featured_shop" */
export enum Featured_Shop_Select_Column {
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
}

/** input type for updating data in table "featured_shop" */
export type Featured_Shop_Set_Input = {
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "featured_shop" */
export type Featured_Shop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Featured_Shop_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Featured_Shop_Stream_Cursor_Value_Input = {
  feed_item_type?: Maybe<Feed_Item_Type_Enum>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
}

/** update columns of table "featured_shop" */
export enum Featured_Shop_Update_Column {
  /** column name */
  FeedItemType = 'feed_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
}

export type Featured_Shop_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Featured_Shop_Set_Input>
  /** filter the rows which have to be updated */
  where: Featured_Shop_Bool_Exp
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_Item = {
  __typename?: 'feed_item'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  /** An object relationship */
  collection?: Maybe<Collection>
  /** An object relationship */
  collection_cart_item?: Maybe<Collection_Cart_Item>
  /** An object relationship */
  featured_shop?: Maybe<Featured_Shop>
  /** An array relationship */
  feed_item_likes: Array<Feed_Item_Like>
  /** An aggregate relationship */
  feed_item_likes_aggregate: Feed_Item_Like_Aggregate
  id: Scalars['uuid']
  /** A computed field, executes function "is_feed_item_in_discovery_backlog" */
  in_discovery_backlog?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "is_feed_item_in_discovery_feed" */
  in_discovery_feed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "is_feed_item_in_discovery_staging" */
  in_discovery_staging?: Maybe<Scalars['Boolean']>
  item_id: Scalars['uuid']
  item_type: Feed_Item_Type_Enum
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "feed_item_liked_by_user_field" */
  liked_by_user?: Maybe<Scalars['Boolean']>
  metadata: Scalars['jsonb']
  /** An object relationship */
  notifiable_user?: Maybe<Notifiable_Feed_Item_User>
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_ItemFeed_Item_LikesArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_ItemFeed_Item_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

/** A table to keep track of all the allowable polymorphic feed items */
export type Feed_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "feed_item" */
export type Feed_Item_Aggregate = {
  __typename?: 'feed_item_aggregate'
  aggregate?: Maybe<Feed_Item_Aggregate_Fields>
  nodes: Array<Feed_Item>
}

/** aggregate fields of "feed_item" */
export type Feed_Item_Aggregate_Fields = {
  __typename?: 'feed_item_aggregate_fields'
  avg?: Maybe<Feed_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Feed_Item_Max_Fields>
  min?: Maybe<Feed_Item_Min_Fields>
  stddev?: Maybe<Feed_Item_Stddev_Fields>
  stddev_pop?: Maybe<Feed_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Feed_Item_Stddev_Samp_Fields>
  sum?: Maybe<Feed_Item_Sum_Fields>
  var_pop?: Maybe<Feed_Item_Var_Pop_Fields>
  var_samp?: Maybe<Feed_Item_Var_Samp_Fields>
  variance?: Maybe<Feed_Item_Variance_Fields>
}

/** aggregate fields of "feed_item" */
export type Feed_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feed_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Feed_Item_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Feed_Item_Avg_Fields = {
  __typename?: 'feed_item_avg_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** Boolean expression to filter rows from the table "feed_item". All fields are combined with a logical 'AND'. */
export type Feed_Item_Bool_Exp = {
  _and?: Maybe<Array<Feed_Item_Bool_Exp>>
  _not?: Maybe<Feed_Item_Bool_Exp>
  _or?: Maybe<Array<Feed_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_cart_item?: Maybe<Collection_Cart_Item_Bool_Exp>
  featured_shop?: Maybe<Featured_Shop_Bool_Exp>
  feed_item_likes?: Maybe<Feed_Item_Like_Bool_Exp>
  feed_item_likes_aggregate?: Maybe<Feed_Item_Like_Aggregate_Bool_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  in_discovery_backlog?: Maybe<Boolean_Comparison_Exp>
  in_discovery_feed?: Maybe<Boolean_Comparison_Exp>
  in_discovery_staging?: Maybe<Boolean_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Feed_Item_Type_Enum_Comparison_Exp>
  like_count?: Maybe<Int_Comparison_Exp>
  liked_by_user?: Maybe<Boolean_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  notifiable_user?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

/** unique or primary key constraints on table "feed_item" */
export enum Feed_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  FeedItemItemTypeItemIdKey = 'feed_item_item_type_item_id_key',
  /** unique or primary key constraint on columns "id" */
  FeedItemPkey = 'feed_item_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Feed_Item_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Feed_Item_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Feed_Item_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "feed_item" */
export type Feed_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_cart_item?: Maybe<Collection_Cart_Item_Obj_Rel_Insert_Input>
  featured_shop?: Maybe<Featured_Shop_Obj_Rel_Insert_Input>
  feed_item_likes?: Maybe<Feed_Item_Like_Arr_Rel_Insert_Input>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Feed_Item_Type_Enum>
  metadata?: Maybe<Scalars['jsonb']>
  notifiable_user?: Maybe<Notifiable_Feed_Item_User_Obj_Rel_Insert_Input>
}

/** The accumulation of likes items that can go in the discovery feed */
export type Feed_Item_Like = {
  __typename?: 'feed_item_like'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  feed_item: Feed_Item
  feed_item_id: Scalars['uuid']
  id: Scalars['uuid']
  notifiable_item_type: Notifiable_Item_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  value: Scalars['Int']
}

/** aggregated selection of "feed_item_like" */
export type Feed_Item_Like_Aggregate = {
  __typename?: 'feed_item_like_aggregate'
  aggregate?: Maybe<Feed_Item_Like_Aggregate_Fields>
  nodes: Array<Feed_Item_Like>
}

export type Feed_Item_Like_Aggregate_Bool_Exp = {
  count?: Maybe<Feed_Item_Like_Aggregate_Bool_Exp_Count>
}

export type Feed_Item_Like_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Feed_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Feed_Item_Like_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "feed_item_like" */
export type Feed_Item_Like_Aggregate_Fields = {
  __typename?: 'feed_item_like_aggregate_fields'
  avg?: Maybe<Feed_Item_Like_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Feed_Item_Like_Max_Fields>
  min?: Maybe<Feed_Item_Like_Min_Fields>
  stddev?: Maybe<Feed_Item_Like_Stddev_Fields>
  stddev_pop?: Maybe<Feed_Item_Like_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Feed_Item_Like_Stddev_Samp_Fields>
  sum?: Maybe<Feed_Item_Like_Sum_Fields>
  var_pop?: Maybe<Feed_Item_Like_Var_Pop_Fields>
  var_samp?: Maybe<Feed_Item_Like_Var_Samp_Fields>
  variance?: Maybe<Feed_Item_Like_Variance_Fields>
}

/** aggregate fields of "feed_item_like" */
export type Feed_Item_Like_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feed_Item_Like_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "feed_item_like" */
export type Feed_Item_Like_Aggregate_Order_By = {
  avg?: Maybe<Feed_Item_Like_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Feed_Item_Like_Max_Order_By>
  min?: Maybe<Feed_Item_Like_Min_Order_By>
  stddev?: Maybe<Feed_Item_Like_Stddev_Order_By>
  stddev_pop?: Maybe<Feed_Item_Like_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Feed_Item_Like_Stddev_Samp_Order_By>
  sum?: Maybe<Feed_Item_Like_Sum_Order_By>
  var_pop?: Maybe<Feed_Item_Like_Var_Pop_Order_By>
  var_samp?: Maybe<Feed_Item_Like_Var_Samp_Order_By>
  variance?: Maybe<Feed_Item_Like_Variance_Order_By>
}

/** input type for inserting array relation for remote table "feed_item_like" */
export type Feed_Item_Like_Arr_Rel_Insert_Input = {
  data: Array<Feed_Item_Like_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** aggregate avg on columns */
export type Feed_Item_Like_Avg_Fields = {
  __typename?: 'feed_item_like_avg_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "feed_item_like" */
export type Feed_Item_Like_Avg_Order_By = {
  value?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "feed_item_like". All fields are combined with a logical 'AND'. */
export type Feed_Item_Like_Bool_Exp = {
  _and?: Maybe<Array<Feed_Item_Like_Bool_Exp>>
  _not?: Maybe<Feed_Item_Like_Bool_Exp>
  _or?: Maybe<Array<Feed_Item_Like_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  feed_item?: Maybe<Feed_Item_Bool_Exp>
  feed_item_id?: Maybe<Uuid_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  value?: Maybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "feed_item_like" */
export enum Feed_Item_Like_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeedItemLikeIdKey = 'feed_item_like_id_key',
  /** unique or primary key constraint on columns "feed_item_id", "user_id" */
  FeedItemLikePkey = 'feed_item_like_pkey',
}

/** input type for incrementing numeric columns in table "feed_item_like" */
export type Feed_Item_Like_Inc_Input = {
  value?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "feed_item_like" */
export type Feed_Item_Like_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item?: Maybe<Feed_Item_Obj_Rel_Insert_Input>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Feed_Item_Like_Max_Fields = {
  __typename?: 'feed_item_like_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "feed_item_like" */
export type Feed_Item_Like_Max_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Feed_Item_Like_Min_Fields = {
  __typename?: 'feed_item_like_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "feed_item_like" */
export type Feed_Item_Like_Min_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** response of any mutation on the table "feed_item_like" */
export type Feed_Item_Like_Mutation_Response = {
  __typename?: 'feed_item_like_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Item_Like>
}

/** input type for inserting object relation for remote table "feed_item_like" */
export type Feed_Item_Like_Obj_Rel_Insert_Input = {
  data: Feed_Item_Like_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** on_conflict condition type for table "feed_item_like" */
export type Feed_Item_Like_On_Conflict = {
  constraint: Feed_Item_Like_Constraint
  update_columns?: Array<Feed_Item_Like_Update_Column>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

/** Ordering options when selecting data from "feed_item_like". */
export type Feed_Item_Like_Order_By = {
  created_at?: Maybe<Order_By>
  feed_item?: Maybe<Feed_Item_Order_By>
  feed_item_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: feed_item_like */
export type Feed_Item_Like_Pk_Columns_Input = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Feed_Item_Like_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

/** select columns of table "feed_item_like" */
export enum Feed_Item_Like_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "feed_item_like" */
export type Feed_Item_Like_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Feed_Item_Like_Stddev_Fields = {
  __typename?: 'feed_item_like_stddev_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "feed_item_like" */
export type Feed_Item_Like_Stddev_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Feed_Item_Like_Stddev_Pop_Fields = {
  __typename?: 'feed_item_like_stddev_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "feed_item_like" */
export type Feed_Item_Like_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Feed_Item_Like_Stddev_Samp_Fields = {
  __typename?: 'feed_item_like_stddev_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "feed_item_like" */
export type Feed_Item_Like_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** Streaming cursor of the table "feed_item_like" */
export type Feed_Item_Like_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Item_Like_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Feed_Item_Like_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  feed_item_id?: Maybe<Scalars['uuid']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Feed_Item_Like_Sum_Fields = {
  __typename?: 'feed_item_like_sum_fields'
  value?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "feed_item_like" */
export type Feed_Item_Like_Sum_Order_By = {
  value?: Maybe<Order_By>
}

/** update columns of table "feed_item_like" */
export enum Feed_Item_Like_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FeedItemId = 'feed_item_id',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value',
}

export type Feed_Item_Like_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Feed_Item_Like_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feed_Item_Like_Set_Input>
  /** filter the rows which have to be updated */
  where: Feed_Item_Like_Bool_Exp
}

/** aggregate var_pop on columns */
export type Feed_Item_Like_Var_Pop_Fields = {
  __typename?: 'feed_item_like_var_pop_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "feed_item_like" */
export type Feed_Item_Like_Var_Pop_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Feed_Item_Like_Var_Samp_Fields = {
  __typename?: 'feed_item_like_var_samp_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "feed_item_like" */
export type Feed_Item_Like_Var_Samp_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Feed_Item_Like_Variance_Fields = {
  __typename?: 'feed_item_like_variance_fields'
  value?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "feed_item_like" */
export type Feed_Item_Like_Variance_Order_By = {
  value?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Feed_Item_Max_Fields = {
  __typename?: 'feed_item_max_fields'
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Feed_Item_Min_Fields = {
  __typename?: 'feed_item_min_fields'
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "feed_item" */
export type Feed_Item_Mutation_Response = {
  __typename?: 'feed_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Item>
}

/** input type for inserting object relation for remote table "feed_item" */
export type Feed_Item_Obj_Rel_Insert_Input = {
  data: Feed_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Feed_Item_On_Conflict>
}

/** on_conflict condition type for table "feed_item" */
export type Feed_Item_On_Conflict = {
  constraint: Feed_Item_Constraint
  update_columns?: Array<Feed_Item_Update_Column>
  where?: Maybe<Feed_Item_Bool_Exp>
}

/** Ordering options when selecting data from "feed_item". */
export type Feed_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_cart_item?: Maybe<Collection_Cart_Item_Order_By>
  featured_shop?: Maybe<Featured_Shop_Order_By>
  feed_item_likes_aggregate?: Maybe<Feed_Item_Like_Aggregate_Order_By>
  id?: Maybe<Order_By>
  in_discovery_backlog?: Maybe<Order_By>
  in_discovery_feed?: Maybe<Order_By>
  in_discovery_staging?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  like_count?: Maybe<Order_By>
  liked_by_user?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  notifiable_user?: Maybe<Notifiable_Feed_Item_User_Order_By>
}

/** primary key columns input for table: feed_item */
export type Feed_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Feed_Item_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "feed_item" */
export enum Feed_Item_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
}

/** input type for updating data in table "feed_item" */
export type Feed_Item_Set_Input = {
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Feed_Item_Type_Enum>
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate stddev on columns */
export type Feed_Item_Stddev_Fields = {
  __typename?: 'feed_item_stddev_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate stddev_pop on columns */
export type Feed_Item_Stddev_Pop_Fields = {
  __typename?: 'feed_item_stddev_pop_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate stddev_samp on columns */
export type Feed_Item_Stddev_Samp_Fields = {
  __typename?: 'feed_item_stddev_samp_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** Streaming cursor of the table "feed_item" */
export type Feed_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Feed_Item_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Feed_Item_Type_Enum>
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate sum on columns */
export type Feed_Item_Sum_Fields = {
  __typename?: 'feed_item_sum_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** An enum table to hold the different feed item types */
export type Feed_Item_Type = {
  __typename?: 'feed_item_type'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "feed_item_type" */
export type Feed_Item_Type_Aggregate = {
  __typename?: 'feed_item_type_aggregate'
  aggregate?: Maybe<Feed_Item_Type_Aggregate_Fields>
  nodes: Array<Feed_Item_Type>
}

/** aggregate fields of "feed_item_type" */
export type Feed_Item_Type_Aggregate_Fields = {
  __typename?: 'feed_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Feed_Item_Type_Max_Fields>
  min?: Maybe<Feed_Item_Type_Min_Fields>
}

/** aggregate fields of "feed_item_type" */
export type Feed_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feed_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "feed_item_type". All fields are combined with a logical 'AND'. */
export type Feed_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Feed_Item_Type_Bool_Exp>>
  _not?: Maybe<Feed_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Feed_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "feed_item_type" */
export enum Feed_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  FeedItemTypePkey = 'feed_item_type_pkey',
}

export enum Feed_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
  CollectionCartItem = 'collection_cart_item',
  FeaturedShop = 'featured_shop',
}

/** Boolean expression to compare columns of type "feed_item_type_enum". All fields are combined with logical 'AND'. */
export type Feed_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Feed_Item_Type_Enum>
  _in?: Maybe<Array<Feed_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Feed_Item_Type_Enum>
  _nin?: Maybe<Array<Feed_Item_Type_Enum>>
}

/** input type for inserting data into table "feed_item_type" */
export type Feed_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Feed_Item_Type_Max_Fields = {
  __typename?: 'feed_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Feed_Item_Type_Min_Fields = {
  __typename?: 'feed_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "feed_item_type" */
export type Feed_Item_Type_Mutation_Response = {
  __typename?: 'feed_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Feed_Item_Type>
}

/** on_conflict condition type for table "feed_item_type" */
export type Feed_Item_Type_On_Conflict = {
  constraint: Feed_Item_Type_Constraint
  update_columns?: Array<Feed_Item_Type_Update_Column>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "feed_item_type". */
export type Feed_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: feed_item_type */
export type Feed_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "feed_item_type" */
export enum Feed_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "feed_item_type" */
export type Feed_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "feed_item_type" */
export type Feed_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feed_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Feed_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "feed_item_type" */
export enum Feed_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Feed_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feed_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Feed_Item_Type_Bool_Exp
}

/** update columns of table "feed_item" */
export enum Feed_Item_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Metadata = 'metadata',
}

export type Feed_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Feed_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Feed_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Feed_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Feed_Item_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Feed_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Feed_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Feed_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Feed_Item_Var_Pop_Fields = {
  __typename?: 'feed_item_var_pop_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate var_samp on columns */
export type Feed_Item_Var_Samp_Fields = {
  __typename?: 'feed_item_var_samp_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

/** aggregate variance on columns */
export type Feed_Item_Variance_Fields = {
  __typename?: 'feed_item_variance_fields'
  /** A computed field, executes function "feed_item_like_count" */
  like_count?: Maybe<Scalars['Int']>
}

export type Feed_Items_For_User_Args = {
  in_username?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>
  _gt?: Maybe<Scalars['float8']>
  _gte?: Maybe<Scalars['float8']>
  _in?: Maybe<Array<Scalars['float8']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['float8']>
  _lte?: Maybe<Scalars['float8']>
  _neq?: Maybe<Scalars['float8']>
  _nin?: Maybe<Array<Scalars['float8']>>
}

/** columns and relationships of "fmtc_deal" */
export type Fmtc_Deal = {
  __typename?: 'fmtc_deal'
  affiliate: Scalars['String']
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  direct_url: Scalars['String']
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id: Scalars['numeric']
  fmtc_url: Scalars['String']
  id: Scalars['uuid']
  merchant_id: Scalars['numeric']
  merchant_name: Scalars['String']
  metadata: Scalars['jsonb']
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  start_date: Scalars['timestamptz']
  status: Scalars['String']
  title: Scalars['String']
  types?: Maybe<Array<Scalars['String']>>
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "fmtc_deal" */
export type Fmtc_DealMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "fmtc_deal" */
export type Fmtc_Deal_Aggregate = {
  __typename?: 'fmtc_deal_aggregate'
  aggregate?: Maybe<Fmtc_Deal_Aggregate_Fields>
  nodes: Array<Fmtc_Deal>
}

/** aggregate fields of "fmtc_deal" */
export type Fmtc_Deal_Aggregate_Fields = {
  __typename?: 'fmtc_deal_aggregate_fields'
  avg?: Maybe<Fmtc_Deal_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Fmtc_Deal_Max_Fields>
  min?: Maybe<Fmtc_Deal_Min_Fields>
  stddev?: Maybe<Fmtc_Deal_Stddev_Fields>
  stddev_pop?: Maybe<Fmtc_Deal_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Fmtc_Deal_Stddev_Samp_Fields>
  sum?: Maybe<Fmtc_Deal_Sum_Fields>
  var_pop?: Maybe<Fmtc_Deal_Var_Pop_Fields>
  var_samp?: Maybe<Fmtc_Deal_Var_Samp_Fields>
  variance?: Maybe<Fmtc_Deal_Variance_Fields>
}

/** aggregate fields of "fmtc_deal" */
export type Fmtc_Deal_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Fmtc_Deal_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Fmtc_Deal_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Fmtc_Deal_Avg_Fields = {
  __typename?: 'fmtc_deal_avg_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "fmtc_deal". All fields are combined with a logical 'AND'. */
export type Fmtc_Deal_Bool_Exp = {
  _and?: Maybe<Array<Fmtc_Deal_Bool_Exp>>
  _not?: Maybe<Fmtc_Deal_Bool_Exp>
  _or?: Maybe<Array<Fmtc_Deal_Bool_Exp>>
  affiliate?: Maybe<String_Comparison_Exp>
  affiliate_url?: Maybe<String_Comparison_Exp>
  categories?: Maybe<String_Array_Comparison_Exp>
  code?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  direct_url?: Maybe<String_Comparison_Exp>
  discount?: Maybe<Numeric_Comparison_Exp>
  discount_percent?: Maybe<Numeric_Comparison_Exp>
  end_date?: Maybe<Timestamptz_Comparison_Exp>
  fmtc_id?: Maybe<Numeric_Comparison_Exp>
  fmtc_url?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  merchant_id?: Maybe<Numeric_Comparison_Exp>
  merchant_name?: Maybe<String_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  rating?: Maybe<Numeric_Comparison_Exp>
  sale_price?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  start_date?: Maybe<Timestamptz_Comparison_Exp>
  status?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  types?: Maybe<String_Array_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "fmtc_deal" */
export enum Fmtc_Deal_Constraint {
  /** unique or primary key constraint on columns "fmtc_id" */
  FmtcDealFmtcId = 'fmtc_deal_fmtc_id',
  /** unique or primary key constraint on columns "id" */
  FmtcDealPkey = 'fmtc_deal_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Fmtc_Deal_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Fmtc_Deal_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Fmtc_Deal_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "fmtc_deal" */
export type Fmtc_Deal_Inc_Input = {
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  fmtc_id?: Maybe<Scalars['numeric']>
  merchant_id?: Maybe<Scalars['numeric']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "fmtc_deal" */
export type Fmtc_Deal_Insert_Input = {
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Fmtc_Deal_Max_Fields = {
  __typename?: 'fmtc_deal_max_fields'
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Fmtc_Deal_Min_Fields = {
  __typename?: 'fmtc_deal_min_fields'
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "fmtc_deal" */
export type Fmtc_Deal_Mutation_Response = {
  __typename?: 'fmtc_deal_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Fmtc_Deal>
}

/** on_conflict condition type for table "fmtc_deal" */
export type Fmtc_Deal_On_Conflict = {
  constraint: Fmtc_Deal_Constraint
  update_columns?: Array<Fmtc_Deal_Update_Column>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

/** Ordering options when selecting data from "fmtc_deal". */
export type Fmtc_Deal_Order_By = {
  affiliate?: Maybe<Order_By>
  affiliate_url?: Maybe<Order_By>
  categories?: Maybe<Order_By>
  code?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  direct_url?: Maybe<Order_By>
  discount?: Maybe<Order_By>
  discount_percent?: Maybe<Order_By>
  end_date?: Maybe<Order_By>
  fmtc_id?: Maybe<Order_By>
  fmtc_url?: Maybe<Order_By>
  id?: Maybe<Order_By>
  merchant_id?: Maybe<Order_By>
  merchant_name?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  rating?: Maybe<Order_By>
  sale_price?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  start_date?: Maybe<Order_By>
  status?: Maybe<Order_By>
  title?: Maybe<Order_By>
  types?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: fmtc_deal */
export type Fmtc_Deal_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Fmtc_Deal_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "fmtc_deal" */
export enum Fmtc_Deal_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  AffiliateUrl = 'affiliate_url',
  /** column name */
  Categories = 'categories',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DirectUrl = 'direct_url',
  /** column name */
  Discount = 'discount',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FmtcId = 'fmtc_id',
  /** column name */
  FmtcUrl = 'fmtc_url',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchant_id',
  /** column name */
  MerchantName = 'merchant_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Rating = 'rating',
  /** column name */
  SalePrice = 'sale_price',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "fmtc_deal" */
export type Fmtc_Deal_Set_Input = {
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Fmtc_Deal_Stddev_Fields = {
  __typename?: 'fmtc_deal_stddev_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Fmtc_Deal_Stddev_Pop_Fields = {
  __typename?: 'fmtc_deal_stddev_pop_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Fmtc_Deal_Stddev_Samp_Fields = {
  __typename?: 'fmtc_deal_stddev_samp_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "fmtc_deal" */
export type Fmtc_Deal_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Fmtc_Deal_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Fmtc_Deal_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Scalars['String']>
  affiliate_url?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  code?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  direct_url?: Maybe<Scalars['String']>
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  end_date?: Maybe<Scalars['timestamptz']>
  fmtc_id?: Maybe<Scalars['numeric']>
  fmtc_url?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  merchant_id?: Maybe<Scalars['numeric']>
  merchant_name?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['jsonb']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  types?: Maybe<Array<Scalars['String']>>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Fmtc_Deal_Sum_Fields = {
  __typename?: 'fmtc_deal_sum_fields'
  discount?: Maybe<Scalars['numeric']>
  discount_percent?: Maybe<Scalars['numeric']>
  fmtc_id?: Maybe<Scalars['numeric']>
  merchant_id?: Maybe<Scalars['numeric']>
  rating?: Maybe<Scalars['numeric']>
  sale_price?: Maybe<Scalars['numeric']>
}

/** update columns of table "fmtc_deal" */
export enum Fmtc_Deal_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  AffiliateUrl = 'affiliate_url',
  /** column name */
  Categories = 'categories',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DirectUrl = 'direct_url',
  /** column name */
  Discount = 'discount',
  /** column name */
  DiscountPercent = 'discount_percent',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  FmtcId = 'fmtc_id',
  /** column name */
  FmtcUrl = 'fmtc_url',
  /** column name */
  Id = 'id',
  /** column name */
  MerchantId = 'merchant_id',
  /** column name */
  MerchantName = 'merchant_name',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Rating = 'rating',
  /** column name */
  SalePrice = 'sale_price',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Types = 'types',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Fmtc_Deal_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Fmtc_Deal_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Fmtc_Deal_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Fmtc_Deal_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Fmtc_Deal_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Fmtc_Deal_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Fmtc_Deal_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Fmtc_Deal_Set_Input>
  /** filter the rows which have to be updated */
  where: Fmtc_Deal_Bool_Exp
}

/** aggregate var_pop on columns */
export type Fmtc_Deal_Var_Pop_Fields = {
  __typename?: 'fmtc_deal_var_pop_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Fmtc_Deal_Var_Samp_Fields = {
  __typename?: 'fmtc_deal_var_samp_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Fmtc_Deal_Variance_Fields = {
  __typename?: 'fmtc_deal_variance_fields'
  discount?: Maybe<Scalars['Float']>
  discount_percent?: Maybe<Scalars['Float']>
  fmtc_id?: Maybe<Scalars['Float']>
  merchant_id?: Maybe<Scalars['Float']>
  rating?: Maybe<Scalars['Float']>
  sale_price?: Maybe<Scalars['Float']>
}

/** A polymorphic table to identify items that can be followed */
export type Followable_Item = {
  __typename?: 'followable_item'
  /** An object relationship */
  collection?: Maybe<Collection>
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

/** aggregated selection of "followable_item" */
export type Followable_Item_Aggregate = {
  __typename?: 'followable_item_aggregate'
  aggregate?: Maybe<Followable_Item_Aggregate_Fields>
  nodes: Array<Followable_Item>
}

/** aggregate fields of "followable_item" */
export type Followable_Item_Aggregate_Fields = {
  __typename?: 'followable_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Followable_Item_Max_Fields>
  min?: Maybe<Followable_Item_Min_Fields>
}

/** aggregate fields of "followable_item" */
export type Followable_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Followable_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "followable_item". All fields are combined with a logical 'AND'. */
export type Followable_Item_Bool_Exp = {
  _and?: Maybe<Array<Followable_Item_Bool_Exp>>
  _not?: Maybe<Followable_Item_Bool_Exp>
  _or?: Maybe<Array<Followable_Item_Bool_Exp>>
  collection?: Maybe<Collection_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Followable_Item_Type_Enum_Comparison_Exp>
}

/** unique or primary key constraints on table "followable_item" */
export enum Followable_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  FollowingItemItemIdItemTypeKey = 'following_item_item_id_item_type_key',
  /** unique or primary key constraint on columns "item_id", "item_type" */
  FollowingItemPkey = 'following_item_pkey',
}

/** input type for inserting data into table "followable_item" */
export type Followable_Item_Insert_Input = {
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Followable_Item_Type_Enum>
}

/** aggregate max on columns */
export type Followable_Item_Max_Fields = {
  __typename?: 'followable_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Followable_Item_Min_Fields = {
  __typename?: 'followable_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "followable_item" */
export type Followable_Item_Mutation_Response = {
  __typename?: 'followable_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Followable_Item>
}

/** input type for inserting object relation for remote table "followable_item" */
export type Followable_Item_Obj_Rel_Insert_Input = {
  data: Followable_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Followable_Item_On_Conflict>
}

/** on_conflict condition type for table "followable_item" */
export type Followable_Item_On_Conflict = {
  constraint: Followable_Item_Constraint
  update_columns?: Array<Followable_Item_Update_Column>
  where?: Maybe<Followable_Item_Bool_Exp>
}

/** Ordering options when selecting data from "followable_item". */
export type Followable_Item_Order_By = {
  collection?: Maybe<Collection_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
}

/** primary key columns input for table: followable_item */
export type Followable_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

/** select columns of table "followable_item" */
export enum Followable_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "followable_item" */
export type Followable_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Followable_Item_Type_Enum>
}

/** Streaming cursor of the table "followable_item" */
export type Followable_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Followable_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Followable_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Followable_Item_Type_Enum>
}

/** An enum table to hold the different followable item types */
export type Followable_Item_Type = {
  __typename?: 'followable_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "followable_item_type" */
export type Followable_Item_Type_Aggregate = {
  __typename?: 'followable_item_type_aggregate'
  aggregate?: Maybe<Followable_Item_Type_Aggregate_Fields>
  nodes: Array<Followable_Item_Type>
}

/** aggregate fields of "followable_item_type" */
export type Followable_Item_Type_Aggregate_Fields = {
  __typename?: 'followable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Followable_Item_Type_Max_Fields>
  min?: Maybe<Followable_Item_Type_Min_Fields>
}

/** aggregate fields of "followable_item_type" */
export type Followable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Followable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "followable_item_type". All fields are combined with a logical 'AND'. */
export type Followable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Followable_Item_Type_Bool_Exp>>
  _not?: Maybe<Followable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Followable_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "followable_item_type" */
export enum Followable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  FollowableItemTypePkey = 'followable_item_type_pkey',
}

export enum Followable_Item_Type_Enum {
  Collection = 'collection',
}

/** Boolean expression to compare columns of type "followable_item_type_enum". All fields are combined with logical 'AND'. */
export type Followable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Followable_Item_Type_Enum>
  _in?: Maybe<Array<Followable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Followable_Item_Type_Enum>
  _nin?: Maybe<Array<Followable_Item_Type_Enum>>
}

/** input type for inserting data into table "followable_item_type" */
export type Followable_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Followable_Item_Type_Max_Fields = {
  __typename?: 'followable_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Followable_Item_Type_Min_Fields = {
  __typename?: 'followable_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "followable_item_type" */
export type Followable_Item_Type_Mutation_Response = {
  __typename?: 'followable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Followable_Item_Type>
}

/** on_conflict condition type for table "followable_item_type" */
export type Followable_Item_Type_On_Conflict = {
  constraint: Followable_Item_Type_Constraint
  update_columns?: Array<Followable_Item_Type_Update_Column>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "followable_item_type". */
export type Followable_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: followable_item_type */
export type Followable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "followable_item_type" */
export enum Followable_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "followable_item_type" */
export type Followable_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "followable_item_type" */
export type Followable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Followable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Followable_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "followable_item_type" */
export enum Followable_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Followable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Followable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Followable_Item_Type_Bool_Exp
}

/** update columns of table "followable_item" */
export enum Followable_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Followable_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Followable_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Followable_Item_Bool_Exp
}

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>
  _gt?: Maybe<Scalars['json']>
  _gte?: Maybe<Scalars['json']>
  _in?: Maybe<Array<Scalars['json']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['json']>
  _lte?: Maybe<Scalars['json']>
  _neq?: Maybe<Scalars['json']>
  _nin?: Maybe<Array<Scalars['json']>>
}

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>
  _eq?: Maybe<Scalars['jsonb']>
  _gt?: Maybe<Scalars['jsonb']>
  _gte?: Maybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>
  _in?: Maybe<Array<Scalars['jsonb']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['jsonb']>
  _lte?: Maybe<Scalars['jsonb']>
  _neq?: Maybe<Scalars['jsonb']>
  _nin?: Maybe<Array<Scalars['jsonb']>>
}

/** A table to hold event data for a creator context */
export type Leaderboard_Event = {
  __typename?: 'leaderboard_event'
  /** An object relationship */
  actor?: Maybe<User>
  created_at: Scalars['timestamptz']
  /** An object relationship */
  creator: User
  creator_id: Scalars['String']
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash: Scalars['String']
  event_name: Leaderboard_Event_Name_Enum
  id: Scalars['uuid']
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points: Scalars['Int']
  updated_at: Scalars['timestamptz']
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id: Scalars['String']
}

/** aggregated selection of "leaderboard_event" */
export type Leaderboard_Event_Aggregate = {
  __typename?: 'leaderboard_event_aggregate'
  aggregate?: Maybe<Leaderboard_Event_Aggregate_Fields>
  nodes: Array<Leaderboard_Event>
}

/** aggregate fields of "leaderboard_event" */
export type Leaderboard_Event_Aggregate_Fields = {
  __typename?: 'leaderboard_event_aggregate_fields'
  avg?: Maybe<Leaderboard_Event_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Leaderboard_Event_Max_Fields>
  min?: Maybe<Leaderboard_Event_Min_Fields>
  stddev?: Maybe<Leaderboard_Event_Stddev_Fields>
  stddev_pop?: Maybe<Leaderboard_Event_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Leaderboard_Event_Stddev_Samp_Fields>
  sum?: Maybe<Leaderboard_Event_Sum_Fields>
  var_pop?: Maybe<Leaderboard_Event_Var_Pop_Fields>
  var_samp?: Maybe<Leaderboard_Event_Var_Samp_Fields>
  variance?: Maybe<Leaderboard_Event_Variance_Fields>
}

/** aggregate fields of "leaderboard_event" */
export type Leaderboard_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Leaderboard_Event_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Leaderboard_Event_Avg_Fields = {
  __typename?: 'leaderboard_event_avg_fields'
  points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "leaderboard_event". All fields are combined with a logical 'AND'. */
export type Leaderboard_Event_Bool_Exp = {
  _and?: Maybe<Array<Leaderboard_Event_Bool_Exp>>
  _not?: Maybe<Leaderboard_Event_Bool_Exp>
  _or?: Maybe<Array<Leaderboard_Event_Bool_Exp>>
  actor?: Maybe<User_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator?: Maybe<User_Bool_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  emitted_at?: Maybe<Timestamptz_Comparison_Exp>
  event_hash?: Maybe<String_Comparison_Exp>
  event_name?: Maybe<Leaderboard_Event_Name_Enum_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  identifier_id?: Maybe<String_Comparison_Exp>
  identifier_type?: Maybe<String_Comparison_Exp>
  points?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "leaderboard_event" */
export enum Leaderboard_Event_Constraint {
  /** unique or primary key constraint on columns "event_hash" */
  LeaderboardEventsEventHashKey = 'leaderboard_events_event_hash_key',
  /** unique or primary key constraint on columns "id" */
  LeaderboardEventsPkey = 'leaderboard_events_pkey',
}

/** input type for incrementing numeric columns in table "leaderboard_event" */
export type Leaderboard_Event_Inc_Input = {
  points?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "leaderboard_event" */
export type Leaderboard_Event_Insert_Input = {
  actor?: Maybe<User_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  creator?: Maybe<User_Obj_Rel_Insert_Input>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  event_name?: Maybe<Leaderboard_Event_Name_Enum>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Leaderboard_Event_Max_Fields = {
  __typename?: 'leaderboard_event_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Leaderboard_Event_Min_Fields = {
  __typename?: 'leaderboard_event_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "leaderboard_event" */
export type Leaderboard_Event_Mutation_Response = {
  __typename?: 'leaderboard_event_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Leaderboard_Event>
}

/** An enum table to hold all of the allowable leaderboard event names */
export type Leaderboard_Event_Name = {
  __typename?: 'leaderboard_event_name'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "leaderboard_event_name" */
export type Leaderboard_Event_Name_Aggregate = {
  __typename?: 'leaderboard_event_name_aggregate'
  aggregate?: Maybe<Leaderboard_Event_Name_Aggregate_Fields>
  nodes: Array<Leaderboard_Event_Name>
}

/** aggregate fields of "leaderboard_event_name" */
export type Leaderboard_Event_Name_Aggregate_Fields = {
  __typename?: 'leaderboard_event_name_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Leaderboard_Event_Name_Max_Fields>
  min?: Maybe<Leaderboard_Event_Name_Min_Fields>
}

/** aggregate fields of "leaderboard_event_name" */
export type Leaderboard_Event_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "leaderboard_event_name". All fields are combined with a logical 'AND'. */
export type Leaderboard_Event_Name_Bool_Exp = {
  _and?: Maybe<Array<Leaderboard_Event_Name_Bool_Exp>>
  _not?: Maybe<Leaderboard_Event_Name_Bool_Exp>
  _or?: Maybe<Array<Leaderboard_Event_Name_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "leaderboard_event_name" */
export enum Leaderboard_Event_Name_Constraint {
  /** unique or primary key constraint on columns "value" */
  LeaderboardEventNamePkey = 'leaderboard_event_name_pkey',
}

export enum Leaderboard_Event_Name_Enum {
  AcceptsCollaborationInvite = 'accepts_collaboration_invite',
  AddsItemToCollaborativeCollection = 'adds_item_to_collaborative_collection',
  ClickedAddToCarrot = 'clicked_add_to_carrot',
  CollectionCollaborateCtaClick = 'collection_collaborate_cta_click',
  CommentsOnCartItem = 'comments_on_cart_item',
  CopiedShareUrl = 'copied_share_url',
  ExtensionInstall = 'extension_install',
  FollowsCollection = 'follows_collection',
  LikesACartItem = 'likes_a_cart_item',
  NewPaidAnnualSubscriber = 'new_paid_annual_subscriber',
  NewPaidMonthlySubscriber = 'new_paid_monthly_subscriber',
  NewSubscriber = 'new_subscriber',
  OpenedItem = 'opened_item',
  ViewedPdpModal = 'viewed_pdp_modal',
}

/** Boolean expression to compare columns of type "leaderboard_event_name_enum". All fields are combined with logical 'AND'. */
export type Leaderboard_Event_Name_Enum_Comparison_Exp = {
  _eq?: Maybe<Leaderboard_Event_Name_Enum>
  _in?: Maybe<Array<Leaderboard_Event_Name_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Leaderboard_Event_Name_Enum>
  _nin?: Maybe<Array<Leaderboard_Event_Name_Enum>>
}

/** input type for inserting data into table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Leaderboard_Event_Name_Max_Fields = {
  __typename?: 'leaderboard_event_name_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Leaderboard_Event_Name_Min_Fields = {
  __typename?: 'leaderboard_event_name_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Mutation_Response = {
  __typename?: 'leaderboard_event_name_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Leaderboard_Event_Name>
}

/** on_conflict condition type for table "leaderboard_event_name" */
export type Leaderboard_Event_Name_On_Conflict = {
  constraint: Leaderboard_Event_Name_Constraint
  update_columns?: Array<Leaderboard_Event_Name_Update_Column>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

/** Ordering options when selecting data from "leaderboard_event_name". */
export type Leaderboard_Event_Name_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: leaderboard_event_name */
export type Leaderboard_Event_Name_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "leaderboard_event_name" */
export enum Leaderboard_Event_Name_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "leaderboard_event_name" */
export type Leaderboard_Event_Name_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leaderboard_Event_Name_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Leaderboard_Event_Name_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "leaderboard_event_name" */
export enum Leaderboard_Event_Name_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Leaderboard_Event_Name_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Leaderboard_Event_Name_Set_Input>
  /** filter the rows which have to be updated */
  where: Leaderboard_Event_Name_Bool_Exp
}

/** on_conflict condition type for table "leaderboard_event" */
export type Leaderboard_Event_On_Conflict = {
  constraint: Leaderboard_Event_Constraint
  update_columns?: Array<Leaderboard_Event_Update_Column>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

/** Ordering options when selecting data from "leaderboard_event". */
export type Leaderboard_Event_Order_By = {
  actor?: Maybe<User_Order_By>
  created_at?: Maybe<Order_By>
  creator?: Maybe<User_Order_By>
  creator_id?: Maybe<Order_By>
  emitted_at?: Maybe<Order_By>
  event_hash?: Maybe<Order_By>
  event_name?: Maybe<Order_By>
  id?: Maybe<Order_By>
  identifier_id?: Maybe<Order_By>
  identifier_type?: Maybe<Order_By>
  points?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: leaderboard_event */
export type Leaderboard_Event_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "leaderboard_event" */
export enum Leaderboard_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  EmittedAt = 'emitted_at',
  /** column name */
  EventHash = 'event_hash',
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentifierId = 'identifier_id',
  /** column name */
  IdentifierType = 'identifier_type',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "leaderboard_event" */
export type Leaderboard_Event_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  event_name?: Maybe<Leaderboard_Event_Name_Enum>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Leaderboard_Event_Stddev_Fields = {
  __typename?: 'leaderboard_event_stddev_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Leaderboard_Event_Stddev_Pop_Fields = {
  __typename?: 'leaderboard_event_stddev_pop_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Leaderboard_Event_Stddev_Samp_Fields = {
  __typename?: 'leaderboard_event_stddev_samp_fields'
  points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "leaderboard_event" */
export type Leaderboard_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Leaderboard_Event_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Leaderboard_Event_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  emitted_at?: Maybe<Scalars['timestamptz']>
  event_hash?: Maybe<Scalars['String']>
  event_name?: Maybe<Leaderboard_Event_Name_Enum>
  id?: Maybe<Scalars['uuid']>
  identifier_id?: Maybe<Scalars['String']>
  identifier_type?: Maybe<Scalars['String']>
  points?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** Can be a user id in the database or an anonymous analytics uuid */
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Leaderboard_Event_Sum_Fields = {
  __typename?: 'leaderboard_event_sum_fields'
  points?: Maybe<Scalars['Int']>
}

/** update columns of table "leaderboard_event" */
export enum Leaderboard_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  EmittedAt = 'emitted_at',
  /** column name */
  EventHash = 'event_hash',
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  IdentifierId = 'identifier_id',
  /** column name */
  IdentifierType = 'identifier_type',
  /** column name */
  Points = 'points',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Leaderboard_Event_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Leaderboard_Event_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Leaderboard_Event_Set_Input>
  /** filter the rows which have to be updated */
  where: Leaderboard_Event_Bool_Exp
}

/** aggregate var_pop on columns */
export type Leaderboard_Event_Var_Pop_Fields = {
  __typename?: 'leaderboard_event_var_pop_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Leaderboard_Event_Var_Samp_Fields = {
  __typename?: 'leaderboard_event_var_samp_fields'
  points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Leaderboard_Event_Variance_Fields = {
  __typename?: 'leaderboard_event_variance_fields'
  points?: Maybe<Scalars['Float']>
}

/** Enum of different user link types */
export type Link_Type = {
  __typename?: 'link_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "link_type" */
export type Link_Type_Aggregate = {
  __typename?: 'link_type_aggregate'
  aggregate?: Maybe<Link_Type_Aggregate_Fields>
  nodes: Array<Link_Type>
}

/** aggregate fields of "link_type" */
export type Link_Type_Aggregate_Fields = {
  __typename?: 'link_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Link_Type_Max_Fields>
  min?: Maybe<Link_Type_Min_Fields>
}

/** aggregate fields of "link_type" */
export type Link_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Link_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "link_type". All fields are combined with a logical 'AND'. */
export type Link_Type_Bool_Exp = {
  _and?: Maybe<Array<Link_Type_Bool_Exp>>
  _not?: Maybe<Link_Type_Bool_Exp>
  _or?: Maybe<Array<Link_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "link_type" */
export enum Link_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  LinkTypePkey = 'link_type_pkey',
}

export enum Link_Type_Enum {
  /** Instagram */
  Instagram = 'instagram',
  /** Other */
  Other = 'other',
  /** TikTok */
  Tiktok = 'tiktok',
  /** Twitter */
  Twitter = 'twitter',
  /** YouTube */
  Youtube = 'youtube',
}

/** Boolean expression to compare columns of type "link_type_enum". All fields are combined with logical 'AND'. */
export type Link_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Link_Type_Enum>
  _in?: Maybe<Array<Link_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Link_Type_Enum>
  _nin?: Maybe<Array<Link_Type_Enum>>
}

/** input type for inserting data into table "link_type" */
export type Link_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Link_Type_Max_Fields = {
  __typename?: 'link_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Link_Type_Min_Fields = {
  __typename?: 'link_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "link_type" */
export type Link_Type_Mutation_Response = {
  __typename?: 'link_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Link_Type>
}

/** input type for inserting object relation for remote table "link_type" */
export type Link_Type_Obj_Rel_Insert_Input = {
  data: Link_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Link_Type_On_Conflict>
}

/** on_conflict condition type for table "link_type" */
export type Link_Type_On_Conflict = {
  constraint: Link_Type_Constraint
  update_columns?: Array<Link_Type_Update_Column>
  where?: Maybe<Link_Type_Bool_Exp>
}

/** Ordering options when selecting data from "link_type". */
export type Link_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: link_type */
export type Link_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "link_type" */
export enum Link_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "link_type" */
export type Link_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "link_type" */
export type Link_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Link_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Link_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "link_type" */
export enum Link_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Link_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Link_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Link_Type_Bool_Exp
}

/** A polymorphic join table to associate media to different objects in the database */
export type Media_Enabled_Item = {
  __typename?: 'media_enabled_item'
  /** An array relationship */
  associated_media: Array<Media_Items>
  /** An aggregate relationship */
  associated_media_aggregate: Media_Items_Aggregate
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
  /** An object relationship */
  media_item_type: Media_Item_Type
}

/** A polymorphic join table to associate media to different objects in the database */
export type Media_Enabled_ItemAssociated_MediaArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** A polymorphic join table to associate media to different objects in the database */
export type Media_Enabled_ItemAssociated_Media_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** aggregated selection of "media_enabled_item" */
export type Media_Enabled_Item_Aggregate = {
  __typename?: 'media_enabled_item_aggregate'
  aggregate?: Maybe<Media_Enabled_Item_Aggregate_Fields>
  nodes: Array<Media_Enabled_Item>
}

/** aggregate fields of "media_enabled_item" */
export type Media_Enabled_Item_Aggregate_Fields = {
  __typename?: 'media_enabled_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Enabled_Item_Max_Fields>
  min?: Maybe<Media_Enabled_Item_Min_Fields>
}

/** aggregate fields of "media_enabled_item" */
export type Media_Enabled_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_enabled_item". All fields are combined with a logical 'AND'. */
export type Media_Enabled_Item_Bool_Exp = {
  _and?: Maybe<Array<Media_Enabled_Item_Bool_Exp>>
  _not?: Maybe<Media_Enabled_Item_Bool_Exp>
  _or?: Maybe<Array<Media_Enabled_Item_Bool_Exp>>
  associated_media?: Maybe<Media_Items_Bool_Exp>
  associated_media_aggregate?: Maybe<Media_Items_Aggregate_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Media_Item_Type_Enum_Comparison_Exp>
  media_item_type?: Maybe<Media_Item_Type_Bool_Exp>
}

/** unique or primary key constraints on table "media_enabled_item" */
export enum Media_Enabled_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  MediaEnabledItemPkey = 'media_enabled_item_pkey',
}

/** input type for inserting data into table "media_enabled_item" */
export type Media_Enabled_Item_Insert_Input = {
  associated_media?: Maybe<Media_Items_Arr_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Media_Item_Type_Enum>
  media_item_type?: Maybe<Media_Item_Type_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Media_Enabled_Item_Max_Fields = {
  __typename?: 'media_enabled_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Media_Enabled_Item_Min_Fields = {
  __typename?: 'media_enabled_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "media_enabled_item" */
export type Media_Enabled_Item_Mutation_Response = {
  __typename?: 'media_enabled_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Enabled_Item>
}

/** input type for inserting object relation for remote table "media_enabled_item" */
export type Media_Enabled_Item_Obj_Rel_Insert_Input = {
  data: Media_Enabled_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Enabled_Item_On_Conflict>
}

/** on_conflict condition type for table "media_enabled_item" */
export type Media_Enabled_Item_On_Conflict = {
  constraint: Media_Enabled_Item_Constraint
  update_columns?: Array<Media_Enabled_Item_Update_Column>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

/** Ordering options when selecting data from "media_enabled_item". */
export type Media_Enabled_Item_Order_By = {
  associated_media_aggregate?: Maybe<Media_Items_Aggregate_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  media_item_type?: Maybe<Media_Item_Type_Order_By>
}

/** primary key columns input for table: media_enabled_item */
export type Media_Enabled_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

/** select columns of table "media_enabled_item" */
export enum Media_Enabled_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "media_enabled_item" */
export type Media_Enabled_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Media_Item_Type_Enum>
}

/** Streaming cursor of the table "media_enabled_item" */
export type Media_Enabled_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Enabled_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Enabled_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Media_Item_Type_Enum>
}

/** update columns of table "media_enabled_item" */
export enum Media_Enabled_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Media_Enabled_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Enabled_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Enabled_Item_Bool_Exp
}

/** columns and relationships of "media_item" */
export type Media_Item = {
  __typename?: 'media_item'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  media_item_type: Media_Type
  /** An array relationship */
  media_items: Array<Media_Items>
  /** An aggregate relationship */
  media_items_aggregate: Media_Items_Aggregate
  media_type: Media_Type_Enum
  media_url: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "media_item" */
export type Media_ItemMedia_ItemsArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** columns and relationships of "media_item" */
export type Media_ItemMedia_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** aggregated selection of "media_item" */
export type Media_Item_Aggregate = {
  __typename?: 'media_item_aggregate'
  aggregate?: Maybe<Media_Item_Aggregate_Fields>
  nodes: Array<Media_Item>
}

/** aggregate fields of "media_item" */
export type Media_Item_Aggregate_Fields = {
  __typename?: 'media_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Item_Max_Fields>
  min?: Maybe<Media_Item_Min_Fields>
}

/** aggregate fields of "media_item" */
export type Media_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_item". All fields are combined with a logical 'AND'. */
export type Media_Item_Bool_Exp = {
  _and?: Maybe<Array<Media_Item_Bool_Exp>>
  _not?: Maybe<Media_Item_Bool_Exp>
  _or?: Maybe<Array<Media_Item_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  media_item_type?: Maybe<Media_Type_Bool_Exp>
  media_items?: Maybe<Media_Items_Bool_Exp>
  media_items_aggregate?: Maybe<Media_Items_Aggregate_Bool_Exp>
  media_type?: Maybe<Media_Type_Enum_Comparison_Exp>
  media_url?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "media_item" */
export enum Media_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  MediaItemPkey = 'media_item_pkey',
}

/** input type for inserting data into table "media_item" */
export type Media_Item_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_item_type?: Maybe<Media_Type_Obj_Rel_Insert_Input>
  media_items?: Maybe<Media_Items_Arr_Rel_Insert_Input>
  media_type?: Maybe<Media_Type_Enum>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Media_Item_Max_Fields = {
  __typename?: 'media_item_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Media_Item_Min_Fields = {
  __typename?: 'media_item_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "media_item" */
export type Media_Item_Mutation_Response = {
  __typename?: 'media_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Item>
}

/** input type for inserting object relation for remote table "media_item" */
export type Media_Item_Obj_Rel_Insert_Input = {
  data: Media_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Item_On_Conflict>
}

/** on_conflict condition type for table "media_item" */
export type Media_Item_On_Conflict = {
  constraint: Media_Item_Constraint
  update_columns?: Array<Media_Item_Update_Column>
  where?: Maybe<Media_Item_Bool_Exp>
}

/** Ordering options when selecting data from "media_item". */
export type Media_Item_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  media_item_type?: Maybe<Media_Type_Order_By>
  media_items_aggregate?: Maybe<Media_Items_Aggregate_Order_By>
  media_type?: Maybe<Order_By>
  media_url?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: media_item */
export type Media_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "media_item" */
export enum Media_Item_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediaType = 'media_type',
  /** column name */
  MediaUrl = 'media_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "media_item" */
export type Media_Item_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_type?: Maybe<Media_Type_Enum>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "media_item" */
export type Media_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Item_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  media_type?: Maybe<Media_Type_Enum>
  media_url?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "media_item_type" */
export type Media_Item_Type = {
  __typename?: 'media_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "media_item_type" */
export type Media_Item_Type_Aggregate = {
  __typename?: 'media_item_type_aggregate'
  aggregate?: Maybe<Media_Item_Type_Aggregate_Fields>
  nodes: Array<Media_Item_Type>
}

/** aggregate fields of "media_item_type" */
export type Media_Item_Type_Aggregate_Fields = {
  __typename?: 'media_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Item_Type_Max_Fields>
  min?: Maybe<Media_Item_Type_Min_Fields>
}

/** aggregate fields of "media_item_type" */
export type Media_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_item_type". All fields are combined with a logical 'AND'. */
export type Media_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Media_Item_Type_Bool_Exp>>
  _not?: Maybe<Media_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Media_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "media_item_type" */
export enum Media_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  MediaItemTypePkey = 'media_item_type_pkey',
}

export enum Media_Item_Type_Enum {
  Collection = 'collection',
}

/** Boolean expression to compare columns of type "media_item_type_enum". All fields are combined with logical 'AND'. */
export type Media_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Media_Item_Type_Enum>
  _in?: Maybe<Array<Media_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Media_Item_Type_Enum>
  _nin?: Maybe<Array<Media_Item_Type_Enum>>
}

/** input type for inserting data into table "media_item_type" */
export type Media_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Media_Item_Type_Max_Fields = {
  __typename?: 'media_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Media_Item_Type_Min_Fields = {
  __typename?: 'media_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "media_item_type" */
export type Media_Item_Type_Mutation_Response = {
  __typename?: 'media_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Item_Type>
}

/** input type for inserting object relation for remote table "media_item_type" */
export type Media_Item_Type_Obj_Rel_Insert_Input = {
  data: Media_Item_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Item_Type_On_Conflict>
}

/** on_conflict condition type for table "media_item_type" */
export type Media_Item_Type_On_Conflict = {
  constraint: Media_Item_Type_Constraint
  update_columns?: Array<Media_Item_Type_Update_Column>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "media_item_type". */
export type Media_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: media_item_type */
export type Media_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "media_item_type" */
export enum Media_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "media_item_type" */
export type Media_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "media_item_type" */
export type Media_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "media_item_type" */
export enum Media_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Media_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Item_Type_Bool_Exp
}

/** update columns of table "media_item" */
export enum Media_Item_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MediaType = 'media_type',
  /** column name */
  MediaUrl = 'media_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Media_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Item_Bool_Exp
}

/** Table to hold media item polymorphic relationships */
export type Media_Items = {
  __typename?: 'media_items'
  /** An object relationship */
  media: Media_Item
  /** An object relationship */
  media_enabled_item?: Maybe<Media_Enabled_Item>
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

/** aggregated selection of "media_items" */
export type Media_Items_Aggregate = {
  __typename?: 'media_items_aggregate'
  aggregate?: Maybe<Media_Items_Aggregate_Fields>
  nodes: Array<Media_Items>
}

export type Media_Items_Aggregate_Bool_Exp = {
  count?: Maybe<Media_Items_Aggregate_Bool_Exp_Count>
}

export type Media_Items_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Media_Items_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Media_Items_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "media_items" */
export type Media_Items_Aggregate_Fields = {
  __typename?: 'media_items_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Items_Max_Fields>
  min?: Maybe<Media_Items_Min_Fields>
}

/** aggregate fields of "media_items" */
export type Media_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Items_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "media_items" */
export type Media_Items_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Media_Items_Max_Order_By>
  min?: Maybe<Media_Items_Min_Order_By>
}

/** input type for inserting array relation for remote table "media_items" */
export type Media_Items_Arr_Rel_Insert_Input = {
  data: Array<Media_Items_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Media_Items_On_Conflict>
}

/** Boolean expression to filter rows from the table "media_items". All fields are combined with a logical 'AND'. */
export type Media_Items_Bool_Exp = {
  _and?: Maybe<Array<Media_Items_Bool_Exp>>
  _not?: Maybe<Media_Items_Bool_Exp>
  _or?: Maybe<Array<Media_Items_Bool_Exp>>
  media?: Maybe<Media_Item_Bool_Exp>
  media_enabled_item?: Maybe<Media_Enabled_Item_Bool_Exp>
  media_enabled_item_id?: Maybe<Uuid_Comparison_Exp>
  media_enabled_item_type?: Maybe<String_Comparison_Exp>
  media_id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "media_items" */
export enum Media_Items_Constraint {
  /** unique or primary key constraint on columns "media_enabled_item_id", "media_enabled_item_type", "media_id" */
  MediaItemsPkey = 'media_items_pkey',
}

/** input type for inserting data into table "media_items" */
export type Media_Items_Insert_Input = {
  media?: Maybe<Media_Item_Obj_Rel_Insert_Input>
  media_enabled_item?: Maybe<Media_Enabled_Item_Obj_Rel_Insert_Input>
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type Media_Items_Max_Fields = {
  __typename?: 'media_items_max_fields'
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "media_items" */
export type Media_Items_Max_Order_By = {
  media_enabled_item_id?: Maybe<Order_By>
  media_enabled_item_type?: Maybe<Order_By>
  media_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Media_Items_Min_Fields = {
  __typename?: 'media_items_min_fields'
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "media_items" */
export type Media_Items_Min_Order_By = {
  media_enabled_item_id?: Maybe<Order_By>
  media_enabled_item_type?: Maybe<Order_By>
  media_id?: Maybe<Order_By>
}

/** response of any mutation on the table "media_items" */
export type Media_Items_Mutation_Response = {
  __typename?: 'media_items_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Items>
}

/** on_conflict condition type for table "media_items" */
export type Media_Items_On_Conflict = {
  constraint: Media_Items_Constraint
  update_columns?: Array<Media_Items_Update_Column>
  where?: Maybe<Media_Items_Bool_Exp>
}

/** Ordering options when selecting data from "media_items". */
export type Media_Items_Order_By = {
  media?: Maybe<Media_Item_Order_By>
  media_enabled_item?: Maybe<Media_Enabled_Item_Order_By>
  media_enabled_item_id?: Maybe<Order_By>
  media_enabled_item_type?: Maybe<Order_By>
  media_id?: Maybe<Order_By>
}

/** primary key columns input for table: media_items */
export type Media_Items_Pk_Columns_Input = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

/** select columns of table "media_items" */
export enum Media_Items_Select_Column {
  /** column name */
  MediaEnabledItemId = 'media_enabled_item_id',
  /** column name */
  MediaEnabledItemType = 'media_enabled_item_type',
  /** column name */
  MediaId = 'media_id',
}

/** input type for updating data in table "media_items" */
export type Media_Items_Set_Input = {
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** Streaming cursor of the table "media_items" */
export type Media_Items_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Items_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Items_Stream_Cursor_Value_Input = {
  media_enabled_item_id?: Maybe<Scalars['uuid']>
  media_enabled_item_type?: Maybe<Scalars['String']>
  media_id?: Maybe<Scalars['uuid']>
}

/** update columns of table "media_items" */
export enum Media_Items_Update_Column {
  /** column name */
  MediaEnabledItemId = 'media_enabled_item_id',
  /** column name */
  MediaEnabledItemType = 'media_enabled_item_type',
  /** column name */
  MediaId = 'media_id',
}

export type Media_Items_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Items_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Items_Bool_Exp
}

/** columns and relationships of "media_type" */
export type Media_Type = {
  __typename?: 'media_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "media_type" */
export type Media_Type_Aggregate = {
  __typename?: 'media_type_aggregate'
  aggregate?: Maybe<Media_Type_Aggregate_Fields>
  nodes: Array<Media_Type>
}

/** aggregate fields of "media_type" */
export type Media_Type_Aggregate_Fields = {
  __typename?: 'media_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Media_Type_Max_Fields>
  min?: Maybe<Media_Type_Min_Fields>
}

/** aggregate fields of "media_type" */
export type Media_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Media_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "media_type". All fields are combined with a logical 'AND'. */
export type Media_Type_Bool_Exp = {
  _and?: Maybe<Array<Media_Type_Bool_Exp>>
  _not?: Maybe<Media_Type_Bool_Exp>
  _or?: Maybe<Array<Media_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "media_type" */
export enum Media_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  MediaTypePkey = 'media_type_pkey',
}

export enum Media_Type_Enum {
  /** Instagram Media */
  Instagram = 'instagram',
  /** TikTok Video */
  Tiktok = 'tiktok',
  /** Twitch Video */
  Twitch = 'twitch',
  /** YouTube Video */
  Youtube = 'youtube',
  /** YouTube Short media type */
  YoutubeShort = 'youtube_short',
}

/** Boolean expression to compare columns of type "media_type_enum". All fields are combined with logical 'AND'. */
export type Media_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Media_Type_Enum>
  _in?: Maybe<Array<Media_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Media_Type_Enum>
  _nin?: Maybe<Array<Media_Type_Enum>>
}

/** input type for inserting data into table "media_type" */
export type Media_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Media_Type_Max_Fields = {
  __typename?: 'media_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Media_Type_Min_Fields = {
  __typename?: 'media_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "media_type" */
export type Media_Type_Mutation_Response = {
  __typename?: 'media_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Media_Type>
}

/** input type for inserting object relation for remote table "media_type" */
export type Media_Type_Obj_Rel_Insert_Input = {
  data: Media_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Media_Type_On_Conflict>
}

/** on_conflict condition type for table "media_type" */
export type Media_Type_On_Conflict = {
  constraint: Media_Type_Constraint
  update_columns?: Array<Media_Type_Update_Column>
  where?: Maybe<Media_Type_Bool_Exp>
}

/** Ordering options when selecting data from "media_type". */
export type Media_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: media_type */
export type Media_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "media_type" */
export enum Media_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "media_type" */
export type Media_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "media_type" */
export type Media_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Media_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Media_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "media_type" */
export enum Media_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Media_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Media_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Media_Type_Bool_Exp
}

export type Mention_Search_Users_Args = {
  page_num?: Maybe<Scalars['Int']>
  page_size?: Maybe<Scalars['Int']>
  search_string?: Maybe<Scalars['String']>
}

/** Table used to display and adjust content shown on the home tab of the mobile app */
export type Mobile_App_Home = {
  __typename?: 'mobile_app_home'
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type: Scalars['String']
  created_at: Scalars['timestamptz']
  deep_link?: Maybe<Scalars['String']>
  id: Scalars['Int']
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "mobile_app_home" */
export type Mobile_App_Home_Aggregate = {
  __typename?: 'mobile_app_home_aggregate'
  aggregate?: Maybe<Mobile_App_Home_Aggregate_Fields>
  nodes: Array<Mobile_App_Home>
}

/** aggregate fields of "mobile_app_home" */
export type Mobile_App_Home_Aggregate_Fields = {
  __typename?: 'mobile_app_home_aggregate_fields'
  avg?: Maybe<Mobile_App_Home_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Mobile_App_Home_Max_Fields>
  min?: Maybe<Mobile_App_Home_Min_Fields>
  stddev?: Maybe<Mobile_App_Home_Stddev_Fields>
  stddev_pop?: Maybe<Mobile_App_Home_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Mobile_App_Home_Stddev_Samp_Fields>
  sum?: Maybe<Mobile_App_Home_Sum_Fields>
  var_pop?: Maybe<Mobile_App_Home_Var_Pop_Fields>
  var_samp?: Maybe<Mobile_App_Home_Var_Samp_Fields>
  variance?: Maybe<Mobile_App_Home_Variance_Fields>
}

/** aggregate fields of "mobile_app_home" */
export type Mobile_App_Home_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_App_Home_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Mobile_App_Home_Avg_Fields = {
  __typename?: 'mobile_app_home_avg_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "mobile_app_home". All fields are combined with a logical 'AND'. */
export type Mobile_App_Home_Bool_Exp = {
  _and?: Maybe<Array<Mobile_App_Home_Bool_Exp>>
  _not?: Maybe<Mobile_App_Home_Bool_Exp>
  _or?: Maybe<Array<Mobile_App_Home_Bool_Exp>>
  block_enabled?: Maybe<Boolean_Comparison_Exp>
  block_image?: Maybe<String_Comparison_Exp>
  block_link?: Maybe<String_Comparison_Exp>
  block_type?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deep_link?: Maybe<String_Comparison_Exp>
  id?: Maybe<Int_Comparison_Exp>
  link_type?: Maybe<String_Comparison_Exp>
  link_type_value?: Maybe<String_Comparison_Exp>
  note?: Maybe<String_Comparison_Exp>
  order?: Maybe<Int_Comparison_Exp>
  parent?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "mobile_app_home" */
export enum Mobile_App_Home_Constraint {
  /** unique or primary key constraint on columns "id" */
  MobileAppHomePkey = 'mobile_app_home_pkey',
}

/** input type for incrementing numeric columns in table "mobile_app_home" */
export type Mobile_App_Home_Inc_Input = {
  id?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "mobile_app_home" */
export type Mobile_App_Home_Insert_Input = {
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Mobile_App_Home_Max_Fields = {
  __typename?: 'mobile_app_home_max_fields'
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Mobile_App_Home_Min_Fields = {
  __typename?: 'mobile_app_home_min_fields'
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "mobile_app_home" */
export type Mobile_App_Home_Mutation_Response = {
  __typename?: 'mobile_app_home_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_App_Home>
}

/** on_conflict condition type for table "mobile_app_home" */
export type Mobile_App_Home_On_Conflict = {
  constraint: Mobile_App_Home_Constraint
  update_columns?: Array<Mobile_App_Home_Update_Column>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_app_home". */
export type Mobile_App_Home_Order_By = {
  block_enabled?: Maybe<Order_By>
  block_image?: Maybe<Order_By>
  block_link?: Maybe<Order_By>
  block_type?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deep_link?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link_type?: Maybe<Order_By>
  link_type_value?: Maybe<Order_By>
  note?: Maybe<Order_By>
  order?: Maybe<Order_By>
  parent?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: mobile_app_home */
export type Mobile_App_Home_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** select columns of table "mobile_app_home" */
export enum Mobile_App_Home_Select_Column {
  /** column name */
  BlockEnabled = 'block_enabled',
  /** column name */
  BlockImage = 'block_image',
  /** column name */
  BlockLink = 'block_link',
  /** column name */
  BlockType = 'block_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeepLink = 'deep_link',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  LinkTypeValue = 'link_type_value',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  Parent = 'parent',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "mobile_app_home" */
export type Mobile_App_Home_Set_Input = {
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Mobile_App_Home_Stddev_Fields = {
  __typename?: 'mobile_app_home_stddev_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Mobile_App_Home_Stddev_Pop_Fields = {
  __typename?: 'mobile_app_home_stddev_pop_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Mobile_App_Home_Stddev_Samp_Fields = {
  __typename?: 'mobile_app_home_stddev_samp_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "mobile_app_home" */
export type Mobile_App_Home_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_App_Home_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_App_Home_Stream_Cursor_Value_Input = {
  block_enabled?: Maybe<Scalars['Boolean']>
  block_image?: Maybe<Scalars['String']>
  block_link?: Maybe<Scalars['String']>
  block_type?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deep_link?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  link_type?: Maybe<Scalars['String']>
  link_type_value?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  parent?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Mobile_App_Home_Sum_Fields = {
  __typename?: 'mobile_app_home_sum_fields'
  id?: Maybe<Scalars['Int']>
  order?: Maybe<Scalars['Int']>
}

/** update columns of table "mobile_app_home" */
export enum Mobile_App_Home_Update_Column {
  /** column name */
  BlockEnabled = 'block_enabled',
  /** column name */
  BlockImage = 'block_image',
  /** column name */
  BlockLink = 'block_link',
  /** column name */
  BlockType = 'block_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeepLink = 'deep_link',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  LinkTypeValue = 'link_type_value',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  Parent = 'parent',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Mobile_App_Home_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mobile_App_Home_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_App_Home_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_App_Home_Bool_Exp
}

/** aggregate var_pop on columns */
export type Mobile_App_Home_Var_Pop_Fields = {
  __typename?: 'mobile_app_home_var_pop_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Mobile_App_Home_Var_Samp_Fields = {
  __typename?: 'mobile_app_home_var_samp_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Mobile_App_Home_Variance_Fields = {
  __typename?: 'mobile_app_home_variance_fields'
  id?: Maybe<Scalars['Float']>
  order?: Maybe<Scalars['Float']>
}

/** columns and relationships of "mobile_home" */
export type Mobile_Home = {
  __typename?: 'mobile_home'
  active: Scalars['Boolean']
  asset_url: Scalars['String']
  created_at: Scalars['timestamptz']
  enabled: Scalars['Boolean']
  id: Scalars['uuid']
  link_url?: Maybe<Scalars['String']>
  order: Scalars['Int']
  type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "mobile_home" */
export type Mobile_Home_Aggregate = {
  __typename?: 'mobile_home_aggregate'
  aggregate?: Maybe<Mobile_Home_Aggregate_Fields>
  nodes: Array<Mobile_Home>
}

/** aggregate fields of "mobile_home" */
export type Mobile_Home_Aggregate_Fields = {
  __typename?: 'mobile_home_aggregate_fields'
  avg?: Maybe<Mobile_Home_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Mobile_Home_Max_Fields>
  min?: Maybe<Mobile_Home_Min_Fields>
  stddev?: Maybe<Mobile_Home_Stddev_Fields>
  stddev_pop?: Maybe<Mobile_Home_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Mobile_Home_Stddev_Samp_Fields>
  sum?: Maybe<Mobile_Home_Sum_Fields>
  var_pop?: Maybe<Mobile_Home_Var_Pop_Fields>
  var_samp?: Maybe<Mobile_Home_Var_Samp_Fields>
  variance?: Maybe<Mobile_Home_Variance_Fields>
}

/** aggregate fields of "mobile_home" */
export type Mobile_Home_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_Home_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Mobile_Home_Avg_Fields = {
  __typename?: 'mobile_home_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "mobile_home". All fields are combined with a logical 'AND'. */
export type Mobile_Home_Bool_Exp = {
  _and?: Maybe<Array<Mobile_Home_Bool_Exp>>
  _not?: Maybe<Mobile_Home_Bool_Exp>
  _or?: Maybe<Array<Mobile_Home_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  asset_url?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  enabled?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  link_url?: Maybe<String_Comparison_Exp>
  order?: Maybe<Int_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "mobile_home" */
export enum Mobile_Home_Constraint {
  /** unique or primary key constraint on columns "asset_url", "link_url" */
  MobileHomeAssetUrlLinkUrlKey = 'mobile_home_asset_url_link_url_key',
  /** unique or primary key constraint on columns "asset_url", "type", "link_url" */
  MobileHomeAssetUrlLinkUrlTypeKey = 'mobile_home_asset_url_link_url_type_key',
  /** unique or primary key constraint on columns "id" */
  MobileHomePkey = 'mobile_home_pkey',
}

/** input type for incrementing numeric columns in table "mobile_home" */
export type Mobile_Home_Inc_Input = {
  order?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "mobile_home" */
export type Mobile_Home_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Mobile_Home_Max_Fields = {
  __typename?: 'mobile_home_max_fields'
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Mobile_Home_Min_Fields = {
  __typename?: 'mobile_home_min_fields'
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "mobile_home" */
export type Mobile_Home_Mutation_Response = {
  __typename?: 'mobile_home_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Home>
}

/** on_conflict condition type for table "mobile_home" */
export type Mobile_Home_On_Conflict = {
  constraint: Mobile_Home_Constraint
  update_columns?: Array<Mobile_Home_Update_Column>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_home". */
export type Mobile_Home_Order_By = {
  active?: Maybe<Order_By>
  asset_url?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  enabled?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link_url?: Maybe<Order_By>
  order?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: mobile_home */
export type Mobile_Home_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "mobile_home" */
export enum Mobile_Home_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  LinkUrl = 'link_url',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "mobile_home" */
export type Mobile_Home_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Mobile_Home_Stddev_Fields = {
  __typename?: 'mobile_home_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Mobile_Home_Stddev_Pop_Fields = {
  __typename?: 'mobile_home_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Mobile_Home_Stddev_Samp_Fields = {
  __typename?: 'mobile_home_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "mobile_home" */
export type Mobile_Home_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Home_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_Home_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  asset_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  enabled?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  link_url?: Maybe<Scalars['String']>
  order?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Mobile_Home_Sum_Fields = {
  __typename?: 'mobile_home_sum_fields'
  order?: Maybe<Scalars['Int']>
}

/** update columns of table "mobile_home" */
export enum Mobile_Home_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AssetUrl = 'asset_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Id = 'id',
  /** column name */
  LinkUrl = 'link_url',
  /** column name */
  Order = 'order',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Mobile_Home_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mobile_Home_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_Home_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_Home_Bool_Exp
}

/** aggregate var_pop on columns */
export type Mobile_Home_Var_Pop_Fields = {
  __typename?: 'mobile_home_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Mobile_Home_Var_Samp_Fields = {
  __typename?: 'mobile_home_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Mobile_Home_Variance_Fields = {
  __typename?: 'mobile_home_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** This is a table to handle progressing mobile push notifications through different states of its lifecycle */
export type Mobile_Push_Notification = {
  __typename?: 'mobile_push_notification'
  attempts: Scalars['Int']
  bulk: Scalars['Boolean']
  bulk_id?: Maybe<Scalars['Int']>
  created_at: Scalars['timestamptz']
  error_details?: Maybe<Scalars['jsonb']>
  id: Scalars['uuid']
  message_payload: Scalars['json']
  push_notification_token: Scalars['String']
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status: Mobile_Push_Notification_Status_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id: Scalars['String']
  /** An object relationship */
  user_push_notification_token?: Maybe<User_Push_Notification_Token>
}

/** This is a table to handle progressing mobile push notifications through different states of its lifecycle */
export type Mobile_Push_NotificationError_DetailsArgs = {
  path?: Maybe<Scalars['String']>
}

/** This is a table to handle progressing mobile push notifications through different states of its lifecycle */
export type Mobile_Push_NotificationMessage_PayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "mobile_push_notification" */
export type Mobile_Push_Notification_Aggregate = {
  __typename?: 'mobile_push_notification_aggregate'
  aggregate?: Maybe<Mobile_Push_Notification_Aggregate_Fields>
  nodes: Array<Mobile_Push_Notification>
}

/** aggregate fields of "mobile_push_notification" */
export type Mobile_Push_Notification_Aggregate_Fields = {
  __typename?: 'mobile_push_notification_aggregate_fields'
  avg?: Maybe<Mobile_Push_Notification_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Mobile_Push_Notification_Max_Fields>
  min?: Maybe<Mobile_Push_Notification_Min_Fields>
  stddev?: Maybe<Mobile_Push_Notification_Stddev_Fields>
  stddev_pop?: Maybe<Mobile_Push_Notification_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Mobile_Push_Notification_Stddev_Samp_Fields>
  sum?: Maybe<Mobile_Push_Notification_Sum_Fields>
  var_pop?: Maybe<Mobile_Push_Notification_Var_Pop_Fields>
  var_samp?: Maybe<Mobile_Push_Notification_Var_Samp_Fields>
  variance?: Maybe<Mobile_Push_Notification_Variance_Fields>
}

/** aggregate fields of "mobile_push_notification" */
export type Mobile_Push_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Push_Notification_Append_Input = {
  error_details?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Mobile_Push_Notification_Avg_Fields = {
  __typename?: 'mobile_push_notification_avg_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "mobile_push_notification". All fields are combined with a logical 'AND'. */
export type Mobile_Push_Notification_Bool_Exp = {
  _and?: Maybe<Array<Mobile_Push_Notification_Bool_Exp>>
  _not?: Maybe<Mobile_Push_Notification_Bool_Exp>
  _or?: Maybe<Array<Mobile_Push_Notification_Bool_Exp>>
  attempts?: Maybe<Int_Comparison_Exp>
  bulk?: Maybe<Boolean_Comparison_Exp>
  bulk_id?: Maybe<Int_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  error_details?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  message_payload?: Maybe<Json_Comparison_Exp>
  push_notification_token?: Maybe<String_Comparison_Exp>
  receipt_id?: Maybe<String_Comparison_Exp>
  scheduled_at?: Maybe<Timestamptz_Comparison_Exp>
  sent_at?: Maybe<Timestamptz_Comparison_Exp>
  status?: Maybe<Mobile_Push_Notification_Status_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_push_notification_token?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** unique or primary key constraints on table "mobile_push_notification" */
export enum Mobile_Push_Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  MobilePushNotificationPkey = 'mobile_push_notification_pkey',
  /** unique or primary key constraint on columns "receipt_id" */
  MobilePushNotificationReceiptIdKey = 'mobile_push_notification_receipt_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mobile_Push_Notification_Delete_At_Path_Input = {
  error_details?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mobile_Push_Notification_Delete_Elem_Input = {
  error_details?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mobile_Push_Notification_Delete_Key_Input = {
  error_details?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "mobile_push_notification" */
export type Mobile_Push_Notification_Inc_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "mobile_push_notification" */
export type Mobile_Push_Notification_Insert_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk?: Maybe<Scalars['Boolean']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  error_details?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  message_payload?: Maybe<Scalars['json']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Mobile_Push_Notification_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_push_notification_token?: Maybe<User_Push_Notification_Token_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Mobile_Push_Notification_Max_Fields = {
  __typename?: 'mobile_push_notification_max_fields'
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Mobile_Push_Notification_Min_Fields = {
  __typename?: 'mobile_push_notification_min_fields'
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "mobile_push_notification" */
export type Mobile_Push_Notification_Mutation_Response = {
  __typename?: 'mobile_push_notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Push_Notification>
}

/** on_conflict condition type for table "mobile_push_notification" */
export type Mobile_Push_Notification_On_Conflict = {
  constraint: Mobile_Push_Notification_Constraint
  update_columns?: Array<Mobile_Push_Notification_Update_Column>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_push_notification". */
export type Mobile_Push_Notification_Order_By = {
  attempts?: Maybe<Order_By>
  bulk?: Maybe<Order_By>
  bulk_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  error_details?: Maybe<Order_By>
  id?: Maybe<Order_By>
  message_payload?: Maybe<Order_By>
  push_notification_token?: Maybe<Order_By>
  receipt_id?: Maybe<Order_By>
  scheduled_at?: Maybe<Order_By>
  sent_at?: Maybe<Order_By>
  status?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_push_notification_token?: Maybe<User_Push_Notification_Token_Order_By>
}

/** primary key columns input for table: mobile_push_notification */
export type Mobile_Push_Notification_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mobile_Push_Notification_Prepend_Input = {
  error_details?: Maybe<Scalars['jsonb']>
}

/** select columns of table "mobile_push_notification" */
export enum Mobile_Push_Notification_Select_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  Bulk = 'bulk',
  /** column name */
  BulkId = 'bulk_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorDetails = 'error_details',
  /** column name */
  Id = 'id',
  /** column name */
  MessagePayload = 'message_payload',
  /** column name */
  PushNotificationToken = 'push_notification_token',
  /** column name */
  ReceiptId = 'receipt_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "mobile_push_notification" */
export type Mobile_Push_Notification_Set_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk?: Maybe<Scalars['Boolean']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  error_details?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  message_payload?: Maybe<Scalars['json']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Mobile_Push_Notification_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** An enum table to describe the different states that a mobile push notification can be in */
export type Mobile_Push_Notification_Status = {
  __typename?: 'mobile_push_notification_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Aggregate = {
  __typename?: 'mobile_push_notification_status_aggregate'
  aggregate?: Maybe<Mobile_Push_Notification_Status_Aggregate_Fields>
  nodes: Array<Mobile_Push_Notification_Status>
}

/** aggregate fields of "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Aggregate_Fields = {
  __typename?: 'mobile_push_notification_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Mobile_Push_Notification_Status_Max_Fields>
  min?: Maybe<Mobile_Push_Notification_Status_Min_Fields>
}

/** aggregate fields of "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "mobile_push_notification_status". All fields are combined with a logical 'AND'. */
export type Mobile_Push_Notification_Status_Bool_Exp = {
  _and?: Maybe<Array<Mobile_Push_Notification_Status_Bool_Exp>>
  _not?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
  _or?: Maybe<Array<Mobile_Push_Notification_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "mobile_push_notification_status" */
export enum Mobile_Push_Notification_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  MobilePushNotificationStatusPkey = 'mobile_push_notification_status_pkey',
}

export enum Mobile_Push_Notification_Status_Enum {
  Expired = 'expired',
  Failed = 'failed',
  Pending = 'pending',
  Scheduled = 'scheduled',
  Sending = 'sending',
  Sent = 'sent',
  Succeeded = 'succeeded',
  WontSend = 'wont_send',
}

/** Boolean expression to compare columns of type "mobile_push_notification_status_enum". All fields are combined with logical 'AND'. */
export type Mobile_Push_Notification_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Mobile_Push_Notification_Status_Enum>
  _in?: Maybe<Array<Mobile_Push_Notification_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Mobile_Push_Notification_Status_Enum>
  _nin?: Maybe<Array<Mobile_Push_Notification_Status_Enum>>
}

/** input type for inserting data into table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Mobile_Push_Notification_Status_Max_Fields = {
  __typename?: 'mobile_push_notification_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Mobile_Push_Notification_Status_Min_Fields = {
  __typename?: 'mobile_push_notification_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Mutation_Response = {
  __typename?: 'mobile_push_notification_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mobile_Push_Notification_Status>
}

/** on_conflict condition type for table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_On_Conflict = {
  constraint: Mobile_Push_Notification_Status_Constraint
  update_columns?: Array<Mobile_Push_Notification_Status_Update_Column>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

/** Ordering options when selecting data from "mobile_push_notification_status". */
export type Mobile_Push_Notification_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: mobile_push_notification_status */
export type Mobile_Push_Notification_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "mobile_push_notification_status" */
export enum Mobile_Push_Notification_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "mobile_push_notification_status" */
export type Mobile_Push_Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Push_Notification_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_Push_Notification_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "mobile_push_notification_status" */
export enum Mobile_Push_Notification_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Mobile_Push_Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_Push_Notification_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_Push_Notification_Status_Bool_Exp
}

/** aggregate stddev on columns */
export type Mobile_Push_Notification_Stddev_Fields = {
  __typename?: 'mobile_push_notification_stddev_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Mobile_Push_Notification_Stddev_Pop_Fields = {
  __typename?: 'mobile_push_notification_stddev_pop_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Mobile_Push_Notification_Stddev_Samp_Fields = {
  __typename?: 'mobile_push_notification_stddev_samp_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "mobile_push_notification" */
export type Mobile_Push_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_Push_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mobile_Push_Notification_Stream_Cursor_Value_Input = {
  attempts?: Maybe<Scalars['Int']>
  bulk?: Maybe<Scalars['Boolean']>
  bulk_id?: Maybe<Scalars['Int']>
  created_at?: Maybe<Scalars['timestamptz']>
  error_details?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  message_payload?: Maybe<Scalars['json']>
  push_notification_token?: Maybe<Scalars['String']>
  receipt_id?: Maybe<Scalars['String']>
  scheduled_at?: Maybe<Scalars['timestamptz']>
  sent_at?: Maybe<Scalars['timestamptz']>
  status?: Maybe<Mobile_Push_Notification_Status_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Mobile_Push_Notification_Sum_Fields = {
  __typename?: 'mobile_push_notification_sum_fields'
  attempts?: Maybe<Scalars['Int']>
  bulk_id?: Maybe<Scalars['Int']>
}

/** update columns of table "mobile_push_notification" */
export enum Mobile_Push_Notification_Update_Column {
  /** column name */
  Attempts = 'attempts',
  /** column name */
  Bulk = 'bulk',
  /** column name */
  BulkId = 'bulk_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorDetails = 'error_details',
  /** column name */
  Id = 'id',
  /** column name */
  MessagePayload = 'message_payload',
  /** column name */
  PushNotificationToken = 'push_notification_token',
  /** column name */
  ReceiptId = 'receipt_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Mobile_Push_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Mobile_Push_Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Mobile_Push_Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Mobile_Push_Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Mobile_Push_Notification_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Mobile_Push_Notification_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Mobile_Push_Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Mobile_Push_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: Mobile_Push_Notification_Bool_Exp
}

/** aggregate var_pop on columns */
export type Mobile_Push_Notification_Var_Pop_Fields = {
  __typename?: 'mobile_push_notification_var_pop_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Mobile_Push_Notification_Var_Samp_Fields = {
  __typename?: 'mobile_push_notification_var_samp_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Mobile_Push_Notification_Variance_Fields = {
  __typename?: 'mobile_push_notification_variance_fields'
  attempts?: Maybe<Scalars['Float']>
  bulk_id?: Maybe<Scalars['Float']>
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** delete data from the table: "account_deletion_reason" */
  delete_account_deletion_reason?: Maybe<Account_Deletion_Reason_Mutation_Response>
  /** delete single row from the table: "account_deletion_reason" */
  delete_account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** delete data from the table: "affiliate" */
  delete_affiliate?: Maybe<Affiliate_Mutation_Response>
  /** delete data from the table: "affiliate_advertiser_id" */
  delete_affiliate_advertiser_id?: Maybe<Affiliate_Advertiser_Id_Mutation_Response>
  /** delete single row from the table: "affiliate_advertiser_id" */
  delete_affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** delete single row from the table: "affiliate" */
  delete_affiliate_by_pk?: Maybe<Affiliate>
  /** delete data from the table: "affiliate_meta" */
  delete_affiliate_meta?: Maybe<Affiliate_Meta_Mutation_Response>
  /** delete single row from the table: "affiliate_meta" */
  delete_affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** delete data from the table: "affiliate_transaction" */
  delete_affiliate_transaction?: Maybe<Affiliate_Transaction_Mutation_Response>
  /** delete single row from the table: "affiliate_transaction" */
  delete_affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** delete data from the table: "billing_mode" */
  delete_billing_mode?: Maybe<Billing_Mode_Mutation_Response>
  /** delete single row from the table: "billing_mode" */
  delete_billing_mode_by_pk?: Maybe<Billing_Mode>
  /** delete data from the table: "billing_platform_connected_account" */
  delete_billing_platform_connected_account?: Maybe<Billing_Platform_Connected_Account_Mutation_Response>
  /** delete single row from the table: "billing_platform_connected_account" */
  delete_billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** delete data from the table: "billing_platform_connected_account_status" */
  delete_billing_platform_connected_account_status?: Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>
  /** delete single row from the table: "billing_platform_connected_account_status" */
  delete_billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** delete data from the table: "billing_platform_customer" */
  delete_billing_platform_customer?: Maybe<Billing_Platform_Customer_Mutation_Response>
  /** delete single row from the table: "billing_platform_customer" */
  delete_billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** delete data from the table: "cart" */
  delete_cart?: Maybe<Cart_Mutation_Response>
  /** delete single row from the table: "cart" */
  delete_cart_by_pk?: Maybe<Cart>
  /** delete data from the table: "cart_item" */
  delete_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** delete single row from the table: "cart_item" */
  delete_cart_item_by_pk?: Maybe<Cart_Item>
  /** delete data from the table: "cart_item_like" */
  delete_cart_item_like?: Maybe<Cart_Item_Like_Mutation_Response>
  /** delete single row from the table: "cart_item_like" */
  delete_cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** delete data from the table: "cart_item_note" */
  delete_cart_item_note?: Maybe<Cart_Item_Note_Mutation_Response>
  /** delete single row from the table: "cart_item_note" */
  delete_cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>
  /** delete data from the table: "category_shop_item" */
  delete_category_shop_item?: Maybe<Category_Shop_Item_Mutation_Response>
  /** delete single row from the table: "category_shop_item" */
  delete_category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** delete data from the table: "checkout" */
  delete_checkout?: Maybe<Checkout_Mutation_Response>
  /** delete single row from the table: "checkout" */
  delete_checkout_by_pk?: Maybe<Checkout>
  /** delete data from the table: "collection" */
  delete_collection?: Maybe<Collection_Mutation_Response>
  /** delete single row from the table: "collection" */
  delete_collection_by_pk?: Maybe<Collection>
  /** delete data from the table: "collection_cart_item" */
  delete_collection_cart_item?: Maybe<Collection_Cart_Item_Mutation_Response>
  /** delete single row from the table: "collection_cart_item" */
  delete_collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** delete data from the table: "collection_collaborator" */
  delete_collection_collaborator?: Maybe<Collection_Collaborator_Mutation_Response>
  /** delete single row from the table: "collection_collaborator" */
  delete_collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** delete data from the table: "collection_invite" */
  delete_collection_invite?: Maybe<Collection_Invite_Mutation_Response>
  /** delete single row from the table: "collection_invite" */
  delete_collection_invite_by_pk?: Maybe<Collection_Invite>
  /** delete data from the table: "collection_invite_status" */
  delete_collection_invite_status?: Maybe<Collection_Invite_Status_Mutation_Response>
  /** delete single row from the table: "collection_invite_status" */
  delete_collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** delete data from the table: "collection_item_claim" */
  delete_collection_item_claim?: Maybe<Collection_Item_Claim_Mutation_Response>
  /** delete single row from the table: "collection_item_claim" */
  delete_collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** delete data from the table: "collection_item_claim_status" */
  delete_collection_item_claim_status?: Maybe<Collection_Item_Claim_Status_Mutation_Response>
  /** delete single row from the table: "collection_item_claim_status" */
  delete_collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** delete data from the table: "collection_section" */
  delete_collection_section?: Maybe<Collection_Section_Mutation_Response>
  /** delete single row from the table: "collection_section" */
  delete_collection_section_by_pk?: Maybe<Collection_Section>
  /** delete data from the table: "collection_section_cart_item" */
  delete_collection_section_cart_item?: Maybe<Collection_Section_Cart_Item_Mutation_Response>
  /** delete single row from the table: "collection_section_cart_item" */
  delete_collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** delete data from the table: "collection_slug_history" */
  delete_collection_slug_history?: Maybe<Collection_Slug_History_Mutation_Response>
  /** delete single row from the table: "collection_slug_history" */
  delete_collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** delete data from the table: "collection_state" */
  delete_collection_state?: Maybe<Collection_State_Mutation_Response>
  /** delete single row from the table: "collection_state" */
  delete_collection_state_by_pk?: Maybe<Collection_State>
  /** delete data from the table: "collection_type" */
  delete_collection_type?: Maybe<Collection_Type_Mutation_Response>
  /** delete single row from the table: "collection_type" */
  delete_collection_type_by_pk?: Maybe<Collection_Type>
  /** delete data from the table: "comment" */
  delete_comment?: Maybe<Comment_Mutation_Response>
  /** delete single row from the table: "comment" */
  delete_comment_by_pk?: Maybe<Comment>
  /** delete data from the table: "commentable_item" */
  delete_commentable_item?: Maybe<Commentable_Item_Mutation_Response>
  /** delete single row from the table: "commentable_item" */
  delete_commentable_item_by_pk?: Maybe<Commentable_Item>
  /** delete data from the table: "commentable_item_type" */
  delete_commentable_item_type?: Maybe<Commentable_Item_Type_Mutation_Response>
  /** delete single row from the table: "commentable_item_type" */
  delete_commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** delete data from the table: "communication_template" */
  delete_communication_template?: Maybe<Communication_Template_Mutation_Response>
  /** delete single row from the table: "communication_template" */
  delete_communication_template_by_pk?: Maybe<Communication_Template>
  /** delete data from the table: "communication_template_full_list" */
  delete_communication_template_full_list?: Maybe<Communication_Template_Full_List_Mutation_Response>
  /** delete single row from the table: "communication_template_full_list" */
  delete_communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  /** delete data from the table: "contact_info_type" */
  delete_contact_info_type?: Maybe<Contact_Info_Type_Mutation_Response>
  /** delete single row from the table: "contact_info_type" */
  delete_contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** delete data from the table: "contact_invite" */
  delete_contact_invite?: Maybe<Contact_Invite_Mutation_Response>
  /** delete single row from the table: "contact_invite" */
  delete_contact_invite_by_pk?: Maybe<Contact_Invite>
  /** delete data from the table: "contact_invite_status" */
  delete_contact_invite_status?: Maybe<Contact_Invite_Status_Mutation_Response>
  /** delete single row from the table: "contact_invite_status" */
  delete_contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** delete data from the table: "contact_invite_type" */
  delete_contact_invite_type?: Maybe<Contact_Invite_Type_Mutation_Response>
  /** delete single row from the table: "contact_invite_type" */
  delete_contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** delete data from the table: "deal_hop_gate" */
  delete_deal_hop_gate?: Maybe<Deal_Hop_Gate_Mutation_Response>
  /** delete single row from the table: "deal_hop_gate" */
  delete_deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** delete data from the table: "device_client_data" */
  delete_device_client_data?: Maybe<Device_Client_Data_Mutation_Response>
  /** delete single row from the table: "device_client_data" */
  delete_device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** delete data from the table: "discovery_backlog" */
  delete_discovery_backlog?: Maybe<Discovery_Backlog_Mutation_Response>
  /** delete single row from the table: "discovery_backlog" */
  delete_discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** delete data from the table: "discovery_feed" */
  delete_discovery_feed?: Maybe<Discovery_Feed_Mutation_Response>
  /** delete single row from the table: "discovery_feed" */
  delete_discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** delete data from the table: "discovery_staging" */
  delete_discovery_staging?: Maybe<Discovery_Staging_Mutation_Response>
  /** delete single row from the table: "discovery_staging" */
  delete_discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** delete data from the table: "featured_shop" */
  delete_featured_shop?: Maybe<Featured_Shop_Mutation_Response>
  /** delete single row from the table: "featured_shop" */
  delete_featured_shop_by_pk?: Maybe<Featured_Shop>
  /** delete data from the table: "featured_shop_item" */
  delete_featured_shop_item?: Maybe<Featured_Shop_Item_Mutation_Response>
  /** delete single row from the table: "featured_shop_item" */
  delete_featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** delete data from the table: "feed_item" */
  delete_feed_item?: Maybe<Feed_Item_Mutation_Response>
  /** delete single row from the table: "feed_item" */
  delete_feed_item_by_pk?: Maybe<Feed_Item>
  /** delete data from the table: "feed_item_like" */
  delete_feed_item_like?: Maybe<Feed_Item_Like_Mutation_Response>
  /** delete single row from the table: "feed_item_like" */
  delete_feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** delete data from the table: "feed_item_type" */
  delete_feed_item_type?: Maybe<Feed_Item_Type_Mutation_Response>
  /** delete single row from the table: "feed_item_type" */
  delete_feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** delete data from the table: "fmtc_deal" */
  delete_fmtc_deal?: Maybe<Fmtc_Deal_Mutation_Response>
  /** delete single row from the table: "fmtc_deal" */
  delete_fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** delete data from the table: "followable_item" */
  delete_followable_item?: Maybe<Followable_Item_Mutation_Response>
  /** delete single row from the table: "followable_item" */
  delete_followable_item_by_pk?: Maybe<Followable_Item>
  /** delete data from the table: "followable_item_type" */
  delete_followable_item_type?: Maybe<Followable_Item_Type_Mutation_Response>
  /** delete single row from the table: "followable_item_type" */
  delete_followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** delete data from the table: "leaderboard_event" */
  delete_leaderboard_event?: Maybe<Leaderboard_Event_Mutation_Response>
  /** delete single row from the table: "leaderboard_event" */
  delete_leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** delete data from the table: "leaderboard_event_name" */
  delete_leaderboard_event_name?: Maybe<Leaderboard_Event_Name_Mutation_Response>
  /** delete single row from the table: "leaderboard_event_name" */
  delete_leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** delete data from the table: "link_type" */
  delete_link_type?: Maybe<Link_Type_Mutation_Response>
  /** delete single row from the table: "link_type" */
  delete_link_type_by_pk?: Maybe<Link_Type>
  /** delete data from the table: "media_enabled_item" */
  delete_media_enabled_item?: Maybe<Media_Enabled_Item_Mutation_Response>
  /** delete single row from the table: "media_enabled_item" */
  delete_media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** delete data from the table: "media_item" */
  delete_media_item?: Maybe<Media_Item_Mutation_Response>
  /** delete single row from the table: "media_item" */
  delete_media_item_by_pk?: Maybe<Media_Item>
  /** delete data from the table: "media_item_type" */
  delete_media_item_type?: Maybe<Media_Item_Type_Mutation_Response>
  /** delete single row from the table: "media_item_type" */
  delete_media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** delete data from the table: "media_items" */
  delete_media_items?: Maybe<Media_Items_Mutation_Response>
  /** delete single row from the table: "media_items" */
  delete_media_items_by_pk?: Maybe<Media_Items>
  /** delete data from the table: "media_type" */
  delete_media_type?: Maybe<Media_Type_Mutation_Response>
  /** delete single row from the table: "media_type" */
  delete_media_type_by_pk?: Maybe<Media_Type>
  /** delete data from the table: "mobile_app_home" */
  delete_mobile_app_home?: Maybe<Mobile_App_Home_Mutation_Response>
  /** delete single row from the table: "mobile_app_home" */
  delete_mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** delete data from the table: "mobile_home" */
  delete_mobile_home?: Maybe<Mobile_Home_Mutation_Response>
  /** delete single row from the table: "mobile_home" */
  delete_mobile_home_by_pk?: Maybe<Mobile_Home>
  /** delete data from the table: "mobile_push_notification" */
  delete_mobile_push_notification?: Maybe<Mobile_Push_Notification_Mutation_Response>
  /** delete single row from the table: "mobile_push_notification" */
  delete_mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** delete data from the table: "mobile_push_notification_status" */
  delete_mobile_push_notification_status?: Maybe<Mobile_Push_Notification_Status_Mutation_Response>
  /** delete single row from the table: "mobile_push_notification_status" */
  delete_mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** delete data from the table: "notifiable_item" */
  delete_notifiable_item?: Maybe<Notifiable_Item_Mutation_Response>
  /** delete single row from the table: "notifiable_item" */
  delete_notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** delete data from the table: "notifiable_item_type" */
  delete_notifiable_item_type?: Maybe<Notifiable_Item_Type_Mutation_Response>
  /** delete single row from the table: "notifiable_item_type" */
  delete_notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<Notification_Mutation_Response>
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>
  /** delete data from the table: "notification_center_item" */
  delete_notification_center_item?: Maybe<Notification_Center_Item_Mutation_Response>
  /** delete single row from the table: "notification_center_item" */
  delete_notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** delete data from the table: "notification_center_item_read_status" */
  delete_notification_center_item_read_status?: Maybe<Notification_Center_Item_Read_Status_Mutation_Response>
  /** delete single row from the table: "notification_center_item_read_status" */
  delete_notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** delete data from the table: "notification_center_item_type" */
  delete_notification_center_item_type?: Maybe<Notification_Center_Item_Type_Mutation_Response>
  /** delete single row from the table: "notification_center_item_type" */
  delete_notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** delete data from the table: "notification_preference" */
  delete_notification_preference?: Maybe<Notification_Preference_Mutation_Response>
  /** delete single row from the table: "notification_preference" */
  delete_notification_preference_by_pk?: Maybe<Notification_Preference>
  /** delete data from the table: "notification_status" */
  delete_notification_status?: Maybe<Notification_Status_Mutation_Response>
  /** delete single row from the table: "notification_status" */
  delete_notification_status_by_pk?: Maybe<Notification_Status>
  /** delete data from the table: "onboarding_categories" */
  delete_onboarding_categories?: Maybe<Onboarding_Categories_Mutation_Response>
  /** delete single row from the table: "onboarding_categories" */
  delete_onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** delete data from the table: "onboarding_sources" */
  delete_onboarding_sources?: Maybe<Onboarding_Sources_Mutation_Response>
  /** delete single row from the table: "onboarding_sources" */
  delete_onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** delete data from the table: "one_off_email" */
  delete_one_off_email?: Maybe<One_Off_Email_Mutation_Response>
  /** delete single row from the table: "one_off_email" */
  delete_one_off_email_by_pk?: Maybe<One_Off_Email>
  /** delete data from the table: "pageview_event" */
  delete_pageview_event?: Maybe<Pageview_Event_Mutation_Response>
  /** delete single row from the table: "pageview_event" */
  delete_pageview_event_by_pk?: Maybe<Pageview_Event>
  /** delete data from the table: "promo_period" */
  delete_promo_period?: Maybe<Promo_Period_Mutation_Response>
  /** delete single row from the table: "promo_period" */
  delete_promo_period_by_pk?: Maybe<Promo_Period>
  /** delete data from the table: "push_notification" */
  delete_push_notification?: Maybe<Push_Notification_Mutation_Response>
  /** delete single row from the table: "push_notification" */
  delete_push_notification_by_pk?: Maybe<Push_Notification>
  /** delete data from the table: "push_notification_status" */
  delete_push_notification_status?: Maybe<Push_Notification_Status_Mutation_Response>
  /** delete single row from the table: "push_notification_status" */
  delete_push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** delete data from the table: "redirect_ids" */
  delete_redirect_ids?: Maybe<Redirect_Ids_Mutation_Response>
  /** delete single row from the table: "redirect_ids" */
  delete_redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** delete data from the table: "remove_reason" */
  delete_remove_reason?: Maybe<Remove_Reason_Mutation_Response>
  /** delete single row from the table: "remove_reason" */
  delete_remove_reason_by_pk?: Maybe<Remove_Reason>
  /** delete data from the table: "rewardable_action_names" */
  delete_rewardable_action_names?: Maybe<Rewardable_Action_Names_Mutation_Response>
  /** delete single row from the table: "rewardable_action_names" */
  delete_rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** delete data from the table: "rewardable_item_type" */
  delete_rewardable_item_type?: Maybe<Rewardable_Item_Type_Mutation_Response>
  /** delete single row from the table: "rewardable_item_type" */
  delete_rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** delete data from the table: "search_query" */
  delete_search_query?: Maybe<Search_Query_Mutation_Response>
  /** delete single row from the table: "search_query" */
  delete_search_query_by_pk?: Maybe<Search_Query>
  /** delete data from the table: "search_query_result" */
  delete_search_query_result?: Maybe<Search_Query_Result_Mutation_Response>
  /** delete single row from the table: "search_query_result" */
  delete_search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** delete data from the table: "shop" */
  delete_shop?: Maybe<Shop_Mutation_Response>
  /** delete data from the table: "shop_affiliate" */
  delete_shop_affiliate?: Maybe<Shop_Affiliate_Mutation_Response>
  /** delete single row from the table: "shop_affiliate" */
  delete_shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** delete single row from the table: "shop" */
  delete_shop_by_pk?: Maybe<Shop>
  /** delete data from the table: "shop_categorization" */
  delete_shop_categorization?: Maybe<Shop_Categorization_Mutation_Response>
  /** delete single row from the table: "shop_categorization" */
  delete_shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** delete data from the table: "shop_category" */
  delete_shop_category?: Maybe<Shop_Category_Mutation_Response>
  /** delete single row from the table: "shop_category" */
  delete_shop_category_by_pk?: Maybe<Shop_Category>
  /** delete data from the table: "shop_item" */
  delete_shop_item?: Maybe<Shop_Item_Mutation_Response>
  /** delete data from the table: "shop_item_availability" */
  delete_shop_item_availability?: Maybe<Shop_Item_Availability_Mutation_Response>
  /** delete single row from the table: "shop_item_availability" */
  delete_shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** delete single row from the table: "shop_item" */
  delete_shop_item_by_pk?: Maybe<Shop_Item>
  /** delete data from the table: "shop_item_enrichment_status" */
  delete_shop_item_enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Mutation_Response>
  /** delete single row from the table: "shop_item_enrichment_status" */
  delete_shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** delete data from the table: "shop_item_history" */
  delete_shop_item_history?: Maybe<Shop_Item_History_Mutation_Response>
  /** delete single row from the table: "shop_item_history" */
  delete_shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** delete data from the table: "shop_item_image" */
  delete_shop_item_image?: Maybe<Shop_Item_Image_Mutation_Response>
  /** delete single row from the table: "shop_item_image" */
  delete_shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** delete data from the table: "shop_item_index" */
  delete_shop_item_index?: Maybe<Shop_Item_Index_Mutation_Response>
  /** delete single row from the table: "shop_item_index" */
  delete_shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** delete data from the table: "shop_item_to_be_scraped" */
  delete_shop_item_to_be_scraped?: Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>
  /** delete data from the table: "shop_rating" */
  delete_shop_rating?: Maybe<Shop_Rating_Mutation_Response>
  /** delete single row from the table: "shop_rating" */
  delete_shop_rating_by_pk?: Maybe<Shop_Rating>
  /** delete data from the table: "similar_products_history" */
  delete_similar_products_history?: Maybe<Similar_Products_History_Mutation_Response>
  /** delete single row from the table: "similar_products_history" */
  delete_similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** delete data from the table: "user" */
  delete_user?: Maybe<User_Mutation_Response>
  /** delete data from the table: "user_account_type" */
  delete_user_account_type?: Maybe<User_Account_Type_Mutation_Response>
  /** delete single row from the table: "user_account_type" */
  delete_user_account_type_by_pk?: Maybe<User_Account_Type>
  /** delete data from the table: "user_action" */
  delete_user_action?: Maybe<User_Action_Mutation_Response>
  /** delete single row from the table: "user_action" */
  delete_user_action_by_pk?: Maybe<User_Action>
  /** delete data from the table: "user_app_version" */
  delete_user_app_version?: Maybe<User_App_Version_Mutation_Response>
  /** delete single row from the table: "user_app_version" */
  delete_user_app_version_by_pk?: Maybe<User_App_Version>
  /** delete single row from the table: "user" */
  delete_user_by_pk?: Maybe<User>
  /** delete data from the table: "user_carts_onboarding" */
  delete_user_carts_onboarding?: Maybe<User_Carts_Onboarding_Mutation_Response>
  /** delete single row from the table: "user_carts_onboarding" */
  delete_user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** delete data from the table: "user_category" */
  delete_user_category?: Maybe<User_Category_Mutation_Response>
  /** delete single row from the table: "user_category" */
  delete_user_category_by_pk?: Maybe<User_Category>
  /** delete data from the table: "user_deletion" */
  delete_user_deletion?: Maybe<User_Deletion_Mutation_Response>
  /** delete single row from the table: "user_deletion" */
  delete_user_deletion_by_pk?: Maybe<User_Deletion>
  /** delete data from the table: "user_feedback" */
  delete_user_feedback?: Maybe<User_Feedback_Mutation_Response>
  /** delete single row from the table: "user_feedback" */
  delete_user_feedback_by_pk?: Maybe<User_Feedback>
  /** delete data from the table: "user_feedback_item" */
  delete_user_feedback_item?: Maybe<User_Feedback_Item_Mutation_Response>
  /** delete single row from the table: "user_feedback_item" */
  delete_user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** delete data from the table: "user_feedback_type" */
  delete_user_feedback_type?: Maybe<User_Feedback_Type_Mutation_Response>
  /** delete single row from the table: "user_feedback_type" */
  delete_user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** delete data from the table: "user_flags" */
  delete_user_flags?: Maybe<User_Flags_Mutation_Response>
  /** delete single row from the table: "user_flags" */
  delete_user_flags_by_pk?: Maybe<User_Flags>
  /** delete data from the table: "user_impersonation" */
  delete_user_impersonation?: Maybe<User_Impersonation_Mutation_Response>
  /** delete single row from the table: "user_impersonation" */
  delete_user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** delete data from the table: "user_ip_address" */
  delete_user_ip_address?: Maybe<User_Ip_Address_Mutation_Response>
  /** delete single row from the table: "user_ip_address" */
  delete_user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** delete data from the table: "user_items_followed" */
  delete_user_items_followed?: Maybe<User_Items_Followed_Mutation_Response>
  /** delete single row from the table: "user_items_followed" */
  delete_user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** delete data from the table: "user_links" */
  delete_user_links?: Maybe<User_Links_Mutation_Response>
  /** delete single row from the table: "user_links" */
  delete_user_links_by_pk?: Maybe<User_Links>
  /** delete data from the table: "user_onboarding_source" */
  delete_user_onboarding_source?: Maybe<User_Onboarding_Source_Mutation_Response>
  /** delete single row from the table: "user_onboarding_source" */
  delete_user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** delete data from the table: "user_onboarding_state" */
  delete_user_onboarding_state?: Maybe<User_Onboarding_State_Mutation_Response>
  /** delete single row from the table: "user_onboarding_state" */
  delete_user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** delete data from the table: "user_phone_contact" */
  delete_user_phone_contact?: Maybe<User_Phone_Contact_Mutation_Response>
  /** delete single row from the table: "user_phone_contact" */
  delete_user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** delete data from the table: "user_purchase_subscription" */
  delete_user_purchase_subscription?: Maybe<User_Purchase_Subscription_Mutation_Response>
  /** delete single row from the table: "user_purchase_subscription" */
  delete_user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** delete data from the table: "user_purchases" */
  delete_user_purchases?: Maybe<User_Purchases_Mutation_Response>
  /** delete single row from the table: "user_purchases" */
  delete_user_purchases_by_pk?: Maybe<User_Purchases>
  /** delete data from the table: "user_push_notification" */
  delete_user_push_notification?: Maybe<User_Push_Notification_Mutation_Response>
  /** delete data from the table: "user_push_notification_token" */
  delete_user_push_notification_token?: Maybe<User_Push_Notification_Token_Mutation_Response>
  /** delete single row from the table: "user_push_notification_token" */
  delete_user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** delete data from the table: "user_referral" */
  delete_user_referral?: Maybe<User_Referral_Mutation_Response>
  /** delete single row from the table: "user_referral" */
  delete_user_referral_by_pk?: Maybe<User_Referral>
  /** delete data from the table: "user_reward_multiplier_config" */
  delete_user_reward_multiplier_config?: Maybe<User_Reward_Multiplier_Config_Mutation_Response>
  /** delete single row from the table: "user_reward_multiplier_config" */
  delete_user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** delete data from the table: "user_reward_redemption" */
  delete_user_reward_redemption?: Maybe<User_Reward_Redemption_Mutation_Response>
  /** delete single row from the table: "user_reward_redemption" */
  delete_user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** delete data from the table: "user_reward_reference" */
  delete_user_reward_reference?: Maybe<User_Reward_Reference_Mutation_Response>
  /** delete single row from the table: "user_reward_reference" */
  delete_user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** delete data from the table: "user_shop_favorite" */
  delete_user_shop_favorite?: Maybe<User_Shop_Favorite_Mutation_Response>
  /** delete single row from the table: "user_shop_favorite" */
  delete_user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** delete data from the table: "user_shopping_preferences" */
  delete_user_shopping_preferences?: Maybe<User_Shopping_Preferences_Mutation_Response>
  /** delete single row from the table: "user_shopping_preferences" */
  delete_user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** delete data from the table: "user_subscription" */
  delete_user_subscription?: Maybe<User_Subscription_Mutation_Response>
  /** delete single row from the table: "user_subscription" */
  delete_user_subscription_by_pk?: Maybe<User_Subscription>
  /** delete data from the table: "user_subscription_status" */
  delete_user_subscription_status?: Maybe<User_Subscription_Status_Mutation_Response>
  /** delete single row from the table: "user_subscription_status" */
  delete_user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** delete data from the table: "user_temp" */
  delete_user_temp?: Maybe<User_Temp_Mutation_Response>
  /** delete single row from the table: "user_temp" */
  delete_user_temp_by_pk?: Maybe<User_Temp>
  /** delete data from the table: "worflow_type" */
  delete_worflow_type?: Maybe<Worflow_Type_Mutation_Response>
  /** delete single row from the table: "worflow_type" */
  delete_worflow_type_by_pk?: Maybe<Worflow_Type>
  /** delete data from the table: "workflow" */
  delete_workflow?: Maybe<Workflow_Mutation_Response>
  /** delete single row from the table: "workflow" */
  delete_workflow_by_pk?: Maybe<Workflow>
  /** delete data from the table: "workflow_status" */
  delete_workflow_status?: Maybe<Workflow_Status_Mutation_Response>
  /** delete single row from the table: "workflow_status" */
  delete_workflow_status_by_pk?: Maybe<Workflow_Status>
  /** A mutation to fully unsubscribe from a creator */
  fullyUnsubscribeFromCreator: FullyUnsubscribeOutput
  /** insert data into the table: "account_deletion_reason" */
  insert_account_deletion_reason?: Maybe<Account_Deletion_Reason_Mutation_Response>
  /** insert a single row into the table: "account_deletion_reason" */
  insert_account_deletion_reason_one?: Maybe<Account_Deletion_Reason>
  /** insert data into the table: "affiliate" */
  insert_affiliate?: Maybe<Affiliate_Mutation_Response>
  /** insert data into the table: "affiliate_advertiser_id" */
  insert_affiliate_advertiser_id?: Maybe<Affiliate_Advertiser_Id_Mutation_Response>
  /** insert a single row into the table: "affiliate_advertiser_id" */
  insert_affiliate_advertiser_id_one?: Maybe<Affiliate_Advertiser_Id>
  /** insert data into the table: "affiliate_meta" */
  insert_affiliate_meta?: Maybe<Affiliate_Meta_Mutation_Response>
  /** insert a single row into the table: "affiliate_meta" */
  insert_affiliate_meta_one?: Maybe<Affiliate_Meta>
  /** insert a single row into the table: "affiliate" */
  insert_affiliate_one?: Maybe<Affiliate>
  /** insert data into the table: "affiliate_transaction" */
  insert_affiliate_transaction?: Maybe<Affiliate_Transaction_Mutation_Response>
  /** insert a single row into the table: "affiliate_transaction" */
  insert_affiliate_transaction_one?: Maybe<Affiliate_Transaction>
  /** insert data into the table: "billing_mode" */
  insert_billing_mode?: Maybe<Billing_Mode_Mutation_Response>
  /** insert a single row into the table: "billing_mode" */
  insert_billing_mode_one?: Maybe<Billing_Mode>
  /** insert data into the table: "billing_platform_connected_account" */
  insert_billing_platform_connected_account?: Maybe<Billing_Platform_Connected_Account_Mutation_Response>
  /** insert a single row into the table: "billing_platform_connected_account" */
  insert_billing_platform_connected_account_one?: Maybe<Billing_Platform_Connected_Account>
  /** insert data into the table: "billing_platform_connected_account_status" */
  insert_billing_platform_connected_account_status?: Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>
  /** insert a single row into the table: "billing_platform_connected_account_status" */
  insert_billing_platform_connected_account_status_one?: Maybe<Billing_Platform_Connected_Account_Status>
  /** insert data into the table: "billing_platform_customer" */
  insert_billing_platform_customer?: Maybe<Billing_Platform_Customer_Mutation_Response>
  /** insert a single row into the table: "billing_platform_customer" */
  insert_billing_platform_customer_one?: Maybe<Billing_Platform_Customer>
  /** insert data into the table: "cart" */
  insert_cart?: Maybe<Cart_Mutation_Response>
  /** insert data into the table: "cart_item" */
  insert_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** insert data into the table: "cart_item_like" */
  insert_cart_item_like?: Maybe<Cart_Item_Like_Mutation_Response>
  /** insert a single row into the table: "cart_item_like" */
  insert_cart_item_like_one?: Maybe<Cart_Item_Like>
  /** insert data into the table: "cart_item_note" */
  insert_cart_item_note?: Maybe<Cart_Item_Note_Mutation_Response>
  /** insert a single row into the table: "cart_item_note" */
  insert_cart_item_note_one?: Maybe<Cart_Item_Note>
  /** insert a single row into the table: "cart_item" */
  insert_cart_item_one?: Maybe<Cart_Item>
  /** insert a single row into the table: "cart" */
  insert_cart_one?: Maybe<Cart>
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>
  /** insert data into the table: "category_shop_item" */
  insert_category_shop_item?: Maybe<Category_Shop_Item_Mutation_Response>
  /** insert a single row into the table: "category_shop_item" */
  insert_category_shop_item_one?: Maybe<Category_Shop_Item>
  /** insert data into the table: "checkout" */
  insert_checkout?: Maybe<Checkout_Mutation_Response>
  /** insert a single row into the table: "checkout" */
  insert_checkout_one?: Maybe<Checkout>
  /** insert data into the table: "collection" */
  insert_collection?: Maybe<Collection_Mutation_Response>
  /** insert data into the table: "collection_cart_item" */
  insert_collection_cart_item?: Maybe<Collection_Cart_Item_Mutation_Response>
  /** insert a single row into the table: "collection_cart_item" */
  insert_collection_cart_item_one?: Maybe<Collection_Cart_Item>
  /** insert data into the table: "collection_collaborator" */
  insert_collection_collaborator?: Maybe<Collection_Collaborator_Mutation_Response>
  /** insert a single row into the table: "collection_collaborator" */
  insert_collection_collaborator_one?: Maybe<Collection_Collaborator>
  /** insert data into the table: "collection_invite" */
  insert_collection_invite?: Maybe<Collection_Invite_Mutation_Response>
  /** insert a single row into the table: "collection_invite" */
  insert_collection_invite_one?: Maybe<Collection_Invite>
  /** insert data into the table: "collection_invite_status" */
  insert_collection_invite_status?: Maybe<Collection_Invite_Status_Mutation_Response>
  /** insert a single row into the table: "collection_invite_status" */
  insert_collection_invite_status_one?: Maybe<Collection_Invite_Status>
  /** insert data into the table: "collection_item_claim" */
  insert_collection_item_claim?: Maybe<Collection_Item_Claim_Mutation_Response>
  /** insert a single row into the table: "collection_item_claim" */
  insert_collection_item_claim_one?: Maybe<Collection_Item_Claim>
  /** insert data into the table: "collection_item_claim_status" */
  insert_collection_item_claim_status?: Maybe<Collection_Item_Claim_Status_Mutation_Response>
  /** insert a single row into the table: "collection_item_claim_status" */
  insert_collection_item_claim_status_one?: Maybe<Collection_Item_Claim_Status>
  /** insert a single row into the table: "collection" */
  insert_collection_one?: Maybe<Collection>
  /** insert data into the table: "collection_section" */
  insert_collection_section?: Maybe<Collection_Section_Mutation_Response>
  /** insert data into the table: "collection_section_cart_item" */
  insert_collection_section_cart_item?: Maybe<Collection_Section_Cart_Item_Mutation_Response>
  /** insert a single row into the table: "collection_section_cart_item" */
  insert_collection_section_cart_item_one?: Maybe<Collection_Section_Cart_Item>
  /** insert a single row into the table: "collection_section" */
  insert_collection_section_one?: Maybe<Collection_Section>
  /** insert data into the table: "collection_slug_history" */
  insert_collection_slug_history?: Maybe<Collection_Slug_History_Mutation_Response>
  /** insert a single row into the table: "collection_slug_history" */
  insert_collection_slug_history_one?: Maybe<Collection_Slug_History>
  /** insert data into the table: "collection_state" */
  insert_collection_state?: Maybe<Collection_State_Mutation_Response>
  /** insert a single row into the table: "collection_state" */
  insert_collection_state_one?: Maybe<Collection_State>
  /** insert data into the table: "collection_type" */
  insert_collection_type?: Maybe<Collection_Type_Mutation_Response>
  /** insert a single row into the table: "collection_type" */
  insert_collection_type_one?: Maybe<Collection_Type>
  /** insert data into the table: "comment" */
  insert_comment?: Maybe<Comment_Mutation_Response>
  /** insert a single row into the table: "comment" */
  insert_comment_one?: Maybe<Comment>
  /** insert data into the table: "commentable_item" */
  insert_commentable_item?: Maybe<Commentable_Item_Mutation_Response>
  /** insert a single row into the table: "commentable_item" */
  insert_commentable_item_one?: Maybe<Commentable_Item>
  /** insert data into the table: "commentable_item_type" */
  insert_commentable_item_type?: Maybe<Commentable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "commentable_item_type" */
  insert_commentable_item_type_one?: Maybe<Commentable_Item_Type>
  /** insert data into the table: "communication_template" */
  insert_communication_template?: Maybe<Communication_Template_Mutation_Response>
  /** insert data into the table: "communication_template_full_list" */
  insert_communication_template_full_list?: Maybe<Communication_Template_Full_List_Mutation_Response>
  /** insert a single row into the table: "communication_template_full_list" */
  insert_communication_template_full_list_one?: Maybe<Communication_Template_Full_List>
  /** insert a single row into the table: "communication_template" */
  insert_communication_template_one?: Maybe<Communication_Template>
  /** insert data into the table: "contact_info_type" */
  insert_contact_info_type?: Maybe<Contact_Info_Type_Mutation_Response>
  /** insert a single row into the table: "contact_info_type" */
  insert_contact_info_type_one?: Maybe<Contact_Info_Type>
  /** insert data into the table: "contact_invite" */
  insert_contact_invite?: Maybe<Contact_Invite_Mutation_Response>
  /** insert a single row into the table: "contact_invite" */
  insert_contact_invite_one?: Maybe<Contact_Invite>
  /** insert data into the table: "contact_invite_status" */
  insert_contact_invite_status?: Maybe<Contact_Invite_Status_Mutation_Response>
  /** insert a single row into the table: "contact_invite_status" */
  insert_contact_invite_status_one?: Maybe<Contact_Invite_Status>
  /** insert data into the table: "contact_invite_type" */
  insert_contact_invite_type?: Maybe<Contact_Invite_Type_Mutation_Response>
  /** insert a single row into the table: "contact_invite_type" */
  insert_contact_invite_type_one?: Maybe<Contact_Invite_Type>
  /** insert data into the table: "deal_hop_gate" */
  insert_deal_hop_gate?: Maybe<Deal_Hop_Gate_Mutation_Response>
  /** insert a single row into the table: "deal_hop_gate" */
  insert_deal_hop_gate_one?: Maybe<Deal_Hop_Gate>
  /** insert data into the table: "device_client_data" */
  insert_device_client_data?: Maybe<Device_Client_Data_Mutation_Response>
  /** insert a single row into the table: "device_client_data" */
  insert_device_client_data_one?: Maybe<Device_Client_Data>
  /** insert data into the table: "discovery_backlog" */
  insert_discovery_backlog?: Maybe<Discovery_Backlog_Mutation_Response>
  /** insert a single row into the table: "discovery_backlog" */
  insert_discovery_backlog_one?: Maybe<Discovery_Backlog>
  /** insert data into the table: "discovery_feed" */
  insert_discovery_feed?: Maybe<Discovery_Feed_Mutation_Response>
  /** insert a single row into the table: "discovery_feed" */
  insert_discovery_feed_one?: Maybe<Discovery_Feed>
  /** insert data into the table: "discovery_staging" */
  insert_discovery_staging?: Maybe<Discovery_Staging_Mutation_Response>
  /** insert a single row into the table: "discovery_staging" */
  insert_discovery_staging_one?: Maybe<Discovery_Staging>
  /** insert data into the table: "featured_shop" */
  insert_featured_shop?: Maybe<Featured_Shop_Mutation_Response>
  /** insert data into the table: "featured_shop_item" */
  insert_featured_shop_item?: Maybe<Featured_Shop_Item_Mutation_Response>
  /** insert a single row into the table: "featured_shop_item" */
  insert_featured_shop_item_one?: Maybe<Featured_Shop_Item>
  /** insert a single row into the table: "featured_shop" */
  insert_featured_shop_one?: Maybe<Featured_Shop>
  /** insert data into the table: "feed_item" */
  insert_feed_item?: Maybe<Feed_Item_Mutation_Response>
  /** insert data into the table: "feed_item_like" */
  insert_feed_item_like?: Maybe<Feed_Item_Like_Mutation_Response>
  /** insert a single row into the table: "feed_item_like" */
  insert_feed_item_like_one?: Maybe<Feed_Item_Like>
  /** insert a single row into the table: "feed_item" */
  insert_feed_item_one?: Maybe<Feed_Item>
  /** insert data into the table: "feed_item_type" */
  insert_feed_item_type?: Maybe<Feed_Item_Type_Mutation_Response>
  /** insert a single row into the table: "feed_item_type" */
  insert_feed_item_type_one?: Maybe<Feed_Item_Type>
  /** insert data into the table: "fmtc_deal" */
  insert_fmtc_deal?: Maybe<Fmtc_Deal_Mutation_Response>
  /** insert a single row into the table: "fmtc_deal" */
  insert_fmtc_deal_one?: Maybe<Fmtc_Deal>
  /** insert data into the table: "followable_item" */
  insert_followable_item?: Maybe<Followable_Item_Mutation_Response>
  /** insert a single row into the table: "followable_item" */
  insert_followable_item_one?: Maybe<Followable_Item>
  /** insert data into the table: "followable_item_type" */
  insert_followable_item_type?: Maybe<Followable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "followable_item_type" */
  insert_followable_item_type_one?: Maybe<Followable_Item_Type>
  /** insert data into the table: "leaderboard_event" */
  insert_leaderboard_event?: Maybe<Leaderboard_Event_Mutation_Response>
  /** insert data into the table: "leaderboard_event_name" */
  insert_leaderboard_event_name?: Maybe<Leaderboard_Event_Name_Mutation_Response>
  /** insert a single row into the table: "leaderboard_event_name" */
  insert_leaderboard_event_name_one?: Maybe<Leaderboard_Event_Name>
  /** insert a single row into the table: "leaderboard_event" */
  insert_leaderboard_event_one?: Maybe<Leaderboard_Event>
  /** insert data into the table: "link_type" */
  insert_link_type?: Maybe<Link_Type_Mutation_Response>
  /** insert a single row into the table: "link_type" */
  insert_link_type_one?: Maybe<Link_Type>
  /** insert data into the table: "media_enabled_item" */
  insert_media_enabled_item?: Maybe<Media_Enabled_Item_Mutation_Response>
  /** insert a single row into the table: "media_enabled_item" */
  insert_media_enabled_item_one?: Maybe<Media_Enabled_Item>
  /** insert data into the table: "media_item" */
  insert_media_item?: Maybe<Media_Item_Mutation_Response>
  /** insert a single row into the table: "media_item" */
  insert_media_item_one?: Maybe<Media_Item>
  /** insert data into the table: "media_item_type" */
  insert_media_item_type?: Maybe<Media_Item_Type_Mutation_Response>
  /** insert a single row into the table: "media_item_type" */
  insert_media_item_type_one?: Maybe<Media_Item_Type>
  /** insert data into the table: "media_items" */
  insert_media_items?: Maybe<Media_Items_Mutation_Response>
  /** insert a single row into the table: "media_items" */
  insert_media_items_one?: Maybe<Media_Items>
  /** insert data into the table: "media_type" */
  insert_media_type?: Maybe<Media_Type_Mutation_Response>
  /** insert a single row into the table: "media_type" */
  insert_media_type_one?: Maybe<Media_Type>
  /** insert data into the table: "mobile_app_home" */
  insert_mobile_app_home?: Maybe<Mobile_App_Home_Mutation_Response>
  /** insert a single row into the table: "mobile_app_home" */
  insert_mobile_app_home_one?: Maybe<Mobile_App_Home>
  /** insert data into the table: "mobile_home" */
  insert_mobile_home?: Maybe<Mobile_Home_Mutation_Response>
  /** insert a single row into the table: "mobile_home" */
  insert_mobile_home_one?: Maybe<Mobile_Home>
  /** insert data into the table: "mobile_push_notification" */
  insert_mobile_push_notification?: Maybe<Mobile_Push_Notification_Mutation_Response>
  /** insert a single row into the table: "mobile_push_notification" */
  insert_mobile_push_notification_one?: Maybe<Mobile_Push_Notification>
  /** insert data into the table: "mobile_push_notification_status" */
  insert_mobile_push_notification_status?: Maybe<Mobile_Push_Notification_Status_Mutation_Response>
  /** insert a single row into the table: "mobile_push_notification_status" */
  insert_mobile_push_notification_status_one?: Maybe<Mobile_Push_Notification_Status>
  /** insert data into the table: "notifiable_item" */
  insert_notifiable_item?: Maybe<Notifiable_Item_Mutation_Response>
  /** insert a single row into the table: "notifiable_item" */
  insert_notifiable_item_one?: Maybe<Notifiable_Item>
  /** insert data into the table: "notifiable_item_type" */
  insert_notifiable_item_type?: Maybe<Notifiable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "notifiable_item_type" */
  insert_notifiable_item_type_one?: Maybe<Notifiable_Item_Type>
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<Notification_Mutation_Response>
  /** insert data into the table: "notification_center_item" */
  insert_notification_center_item?: Maybe<Notification_Center_Item_Mutation_Response>
  /** insert a single row into the table: "notification_center_item" */
  insert_notification_center_item_one?: Maybe<Notification_Center_Item>
  /** insert data into the table: "notification_center_item_read_status" */
  insert_notification_center_item_read_status?: Maybe<Notification_Center_Item_Read_Status_Mutation_Response>
  /** insert a single row into the table: "notification_center_item_read_status" */
  insert_notification_center_item_read_status_one?: Maybe<Notification_Center_Item_Read_Status>
  /** insert data into the table: "notification_center_item_type" */
  insert_notification_center_item_type?: Maybe<Notification_Center_Item_Type_Mutation_Response>
  /** insert a single row into the table: "notification_center_item_type" */
  insert_notification_center_item_type_one?: Maybe<Notification_Center_Item_Type>
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>
  /** insert data into the table: "notification_preference" */
  insert_notification_preference?: Maybe<Notification_Preference_Mutation_Response>
  /** insert a single row into the table: "notification_preference" */
  insert_notification_preference_one?: Maybe<Notification_Preference>
  /** insert data into the table: "notification_status" */
  insert_notification_status?: Maybe<Notification_Status_Mutation_Response>
  /** insert a single row into the table: "notification_status" */
  insert_notification_status_one?: Maybe<Notification_Status>
  /** insert data into the table: "onboarding_categories" */
  insert_onboarding_categories?: Maybe<Onboarding_Categories_Mutation_Response>
  /** insert a single row into the table: "onboarding_categories" */
  insert_onboarding_categories_one?: Maybe<Onboarding_Categories>
  /** insert data into the table: "onboarding_sources" */
  insert_onboarding_sources?: Maybe<Onboarding_Sources_Mutation_Response>
  /** insert a single row into the table: "onboarding_sources" */
  insert_onboarding_sources_one?: Maybe<Onboarding_Sources>
  /** insert data into the table: "one_off_email" */
  insert_one_off_email?: Maybe<One_Off_Email_Mutation_Response>
  /** insert a single row into the table: "one_off_email" */
  insert_one_off_email_one?: Maybe<One_Off_Email>
  /** insert data into the table: "pageview_event" */
  insert_pageview_event?: Maybe<Pageview_Event_Mutation_Response>
  /** insert a single row into the table: "pageview_event" */
  insert_pageview_event_one?: Maybe<Pageview_Event>
  /** insert data into the table: "promo_period" */
  insert_promo_period?: Maybe<Promo_Period_Mutation_Response>
  /** insert a single row into the table: "promo_period" */
  insert_promo_period_one?: Maybe<Promo_Period>
  /** insert data into the table: "push_notification" */
  insert_push_notification?: Maybe<Push_Notification_Mutation_Response>
  /** insert a single row into the table: "push_notification" */
  insert_push_notification_one?: Maybe<Push_Notification>
  /** insert data into the table: "push_notification_status" */
  insert_push_notification_status?: Maybe<Push_Notification_Status_Mutation_Response>
  /** insert a single row into the table: "push_notification_status" */
  insert_push_notification_status_one?: Maybe<Push_Notification_Status>
  /** insert data into the table: "redirect_ids" */
  insert_redirect_ids?: Maybe<Redirect_Ids_Mutation_Response>
  /** insert a single row into the table: "redirect_ids" */
  insert_redirect_ids_one?: Maybe<Redirect_Ids>
  /** insert data into the table: "remove_reason" */
  insert_remove_reason?: Maybe<Remove_Reason_Mutation_Response>
  /** insert a single row into the table: "remove_reason" */
  insert_remove_reason_one?: Maybe<Remove_Reason>
  /** insert data into the table: "rewardable_action_names" */
  insert_rewardable_action_names?: Maybe<Rewardable_Action_Names_Mutation_Response>
  /** insert a single row into the table: "rewardable_action_names" */
  insert_rewardable_action_names_one?: Maybe<Rewardable_Action_Names>
  /** insert data into the table: "rewardable_item_type" */
  insert_rewardable_item_type?: Maybe<Rewardable_Item_Type_Mutation_Response>
  /** insert a single row into the table: "rewardable_item_type" */
  insert_rewardable_item_type_one?: Maybe<Rewardable_Item_Type>
  /** insert data into the table: "search_query" */
  insert_search_query?: Maybe<Search_Query_Mutation_Response>
  /** insert a single row into the table: "search_query" */
  insert_search_query_one?: Maybe<Search_Query>
  /** insert data into the table: "search_query_result" */
  insert_search_query_result?: Maybe<Search_Query_Result_Mutation_Response>
  /** insert a single row into the table: "search_query_result" */
  insert_search_query_result_one?: Maybe<Search_Query_Result>
  /** insert data into the table: "shop" */
  insert_shop?: Maybe<Shop_Mutation_Response>
  /** insert data into the table: "shop_affiliate" */
  insert_shop_affiliate?: Maybe<Shop_Affiliate_Mutation_Response>
  /** insert a single row into the table: "shop_affiliate" */
  insert_shop_affiliate_one?: Maybe<Shop_Affiliate>
  /** insert data into the table: "shop_categorization" */
  insert_shop_categorization?: Maybe<Shop_Categorization_Mutation_Response>
  /** insert a single row into the table: "shop_categorization" */
  insert_shop_categorization_one?: Maybe<Shop_Categorization>
  /** insert data into the table: "shop_category" */
  insert_shop_category?: Maybe<Shop_Category_Mutation_Response>
  /** insert a single row into the table: "shop_category" */
  insert_shop_category_one?: Maybe<Shop_Category>
  /** insert data into the table: "shop_item" */
  insert_shop_item?: Maybe<Shop_Item_Mutation_Response>
  /** insert data into the table: "shop_item_availability" */
  insert_shop_item_availability?: Maybe<Shop_Item_Availability_Mutation_Response>
  /** insert a single row into the table: "shop_item_availability" */
  insert_shop_item_availability_one?: Maybe<Shop_Item_Availability>
  /** insert data into the table: "shop_item_enrichment_status" */
  insert_shop_item_enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Mutation_Response>
  /** insert a single row into the table: "shop_item_enrichment_status" */
  insert_shop_item_enrichment_status_one?: Maybe<Shop_Item_Enrichment_Status>
  /** insert data into the table: "shop_item_history" */
  insert_shop_item_history?: Maybe<Shop_Item_History_Mutation_Response>
  /** insert a single row into the table: "shop_item_history" */
  insert_shop_item_history_one?: Maybe<Shop_Item_History>
  /** insert data into the table: "shop_item_image" */
  insert_shop_item_image?: Maybe<Shop_Item_Image_Mutation_Response>
  /** insert a single row into the table: "shop_item_image" */
  insert_shop_item_image_one?: Maybe<Shop_Item_Image>
  /** insert data into the table: "shop_item_index" */
  insert_shop_item_index?: Maybe<Shop_Item_Index_Mutation_Response>
  /** insert a single row into the table: "shop_item_index" */
  insert_shop_item_index_one?: Maybe<Shop_Item_Index>
  /** insert a single row into the table: "shop_item" */
  insert_shop_item_one?: Maybe<Shop_Item>
  /** insert data into the table: "shop_item_to_be_scraped" */
  insert_shop_item_to_be_scraped?: Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>
  /** insert a single row into the table: "shop_item_to_be_scraped" */
  insert_shop_item_to_be_scraped_one?: Maybe<Shop_Item_To_Be_Scraped>
  /** insert a single row into the table: "shop" */
  insert_shop_one?: Maybe<Shop>
  /** insert data into the table: "shop_rating" */
  insert_shop_rating?: Maybe<Shop_Rating_Mutation_Response>
  /** insert a single row into the table: "shop_rating" */
  insert_shop_rating_one?: Maybe<Shop_Rating>
  /** insert data into the table: "similar_products_history" */
  insert_similar_products_history?: Maybe<Similar_Products_History_Mutation_Response>
  /** insert a single row into the table: "similar_products_history" */
  insert_similar_products_history_one?: Maybe<Similar_Products_History>
  /** insert data into the table: "user" */
  insert_user?: Maybe<User_Mutation_Response>
  /** insert data into the table: "user_account_type" */
  insert_user_account_type?: Maybe<User_Account_Type_Mutation_Response>
  /** insert a single row into the table: "user_account_type" */
  insert_user_account_type_one?: Maybe<User_Account_Type>
  /** insert data into the table: "user_action" */
  insert_user_action?: Maybe<User_Action_Mutation_Response>
  /** insert a single row into the table: "user_action" */
  insert_user_action_one?: Maybe<User_Action>
  /** insert data into the table: "user_app_version" */
  insert_user_app_version?: Maybe<User_App_Version_Mutation_Response>
  /** insert a single row into the table: "user_app_version" */
  insert_user_app_version_one?: Maybe<User_App_Version>
  /** insert data into the table: "user_carts_onboarding" */
  insert_user_carts_onboarding?: Maybe<User_Carts_Onboarding_Mutation_Response>
  /** insert a single row into the table: "user_carts_onboarding" */
  insert_user_carts_onboarding_one?: Maybe<User_Carts_Onboarding>
  /** insert data into the table: "user_category" */
  insert_user_category?: Maybe<User_Category_Mutation_Response>
  /** insert a single row into the table: "user_category" */
  insert_user_category_one?: Maybe<User_Category>
  /** insert data into the table: "user_deletion" */
  insert_user_deletion?: Maybe<User_Deletion_Mutation_Response>
  /** insert a single row into the table: "user_deletion" */
  insert_user_deletion_one?: Maybe<User_Deletion>
  /** insert data into the table: "user_feedback" */
  insert_user_feedback?: Maybe<User_Feedback_Mutation_Response>
  /** insert data into the table: "user_feedback_item" */
  insert_user_feedback_item?: Maybe<User_Feedback_Item_Mutation_Response>
  /** insert a single row into the table: "user_feedback_item" */
  insert_user_feedback_item_one?: Maybe<User_Feedback_Item>
  /** insert a single row into the table: "user_feedback" */
  insert_user_feedback_one?: Maybe<User_Feedback>
  /** insert data into the table: "user_feedback_type" */
  insert_user_feedback_type?: Maybe<User_Feedback_Type_Mutation_Response>
  /** insert a single row into the table: "user_feedback_type" */
  insert_user_feedback_type_one?: Maybe<User_Feedback_Type>
  /** insert data into the table: "user_flags" */
  insert_user_flags?: Maybe<User_Flags_Mutation_Response>
  /** insert a single row into the table: "user_flags" */
  insert_user_flags_one?: Maybe<User_Flags>
  /** insert data into the table: "user_impersonation" */
  insert_user_impersonation?: Maybe<User_Impersonation_Mutation_Response>
  /** insert a single row into the table: "user_impersonation" */
  insert_user_impersonation_one?: Maybe<User_Impersonation>
  /** insert data into the table: "user_ip_address" */
  insert_user_ip_address?: Maybe<User_Ip_Address_Mutation_Response>
  /** insert a single row into the table: "user_ip_address" */
  insert_user_ip_address_one?: Maybe<User_Ip_Address>
  /** insert data into the table: "user_items_followed" */
  insert_user_items_followed?: Maybe<User_Items_Followed_Mutation_Response>
  /** insert a single row into the table: "user_items_followed" */
  insert_user_items_followed_one?: Maybe<User_Items_Followed>
  /** insert data into the table: "user_links" */
  insert_user_links?: Maybe<User_Links_Mutation_Response>
  /** insert a single row into the table: "user_links" */
  insert_user_links_one?: Maybe<User_Links>
  /** insert data into the table: "user_onboarding_source" */
  insert_user_onboarding_source?: Maybe<User_Onboarding_Source_Mutation_Response>
  /** insert a single row into the table: "user_onboarding_source" */
  insert_user_onboarding_source_one?: Maybe<User_Onboarding_Source>
  /** insert data into the table: "user_onboarding_state" */
  insert_user_onboarding_state?: Maybe<User_Onboarding_State_Mutation_Response>
  /** insert a single row into the table: "user_onboarding_state" */
  insert_user_onboarding_state_one?: Maybe<User_Onboarding_State>
  /** insert a single row into the table: "user" */
  insert_user_one?: Maybe<User>
  /** insert data into the table: "user_phone_contact" */
  insert_user_phone_contact?: Maybe<User_Phone_Contact_Mutation_Response>
  /** insert a single row into the table: "user_phone_contact" */
  insert_user_phone_contact_one?: Maybe<User_Phone_Contact>
  /** insert data into the table: "user_purchase_subscription" */
  insert_user_purchase_subscription?: Maybe<User_Purchase_Subscription_Mutation_Response>
  /** insert a single row into the table: "user_purchase_subscription" */
  insert_user_purchase_subscription_one?: Maybe<User_Purchase_Subscription>
  /** insert data into the table: "user_purchases" */
  insert_user_purchases?: Maybe<User_Purchases_Mutation_Response>
  /** insert a single row into the table: "user_purchases" */
  insert_user_purchases_one?: Maybe<User_Purchases>
  /** insert data into the table: "user_push_notification" */
  insert_user_push_notification?: Maybe<User_Push_Notification_Mutation_Response>
  /** insert a single row into the table: "user_push_notification" */
  insert_user_push_notification_one?: Maybe<User_Push_Notification>
  /** insert data into the table: "user_push_notification_token" */
  insert_user_push_notification_token?: Maybe<User_Push_Notification_Token_Mutation_Response>
  /** insert a single row into the table: "user_push_notification_token" */
  insert_user_push_notification_token_one?: Maybe<User_Push_Notification_Token>
  /** insert data into the table: "user_referral" */
  insert_user_referral?: Maybe<User_Referral_Mutation_Response>
  /** insert a single row into the table: "user_referral" */
  insert_user_referral_one?: Maybe<User_Referral>
  /** insert data into the table: "user_reward_multiplier_config" */
  insert_user_reward_multiplier_config?: Maybe<User_Reward_Multiplier_Config_Mutation_Response>
  /** insert a single row into the table: "user_reward_multiplier_config" */
  insert_user_reward_multiplier_config_one?: Maybe<User_Reward_Multiplier_Config>
  /** insert data into the table: "user_reward_redemption" */
  insert_user_reward_redemption?: Maybe<User_Reward_Redemption_Mutation_Response>
  /** insert a single row into the table: "user_reward_redemption" */
  insert_user_reward_redemption_one?: Maybe<User_Reward_Redemption>
  /** insert data into the table: "user_reward_reference" */
  insert_user_reward_reference?: Maybe<User_Reward_Reference_Mutation_Response>
  /** insert a single row into the table: "user_reward_reference" */
  insert_user_reward_reference_one?: Maybe<User_Reward_Reference>
  /** insert data into the table: "user_shop_favorite" */
  insert_user_shop_favorite?: Maybe<User_Shop_Favorite_Mutation_Response>
  /** insert a single row into the table: "user_shop_favorite" */
  insert_user_shop_favorite_one?: Maybe<User_Shop_Favorite>
  /** insert data into the table: "user_shopping_preferences" */
  insert_user_shopping_preferences?: Maybe<User_Shopping_Preferences_Mutation_Response>
  /** insert a single row into the table: "user_shopping_preferences" */
  insert_user_shopping_preferences_one?: Maybe<User_Shopping_Preferences>
  /** insert data into the table: "user_subscription" */
  insert_user_subscription?: Maybe<User_Subscription_Mutation_Response>
  /** insert a single row into the table: "user_subscription" */
  insert_user_subscription_one?: Maybe<User_Subscription>
  /** insert data into the table: "user_subscription_status" */
  insert_user_subscription_status?: Maybe<User_Subscription_Status_Mutation_Response>
  /** insert a single row into the table: "user_subscription_status" */
  insert_user_subscription_status_one?: Maybe<User_Subscription_Status>
  /** insert data into the table: "user_temp" */
  insert_user_temp?: Maybe<User_Temp_Mutation_Response>
  /** insert a single row into the table: "user_temp" */
  insert_user_temp_one?: Maybe<User_Temp>
  /** insert data into the table: "worflow_type" */
  insert_worflow_type?: Maybe<Worflow_Type_Mutation_Response>
  /** insert a single row into the table: "worflow_type" */
  insert_worflow_type_one?: Maybe<Worflow_Type>
  /** insert data into the table: "workflow" */
  insert_workflow?: Maybe<Workflow_Mutation_Response>
  /** insert a single row into the table: "workflow" */
  insert_workflow_one?: Maybe<Workflow>
  /** insert data into the table: "workflow_status" */
  insert_workflow_status?: Maybe<Workflow_Status_Mutation_Response>
  /** insert a single row into the table: "workflow_status" */
  insert_workflow_status_one?: Maybe<Workflow_Status>
  /** This action is to be able to invite a collection collaborator via either their user id or via an email address */
  inviteCollaborator?: Maybe<CollectionInviteOutput>
  /** This action is to be able to like feed items by just passing in their item id */
  likeFeedItem?: Maybe<FeedItemLikeOutput>
  /** An action to follow a creator for free */
  subscribeToCreator: CreateSubscriptionOutput
  /** update data of the table: "account_deletion_reason" */
  update_account_deletion_reason?: Maybe<Account_Deletion_Reason_Mutation_Response>
  /** update single row of the table: "account_deletion_reason" */
  update_account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** update multiples rows of table: "account_deletion_reason" */
  update_account_deletion_reason_many?: Maybe<
    Array<Maybe<Account_Deletion_Reason_Mutation_Response>>
  >
  /** update data of the table: "affiliate" */
  update_affiliate?: Maybe<Affiliate_Mutation_Response>
  /** update data of the table: "affiliate_advertiser_id" */
  update_affiliate_advertiser_id?: Maybe<Affiliate_Advertiser_Id_Mutation_Response>
  /** update single row of the table: "affiliate_advertiser_id" */
  update_affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** update multiples rows of table: "affiliate_advertiser_id" */
  update_affiliate_advertiser_id_many?: Maybe<
    Array<Maybe<Affiliate_Advertiser_Id_Mutation_Response>>
  >
  /** update single row of the table: "affiliate" */
  update_affiliate_by_pk?: Maybe<Affiliate>
  /** update multiples rows of table: "affiliate" */
  update_affiliate_many?: Maybe<Array<Maybe<Affiliate_Mutation_Response>>>
  /** update data of the table: "affiliate_meta" */
  update_affiliate_meta?: Maybe<Affiliate_Meta_Mutation_Response>
  /** update single row of the table: "affiliate_meta" */
  update_affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** update multiples rows of table: "affiliate_meta" */
  update_affiliate_meta_many?: Maybe<
    Array<Maybe<Affiliate_Meta_Mutation_Response>>
  >
  /** update data of the table: "affiliate_transaction" */
  update_affiliate_transaction?: Maybe<Affiliate_Transaction_Mutation_Response>
  /** update single row of the table: "affiliate_transaction" */
  update_affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** update multiples rows of table: "affiliate_transaction" */
  update_affiliate_transaction_many?: Maybe<
    Array<Maybe<Affiliate_Transaction_Mutation_Response>>
  >
  /** update data of the table: "billing_mode" */
  update_billing_mode?: Maybe<Billing_Mode_Mutation_Response>
  /** update single row of the table: "billing_mode" */
  update_billing_mode_by_pk?: Maybe<Billing_Mode>
  /** update multiples rows of table: "billing_mode" */
  update_billing_mode_many?: Maybe<Array<Maybe<Billing_Mode_Mutation_Response>>>
  /** update data of the table: "billing_platform_connected_account" */
  update_billing_platform_connected_account?: Maybe<Billing_Platform_Connected_Account_Mutation_Response>
  /** update single row of the table: "billing_platform_connected_account" */
  update_billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** update multiples rows of table: "billing_platform_connected_account" */
  update_billing_platform_connected_account_many?: Maybe<
    Array<Maybe<Billing_Platform_Connected_Account_Mutation_Response>>
  >
  /** update data of the table: "billing_platform_connected_account_status" */
  update_billing_platform_connected_account_status?: Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>
  /** update single row of the table: "billing_platform_connected_account_status" */
  update_billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** update multiples rows of table: "billing_platform_connected_account_status" */
  update_billing_platform_connected_account_status_many?: Maybe<
    Array<Maybe<Billing_Platform_Connected_Account_Status_Mutation_Response>>
  >
  /** update data of the table: "billing_platform_customer" */
  update_billing_platform_customer?: Maybe<Billing_Platform_Customer_Mutation_Response>
  /** update single row of the table: "billing_platform_customer" */
  update_billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** update multiples rows of table: "billing_platform_customer" */
  update_billing_platform_customer_many?: Maybe<
    Array<Maybe<Billing_Platform_Customer_Mutation_Response>>
  >
  /** update data of the table: "cart" */
  update_cart?: Maybe<Cart_Mutation_Response>
  /** update single row of the table: "cart" */
  update_cart_by_pk?: Maybe<Cart>
  /** update data of the table: "cart_item" */
  update_cart_item?: Maybe<Cart_Item_Mutation_Response>
  /** update single row of the table: "cart_item" */
  update_cart_item_by_pk?: Maybe<Cart_Item>
  /** update data of the table: "cart_item_like" */
  update_cart_item_like?: Maybe<Cart_Item_Like_Mutation_Response>
  /** update single row of the table: "cart_item_like" */
  update_cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** update multiples rows of table: "cart_item_like" */
  update_cart_item_like_many?: Maybe<
    Array<Maybe<Cart_Item_Like_Mutation_Response>>
  >
  /** update multiples rows of table: "cart_item" */
  update_cart_item_many?: Maybe<Array<Maybe<Cart_Item_Mutation_Response>>>
  /** update data of the table: "cart_item_note" */
  update_cart_item_note?: Maybe<Cart_Item_Note_Mutation_Response>
  /** update single row of the table: "cart_item_note" */
  update_cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** update multiples rows of table: "cart_item_note" */
  update_cart_item_note_many?: Maybe<
    Array<Maybe<Cart_Item_Note_Mutation_Response>>
  >
  /** update multiples rows of table: "cart" */
  update_cart_many?: Maybe<Array<Maybe<Cart_Mutation_Response>>>
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>
  /** update data of the table: "category_shop_item" */
  update_category_shop_item?: Maybe<Category_Shop_Item_Mutation_Response>
  /** update single row of the table: "category_shop_item" */
  update_category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** update multiples rows of table: "category_shop_item" */
  update_category_shop_item_many?: Maybe<
    Array<Maybe<Category_Shop_Item_Mutation_Response>>
  >
  /** update data of the table: "checkout" */
  update_checkout?: Maybe<Checkout_Mutation_Response>
  /** update single row of the table: "checkout" */
  update_checkout_by_pk?: Maybe<Checkout>
  /** update multiples rows of table: "checkout" */
  update_checkout_many?: Maybe<Array<Maybe<Checkout_Mutation_Response>>>
  /** update data of the table: "collection" */
  update_collection?: Maybe<Collection_Mutation_Response>
  /** update single row of the table: "collection" */
  update_collection_by_pk?: Maybe<Collection>
  /** update data of the table: "collection_cart_item" */
  update_collection_cart_item?: Maybe<Collection_Cart_Item_Mutation_Response>
  /** update single row of the table: "collection_cart_item" */
  update_collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** update multiples rows of table: "collection_cart_item" */
  update_collection_cart_item_many?: Maybe<
    Array<Maybe<Collection_Cart_Item_Mutation_Response>>
  >
  /** update data of the table: "collection_collaborator" */
  update_collection_collaborator?: Maybe<Collection_Collaborator_Mutation_Response>
  /** update single row of the table: "collection_collaborator" */
  update_collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** update multiples rows of table: "collection_collaborator" */
  update_collection_collaborator_many?: Maybe<
    Array<Maybe<Collection_Collaborator_Mutation_Response>>
  >
  /** update data of the table: "collection_invite" */
  update_collection_invite?: Maybe<Collection_Invite_Mutation_Response>
  /** update single row of the table: "collection_invite" */
  update_collection_invite_by_pk?: Maybe<Collection_Invite>
  /** update multiples rows of table: "collection_invite" */
  update_collection_invite_many?: Maybe<
    Array<Maybe<Collection_Invite_Mutation_Response>>
  >
  /** update data of the table: "collection_invite_status" */
  update_collection_invite_status?: Maybe<Collection_Invite_Status_Mutation_Response>
  /** update single row of the table: "collection_invite_status" */
  update_collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** update multiples rows of table: "collection_invite_status" */
  update_collection_invite_status_many?: Maybe<
    Array<Maybe<Collection_Invite_Status_Mutation_Response>>
  >
  /** update data of the table: "collection_item_claim" */
  update_collection_item_claim?: Maybe<Collection_Item_Claim_Mutation_Response>
  /** update single row of the table: "collection_item_claim" */
  update_collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** update multiples rows of table: "collection_item_claim" */
  update_collection_item_claim_many?: Maybe<
    Array<Maybe<Collection_Item_Claim_Mutation_Response>>
  >
  /** update data of the table: "collection_item_claim_status" */
  update_collection_item_claim_status?: Maybe<Collection_Item_Claim_Status_Mutation_Response>
  /** update single row of the table: "collection_item_claim_status" */
  update_collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** update multiples rows of table: "collection_item_claim_status" */
  update_collection_item_claim_status_many?: Maybe<
    Array<Maybe<Collection_Item_Claim_Status_Mutation_Response>>
  >
  /** update multiples rows of table: "collection" */
  update_collection_many?: Maybe<Array<Maybe<Collection_Mutation_Response>>>
  /** update data of the table: "collection_section" */
  update_collection_section?: Maybe<Collection_Section_Mutation_Response>
  /** update single row of the table: "collection_section" */
  update_collection_section_by_pk?: Maybe<Collection_Section>
  /** update data of the table: "collection_section_cart_item" */
  update_collection_section_cart_item?: Maybe<Collection_Section_Cart_Item_Mutation_Response>
  /** update single row of the table: "collection_section_cart_item" */
  update_collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** update multiples rows of table: "collection_section_cart_item" */
  update_collection_section_cart_item_many?: Maybe<
    Array<Maybe<Collection_Section_Cart_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "collection_section" */
  update_collection_section_many?: Maybe<
    Array<Maybe<Collection_Section_Mutation_Response>>
  >
  /** update data of the table: "collection_slug_history" */
  update_collection_slug_history?: Maybe<Collection_Slug_History_Mutation_Response>
  /** update single row of the table: "collection_slug_history" */
  update_collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** update multiples rows of table: "collection_slug_history" */
  update_collection_slug_history_many?: Maybe<
    Array<Maybe<Collection_Slug_History_Mutation_Response>>
  >
  /** update data of the table: "collection_state" */
  update_collection_state?: Maybe<Collection_State_Mutation_Response>
  /** update single row of the table: "collection_state" */
  update_collection_state_by_pk?: Maybe<Collection_State>
  /** update multiples rows of table: "collection_state" */
  update_collection_state_many?: Maybe<
    Array<Maybe<Collection_State_Mutation_Response>>
  >
  /** update data of the table: "collection_type" */
  update_collection_type?: Maybe<Collection_Type_Mutation_Response>
  /** update single row of the table: "collection_type" */
  update_collection_type_by_pk?: Maybe<Collection_Type>
  /** update multiples rows of table: "collection_type" */
  update_collection_type_many?: Maybe<
    Array<Maybe<Collection_Type_Mutation_Response>>
  >
  /** update data of the table: "comment" */
  update_comment?: Maybe<Comment_Mutation_Response>
  /** update single row of the table: "comment" */
  update_comment_by_pk?: Maybe<Comment>
  /** update multiples rows of table: "comment" */
  update_comment_many?: Maybe<Array<Maybe<Comment_Mutation_Response>>>
  /** update data of the table: "commentable_item" */
  update_commentable_item?: Maybe<Commentable_Item_Mutation_Response>
  /** update single row of the table: "commentable_item" */
  update_commentable_item_by_pk?: Maybe<Commentable_Item>
  /** update multiples rows of table: "commentable_item" */
  update_commentable_item_many?: Maybe<
    Array<Maybe<Commentable_Item_Mutation_Response>>
  >
  /** update data of the table: "commentable_item_type" */
  update_commentable_item_type?: Maybe<Commentable_Item_Type_Mutation_Response>
  /** update single row of the table: "commentable_item_type" */
  update_commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** update multiples rows of table: "commentable_item_type" */
  update_commentable_item_type_many?: Maybe<
    Array<Maybe<Commentable_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "communication_template" */
  update_communication_template?: Maybe<Communication_Template_Mutation_Response>
  /** update single row of the table: "communication_template" */
  update_communication_template_by_pk?: Maybe<Communication_Template>
  /** update data of the table: "communication_template_full_list" */
  update_communication_template_full_list?: Maybe<Communication_Template_Full_List_Mutation_Response>
  /** update single row of the table: "communication_template_full_list" */
  update_communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  /** update multiples rows of table: "communication_template_full_list" */
  update_communication_template_full_list_many?: Maybe<
    Array<Maybe<Communication_Template_Full_List_Mutation_Response>>
  >
  /** update multiples rows of table: "communication_template" */
  update_communication_template_many?: Maybe<
    Array<Maybe<Communication_Template_Mutation_Response>>
  >
  /** update data of the table: "contact_info_type" */
  update_contact_info_type?: Maybe<Contact_Info_Type_Mutation_Response>
  /** update single row of the table: "contact_info_type" */
  update_contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** update multiples rows of table: "contact_info_type" */
  update_contact_info_type_many?: Maybe<
    Array<Maybe<Contact_Info_Type_Mutation_Response>>
  >
  /** update data of the table: "contact_invite" */
  update_contact_invite?: Maybe<Contact_Invite_Mutation_Response>
  /** update single row of the table: "contact_invite" */
  update_contact_invite_by_pk?: Maybe<Contact_Invite>
  /** update multiples rows of table: "contact_invite" */
  update_contact_invite_many?: Maybe<
    Array<Maybe<Contact_Invite_Mutation_Response>>
  >
  /** update data of the table: "contact_invite_status" */
  update_contact_invite_status?: Maybe<Contact_Invite_Status_Mutation_Response>
  /** update single row of the table: "contact_invite_status" */
  update_contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** update multiples rows of table: "contact_invite_status" */
  update_contact_invite_status_many?: Maybe<
    Array<Maybe<Contact_Invite_Status_Mutation_Response>>
  >
  /** update data of the table: "contact_invite_type" */
  update_contact_invite_type?: Maybe<Contact_Invite_Type_Mutation_Response>
  /** update single row of the table: "contact_invite_type" */
  update_contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** update multiples rows of table: "contact_invite_type" */
  update_contact_invite_type_many?: Maybe<
    Array<Maybe<Contact_Invite_Type_Mutation_Response>>
  >
  /** update data of the table: "deal_hop_gate" */
  update_deal_hop_gate?: Maybe<Deal_Hop_Gate_Mutation_Response>
  /** update single row of the table: "deal_hop_gate" */
  update_deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** update multiples rows of table: "deal_hop_gate" */
  update_deal_hop_gate_many?: Maybe<
    Array<Maybe<Deal_Hop_Gate_Mutation_Response>>
  >
  /** update data of the table: "device_client_data" */
  update_device_client_data?: Maybe<Device_Client_Data_Mutation_Response>
  /** update single row of the table: "device_client_data" */
  update_device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** update multiples rows of table: "device_client_data" */
  update_device_client_data_many?: Maybe<
    Array<Maybe<Device_Client_Data_Mutation_Response>>
  >
  /** update data of the table: "discovery_backlog" */
  update_discovery_backlog?: Maybe<Discovery_Backlog_Mutation_Response>
  /** update single row of the table: "discovery_backlog" */
  update_discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** update multiples rows of table: "discovery_backlog" */
  update_discovery_backlog_many?: Maybe<
    Array<Maybe<Discovery_Backlog_Mutation_Response>>
  >
  /** update data of the table: "discovery_feed" */
  update_discovery_feed?: Maybe<Discovery_Feed_Mutation_Response>
  /** update single row of the table: "discovery_feed" */
  update_discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** update multiples rows of table: "discovery_feed" */
  update_discovery_feed_many?: Maybe<
    Array<Maybe<Discovery_Feed_Mutation_Response>>
  >
  /** update data of the table: "discovery_staging" */
  update_discovery_staging?: Maybe<Discovery_Staging_Mutation_Response>
  /** update single row of the table: "discovery_staging" */
  update_discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** update multiples rows of table: "discovery_staging" */
  update_discovery_staging_many?: Maybe<
    Array<Maybe<Discovery_Staging_Mutation_Response>>
  >
  /** update data of the table: "featured_shop" */
  update_featured_shop?: Maybe<Featured_Shop_Mutation_Response>
  /** update single row of the table: "featured_shop" */
  update_featured_shop_by_pk?: Maybe<Featured_Shop>
  /** update data of the table: "featured_shop_item" */
  update_featured_shop_item?: Maybe<Featured_Shop_Item_Mutation_Response>
  /** update single row of the table: "featured_shop_item" */
  update_featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** update multiples rows of table: "featured_shop_item" */
  update_featured_shop_item_many?: Maybe<
    Array<Maybe<Featured_Shop_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "featured_shop" */
  update_featured_shop_many?: Maybe<
    Array<Maybe<Featured_Shop_Mutation_Response>>
  >
  /** update data of the table: "feed_item" */
  update_feed_item?: Maybe<Feed_Item_Mutation_Response>
  /** update single row of the table: "feed_item" */
  update_feed_item_by_pk?: Maybe<Feed_Item>
  /** update data of the table: "feed_item_like" */
  update_feed_item_like?: Maybe<Feed_Item_Like_Mutation_Response>
  /** update single row of the table: "feed_item_like" */
  update_feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** update multiples rows of table: "feed_item_like" */
  update_feed_item_like_many?: Maybe<
    Array<Maybe<Feed_Item_Like_Mutation_Response>>
  >
  /** update multiples rows of table: "feed_item" */
  update_feed_item_many?: Maybe<Array<Maybe<Feed_Item_Mutation_Response>>>
  /** update data of the table: "feed_item_type" */
  update_feed_item_type?: Maybe<Feed_Item_Type_Mutation_Response>
  /** update single row of the table: "feed_item_type" */
  update_feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** update multiples rows of table: "feed_item_type" */
  update_feed_item_type_many?: Maybe<
    Array<Maybe<Feed_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "fmtc_deal" */
  update_fmtc_deal?: Maybe<Fmtc_Deal_Mutation_Response>
  /** update single row of the table: "fmtc_deal" */
  update_fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** update multiples rows of table: "fmtc_deal" */
  update_fmtc_deal_many?: Maybe<Array<Maybe<Fmtc_Deal_Mutation_Response>>>
  /** update data of the table: "followable_item" */
  update_followable_item?: Maybe<Followable_Item_Mutation_Response>
  /** update single row of the table: "followable_item" */
  update_followable_item_by_pk?: Maybe<Followable_Item>
  /** update multiples rows of table: "followable_item" */
  update_followable_item_many?: Maybe<
    Array<Maybe<Followable_Item_Mutation_Response>>
  >
  /** update data of the table: "followable_item_type" */
  update_followable_item_type?: Maybe<Followable_Item_Type_Mutation_Response>
  /** update single row of the table: "followable_item_type" */
  update_followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** update multiples rows of table: "followable_item_type" */
  update_followable_item_type_many?: Maybe<
    Array<Maybe<Followable_Item_Type_Mutation_Response>>
  >
  /** execute VOLATILE function "update_last_seen_notification_center_item_sequential_id" which returns "user_flags" */
  update_last_seen_notification_center_item_sequential_id: Array<User_Flags>
  /** update data of the table: "leaderboard_event" */
  update_leaderboard_event?: Maybe<Leaderboard_Event_Mutation_Response>
  /** update single row of the table: "leaderboard_event" */
  update_leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** update multiples rows of table: "leaderboard_event" */
  update_leaderboard_event_many?: Maybe<
    Array<Maybe<Leaderboard_Event_Mutation_Response>>
  >
  /** update data of the table: "leaderboard_event_name" */
  update_leaderboard_event_name?: Maybe<Leaderboard_Event_Name_Mutation_Response>
  /** update single row of the table: "leaderboard_event_name" */
  update_leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** update multiples rows of table: "leaderboard_event_name" */
  update_leaderboard_event_name_many?: Maybe<
    Array<Maybe<Leaderboard_Event_Name_Mutation_Response>>
  >
  /** update data of the table: "link_type" */
  update_link_type?: Maybe<Link_Type_Mutation_Response>
  /** update single row of the table: "link_type" */
  update_link_type_by_pk?: Maybe<Link_Type>
  /** update multiples rows of table: "link_type" */
  update_link_type_many?: Maybe<Array<Maybe<Link_Type_Mutation_Response>>>
  /** update data of the table: "media_enabled_item" */
  update_media_enabled_item?: Maybe<Media_Enabled_Item_Mutation_Response>
  /** update single row of the table: "media_enabled_item" */
  update_media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** update multiples rows of table: "media_enabled_item" */
  update_media_enabled_item_many?: Maybe<
    Array<Maybe<Media_Enabled_Item_Mutation_Response>>
  >
  /** update data of the table: "media_item" */
  update_media_item?: Maybe<Media_Item_Mutation_Response>
  /** update single row of the table: "media_item" */
  update_media_item_by_pk?: Maybe<Media_Item>
  /** update multiples rows of table: "media_item" */
  update_media_item_many?: Maybe<Array<Maybe<Media_Item_Mutation_Response>>>
  /** update data of the table: "media_item_type" */
  update_media_item_type?: Maybe<Media_Item_Type_Mutation_Response>
  /** update single row of the table: "media_item_type" */
  update_media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** update multiples rows of table: "media_item_type" */
  update_media_item_type_many?: Maybe<
    Array<Maybe<Media_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "media_items" */
  update_media_items?: Maybe<Media_Items_Mutation_Response>
  /** update single row of the table: "media_items" */
  update_media_items_by_pk?: Maybe<Media_Items>
  /** update multiples rows of table: "media_items" */
  update_media_items_many?: Maybe<Array<Maybe<Media_Items_Mutation_Response>>>
  /** update data of the table: "media_type" */
  update_media_type?: Maybe<Media_Type_Mutation_Response>
  /** update single row of the table: "media_type" */
  update_media_type_by_pk?: Maybe<Media_Type>
  /** update multiples rows of table: "media_type" */
  update_media_type_many?: Maybe<Array<Maybe<Media_Type_Mutation_Response>>>
  /** update data of the table: "mobile_app_home" */
  update_mobile_app_home?: Maybe<Mobile_App_Home_Mutation_Response>
  /** update single row of the table: "mobile_app_home" */
  update_mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** update multiples rows of table: "mobile_app_home" */
  update_mobile_app_home_many?: Maybe<
    Array<Maybe<Mobile_App_Home_Mutation_Response>>
  >
  /** update data of the table: "mobile_home" */
  update_mobile_home?: Maybe<Mobile_Home_Mutation_Response>
  /** update single row of the table: "mobile_home" */
  update_mobile_home_by_pk?: Maybe<Mobile_Home>
  /** update multiples rows of table: "mobile_home" */
  update_mobile_home_many?: Maybe<Array<Maybe<Mobile_Home_Mutation_Response>>>
  /** update data of the table: "mobile_push_notification" */
  update_mobile_push_notification?: Maybe<Mobile_Push_Notification_Mutation_Response>
  /** update single row of the table: "mobile_push_notification" */
  update_mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** update multiples rows of table: "mobile_push_notification" */
  update_mobile_push_notification_many?: Maybe<
    Array<Maybe<Mobile_Push_Notification_Mutation_Response>>
  >
  /** update data of the table: "mobile_push_notification_status" */
  update_mobile_push_notification_status?: Maybe<Mobile_Push_Notification_Status_Mutation_Response>
  /** update single row of the table: "mobile_push_notification_status" */
  update_mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** update multiples rows of table: "mobile_push_notification_status" */
  update_mobile_push_notification_status_many?: Maybe<
    Array<Maybe<Mobile_Push_Notification_Status_Mutation_Response>>
  >
  /** update data of the table: "notifiable_item" */
  update_notifiable_item?: Maybe<Notifiable_Item_Mutation_Response>
  /** update single row of the table: "notifiable_item" */
  update_notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** update multiples rows of table: "notifiable_item" */
  update_notifiable_item_many?: Maybe<
    Array<Maybe<Notifiable_Item_Mutation_Response>>
  >
  /** update data of the table: "notifiable_item_type" */
  update_notifiable_item_type?: Maybe<Notifiable_Item_Type_Mutation_Response>
  /** update single row of the table: "notifiable_item_type" */
  update_notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** update multiples rows of table: "notifiable_item_type" */
  update_notifiable_item_type_many?: Maybe<
    Array<Maybe<Notifiable_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "notification" */
  update_notification?: Maybe<Notification_Mutation_Response>
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>
  /** update data of the table: "notification_center_item" */
  update_notification_center_item?: Maybe<Notification_Center_Item_Mutation_Response>
  /** update single row of the table: "notification_center_item" */
  update_notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** update multiples rows of table: "notification_center_item" */
  update_notification_center_item_many?: Maybe<
    Array<Maybe<Notification_Center_Item_Mutation_Response>>
  >
  /** update data of the table: "notification_center_item_read_status" */
  update_notification_center_item_read_status?: Maybe<Notification_Center_Item_Read_Status_Mutation_Response>
  /** update single row of the table: "notification_center_item_read_status" */
  update_notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** update multiples rows of table: "notification_center_item_read_status" */
  update_notification_center_item_read_status_many?: Maybe<
    Array<Maybe<Notification_Center_Item_Read_Status_Mutation_Response>>
  >
  /** update data of the table: "notification_center_item_type" */
  update_notification_center_item_type?: Maybe<Notification_Center_Item_Type_Mutation_Response>
  /** update single row of the table: "notification_center_item_type" */
  update_notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** update multiples rows of table: "notification_center_item_type" */
  update_notification_center_item_type_many?: Maybe<
    Array<Maybe<Notification_Center_Item_Type_Mutation_Response>>
  >
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<Array<Maybe<Notification_Mutation_Response>>>
  /** update data of the table: "notification_preference" */
  update_notification_preference?: Maybe<Notification_Preference_Mutation_Response>
  /** update single row of the table: "notification_preference" */
  update_notification_preference_by_pk?: Maybe<Notification_Preference>
  /** update multiples rows of table: "notification_preference" */
  update_notification_preference_many?: Maybe<
    Array<Maybe<Notification_Preference_Mutation_Response>>
  >
  /** update data of the table: "notification_status" */
  update_notification_status?: Maybe<Notification_Status_Mutation_Response>
  /** update single row of the table: "notification_status" */
  update_notification_status_by_pk?: Maybe<Notification_Status>
  /** update multiples rows of table: "notification_status" */
  update_notification_status_many?: Maybe<
    Array<Maybe<Notification_Status_Mutation_Response>>
  >
  /** update data of the table: "onboarding_categories" */
  update_onboarding_categories?: Maybe<Onboarding_Categories_Mutation_Response>
  /** update single row of the table: "onboarding_categories" */
  update_onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** update multiples rows of table: "onboarding_categories" */
  update_onboarding_categories_many?: Maybe<
    Array<Maybe<Onboarding_Categories_Mutation_Response>>
  >
  /** update data of the table: "onboarding_sources" */
  update_onboarding_sources?: Maybe<Onboarding_Sources_Mutation_Response>
  /** update single row of the table: "onboarding_sources" */
  update_onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** update multiples rows of table: "onboarding_sources" */
  update_onboarding_sources_many?: Maybe<
    Array<Maybe<Onboarding_Sources_Mutation_Response>>
  >
  /** update data of the table: "one_off_email" */
  update_one_off_email?: Maybe<One_Off_Email_Mutation_Response>
  /** update single row of the table: "one_off_email" */
  update_one_off_email_by_pk?: Maybe<One_Off_Email>
  /** update multiples rows of table: "one_off_email" */
  update_one_off_email_many?: Maybe<
    Array<Maybe<One_Off_Email_Mutation_Response>>
  >
  /** update data of the table: "pageview_event" */
  update_pageview_event?: Maybe<Pageview_Event_Mutation_Response>
  /** update single row of the table: "pageview_event" */
  update_pageview_event_by_pk?: Maybe<Pageview_Event>
  /** update multiples rows of table: "pageview_event" */
  update_pageview_event_many?: Maybe<
    Array<Maybe<Pageview_Event_Mutation_Response>>
  >
  /** update data of the table: "promo_period" */
  update_promo_period?: Maybe<Promo_Period_Mutation_Response>
  /** update single row of the table: "promo_period" */
  update_promo_period_by_pk?: Maybe<Promo_Period>
  /** update multiples rows of table: "promo_period" */
  update_promo_period_many?: Maybe<Array<Maybe<Promo_Period_Mutation_Response>>>
  /** update data of the table: "push_notification" */
  update_push_notification?: Maybe<Push_Notification_Mutation_Response>
  /** update single row of the table: "push_notification" */
  update_push_notification_by_pk?: Maybe<Push_Notification>
  /** update multiples rows of table: "push_notification" */
  update_push_notification_many?: Maybe<
    Array<Maybe<Push_Notification_Mutation_Response>>
  >
  /** update data of the table: "push_notification_status" */
  update_push_notification_status?: Maybe<Push_Notification_Status_Mutation_Response>
  /** update single row of the table: "push_notification_status" */
  update_push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** update multiples rows of table: "push_notification_status" */
  update_push_notification_status_many?: Maybe<
    Array<Maybe<Push_Notification_Status_Mutation_Response>>
  >
  /** update data of the table: "redirect_ids" */
  update_redirect_ids?: Maybe<Redirect_Ids_Mutation_Response>
  /** update single row of the table: "redirect_ids" */
  update_redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** update multiples rows of table: "redirect_ids" */
  update_redirect_ids_many?: Maybe<Array<Maybe<Redirect_Ids_Mutation_Response>>>
  /** update data of the table: "remove_reason" */
  update_remove_reason?: Maybe<Remove_Reason_Mutation_Response>
  /** update single row of the table: "remove_reason" */
  update_remove_reason_by_pk?: Maybe<Remove_Reason>
  /** update multiples rows of table: "remove_reason" */
  update_remove_reason_many?: Maybe<
    Array<Maybe<Remove_Reason_Mutation_Response>>
  >
  /** update data of the table: "rewardable_action_names" */
  update_rewardable_action_names?: Maybe<Rewardable_Action_Names_Mutation_Response>
  /** update single row of the table: "rewardable_action_names" */
  update_rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** update multiples rows of table: "rewardable_action_names" */
  update_rewardable_action_names_many?: Maybe<
    Array<Maybe<Rewardable_Action_Names_Mutation_Response>>
  >
  /** update data of the table: "rewardable_item_type" */
  update_rewardable_item_type?: Maybe<Rewardable_Item_Type_Mutation_Response>
  /** update single row of the table: "rewardable_item_type" */
  update_rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** update multiples rows of table: "rewardable_item_type" */
  update_rewardable_item_type_many?: Maybe<
    Array<Maybe<Rewardable_Item_Type_Mutation_Response>>
  >
  /** update data of the table: "search_query" */
  update_search_query?: Maybe<Search_Query_Mutation_Response>
  /** update single row of the table: "search_query" */
  update_search_query_by_pk?: Maybe<Search_Query>
  /** update multiples rows of table: "search_query" */
  update_search_query_many?: Maybe<Array<Maybe<Search_Query_Mutation_Response>>>
  /** update data of the table: "search_query_result" */
  update_search_query_result?: Maybe<Search_Query_Result_Mutation_Response>
  /** update single row of the table: "search_query_result" */
  update_search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** update multiples rows of table: "search_query_result" */
  update_search_query_result_many?: Maybe<
    Array<Maybe<Search_Query_Result_Mutation_Response>>
  >
  /** update data of the table: "shop" */
  update_shop?: Maybe<Shop_Mutation_Response>
  /** update data of the table: "shop_affiliate" */
  update_shop_affiliate?: Maybe<Shop_Affiliate_Mutation_Response>
  /** update single row of the table: "shop_affiliate" */
  update_shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** update multiples rows of table: "shop_affiliate" */
  update_shop_affiliate_many?: Maybe<
    Array<Maybe<Shop_Affiliate_Mutation_Response>>
  >
  /** update single row of the table: "shop" */
  update_shop_by_pk?: Maybe<Shop>
  /** update data of the table: "shop_categorization" */
  update_shop_categorization?: Maybe<Shop_Categorization_Mutation_Response>
  /** update single row of the table: "shop_categorization" */
  update_shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** update multiples rows of table: "shop_categorization" */
  update_shop_categorization_many?: Maybe<
    Array<Maybe<Shop_Categorization_Mutation_Response>>
  >
  /** update data of the table: "shop_category" */
  update_shop_category?: Maybe<Shop_Category_Mutation_Response>
  /** update single row of the table: "shop_category" */
  update_shop_category_by_pk?: Maybe<Shop_Category>
  /** update multiples rows of table: "shop_category" */
  update_shop_category_many?: Maybe<
    Array<Maybe<Shop_Category_Mutation_Response>>
  >
  /** update data of the table: "shop_item" */
  update_shop_item?: Maybe<Shop_Item_Mutation_Response>
  /** update data of the table: "shop_item_availability" */
  update_shop_item_availability?: Maybe<Shop_Item_Availability_Mutation_Response>
  /** update single row of the table: "shop_item_availability" */
  update_shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** update multiples rows of table: "shop_item_availability" */
  update_shop_item_availability_many?: Maybe<
    Array<Maybe<Shop_Item_Availability_Mutation_Response>>
  >
  /** update single row of the table: "shop_item" */
  update_shop_item_by_pk?: Maybe<Shop_Item>
  /** update data of the table: "shop_item_enrichment_status" */
  update_shop_item_enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Mutation_Response>
  /** update single row of the table: "shop_item_enrichment_status" */
  update_shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** update multiples rows of table: "shop_item_enrichment_status" */
  update_shop_item_enrichment_status_many?: Maybe<
    Array<Maybe<Shop_Item_Enrichment_Status_Mutation_Response>>
  >
  /** update data of the table: "shop_item_history" */
  update_shop_item_history?: Maybe<Shop_Item_History_Mutation_Response>
  /** update single row of the table: "shop_item_history" */
  update_shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** update multiples rows of table: "shop_item_history" */
  update_shop_item_history_many?: Maybe<
    Array<Maybe<Shop_Item_History_Mutation_Response>>
  >
  /** update data of the table: "shop_item_image" */
  update_shop_item_image?: Maybe<Shop_Item_Image_Mutation_Response>
  /** update single row of the table: "shop_item_image" */
  update_shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** update multiples rows of table: "shop_item_image" */
  update_shop_item_image_many?: Maybe<
    Array<Maybe<Shop_Item_Image_Mutation_Response>>
  >
  /** update data of the table: "shop_item_index" */
  update_shop_item_index?: Maybe<Shop_Item_Index_Mutation_Response>
  /** update single row of the table: "shop_item_index" */
  update_shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** update multiples rows of table: "shop_item_index" */
  update_shop_item_index_many?: Maybe<
    Array<Maybe<Shop_Item_Index_Mutation_Response>>
  >
  /** update multiples rows of table: "shop_item" */
  update_shop_item_many?: Maybe<Array<Maybe<Shop_Item_Mutation_Response>>>
  /** update data of the table: "shop_item_to_be_scraped" */
  update_shop_item_to_be_scraped?: Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>
  /** update multiples rows of table: "shop_item_to_be_scraped" */
  update_shop_item_to_be_scraped_many?: Maybe<
    Array<Maybe<Shop_Item_To_Be_Scraped_Mutation_Response>>
  >
  /** update multiples rows of table: "shop" */
  update_shop_many?: Maybe<Array<Maybe<Shop_Mutation_Response>>>
  /** update data of the table: "shop_rating" */
  update_shop_rating?: Maybe<Shop_Rating_Mutation_Response>
  /** update single row of the table: "shop_rating" */
  update_shop_rating_by_pk?: Maybe<Shop_Rating>
  /** update multiples rows of table: "shop_rating" */
  update_shop_rating_many?: Maybe<Array<Maybe<Shop_Rating_Mutation_Response>>>
  /** update data of the table: "similar_products_history" */
  update_similar_products_history?: Maybe<Similar_Products_History_Mutation_Response>
  /** update single row of the table: "similar_products_history" */
  update_similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** update multiples rows of table: "similar_products_history" */
  update_similar_products_history_many?: Maybe<
    Array<Maybe<Similar_Products_History_Mutation_Response>>
  >
  /** update data of the table: "user" */
  update_user?: Maybe<User_Mutation_Response>
  /** update data of the table: "user_account_type" */
  update_user_account_type?: Maybe<User_Account_Type_Mutation_Response>
  /** update single row of the table: "user_account_type" */
  update_user_account_type_by_pk?: Maybe<User_Account_Type>
  /** update multiples rows of table: "user_account_type" */
  update_user_account_type_many?: Maybe<
    Array<Maybe<User_Account_Type_Mutation_Response>>
  >
  /** update data of the table: "user_action" */
  update_user_action?: Maybe<User_Action_Mutation_Response>
  /** update single row of the table: "user_action" */
  update_user_action_by_pk?: Maybe<User_Action>
  /** update multiples rows of table: "user_action" */
  update_user_action_many?: Maybe<Array<Maybe<User_Action_Mutation_Response>>>
  /** update data of the table: "user_app_version" */
  update_user_app_version?: Maybe<User_App_Version_Mutation_Response>
  /** update single row of the table: "user_app_version" */
  update_user_app_version_by_pk?: Maybe<User_App_Version>
  /** update multiples rows of table: "user_app_version" */
  update_user_app_version_many?: Maybe<
    Array<Maybe<User_App_Version_Mutation_Response>>
  >
  /** update single row of the table: "user" */
  update_user_by_pk?: Maybe<User>
  /** update data of the table: "user_carts_onboarding" */
  update_user_carts_onboarding?: Maybe<User_Carts_Onboarding_Mutation_Response>
  /** update single row of the table: "user_carts_onboarding" */
  update_user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** update multiples rows of table: "user_carts_onboarding" */
  update_user_carts_onboarding_many?: Maybe<
    Array<Maybe<User_Carts_Onboarding_Mutation_Response>>
  >
  /** update data of the table: "user_category" */
  update_user_category?: Maybe<User_Category_Mutation_Response>
  /** update single row of the table: "user_category" */
  update_user_category_by_pk?: Maybe<User_Category>
  /** update multiples rows of table: "user_category" */
  update_user_category_many?: Maybe<
    Array<Maybe<User_Category_Mutation_Response>>
  >
  /** update data of the table: "user_deletion" */
  update_user_deletion?: Maybe<User_Deletion_Mutation_Response>
  /** update single row of the table: "user_deletion" */
  update_user_deletion_by_pk?: Maybe<User_Deletion>
  /** update multiples rows of table: "user_deletion" */
  update_user_deletion_many?: Maybe<
    Array<Maybe<User_Deletion_Mutation_Response>>
  >
  /** update data of the table: "user_feedback" */
  update_user_feedback?: Maybe<User_Feedback_Mutation_Response>
  /** update single row of the table: "user_feedback" */
  update_user_feedback_by_pk?: Maybe<User_Feedback>
  /** update data of the table: "user_feedback_item" */
  update_user_feedback_item?: Maybe<User_Feedback_Item_Mutation_Response>
  /** update single row of the table: "user_feedback_item" */
  update_user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** update multiples rows of table: "user_feedback_item" */
  update_user_feedback_item_many?: Maybe<
    Array<Maybe<User_Feedback_Item_Mutation_Response>>
  >
  /** update multiples rows of table: "user_feedback" */
  update_user_feedback_many?: Maybe<
    Array<Maybe<User_Feedback_Mutation_Response>>
  >
  /** update data of the table: "user_feedback_type" */
  update_user_feedback_type?: Maybe<User_Feedback_Type_Mutation_Response>
  /** update single row of the table: "user_feedback_type" */
  update_user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** update multiples rows of table: "user_feedback_type" */
  update_user_feedback_type_many?: Maybe<
    Array<Maybe<User_Feedback_Type_Mutation_Response>>
  >
  /** update data of the table: "user_flags" */
  update_user_flags?: Maybe<User_Flags_Mutation_Response>
  /** update single row of the table: "user_flags" */
  update_user_flags_by_pk?: Maybe<User_Flags>
  /** update multiples rows of table: "user_flags" */
  update_user_flags_many?: Maybe<Array<Maybe<User_Flags_Mutation_Response>>>
  /** update data of the table: "user_impersonation" */
  update_user_impersonation?: Maybe<User_Impersonation_Mutation_Response>
  /** update single row of the table: "user_impersonation" */
  update_user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** update multiples rows of table: "user_impersonation" */
  update_user_impersonation_many?: Maybe<
    Array<Maybe<User_Impersonation_Mutation_Response>>
  >
  /** update data of the table: "user_ip_address" */
  update_user_ip_address?: Maybe<User_Ip_Address_Mutation_Response>
  /** update single row of the table: "user_ip_address" */
  update_user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** update multiples rows of table: "user_ip_address" */
  update_user_ip_address_many?: Maybe<
    Array<Maybe<User_Ip_Address_Mutation_Response>>
  >
  /** update data of the table: "user_items_followed" */
  update_user_items_followed?: Maybe<User_Items_Followed_Mutation_Response>
  /** update single row of the table: "user_items_followed" */
  update_user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** update multiples rows of table: "user_items_followed" */
  update_user_items_followed_many?: Maybe<
    Array<Maybe<User_Items_Followed_Mutation_Response>>
  >
  /** update data of the table: "user_links" */
  update_user_links?: Maybe<User_Links_Mutation_Response>
  /** update single row of the table: "user_links" */
  update_user_links_by_pk?: Maybe<User_Links>
  /** update multiples rows of table: "user_links" */
  update_user_links_many?: Maybe<Array<Maybe<User_Links_Mutation_Response>>>
  /** update multiples rows of table: "user" */
  update_user_many?: Maybe<Array<Maybe<User_Mutation_Response>>>
  /** update data of the table: "user_onboarding_source" */
  update_user_onboarding_source?: Maybe<User_Onboarding_Source_Mutation_Response>
  /** update single row of the table: "user_onboarding_source" */
  update_user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** update multiples rows of table: "user_onboarding_source" */
  update_user_onboarding_source_many?: Maybe<
    Array<Maybe<User_Onboarding_Source_Mutation_Response>>
  >
  /** update data of the table: "user_onboarding_state" */
  update_user_onboarding_state?: Maybe<User_Onboarding_State_Mutation_Response>
  /** update single row of the table: "user_onboarding_state" */
  update_user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** update multiples rows of table: "user_onboarding_state" */
  update_user_onboarding_state_many?: Maybe<
    Array<Maybe<User_Onboarding_State_Mutation_Response>>
  >
  /** update data of the table: "user_phone_contact" */
  update_user_phone_contact?: Maybe<User_Phone_Contact_Mutation_Response>
  /** update single row of the table: "user_phone_contact" */
  update_user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** update multiples rows of table: "user_phone_contact" */
  update_user_phone_contact_many?: Maybe<
    Array<Maybe<User_Phone_Contact_Mutation_Response>>
  >
  /** update data of the table: "user_purchase_subscription" */
  update_user_purchase_subscription?: Maybe<User_Purchase_Subscription_Mutation_Response>
  /** update single row of the table: "user_purchase_subscription" */
  update_user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** update multiples rows of table: "user_purchase_subscription" */
  update_user_purchase_subscription_many?: Maybe<
    Array<Maybe<User_Purchase_Subscription_Mutation_Response>>
  >
  /** update data of the table: "user_purchases" */
  update_user_purchases?: Maybe<User_Purchases_Mutation_Response>
  /** update single row of the table: "user_purchases" */
  update_user_purchases_by_pk?: Maybe<User_Purchases>
  /** update multiples rows of table: "user_purchases" */
  update_user_purchases_many?: Maybe<
    Array<Maybe<User_Purchases_Mutation_Response>>
  >
  /** update data of the table: "user_push_notification" */
  update_user_push_notification?: Maybe<User_Push_Notification_Mutation_Response>
  /** update multiples rows of table: "user_push_notification" */
  update_user_push_notification_many?: Maybe<
    Array<Maybe<User_Push_Notification_Mutation_Response>>
  >
  /** update data of the table: "user_push_notification_token" */
  update_user_push_notification_token?: Maybe<User_Push_Notification_Token_Mutation_Response>
  /** update single row of the table: "user_push_notification_token" */
  update_user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** update multiples rows of table: "user_push_notification_token" */
  update_user_push_notification_token_many?: Maybe<
    Array<Maybe<User_Push_Notification_Token_Mutation_Response>>
  >
  /** update data of the table: "user_referral" */
  update_user_referral?: Maybe<User_Referral_Mutation_Response>
  /** update single row of the table: "user_referral" */
  update_user_referral_by_pk?: Maybe<User_Referral>
  /** update multiples rows of table: "user_referral" */
  update_user_referral_many?: Maybe<
    Array<Maybe<User_Referral_Mutation_Response>>
  >
  /** update data of the table: "user_reward_multiplier_config" */
  update_user_reward_multiplier_config?: Maybe<User_Reward_Multiplier_Config_Mutation_Response>
  /** update single row of the table: "user_reward_multiplier_config" */
  update_user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** update multiples rows of table: "user_reward_multiplier_config" */
  update_user_reward_multiplier_config_many?: Maybe<
    Array<Maybe<User_Reward_Multiplier_Config_Mutation_Response>>
  >
  /** update data of the table: "user_reward_redemption" */
  update_user_reward_redemption?: Maybe<User_Reward_Redemption_Mutation_Response>
  /** update single row of the table: "user_reward_redemption" */
  update_user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** update multiples rows of table: "user_reward_redemption" */
  update_user_reward_redemption_many?: Maybe<
    Array<Maybe<User_Reward_Redemption_Mutation_Response>>
  >
  /** update data of the table: "user_reward_reference" */
  update_user_reward_reference?: Maybe<User_Reward_Reference_Mutation_Response>
  /** update single row of the table: "user_reward_reference" */
  update_user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** update multiples rows of table: "user_reward_reference" */
  update_user_reward_reference_many?: Maybe<
    Array<Maybe<User_Reward_Reference_Mutation_Response>>
  >
  /** update data of the table: "user_shop_favorite" */
  update_user_shop_favorite?: Maybe<User_Shop_Favorite_Mutation_Response>
  /** update single row of the table: "user_shop_favorite" */
  update_user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** update multiples rows of table: "user_shop_favorite" */
  update_user_shop_favorite_many?: Maybe<
    Array<Maybe<User_Shop_Favorite_Mutation_Response>>
  >
  /** update data of the table: "user_shopping_preferences" */
  update_user_shopping_preferences?: Maybe<User_Shopping_Preferences_Mutation_Response>
  /** update single row of the table: "user_shopping_preferences" */
  update_user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** update multiples rows of table: "user_shopping_preferences" */
  update_user_shopping_preferences_many?: Maybe<
    Array<Maybe<User_Shopping_Preferences_Mutation_Response>>
  >
  /** update data of the table: "user_subscription" */
  update_user_subscription?: Maybe<User_Subscription_Mutation_Response>
  /** update single row of the table: "user_subscription" */
  update_user_subscription_by_pk?: Maybe<User_Subscription>
  /** update multiples rows of table: "user_subscription" */
  update_user_subscription_many?: Maybe<
    Array<Maybe<User_Subscription_Mutation_Response>>
  >
  /** update data of the table: "user_subscription_status" */
  update_user_subscription_status?: Maybe<User_Subscription_Status_Mutation_Response>
  /** update single row of the table: "user_subscription_status" */
  update_user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** update multiples rows of table: "user_subscription_status" */
  update_user_subscription_status_many?: Maybe<
    Array<Maybe<User_Subscription_Status_Mutation_Response>>
  >
  /** update data of the table: "user_temp" */
  update_user_temp?: Maybe<User_Temp_Mutation_Response>
  /** update single row of the table: "user_temp" */
  update_user_temp_by_pk?: Maybe<User_Temp>
  /** update multiples rows of table: "user_temp" */
  update_user_temp_many?: Maybe<Array<Maybe<User_Temp_Mutation_Response>>>
  /** update data of the table: "worflow_type" */
  update_worflow_type?: Maybe<Worflow_Type_Mutation_Response>
  /** update single row of the table: "worflow_type" */
  update_worflow_type_by_pk?: Maybe<Worflow_Type>
  /** update multiples rows of table: "worflow_type" */
  update_worflow_type_many?: Maybe<Array<Maybe<Worflow_Type_Mutation_Response>>>
  /** update data of the table: "workflow" */
  update_workflow?: Maybe<Workflow_Mutation_Response>
  /** update single row of the table: "workflow" */
  update_workflow_by_pk?: Maybe<Workflow>
  /** update multiples rows of table: "workflow" */
  update_workflow_many?: Maybe<Array<Maybe<Workflow_Mutation_Response>>>
  /** update data of the table: "workflow_status" */
  update_workflow_status?: Maybe<Workflow_Status_Mutation_Response>
  /** update single row of the table: "workflow_status" */
  update_workflow_status_by_pk?: Maybe<Workflow_Status>
  /** update multiples rows of table: "workflow_status" */
  update_workflow_status_many?: Maybe<
    Array<Maybe<Workflow_Status_Mutation_Response>>
  >
  /** execute VOLATILE function "upsert_notification_preference" which returns "notification_preference" */
  upsert_notification_preference: Array<Notification_Preference>
}

/** mutation root */
export type Mutation_RootDelete_Account_Deletion_ReasonArgs = {
  where: Account_Deletion_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Account_Deletion_Reason_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_AffiliateArgs = {
  where: Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Advertiser_IdArgs = {
  where: Affiliate_Advertiser_Id_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Advertiser_Id_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_MetaArgs = {
  where: Affiliate_Meta_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Meta_By_PkArgs = {
  affiliate: Affiliate_Enum
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_TransactionArgs = {
  where: Affiliate_Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Affiliate_Transaction_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Billing_ModeArgs = {
  where: Billing_Mode_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Billing_Mode_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_AccountArgs = {
  where: Billing_Platform_Connected_Account_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_Account_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_Account_StatusArgs =
  {
    where: Billing_Platform_Connected_Account_Status_Bool_Exp
  }

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Connected_Account_Status_By_PkArgs =
  {
    value: Scalars['String']
  }

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_CustomerArgs = {
  where: Billing_Platform_Customer_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Billing_Platform_Customer_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_CartArgs = {
  where: Cart_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Cart_ItemArgs = {
  where: Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_LikeArgs = {
  where: Cart_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_Like_By_PkArgs = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_NoteArgs = {
  where: Cart_Item_Note_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Cart_Item_Note_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Category_Shop_ItemArgs = {
  where: Category_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Category_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CheckoutArgs = {
  where: Checkout_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Checkout_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_CollectionArgs = {
  where: Collection_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Cart_ItemArgs = {
  where: Collection_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_CollaboratorArgs = {
  where: Collection_Collaborator_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Collaborator_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_InviteArgs = {
  where: Collection_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Invite_StatusArgs = {
  where: Collection_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_ClaimArgs = {
  where: Collection_Item_Claim_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_Claim_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_Claim_StatusArgs = {
  where: Collection_Item_Claim_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Item_Claim_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Collection_SectionArgs = {
  where: Collection_Section_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Section_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Section_Cart_ItemArgs = {
  where: Collection_Section_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Section_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Collection_Slug_HistoryArgs = {
  where: Collection_Slug_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Slug_History_By_PkArgs = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

/** mutation root */
export type Mutation_RootDelete_Collection_StateArgs = {
  where: Collection_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_State_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Collection_TypeArgs = {
  where: Collection_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Collection_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_CommentArgs = {
  where: Comment_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Comment_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Commentable_ItemArgs = {
  where: Commentable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Commentable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Commentable_Item_TypeArgs = {
  where: Commentable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Commentable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Communication_TemplateArgs = {
  where: Communication_Template_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_Template_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Communication_Template_Full_ListArgs = {
  where: Communication_Template_Full_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Communication_Template_Full_List_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Contact_Info_TypeArgs = {
  where: Contact_Info_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Info_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Contact_InviteArgs = {
  where: Contact_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_StatusArgs = {
  where: Contact_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_TypeArgs = {
  where: Contact_Invite_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Contact_Invite_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Deal_Hop_GateArgs = {
  where: Deal_Hop_Gate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Deal_Hop_Gate_By_PkArgs = {
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Device_Client_DataArgs = {
  where: Device_Client_Data_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Device_Client_Data_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Discovery_BacklogArgs = {
  where: Discovery_Backlog_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Discovery_Backlog_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Discovery_FeedArgs = {
  where: Discovery_Feed_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Discovery_Feed_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Discovery_StagingArgs = {
  where: Discovery_Staging_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Discovery_Staging_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Featured_ShopArgs = {
  where: Featured_Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Featured_Shop_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Featured_Shop_ItemArgs = {
  where: Featured_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Featured_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Feed_ItemArgs = {
  where: Feed_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_LikeArgs = {
  where: Feed_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_Like_By_PkArgs = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_TypeArgs = {
  where: Feed_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Feed_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Fmtc_DealArgs = {
  where: Fmtc_Deal_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Fmtc_Deal_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Followable_ItemArgs = {
  where: Followable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Followable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Followable_Item_TypeArgs = {
  where: Followable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Followable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_EventArgs = {
  where: Leaderboard_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_Event_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_Event_NameArgs = {
  where: Leaderboard_Event_Name_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Leaderboard_Event_Name_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Link_TypeArgs = {
  where: Link_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Link_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Media_Enabled_ItemArgs = {
  where: Media_Enabled_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Enabled_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Media_ItemArgs = {
  where: Media_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Media_Item_TypeArgs = {
  where: Media_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Media_ItemsArgs = {
  where: Media_Items_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Items_By_PkArgs = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Media_TypeArgs = {
  where: Media_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Media_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_App_HomeArgs = {
  where: Mobile_App_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_App_Home_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_HomeArgs = {
  where: Mobile_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Home_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_NotificationArgs = {
  where: Mobile_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_Notification_StatusArgs = {
  where: Mobile_Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mobile_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_ItemArgs = {
  where: Notifiable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_Item_TypeArgs = {
  where: Notifiable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notifiable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_NotificationArgs = {
  where: Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_ItemArgs = {
  where: Notification_Center_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_Read_StatusArgs = {
  where: Notification_Center_Item_Read_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_Read_Status_By_PkArgs =
  {
    notification_center_item_id: Scalars['uuid']
    user_id: Scalars['String']
  }

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_TypeArgs = {
  where: Notification_Center_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Center_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Notification_PreferenceArgs = {
  where: Notification_Preference_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Preference_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Notification_StatusArgs = {
  where: Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_CategoriesArgs = {
  where: Onboarding_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_Categories_By_PkArgs = {
  category: Shop_Category_Enum
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_SourcesArgs = {
  where: Onboarding_Sources_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Onboarding_Sources_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_One_Off_EmailArgs = {
  where: One_Off_Email_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_One_Off_Email_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Pageview_EventArgs = {
  where: Pageview_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Pageview_Event_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Promo_PeriodArgs = {
  where: Promo_Period_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Promo_Period_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Push_NotificationArgs = {
  where: Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Push_Notification_StatusArgs = {
  where: Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Push_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Redirect_IdsArgs = {
  where: Redirect_Ids_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Redirect_Ids_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Remove_ReasonArgs = {
  where: Remove_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Remove_Reason_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Action_NamesArgs = {
  where: Rewardable_Action_Names_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Action_Names_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Item_TypeArgs = {
  where: Rewardable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Rewardable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Search_QueryArgs = {
  where: Search_Query_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Search_Query_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Search_Query_ResultArgs = {
  where: Search_Query_Result_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Search_Query_Result_By_PkArgs = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_ShopArgs = {
  where: Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_AffiliateArgs = {
  where: Shop_Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Affiliate_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_By_PkArgs = {
  key: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_CategorizationArgs = {
  where: Shop_Categorization_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Categorization_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_CategoryArgs = {
  where: Shop_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Category_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_ItemArgs = {
  where: Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_AvailabilityArgs = {
  where: Shop_Item_Availability_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Availability_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Enrichment_StatusArgs = {
  where: Shop_Item_Enrichment_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Enrichment_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_HistoryArgs = {
  where: Shop_Item_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_History_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_ImageArgs = {
  where: Shop_Item_Image_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Image_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_IndexArgs = {
  where: Shop_Item_Index_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_Index_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Shop_Item_To_Be_ScrapedArgs = {
  where: Shop_Item_To_Be_Scraped_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_RatingArgs = {
  where: Shop_Rating_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Shop_Rating_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Similar_Products_HistoryArgs = {
  where: Similar_Products_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Similar_Products_History_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Account_TypeArgs = {
  where: User_Account_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Account_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_ActionArgs = {
  where: User_Action_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Action_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_App_VersionArgs = {
  where: User_App_Version_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_App_Version_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_Carts_OnboardingArgs = {
  where: User_Carts_Onboarding_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Carts_Onboarding_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_CategoryArgs = {
  where: User_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Category_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_DeletionArgs = {
  where: User_Deletion_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Deletion_By_PkArgs = {
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_FeedbackArgs = {
  where: User_Feedback_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_ItemArgs = {
  where: User_Feedback_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_TypeArgs = {
  where: User_Feedback_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Feedback_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_FlagsArgs = {
  where: User_Flags_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Flags_By_PkArgs = {
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_ImpersonationArgs = {
  where: User_Impersonation_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Impersonation_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Ip_AddressArgs = {
  where: User_Ip_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Ip_Address_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Items_FollowedArgs = {
  where: User_Items_Followed_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Items_Followed_By_PkArgs = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_LinksArgs = {
  where: User_Links_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Links_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_SourceArgs = {
  where: User_Onboarding_Source_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_Source_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_StateArgs = {
  where: User_Onboarding_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_State_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_Phone_ContactArgs = {
  where: User_Phone_Contact_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Phone_Contact_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Purchase_SubscriptionArgs = {
  where: User_Purchase_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Purchase_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_PurchasesArgs = {
  where: User_Purchases_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Purchases_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Push_NotificationArgs = {
  where: User_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Push_Notification_TokenArgs = {
  where: User_Push_Notification_Token_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Push_Notification_Token_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_ReferralArgs = {
  where: User_Referral_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Referral_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Multiplier_ConfigArgs = {
  where: User_Reward_Multiplier_Config_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Multiplier_Config_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_RedemptionArgs = {
  where: User_Reward_Redemption_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Redemption_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_ReferenceArgs = {
  where: User_Reward_Reference_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Reward_Reference_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Shop_FavoriteArgs = {
  where: User_Shop_Favorite_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Shop_Favorite_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Shopping_PreferencesArgs = {
  where: User_Shopping_Preferences_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Shopping_Preferences_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_SubscriptionArgs = {
  where: User_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_User_Subscription_StatusArgs = {
  where: User_Subscription_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Subscription_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_User_TempArgs = {
  where: User_Temp_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_User_Temp_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Worflow_TypeArgs = {
  where: Worflow_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Worflow_Type_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_WorkflowArgs = {
  where: Workflow_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Workflow_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_Workflow_StatusArgs = {
  where: Workflow_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Workflow_Status_By_PkArgs = {
  value: Scalars['String']
}

/** mutation root */
export type Mutation_RootFullyUnsubscribeFromCreatorArgs = {
  creatorId: Scalars['String']
}

/** mutation root */
export type Mutation_RootInsert_Account_Deletion_ReasonArgs = {
  objects: Array<Account_Deletion_Reason_Insert_Input>
  on_conflict?: Maybe<Account_Deletion_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Account_Deletion_Reason_OneArgs = {
  object: Account_Deletion_Reason_Insert_Input
  on_conflict?: Maybe<Account_Deletion_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_AffiliateArgs = {
  objects: Array<Affiliate_Insert_Input>
  on_conflict?: Maybe<Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Advertiser_IdArgs = {
  objects: Array<Affiliate_Advertiser_Id_Insert_Input>
  on_conflict?: Maybe<Affiliate_Advertiser_Id_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Advertiser_Id_OneArgs = {
  object: Affiliate_Advertiser_Id_Insert_Input
  on_conflict?: Maybe<Affiliate_Advertiser_Id_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_MetaArgs = {
  objects: Array<Affiliate_Meta_Insert_Input>
  on_conflict?: Maybe<Affiliate_Meta_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Meta_OneArgs = {
  object: Affiliate_Meta_Insert_Input
  on_conflict?: Maybe<Affiliate_Meta_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_OneArgs = {
  object: Affiliate_Insert_Input
  on_conflict?: Maybe<Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_TransactionArgs = {
  objects: Array<Affiliate_Transaction_Insert_Input>
  on_conflict?: Maybe<Affiliate_Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Affiliate_Transaction_OneArgs = {
  object: Affiliate_Transaction_Insert_Input
  on_conflict?: Maybe<Affiliate_Transaction_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_ModeArgs = {
  objects: Array<Billing_Mode_Insert_Input>
  on_conflict?: Maybe<Billing_Mode_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Mode_OneArgs = {
  object: Billing_Mode_Insert_Input
  on_conflict?: Maybe<Billing_Mode_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_AccountArgs = {
  objects: Array<Billing_Platform_Connected_Account_Insert_Input>
  on_conflict?: Maybe<Billing_Platform_Connected_Account_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_Account_OneArgs = {
  object: Billing_Platform_Connected_Account_Insert_Input
  on_conflict?: Maybe<Billing_Platform_Connected_Account_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_Account_StatusArgs =
  {
    objects: Array<Billing_Platform_Connected_Account_Status_Insert_Input>
    on_conflict?: Maybe<Billing_Platform_Connected_Account_Status_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Connected_Account_Status_OneArgs =
  {
    object: Billing_Platform_Connected_Account_Status_Insert_Input
    on_conflict?: Maybe<Billing_Platform_Connected_Account_Status_On_Conflict>
  }

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_CustomerArgs = {
  objects: Array<Billing_Platform_Customer_Insert_Input>
  on_conflict?: Maybe<Billing_Platform_Customer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Billing_Platform_Customer_OneArgs = {
  object: Billing_Platform_Customer_Insert_Input
  on_conflict?: Maybe<Billing_Platform_Customer_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CartArgs = {
  objects: Array<Cart_Insert_Input>
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_ItemArgs = {
  objects: Array<Cart_Item_Insert_Input>
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_LikeArgs = {
  objects: Array<Cart_Item_Like_Insert_Input>
  on_conflict?: Maybe<Cart_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_Like_OneArgs = {
  object: Cart_Item_Like_Insert_Input
  on_conflict?: Maybe<Cart_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_NoteArgs = {
  objects: Array<Cart_Item_Note_Insert_Input>
  on_conflict?: Maybe<Cart_Item_Note_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_Note_OneArgs = {
  object: Cart_Item_Note_Insert_Input
  on_conflict?: Maybe<Cart_Item_Note_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_Item_OneArgs = {
  object: Cart_Item_Insert_Input
  on_conflict?: Maybe<Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Cart_OneArgs = {
  object: Cart_Insert_Input
  on_conflict?: Maybe<Cart_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input
  on_conflict?: Maybe<Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Category_Shop_ItemArgs = {
  objects: Array<Category_Shop_Item_Insert_Input>
  on_conflict?: Maybe<Category_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Category_Shop_Item_OneArgs = {
  object: Category_Shop_Item_Insert_Input
  on_conflict?: Maybe<Category_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CheckoutArgs = {
  objects: Array<Checkout_Insert_Input>
  on_conflict?: Maybe<Checkout_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Checkout_OneArgs = {
  object: Checkout_Insert_Input
  on_conflict?: Maybe<Checkout_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CollectionArgs = {
  objects: Array<Collection_Insert_Input>
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Cart_ItemArgs = {
  objects: Array<Collection_Cart_Item_Insert_Input>
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Cart_Item_OneArgs = {
  object: Collection_Cart_Item_Insert_Input
  on_conflict?: Maybe<Collection_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_CollaboratorArgs = {
  objects: Array<Collection_Collaborator_Insert_Input>
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Collaborator_OneArgs = {
  object: Collection_Collaborator_Insert_Input
  on_conflict?: Maybe<Collection_Collaborator_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_InviteArgs = {
  objects: Array<Collection_Invite_Insert_Input>
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Invite_OneArgs = {
  object: Collection_Invite_Insert_Input
  on_conflict?: Maybe<Collection_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Invite_StatusArgs = {
  objects: Array<Collection_Invite_Status_Insert_Input>
  on_conflict?: Maybe<Collection_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Invite_Status_OneArgs = {
  object: Collection_Invite_Status_Insert_Input
  on_conflict?: Maybe<Collection_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_ClaimArgs = {
  objects: Array<Collection_Item_Claim_Insert_Input>
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_Claim_OneArgs = {
  object: Collection_Item_Claim_Insert_Input
  on_conflict?: Maybe<Collection_Item_Claim_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_Claim_StatusArgs = {
  objects: Array<Collection_Item_Claim_Status_Insert_Input>
  on_conflict?: Maybe<Collection_Item_Claim_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Item_Claim_Status_OneArgs = {
  object: Collection_Item_Claim_Status_Insert_Input
  on_conflict?: Maybe<Collection_Item_Claim_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_OneArgs = {
  object: Collection_Insert_Input
  on_conflict?: Maybe<Collection_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_SectionArgs = {
  objects: Array<Collection_Section_Insert_Input>
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Section_Cart_ItemArgs = {
  objects: Array<Collection_Section_Cart_Item_Insert_Input>
  on_conflict?: Maybe<Collection_Section_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Section_Cart_Item_OneArgs = {
  object: Collection_Section_Cart_Item_Insert_Input
  on_conflict?: Maybe<Collection_Section_Cart_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Section_OneArgs = {
  object: Collection_Section_Insert_Input
  on_conflict?: Maybe<Collection_Section_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Slug_HistoryArgs = {
  objects: Array<Collection_Slug_History_Insert_Input>
  on_conflict?: Maybe<Collection_Slug_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Slug_History_OneArgs = {
  object: Collection_Slug_History_Insert_Input
  on_conflict?: Maybe<Collection_Slug_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_StateArgs = {
  objects: Array<Collection_State_Insert_Input>
  on_conflict?: Maybe<Collection_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_State_OneArgs = {
  object: Collection_State_Insert_Input
  on_conflict?: Maybe<Collection_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_TypeArgs = {
  objects: Array<Collection_Type_Insert_Input>
  on_conflict?: Maybe<Collection_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Collection_Type_OneArgs = {
  object: Collection_Type_Insert_Input
  on_conflict?: Maybe<Collection_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_CommentArgs = {
  objects: Array<Comment_Insert_Input>
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Comment_OneArgs = {
  object: Comment_Insert_Input
  on_conflict?: Maybe<Comment_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_ItemArgs = {
  objects: Array<Commentable_Item_Insert_Input>
  on_conflict?: Maybe<Commentable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_Item_OneArgs = {
  object: Commentable_Item_Insert_Input
  on_conflict?: Maybe<Commentable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_Item_TypeArgs = {
  objects: Array<Commentable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Commentable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Commentable_Item_Type_OneArgs = {
  object: Commentable_Item_Type_Insert_Input
  on_conflict?: Maybe<Commentable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_TemplateArgs = {
  objects: Array<Communication_Template_Insert_Input>
  on_conflict?: Maybe<Communication_Template_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Template_Full_ListArgs = {
  objects: Array<Communication_Template_Full_List_Insert_Input>
  on_conflict?: Maybe<Communication_Template_Full_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Template_Full_List_OneArgs = {
  object: Communication_Template_Full_List_Insert_Input
  on_conflict?: Maybe<Communication_Template_Full_List_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Communication_Template_OneArgs = {
  object: Communication_Template_Insert_Input
  on_conflict?: Maybe<Communication_Template_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Info_TypeArgs = {
  objects: Array<Contact_Info_Type_Insert_Input>
  on_conflict?: Maybe<Contact_Info_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Info_Type_OneArgs = {
  object: Contact_Info_Type_Insert_Input
  on_conflict?: Maybe<Contact_Info_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_InviteArgs = {
  objects: Array<Contact_Invite_Insert_Input>
  on_conflict?: Maybe<Contact_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_OneArgs = {
  object: Contact_Invite_Insert_Input
  on_conflict?: Maybe<Contact_Invite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_StatusArgs = {
  objects: Array<Contact_Invite_Status_Insert_Input>
  on_conflict?: Maybe<Contact_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_Status_OneArgs = {
  object: Contact_Invite_Status_Insert_Input
  on_conflict?: Maybe<Contact_Invite_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_TypeArgs = {
  objects: Array<Contact_Invite_Type_Insert_Input>
  on_conflict?: Maybe<Contact_Invite_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Contact_Invite_Type_OneArgs = {
  object: Contact_Invite_Type_Insert_Input
  on_conflict?: Maybe<Contact_Invite_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Deal_Hop_GateArgs = {
  objects: Array<Deal_Hop_Gate_Insert_Input>
  on_conflict?: Maybe<Deal_Hop_Gate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Deal_Hop_Gate_OneArgs = {
  object: Deal_Hop_Gate_Insert_Input
  on_conflict?: Maybe<Deal_Hop_Gate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Client_DataArgs = {
  objects: Array<Device_Client_Data_Insert_Input>
  on_conflict?: Maybe<Device_Client_Data_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Device_Client_Data_OneArgs = {
  object: Device_Client_Data_Insert_Input
  on_conflict?: Maybe<Device_Client_Data_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_BacklogArgs = {
  objects: Array<Discovery_Backlog_Insert_Input>
  on_conflict?: Maybe<Discovery_Backlog_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_Backlog_OneArgs = {
  object: Discovery_Backlog_Insert_Input
  on_conflict?: Maybe<Discovery_Backlog_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_FeedArgs = {
  objects: Array<Discovery_Feed_Insert_Input>
  on_conflict?: Maybe<Discovery_Feed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_Feed_OneArgs = {
  object: Discovery_Feed_Insert_Input
  on_conflict?: Maybe<Discovery_Feed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_StagingArgs = {
  objects: Array<Discovery_Staging_Insert_Input>
  on_conflict?: Maybe<Discovery_Staging_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Discovery_Staging_OneArgs = {
  object: Discovery_Staging_Insert_Input
  on_conflict?: Maybe<Discovery_Staging_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_ShopArgs = {
  objects: Array<Featured_Shop_Insert_Input>
  on_conflict?: Maybe<Featured_Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_Shop_ItemArgs = {
  objects: Array<Featured_Shop_Item_Insert_Input>
  on_conflict?: Maybe<Featured_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_Shop_Item_OneArgs = {
  object: Featured_Shop_Item_Insert_Input
  on_conflict?: Maybe<Featured_Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Featured_Shop_OneArgs = {
  object: Featured_Shop_Insert_Input
  on_conflict?: Maybe<Featured_Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_ItemArgs = {
  objects: Array<Feed_Item_Insert_Input>
  on_conflict?: Maybe<Feed_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_LikeArgs = {
  objects: Array<Feed_Item_Like_Insert_Input>
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_Like_OneArgs = {
  object: Feed_Item_Like_Insert_Input
  on_conflict?: Maybe<Feed_Item_Like_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_OneArgs = {
  object: Feed_Item_Insert_Input
  on_conflict?: Maybe<Feed_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_TypeArgs = {
  objects: Array<Feed_Item_Type_Insert_Input>
  on_conflict?: Maybe<Feed_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Feed_Item_Type_OneArgs = {
  object: Feed_Item_Type_Insert_Input
  on_conflict?: Maybe<Feed_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Fmtc_DealArgs = {
  objects: Array<Fmtc_Deal_Insert_Input>
  on_conflict?: Maybe<Fmtc_Deal_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Fmtc_Deal_OneArgs = {
  object: Fmtc_Deal_Insert_Input
  on_conflict?: Maybe<Fmtc_Deal_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_ItemArgs = {
  objects: Array<Followable_Item_Insert_Input>
  on_conflict?: Maybe<Followable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_Item_OneArgs = {
  object: Followable_Item_Insert_Input
  on_conflict?: Maybe<Followable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_Item_TypeArgs = {
  objects: Array<Followable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Followable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Followable_Item_Type_OneArgs = {
  object: Followable_Item_Type_Insert_Input
  on_conflict?: Maybe<Followable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_EventArgs = {
  objects: Array<Leaderboard_Event_Insert_Input>
  on_conflict?: Maybe<Leaderboard_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_Event_NameArgs = {
  objects: Array<Leaderboard_Event_Name_Insert_Input>
  on_conflict?: Maybe<Leaderboard_Event_Name_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_Event_Name_OneArgs = {
  object: Leaderboard_Event_Name_Insert_Input
  on_conflict?: Maybe<Leaderboard_Event_Name_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Leaderboard_Event_OneArgs = {
  object: Leaderboard_Event_Insert_Input
  on_conflict?: Maybe<Leaderboard_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Link_TypeArgs = {
  objects: Array<Link_Type_Insert_Input>
  on_conflict?: Maybe<Link_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Link_Type_OneArgs = {
  object: Link_Type_Insert_Input
  on_conflict?: Maybe<Link_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Enabled_ItemArgs = {
  objects: Array<Media_Enabled_Item_Insert_Input>
  on_conflict?: Maybe<Media_Enabled_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Enabled_Item_OneArgs = {
  object: Media_Enabled_Item_Insert_Input
  on_conflict?: Maybe<Media_Enabled_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_ItemArgs = {
  objects: Array<Media_Item_Insert_Input>
  on_conflict?: Maybe<Media_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Item_OneArgs = {
  object: Media_Item_Insert_Input
  on_conflict?: Maybe<Media_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Item_TypeArgs = {
  objects: Array<Media_Item_Type_Insert_Input>
  on_conflict?: Maybe<Media_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Item_Type_OneArgs = {
  object: Media_Item_Type_Insert_Input
  on_conflict?: Maybe<Media_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_ItemsArgs = {
  objects: Array<Media_Items_Insert_Input>
  on_conflict?: Maybe<Media_Items_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Items_OneArgs = {
  object: Media_Items_Insert_Input
  on_conflict?: Maybe<Media_Items_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_TypeArgs = {
  objects: Array<Media_Type_Insert_Input>
  on_conflict?: Maybe<Media_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Media_Type_OneArgs = {
  object: Media_Type_Insert_Input
  on_conflict?: Maybe<Media_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_App_HomeArgs = {
  objects: Array<Mobile_App_Home_Insert_Input>
  on_conflict?: Maybe<Mobile_App_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_App_Home_OneArgs = {
  object: Mobile_App_Home_Insert_Input
  on_conflict?: Maybe<Mobile_App_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_HomeArgs = {
  objects: Array<Mobile_Home_Insert_Input>
  on_conflict?: Maybe<Mobile_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Home_OneArgs = {
  object: Mobile_Home_Insert_Input
  on_conflict?: Maybe<Mobile_Home_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_NotificationArgs = {
  objects: Array<Mobile_Push_Notification_Insert_Input>
  on_conflict?: Maybe<Mobile_Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_Notification_OneArgs = {
  object: Mobile_Push_Notification_Insert_Input
  on_conflict?: Maybe<Mobile_Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_Notification_StatusArgs = {
  objects: Array<Mobile_Push_Notification_Status_Insert_Input>
  on_conflict?: Maybe<Mobile_Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mobile_Push_Notification_Status_OneArgs = {
  object: Mobile_Push_Notification_Status_Insert_Input
  on_conflict?: Maybe<Mobile_Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_ItemArgs = {
  objects: Array<Notifiable_Item_Insert_Input>
  on_conflict?: Maybe<Notifiable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_Item_OneArgs = {
  object: Notifiable_Item_Insert_Input
  on_conflict?: Maybe<Notifiable_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_Item_TypeArgs = {
  objects: Array<Notifiable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Notifiable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notifiable_Item_Type_OneArgs = {
  object: Notifiable_Item_Type_Insert_Input
  on_conflict?: Maybe<Notifiable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_NotificationArgs = {
  objects: Array<Notification_Insert_Input>
  on_conflict?: Maybe<Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_ItemArgs = {
  objects: Array<Notification_Center_Item_Insert_Input>
  on_conflict?: Maybe<Notification_Center_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_OneArgs = {
  object: Notification_Center_Item_Insert_Input
  on_conflict?: Maybe<Notification_Center_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_Read_StatusArgs = {
  objects: Array<Notification_Center_Item_Read_Status_Insert_Input>
  on_conflict?: Maybe<Notification_Center_Item_Read_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_Read_Status_OneArgs = {
  object: Notification_Center_Item_Read_Status_Insert_Input
  on_conflict?: Maybe<Notification_Center_Item_Read_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_TypeArgs = {
  objects: Array<Notification_Center_Item_Type_Insert_Input>
  on_conflict?: Maybe<Notification_Center_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Center_Item_Type_OneArgs = {
  object: Notification_Center_Item_Type_Insert_Input
  on_conflict?: Maybe<Notification_Center_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_OneArgs = {
  object: Notification_Insert_Input
  on_conflict?: Maybe<Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_PreferenceArgs = {
  objects: Array<Notification_Preference_Insert_Input>
  on_conflict?: Maybe<Notification_Preference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Preference_OneArgs = {
  object: Notification_Preference_Insert_Input
  on_conflict?: Maybe<Notification_Preference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_StatusArgs = {
  objects: Array<Notification_Status_Insert_Input>
  on_conflict?: Maybe<Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Notification_Status_OneArgs = {
  object: Notification_Status_Insert_Input
  on_conflict?: Maybe<Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_CategoriesArgs = {
  objects: Array<Onboarding_Categories_Insert_Input>
  on_conflict?: Maybe<Onboarding_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_Categories_OneArgs = {
  object: Onboarding_Categories_Insert_Input
  on_conflict?: Maybe<Onboarding_Categories_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_SourcesArgs = {
  objects: Array<Onboarding_Sources_Insert_Input>
  on_conflict?: Maybe<Onboarding_Sources_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Onboarding_Sources_OneArgs = {
  object: Onboarding_Sources_Insert_Input
  on_conflict?: Maybe<Onboarding_Sources_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_One_Off_EmailArgs = {
  objects: Array<One_Off_Email_Insert_Input>
  on_conflict?: Maybe<One_Off_Email_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_One_Off_Email_OneArgs = {
  object: One_Off_Email_Insert_Input
  on_conflict?: Maybe<One_Off_Email_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Pageview_EventArgs = {
  objects: Array<Pageview_Event_Insert_Input>
  on_conflict?: Maybe<Pageview_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Pageview_Event_OneArgs = {
  object: Pageview_Event_Insert_Input
  on_conflict?: Maybe<Pageview_Event_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Promo_PeriodArgs = {
  objects: Array<Promo_Period_Insert_Input>
  on_conflict?: Maybe<Promo_Period_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Promo_Period_OneArgs = {
  object: Promo_Period_Insert_Input
  on_conflict?: Maybe<Promo_Period_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_NotificationArgs = {
  objects: Array<Push_Notification_Insert_Input>
  on_conflict?: Maybe<Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_Notification_OneArgs = {
  object: Push_Notification_Insert_Input
  on_conflict?: Maybe<Push_Notification_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_Notification_StatusArgs = {
  objects: Array<Push_Notification_Status_Insert_Input>
  on_conflict?: Maybe<Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Push_Notification_Status_OneArgs = {
  object: Push_Notification_Status_Insert_Input
  on_conflict?: Maybe<Push_Notification_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Redirect_IdsArgs = {
  objects: Array<Redirect_Ids_Insert_Input>
  on_conflict?: Maybe<Redirect_Ids_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Redirect_Ids_OneArgs = {
  object: Redirect_Ids_Insert_Input
  on_conflict?: Maybe<Redirect_Ids_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Remove_ReasonArgs = {
  objects: Array<Remove_Reason_Insert_Input>
  on_conflict?: Maybe<Remove_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Remove_Reason_OneArgs = {
  object: Remove_Reason_Insert_Input
  on_conflict?: Maybe<Remove_Reason_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Action_NamesArgs = {
  objects: Array<Rewardable_Action_Names_Insert_Input>
  on_conflict?: Maybe<Rewardable_Action_Names_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Action_Names_OneArgs = {
  object: Rewardable_Action_Names_Insert_Input
  on_conflict?: Maybe<Rewardable_Action_Names_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Item_TypeArgs = {
  objects: Array<Rewardable_Item_Type_Insert_Input>
  on_conflict?: Maybe<Rewardable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Rewardable_Item_Type_OneArgs = {
  object: Rewardable_Item_Type_Insert_Input
  on_conflict?: Maybe<Rewardable_Item_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_QueryArgs = {
  objects: Array<Search_Query_Insert_Input>
  on_conflict?: Maybe<Search_Query_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_Query_OneArgs = {
  object: Search_Query_Insert_Input
  on_conflict?: Maybe<Search_Query_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_Query_ResultArgs = {
  objects: Array<Search_Query_Result_Insert_Input>
  on_conflict?: Maybe<Search_Query_Result_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Search_Query_Result_OneArgs = {
  object: Search_Query_Result_Insert_Input
  on_conflict?: Maybe<Search_Query_Result_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ShopArgs = {
  objects: Array<Shop_Insert_Input>
  on_conflict?: Maybe<Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_AffiliateArgs = {
  objects: Array<Shop_Affiliate_Insert_Input>
  on_conflict?: Maybe<Shop_Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Affiliate_OneArgs = {
  object: Shop_Affiliate_Insert_Input
  on_conflict?: Maybe<Shop_Affiliate_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_CategorizationArgs = {
  objects: Array<Shop_Categorization_Insert_Input>
  on_conflict?: Maybe<Shop_Categorization_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Categorization_OneArgs = {
  object: Shop_Categorization_Insert_Input
  on_conflict?: Maybe<Shop_Categorization_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_CategoryArgs = {
  objects: Array<Shop_Category_Insert_Input>
  on_conflict?: Maybe<Shop_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Category_OneArgs = {
  object: Shop_Category_Insert_Input
  on_conflict?: Maybe<Shop_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_ItemArgs = {
  objects: Array<Shop_Item_Insert_Input>
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_AvailabilityArgs = {
  objects: Array<Shop_Item_Availability_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Availability_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Availability_OneArgs = {
  object: Shop_Item_Availability_Insert_Input
  on_conflict?: Maybe<Shop_Item_Availability_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Enrichment_StatusArgs = {
  objects: Array<Shop_Item_Enrichment_Status_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Enrichment_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Enrichment_Status_OneArgs = {
  object: Shop_Item_Enrichment_Status_Insert_Input
  on_conflict?: Maybe<Shop_Item_Enrichment_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_HistoryArgs = {
  objects: Array<Shop_Item_History_Insert_Input>
  on_conflict?: Maybe<Shop_Item_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_History_OneArgs = {
  object: Shop_Item_History_Insert_Input
  on_conflict?: Maybe<Shop_Item_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_ImageArgs = {
  objects: Array<Shop_Item_Image_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Image_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Image_OneArgs = {
  object: Shop_Item_Image_Insert_Input
  on_conflict?: Maybe<Shop_Item_Image_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_IndexArgs = {
  objects: Array<Shop_Item_Index_Insert_Input>
  on_conflict?: Maybe<Shop_Item_Index_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_Index_OneArgs = {
  object: Shop_Item_Index_Insert_Input
  on_conflict?: Maybe<Shop_Item_Index_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_OneArgs = {
  object: Shop_Item_Insert_Input
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_To_Be_ScrapedArgs = {
  objects: Array<Shop_Item_To_Be_Scraped_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Item_To_Be_Scraped_OneArgs = {
  object: Shop_Item_To_Be_Scraped_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_Shop_OneArgs = {
  object: Shop_Insert_Input
  on_conflict?: Maybe<Shop_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_RatingArgs = {
  objects: Array<Shop_Rating_Insert_Input>
  on_conflict?: Maybe<Shop_Rating_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Shop_Rating_OneArgs = {
  object: Shop_Rating_Insert_Input
  on_conflict?: Maybe<Shop_Rating_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Similar_Products_HistoryArgs = {
  objects: Array<Similar_Products_History_Insert_Input>
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Similar_Products_History_OneArgs = {
  object: Similar_Products_History_Insert_Input
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>
  on_conflict?: Maybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Account_TypeArgs = {
  objects: Array<User_Account_Type_Insert_Input>
  on_conflict?: Maybe<User_Account_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Account_Type_OneArgs = {
  object: User_Account_Type_Insert_Input
  on_conflict?: Maybe<User_Account_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ActionArgs = {
  objects: Array<User_Action_Insert_Input>
  on_conflict?: Maybe<User_Action_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Action_OneArgs = {
  object: User_Action_Insert_Input
  on_conflict?: Maybe<User_Action_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_App_VersionArgs = {
  objects: Array<User_App_Version_Insert_Input>
  on_conflict?: Maybe<User_App_Version_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_App_Version_OneArgs = {
  object: User_App_Version_Insert_Input
  on_conflict?: Maybe<User_App_Version_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Carts_OnboardingArgs = {
  objects: Array<User_Carts_Onboarding_Insert_Input>
  on_conflict?: Maybe<User_Carts_Onboarding_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Carts_Onboarding_OneArgs = {
  object: User_Carts_Onboarding_Insert_Input
  on_conflict?: Maybe<User_Carts_Onboarding_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_CategoryArgs = {
  objects: Array<User_Category_Insert_Input>
  on_conflict?: Maybe<User_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Category_OneArgs = {
  object: User_Category_Insert_Input
  on_conflict?: Maybe<User_Category_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_DeletionArgs = {
  objects: Array<User_Deletion_Insert_Input>
  on_conflict?: Maybe<User_Deletion_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Deletion_OneArgs = {
  object: User_Deletion_Insert_Input
  on_conflict?: Maybe<User_Deletion_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_FeedbackArgs = {
  objects: Array<User_Feedback_Insert_Input>
  on_conflict?: Maybe<User_Feedback_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_ItemArgs = {
  objects: Array<User_Feedback_Item_Insert_Input>
  on_conflict?: Maybe<User_Feedback_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_Item_OneArgs = {
  object: User_Feedback_Item_Insert_Input
  on_conflict?: Maybe<User_Feedback_Item_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_OneArgs = {
  object: User_Feedback_Insert_Input
  on_conflict?: Maybe<User_Feedback_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_TypeArgs = {
  objects: Array<User_Feedback_Type_Insert_Input>
  on_conflict?: Maybe<User_Feedback_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Feedback_Type_OneArgs = {
  object: User_Feedback_Type_Insert_Input
  on_conflict?: Maybe<User_Feedback_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_FlagsArgs = {
  objects: Array<User_Flags_Insert_Input>
  on_conflict?: Maybe<User_Flags_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Flags_OneArgs = {
  object: User_Flags_Insert_Input
  on_conflict?: Maybe<User_Flags_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ImpersonationArgs = {
  objects: Array<User_Impersonation_Insert_Input>
  on_conflict?: Maybe<User_Impersonation_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Impersonation_OneArgs = {
  object: User_Impersonation_Insert_Input
  on_conflict?: Maybe<User_Impersonation_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Ip_AddressArgs = {
  objects: Array<User_Ip_Address_Insert_Input>
  on_conflict?: Maybe<User_Ip_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Ip_Address_OneArgs = {
  object: User_Ip_Address_Insert_Input
  on_conflict?: Maybe<User_Ip_Address_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Items_FollowedArgs = {
  objects: Array<User_Items_Followed_Insert_Input>
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Items_Followed_OneArgs = {
  object: User_Items_Followed_Insert_Input
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_LinksArgs = {
  objects: Array<User_Links_Insert_Input>
  on_conflict?: Maybe<User_Links_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Links_OneArgs = {
  object: User_Links_Insert_Input
  on_conflict?: Maybe<User_Links_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_SourceArgs = {
  objects: Array<User_Onboarding_Source_Insert_Input>
  on_conflict?: Maybe<User_Onboarding_Source_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_Source_OneArgs = {
  object: User_Onboarding_Source_Insert_Input
  on_conflict?: Maybe<User_Onboarding_Source_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_StateArgs = {
  objects: Array<User_Onboarding_State_Insert_Input>
  on_conflict?: Maybe<User_Onboarding_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_State_OneArgs = {
  object: User_Onboarding_State_Insert_Input
  on_conflict?: Maybe<User_Onboarding_State_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input
  on_conflict?: Maybe<User_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Phone_ContactArgs = {
  objects: Array<User_Phone_Contact_Insert_Input>
  on_conflict?: Maybe<User_Phone_Contact_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Phone_Contact_OneArgs = {
  object: User_Phone_Contact_Insert_Input
  on_conflict?: Maybe<User_Phone_Contact_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Purchase_SubscriptionArgs = {
  objects: Array<User_Purchase_Subscription_Insert_Input>
  on_conflict?: Maybe<User_Purchase_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Purchase_Subscription_OneArgs = {
  object: User_Purchase_Subscription_Insert_Input
  on_conflict?: Maybe<User_Purchase_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_PurchasesArgs = {
  objects: Array<User_Purchases_Insert_Input>
  on_conflict?: Maybe<User_Purchases_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Purchases_OneArgs = {
  object: User_Purchases_Insert_Input
  on_conflict?: Maybe<User_Purchases_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Push_NotificationArgs = {
  objects: Array<User_Push_Notification_Insert_Input>
}

/** mutation root */
export type Mutation_RootInsert_User_Push_Notification_OneArgs = {
  object: User_Push_Notification_Insert_Input
}

/** mutation root */
export type Mutation_RootInsert_User_Push_Notification_TokenArgs = {
  objects: Array<User_Push_Notification_Token_Insert_Input>
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Push_Notification_Token_OneArgs = {
  object: User_Push_Notification_Token_Insert_Input
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_ReferralArgs = {
  objects: Array<User_Referral_Insert_Input>
  on_conflict?: Maybe<User_Referral_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Referral_OneArgs = {
  object: User_Referral_Insert_Input
  on_conflict?: Maybe<User_Referral_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Multiplier_ConfigArgs = {
  objects: Array<User_Reward_Multiplier_Config_Insert_Input>
  on_conflict?: Maybe<User_Reward_Multiplier_Config_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Multiplier_Config_OneArgs = {
  object: User_Reward_Multiplier_Config_Insert_Input
  on_conflict?: Maybe<User_Reward_Multiplier_Config_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_RedemptionArgs = {
  objects: Array<User_Reward_Redemption_Insert_Input>
  on_conflict?: Maybe<User_Reward_Redemption_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Redemption_OneArgs = {
  object: User_Reward_Redemption_Insert_Input
  on_conflict?: Maybe<User_Reward_Redemption_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_ReferenceArgs = {
  objects: Array<User_Reward_Reference_Insert_Input>
  on_conflict?: Maybe<User_Reward_Reference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Reward_Reference_OneArgs = {
  object: User_Reward_Reference_Insert_Input
  on_conflict?: Maybe<User_Reward_Reference_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shop_FavoriteArgs = {
  objects: Array<User_Shop_Favorite_Insert_Input>
  on_conflict?: Maybe<User_Shop_Favorite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shop_Favorite_OneArgs = {
  object: User_Shop_Favorite_Insert_Input
  on_conflict?: Maybe<User_Shop_Favorite_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shopping_PreferencesArgs = {
  objects: Array<User_Shopping_Preferences_Insert_Input>
  on_conflict?: Maybe<User_Shopping_Preferences_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Shopping_Preferences_OneArgs = {
  object: User_Shopping_Preferences_Insert_Input
  on_conflict?: Maybe<User_Shopping_Preferences_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_SubscriptionArgs = {
  objects: Array<User_Subscription_Insert_Input>
  on_conflict?: Maybe<User_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Subscription_OneArgs = {
  object: User_Subscription_Insert_Input
  on_conflict?: Maybe<User_Subscription_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Subscription_StatusArgs = {
  objects: Array<User_Subscription_Status_Insert_Input>
  on_conflict?: Maybe<User_Subscription_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Subscription_Status_OneArgs = {
  object: User_Subscription_Status_Insert_Input
  on_conflict?: Maybe<User_Subscription_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_TempArgs = {
  objects: Array<User_Temp_Insert_Input>
  on_conflict?: Maybe<User_Temp_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_User_Temp_OneArgs = {
  object: User_Temp_Insert_Input
  on_conflict?: Maybe<User_Temp_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Worflow_TypeArgs = {
  objects: Array<Worflow_Type_Insert_Input>
  on_conflict?: Maybe<Worflow_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Worflow_Type_OneArgs = {
  object: Worflow_Type_Insert_Input
  on_conflict?: Maybe<Worflow_Type_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_WorkflowArgs = {
  objects: Array<Workflow_Insert_Input>
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Workflow_OneArgs = {
  object: Workflow_Insert_Input
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Workflow_StatusArgs = {
  objects: Array<Workflow_Status_Insert_Input>
  on_conflict?: Maybe<Workflow_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Workflow_Status_OneArgs = {
  object: Workflow_Status_Insert_Input
  on_conflict?: Maybe<Workflow_Status_On_Conflict>
}

/** mutation root */
export type Mutation_RootInviteCollaboratorArgs = {
  invite: CollectionCollaboratorInviteInput
}

/** mutation root */
export type Mutation_RootLikeFeedItemArgs = {
  likeData: FeedItemLikeInput
}

/** mutation root */
export type Mutation_RootSubscribeToCreatorArgs = {
  creatorId: Scalars['String']
}

/** mutation root */
export type Mutation_RootUpdate_Account_Deletion_ReasonArgs = {
  _set?: Maybe<Account_Deletion_Reason_Set_Input>
  where: Account_Deletion_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Account_Deletion_Reason_By_PkArgs = {
  _set?: Maybe<Account_Deletion_Reason_Set_Input>
  pk_columns: Account_Deletion_Reason_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Account_Deletion_Reason_ManyArgs = {
  updates: Array<Account_Deletion_Reason_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_AffiliateArgs = {
  _set?: Maybe<Affiliate_Set_Input>
  where: Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Advertiser_IdArgs = {
  _set?: Maybe<Affiliate_Advertiser_Id_Set_Input>
  where: Affiliate_Advertiser_Id_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Advertiser_Id_By_PkArgs = {
  _set?: Maybe<Affiliate_Advertiser_Id_Set_Input>
  pk_columns: Affiliate_Advertiser_Id_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Advertiser_Id_ManyArgs = {
  updates: Array<Affiliate_Advertiser_Id_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_By_PkArgs = {
  _set?: Maybe<Affiliate_Set_Input>
  pk_columns: Affiliate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_ManyArgs = {
  updates: Array<Affiliate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_MetaArgs = {
  _append?: Maybe<Affiliate_Meta_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Meta_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Meta_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Meta_Delete_Key_Input>
  _prepend?: Maybe<Affiliate_Meta_Prepend_Input>
  _set?: Maybe<Affiliate_Meta_Set_Input>
  where: Affiliate_Meta_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Meta_By_PkArgs = {
  _append?: Maybe<Affiliate_Meta_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Meta_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Meta_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Meta_Delete_Key_Input>
  _prepend?: Maybe<Affiliate_Meta_Prepend_Input>
  _set?: Maybe<Affiliate_Meta_Set_Input>
  pk_columns: Affiliate_Meta_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Meta_ManyArgs = {
  updates: Array<Affiliate_Meta_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_TransactionArgs = {
  _append?: Maybe<Affiliate_Transaction_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Transaction_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Transaction_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Transaction_Delete_Key_Input>
  _inc?: Maybe<Affiliate_Transaction_Inc_Input>
  _prepend?: Maybe<Affiliate_Transaction_Prepend_Input>
  _set?: Maybe<Affiliate_Transaction_Set_Input>
  where: Affiliate_Transaction_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Transaction_By_PkArgs = {
  _append?: Maybe<Affiliate_Transaction_Append_Input>
  _delete_at_path?: Maybe<Affiliate_Transaction_Delete_At_Path_Input>
  _delete_elem?: Maybe<Affiliate_Transaction_Delete_Elem_Input>
  _delete_key?: Maybe<Affiliate_Transaction_Delete_Key_Input>
  _inc?: Maybe<Affiliate_Transaction_Inc_Input>
  _prepend?: Maybe<Affiliate_Transaction_Prepend_Input>
  _set?: Maybe<Affiliate_Transaction_Set_Input>
  pk_columns: Affiliate_Transaction_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Affiliate_Transaction_ManyArgs = {
  updates: Array<Affiliate_Transaction_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Billing_ModeArgs = {
  _set?: Maybe<Billing_Mode_Set_Input>
  where: Billing_Mode_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Mode_By_PkArgs = {
  _set?: Maybe<Billing_Mode_Set_Input>
  pk_columns: Billing_Mode_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Mode_ManyArgs = {
  updates: Array<Billing_Mode_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_AccountArgs = {
  _set?: Maybe<Billing_Platform_Connected_Account_Set_Input>
  where: Billing_Platform_Connected_Account_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_By_PkArgs = {
  _set?: Maybe<Billing_Platform_Connected_Account_Set_Input>
  pk_columns: Billing_Platform_Connected_Account_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_ManyArgs = {
  updates: Array<Billing_Platform_Connected_Account_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_StatusArgs =
  {
    _set?: Maybe<Billing_Platform_Connected_Account_Status_Set_Input>
    where: Billing_Platform_Connected_Account_Status_Bool_Exp
  }

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_Status_By_PkArgs =
  {
    _set?: Maybe<Billing_Platform_Connected_Account_Status_Set_Input>
    pk_columns: Billing_Platform_Connected_Account_Status_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Connected_Account_Status_ManyArgs =
  {
    updates: Array<Billing_Platform_Connected_Account_Status_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_CustomerArgs = {
  _append?: Maybe<Billing_Platform_Customer_Append_Input>
  _delete_at_path?: Maybe<Billing_Platform_Customer_Delete_At_Path_Input>
  _delete_elem?: Maybe<Billing_Platform_Customer_Delete_Elem_Input>
  _delete_key?: Maybe<Billing_Platform_Customer_Delete_Key_Input>
  _prepend?: Maybe<Billing_Platform_Customer_Prepend_Input>
  _set?: Maybe<Billing_Platform_Customer_Set_Input>
  where: Billing_Platform_Customer_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Customer_By_PkArgs = {
  _append?: Maybe<Billing_Platform_Customer_Append_Input>
  _delete_at_path?: Maybe<Billing_Platform_Customer_Delete_At_Path_Input>
  _delete_elem?: Maybe<Billing_Platform_Customer_Delete_Elem_Input>
  _delete_key?: Maybe<Billing_Platform_Customer_Delete_Key_Input>
  _prepend?: Maybe<Billing_Platform_Customer_Prepend_Input>
  _set?: Maybe<Billing_Platform_Customer_Set_Input>
  pk_columns: Billing_Platform_Customer_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Billing_Platform_Customer_ManyArgs = {
  updates: Array<Billing_Platform_Customer_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CartArgs = {
  _set?: Maybe<Cart_Set_Input>
  where: Cart_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_By_PkArgs = {
  _set?: Maybe<Cart_Set_Input>
  pk_columns: Cart_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_ItemArgs = {
  _append?: Maybe<Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Cart_Item_Inc_Input>
  _prepend?: Maybe<Cart_Item_Prepend_Input>
  _set?: Maybe<Cart_Item_Set_Input>
  where: Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_By_PkArgs = {
  _append?: Maybe<Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Cart_Item_Inc_Input>
  _prepend?: Maybe<Cart_Item_Prepend_Input>
  _set?: Maybe<Cart_Item_Set_Input>
  pk_columns: Cart_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_LikeArgs = {
  _inc?: Maybe<Cart_Item_Like_Inc_Input>
  _set?: Maybe<Cart_Item_Like_Set_Input>
  where: Cart_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Like_By_PkArgs = {
  _inc?: Maybe<Cart_Item_Like_Inc_Input>
  _set?: Maybe<Cart_Item_Like_Set_Input>
  pk_columns: Cart_Item_Like_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Like_ManyArgs = {
  updates: Array<Cart_Item_Like_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_ManyArgs = {
  updates: Array<Cart_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_NoteArgs = {
  _set?: Maybe<Cart_Item_Note_Set_Input>
  where: Cart_Item_Note_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Note_By_PkArgs = {
  _set?: Maybe<Cart_Item_Note_Set_Input>
  pk_columns: Cart_Item_Note_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Cart_Item_Note_ManyArgs = {
  updates: Array<Cart_Item_Note_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Cart_ManyArgs = {
  updates: Array<Cart_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _set?: Maybe<Categories_Set_Input>
  where: Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _set?: Maybe<Categories_Set_Input>
  pk_columns: Categories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Category_Shop_ItemArgs = {
  _inc?: Maybe<Category_Shop_Item_Inc_Input>
  _set?: Maybe<Category_Shop_Item_Set_Input>
  where: Category_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Category_Shop_Item_By_PkArgs = {
  _inc?: Maybe<Category_Shop_Item_Inc_Input>
  _set?: Maybe<Category_Shop_Item_Set_Input>
  pk_columns: Category_Shop_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Category_Shop_Item_ManyArgs = {
  updates: Array<Category_Shop_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CheckoutArgs = {
  _append?: Maybe<Checkout_Append_Input>
  _delete_at_path?: Maybe<Checkout_Delete_At_Path_Input>
  _delete_elem?: Maybe<Checkout_Delete_Elem_Input>
  _delete_key?: Maybe<Checkout_Delete_Key_Input>
  _inc?: Maybe<Checkout_Inc_Input>
  _prepend?: Maybe<Checkout_Prepend_Input>
  _set?: Maybe<Checkout_Set_Input>
  where: Checkout_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Checkout_By_PkArgs = {
  _append?: Maybe<Checkout_Append_Input>
  _delete_at_path?: Maybe<Checkout_Delete_At_Path_Input>
  _delete_elem?: Maybe<Checkout_Delete_Elem_Input>
  _delete_key?: Maybe<Checkout_Delete_Key_Input>
  _inc?: Maybe<Checkout_Inc_Input>
  _prepend?: Maybe<Checkout_Prepend_Input>
  _set?: Maybe<Checkout_Set_Input>
  pk_columns: Checkout_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Checkout_ManyArgs = {
  updates: Array<Checkout_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CollectionArgs = {
  _append?: Maybe<Collection_Append_Input>
  _delete_at_path?: Maybe<Collection_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Delete_Key_Input>
  _inc?: Maybe<Collection_Inc_Input>
  _prepend?: Maybe<Collection_Prepend_Input>
  _set?: Maybe<Collection_Set_Input>
  where: Collection_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_By_PkArgs = {
  _append?: Maybe<Collection_Append_Input>
  _delete_at_path?: Maybe<Collection_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Delete_Key_Input>
  _inc?: Maybe<Collection_Inc_Input>
  _prepend?: Maybe<Collection_Prepend_Input>
  _set?: Maybe<Collection_Set_Input>
  pk_columns: Collection_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Cart_ItemArgs = {
  _append?: Maybe<Collection_Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Collection_Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Collection_Cart_Item_Inc_Input>
  _prepend?: Maybe<Collection_Cart_Item_Prepend_Input>
  _set?: Maybe<Collection_Cart_Item_Set_Input>
  where: Collection_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Cart_Item_By_PkArgs = {
  _append?: Maybe<Collection_Cart_Item_Append_Input>
  _delete_at_path?: Maybe<Collection_Cart_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Collection_Cart_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Collection_Cart_Item_Delete_Key_Input>
  _inc?: Maybe<Collection_Cart_Item_Inc_Input>
  _prepend?: Maybe<Collection_Cart_Item_Prepend_Input>
  _set?: Maybe<Collection_Cart_Item_Set_Input>
  pk_columns: Collection_Cart_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Cart_Item_ManyArgs = {
  updates: Array<Collection_Cart_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_CollaboratorArgs = {
  _set?: Maybe<Collection_Collaborator_Set_Input>
  where: Collection_Collaborator_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Collaborator_By_PkArgs = {
  _set?: Maybe<Collection_Collaborator_Set_Input>
  pk_columns: Collection_Collaborator_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Collaborator_ManyArgs = {
  updates: Array<Collection_Collaborator_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_InviteArgs = {
  _set?: Maybe<Collection_Invite_Set_Input>
  where: Collection_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_By_PkArgs = {
  _set?: Maybe<Collection_Invite_Set_Input>
  pk_columns: Collection_Invite_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_ManyArgs = {
  updates: Array<Collection_Invite_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_StatusArgs = {
  _set?: Maybe<Collection_Invite_Status_Set_Input>
  where: Collection_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_Status_By_PkArgs = {
  _set?: Maybe<Collection_Invite_Status_Set_Input>
  pk_columns: Collection_Invite_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Invite_Status_ManyArgs = {
  updates: Array<Collection_Invite_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_ClaimArgs = {
  _set?: Maybe<Collection_Item_Claim_Set_Input>
  where: Collection_Item_Claim_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_By_PkArgs = {
  _set?: Maybe<Collection_Item_Claim_Set_Input>
  pk_columns: Collection_Item_Claim_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_ManyArgs = {
  updates: Array<Collection_Item_Claim_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_StatusArgs = {
  _set?: Maybe<Collection_Item_Claim_Status_Set_Input>
  where: Collection_Item_Claim_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_Status_By_PkArgs = {
  _set?: Maybe<Collection_Item_Claim_Status_Set_Input>
  pk_columns: Collection_Item_Claim_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Item_Claim_Status_ManyArgs = {
  updates: Array<Collection_Item_Claim_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_ManyArgs = {
  updates: Array<Collection_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_SectionArgs = {
  _inc?: Maybe<Collection_Section_Inc_Input>
  _set?: Maybe<Collection_Section_Set_Input>
  where: Collection_Section_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_By_PkArgs = {
  _inc?: Maybe<Collection_Section_Inc_Input>
  _set?: Maybe<Collection_Section_Set_Input>
  pk_columns: Collection_Section_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_Cart_ItemArgs = {
  _inc?: Maybe<Collection_Section_Cart_Item_Inc_Input>
  _set?: Maybe<Collection_Section_Cart_Item_Set_Input>
  where: Collection_Section_Cart_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_Cart_Item_By_PkArgs = {
  _inc?: Maybe<Collection_Section_Cart_Item_Inc_Input>
  _set?: Maybe<Collection_Section_Cart_Item_Set_Input>
  pk_columns: Collection_Section_Cart_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_Cart_Item_ManyArgs = {
  updates: Array<Collection_Section_Cart_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Section_ManyArgs = {
  updates: Array<Collection_Section_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Slug_HistoryArgs = {
  _set?: Maybe<Collection_Slug_History_Set_Input>
  where: Collection_Slug_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Slug_History_By_PkArgs = {
  _set?: Maybe<Collection_Slug_History_Set_Input>
  pk_columns: Collection_Slug_History_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Slug_History_ManyArgs = {
  updates: Array<Collection_Slug_History_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_StateArgs = {
  _set?: Maybe<Collection_State_Set_Input>
  where: Collection_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_State_By_PkArgs = {
  _set?: Maybe<Collection_State_Set_Input>
  pk_columns: Collection_State_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_State_ManyArgs = {
  updates: Array<Collection_State_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Collection_TypeArgs = {
  _set?: Maybe<Collection_Type_Set_Input>
  where: Collection_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Type_By_PkArgs = {
  _set?: Maybe<Collection_Type_Set_Input>
  pk_columns: Collection_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Collection_Type_ManyArgs = {
  updates: Array<Collection_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_CommentArgs = {
  _inc?: Maybe<Comment_Inc_Input>
  _set?: Maybe<Comment_Set_Input>
  where: Comment_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Comment_By_PkArgs = {
  _inc?: Maybe<Comment_Inc_Input>
  _set?: Maybe<Comment_Set_Input>
  pk_columns: Comment_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Comment_ManyArgs = {
  updates: Array<Comment_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_ItemArgs = {
  _set?: Maybe<Commentable_Item_Set_Input>
  where: Commentable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_By_PkArgs = {
  _set?: Maybe<Commentable_Item_Set_Input>
  pk_columns: Commentable_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_ManyArgs = {
  updates: Array<Commentable_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_TypeArgs = {
  _set?: Maybe<Commentable_Item_Type_Set_Input>
  where: Commentable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_Type_By_PkArgs = {
  _set?: Maybe<Commentable_Item_Type_Set_Input>
  pk_columns: Commentable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Commentable_Item_Type_ManyArgs = {
  updates: Array<Commentable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_TemplateArgs = {
  _set?: Maybe<Communication_Template_Set_Input>
  where: Communication_Template_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_By_PkArgs = {
  _set?: Maybe<Communication_Template_Set_Input>
  pk_columns: Communication_Template_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_Full_ListArgs = {
  _set?: Maybe<Communication_Template_Full_List_Set_Input>
  where: Communication_Template_Full_List_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_Full_List_By_PkArgs = {
  _set?: Maybe<Communication_Template_Full_List_Set_Input>
  pk_columns: Communication_Template_Full_List_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_Full_List_ManyArgs = {
  updates: Array<Communication_Template_Full_List_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Communication_Template_ManyArgs = {
  updates: Array<Communication_Template_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Info_TypeArgs = {
  _set?: Maybe<Contact_Info_Type_Set_Input>
  where: Contact_Info_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Info_Type_By_PkArgs = {
  _set?: Maybe<Contact_Info_Type_Set_Input>
  pk_columns: Contact_Info_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Info_Type_ManyArgs = {
  updates: Array<Contact_Info_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_InviteArgs = {
  _set?: Maybe<Contact_Invite_Set_Input>
  where: Contact_Invite_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_By_PkArgs = {
  _set?: Maybe<Contact_Invite_Set_Input>
  pk_columns: Contact_Invite_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_ManyArgs = {
  updates: Array<Contact_Invite_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_StatusArgs = {
  _set?: Maybe<Contact_Invite_Status_Set_Input>
  where: Contact_Invite_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Status_By_PkArgs = {
  _set?: Maybe<Contact_Invite_Status_Set_Input>
  pk_columns: Contact_Invite_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Status_ManyArgs = {
  updates: Array<Contact_Invite_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_TypeArgs = {
  _set?: Maybe<Contact_Invite_Type_Set_Input>
  where: Contact_Invite_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Type_By_PkArgs = {
  _set?: Maybe<Contact_Invite_Type_Set_Input>
  pk_columns: Contact_Invite_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Contact_Invite_Type_ManyArgs = {
  updates: Array<Contact_Invite_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Deal_Hop_GateArgs = {
  _append?: Maybe<Deal_Hop_Gate_Append_Input>
  _delete_at_path?: Maybe<Deal_Hop_Gate_Delete_At_Path_Input>
  _delete_elem?: Maybe<Deal_Hop_Gate_Delete_Elem_Input>
  _delete_key?: Maybe<Deal_Hop_Gate_Delete_Key_Input>
  _prepend?: Maybe<Deal_Hop_Gate_Prepend_Input>
  _set?: Maybe<Deal_Hop_Gate_Set_Input>
  where: Deal_Hop_Gate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Deal_Hop_Gate_By_PkArgs = {
  _append?: Maybe<Deal_Hop_Gate_Append_Input>
  _delete_at_path?: Maybe<Deal_Hop_Gate_Delete_At_Path_Input>
  _delete_elem?: Maybe<Deal_Hop_Gate_Delete_Elem_Input>
  _delete_key?: Maybe<Deal_Hop_Gate_Delete_Key_Input>
  _prepend?: Maybe<Deal_Hop_Gate_Prepend_Input>
  _set?: Maybe<Deal_Hop_Gate_Set_Input>
  pk_columns: Deal_Hop_Gate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Deal_Hop_Gate_ManyArgs = {
  updates: Array<Deal_Hop_Gate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Device_Client_DataArgs = {
  _set?: Maybe<Device_Client_Data_Set_Input>
  where: Device_Client_Data_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Device_Client_Data_By_PkArgs = {
  _set?: Maybe<Device_Client_Data_Set_Input>
  pk_columns: Device_Client_Data_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Device_Client_Data_ManyArgs = {
  updates: Array<Device_Client_Data_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_BacklogArgs = {
  _inc?: Maybe<Discovery_Backlog_Inc_Input>
  _set?: Maybe<Discovery_Backlog_Set_Input>
  where: Discovery_Backlog_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Backlog_By_PkArgs = {
  _inc?: Maybe<Discovery_Backlog_Inc_Input>
  _set?: Maybe<Discovery_Backlog_Set_Input>
  pk_columns: Discovery_Backlog_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Backlog_ManyArgs = {
  updates: Array<Discovery_Backlog_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_FeedArgs = {
  _inc?: Maybe<Discovery_Feed_Inc_Input>
  _set?: Maybe<Discovery_Feed_Set_Input>
  where: Discovery_Feed_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Feed_By_PkArgs = {
  _inc?: Maybe<Discovery_Feed_Inc_Input>
  _set?: Maybe<Discovery_Feed_Set_Input>
  pk_columns: Discovery_Feed_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Feed_ManyArgs = {
  updates: Array<Discovery_Feed_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_StagingArgs = {
  _inc?: Maybe<Discovery_Staging_Inc_Input>
  _set?: Maybe<Discovery_Staging_Set_Input>
  where: Discovery_Staging_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Staging_By_PkArgs = {
  _inc?: Maybe<Discovery_Staging_Inc_Input>
  _set?: Maybe<Discovery_Staging_Set_Input>
  pk_columns: Discovery_Staging_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Discovery_Staging_ManyArgs = {
  updates: Array<Discovery_Staging_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Featured_ShopArgs = {
  _set?: Maybe<Featured_Shop_Set_Input>
  where: Featured_Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_By_PkArgs = {
  _set?: Maybe<Featured_Shop_Set_Input>
  pk_columns: Featured_Shop_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_ItemArgs = {
  _set?: Maybe<Featured_Shop_Item_Set_Input>
  where: Featured_Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_Item_By_PkArgs = {
  _set?: Maybe<Featured_Shop_Item_Set_Input>
  pk_columns: Featured_Shop_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_Item_ManyArgs = {
  updates: Array<Featured_Shop_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Featured_Shop_ManyArgs = {
  updates: Array<Featured_Shop_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Feed_ItemArgs = {
  _append?: Maybe<Feed_Item_Append_Input>
  _delete_at_path?: Maybe<Feed_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Feed_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Feed_Item_Delete_Key_Input>
  _prepend?: Maybe<Feed_Item_Prepend_Input>
  _set?: Maybe<Feed_Item_Set_Input>
  where: Feed_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_By_PkArgs = {
  _append?: Maybe<Feed_Item_Append_Input>
  _delete_at_path?: Maybe<Feed_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Feed_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Feed_Item_Delete_Key_Input>
  _prepend?: Maybe<Feed_Item_Prepend_Input>
  _set?: Maybe<Feed_Item_Set_Input>
  pk_columns: Feed_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_LikeArgs = {
  _inc?: Maybe<Feed_Item_Like_Inc_Input>
  _set?: Maybe<Feed_Item_Like_Set_Input>
  where: Feed_Item_Like_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Like_By_PkArgs = {
  _inc?: Maybe<Feed_Item_Like_Inc_Input>
  _set?: Maybe<Feed_Item_Like_Set_Input>
  pk_columns: Feed_Item_Like_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Like_ManyArgs = {
  updates: Array<Feed_Item_Like_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_ManyArgs = {
  updates: Array<Feed_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_TypeArgs = {
  _set?: Maybe<Feed_Item_Type_Set_Input>
  where: Feed_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Type_By_PkArgs = {
  _set?: Maybe<Feed_Item_Type_Set_Input>
  pk_columns: Feed_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Feed_Item_Type_ManyArgs = {
  updates: Array<Feed_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Fmtc_DealArgs = {
  _append?: Maybe<Fmtc_Deal_Append_Input>
  _delete_at_path?: Maybe<Fmtc_Deal_Delete_At_Path_Input>
  _delete_elem?: Maybe<Fmtc_Deal_Delete_Elem_Input>
  _delete_key?: Maybe<Fmtc_Deal_Delete_Key_Input>
  _inc?: Maybe<Fmtc_Deal_Inc_Input>
  _prepend?: Maybe<Fmtc_Deal_Prepend_Input>
  _set?: Maybe<Fmtc_Deal_Set_Input>
  where: Fmtc_Deal_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Fmtc_Deal_By_PkArgs = {
  _append?: Maybe<Fmtc_Deal_Append_Input>
  _delete_at_path?: Maybe<Fmtc_Deal_Delete_At_Path_Input>
  _delete_elem?: Maybe<Fmtc_Deal_Delete_Elem_Input>
  _delete_key?: Maybe<Fmtc_Deal_Delete_Key_Input>
  _inc?: Maybe<Fmtc_Deal_Inc_Input>
  _prepend?: Maybe<Fmtc_Deal_Prepend_Input>
  _set?: Maybe<Fmtc_Deal_Set_Input>
  pk_columns: Fmtc_Deal_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Fmtc_Deal_ManyArgs = {
  updates: Array<Fmtc_Deal_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Followable_ItemArgs = {
  _set?: Maybe<Followable_Item_Set_Input>
  where: Followable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_By_PkArgs = {
  _set?: Maybe<Followable_Item_Set_Input>
  pk_columns: Followable_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_ManyArgs = {
  updates: Array<Followable_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_TypeArgs = {
  _set?: Maybe<Followable_Item_Type_Set_Input>
  where: Followable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_Type_By_PkArgs = {
  _set?: Maybe<Followable_Item_Type_Set_Input>
  pk_columns: Followable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Followable_Item_Type_ManyArgs = {
  updates: Array<Followable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Last_Seen_Notification_Center_Item_Sequential_IdArgs =
  {
    args?: Maybe<Update_Last_Seen_Notification_Center_Item_Sequential_Id_Args>
    distinct_on?: Maybe<Array<User_Flags_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<User_Flags_Order_By>>
    where?: Maybe<User_Flags_Bool_Exp>
  }

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_EventArgs = {
  _inc?: Maybe<Leaderboard_Event_Inc_Input>
  _set?: Maybe<Leaderboard_Event_Set_Input>
  where: Leaderboard_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_By_PkArgs = {
  _inc?: Maybe<Leaderboard_Event_Inc_Input>
  _set?: Maybe<Leaderboard_Event_Set_Input>
  pk_columns: Leaderboard_Event_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_ManyArgs = {
  updates: Array<Leaderboard_Event_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_NameArgs = {
  _set?: Maybe<Leaderboard_Event_Name_Set_Input>
  where: Leaderboard_Event_Name_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_Name_By_PkArgs = {
  _set?: Maybe<Leaderboard_Event_Name_Set_Input>
  pk_columns: Leaderboard_Event_Name_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Leaderboard_Event_Name_ManyArgs = {
  updates: Array<Leaderboard_Event_Name_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Link_TypeArgs = {
  _set?: Maybe<Link_Type_Set_Input>
  where: Link_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Link_Type_By_PkArgs = {
  _set?: Maybe<Link_Type_Set_Input>
  pk_columns: Link_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Link_Type_ManyArgs = {
  updates: Array<Link_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_Enabled_ItemArgs = {
  _set?: Maybe<Media_Enabled_Item_Set_Input>
  where: Media_Enabled_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Enabled_Item_By_PkArgs = {
  _set?: Maybe<Media_Enabled_Item_Set_Input>
  pk_columns: Media_Enabled_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Enabled_Item_ManyArgs = {
  updates: Array<Media_Enabled_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_ItemArgs = {
  _set?: Maybe<Media_Item_Set_Input>
  where: Media_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_By_PkArgs = {
  _set?: Maybe<Media_Item_Set_Input>
  pk_columns: Media_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_ManyArgs = {
  updates: Array<Media_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_TypeArgs = {
  _set?: Maybe<Media_Item_Type_Set_Input>
  where: Media_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_Type_By_PkArgs = {
  _set?: Maybe<Media_Item_Type_Set_Input>
  pk_columns: Media_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Item_Type_ManyArgs = {
  updates: Array<Media_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_ItemsArgs = {
  _set?: Maybe<Media_Items_Set_Input>
  where: Media_Items_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Items_By_PkArgs = {
  _set?: Maybe<Media_Items_Set_Input>
  pk_columns: Media_Items_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Items_ManyArgs = {
  updates: Array<Media_Items_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Media_TypeArgs = {
  _set?: Maybe<Media_Type_Set_Input>
  where: Media_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Media_Type_By_PkArgs = {
  _set?: Maybe<Media_Type_Set_Input>
  pk_columns: Media_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Media_Type_ManyArgs = {
  updates: Array<Media_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_App_HomeArgs = {
  _inc?: Maybe<Mobile_App_Home_Inc_Input>
  _set?: Maybe<Mobile_App_Home_Set_Input>
  where: Mobile_App_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_App_Home_By_PkArgs = {
  _inc?: Maybe<Mobile_App_Home_Inc_Input>
  _set?: Maybe<Mobile_App_Home_Set_Input>
  pk_columns: Mobile_App_Home_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_App_Home_ManyArgs = {
  updates: Array<Mobile_App_Home_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_HomeArgs = {
  _inc?: Maybe<Mobile_Home_Inc_Input>
  _set?: Maybe<Mobile_Home_Set_Input>
  where: Mobile_Home_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Home_By_PkArgs = {
  _inc?: Maybe<Mobile_Home_Inc_Input>
  _set?: Maybe<Mobile_Home_Set_Input>
  pk_columns: Mobile_Home_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Home_ManyArgs = {
  updates: Array<Mobile_Home_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_NotificationArgs = {
  _append?: Maybe<Mobile_Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Mobile_Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Mobile_Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Mobile_Push_Notification_Delete_Key_Input>
  _inc?: Maybe<Mobile_Push_Notification_Inc_Input>
  _prepend?: Maybe<Mobile_Push_Notification_Prepend_Input>
  _set?: Maybe<Mobile_Push_Notification_Set_Input>
  where: Mobile_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_By_PkArgs = {
  _append?: Maybe<Mobile_Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Mobile_Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Mobile_Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Mobile_Push_Notification_Delete_Key_Input>
  _inc?: Maybe<Mobile_Push_Notification_Inc_Input>
  _prepend?: Maybe<Mobile_Push_Notification_Prepend_Input>
  _set?: Maybe<Mobile_Push_Notification_Set_Input>
  pk_columns: Mobile_Push_Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_ManyArgs = {
  updates: Array<Mobile_Push_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_StatusArgs = {
  _set?: Maybe<Mobile_Push_Notification_Status_Set_Input>
  where: Mobile_Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_Status_By_PkArgs = {
  _set?: Maybe<Mobile_Push_Notification_Status_Set_Input>
  pk_columns: Mobile_Push_Notification_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mobile_Push_Notification_Status_ManyArgs = {
  updates: Array<Mobile_Push_Notification_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_ItemArgs = {
  _set?: Maybe<Notifiable_Item_Set_Input>
  where: Notifiable_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_By_PkArgs = {
  _set?: Maybe<Notifiable_Item_Set_Input>
  pk_columns: Notifiable_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_ManyArgs = {
  updates: Array<Notifiable_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_TypeArgs = {
  _set?: Maybe<Notifiable_Item_Type_Set_Input>
  where: Notifiable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_Type_By_PkArgs = {
  _set?: Maybe<Notifiable_Item_Type_Set_Input>
  pk_columns: Notifiable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notifiable_Item_Type_ManyArgs = {
  updates: Array<Notifiable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_NotificationArgs = {
  _append?: Maybe<Notification_Append_Input>
  _delete_at_path?: Maybe<Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Notification_Delete_Key_Input>
  _prepend?: Maybe<Notification_Prepend_Input>
  _set?: Maybe<Notification_Set_Input>
  where: Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_By_PkArgs = {
  _append?: Maybe<Notification_Append_Input>
  _delete_at_path?: Maybe<Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Notification_Delete_Key_Input>
  _prepend?: Maybe<Notification_Prepend_Input>
  _set?: Maybe<Notification_Set_Input>
  pk_columns: Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_ItemArgs = {
  _inc?: Maybe<Notification_Center_Item_Inc_Input>
  _set?: Maybe<Notification_Center_Item_Set_Input>
  where: Notification_Center_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_By_PkArgs = {
  _inc?: Maybe<Notification_Center_Item_Inc_Input>
  _set?: Maybe<Notification_Center_Item_Set_Input>
  pk_columns: Notification_Center_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_ManyArgs = {
  updates: Array<Notification_Center_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Read_StatusArgs = {
  _set?: Maybe<Notification_Center_Item_Read_Status_Set_Input>
  where: Notification_Center_Item_Read_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Read_Status_By_PkArgs =
  {
    _set?: Maybe<Notification_Center_Item_Read_Status_Set_Input>
    pk_columns: Notification_Center_Item_Read_Status_Pk_Columns_Input
  }

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Read_Status_ManyArgs =
  {
    updates: Array<Notification_Center_Item_Read_Status_Updates>
  }

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_TypeArgs = {
  _set?: Maybe<Notification_Center_Item_Type_Set_Input>
  where: Notification_Center_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Type_By_PkArgs = {
  _set?: Maybe<Notification_Center_Item_Type_Set_Input>
  pk_columns: Notification_Center_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Center_Item_Type_ManyArgs = {
  updates: Array<Notification_Center_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_ManyArgs = {
  updates: Array<Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_PreferenceArgs = {
  _set?: Maybe<Notification_Preference_Set_Input>
  where: Notification_Preference_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Preference_By_PkArgs = {
  _set?: Maybe<Notification_Preference_Set_Input>
  pk_columns: Notification_Preference_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Preference_ManyArgs = {
  updates: Array<Notification_Preference_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Notification_StatusArgs = {
  _set?: Maybe<Notification_Status_Set_Input>
  where: Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Status_By_PkArgs = {
  _set?: Maybe<Notification_Status_Set_Input>
  pk_columns: Notification_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Notification_Status_ManyArgs = {
  updates: Array<Notification_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_CategoriesArgs = {
  _set?: Maybe<Onboarding_Categories_Set_Input>
  where: Onboarding_Categories_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Categories_By_PkArgs = {
  _set?: Maybe<Onboarding_Categories_Set_Input>
  pk_columns: Onboarding_Categories_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Categories_ManyArgs = {
  updates: Array<Onboarding_Categories_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_SourcesArgs = {
  _set?: Maybe<Onboarding_Sources_Set_Input>
  where: Onboarding_Sources_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sources_By_PkArgs = {
  _set?: Maybe<Onboarding_Sources_Set_Input>
  pk_columns: Onboarding_Sources_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sources_ManyArgs = {
  updates: Array<Onboarding_Sources_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_One_Off_EmailArgs = {
  _append?: Maybe<One_Off_Email_Append_Input>
  _delete_at_path?: Maybe<One_Off_Email_Delete_At_Path_Input>
  _delete_elem?: Maybe<One_Off_Email_Delete_Elem_Input>
  _delete_key?: Maybe<One_Off_Email_Delete_Key_Input>
  _prepend?: Maybe<One_Off_Email_Prepend_Input>
  _set?: Maybe<One_Off_Email_Set_Input>
  where: One_Off_Email_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_One_Off_Email_By_PkArgs = {
  _append?: Maybe<One_Off_Email_Append_Input>
  _delete_at_path?: Maybe<One_Off_Email_Delete_At_Path_Input>
  _delete_elem?: Maybe<One_Off_Email_Delete_Elem_Input>
  _delete_key?: Maybe<One_Off_Email_Delete_Key_Input>
  _prepend?: Maybe<One_Off_Email_Prepend_Input>
  _set?: Maybe<One_Off_Email_Set_Input>
  pk_columns: One_Off_Email_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_One_Off_Email_ManyArgs = {
  updates: Array<One_Off_Email_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Pageview_EventArgs = {
  _append?: Maybe<Pageview_Event_Append_Input>
  _delete_at_path?: Maybe<Pageview_Event_Delete_At_Path_Input>
  _delete_elem?: Maybe<Pageview_Event_Delete_Elem_Input>
  _delete_key?: Maybe<Pageview_Event_Delete_Key_Input>
  _prepend?: Maybe<Pageview_Event_Prepend_Input>
  _set?: Maybe<Pageview_Event_Set_Input>
  where: Pageview_Event_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Pageview_Event_By_PkArgs = {
  _append?: Maybe<Pageview_Event_Append_Input>
  _delete_at_path?: Maybe<Pageview_Event_Delete_At_Path_Input>
  _delete_elem?: Maybe<Pageview_Event_Delete_Elem_Input>
  _delete_key?: Maybe<Pageview_Event_Delete_Key_Input>
  _prepend?: Maybe<Pageview_Event_Prepend_Input>
  _set?: Maybe<Pageview_Event_Set_Input>
  pk_columns: Pageview_Event_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Pageview_Event_ManyArgs = {
  updates: Array<Pageview_Event_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Promo_PeriodArgs = {
  _set?: Maybe<Promo_Period_Set_Input>
  where: Promo_Period_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Promo_Period_By_PkArgs = {
  _set?: Maybe<Promo_Period_Set_Input>
  pk_columns: Promo_Period_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Promo_Period_ManyArgs = {
  updates: Array<Promo_Period_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Push_NotificationArgs = {
  _append?: Maybe<Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Push_Notification_Delete_Key_Input>
  _prepend?: Maybe<Push_Notification_Prepend_Input>
  _set?: Maybe<Push_Notification_Set_Input>
  where: Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_By_PkArgs = {
  _append?: Maybe<Push_Notification_Append_Input>
  _delete_at_path?: Maybe<Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<Push_Notification_Delete_Key_Input>
  _prepend?: Maybe<Push_Notification_Prepend_Input>
  _set?: Maybe<Push_Notification_Set_Input>
  pk_columns: Push_Notification_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_ManyArgs = {
  updates: Array<Push_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_StatusArgs = {
  _set?: Maybe<Push_Notification_Status_Set_Input>
  where: Push_Notification_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_Status_By_PkArgs = {
  _set?: Maybe<Push_Notification_Status_Set_Input>
  pk_columns: Push_Notification_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Push_Notification_Status_ManyArgs = {
  updates: Array<Push_Notification_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Redirect_IdsArgs = {
  _set?: Maybe<Redirect_Ids_Set_Input>
  where: Redirect_Ids_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Redirect_Ids_By_PkArgs = {
  _set?: Maybe<Redirect_Ids_Set_Input>
  pk_columns: Redirect_Ids_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Redirect_Ids_ManyArgs = {
  updates: Array<Redirect_Ids_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Remove_ReasonArgs = {
  _set?: Maybe<Remove_Reason_Set_Input>
  where: Remove_Reason_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Remove_Reason_By_PkArgs = {
  _set?: Maybe<Remove_Reason_Set_Input>
  pk_columns: Remove_Reason_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Remove_Reason_ManyArgs = {
  updates: Array<Remove_Reason_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Action_NamesArgs = {
  _set?: Maybe<Rewardable_Action_Names_Set_Input>
  where: Rewardable_Action_Names_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Action_Names_By_PkArgs = {
  _set?: Maybe<Rewardable_Action_Names_Set_Input>
  pk_columns: Rewardable_Action_Names_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Action_Names_ManyArgs = {
  updates: Array<Rewardable_Action_Names_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Item_TypeArgs = {
  _set?: Maybe<Rewardable_Item_Type_Set_Input>
  where: Rewardable_Item_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Item_Type_By_PkArgs = {
  _set?: Maybe<Rewardable_Item_Type_Set_Input>
  pk_columns: Rewardable_Item_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Rewardable_Item_Type_ManyArgs = {
  updates: Array<Rewardable_Item_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Search_QueryArgs = {
  _inc?: Maybe<Search_Query_Inc_Input>
  _set?: Maybe<Search_Query_Set_Input>
  where: Search_Query_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_By_PkArgs = {
  _inc?: Maybe<Search_Query_Inc_Input>
  _set?: Maybe<Search_Query_Set_Input>
  pk_columns: Search_Query_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_ManyArgs = {
  updates: Array<Search_Query_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_ResultArgs = {
  _inc?: Maybe<Search_Query_Result_Inc_Input>
  _set?: Maybe<Search_Query_Result_Set_Input>
  where: Search_Query_Result_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_Result_By_PkArgs = {
  _inc?: Maybe<Search_Query_Result_Inc_Input>
  _set?: Maybe<Search_Query_Result_Set_Input>
  pk_columns: Search_Query_Result_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Search_Query_Result_ManyArgs = {
  updates: Array<Search_Query_Result_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ShopArgs = {
  _append?: Maybe<Shop_Append_Input>
  _delete_at_path?: Maybe<Shop_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Delete_Key_Input>
  _prepend?: Maybe<Shop_Prepend_Input>
  _set?: Maybe<Shop_Set_Input>
  where: Shop_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_AffiliateArgs = {
  _set?: Maybe<Shop_Affiliate_Set_Input>
  where: Shop_Affiliate_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Affiliate_By_PkArgs = {
  _set?: Maybe<Shop_Affiliate_Set_Input>
  pk_columns: Shop_Affiliate_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Affiliate_ManyArgs = {
  updates: Array<Shop_Affiliate_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_By_PkArgs = {
  _append?: Maybe<Shop_Append_Input>
  _delete_at_path?: Maybe<Shop_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Delete_Key_Input>
  _prepend?: Maybe<Shop_Prepend_Input>
  _set?: Maybe<Shop_Set_Input>
  pk_columns: Shop_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_CategorizationArgs = {
  _inc?: Maybe<Shop_Categorization_Inc_Input>
  _set?: Maybe<Shop_Categorization_Set_Input>
  where: Shop_Categorization_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Categorization_By_PkArgs = {
  _inc?: Maybe<Shop_Categorization_Inc_Input>
  _set?: Maybe<Shop_Categorization_Set_Input>
  pk_columns: Shop_Categorization_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Categorization_ManyArgs = {
  updates: Array<Shop_Categorization_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_CategoryArgs = {
  _set?: Maybe<Shop_Category_Set_Input>
  where: Shop_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Category_By_PkArgs = {
  _set?: Maybe<Shop_Category_Set_Input>
  pk_columns: Shop_Category_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Category_ManyArgs = {
  updates: Array<Shop_Category_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_ItemArgs = {
  _append?: Maybe<Shop_Item_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_Inc_Input>
  _prepend?: Maybe<Shop_Item_Prepend_Input>
  _set?: Maybe<Shop_Item_Set_Input>
  where: Shop_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_AvailabilityArgs = {
  _set?: Maybe<Shop_Item_Availability_Set_Input>
  where: Shop_Item_Availability_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Availability_By_PkArgs = {
  _set?: Maybe<Shop_Item_Availability_Set_Input>
  pk_columns: Shop_Item_Availability_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Availability_ManyArgs = {
  updates: Array<Shop_Item_Availability_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_By_PkArgs = {
  _append?: Maybe<Shop_Item_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_Inc_Input>
  _prepend?: Maybe<Shop_Item_Prepend_Input>
  _set?: Maybe<Shop_Item_Set_Input>
  pk_columns: Shop_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Enrichment_StatusArgs = {
  _set?: Maybe<Shop_Item_Enrichment_Status_Set_Input>
  where: Shop_Item_Enrichment_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Enrichment_Status_By_PkArgs = {
  _set?: Maybe<Shop_Item_Enrichment_Status_Set_Input>
  pk_columns: Shop_Item_Enrichment_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Enrichment_Status_ManyArgs = {
  updates: Array<Shop_Item_Enrichment_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_HistoryArgs = {
  _append?: Maybe<Shop_Item_History_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_History_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_History_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_History_Inc_Input>
  _prepend?: Maybe<Shop_Item_History_Prepend_Input>
  _set?: Maybe<Shop_Item_History_Set_Input>
  where: Shop_Item_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_History_By_PkArgs = {
  _append?: Maybe<Shop_Item_History_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_History_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_History_Delete_Key_Input>
  _inc?: Maybe<Shop_Item_History_Inc_Input>
  _prepend?: Maybe<Shop_Item_History_Prepend_Input>
  _set?: Maybe<Shop_Item_History_Set_Input>
  pk_columns: Shop_Item_History_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_History_ManyArgs = {
  updates: Array<Shop_Item_History_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_ImageArgs = {
  _append?: Maybe<Shop_Item_Image_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Image_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Image_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Image_Delete_Key_Input>
  _prepend?: Maybe<Shop_Item_Image_Prepend_Input>
  _set?: Maybe<Shop_Item_Image_Set_Input>
  where: Shop_Item_Image_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Image_By_PkArgs = {
  _append?: Maybe<Shop_Item_Image_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_Image_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_Image_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_Image_Delete_Key_Input>
  _prepend?: Maybe<Shop_Item_Image_Prepend_Input>
  _set?: Maybe<Shop_Item_Image_Set_Input>
  pk_columns: Shop_Item_Image_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Image_ManyArgs = {
  updates: Array<Shop_Item_Image_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_IndexArgs = {
  _set?: Maybe<Shop_Item_Index_Set_Input>
  where: Shop_Item_Index_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Index_By_PkArgs = {
  _set?: Maybe<Shop_Item_Index_Set_Input>
  pk_columns: Shop_Item_Index_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_Index_ManyArgs = {
  updates: Array<Shop_Item_Index_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_ManyArgs = {
  updates: Array<Shop_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_To_Be_ScrapedArgs = {
  _append?: Maybe<Shop_Item_To_Be_Scraped_Append_Input>
  _delete_at_path?: Maybe<Shop_Item_To_Be_Scraped_Delete_At_Path_Input>
  _delete_elem?: Maybe<Shop_Item_To_Be_Scraped_Delete_Elem_Input>
  _delete_key?: Maybe<Shop_Item_To_Be_Scraped_Delete_Key_Input>
  _prepend?: Maybe<Shop_Item_To_Be_Scraped_Prepend_Input>
  _set?: Maybe<Shop_Item_To_Be_Scraped_Set_Input>
  where: Shop_Item_To_Be_Scraped_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Item_To_Be_Scraped_ManyArgs = {
  updates: Array<Shop_Item_To_Be_Scraped_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_ManyArgs = {
  updates: Array<Shop_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Shop_RatingArgs = {
  _inc?: Maybe<Shop_Rating_Inc_Input>
  _set?: Maybe<Shop_Rating_Set_Input>
  where: Shop_Rating_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Rating_By_PkArgs = {
  _inc?: Maybe<Shop_Rating_Inc_Input>
  _set?: Maybe<Shop_Rating_Set_Input>
  pk_columns: Shop_Rating_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Shop_Rating_ManyArgs = {
  updates: Array<Shop_Rating_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Similar_Products_HistoryArgs = {
  _append?: Maybe<Similar_Products_History_Append_Input>
  _delete_at_path?: Maybe<Similar_Products_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Similar_Products_History_Delete_Elem_Input>
  _delete_key?: Maybe<Similar_Products_History_Delete_Key_Input>
  _prepend?: Maybe<Similar_Products_History_Prepend_Input>
  _set?: Maybe<Similar_Products_History_Set_Input>
  where: Similar_Products_History_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Similar_Products_History_By_PkArgs = {
  _append?: Maybe<Similar_Products_History_Append_Input>
  _delete_at_path?: Maybe<Similar_Products_History_Delete_At_Path_Input>
  _delete_elem?: Maybe<Similar_Products_History_Delete_Elem_Input>
  _delete_key?: Maybe<Similar_Products_History_Delete_Key_Input>
  _prepend?: Maybe<Similar_Products_History_Prepend_Input>
  _set?: Maybe<Similar_Products_History_Set_Input>
  pk_columns: Similar_Products_History_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Similar_Products_History_ManyArgs = {
  updates: Array<Similar_Products_History_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _append?: Maybe<User_Append_Input>
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Delete_Elem_Input>
  _delete_key?: Maybe<User_Delete_Key_Input>
  _inc?: Maybe<User_Inc_Input>
  _prepend?: Maybe<User_Prepend_Input>
  _set?: Maybe<User_Set_Input>
  where: User_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Account_TypeArgs = {
  _set?: Maybe<User_Account_Type_Set_Input>
  where: User_Account_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Account_Type_By_PkArgs = {
  _set?: Maybe<User_Account_Type_Set_Input>
  pk_columns: User_Account_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Account_Type_ManyArgs = {
  updates: Array<User_Account_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ActionArgs = {
  _inc?: Maybe<User_Action_Inc_Input>
  _set?: Maybe<User_Action_Set_Input>
  where: User_Action_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Action_By_PkArgs = {
  _inc?: Maybe<User_Action_Inc_Input>
  _set?: Maybe<User_Action_Set_Input>
  pk_columns: User_Action_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Action_ManyArgs = {
  updates: Array<User_Action_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_App_VersionArgs = {
  _append?: Maybe<User_App_Version_Append_Input>
  _delete_at_path?: Maybe<User_App_Version_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_App_Version_Delete_Elem_Input>
  _delete_key?: Maybe<User_App_Version_Delete_Key_Input>
  _inc?: Maybe<User_App_Version_Inc_Input>
  _prepend?: Maybe<User_App_Version_Prepend_Input>
  _set?: Maybe<User_App_Version_Set_Input>
  where: User_App_Version_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_App_Version_By_PkArgs = {
  _append?: Maybe<User_App_Version_Append_Input>
  _delete_at_path?: Maybe<User_App_Version_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_App_Version_Delete_Elem_Input>
  _delete_key?: Maybe<User_App_Version_Delete_Key_Input>
  _inc?: Maybe<User_App_Version_Inc_Input>
  _prepend?: Maybe<User_App_Version_Prepend_Input>
  _set?: Maybe<User_App_Version_Set_Input>
  pk_columns: User_App_Version_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_App_Version_ManyArgs = {
  updates: Array<User_App_Version_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _append?: Maybe<User_Append_Input>
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Delete_Elem_Input>
  _delete_key?: Maybe<User_Delete_Key_Input>
  _inc?: Maybe<User_Inc_Input>
  _prepend?: Maybe<User_Prepend_Input>
  _set?: Maybe<User_Set_Input>
  pk_columns: User_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Carts_OnboardingArgs = {
  _inc?: Maybe<User_Carts_Onboarding_Inc_Input>
  _set?: Maybe<User_Carts_Onboarding_Set_Input>
  where: User_Carts_Onboarding_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Carts_Onboarding_By_PkArgs = {
  _inc?: Maybe<User_Carts_Onboarding_Inc_Input>
  _set?: Maybe<User_Carts_Onboarding_Set_Input>
  pk_columns: User_Carts_Onboarding_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Carts_Onboarding_ManyArgs = {
  updates: Array<User_Carts_Onboarding_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_CategoryArgs = {
  _set?: Maybe<User_Category_Set_Input>
  where: User_Category_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Category_By_PkArgs = {
  _set?: Maybe<User_Category_Set_Input>
  pk_columns: User_Category_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Category_ManyArgs = {
  updates: Array<User_Category_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_DeletionArgs = {
  _append?: Maybe<User_Deletion_Append_Input>
  _delete_at_path?: Maybe<User_Deletion_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Deletion_Delete_Elem_Input>
  _delete_key?: Maybe<User_Deletion_Delete_Key_Input>
  _prepend?: Maybe<User_Deletion_Prepend_Input>
  _set?: Maybe<User_Deletion_Set_Input>
  where: User_Deletion_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Deletion_By_PkArgs = {
  _append?: Maybe<User_Deletion_Append_Input>
  _delete_at_path?: Maybe<User_Deletion_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Deletion_Delete_Elem_Input>
  _delete_key?: Maybe<User_Deletion_Delete_Key_Input>
  _prepend?: Maybe<User_Deletion_Prepend_Input>
  _set?: Maybe<User_Deletion_Set_Input>
  pk_columns: User_Deletion_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Deletion_ManyArgs = {
  updates: Array<User_Deletion_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_FeedbackArgs = {
  _set?: Maybe<User_Feedback_Set_Input>
  where: User_Feedback_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_By_PkArgs = {
  _set?: Maybe<User_Feedback_Set_Input>
  pk_columns: User_Feedback_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_ItemArgs = {
  _set?: Maybe<User_Feedback_Item_Set_Input>
  where: User_Feedback_Item_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Item_By_PkArgs = {
  _set?: Maybe<User_Feedback_Item_Set_Input>
  pk_columns: User_Feedback_Item_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Item_ManyArgs = {
  updates: Array<User_Feedback_Item_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_ManyArgs = {
  updates: Array<User_Feedback_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_TypeArgs = {
  _set?: Maybe<User_Feedback_Type_Set_Input>
  where: User_Feedback_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Type_By_PkArgs = {
  _set?: Maybe<User_Feedback_Type_Set_Input>
  pk_columns: User_Feedback_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Feedback_Type_ManyArgs = {
  updates: Array<User_Feedback_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_FlagsArgs = {
  _append?: Maybe<User_Flags_Append_Input>
  _delete_at_path?: Maybe<User_Flags_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Flags_Delete_Elem_Input>
  _delete_key?: Maybe<User_Flags_Delete_Key_Input>
  _inc?: Maybe<User_Flags_Inc_Input>
  _prepend?: Maybe<User_Flags_Prepend_Input>
  _set?: Maybe<User_Flags_Set_Input>
  where: User_Flags_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Flags_By_PkArgs = {
  _append?: Maybe<User_Flags_Append_Input>
  _delete_at_path?: Maybe<User_Flags_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Flags_Delete_Elem_Input>
  _delete_key?: Maybe<User_Flags_Delete_Key_Input>
  _inc?: Maybe<User_Flags_Inc_Input>
  _prepend?: Maybe<User_Flags_Prepend_Input>
  _set?: Maybe<User_Flags_Set_Input>
  pk_columns: User_Flags_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Flags_ManyArgs = {
  updates: Array<User_Flags_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ImpersonationArgs = {
  _set?: Maybe<User_Impersonation_Set_Input>
  where: User_Impersonation_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Impersonation_By_PkArgs = {
  _set?: Maybe<User_Impersonation_Set_Input>
  pk_columns: User_Impersonation_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Impersonation_ManyArgs = {
  updates: Array<User_Impersonation_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Ip_AddressArgs = {
  _set?: Maybe<User_Ip_Address_Set_Input>
  where: User_Ip_Address_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Ip_Address_By_PkArgs = {
  _set?: Maybe<User_Ip_Address_Set_Input>
  pk_columns: User_Ip_Address_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Ip_Address_ManyArgs = {
  updates: Array<User_Ip_Address_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Items_FollowedArgs = {
  _set?: Maybe<User_Items_Followed_Set_Input>
  where: User_Items_Followed_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Items_Followed_By_PkArgs = {
  _set?: Maybe<User_Items_Followed_Set_Input>
  pk_columns: User_Items_Followed_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Items_Followed_ManyArgs = {
  updates: Array<User_Items_Followed_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_LinksArgs = {
  _set?: Maybe<User_Links_Set_Input>
  where: User_Links_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Links_By_PkArgs = {
  _set?: Maybe<User_Links_Set_Input>
  pk_columns: User_Links_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Links_ManyArgs = {
  updates: Array<User_Links_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ManyArgs = {
  updates: Array<User_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_SourceArgs = {
  _set?: Maybe<User_Onboarding_Source_Set_Input>
  where: User_Onboarding_Source_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_Source_By_PkArgs = {
  _set?: Maybe<User_Onboarding_Source_Set_Input>
  pk_columns: User_Onboarding_Source_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_Source_ManyArgs = {
  updates: Array<User_Onboarding_Source_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_StateArgs = {
  _set?: Maybe<User_Onboarding_State_Set_Input>
  where: User_Onboarding_State_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_State_By_PkArgs = {
  _set?: Maybe<User_Onboarding_State_Set_Input>
  pk_columns: User_Onboarding_State_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_State_ManyArgs = {
  updates: Array<User_Onboarding_State_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Phone_ContactArgs = {
  _set?: Maybe<User_Phone_Contact_Set_Input>
  where: User_Phone_Contact_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Phone_Contact_By_PkArgs = {
  _set?: Maybe<User_Phone_Contact_Set_Input>
  pk_columns: User_Phone_Contact_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Phone_Contact_ManyArgs = {
  updates: Array<User_Phone_Contact_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchase_SubscriptionArgs = {
  _set?: Maybe<User_Purchase_Subscription_Set_Input>
  where: User_Purchase_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchase_Subscription_By_PkArgs = {
  _set?: Maybe<User_Purchase_Subscription_Set_Input>
  pk_columns: User_Purchase_Subscription_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchase_Subscription_ManyArgs = {
  updates: Array<User_Purchase_Subscription_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_PurchasesArgs = {
  _set?: Maybe<User_Purchases_Set_Input>
  where: User_Purchases_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchases_By_PkArgs = {
  _set?: Maybe<User_Purchases_Set_Input>
  pk_columns: User_Purchases_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Purchases_ManyArgs = {
  updates: Array<User_Purchases_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_NotificationArgs = {
  _append?: Maybe<User_Push_Notification_Append_Input>
  _delete_at_path?: Maybe<User_Push_Notification_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Push_Notification_Delete_Elem_Input>
  _delete_key?: Maybe<User_Push_Notification_Delete_Key_Input>
  _prepend?: Maybe<User_Push_Notification_Prepend_Input>
  _set?: Maybe<User_Push_Notification_Set_Input>
  where: User_Push_Notification_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_ManyArgs = {
  updates: Array<User_Push_Notification_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_TokenArgs = {
  _set?: Maybe<User_Push_Notification_Token_Set_Input>
  where: User_Push_Notification_Token_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_Token_By_PkArgs = {
  _set?: Maybe<User_Push_Notification_Token_Set_Input>
  pk_columns: User_Push_Notification_Token_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Push_Notification_Token_ManyArgs = {
  updates: Array<User_Push_Notification_Token_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_ReferralArgs = {
  _inc?: Maybe<User_Referral_Inc_Input>
  _set?: Maybe<User_Referral_Set_Input>
  where: User_Referral_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Referral_By_PkArgs = {
  _inc?: Maybe<User_Referral_Inc_Input>
  _set?: Maybe<User_Referral_Set_Input>
  pk_columns: User_Referral_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Referral_ManyArgs = {
  updates: Array<User_Referral_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Multiplier_ConfigArgs = {
  _inc?: Maybe<User_Reward_Multiplier_Config_Inc_Input>
  _set?: Maybe<User_Reward_Multiplier_Config_Set_Input>
  where: User_Reward_Multiplier_Config_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Multiplier_Config_By_PkArgs = {
  _inc?: Maybe<User_Reward_Multiplier_Config_Inc_Input>
  _set?: Maybe<User_Reward_Multiplier_Config_Set_Input>
  pk_columns: User_Reward_Multiplier_Config_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Multiplier_Config_ManyArgs = {
  updates: Array<User_Reward_Multiplier_Config_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_RedemptionArgs = {
  _inc?: Maybe<User_Reward_Redemption_Inc_Input>
  _set?: Maybe<User_Reward_Redemption_Set_Input>
  where: User_Reward_Redemption_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Redemption_By_PkArgs = {
  _inc?: Maybe<User_Reward_Redemption_Inc_Input>
  _set?: Maybe<User_Reward_Redemption_Set_Input>
  pk_columns: User_Reward_Redemption_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Redemption_ManyArgs = {
  updates: Array<User_Reward_Redemption_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_ReferenceArgs = {
  _append?: Maybe<User_Reward_Reference_Append_Input>
  _delete_at_path?: Maybe<User_Reward_Reference_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Reward_Reference_Delete_Elem_Input>
  _delete_key?: Maybe<User_Reward_Reference_Delete_Key_Input>
  _inc?: Maybe<User_Reward_Reference_Inc_Input>
  _prepend?: Maybe<User_Reward_Reference_Prepend_Input>
  _set?: Maybe<User_Reward_Reference_Set_Input>
  where: User_Reward_Reference_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Reference_By_PkArgs = {
  _append?: Maybe<User_Reward_Reference_Append_Input>
  _delete_at_path?: Maybe<User_Reward_Reference_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Reward_Reference_Delete_Elem_Input>
  _delete_key?: Maybe<User_Reward_Reference_Delete_Key_Input>
  _inc?: Maybe<User_Reward_Reference_Inc_Input>
  _prepend?: Maybe<User_Reward_Reference_Prepend_Input>
  _set?: Maybe<User_Reward_Reference_Set_Input>
  pk_columns: User_Reward_Reference_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Reward_Reference_ManyArgs = {
  updates: Array<User_Reward_Reference_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Shop_FavoriteArgs = {
  _set?: Maybe<User_Shop_Favorite_Set_Input>
  where: User_Shop_Favorite_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Shop_Favorite_By_PkArgs = {
  _set?: Maybe<User_Shop_Favorite_Set_Input>
  pk_columns: User_Shop_Favorite_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Shop_Favorite_ManyArgs = {
  updates: Array<User_Shop_Favorite_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Shopping_PreferencesArgs = {
  _set?: Maybe<User_Shopping_Preferences_Set_Input>
  where: User_Shopping_Preferences_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Shopping_Preferences_By_PkArgs = {
  _set?: Maybe<User_Shopping_Preferences_Set_Input>
  pk_columns: User_Shopping_Preferences_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Shopping_Preferences_ManyArgs = {
  updates: Array<User_Shopping_Preferences_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_SubscriptionArgs = {
  _append?: Maybe<User_Subscription_Append_Input>
  _delete_at_path?: Maybe<User_Subscription_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Subscription_Delete_Elem_Input>
  _delete_key?: Maybe<User_Subscription_Delete_Key_Input>
  _prepend?: Maybe<User_Subscription_Prepend_Input>
  _set?: Maybe<User_Subscription_Set_Input>
  where: User_Subscription_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_By_PkArgs = {
  _append?: Maybe<User_Subscription_Append_Input>
  _delete_at_path?: Maybe<User_Subscription_Delete_At_Path_Input>
  _delete_elem?: Maybe<User_Subscription_Delete_Elem_Input>
  _delete_key?: Maybe<User_Subscription_Delete_Key_Input>
  _prepend?: Maybe<User_Subscription_Prepend_Input>
  _set?: Maybe<User_Subscription_Set_Input>
  pk_columns: User_Subscription_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_ManyArgs = {
  updates: Array<User_Subscription_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_StatusArgs = {
  _set?: Maybe<User_Subscription_Status_Set_Input>
  where: User_Subscription_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_Status_By_PkArgs = {
  _set?: Maybe<User_Subscription_Status_Set_Input>
  pk_columns: User_Subscription_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Subscription_Status_ManyArgs = {
  updates: Array<User_Subscription_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_User_TempArgs = {
  _set?: Maybe<User_Temp_Set_Input>
  where: User_Temp_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_User_Temp_By_PkArgs = {
  _set?: Maybe<User_Temp_Set_Input>
  pk_columns: User_Temp_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_User_Temp_ManyArgs = {
  updates: Array<User_Temp_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Worflow_TypeArgs = {
  _set?: Maybe<Worflow_Type_Set_Input>
  where: Worflow_Type_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Worflow_Type_By_PkArgs = {
  _set?: Maybe<Worflow_Type_Set_Input>
  pk_columns: Worflow_Type_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Worflow_Type_ManyArgs = {
  updates: Array<Worflow_Type_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_WorkflowArgs = {
  _append?: Maybe<Workflow_Append_Input>
  _delete_at_path?: Maybe<Workflow_Delete_At_Path_Input>
  _delete_elem?: Maybe<Workflow_Delete_Elem_Input>
  _delete_key?: Maybe<Workflow_Delete_Key_Input>
  _prepend?: Maybe<Workflow_Prepend_Input>
  _set?: Maybe<Workflow_Set_Input>
  where: Workflow_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_By_PkArgs = {
  _append?: Maybe<Workflow_Append_Input>
  _delete_at_path?: Maybe<Workflow_Delete_At_Path_Input>
  _delete_elem?: Maybe<Workflow_Delete_Elem_Input>
  _delete_key?: Maybe<Workflow_Delete_Key_Input>
  _prepend?: Maybe<Workflow_Prepend_Input>
  _set?: Maybe<Workflow_Set_Input>
  pk_columns: Workflow_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_ManyArgs = {
  updates: Array<Workflow_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_StatusArgs = {
  _set?: Maybe<Workflow_Status_Set_Input>
  where: Workflow_Status_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_Status_By_PkArgs = {
  _set?: Maybe<Workflow_Status_Set_Input>
  pk_columns: Workflow_Status_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Workflow_Status_ManyArgs = {
  updates: Array<Workflow_Status_Updates>
}

/** mutation root */
export type Mutation_RootUpsert_Notification_PreferenceArgs = {
  args: Upsert_Notification_Preference_Args
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: Maybe<Scalars['name']>
  _gt?: Maybe<Scalars['name']>
  _gte?: Maybe<Scalars['name']>
  _in?: Maybe<Array<Scalars['name']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['name']>
  _lte?: Maybe<Scalars['name']>
  _neq?: Maybe<Scalars['name']>
  _nin?: Maybe<Array<Scalars['name']>>
}

/** columns and relationships of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User = {
  __typename?: 'notifiable_feed_item_user'
  id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Aggregate = {
  __typename?: 'notifiable_feed_item_user_aggregate'
  aggregate?: Maybe<Notifiable_Feed_Item_User_Aggregate_Fields>
  nodes: Array<Notifiable_Feed_Item_User>
}

/** aggregate fields of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Aggregate_Fields = {
  __typename?: 'notifiable_feed_item_user_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notifiable_Feed_Item_User_Max_Fields>
  min?: Maybe<Notifiable_Feed_Item_User_Min_Fields>
}

/** aggregate fields of "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notifiable_feed_item_user". All fields are combined with a logical 'AND'. */
export type Notifiable_Feed_Item_User_Bool_Exp = {
  _and?: Maybe<Array<Notifiable_Feed_Item_User_Bool_Exp>>
  _not?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
  _or?: Maybe<Array<Notifiable_Feed_Item_User_Bool_Exp>>
  id?: Maybe<Uuid_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** input type for inserting data into table "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Insert_Input = {
  id?: Maybe<Scalars['uuid']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notifiable_Feed_Item_User_Max_Fields = {
  __typename?: 'notifiable_feed_item_user_max_fields'
  id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notifiable_Feed_Item_User_Min_Fields = {
  __typename?: 'notifiable_feed_item_user_min_fields'
  id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** input type for inserting object relation for remote table "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Obj_Rel_Insert_Input = {
  data: Notifiable_Feed_Item_User_Insert_Input
}

/** Ordering options when selecting data from "notifiable_feed_item_user". */
export type Notifiable_Feed_Item_User_Order_By = {
  id?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "notifiable_feed_item_user" */
export enum Notifiable_Feed_Item_User_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "notifiable_feed_item_user" */
export type Notifiable_Feed_Item_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifiable_Feed_Item_User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notifiable_Feed_Item_User_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to keep track of all the allowable polymorphic notifiable items */
export type Notifiable_Item = {
  __typename?: 'notifiable_item'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  /** An object relationship */
  collection?: Maybe<Collection>
  /** An object relationship */
  collection_cart_item?: Maybe<Collection_Cart_Item>
  /** An object relationship */
  collection_invite?: Maybe<Collection_Invite>
  /** An object relationship */
  comment?: Maybe<Comment>
  /** An object relationship */
  feed_item_like?: Maybe<Feed_Item_Like>
  /** An object relationship */
  follow?: Maybe<User_Items_Followed>
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

/** aggregated selection of "notifiable_item" */
export type Notifiable_Item_Aggregate = {
  __typename?: 'notifiable_item_aggregate'
  aggregate?: Maybe<Notifiable_Item_Aggregate_Fields>
  nodes: Array<Notifiable_Item>
}

/** aggregate fields of "notifiable_item" */
export type Notifiable_Item_Aggregate_Fields = {
  __typename?: 'notifiable_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notifiable_Item_Max_Fields>
  min?: Maybe<Notifiable_Item_Min_Fields>
}

/** aggregate fields of "notifiable_item" */
export type Notifiable_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifiable_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notifiable_item". All fields are combined with a logical 'AND'. */
export type Notifiable_Item_Bool_Exp = {
  _and?: Maybe<Array<Notifiable_Item_Bool_Exp>>
  _not?: Maybe<Notifiable_Item_Bool_Exp>
  _or?: Maybe<Array<Notifiable_Item_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  collection?: Maybe<Collection_Bool_Exp>
  collection_cart_item?: Maybe<Collection_Cart_Item_Bool_Exp>
  collection_invite?: Maybe<Collection_Invite_Bool_Exp>
  comment?: Maybe<Comment_Bool_Exp>
  feed_item_like?: Maybe<Feed_Item_Like_Bool_Exp>
  follow?: Maybe<User_Items_Followed_Bool_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
}

/** unique or primary key constraints on table "notifiable_item" */
export enum Notifiable_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  NotifiableItemItemIdItemTypeKey = 'notifiable_item_item_id_item_type_key',
  /** unique or primary key constraint on columns "item_id", "item_type" */
  NotifiableItemPkey = 'notifiable_item_pkey',
}

/** input type for inserting data into table "notifiable_item" */
export type Notifiable_Item_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  collection?: Maybe<Collection_Obj_Rel_Insert_Input>
  collection_cart_item?: Maybe<Collection_Cart_Item_Obj_Rel_Insert_Input>
  collection_invite?: Maybe<Collection_Invite_Obj_Rel_Insert_Input>
  comment?: Maybe<Comment_Obj_Rel_Insert_Input>
  feed_item_like?: Maybe<Feed_Item_Like_Obj_Rel_Insert_Input>
  follow?: Maybe<User_Items_Followed_Obj_Rel_Insert_Input>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Notifiable_Item_Type_Enum>
}

/** aggregate max on columns */
export type Notifiable_Item_Max_Fields = {
  __typename?: 'notifiable_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type Notifiable_Item_Min_Fields = {
  __typename?: 'notifiable_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "notifiable_item" */
export type Notifiable_Item_Mutation_Response = {
  __typename?: 'notifiable_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notifiable_Item>
}

/** input type for inserting object relation for remote table "notifiable_item" */
export type Notifiable_Item_Obj_Rel_Insert_Input = {
  data: Notifiable_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Notifiable_Item_On_Conflict>
}

/** on_conflict condition type for table "notifiable_item" */
export type Notifiable_Item_On_Conflict = {
  constraint: Notifiable_Item_Constraint
  update_columns?: Array<Notifiable_Item_Update_Column>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

/** Ordering options when selecting data from "notifiable_item". */
export type Notifiable_Item_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  collection?: Maybe<Collection_Order_By>
  collection_cart_item?: Maybe<Collection_Cart_Item_Order_By>
  collection_invite?: Maybe<Collection_Invite_Order_By>
  comment?: Maybe<Comment_Order_By>
  feed_item_like?: Maybe<Feed_Item_Like_Order_By>
  follow?: Maybe<User_Items_Followed_Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
}

/** primary key columns input for table: notifiable_item */
export type Notifiable_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

/** select columns of table "notifiable_item" */
export enum Notifiable_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "notifiable_item" */
export type Notifiable_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Notifiable_Item_Type_Enum>
}

/** Streaming cursor of the table "notifiable_item" */
export type Notifiable_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifiable_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notifiable_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Notifiable_Item_Type_Enum>
}

/** An enum table to hold the different notifiable item types */
export type Notifiable_Item_Type = {
  __typename?: 'notifiable_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "notifiable_item_type" */
export type Notifiable_Item_Type_Aggregate = {
  __typename?: 'notifiable_item_type_aggregate'
  aggregate?: Maybe<Notifiable_Item_Type_Aggregate_Fields>
  nodes: Array<Notifiable_Item_Type>
}

/** aggregate fields of "notifiable_item_type" */
export type Notifiable_Item_Type_Aggregate_Fields = {
  __typename?: 'notifiable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notifiable_Item_Type_Max_Fields>
  min?: Maybe<Notifiable_Item_Type_Min_Fields>
}

/** aggregate fields of "notifiable_item_type" */
export type Notifiable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notifiable_item_type". All fields are combined with a logical 'AND'. */
export type Notifiable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Notifiable_Item_Type_Bool_Exp>>
  _not?: Maybe<Notifiable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Notifiable_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notifiable_item_type" */
export enum Notifiable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotifiableItemTypePkey = 'notifiable_item_type_pkey',
}

export enum Notifiable_Item_Type_Enum {
  CartItem = 'cart_item',
  Collection = 'collection',
  CollectionCartItem = 'collection_cart_item',
  CollectionInvite = 'collection_invite',
  Comment = 'comment',
  DealHopGate = 'deal_hop_gate',
  FeedItemLike = 'feed_item_like',
  UserItemsFollowed = 'user_items_followed',
}

/** Boolean expression to compare columns of type "notifiable_item_type_enum". All fields are combined with logical 'AND'. */
export type Notifiable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Notifiable_Item_Type_Enum>
  _in?: Maybe<Array<Notifiable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Notifiable_Item_Type_Enum>
  _nin?: Maybe<Array<Notifiable_Item_Type_Enum>>
}

/** input type for inserting data into table "notifiable_item_type" */
export type Notifiable_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notifiable_Item_Type_Max_Fields = {
  __typename?: 'notifiable_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notifiable_Item_Type_Min_Fields = {
  __typename?: 'notifiable_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "notifiable_item_type" */
export type Notifiable_Item_Type_Mutation_Response = {
  __typename?: 'notifiable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notifiable_Item_Type>
}

/** on_conflict condition type for table "notifiable_item_type" */
export type Notifiable_Item_Type_On_Conflict = {
  constraint: Notifiable_Item_Type_Constraint
  update_columns?: Array<Notifiable_Item_Type_Update_Column>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "notifiable_item_type". */
export type Notifiable_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: notifiable_item_type */
export type Notifiable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "notifiable_item_type" */
export enum Notifiable_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "notifiable_item_type" */
export type Notifiable_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notifiable_item_type" */
export type Notifiable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifiable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notifiable_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "notifiable_item_type" */
export enum Notifiable_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Notifiable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notifiable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Notifiable_Item_Type_Bool_Exp
}

/** update columns of table "notifiable_item" */
export enum Notifiable_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type Notifiable_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notifiable_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Notifiable_Item_Bool_Exp
}

/** columns and relationships of "notification" */
export type Notification = {
  __typename?: 'notification'
  active: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  criteria: Scalars['jsonb']
  id: Scalars['uuid']
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  /** An array relationship */
  notification_status: Array<Notification_Status>
  /** An aggregate relationship */
  notification_status_aggregate: Notification_Status_Aggregate
  payload: Scalars['jsonb']
  show_after: Scalars['timestamptz']
  show_before: Scalars['timestamptz']
  updated_at: Scalars['timestamptz']
}

/** columns and relationships of "notification" */
export type NotificationCriteriaArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "notification" */
export type NotificationNotification_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

/** columns and relationships of "notification" */
export type NotificationNotification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

/** columns and relationships of "notification" */
export type NotificationPayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "notification" */
export type Notification_Aggregate = {
  __typename?: 'notification_aggregate'
  aggregate?: Maybe<Notification_Aggregate_Fields>
  nodes: Array<Notification>
}

/** aggregate fields of "notification" */
export type Notification_Aggregate_Fields = {
  __typename?: 'notification_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Max_Fields>
  min?: Maybe<Notification_Min_Fields>
}

/** aggregate fields of "notification" */
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notification_Append_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: Maybe<Array<Notification_Bool_Exp>>
  _not?: Maybe<Notification_Bool_Exp>
  _or?: Maybe<Array<Notification_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  criteria?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<String_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  notification_status?: Maybe<Notification_Status_Bool_Exp>
  notification_status_aggregate?: Maybe<Notification_Status_Aggregate_Bool_Exp>
  payload?: Maybe<Jsonb_Comparison_Exp>
  show_after?: Maybe<Timestamptz_Comparison_Exp>
  show_before?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

export type Notification_Center_Feed_For_User_Args = {
  read?: Maybe<Scalars['Boolean']>
  tasks?: Maybe<Scalars['Boolean']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to hold distinct items that should show up in the notification center */
export type Notification_Center_Item = {
  __typename?: 'notification_center_item'
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  notifiable_item?: Maybe<Notifiable_Item>
  notifiable_type?: Maybe<Scalars['String']>
  /** Grabs the timestamp for when a notification center item was read for a given user */
  read_at?: Maybe<Scalars['timestamptz']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id: Scalars['Int']
  type: Notification_Center_Item_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "notification_center_item" */
export type Notification_Center_Item_Aggregate = {
  __typename?: 'notification_center_item_aggregate'
  aggregate?: Maybe<Notification_Center_Item_Aggregate_Fields>
  nodes: Array<Notification_Center_Item>
}

export type Notification_Center_Item_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp_Count>
}

export type Notification_Center_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Center_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Center_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Center_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Notification_Center_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Center_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "notification_center_item" */
export type Notification_Center_Item_Aggregate_Fields = {
  __typename?: 'notification_center_item_aggregate_fields'
  avg?: Maybe<Notification_Center_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Notification_Center_Item_Max_Fields>
  min?: Maybe<Notification_Center_Item_Min_Fields>
  stddev?: Maybe<Notification_Center_Item_Stddev_Fields>
  stddev_pop?: Maybe<Notification_Center_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Notification_Center_Item_Stddev_Samp_Fields>
  sum?: Maybe<Notification_Center_Item_Sum_Fields>
  var_pop?: Maybe<Notification_Center_Item_Var_Pop_Fields>
  var_samp?: Maybe<Notification_Center_Item_Var_Samp_Fields>
  variance?: Maybe<Notification_Center_Item_Variance_Fields>
}

/** aggregate fields of "notification_center_item" */
export type Notification_Center_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Center_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notification_center_item" */
export type Notification_Center_Item_Aggregate_Order_By = {
  avg?: Maybe<Notification_Center_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Notification_Center_Item_Max_Order_By>
  min?: Maybe<Notification_Center_Item_Min_Order_By>
  stddev?: Maybe<Notification_Center_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Notification_Center_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Notification_Center_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Notification_Center_Item_Sum_Order_By>
  var_pop?: Maybe<Notification_Center_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Notification_Center_Item_Var_Samp_Order_By>
  variance?: Maybe<Notification_Center_Item_Variance_Order_By>
}

/** input type for inserting array relation for remote table "notification_center_item" */
export type Notification_Center_Item_Arr_Rel_Insert_Input = {
  data: Array<Notification_Center_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Notification_Center_Item_On_Conflict>
}

/** aggregate avg on columns */
export type Notification_Center_Item_Avg_Fields = {
  __typename?: 'notification_center_item_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "notification_center_item" */
export type Notification_Center_Item_Avg_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "notification_center_item". All fields are combined with a logical 'AND'. */
export type Notification_Center_Item_Bool_Exp = {
  _and?: Maybe<Array<Notification_Center_Item_Bool_Exp>>
  _not?: Maybe<Notification_Center_Item_Bool_Exp>
  _or?: Maybe<Array<Notification_Center_Item_Bool_Exp>>
  banner?: Maybe<Boolean_Comparison_Exp>
  body?: Maybe<String_Comparison_Exp>
  communication_template?: Maybe<Communication_Template_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  link?: Maybe<String_Comparison_Exp>
  main_image?: Maybe<String_Comparison_Exp>
  notifiable_id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item?: Maybe<Notifiable_Item_Bool_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  read_at?: Maybe<Timestamptz_Comparison_Exp>
  secondary_image?: Maybe<String_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  type?: Maybe<Notification_Center_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_center_item" */
export enum Notification_Center_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationCenterItemPkey = 'notification_center_item_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  NotificationCenterItemSequentialIdKey = 'notification_center_item_sequential_id_key',
  /** unique or primary key constraint on columns "user_id", "communication_template", "notifiable_type", "notifiable_id" */
  NotificationCenterItemUserIdCommunicationTemplateNotIdx = 'notification_center_item_user_id_communication_template_not_idx',
}

/** input type for incrementing numeric columns in table "notification_center_item" */
export type Notification_Center_Item_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "notification_center_item" */
export type Notification_Center_Item_Insert_Input = {
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_item?: Maybe<Notifiable_Item_Obj_Rel_Insert_Input>
  notifiable_type?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  type?: Maybe<Notification_Center_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Center_Item_Max_Fields = {
  __typename?: 'notification_center_item_max_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  /** Grabs the timestamp for when a notification center item was read for a given user */
  read_at?: Maybe<Scalars['timestamptz']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "notification_center_item" */
export type Notification_Center_Item_Max_Order_By = {
  body?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link?: Maybe<Order_By>
  main_image?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  secondary_image?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Notification_Center_Item_Min_Fields = {
  __typename?: 'notification_center_item_min_fields'
  body?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  /** Grabs the timestamp for when a notification center item was read for a given user */
  read_at?: Maybe<Scalars['timestamptz']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "notification_center_item" */
export type Notification_Center_Item_Min_Order_By = {
  body?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link?: Maybe<Order_By>
  main_image?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  secondary_image?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "notification_center_item" */
export type Notification_Center_Item_Mutation_Response = {
  __typename?: 'notification_center_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Center_Item>
}

/** on_conflict condition type for table "notification_center_item" */
export type Notification_Center_Item_On_Conflict = {
  constraint: Notification_Center_Item_Constraint
  update_columns?: Array<Notification_Center_Item_Update_Column>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

/** Ordering options when selecting data from "notification_center_item". */
export type Notification_Center_Item_Order_By = {
  banner?: Maybe<Order_By>
  body?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link?: Maybe<Order_By>
  main_image?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_item?: Maybe<Notifiable_Item_Order_By>
  notifiable_type?: Maybe<Order_By>
  read_at?: Maybe<Order_By>
  secondary_image?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_center_item */
export type Notification_Center_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** A table to hold whether a user has or has not read a particular notification center item */
export type Notification_Center_Item_Read_Status = {
  __typename?: 'notification_center_item_read_status'
  created_at: Scalars['timestamptz']
  notification_center_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Aggregate = {
  __typename?: 'notification_center_item_read_status_aggregate'
  aggregate?: Maybe<Notification_Center_Item_Read_Status_Aggregate_Fields>
  nodes: Array<Notification_Center_Item_Read_Status>
}

/** aggregate fields of "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Aggregate_Fields = {
  __typename?: 'notification_center_item_read_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Center_Item_Read_Status_Max_Fields>
  min?: Maybe<Notification_Center_Item_Read_Status_Min_Fields>
}

/** aggregate fields of "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notification_center_item_read_status". All fields are combined with a logical 'AND'. */
export type Notification_Center_Item_Read_Status_Bool_Exp = {
  _and?: Maybe<Array<Notification_Center_Item_Read_Status_Bool_Exp>>
  _not?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
  _or?: Maybe<Array<Notification_Center_Item_Read_Status_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  notification_center_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_center_item_read_status" */
export enum Notification_Center_Item_Read_Status_Constraint {
  /** unique or primary key constraint on columns "user_id", "notification_center_item_id" */
  NotificationCenterItemReadStatusPkey = 'notification_center_item_read_status_pkey',
}

/** input type for inserting data into table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Center_Item_Read_Status_Max_Fields = {
  __typename?: 'notification_center_item_read_status_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notification_Center_Item_Read_Status_Min_Fields = {
  __typename?: 'notification_center_item_read_status_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Mutation_Response = {
  __typename?: 'notification_center_item_read_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Center_Item_Read_Status>
}

/** on_conflict condition type for table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_On_Conflict = {
  constraint: Notification_Center_Item_Read_Status_Constraint
  update_columns?: Array<Notification_Center_Item_Read_Status_Update_Column>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

/** Ordering options when selecting data from "notification_center_item_read_status". */
export type Notification_Center_Item_Read_Status_Order_By = {
  created_at?: Maybe<Order_By>
  notification_center_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_center_item_read_status */
export type Notification_Center_Item_Read_Status_Pk_Columns_Input = {
  notification_center_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

/** select columns of table "notification_center_item_read_status" */
export enum Notification_Center_Item_Read_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NotificationCenterItemId = 'notification_center_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_center_item_read_status" */
export type Notification_Center_Item_Read_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Center_Item_Read_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Center_Item_Read_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  notification_center_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "notification_center_item_read_status" */
export enum Notification_Center_Item_Read_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NotificationCenterItemId = 'notification_center_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Center_Item_Read_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Center_Item_Read_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Center_Item_Read_Status_Bool_Exp
}

/** select columns of table "notification_center_item" */
export enum Notification_Center_Item_Select_Column {
  /** column name */
  Banner = 'banner',
  /** column name */
  Body = 'body',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MainImage = 'main_image',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  SecondaryImage = 'secondary_image',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "notification_center_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_center_item" */
export enum Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Banner = 'banner',
}

/** select "notification_center_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_center_item" */
export enum Notification_Center_Item_Select_Column_Notification_Center_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Banner = 'banner',
}

/** input type for updating data in table "notification_center_item" */
export type Notification_Center_Item_Set_Input = {
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  type?: Maybe<Notification_Center_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Notification_Center_Item_Stddev_Fields = {
  __typename?: 'notification_center_item_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "notification_center_item" */
export type Notification_Center_Item_Stddev_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Notification_Center_Item_Stddev_Pop_Fields = {
  __typename?: 'notification_center_item_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "notification_center_item" */
export type Notification_Center_Item_Stddev_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Notification_Center_Item_Stddev_Samp_Fields = {
  __typename?: 'notification_center_item_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "notification_center_item" */
export type Notification_Center_Item_Stddev_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Streaming cursor of the table "notification_center_item" */
export type Notification_Center_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Center_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Center_Item_Stream_Cursor_Value_Input = {
  banner?: Maybe<Scalars['Boolean']>
  body?: Maybe<Scalars['String']>
  communication_template?: Maybe<Communication_Template_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link?: Maybe<Scalars['String']>
  main_image?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  secondary_image?: Maybe<Scalars['String']>
  sequential_id?: Maybe<Scalars['Int']>
  type?: Maybe<Notification_Center_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Notification_Center_Item_Sum_Fields = {
  __typename?: 'notification_center_item_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "notification_center_item" */
export type Notification_Center_Item_Sum_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** An enum table of the different types of notification center items */
export type Notification_Center_Item_Type = {
  __typename?: 'notification_center_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "notification_center_item_type" */
export type Notification_Center_Item_Type_Aggregate = {
  __typename?: 'notification_center_item_type_aggregate'
  aggregate?: Maybe<Notification_Center_Item_Type_Aggregate_Fields>
  nodes: Array<Notification_Center_Item_Type>
}

/** aggregate fields of "notification_center_item_type" */
export type Notification_Center_Item_Type_Aggregate_Fields = {
  __typename?: 'notification_center_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Center_Item_Type_Max_Fields>
  min?: Maybe<Notification_Center_Item_Type_Min_Fields>
}

/** aggregate fields of "notification_center_item_type" */
export type Notification_Center_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "notification_center_item_type". All fields are combined with a logical 'AND'. */
export type Notification_Center_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Notification_Center_Item_Type_Bool_Exp>>
  _not?: Maybe<Notification_Center_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Notification_Center_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_center_item_type" */
export enum Notification_Center_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationCenterItemTypePkey = 'notification_center_item_type_pkey',
}

export enum Notification_Center_Item_Type_Enum {
  Communication = 'communication',
  Informational = 'informational',
  Task = 'task',
}

/** Boolean expression to compare columns of type "notification_center_item_type_enum". All fields are combined with logical 'AND'. */
export type Notification_Center_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Notification_Center_Item_Type_Enum>
  _in?: Maybe<Array<Notification_Center_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Notification_Center_Item_Type_Enum>
  _nin?: Maybe<Array<Notification_Center_Item_Type_Enum>>
}

/** input type for inserting data into table "notification_center_item_type" */
export type Notification_Center_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Center_Item_Type_Max_Fields = {
  __typename?: 'notification_center_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Notification_Center_Item_Type_Min_Fields = {
  __typename?: 'notification_center_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "notification_center_item_type" */
export type Notification_Center_Item_Type_Mutation_Response = {
  __typename?: 'notification_center_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Center_Item_Type>
}

/** on_conflict condition type for table "notification_center_item_type" */
export type Notification_Center_Item_Type_On_Conflict = {
  constraint: Notification_Center_Item_Type_Constraint
  update_columns?: Array<Notification_Center_Item_Type_Update_Column>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "notification_center_item_type". */
export type Notification_Center_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: notification_center_item_type */
export type Notification_Center_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "notification_center_item_type" */
export enum Notification_Center_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "notification_center_item_type" */
export type Notification_Center_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_center_item_type" */
export type Notification_Center_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Center_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Center_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "notification_center_item_type" */
export enum Notification_Center_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Notification_Center_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Center_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Center_Item_Type_Bool_Exp
}

/** update columns of table "notification_center_item" */
export enum Notification_Center_Item_Update_Column {
  /** column name */
  Banner = 'banner',
  /** column name */
  Body = 'body',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Link = 'link',
  /** column name */
  MainImage = 'main_image',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  SecondaryImage = 'secondary_image',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Center_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Notification_Center_Item_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Center_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Center_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Notification_Center_Item_Var_Pop_Fields = {
  __typename?: 'notification_center_item_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "notification_center_item" */
export type Notification_Center_Item_Var_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Notification_Center_Item_Var_Samp_Fields = {
  __typename?: 'notification_center_item_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "notification_center_item" */
export type Notification_Center_Item_Var_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Notification_Center_Item_Variance_Fields = {
  __typename?: 'notification_center_item_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "notification_center_item" */
export type Notification_Center_Item_Variance_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notification_Delete_At_Path_Input = {
  criteria?: Maybe<Array<Scalars['String']>>
  payload?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notification_Delete_Elem_Input = {
  criteria?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notification_Delete_Key_Input = {
  criteria?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  notification_status?: Maybe<Notification_Status_Arr_Rel_Insert_Input>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Notification_Max_Fields = {
  __typename?: 'notification_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Notification_Min_Fields = {
  __typename?: 'notification_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "notification" */
export type Notification_Mutation_Response = {
  __typename?: 'notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification>
}

/** input type for inserting object relation for remote table "notification" */
export type Notification_Obj_Rel_Insert_Input = {
  data: Notification_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Notification_On_Conflict>
}

/** on_conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint
  update_columns?: Array<Notification_Update_Column>
  where?: Maybe<Notification_Bool_Exp>
}

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  criteria?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  notification_status_aggregate?: Maybe<Notification_Status_Aggregate_Order_By>
  payload?: Maybe<Order_By>
  show_after?: Maybe<Order_By>
  show_before?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** A table to hold our users' notification preferences */
export type Notification_Preference = {
  __typename?: 'notification_preference'
  allowable_communication: Array<Scalars['String']>
  allowed: Scalars['Boolean']
  communication_template: Scalars['String']
  id: Scalars['uuid']
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "notification_preference" */
export type Notification_Preference_Aggregate = {
  __typename?: 'notification_preference_aggregate'
  aggregate?: Maybe<Notification_Preference_Aggregate_Fields>
  nodes: Array<Notification_Preference>
}

export type Notification_Preference_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Notification_Preference_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Notification_Preference_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Notification_Preference_Aggregate_Bool_Exp_Count>
}

export type Notification_Preference_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Preference_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Preference_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Preference_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Preference_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Notification_Preference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Preference_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "notification_preference" */
export type Notification_Preference_Aggregate_Fields = {
  __typename?: 'notification_preference_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Preference_Max_Fields>
  min?: Maybe<Notification_Preference_Min_Fields>
}

/** aggregate fields of "notification_preference" */
export type Notification_Preference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Preference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notification_preference" */
export type Notification_Preference_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Notification_Preference_Max_Order_By>
  min?: Maybe<Notification_Preference_Min_Order_By>
}

/** input type for inserting array relation for remote table "notification_preference" */
export type Notification_Preference_Arr_Rel_Insert_Input = {
  data: Array<Notification_Preference_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Notification_Preference_On_Conflict>
}

/** Boolean expression to filter rows from the table "notification_preference". All fields are combined with a logical 'AND'. */
export type Notification_Preference_Bool_Exp = {
  _and?: Maybe<Array<Notification_Preference_Bool_Exp>>
  _not?: Maybe<Notification_Preference_Bool_Exp>
  _or?: Maybe<Array<Notification_Preference_Bool_Exp>>
  allowable_communication?: Maybe<String_Array_Comparison_Exp>
  allowed?: Maybe<Boolean_Comparison_Exp>
  communication_template?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<Uuid_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_preference" */
export enum Notification_Preference_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPreferencePkey = 'notification_preference_pkey',
  /** unique or primary key constraint on columns "user_id", "communication_template" */
  NotificationPreferenceUniqueIsNullIdx = 'notification_preference_unique_is_null_idx',
  /** unique or primary key constraint on columns "user_id", "communication_template", "notifiable_type", "notifiable_id" */
  NotificationPreferenceUniqueNotNullIdx = 'notification_preference_unique_not_null_idx',
}

/** input type for inserting data into table "notification_preference" */
export type Notification_Preference_Insert_Input = {
  allowable_communication?: Maybe<Array<Scalars['String']>>
  allowed?: Maybe<Scalars['Boolean']>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Preference_Max_Fields = {
  __typename?: 'notification_preference_max_fields'
  allowable_communication?: Maybe<Array<Scalars['String']>>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "notification_preference" */
export type Notification_Preference_Max_Order_By = {
  allowable_communication?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Notification_Preference_Min_Fields = {
  __typename?: 'notification_preference_min_fields'
  allowable_communication?: Maybe<Array<Scalars['String']>>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "notification_preference" */
export type Notification_Preference_Min_Order_By = {
  allowable_communication?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "notification_preference" */
export type Notification_Preference_Mutation_Response = {
  __typename?: 'notification_preference_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Preference>
}

/** on_conflict condition type for table "notification_preference" */
export type Notification_Preference_On_Conflict = {
  constraint: Notification_Preference_Constraint
  update_columns?: Array<Notification_Preference_Update_Column>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** Ordering options when selecting data from "notification_preference". */
export type Notification_Preference_Order_By = {
  allowable_communication?: Maybe<Order_By>
  allowed?: Maybe<Order_By>
  communication_template?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_preference */
export type Notification_Preference_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "notification_preference" */
export enum Notification_Preference_Select_Column {
  /** column name */
  AllowableCommunication = 'allowable_communication',
  /** column name */
  Allowed = 'allowed',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  UserId = 'user_id',
}

/** select "notification_preference_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_preference" */
export enum Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Allowed = 'allowed',
}

/** select "notification_preference_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_preference" */
export enum Notification_Preference_Select_Column_Notification_Preference_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Allowed = 'allowed',
}

/** input type for updating data in table "notification_preference" */
export type Notification_Preference_Set_Input = {
  allowable_communication?: Maybe<Array<Scalars['String']>>
  allowed?: Maybe<Scalars['Boolean']>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_preference" */
export type Notification_Preference_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Preference_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Preference_Stream_Cursor_Value_Input = {
  allowable_communication?: Maybe<Array<Scalars['String']>>
  allowed?: Maybe<Scalars['Boolean']>
  communication_template?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "notification_preference" */
export enum Notification_Preference_Update_Column {
  /** column name */
  AllowableCommunication = 'allowable_communication',
  /** column name */
  Allowed = 'allowed',
  /** column name */
  CommunicationTemplate = 'communication_template',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Preference_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Preference_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Preference_Bool_Exp
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notification_Prepend_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "notification_status" */
export type Notification_Status = {
  __typename?: 'notification_status'
  clicked: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  notification: Notification
  notification_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "notification_status" */
export type Notification_Status_Aggregate = {
  __typename?: 'notification_status_aggregate'
  aggregate?: Maybe<Notification_Status_Aggregate_Fields>
  nodes: Array<Notification_Status>
}

export type Notification_Status_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Notification_Status_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Notification_Status_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Notification_Status_Aggregate_Bool_Exp_Count>
}

export type Notification_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Notification_Status_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Notification_Status_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "notification_status" */
export type Notification_Status_Aggregate_Fields = {
  __typename?: 'notification_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Notification_Status_Max_Fields>
  min?: Maybe<Notification_Status_Min_Fields>
}

/** aggregate fields of "notification_status" */
export type Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "notification_status" */
export type Notification_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Notification_Status_Max_Order_By>
  min?: Maybe<Notification_Status_Min_Order_By>
}

/** input type for inserting array relation for remote table "notification_status" */
export type Notification_Status_Arr_Rel_Insert_Input = {
  data: Array<Notification_Status_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Notification_Status_On_Conflict>
}

/** Boolean expression to filter rows from the table "notification_status". All fields are combined with a logical 'AND'. */
export type Notification_Status_Bool_Exp = {
  _and?: Maybe<Array<Notification_Status_Bool_Exp>>
  _not?: Maybe<Notification_Status_Bool_Exp>
  _or?: Maybe<Array<Notification_Status_Bool_Exp>>
  clicked?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notification?: Maybe<Notification_Bool_Exp>
  notification_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "notification_status" */
export enum Notification_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationStatusPkey = 'notification_status_pkey',
  /** unique or primary key constraint on columns "user_id", "notification_id" */
  NotificationStatusUserIdNotificationIdKey = 'notification_status_user_id_notification_id_key',
}

/** input type for inserting data into table "notification_status" */
export type Notification_Status_Insert_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification?: Maybe<Notification_Obj_Rel_Insert_Input>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Notification_Status_Max_Fields = {
  __typename?: 'notification_status_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "notification_status" */
export type Notification_Status_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Notification_Status_Min_Fields = {
  __typename?: 'notification_status_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "notification_status" */
export type Notification_Status_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "notification_status" */
export type Notification_Status_Mutation_Response = {
  __typename?: 'notification_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Status>
}

/** on_conflict condition type for table "notification_status" */
export type Notification_Status_On_Conflict = {
  constraint: Notification_Status_Constraint
  update_columns?: Array<Notification_Status_Update_Column>
  where?: Maybe<Notification_Status_Bool_Exp>
}

/** Ordering options when selecting data from "notification_status". */
export type Notification_Status_Order_By = {
  clicked?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification?: Maybe<Notification_Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: notification_status */
export type Notification_Status_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "notification_status" */
export enum Notification_Status_Select_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "notification_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notification_status" */
export enum Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** select "notification_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notification_status" */
export enum Notification_Status_Select_Column_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** input type for updating data in table "notification_status" */
export type Notification_Status_Set_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "notification_status" */
export type Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Status_Stream_Cursor_Value_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "notification_status" */
export enum Notification_Status_Update_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Status_Bool_Exp
}

/** Streaming cursor of the table "notification" */
export type Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Notification_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Notification_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: Notification_Bool_Exp
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>
  _gt?: Maybe<Scalars['numeric']>
  _gte?: Maybe<Scalars['numeric']>
  _in?: Maybe<Array<Scalars['numeric']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['numeric']>
  _lte?: Maybe<Scalars['numeric']>
  _neq?: Maybe<Scalars['numeric']>
  _nin?: Maybe<Array<Scalars['numeric']>>
}

/** Used for showing category selection during onboarding */
export type Onboarding_Categories = {
  __typename?: 'onboarding_categories'
  category: Shop_Category_Enum
  featured: Scalars['Boolean']
  image: Scalars['String']
  /** An object relationship */
  shop_category: Shop_Category
}

/** aggregated selection of "onboarding_categories" */
export type Onboarding_Categories_Aggregate = {
  __typename?: 'onboarding_categories_aggregate'
  aggregate?: Maybe<Onboarding_Categories_Aggregate_Fields>
  nodes: Array<Onboarding_Categories>
}

/** aggregate fields of "onboarding_categories" */
export type Onboarding_Categories_Aggregate_Fields = {
  __typename?: 'onboarding_categories_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Onboarding_Categories_Max_Fields>
  min?: Maybe<Onboarding_Categories_Min_Fields>
}

/** aggregate fields of "onboarding_categories" */
export type Onboarding_Categories_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Onboarding_Categories_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "onboarding_categories". All fields are combined with a logical 'AND'. */
export type Onboarding_Categories_Bool_Exp = {
  _and?: Maybe<Array<Onboarding_Categories_Bool_Exp>>
  _not?: Maybe<Onboarding_Categories_Bool_Exp>
  _or?: Maybe<Array<Onboarding_Categories_Bool_Exp>>
  category?: Maybe<Shop_Category_Enum_Comparison_Exp>
  featured?: Maybe<Boolean_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  shop_category?: Maybe<Shop_Category_Bool_Exp>
}

/** unique or primary key constraints on table "onboarding_categories" */
export enum Onboarding_Categories_Constraint {
  /** unique or primary key constraint on columns "category" */
  OnboardingCategoriesPkey = 'onboarding_categories_pkey',
}

/** input type for inserting data into table "onboarding_categories" */
export type Onboarding_Categories_Insert_Input = {
  category?: Maybe<Shop_Category_Enum>
  featured?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
  shop_category?: Maybe<Shop_Category_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Onboarding_Categories_Max_Fields = {
  __typename?: 'onboarding_categories_max_fields'
  image?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Onboarding_Categories_Min_Fields = {
  __typename?: 'onboarding_categories_min_fields'
  image?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "onboarding_categories" */
export type Onboarding_Categories_Mutation_Response = {
  __typename?: 'onboarding_categories_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Onboarding_Categories>
}

/** input type for inserting object relation for remote table "onboarding_categories" */
export type Onboarding_Categories_Obj_Rel_Insert_Input = {
  data: Onboarding_Categories_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Onboarding_Categories_On_Conflict>
}

/** on_conflict condition type for table "onboarding_categories" */
export type Onboarding_Categories_On_Conflict = {
  constraint: Onboarding_Categories_Constraint
  update_columns?: Array<Onboarding_Categories_Update_Column>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

/** Ordering options when selecting data from "onboarding_categories". */
export type Onboarding_Categories_Order_By = {
  category?: Maybe<Order_By>
  featured?: Maybe<Order_By>
  image?: Maybe<Order_By>
  shop_category?: Maybe<Shop_Category_Order_By>
}

/** primary key columns input for table: onboarding_categories */
export type Onboarding_Categories_Pk_Columns_Input = {
  category: Shop_Category_Enum
}

/** select columns of table "onboarding_categories" */
export enum Onboarding_Categories_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Featured = 'featured',
  /** column name */
  Image = 'image',
}

/** input type for updating data in table "onboarding_categories" */
export type Onboarding_Categories_Set_Input = {
  category?: Maybe<Shop_Category_Enum>
  featured?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "onboarding_categories" */
export type Onboarding_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Onboarding_Categories_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Onboarding_Categories_Stream_Cursor_Value_Input = {
  category?: Maybe<Shop_Category_Enum>
  featured?: Maybe<Scalars['Boolean']>
  image?: Maybe<Scalars['String']>
}

/** update columns of table "onboarding_categories" */
export enum Onboarding_Categories_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Featured = 'featured',
  /** column name */
  Image = 'image',
}

export type Onboarding_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Onboarding_Categories_Set_Input>
  /** filter the rows which have to be updated */
  where: Onboarding_Categories_Bool_Exp
}

/** How did you hear about Carrot enum */
export type Onboarding_Sources = {
  __typename?: 'onboarding_sources'
  label?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "onboarding_sources" */
export type Onboarding_Sources_Aggregate = {
  __typename?: 'onboarding_sources_aggregate'
  aggregate?: Maybe<Onboarding_Sources_Aggregate_Fields>
  nodes: Array<Onboarding_Sources>
}

/** aggregate fields of "onboarding_sources" */
export type Onboarding_Sources_Aggregate_Fields = {
  __typename?: 'onboarding_sources_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Onboarding_Sources_Max_Fields>
  min?: Maybe<Onboarding_Sources_Min_Fields>
}

/** aggregate fields of "onboarding_sources" */
export type Onboarding_Sources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Onboarding_Sources_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "onboarding_sources". All fields are combined with a logical 'AND'. */
export type Onboarding_Sources_Bool_Exp = {
  _and?: Maybe<Array<Onboarding_Sources_Bool_Exp>>
  _not?: Maybe<Onboarding_Sources_Bool_Exp>
  _or?: Maybe<Array<Onboarding_Sources_Bool_Exp>>
  label?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "onboarding_sources" */
export enum Onboarding_Sources_Constraint {
  /** unique or primary key constraint on columns "value" */
  OnboardingSourcesPkey = 'onboarding_sources_pkey',
}

export enum Onboarding_Sources_Enum {
  /** Blog or news article */
  BlogNews = 'blog_news',
  /** Recommended by a friend */
  Friend = 'friend',
  /** Other */
  Other = 'other',
  /** Search engine (Google, Yahoo, etc.) */
  Search = 'search',
  /** Carrot's social media (@addtocarrot) */
  SocialCarrot = 'social_carrot',
  /** Someone else's social media */
  SocialOther = 'social_other',
}

/** Boolean expression to compare columns of type "onboarding_sources_enum". All fields are combined with logical 'AND'. */
export type Onboarding_Sources_Enum_Comparison_Exp = {
  _eq?: Maybe<Onboarding_Sources_Enum>
  _in?: Maybe<Array<Onboarding_Sources_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Onboarding_Sources_Enum>
  _nin?: Maybe<Array<Onboarding_Sources_Enum>>
}

/** input type for inserting data into table "onboarding_sources" */
export type Onboarding_Sources_Insert_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Onboarding_Sources_Max_Fields = {
  __typename?: 'onboarding_sources_max_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Onboarding_Sources_Min_Fields = {
  __typename?: 'onboarding_sources_min_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "onboarding_sources" */
export type Onboarding_Sources_Mutation_Response = {
  __typename?: 'onboarding_sources_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Onboarding_Sources>
}

/** input type for inserting object relation for remote table "onboarding_sources" */
export type Onboarding_Sources_Obj_Rel_Insert_Input = {
  data: Onboarding_Sources_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Onboarding_Sources_On_Conflict>
}

/** on_conflict condition type for table "onboarding_sources" */
export type Onboarding_Sources_On_Conflict = {
  constraint: Onboarding_Sources_Constraint
  update_columns?: Array<Onboarding_Sources_Update_Column>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

/** Ordering options when selecting data from "onboarding_sources". */
export type Onboarding_Sources_Order_By = {
  label?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: onboarding_sources */
export type Onboarding_Sources_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "onboarding_sources" */
export enum Onboarding_Sources_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "onboarding_sources" */
export type Onboarding_Sources_Set_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "onboarding_sources" */
export type Onboarding_Sources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Onboarding_Sources_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Onboarding_Sources_Stream_Cursor_Value_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "onboarding_sources" */
export enum Onboarding_Sources_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

export type Onboarding_Sources_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Onboarding_Sources_Set_Input>
  /** filter the rows which have to be updated */
  where: Onboarding_Sources_Bool_Exp
}

/** Table to keep track of one off emails sent to users */
export type One_Off_Email = {
  __typename?: 'one_off_email'
  created_at: Scalars['timestamptz']
  data: Scalars['jsonb']
  id: Scalars['uuid']
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** Table to keep track of one off emails sent to users */
export type One_Off_EmailDataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "one_off_email" */
export type One_Off_Email_Aggregate = {
  __typename?: 'one_off_email_aggregate'
  aggregate?: Maybe<One_Off_Email_Aggregate_Fields>
  nodes: Array<One_Off_Email>
}

/** aggregate fields of "one_off_email" */
export type One_Off_Email_Aggregate_Fields = {
  __typename?: 'one_off_email_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<One_Off_Email_Max_Fields>
  min?: Maybe<One_Off_Email_Min_Fields>
}

/** aggregate fields of "one_off_email" */
export type One_Off_Email_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<One_Off_Email_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type One_Off_Email_Append_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "one_off_email". All fields are combined with a logical 'AND'. */
export type One_Off_Email_Bool_Exp = {
  _and?: Maybe<Array<One_Off_Email_Bool_Exp>>
  _not?: Maybe<One_Off_Email_Bool_Exp>
  _or?: Maybe<Array<One_Off_Email_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  data?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  sent_at?: Maybe<Timestamptz_Comparison_Exp>
  template?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "one_off_email" */
export enum One_Off_Email_Constraint {
  /** unique or primary key constraint on columns "id" */
  OneOffEmailPkey = 'one_off_email_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type One_Off_Email_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type One_Off_Email_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type One_Off_Email_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "one_off_email" */
export type One_Off_Email_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type One_Off_Email_Max_Fields = {
  __typename?: 'one_off_email_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type One_Off_Email_Min_Fields = {
  __typename?: 'one_off_email_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "one_off_email" */
export type One_Off_Email_Mutation_Response = {
  __typename?: 'one_off_email_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<One_Off_Email>
}

/** on_conflict condition type for table "one_off_email" */
export type One_Off_Email_On_Conflict = {
  constraint: One_Off_Email_Constraint
  update_columns?: Array<One_Off_Email_Update_Column>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

/** Ordering options when selecting data from "one_off_email". */
export type One_Off_Email_Order_By = {
  created_at?: Maybe<Order_By>
  data?: Maybe<Order_By>
  id?: Maybe<Order_By>
  sent_at?: Maybe<Order_By>
  template?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: one_off_email */
export type One_Off_Email_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type One_Off_Email_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "one_off_email" */
export enum One_Off_Email_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Template = 'template',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "one_off_email" */
export type One_Off_Email_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "one_off_email" */
export type One_Off_Email_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: One_Off_Email_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type One_Off_Email_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  sent_at?: Maybe<Scalars['timestamptz']>
  template?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "one_off_email" */
export enum One_Off_Email_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  Template = 'template',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type One_Off_Email_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<One_Off_Email_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<One_Off_Email_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<One_Off_Email_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<One_Off_Email_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<One_Off_Email_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<One_Off_Email_Set_Input>
  /** filter the rows which have to be updated */
  where: One_Off_Email_Bool_Exp
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** This table stores the information of every page view */
export type Pageview_Event = {
  __typename?: 'pageview_event'
  /** An object relationship */
  actor?: Maybe<User>
  created_at: Scalars['timestamptz']
  event_data: Scalars['jsonb']
  id: Scalars['uuid']
  is_product_page: Scalars['Boolean']
  message_id: Scalars['String']
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key: Scalars['String']
  title?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  url: Scalars['String']
  user_id: Scalars['String']
}

/** This table stores the information of every page view */
export type Pageview_EventEvent_DataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "pageview_event" */
export type Pageview_Event_Aggregate = {
  __typename?: 'pageview_event_aggregate'
  aggregate?: Maybe<Pageview_Event_Aggregate_Fields>
  nodes: Array<Pageview_Event>
}

/** aggregate fields of "pageview_event" */
export type Pageview_Event_Aggregate_Fields = {
  __typename?: 'pageview_event_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Pageview_Event_Max_Fields>
  min?: Maybe<Pageview_Event_Min_Fields>
}

/** aggregate fields of "pageview_event" */
export type Pageview_Event_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pageview_Event_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pageview_Event_Append_Input = {
  event_data?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "pageview_event". All fields are combined with a logical 'AND'. */
export type Pageview_Event_Bool_Exp = {
  _and?: Maybe<Array<Pageview_Event_Bool_Exp>>
  _not?: Maybe<Pageview_Event_Bool_Exp>
  _or?: Maybe<Array<Pageview_Event_Bool_Exp>>
  actor?: Maybe<User_Bool_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  event_data?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_product_page?: Maybe<Boolean_Comparison_Exp>
  message_id?: Maybe<String_Comparison_Exp>
  path?: Maybe<String_Comparison_Exp>
  search?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "pageview_event" */
export enum Pageview_Event_Constraint {
  /** unique or primary key constraint on columns "message_id" */
  PageviewEventMessageIdKey = 'pageview_event_message_id_key',
  /** unique or primary key constraint on columns "id" */
  PageviewEventPkey = 'pageview_event_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pageview_Event_Delete_At_Path_Input = {
  event_data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pageview_Event_Delete_Elem_Input = {
  event_data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pageview_Event_Delete_Key_Input = {
  event_data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "pageview_event" */
export type Pageview_Event_Insert_Input = {
  actor?: Maybe<User_Obj_Rel_Insert_Input>
  created_at?: Maybe<Scalars['timestamptz']>
  event_data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  is_product_page?: Maybe<Scalars['Boolean']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Pageview_Event_Max_Fields = {
  __typename?: 'pageview_event_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Pageview_Event_Min_Fields = {
  __typename?: 'pageview_event_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "pageview_event" */
export type Pageview_Event_Mutation_Response = {
  __typename?: 'pageview_event_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Pageview_Event>
}

/** on_conflict condition type for table "pageview_event" */
export type Pageview_Event_On_Conflict = {
  constraint: Pageview_Event_Constraint
  update_columns?: Array<Pageview_Event_Update_Column>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

/** Ordering options when selecting data from "pageview_event". */
export type Pageview_Event_Order_By = {
  actor?: Maybe<User_Order_By>
  created_at?: Maybe<Order_By>
  event_data?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_product_page?: Maybe<Order_By>
  message_id?: Maybe<Order_By>
  path?: Maybe<Order_By>
  search?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: pageview_event */
export type Pageview_Event_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pageview_Event_Prepend_Input = {
  event_data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "pageview_event" */
export enum Pageview_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  Id = 'id',
  /** column name */
  IsProductPage = 'is_product_page',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Path = 'path',
  /** column name */
  Search = 'search',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "pageview_event" */
export type Pageview_Event_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  event_data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  is_product_page?: Maybe<Scalars['Boolean']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "pageview_event" */
export type Pageview_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pageview_Event_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Pageview_Event_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  event_data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  is_product_page?: Maybe<Scalars['Boolean']>
  message_id?: Maybe<Scalars['String']>
  path?: Maybe<Scalars['String']>
  search?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** columns and relationships of "pageview_event_summary" */
export type Pageview_Event_Summary = {
  __typename?: 'pageview_event_summary'
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  /** An object relationship */
  shop?: Maybe<Shop>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "pageview_event_summary" */
export type Pageview_Event_Summary_Aggregate = {
  __typename?: 'pageview_event_summary_aggregate'
  aggregate?: Maybe<Pageview_Event_Summary_Aggregate_Fields>
  nodes: Array<Pageview_Event_Summary>
}

/** aggregate fields of "pageview_event_summary" */
export type Pageview_Event_Summary_Aggregate_Fields = {
  __typename?: 'pageview_event_summary_aggregate_fields'
  avg?: Maybe<Pageview_Event_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Pageview_Event_Summary_Max_Fields>
  min?: Maybe<Pageview_Event_Summary_Min_Fields>
  stddev?: Maybe<Pageview_Event_Summary_Stddev_Fields>
  stddev_pop?: Maybe<Pageview_Event_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Pageview_Event_Summary_Stddev_Samp_Fields>
  sum?: Maybe<Pageview_Event_Summary_Sum_Fields>
  var_pop?: Maybe<Pageview_Event_Summary_Var_Pop_Fields>
  var_samp?: Maybe<Pageview_Event_Summary_Var_Samp_Fields>
  variance?: Maybe<Pageview_Event_Summary_Variance_Fields>
}

/** aggregate fields of "pageview_event_summary" */
export type Pageview_Event_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Pageview_Event_Summary_Avg_Fields = {
  __typename?: 'pageview_event_summary_avg_fields'
  count?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "pageview_event_summary". All fields are combined with a logical 'AND'. */
export type Pageview_Event_Summary_Bool_Exp = {
  _and?: Maybe<Array<Pageview_Event_Summary_Bool_Exp>>
  _not?: Maybe<Pageview_Event_Summary_Bool_Exp>
  _or?: Maybe<Array<Pageview_Event_Summary_Bool_Exp>>
  count?: Maybe<Bigint_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  key?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type Pageview_Event_Summary_Max_Fields = {
  __typename?: 'pageview_event_summary_max_fields'
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Pageview_Event_Summary_Min_Fields = {
  __typename?: 'pageview_event_summary_min_fields'
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "pageview_event_summary". */
export type Pageview_Event_Summary_Order_By = {
  count?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  key?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "pageview_event_summary" */
export enum Pageview_Event_Summary_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Pageview_Event_Summary_Stddev_Fields = {
  __typename?: 'pageview_event_summary_stddev_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Pageview_Event_Summary_Stddev_Pop_Fields = {
  __typename?: 'pageview_event_summary_stddev_pop_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Pageview_Event_Summary_Stddev_Samp_Fields = {
  __typename?: 'pageview_event_summary_stddev_samp_fields'
  count?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "pageview_event_summary" */
export type Pageview_Event_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pageview_Event_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Pageview_Event_Summary_Stream_Cursor_Value_Input = {
  count?: Maybe<Scalars['bigint']>
  created_at?: Maybe<Scalars['timestamptz']>
  key?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Pageview_Event_Summary_Sum_Fields = {
  __typename?: 'pageview_event_summary_sum_fields'
  count?: Maybe<Scalars['bigint']>
}

/** aggregate var_pop on columns */
export type Pageview_Event_Summary_Var_Pop_Fields = {
  __typename?: 'pageview_event_summary_var_pop_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Pageview_Event_Summary_Var_Samp_Fields = {
  __typename?: 'pageview_event_summary_var_samp_fields'
  count?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Pageview_Event_Summary_Variance_Fields = {
  __typename?: 'pageview_event_summary_variance_fields'
  count?: Maybe<Scalars['Float']>
}

/** update columns of table "pageview_event" */
export enum Pageview_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  Id = 'id',
  /** column name */
  IsProductPage = 'is_product_page',
  /** column name */
  MessageId = 'message_id',
  /** column name */
  Path = 'path',
  /** column name */
  Search = 'search',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

export type Pageview_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Pageview_Event_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Pageview_Event_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Pageview_Event_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Pageview_Event_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Pageview_Event_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Pageview_Event_Set_Input>
  /** filter the rows which have to be updated */
  where: Pageview_Event_Bool_Exp
}

/** Table to track rewards promo periods for invite payouts */
export type Promo_Period = {
  __typename?: 'promo_period'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name: Scalars['String']
  promo_start_at: Scalars['timestamptz']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "promo_period" */
export type Promo_Period_Aggregate = {
  __typename?: 'promo_period_aggregate'
  aggregate?: Maybe<Promo_Period_Aggregate_Fields>
  nodes: Array<Promo_Period>
}

/** aggregate fields of "promo_period" */
export type Promo_Period_Aggregate_Fields = {
  __typename?: 'promo_period_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Promo_Period_Max_Fields>
  min?: Maybe<Promo_Period_Min_Fields>
}

/** aggregate fields of "promo_period" */
export type Promo_Period_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Promo_Period_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "promo_period". All fields are combined with a logical 'AND'. */
export type Promo_Period_Bool_Exp = {
  _and?: Maybe<Array<Promo_Period_Bool_Exp>>
  _not?: Maybe<Promo_Period_Bool_Exp>
  _or?: Maybe<Array<Promo_Period_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  promo_end_at?: Maybe<Timestamptz_Comparison_Exp>
  promo_name?: Maybe<String_Comparison_Exp>
  promo_start_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "promo_period" */
export enum Promo_Period_Constraint {
  /** unique or primary key constraint on columns "id" */
  PromoPeriodPkey = 'promo_period_pkey',
}

/** input type for inserting data into table "promo_period" */
export type Promo_Period_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Promo_Period_Max_Fields = {
  __typename?: 'promo_period_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Promo_Period_Min_Fields = {
  __typename?: 'promo_period_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "promo_period" */
export type Promo_Period_Mutation_Response = {
  __typename?: 'promo_period_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Promo_Period>
}

/** on_conflict condition type for table "promo_period" */
export type Promo_Period_On_Conflict = {
  constraint: Promo_Period_Constraint
  update_columns?: Array<Promo_Period_Update_Column>
  where?: Maybe<Promo_Period_Bool_Exp>
}

/** Ordering options when selecting data from "promo_period". */
export type Promo_Period_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_end_at?: Maybe<Order_By>
  promo_name?: Maybe<Order_By>
  promo_start_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: promo_period */
export type Promo_Period_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "promo_period" */
export enum Promo_Period_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoEndAt = 'promo_end_at',
  /** column name */
  PromoName = 'promo_name',
  /** column name */
  PromoStartAt = 'promo_start_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "promo_period" */
export type Promo_Period_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "promo_period" */
export type Promo_Period_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Promo_Period_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Promo_Period_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_end_at?: Maybe<Scalars['timestamptz']>
  promo_name?: Maybe<Scalars['String']>
  promo_start_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "promo_period" */
export enum Promo_Period_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoEndAt = 'promo_end_at',
  /** column name */
  PromoName = 'promo_name',
  /** column name */
  PromoStartAt = 'promo_start_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Promo_Period_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Promo_Period_Set_Input>
  /** filter the rows which have to be updated */
  where: Promo_Period_Bool_Exp
}

/** columns and relationships of "push_notification" */
export type Push_Notification = {
  __typename?: 'push_notification'
  active: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  criteria: Scalars['jsonb']
  id: Scalars['uuid']
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload: Scalars['jsonb']
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  show_after: Scalars['timestamptz']
  show_before: Scalars['timestamptz']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationCriteriaArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationPayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** columns and relationships of "push_notification" */
export type Push_NotificationPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** aggregated selection of "push_notification" */
export type Push_Notification_Aggregate = {
  __typename?: 'push_notification_aggregate'
  aggregate?: Maybe<Push_Notification_Aggregate_Fields>
  nodes: Array<Push_Notification>
}

/** aggregate fields of "push_notification" */
export type Push_Notification_Aggregate_Fields = {
  __typename?: 'push_notification_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Push_Notification_Max_Fields>
  min?: Maybe<Push_Notification_Min_Fields>
}

/** aggregate fields of "push_notification" */
export type Push_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Push_Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Push_Notification_Append_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "push_notification". All fields are combined with a logical 'AND'. */
export type Push_Notification_Bool_Exp = {
  _and?: Maybe<Array<Push_Notification_Bool_Exp>>
  _not?: Maybe<Push_Notification_Bool_Exp>
  _or?: Maybe<Array<Push_Notification_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  criteria?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<String_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  payload?: Maybe<Jsonb_Comparison_Exp>
  push_notification_status?: Maybe<Push_Notification_Status_Bool_Exp>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp>
  show_after?: Maybe<Timestamptz_Comparison_Exp>
  show_before?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
}

/** unique or primary key constraints on table "push_notification" */
export enum Push_Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  PushNotificationPkey = 'push_notification_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Push_Notification_Delete_At_Path_Input = {
  criteria?: Maybe<Array<Scalars['String']>>
  payload?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Push_Notification_Delete_Elem_Input = {
  criteria?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Push_Notification_Delete_Key_Input = {
  criteria?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "push_notification" */
export type Push_Notification_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  push_notification_status?: Maybe<Push_Notification_Status_Arr_Rel_Insert_Input>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Push_Notification_Max_Fields = {
  __typename?: 'push_notification_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Push_Notification_Min_Fields = {
  __typename?: 'push_notification_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "push_notification" */
export type Push_Notification_Mutation_Response = {
  __typename?: 'push_notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Push_Notification>
}

/** on_conflict condition type for table "push_notification" */
export type Push_Notification_On_Conflict = {
  constraint: Push_Notification_Constraint
  update_columns?: Array<Push_Notification_Update_Column>
  where?: Maybe<Push_Notification_Bool_Exp>
}

/** Ordering options when selecting data from "push_notification". */
export type Push_Notification_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  criteria?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  payload?: Maybe<Order_By>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Order_By>
  show_after?: Maybe<Order_By>
  show_before?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
}

/** primary key columns input for table: push_notification */
export type Push_Notification_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Push_Notification_Prepend_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** select columns of table "push_notification" */
export enum Push_Notification_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "push_notification" */
export type Push_Notification_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "push_notification_status" */
export type Push_Notification_Status = {
  __typename?: 'push_notification_status'
  clicked: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  notification_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "push_notification_status" */
export type Push_Notification_Status_Aggregate = {
  __typename?: 'push_notification_status_aggregate'
  aggregate?: Maybe<Push_Notification_Status_Aggregate_Fields>
  nodes: Array<Push_Notification_Status>
}

export type Push_Notification_Status_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp_Count>
}

export type Push_Notification_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Push_Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Push_Notification_Status_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Push_Notification_Status_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Push_Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Push_Notification_Status_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "push_notification_status" */
export type Push_Notification_Status_Aggregate_Fields = {
  __typename?: 'push_notification_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Push_Notification_Status_Max_Fields>
  min?: Maybe<Push_Notification_Status_Min_Fields>
}

/** aggregate fields of "push_notification_status" */
export type Push_Notification_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Push_Notification_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "push_notification_status" */
export type Push_Notification_Status_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Push_Notification_Status_Max_Order_By>
  min?: Maybe<Push_Notification_Status_Min_Order_By>
}

/** input type for inserting array relation for remote table "push_notification_status" */
export type Push_Notification_Status_Arr_Rel_Insert_Input = {
  data: Array<Push_Notification_Status_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Push_Notification_Status_On_Conflict>
}

/** Boolean expression to filter rows from the table "push_notification_status". All fields are combined with a logical 'AND'. */
export type Push_Notification_Status_Bool_Exp = {
  _and?: Maybe<Array<Push_Notification_Status_Bool_Exp>>
  _not?: Maybe<Push_Notification_Status_Bool_Exp>
  _or?: Maybe<Array<Push_Notification_Status_Bool_Exp>>
  clicked?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notification_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "push_notification_status" */
export enum Push_Notification_Status_Constraint {
  /** unique or primary key constraint on columns "user_id", "notification_id" */
  PushNotificationStatusNotificationIdUserIdKey = 'push_notification_status_notification_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  PushNotificationStatusPkey = 'push_notification_status_pkey',
}

/** input type for inserting data into table "push_notification_status" */
export type Push_Notification_Status_Insert_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Push_Notification_Status_Max_Fields = {
  __typename?: 'push_notification_status_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "push_notification_status" */
export type Push_Notification_Status_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Push_Notification_Status_Min_Fields = {
  __typename?: 'push_notification_status_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "push_notification_status" */
export type Push_Notification_Status_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "push_notification_status" */
export type Push_Notification_Status_Mutation_Response = {
  __typename?: 'push_notification_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Push_Notification_Status>
}

/** on_conflict condition type for table "push_notification_status" */
export type Push_Notification_Status_On_Conflict = {
  constraint: Push_Notification_Status_Constraint
  update_columns?: Array<Push_Notification_Status_Update_Column>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** Ordering options when selecting data from "push_notification_status". */
export type Push_Notification_Status_Order_By = {
  clicked?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notification_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: push_notification_status */
export type Push_Notification_Status_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "push_notification_status" */
export enum Push_Notification_Status_Select_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "push_notification_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "push_notification_status" */
export enum Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** select "push_notification_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "push_notification_status" */
export enum Push_Notification_Status_Select_Column_Push_Notification_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Clicked = 'clicked',
}

/** input type for updating data in table "push_notification_status" */
export type Push_Notification_Status_Set_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "push_notification_status" */
export type Push_Notification_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Push_Notification_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Push_Notification_Status_Stream_Cursor_Value_Input = {
  clicked?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notification_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "push_notification_status" */
export enum Push_Notification_Status_Update_Column {
  /** column name */
  Clicked = 'clicked',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Push_Notification_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Push_Notification_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Push_Notification_Status_Bool_Exp
}

/** Streaming cursor of the table "push_notification" */
export type Push_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Push_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Push_Notification_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "push_notification" */
export enum Push_Notification_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Push_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Push_Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Push_Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Push_Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Push_Notification_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Push_Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Push_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: Push_Notification_Bool_Exp
}

export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "account_deletion_reason" */
  account_deletion_reason: Array<Account_Deletion_Reason>
  /** fetch aggregated fields from the table: "account_deletion_reason" */
  account_deletion_reason_aggregate: Account_Deletion_Reason_Aggregate
  /** fetch data from the table: "account_deletion_reason" using primary key columns */
  account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** fetch data from the table: "affiliate" */
  affiliate: Array<Affiliate>
  /** fetch data from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id: Array<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id_aggregate: Affiliate_Advertiser_Id_Aggregate
  /** fetch data from the table: "affiliate_advertiser_id" using primary key columns */
  affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate" */
  affiliate_aggregate: Affiliate_Aggregate
  /** fetch data from the table: "affiliate" using primary key columns */
  affiliate_by_pk?: Maybe<Affiliate>
  /** fetch data from the table: "affiliate_commission_summary" */
  affiliate_commission_summary: Array<Affiliate_Commission_Summary>
  /** fetch aggregated fields from the table: "affiliate_commission_summary" */
  affiliate_commission_summary_aggregate: Affiliate_Commission_Summary_Aggregate
  /** fetch data from the table: "affiliate_meta" */
  affiliate_meta: Array<Affiliate_Meta>
  /** fetch aggregated fields from the table: "affiliate_meta" */
  affiliate_meta_aggregate: Affiliate_Meta_Aggregate
  /** fetch data from the table: "affiliate_meta" using primary key columns */
  affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** fetch data from the table: "affiliate_transaction" */
  affiliate_transaction: Array<Affiliate_Transaction>
  /** fetch aggregated fields from the table: "affiliate_transaction" */
  affiliate_transaction_aggregate: Affiliate_Transaction_Aggregate
  /** fetch data from the table: "affiliate_transaction" using primary key columns */
  affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** execute function "all_shops_for_shop_page" which returns "shop" */
  all_shops_for_shop_page: Array<Shop>
  /** execute function "all_shops_for_shop_page" and query aggregates on result of table type "shop" */
  all_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "amazon_categories" */
  amazon_categories: Array<Amazon_Categories>
  /** fetch aggregated fields from the table: "amazon_categories" */
  amazon_categories_aggregate: Amazon_Categories_Aggregate
  /** A query to grab the available subscriptions for a given creator and/or influencer */
  availableSubscriptions: Array<Subscription>
  /** fetch data from the table: "billing_mode" */
  billing_mode: Array<Billing_Mode>
  /** fetch aggregated fields from the table: "billing_mode" */
  billing_mode_aggregate: Billing_Mode_Aggregate
  /** fetch data from the table: "billing_mode" using primary key columns */
  billing_mode_by_pk?: Maybe<Billing_Mode>
  /** fetch data from the table: "billing_platform_connected_account" */
  billing_platform_connected_account: Array<Billing_Platform_Connected_Account>
  /** fetch aggregated fields from the table: "billing_platform_connected_account" */
  billing_platform_connected_account_aggregate: Billing_Platform_Connected_Account_Aggregate
  /** fetch data from the table: "billing_platform_connected_account" using primary key columns */
  billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** fetch data from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status: Array<Billing_Platform_Connected_Account_Status>
  /** fetch aggregated fields from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status_aggregate: Billing_Platform_Connected_Account_Status_Aggregate
  /** fetch data from the table: "billing_platform_connected_account_status" using primary key columns */
  billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** fetch data from the table: "billing_platform_customer" */
  billing_platform_customer: Array<Billing_Platform_Customer>
  /** fetch aggregated fields from the table: "billing_platform_customer" */
  billing_platform_customer_aggregate: Billing_Platform_Customer_Aggregate
  /** fetch data from the table: "billing_platform_customer" using primary key columns */
  billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** fetch data from the table: "cart" */
  cart: Array<Cart>
  /** fetch aggregated fields from the table: "cart" */
  cart_aggregate: Cart_Aggregate
  /** fetch data from the table: "cart" using primary key columns */
  cart_by_pk?: Maybe<Cart>
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>
  /** fetch data from the table: "cart_item_like" */
  cart_item_like: Array<Cart_Item_Like>
  /** fetch aggregated fields from the table: "cart_item_like" */
  cart_item_like_aggregate: Cart_Item_Like_Aggregate
  /** fetch data from the table: "cart_item_like" using primary key columns */
  cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** fetch data from the table: "cart_item_note" */
  cart_item_note: Array<Cart_Item_Note>
  /** fetch aggregated fields from the table: "cart_item_note" */
  cart_item_note_aggregate: Cart_Item_Note_Aggregate
  /** fetch data from the table: "cart_item_note" using primary key columns */
  cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** fetch data from the table: "categories" */
  categories: Array<Categories>
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>
  /** fetch data from the table: "category_shop_item" */
  category_shop_item: Array<Category_Shop_Item>
  /** fetch aggregated fields from the table: "category_shop_item" */
  category_shop_item_aggregate: Category_Shop_Item_Aggregate
  /** fetch data from the table: "category_shop_item" using primary key columns */
  category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** fetch data from the table: "checkout" */
  checkout: Array<Checkout>
  /** fetch aggregated fields from the table: "checkout" */
  checkout_aggregate: Checkout_Aggregate
  /** fetch data from the table: "checkout" using primary key columns */
  checkout_by_pk?: Maybe<Checkout>
  /** fetch data from the table: "collection" */
  collection: Array<Collection>
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>
  /** fetch data from the table: "collection_cart_item" */
  collection_cart_item: Array<Collection_Cart_Item>
  /** fetch aggregated fields from the table: "collection_cart_item" */
  collection_cart_item_aggregate: Collection_Cart_Item_Aggregate
  /** fetch data from the table: "collection_cart_item" using primary key columns */
  collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** fetch data from the table: "collection_collaborator" */
  collection_collaborator: Array<Collection_Collaborator>
  /** fetch aggregated fields from the table: "collection_collaborator" */
  collection_collaborator_aggregate: Collection_Collaborator_Aggregate
  /** fetch data from the table: "collection_collaborator" using primary key columns */
  collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** fetch data from the table: "collection_invite" */
  collection_invite: Array<Collection_Invite>
  /** fetch aggregated fields from the table: "collection_invite" */
  collection_invite_aggregate: Collection_Invite_Aggregate
  /** fetch data from the table: "collection_invite" using primary key columns */
  collection_invite_by_pk?: Maybe<Collection_Invite>
  /** fetch data from the table: "collection_invite_status" */
  collection_invite_status: Array<Collection_Invite_Status>
  /** fetch aggregated fields from the table: "collection_invite_status" */
  collection_invite_status_aggregate: Collection_Invite_Status_Aggregate
  /** fetch data from the table: "collection_invite_status" using primary key columns */
  collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** fetch data from the table: "collection_item_claim" */
  collection_item_claim: Array<Collection_Item_Claim>
  /** fetch aggregated fields from the table: "collection_item_claim" */
  collection_item_claim_aggregate: Collection_Item_Claim_Aggregate
  /** fetch data from the table: "collection_item_claim" using primary key columns */
  collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** fetch data from the table: "collection_item_claim_status" */
  collection_item_claim_status: Array<Collection_Item_Claim_Status>
  /** fetch aggregated fields from the table: "collection_item_claim_status" */
  collection_item_claim_status_aggregate: Collection_Item_Claim_Status_Aggregate
  /** fetch data from the table: "collection_item_claim_status" using primary key columns */
  collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** execute function "collection_random" which returns "collection" */
  collection_random: Array<Collection>
  /** execute function "collection_random" and query aggregates on result of table type "collection" */
  collection_random_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection_section" */
  collection_section: Array<Collection_Section>
  /** fetch aggregated fields from the table: "collection_section" */
  collection_section_aggregate: Collection_Section_Aggregate
  /** fetch data from the table: "collection_section" using primary key columns */
  collection_section_by_pk?: Maybe<Collection_Section>
  /** fetch data from the table: "collection_section_cart_item" */
  collection_section_cart_item: Array<Collection_Section_Cart_Item>
  /** fetch aggregated fields from the table: "collection_section_cart_item" */
  collection_section_cart_item_aggregate: Collection_Section_Cart_Item_Aggregate
  /** fetch data from the table: "collection_section_cart_item" using primary key columns */
  collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** fetch data from the table: "collection_slug_history" */
  collection_slug_history: Array<Collection_Slug_History>
  /** fetch aggregated fields from the table: "collection_slug_history" */
  collection_slug_history_aggregate: Collection_Slug_History_Aggregate
  /** fetch data from the table: "collection_slug_history" using primary key columns */
  collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** fetch data from the table: "collection_state" */
  collection_state: Array<Collection_State>
  /** fetch aggregated fields from the table: "collection_state" */
  collection_state_aggregate: Collection_State_Aggregate
  /** fetch data from the table: "collection_state" using primary key columns */
  collection_state_by_pk?: Maybe<Collection_State>
  /** fetch data from the table: "collection_type" */
  collection_type: Array<Collection_Type>
  /** fetch aggregated fields from the table: "collection_type" */
  collection_type_aggregate: Collection_Type_Aggregate
  /** fetch data from the table: "collection_type" using primary key columns */
  collection_type_by_pk?: Maybe<Collection_Type>
  /** fetch data from the table: "comment" */
  comment: Array<Comment>
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>
  /** fetch data from the table: "commentable_item" */
  commentable_item: Array<Commentable_Item>
  /** fetch aggregated fields from the table: "commentable_item" */
  commentable_item_aggregate: Commentable_Item_Aggregate
  /** fetch data from the table: "commentable_item" using primary key columns */
  commentable_item_by_pk?: Maybe<Commentable_Item>
  /** fetch data from the table: "commentable_item_type" */
  commentable_item_type: Array<Commentable_Item_Type>
  /** fetch aggregated fields from the table: "commentable_item_type" */
  commentable_item_type_aggregate: Commentable_Item_Type_Aggregate
  /** fetch data from the table: "commentable_item_type" using primary key columns */
  commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** fetch data from the table: "communication_template" */
  communication_template: Array<Communication_Template>
  /** fetch aggregated fields from the table: "communication_template" */
  communication_template_aggregate: Communication_Template_Aggregate
  /** fetch data from the table: "communication_template" using primary key columns */
  communication_template_by_pk?: Maybe<Communication_Template>
  /** fetch data from the table: "communication_template_full_list" */
  communication_template_full_list: Array<Communication_Template_Full_List>
  /** fetch aggregated fields from the table: "communication_template_full_list" */
  communication_template_full_list_aggregate: Communication_Template_Full_List_Aggregate
  /** fetch data from the table: "communication_template_full_list" using primary key columns */
  communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  contactConnections: Array<ContactConnection>
  /** fetch data from the table: "contact_info_type" */
  contact_info_type: Array<Contact_Info_Type>
  /** fetch aggregated fields from the table: "contact_info_type" */
  contact_info_type_aggregate: Contact_Info_Type_Aggregate
  /** fetch data from the table: "contact_info_type" using primary key columns */
  contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** fetch data from the table: "contact_invite" */
  contact_invite: Array<Contact_Invite>
  /** fetch aggregated fields from the table: "contact_invite" */
  contact_invite_aggregate: Contact_Invite_Aggregate
  /** fetch data from the table: "contact_invite" using primary key columns */
  contact_invite_by_pk?: Maybe<Contact_Invite>
  /** fetch data from the table: "contact_invite_status" */
  contact_invite_status: Array<Contact_Invite_Status>
  /** fetch aggregated fields from the table: "contact_invite_status" */
  contact_invite_status_aggregate: Contact_Invite_Status_Aggregate
  /** fetch data from the table: "contact_invite_status" using primary key columns */
  contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** fetch data from the table: "contact_invite_type" */
  contact_invite_type: Array<Contact_Invite_Type>
  /** fetch aggregated fields from the table: "contact_invite_type" */
  contact_invite_type_aggregate: Contact_Invite_Type_Aggregate
  /** fetch data from the table: "contact_invite_type" using primary key columns */
  contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** This is a query for fetching the subscriber chart data for a creator */
  creatorSubscriberChartData: Array<CreatorSubscriberChartDataOutput>
  /** execute function "creator_subscribers_random" which returns "user" */
  creator_subscribers_random: Array<User>
  /** execute function "creator_subscribers_random" and query aggregates on result of table type "user" */
  creator_subscribers_random_aggregate: User_Aggregate
  /** fetch data from the table: "creator_user_subscriptions" */
  creator_user_subscriptions: Array<Creator_User_Subscriptions>
  /** fetch aggregated fields from the table: "creator_user_subscriptions" */
  creator_user_subscriptions_aggregate: Creator_User_Subscriptions_Aggregate
  /** fetch data from the table: "deal_hop_gate" */
  deal_hop_gate: Array<Deal_Hop_Gate>
  /** fetch aggregated fields from the table: "deal_hop_gate" */
  deal_hop_gate_aggregate: Deal_Hop_Gate_Aggregate
  /** fetch data from the table: "deal_hop_gate" using primary key columns */
  deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** fetch data from the table: "device_client_data" */
  device_client_data: Array<Device_Client_Data>
  /** fetch aggregated fields from the table: "device_client_data" */
  device_client_data_aggregate: Device_Client_Data_Aggregate
  /** fetch data from the table: "device_client_data" using primary key columns */
  device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** fetch data from the table: "discovery_backlog" */
  discovery_backlog: Array<Discovery_Backlog>
  /** fetch aggregated fields from the table: "discovery_backlog" */
  discovery_backlog_aggregate: Discovery_Backlog_Aggregate
  /** fetch data from the table: "discovery_backlog" using primary key columns */
  discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** fetch data from the table: "discovery_feed" */
  discovery_feed: Array<Discovery_Feed>
  /** fetch aggregated fields from the table: "discovery_feed" */
  discovery_feed_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table: "discovery_feed" using primary key columns */
  discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** execute function "discovery_feed_random" which returns "discovery_feed" */
  discovery_feed_random: Array<Discovery_Feed>
  /** execute function "discovery_feed_random" and query aggregates on result of table type "discovery_feed" */
  discovery_feed_random_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table: "discovery_staging" */
  discovery_staging: Array<Discovery_Staging>
  /** fetch aggregated fields from the table: "discovery_staging" */
  discovery_staging_aggregate: Discovery_Staging_Aggregate
  /** fetch data from the table: "discovery_staging" using primary key columns */
  discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** fetch data from the table: "featured_shop" */
  featured_shop: Array<Featured_Shop>
  /** fetch aggregated fields from the table: "featured_shop" */
  featured_shop_aggregate: Featured_Shop_Aggregate
  /** fetch data from the table: "featured_shop" using primary key columns */
  featured_shop_by_pk?: Maybe<Featured_Shop>
  /** fetch data from the table: "featured_shop_item" */
  featured_shop_item: Array<Featured_Shop_Item>
  /** fetch aggregated fields from the table: "featured_shop_item" */
  featured_shop_item_aggregate: Featured_Shop_Item_Aggregate
  /** fetch data from the table: "featured_shop_item" using primary key columns */
  featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** execute function "featured_shops_for_onboarding" which returns "shop" */
  featured_shops_for_onboarding: Array<Shop>
  /** execute function "featured_shops_for_onboarding" and query aggregates on result of table type "shop" */
  featured_shops_for_onboarding_aggregate: Shop_Aggregate
  /** fetch data from the table: "feed_item" */
  feed_item: Array<Feed_Item>
  /** fetch aggregated fields from the table: "feed_item" */
  feed_item_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "feed_item" using primary key columns */
  feed_item_by_pk?: Maybe<Feed_Item>
  /** fetch data from the table: "feed_item_like" */
  feed_item_like: Array<Feed_Item_Like>
  /** fetch aggregated fields from the table: "feed_item_like" */
  feed_item_like_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table: "feed_item_like" using primary key columns */
  feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** execute function "feed_item_like_random" which returns "feed_item_like" */
  feed_item_like_random: Array<Feed_Item_Like>
  /** execute function "feed_item_like_random" and query aggregates on result of table type "feed_item_like" */
  feed_item_like_random_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table: "feed_item_type" */
  feed_item_type: Array<Feed_Item_Type>
  /** fetch aggregated fields from the table: "feed_item_type" */
  feed_item_type_aggregate: Feed_Item_Type_Aggregate
  /** fetch data from the table: "feed_item_type" using primary key columns */
  feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** execute function "feed_items_for_style_accounts" which returns "feed_item" */
  feed_items_for_style_accounts: Array<Feed_Item>
  /** execute function "feed_items_for_style_accounts" and query aggregates on result of table type "feed_item" */
  feed_items_for_style_accounts_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user" which returns "feed_item" */
  feed_items_for_user: Array<Feed_Item>
  /** execute function "feed_items_for_user" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user_subscriptions" which returns "feed_item" */
  feed_items_for_user_subscriptions: Array<Feed_Item>
  /** execute function "feed_items_for_user_subscriptions" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_subscriptions_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "fmtc_deal" */
  fmtc_deal: Array<Fmtc_Deal>
  /** fetch aggregated fields from the table: "fmtc_deal" */
  fmtc_deal_aggregate: Fmtc_Deal_Aggregate
  /** fetch data from the table: "fmtc_deal" using primary key columns */
  fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** fetch data from the table: "followable_item" */
  followable_item: Array<Followable_Item>
  /** fetch aggregated fields from the table: "followable_item" */
  followable_item_aggregate: Followable_Item_Aggregate
  /** fetch data from the table: "followable_item" using primary key columns */
  followable_item_by_pk?: Maybe<Followable_Item>
  /** fetch data from the table: "followable_item_type" */
  followable_item_type: Array<Followable_Item_Type>
  /** fetch aggregated fields from the table: "followable_item_type" */
  followable_item_type_aggregate: Followable_Item_Type_Aggregate
  /** fetch data from the table: "followable_item_type" using primary key columns */
  followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** fetch data from the table: "leaderboard_event" */
  leaderboard_event: Array<Leaderboard_Event>
  /** fetch aggregated fields from the table: "leaderboard_event" */
  leaderboard_event_aggregate: Leaderboard_Event_Aggregate
  /** fetch data from the table: "leaderboard_event" using primary key columns */
  leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** fetch data from the table: "leaderboard_event_name" */
  leaderboard_event_name: Array<Leaderboard_Event_Name>
  /** fetch aggregated fields from the table: "leaderboard_event_name" */
  leaderboard_event_name_aggregate: Leaderboard_Event_Name_Aggregate
  /** fetch data from the table: "leaderboard_event_name" using primary key columns */
  leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** fetch data from the table: "link_type" */
  link_type: Array<Link_Type>
  /** fetch aggregated fields from the table: "link_type" */
  link_type_aggregate: Link_Type_Aggregate
  /** fetch data from the table: "link_type" using primary key columns */
  link_type_by_pk?: Maybe<Link_Type>
  /** fetch data from the table: "media_enabled_item" */
  media_enabled_item: Array<Media_Enabled_Item>
  /** fetch aggregated fields from the table: "media_enabled_item" */
  media_enabled_item_aggregate: Media_Enabled_Item_Aggregate
  /** fetch data from the table: "media_enabled_item" using primary key columns */
  media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** fetch data from the table: "media_item" */
  media_item: Array<Media_Item>
  /** fetch aggregated fields from the table: "media_item" */
  media_item_aggregate: Media_Item_Aggregate
  /** fetch data from the table: "media_item" using primary key columns */
  media_item_by_pk?: Maybe<Media_Item>
  /** fetch data from the table: "media_item_type" */
  media_item_type: Array<Media_Item_Type>
  /** fetch aggregated fields from the table: "media_item_type" */
  media_item_type_aggregate: Media_Item_Type_Aggregate
  /** fetch data from the table: "media_item_type" using primary key columns */
  media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** An array relationship */
  media_items: Array<Media_Items>
  /** An aggregate relationship */
  media_items_aggregate: Media_Items_Aggregate
  /** fetch data from the table: "media_items" using primary key columns */
  media_items_by_pk?: Maybe<Media_Items>
  /** fetch data from the table: "media_type" */
  media_type: Array<Media_Type>
  /** fetch aggregated fields from the table: "media_type" */
  media_type_aggregate: Media_Type_Aggregate
  /** fetch data from the table: "media_type" using primary key columns */
  media_type_by_pk?: Maybe<Media_Type>
  /** execute function "mention_search_users" which returns "user" */
  mention_search_users: Array<User>
  /** execute function "mention_search_users" and query aggregates on result of table type "user" */
  mention_search_users_aggregate: User_Aggregate
  /** fetch data from the table: "mobile_app_home" */
  mobile_app_home: Array<Mobile_App_Home>
  /** fetch aggregated fields from the table: "mobile_app_home" */
  mobile_app_home_aggregate: Mobile_App_Home_Aggregate
  /** fetch data from the table: "mobile_app_home" using primary key columns */
  mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** fetch data from the table: "mobile_home" */
  mobile_home: Array<Mobile_Home>
  /** fetch aggregated fields from the table: "mobile_home" */
  mobile_home_aggregate: Mobile_Home_Aggregate
  /** fetch data from the table: "mobile_home" using primary key columns */
  mobile_home_by_pk?: Maybe<Mobile_Home>
  /** fetch data from the table: "mobile_push_notification" */
  mobile_push_notification: Array<Mobile_Push_Notification>
  /** fetch aggregated fields from the table: "mobile_push_notification" */
  mobile_push_notification_aggregate: Mobile_Push_Notification_Aggregate
  /** fetch data from the table: "mobile_push_notification" using primary key columns */
  mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** fetch data from the table: "mobile_push_notification_status" */
  mobile_push_notification_status: Array<Mobile_Push_Notification_Status>
  /** fetch aggregated fields from the table: "mobile_push_notification_status" */
  mobile_push_notification_status_aggregate: Mobile_Push_Notification_Status_Aggregate
  /** fetch data from the table: "mobile_push_notification_status" using primary key columns */
  mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** fetch data from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user: Array<Notifiable_Feed_Item_User>
  /** fetch aggregated fields from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user_aggregate: Notifiable_Feed_Item_User_Aggregate
  /** fetch data from the table: "notifiable_item" */
  notifiable_item: Array<Notifiable_Item>
  /** fetch aggregated fields from the table: "notifiable_item" */
  notifiable_item_aggregate: Notifiable_Item_Aggregate
  /** fetch data from the table: "notifiable_item" using primary key columns */
  notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** fetch data from the table: "notifiable_item_type" */
  notifiable_item_type: Array<Notifiable_Item_Type>
  /** fetch aggregated fields from the table: "notifiable_item_type" */
  notifiable_item_type_aggregate: Notifiable_Item_Type_Aggregate
  /** fetch data from the table: "notifiable_item_type" using primary key columns */
  notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** fetch data from the table: "notification" */
  notification: Array<Notification>
  /** A wrapper for getting notification center item data so that we can template-ize the notification copy */
  notificationCenterItems: Array<WrappedNotificationCenterItem>
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>
  /** execute function "notification_center_feed_for_user" which returns "notification_center_item" */
  notification_center_feed_for_user: Array<Notification_Center_Item>
  /** execute function "notification_center_feed_for_user" and query aggregates on result of table type "notification_center_item" */
  notification_center_feed_for_user_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" */
  notification_center_item: Array<Notification_Center_Item>
  /** fetch aggregated fields from the table: "notification_center_item" */
  notification_center_item_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" using primary key columns */
  notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** fetch data from the table: "notification_center_item_read_status" */
  notification_center_item_read_status: Array<Notification_Center_Item_Read_Status>
  /** fetch aggregated fields from the table: "notification_center_item_read_status" */
  notification_center_item_read_status_aggregate: Notification_Center_Item_Read_Status_Aggregate
  /** fetch data from the table: "notification_center_item_read_status" using primary key columns */
  notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** fetch data from the table: "notification_center_item_type" */
  notification_center_item_type: Array<Notification_Center_Item_Type>
  /** fetch aggregated fields from the table: "notification_center_item_type" */
  notification_center_item_type_aggregate: Notification_Center_Item_Type_Aggregate
  /** fetch data from the table: "notification_center_item_type" using primary key columns */
  notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** fetch data from the table: "notification_preference" */
  notification_preference: Array<Notification_Preference>
  /** fetch aggregated fields from the table: "notification_preference" */
  notification_preference_aggregate: Notification_Preference_Aggregate
  /** fetch data from the table: "notification_preference" using primary key columns */
  notification_preference_by_pk?: Maybe<Notification_Preference>
  /** An array relationship */
  notification_status: Array<Notification_Status>
  /** An aggregate relationship */
  notification_status_aggregate: Notification_Status_Aggregate
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<Notification_Status>
  /** fetch data from the table: "onboarding_categories" */
  onboarding_categories: Array<Onboarding_Categories>
  /** fetch aggregated fields from the table: "onboarding_categories" */
  onboarding_categories_aggregate: Onboarding_Categories_Aggregate
  /** fetch data from the table: "onboarding_categories" using primary key columns */
  onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** fetch data from the table: "onboarding_sources" */
  onboarding_sources: Array<Onboarding_Sources>
  /** fetch aggregated fields from the table: "onboarding_sources" */
  onboarding_sources_aggregate: Onboarding_Sources_Aggregate
  /** fetch data from the table: "onboarding_sources" using primary key columns */
  onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** fetch data from the table: "one_off_email" */
  one_off_email: Array<One_Off_Email>
  /** fetch aggregated fields from the table: "one_off_email" */
  one_off_email_aggregate: One_Off_Email_Aggregate
  /** fetch data from the table: "one_off_email" using primary key columns */
  one_off_email_by_pk?: Maybe<One_Off_Email>
  /** execute function "other_shops_for_shop_page" which returns "shop" */
  other_shops_for_shop_page: Array<Shop>
  /** execute function "other_shops_for_shop_page" and query aggregates on result of table type "shop" */
  other_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "pageview_event" */
  pageview_event: Array<Pageview_Event>
  /** fetch aggregated fields from the table: "pageview_event" */
  pageview_event_aggregate: Pageview_Event_Aggregate
  /** fetch data from the table: "pageview_event" using primary key columns */
  pageview_event_by_pk?: Maybe<Pageview_Event>
  /** fetch data from the table: "pageview_event_summary" */
  pageview_event_summary: Array<Pageview_Event_Summary>
  /** fetch aggregated fields from the table: "pageview_event_summary" */
  pageview_event_summary_aggregate: Pageview_Event_Summary_Aggregate
  /** fetch data from the table: "promo_period" */
  promo_period: Array<Promo_Period>
  /** fetch aggregated fields from the table: "promo_period" */
  promo_period_aggregate: Promo_Period_Aggregate
  /** fetch data from the table: "promo_period" using primary key columns */
  promo_period_by_pk?: Maybe<Promo_Period>
  /** fetch data from the table: "push_notification" */
  push_notification: Array<Push_Notification>
  /** fetch aggregated fields from the table: "push_notification" */
  push_notification_aggregate: Push_Notification_Aggregate
  /** fetch data from the table: "push_notification" using primary key columns */
  push_notification_by_pk?: Maybe<Push_Notification>
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  /** fetch data from the table: "push_notification_status" using primary key columns */
  push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** execute function "random_discovery_feed_items" which returns "discovery_feed" */
  random_discovery_feed_items: Array<Discovery_Feed>
  /** execute function "random_discovery_feed_items" and query aggregates on result of table type "discovery_feed" */
  random_discovery_feed_items_aggregate: Discovery_Feed_Aggregate
  /** execute function "recommended_shops_by_user" which returns "shop" */
  recommended_shops_by_user: Array<Shop>
  /** execute function "recommended_shops_by_user" and query aggregates on result of table type "shop" */
  recommended_shops_by_user_aggregate: Shop_Aggregate
  /** fetch data from the table: "redirect_ids" */
  redirect_ids: Array<Redirect_Ids>
  /** fetch aggregated fields from the table: "redirect_ids" */
  redirect_ids_aggregate: Redirect_Ids_Aggregate
  /** fetch data from the table: "redirect_ids" using primary key columns */
  redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** execute function "related_shops_by_category" which returns "shop" */
  related_shops_by_category: Array<Shop>
  /** execute function "related_shops_by_category" and query aggregates on result of table type "shop" */
  related_shops_by_category_aggregate: Shop_Aggregate
  /** execute function "related_users_for_shop_item" which returns "user" */
  related_users_for_shop_item: Array<User>
  /** execute function "related_users_for_shop_item" and query aggregates on result of table type "user" */
  related_users_for_shop_item_aggregate: User_Aggregate
  /** fetch data from the table: "remove_reason" */
  remove_reason: Array<Remove_Reason>
  /** fetch aggregated fields from the table: "remove_reason" */
  remove_reason_aggregate: Remove_Reason_Aggregate
  /** fetch data from the table: "remove_reason" using primary key columns */
  remove_reason_by_pk?: Maybe<Remove_Reason>
  /** fetch data from the table: "rewardable_action_names" */
  rewardable_action_names: Array<Rewardable_Action_Names>
  /** fetch aggregated fields from the table: "rewardable_action_names" */
  rewardable_action_names_aggregate: Rewardable_Action_Names_Aggregate
  /** fetch data from the table: "rewardable_action_names" using primary key columns */
  rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** fetch data from the table: "rewardable_item_type" */
  rewardable_item_type: Array<Rewardable_Item_Type>
  /** fetch aggregated fields from the table: "rewardable_item_type" */
  rewardable_item_type_aggregate: Rewardable_Item_Type_Aggregate
  /** fetch data from the table: "rewardable_item_type" using primary key columns */
  rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** This is a query for searching products */
  searchProducts: SearchProductsOutput
  /** fetch data from the table: "search_query" */
  search_query: Array<Search_Query>
  /** fetch aggregated fields from the table: "search_query" */
  search_query_aggregate: Search_Query_Aggregate
  /** fetch data from the table: "search_query" using primary key columns */
  search_query_by_pk?: Maybe<Search_Query>
  /** fetch data from the table: "search_query_result" */
  search_query_result: Array<Search_Query_Result>
  /** fetch aggregated fields from the table: "search_query_result" */
  search_query_result_aggregate: Search_Query_Result_Aggregate
  /** fetch data from the table: "search_query_result" using primary key columns */
  search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** execute function "search_shop_items" which returns "shop_item" */
  search_shop_items: Array<Shop_Item>
  /** execute function "search_shop_items" and query aggregates on result of table type "shop_item" */
  search_shop_items_aggregate: Shop_Item_Aggregate
  /** execute function "search_shops" which returns "shop" */
  search_shops: Array<Shop>
  /** execute function "search_shops" and query aggregates on result of table type "shop" */
  search_shops_aggregate: Shop_Aggregate
  /** execute function "search_users" which returns "user" */
  search_users: Array<User>
  /** execute function "search_users" and query aggregates on result of table type "user" */
  search_users_aggregate: User_Aggregate
  /** fetch data from the table: "shop" */
  shop: Array<Shop>
  /** fetch data from the table: "shop_affiliate" */
  shop_affiliate: Array<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop_affiliate" */
  shop_affiliate_aggregate: Shop_Affiliate_Aggregate
  /** fetch data from the table: "shop_affiliate" using primary key columns */
  shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop" */
  shop_aggregate: Shop_Aggregate
  /** fetch data from the table: "shop" using primary key columns */
  shop_by_pk?: Maybe<Shop>
  /** fetch data from the table: "shop_categorization" */
  shop_categorization: Array<Shop_Categorization>
  /** fetch aggregated fields from the table: "shop_categorization" */
  shop_categorization_aggregate: Shop_Categorization_Aggregate
  /** fetch data from the table: "shop_categorization" using primary key columns */
  shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** fetch data from the table: "shop_categorized_deals" */
  shop_categorized_deals: Array<Shop_Categorized_Deals>
  /** fetch aggregated fields from the table: "shop_categorized_deals" */
  shop_categorized_deals_aggregate: Shop_Categorized_Deals_Aggregate
  /** fetch data from the table: "shop_category" */
  shop_category: Array<Shop_Category>
  /** fetch aggregated fields from the table: "shop_category" */
  shop_category_aggregate: Shop_Category_Aggregate
  /** fetch data from the table: "shop_category" using primary key columns */
  shop_category_by_pk?: Maybe<Shop_Category>
  /** execute function "shop_collections" which returns "collection" */
  shop_collections: Array<Collection>
  /** execute function "shop_collections" and query aggregates on result of table type "collection" */
  shop_collections_aggregate: Collection_Aggregate
  /** fetch data from the table: "shop_item" */
  shop_item: Array<Shop_Item>
  /** fetch aggregated fields from the table: "shop_item" */
  shop_item_aggregate: Shop_Item_Aggregate
  /** fetch data from the table: "shop_item_availability" */
  shop_item_availability: Array<Shop_Item_Availability>
  /** fetch aggregated fields from the table: "shop_item_availability" */
  shop_item_availability_aggregate: Shop_Item_Availability_Aggregate
  /** fetch data from the table: "shop_item_availability" using primary key columns */
  shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** fetch data from the table: "shop_item" using primary key columns */
  shop_item_by_pk?: Maybe<Shop_Item>
  /** fetch data from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status: Array<Shop_Item_Enrichment_Status>
  /** fetch aggregated fields from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status_aggregate: Shop_Item_Enrichment_Status_Aggregate
  /** fetch data from the table: "shop_item_enrichment_status" using primary key columns */
  shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** fetch data from the table: "shop_item_history" */
  shop_item_history: Array<Shop_Item_History>
  /** fetch aggregated fields from the table: "shop_item_history" */
  shop_item_history_aggregate: Shop_Item_History_Aggregate
  /** fetch data from the table: "shop_item_history" using primary key columns */
  shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** fetch data from the table: "shop_item_image" */
  shop_item_image: Array<Shop_Item_Image>
  /** fetch aggregated fields from the table: "shop_item_image" */
  shop_item_image_aggregate: Shop_Item_Image_Aggregate
  /** fetch data from the table: "shop_item_image" using primary key columns */
  shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** fetch data from the table: "shop_item_index" */
  shop_item_index: Array<Shop_Item_Index>
  /** fetch aggregated fields from the table: "shop_item_index" */
  shop_item_index_aggregate: Shop_Item_Index_Aggregate
  /** fetch data from the table: "shop_item_index" using primary key columns */
  shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** fetch data from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped: Array<Shop_Item_To_Be_Scraped>
  /** fetch aggregated fields from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped_aggregate: Shop_Item_To_Be_Scraped_Aggregate
  /** fetch data from the table: "shop_popularity" */
  shop_popularity: Array<Shop_Popularity>
  /** fetch aggregated fields from the table: "shop_popularity" */
  shop_popularity_aggregate: Shop_Popularity_Aggregate
  /** fetch data from the table: "shop_popularity_with_points" */
  shop_popularity_with_points: Array<Shop_Popularity_With_Points>
  /** fetch aggregated fields from the table: "shop_popularity_with_points" */
  shop_popularity_with_points_aggregate: Shop_Popularity_With_Points_Aggregate
  /** fetch data from the table: "shop_rating" */
  shop_rating: Array<Shop_Rating>
  /** fetch aggregated fields from the table: "shop_rating" */
  shop_rating_aggregate: Shop_Rating_Aggregate
  /** fetch data from the table: "shop_rating" using primary key columns */
  shop_rating_by_pk?: Maybe<Shop_Rating>
  /** Returns shopping products based on query */
  shoppingProducts: Array<ShoppingProductOutput>
  /** Returns similar products based on image URL */
  similarProducts: Array<SimilarProductOutput>
  /** fetch data from the table: "similar_products_history" */
  similar_products_history: Array<Similar_Products_History>
  /** fetch aggregated fields from the table: "similar_products_history" */
  similar_products_history_aggregate: Similar_Products_History_Aggregate
  /** fetch data from the table: "similar_products_history" using primary key columns */
  similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** execute function "similar_products_history_unique" which returns "similar_products_history" */
  similar_products_history_unique: Array<Similar_Products_History>
  /** execute function "similar_products_history_unique" and query aggregates on result of table type "similar_products_history" */
  similar_products_history_unique_aggregate: Similar_Products_History_Aggregate
  /** execute function "single_unread_notification_banner_item_for_user" which returns "notification_center_item" */
  single_unread_notification_banner_item_for_user?: Maybe<Notification_Center_Item>
  /** execute function "single_unread_notification_banner_item_for_user" and query aggregates on result of table type "notification_center_item" */
  single_unread_notification_banner_item_for_user_aggregate: Notification_Center_Item_Aggregate
  /** execute function "steal_their_looks" which returns "collection" */
  steal_their_looks: Array<Collection>
  /** execute function "steal_their_looks" and query aggregates on result of table type "collection" */
  steal_their_looks_aggregate: Collection_Aggregate
  /** execute function "steal_their_looks_random" which returns "collection" */
  steal_their_looks_random: Array<Collection>
  /** execute function "steal_their_looks_random" and query aggregates on result of table type "collection" */
  steal_their_looks_random_aggregate: Collection_Aggregate
  /** A query to be able to grab user's related to a user's phone contacts that are on Carrot as well as people that a user may know due to the information in the contacts */
  subscriptionSuggestions: Array<ContactSubscriptionSuggestion>
  /** execute function "used_creator_redirect" which returns "user" */
  used_creator_redirect: Array<User>
  /** execute function "used_creator_redirect" and query aggregates on result of table type "user" */
  used_creator_redirect_aggregate: User_Aggregate
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch data from the table: "user_account_type" */
  user_account_type: Array<User_Account_Type>
  /** fetch aggregated fields from the table: "user_account_type" */
  user_account_type_aggregate: User_Account_Type_Aggregate
  /** fetch data from the table: "user_account_type" using primary key columns */
  user_account_type_by_pk?: Maybe<User_Account_Type>
  /** fetch data from the table: "user_action" */
  user_action: Array<User_Action>
  /** fetch aggregated fields from the table: "user_action" */
  user_action_aggregate: User_Action_Aggregate
  /** fetch data from the table: "user_action" using primary key columns */
  user_action_by_pk?: Maybe<User_Action>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user_app_version" */
  user_app_version: Array<User_App_Version>
  /** fetch aggregated fields from the table: "user_app_version" */
  user_app_version_aggregate: User_App_Version_Aggregate
  /** fetch data from the table: "user_app_version" using primary key columns */
  user_app_version_by_pk?: Maybe<User_App_Version>
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_carts_onboarding" */
  user_carts_onboarding: Array<User_Carts_Onboarding>
  /** fetch aggregated fields from the table: "user_carts_onboarding" */
  user_carts_onboarding_aggregate: User_Carts_Onboarding_Aggregate
  /** fetch data from the table: "user_carts_onboarding" using primary key columns */
  user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** fetch data from the table: "user_category" */
  user_category: Array<User_Category>
  /** fetch aggregated fields from the table: "user_category" */
  user_category_aggregate: User_Category_Aggregate
  /** fetch data from the table: "user_category" using primary key columns */
  user_category_by_pk?: Maybe<User_Category>
  /** fetch data from the table: "user_deletion" */
  user_deletion: Array<User_Deletion>
  /** fetch aggregated fields from the table: "user_deletion" */
  user_deletion_aggregate: User_Deletion_Aggregate
  /** fetch data from the table: "user_deletion" using primary key columns */
  user_deletion_by_pk?: Maybe<User_Deletion>
  /** fetch data from the table: "user_feedback" */
  user_feedback: Array<User_Feedback>
  /** fetch aggregated fields from the table: "user_feedback" */
  user_feedback_aggregate: User_Feedback_Aggregate
  /** fetch data from the table: "user_feedback" using primary key columns */
  user_feedback_by_pk?: Maybe<User_Feedback>
  /** fetch data from the table: "user_feedback_item" */
  user_feedback_item: Array<User_Feedback_Item>
  /** fetch aggregated fields from the table: "user_feedback_item" */
  user_feedback_item_aggregate: User_Feedback_Item_Aggregate
  /** fetch data from the table: "user_feedback_item" using primary key columns */
  user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** fetch data from the table: "user_feedback_type" */
  user_feedback_type: Array<User_Feedback_Type>
  /** fetch aggregated fields from the table: "user_feedback_type" */
  user_feedback_type_aggregate: User_Feedback_Type_Aggregate
  /** fetch data from the table: "user_feedback_type" using primary key columns */
  user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** fetch data from the table: "user_flags" */
  user_flags: Array<User_Flags>
  /** fetch aggregated fields from the table: "user_flags" */
  user_flags_aggregate: User_Flags_Aggregate
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>
  /** fetch data from the table: "user_impersonation" */
  user_impersonation: Array<User_Impersonation>
  /** fetch aggregated fields from the table: "user_impersonation" */
  user_impersonation_aggregate: User_Impersonation_Aggregate
  /** fetch data from the table: "user_impersonation" using primary key columns */
  user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** fetch data from the table: "user_ip_address" */
  user_ip_address: Array<User_Ip_Address>
  /** fetch aggregated fields from the table: "user_ip_address" */
  user_ip_address_aggregate: User_Ip_Address_Aggregate
  /** fetch data from the table: "user_ip_address" using primary key columns */
  user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** fetch data from the table: "user_items_followed" */
  user_items_followed: Array<User_Items_Followed>
  /** fetch aggregated fields from the table: "user_items_followed" */
  user_items_followed_aggregate: User_Items_Followed_Aggregate
  /** fetch data from the table: "user_items_followed" using primary key columns */
  user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** fetch data from the table: "user_links" */
  user_links: Array<User_Links>
  /** fetch aggregated fields from the table: "user_links" */
  user_links_aggregate: User_Links_Aggregate
  /** fetch data from the table: "user_links" using primary key columns */
  user_links_by_pk?: Maybe<User_Links>
  /** fetch data from the table: "user_onboarding_source" */
  user_onboarding_source: Array<User_Onboarding_Source>
  /** fetch aggregated fields from the table: "user_onboarding_source" */
  user_onboarding_source_aggregate: User_Onboarding_Source_Aggregate
  /** fetch data from the table: "user_onboarding_source" using primary key columns */
  user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** fetch data from the table: "user_onboarding_state" */
  user_onboarding_state: Array<User_Onboarding_State>
  /** fetch aggregated fields from the table: "user_onboarding_state" */
  user_onboarding_state_aggregate: User_Onboarding_State_Aggregate
  /** fetch data from the table: "user_onboarding_state" using primary key columns */
  user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** fetch data from the table: "user_phone_contact" */
  user_phone_contact: Array<User_Phone_Contact>
  /** fetch aggregated fields from the table: "user_phone_contact" */
  user_phone_contact_aggregate: User_Phone_Contact_Aggregate
  /** fetch data from the table: "user_phone_contact" using primary key columns */
  user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** fetch data from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary: Array<User_Points_Redeemed_Summary>
  /** fetch aggregated fields from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary_aggregate: User_Points_Redeemed_Summary_Aggregate
  /** fetch data from the table: "user_points_summary" */
  user_points_summary: Array<User_Points_Summary>
  /** fetch aggregated fields from the table: "user_points_summary" */
  user_points_summary_aggregate: User_Points_Summary_Aggregate
  /** fetch data from the table: "user_purchase_subscription" */
  user_purchase_subscription: Array<User_Purchase_Subscription>
  /** fetch aggregated fields from the table: "user_purchase_subscription" */
  user_purchase_subscription_aggregate: User_Purchase_Subscription_Aggregate
  /** fetch data from the table: "user_purchase_subscription" using primary key columns */
  user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** fetch data from the table: "user_purchases" */
  user_purchases: Array<User_Purchases>
  /** fetch aggregated fields from the table: "user_purchases" */
  user_purchases_aggregate: User_Purchases_Aggregate
  /** fetch data from the table: "user_purchases" using primary key columns */
  user_purchases_by_pk?: Maybe<User_Purchases>
  /** fetch data from the table: "user_push_notification" */
  user_push_notification: Array<User_Push_Notification>
  /** fetch aggregated fields from the table: "user_push_notification" */
  user_push_notification_aggregate: User_Push_Notification_Aggregate
  /** fetch data from the table: "user_push_notification_token" */
  user_push_notification_token: Array<User_Push_Notification_Token>
  /** fetch aggregated fields from the table: "user_push_notification_token" */
  user_push_notification_token_aggregate: User_Push_Notification_Token_Aggregate
  /** fetch data from the table: "user_push_notification_token" using primary key columns */
  user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** fetch data from the table: "user_referral" */
  user_referral: Array<User_Referral>
  /** fetch aggregated fields from the table: "user_referral" */
  user_referral_aggregate: User_Referral_Aggregate
  /** fetch data from the table: "user_referral" using primary key columns */
  user_referral_by_pk?: Maybe<User_Referral>
  /** fetch data from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config: Array<User_Reward_Multiplier_Config>
  /** fetch aggregated fields from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config_aggregate: User_Reward_Multiplier_Config_Aggregate
  /** fetch data from the table: "user_reward_multiplier_config" using primary key columns */
  user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** fetch data from the table: "user_reward_redemption" */
  user_reward_redemption: Array<User_Reward_Redemption>
  /** fetch aggregated fields from the table: "user_reward_redemption" */
  user_reward_redemption_aggregate: User_Reward_Redemption_Aggregate
  /** fetch data from the table: "user_reward_redemption" using primary key columns */
  user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** fetch data from the table: "user_reward_reference" */
  user_reward_reference: Array<User_Reward_Reference>
  /** fetch aggregated fields from the table: "user_reward_reference" */
  user_reward_reference_aggregate: User_Reward_Reference_Aggregate
  /** fetch data from the table: "user_reward_reference" using primary key columns */
  user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** fetch data from the table: "user_shop_favorite" */
  user_shop_favorite: Array<User_Shop_Favorite>
  /** fetch aggregated fields from the table: "user_shop_favorite" */
  user_shop_favorite_aggregate: User_Shop_Favorite_Aggregate
  /** fetch data from the table: "user_shop_favorite" using primary key columns */
  user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** fetch data from the table: "user_shopping_preferences" */
  user_shopping_preferences: Array<User_Shopping_Preferences>
  /** fetch aggregated fields from the table: "user_shopping_preferences" */
  user_shopping_preferences_aggregate: User_Shopping_Preferences_Aggregate
  /** fetch data from the table: "user_shopping_preferences" using primary key columns */
  user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** fetch data from the table: "user_subscription" */
  user_subscription: Array<User_Subscription>
  /** fetch aggregated fields from the table: "user_subscription" */
  user_subscription_aggregate: User_Subscription_Aggregate
  /** fetch data from the table: "user_subscription" using primary key columns */
  user_subscription_by_pk?: Maybe<User_Subscription>
  /** fetch data from the table: "user_subscription_status" */
  user_subscription_status: Array<User_Subscription_Status>
  /** fetch aggregated fields from the table: "user_subscription_status" */
  user_subscription_status_aggregate: User_Subscription_Status_Aggregate
  /** fetch data from the table: "user_subscription_status" using primary key columns */
  user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** fetch data from the table: "user_temp" */
  user_temp: Array<User_Temp>
  /** fetch aggregated fields from the table: "user_temp" */
  user_temp_aggregate: User_Temp_Aggregate
  /** fetch data from the table: "user_temp" using primary key columns */
  user_temp_by_pk?: Maybe<User_Temp>
  /** execute function "users_search" which returns "user" */
  users_search: Array<User>
  /** execute function "users_search" and query aggregates on result of table type "user" */
  users_search_aggregate: User_Aggregate
  /** fetch data from the table: "worflow_type" */
  worflow_type: Array<Worflow_Type>
  /** fetch aggregated fields from the table: "worflow_type" */
  worflow_type_aggregate: Worflow_Type_Aggregate
  /** fetch data from the table: "worflow_type" using primary key columns */
  worflow_type_by_pk?: Maybe<Worflow_Type>
  /** fetch data from the table: "workflow" */
  workflow: Array<Workflow>
  /** fetch aggregated fields from the table: "workflow" */
  workflow_aggregate: Workflow_Aggregate
  /** fetch data from the table: "workflow" using primary key columns */
  workflow_by_pk?: Maybe<Workflow>
  /** fetch data from the table: "workflow_status" */
  workflow_status: Array<Workflow_Status>
  /** fetch aggregated fields from the table: "workflow_status" */
  workflow_status_aggregate: Workflow_Status_Aggregate
  /** fetch data from the table: "workflow_status" using primary key columns */
  workflow_status_by_pk?: Maybe<Workflow_Status>
}

export type Query_RootAccount_Deletion_ReasonArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Query_RootAccount_Deletion_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Query_RootAccount_Deletion_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootAffiliateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Query_RootAffiliate_Advertiser_IdArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Query_RootAffiliate_Advertiser_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Query_RootAffiliate_Advertiser_Id_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAffiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Query_RootAffiliate_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootAffiliate_Commission_SummaryArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Query_RootAffiliate_Commission_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Query_RootAffiliate_MetaArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Query_RootAffiliate_Meta_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Query_RootAffiliate_Meta_By_PkArgs = {
  affiliate: Affiliate_Enum
}

export type Query_RootAffiliate_TransactionArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Query_RootAffiliate_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Query_RootAffiliate_Transaction_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootAll_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootAll_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootAmazon_CategoriesArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Query_RootAmazon_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Query_RootAvailableSubscriptionsArgs = {
  creatorId: Scalars['String']
}

export type Query_RootBilling_ModeArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Query_RootBilling_Mode_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Query_RootBilling_Mode_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootBilling_Platform_Connected_AccountArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Query_RootBilling_Platform_Connected_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Query_RootBilling_Platform_Connected_Account_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootBilling_Platform_Connected_Account_StatusArgs = {
  distinct_on?: Maybe<
    Array<Billing_Platform_Connected_Account_Status_Select_Column>
  >
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
}

export type Query_RootBilling_Platform_Connected_Account_Status_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Billing_Platform_Connected_Account_Status_Select_Column>
    >
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
    where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  }

export type Query_RootBilling_Platform_Connected_Account_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootBilling_Platform_CustomerArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Query_RootBilling_Platform_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Query_RootBilling_Platform_Customer_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootCartArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Query_RootCart_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Query_RootCart_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Query_RootCart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Query_RootCart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCart_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Query_RootCart_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Query_RootCart_Item_Like_By_PkArgs = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Query_RootCart_Item_NoteArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Query_RootCart_Item_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Query_RootCart_Item_Note_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Query_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCategory_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Query_RootCategory_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Query_RootCategory_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCheckoutArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Query_RootCheckout_AggregateArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Query_RootCheckout_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_CollaboratorArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Query_RootCollection_Collaborator_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Query_RootCollection_Collaborator_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_InviteArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Query_RootCollection_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Query_RootCollection_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Query_RootCollection_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Query_RootCollection_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCollection_Item_ClaimArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Item_Claim_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Query_RootCollection_Item_Claim_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCollection_RandomArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_Random_AggregateArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootCollection_SectionArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Query_RootCollection_Section_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Query_RootCollection_Section_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Section_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Section_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Query_RootCollection_Section_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCollection_Slug_HistoryArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Query_RootCollection_Slug_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Query_RootCollection_Slug_History_By_PkArgs = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

export type Query_RootCollection_StateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Query_RootCollection_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Query_RootCollection_State_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCollection_TypeArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Query_RootCollection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Query_RootCollection_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCommentArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Query_RootComment_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Query_RootComment_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootCommentable_ItemArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Query_RootCommentable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Query_RootCommentable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

export type Query_RootCommentable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Query_RootCommentable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Query_RootCommentable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCommunication_TemplateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Query_RootCommunication_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Query_RootCommunication_Template_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCommunication_Template_Full_ListArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Query_RootCommunication_Template_Full_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Query_RootCommunication_Template_Full_List_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootContactConnectionsArgs = {
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

export type Query_RootContact_Info_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Query_RootContact_Info_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Query_RootContact_Info_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootContact_InviteArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Query_RootContact_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Query_RootContact_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootContact_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Query_RootContact_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Query_RootContact_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootContact_Invite_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Query_RootContact_Invite_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Query_RootContact_Invite_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootCreatorSubscriberChartDataArgs = {
  creator_id?: Maybe<Scalars['String']>
  end_date?: Maybe<Scalars['String']>
  start_date?: Maybe<Scalars['String']>
}

export type Query_RootCreator_Subscribers_RandomArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootCreator_Subscribers_Random_AggregateArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootCreator_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Query_RootCreator_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Query_RootDeal_Hop_GateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Query_RootDeal_Hop_Gate_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Query_RootDeal_Hop_Gate_By_PkArgs = {
  user_id: Scalars['String']
}

export type Query_RootDevice_Client_DataArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Query_RootDevice_Client_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Query_RootDevice_Client_Data_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDiscovery_BacklogArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Query_RootDiscovery_Backlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Query_RootDiscovery_Backlog_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDiscovery_FeedArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_Feed_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_Feed_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootDiscovery_Feed_RandomArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_Feed_Random_AggregateArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootDiscovery_StagingArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Query_RootDiscovery_Staging_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Query_RootDiscovery_Staging_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeatured_ShopArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Query_RootFeatured_Shop_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Query_RootFeatured_Shop_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeatured_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Query_RootFeatured_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Query_RootFeatured_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeatured_Shops_For_OnboardingArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootFeatured_Shops_For_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootFeed_ItemArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFeed_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_Like_By_PkArgs = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Query_RootFeed_Item_Like_RandomArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_Like_Random_AggregateArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Query_RootFeed_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Query_RootFeed_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Query_RootFeed_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootFeed_Items_For_Style_AccountsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_Style_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_UserArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_User_AggregateArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFeed_Items_For_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Query_RootFmtc_DealArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Query_RootFmtc_Deal_AggregateArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Query_RootFmtc_Deal_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootFollowable_ItemArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Query_RootFollowable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Query_RootFollowable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

export type Query_RootFollowable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Query_RootFollowable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Query_RootFollowable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootLeaderboard_EventArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Query_RootLeaderboard_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Query_RootLeaderboard_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootLeaderboard_Event_NameArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Query_RootLeaderboard_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Query_RootLeaderboard_Event_Name_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootLink_TypeArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Query_RootLink_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Query_RootLink_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootMedia_Enabled_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Query_RootMedia_Enabled_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Query_RootMedia_Enabled_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

export type Query_RootMedia_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Query_RootMedia_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Query_RootMedia_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMedia_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Query_RootMedia_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Query_RootMedia_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootMedia_ItemsArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Query_RootMedia_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Query_RootMedia_Items_By_PkArgs = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

export type Query_RootMedia_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Query_RootMedia_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Query_RootMedia_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootMention_Search_UsersArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootMention_Search_Users_AggregateArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootMobile_App_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Query_RootMobile_App_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Query_RootMobile_App_Home_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootMobile_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Query_RootMobile_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Query_RootMobile_Home_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMobile_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootMobile_Push_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Query_RootMobile_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootNotifiable_Feed_Item_UserArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Query_RootNotifiable_Feed_Item_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Query_RootNotifiable_ItemArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Query_RootNotifiable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Query_RootNotifiable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

export type Query_RootNotifiable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Query_RootNotifiable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Query_RootNotifiable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootNotificationArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Query_RootNotificationCenterItemsArgs = {
  cursor?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  read?: Maybe<Scalars['Boolean']>
  tasks?: Maybe<Scalars['Boolean']>
}

export type Query_RootNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNotification_Center_Feed_For_UserArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_Feed_For_User_AggregateArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_ItemArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootNotification_Center_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNotification_Center_Item_Read_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Read_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Read_Status_By_PkArgs = {
  notification_center_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Query_RootNotification_Center_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Query_RootNotification_Center_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootNotification_PreferenceArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Query_RootNotification_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Query_RootNotification_Preference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootNotification_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Query_RootNotification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Query_RootNotification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootOnboarding_CategoriesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Query_RootOnboarding_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Query_RootOnboarding_Categories_By_PkArgs = {
  category: Shop_Category_Enum
}

export type Query_RootOnboarding_SourcesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Query_RootOnboarding_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Query_RootOnboarding_Sources_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootOne_Off_EmailArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Query_RootOne_Off_Email_AggregateArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Query_RootOne_Off_Email_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootOther_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootOther_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootPageview_EventArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Query_RootPageview_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Query_RootPageview_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPageview_Event_SummaryArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Query_RootPageview_Event_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Query_RootPromo_PeriodArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Query_RootPromo_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Query_RootPromo_Period_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPush_NotificationArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Query_RootPush_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Query_RootPush_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Query_RootPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Query_RootPush_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRandom_Discovery_Feed_ItemsArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootRandom_Discovery_Feed_Items_AggregateArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Query_RootRecommended_Shops_By_UserArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRecommended_Shops_By_User_AggregateArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRedirect_IdsArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Query_RootRedirect_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Query_RootRedirect_Ids_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootRelated_Shops_By_CategoryArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRelated_Shops_By_Category_AggregateArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootRelated_Users_For_Shop_ItemArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootRelated_Users_For_Shop_Item_AggregateArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootRemove_ReasonArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Query_RootRemove_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Query_RootRemove_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootRewardable_Action_NamesArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Query_RootRewardable_Action_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Query_RootRewardable_Action_Names_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootRewardable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Query_RootRewardable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Query_RootRewardable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootSearchProductsArgs = {
  pagination?: Maybe<Scalars['String']>
  query: Scalars['String']
}

export type Query_RootSearch_QueryArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Query_RootSearch_Query_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Query_RootSearch_Query_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSearch_Query_ResultArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Query_RootSearch_Query_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Query_RootSearch_Query_Result_By_PkArgs = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

export type Query_RootSearch_Shop_ItemsArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootSearch_Shop_Items_AggregateArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootSearch_ShopsArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootSearch_Shops_AggregateArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootSearch_UsersArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootShopArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootShop_AffiliateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Query_RootShop_Affiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Query_RootShop_Affiliate_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Query_RootShop_By_PkArgs = {
  key: Scalars['String']
}

export type Query_RootShop_CategorizationArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Query_RootShop_Categorization_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Query_RootShop_Categorization_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Categorized_DealsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Query_RootShop_Categorized_Deals_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Query_RootShop_CategoryArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Query_RootShop_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Query_RootShop_Category_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootShop_CollectionsArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootShop_Collections_AggregateArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootShop_ItemArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootShop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Query_RootShop_Item_AvailabilityArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Query_RootShop_Item_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Query_RootShop_Item_Availability_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootShop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_Enrichment_StatusArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Query_RootShop_Item_Enrichment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Query_RootShop_Item_Enrichment_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootShop_Item_HistoryArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Query_RootShop_Item_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Query_RootShop_Item_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_ImageArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Query_RootShop_Item_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Query_RootShop_Item_Image_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_IndexArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Query_RootShop_Item_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Query_RootShop_Item_Index_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShop_Item_To_Be_ScrapedArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Query_RootShop_Item_To_Be_Scraped_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Query_RootShop_PopularityArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Query_RootShop_Popularity_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Query_RootShop_Popularity_With_PointsArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Query_RootShop_Popularity_With_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Query_RootShop_RatingArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Query_RootShop_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Query_RootShop_Rating_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootShoppingProductsArgs = {
  blue?: Maybe<Scalars['Int']>
  green?: Maybe<Scalars['Int']>
  location?: Maybe<Scalars['String']>
  query: Scalars['String']
  red?: Maybe<Scalars['Int']>
}

export type Query_RootSimilarProductsArgs = {
  allResults?: Maybe<Scalars['Boolean']>
  cartItemId?: Maybe<Scalars['uuid']>
  imageUrl: Scalars['String']
  noCache?: Maybe<Scalars['Boolean']>
  shopItemId?: Maybe<Scalars['uuid']>
}

export type Query_RootSimilar_Products_HistoryArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSimilar_Products_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSimilar_Products_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootSimilar_Products_History_UniqueArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSimilar_Products_History_Unique_AggregateArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Query_RootSingle_Unread_Notification_Banner_Item_For_UserArgs = {
  args: Single_Unread_Notification_Banner_Item_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Query_RootSingle_Unread_Notification_Banner_Item_For_User_AggregateArgs =
  {
    args: Single_Unread_Notification_Banner_Item_For_User_Args
    distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
    where?: Maybe<Notification_Center_Item_Bool_Exp>
  }

export type Query_RootSteal_Their_LooksArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSteal_Their_Looks_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSteal_Their_Looks_RandomArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSteal_Their_Looks_Random_AggregateArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Query_RootSubscriptionSuggestionsArgs = {
  degreesOfSeparation?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
}

export type Query_RootUsed_Creator_RedirectArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUsed_Creator_Redirect_AggregateArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUser_Account_TypeArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Query_RootUser_Account_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Query_RootUser_Account_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_ActionArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Query_RootUser_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Query_RootUser_Action_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUser_App_VersionArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Query_RootUser_App_Version_AggregateArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Query_RootUser_App_Version_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_By_PkArgs = {
  id: Scalars['String']
}

export type Query_RootUser_Carts_OnboardingArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Query_RootUser_Carts_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Query_RootUser_Carts_Onboarding_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_CategoryArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Query_RootUser_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Query_RootUser_Category_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_DeletionArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Query_RootUser_Deletion_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Query_RootUser_Deletion_By_PkArgs = {
  user_id: Scalars['String']
}

export type Query_RootUser_FeedbackArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Query_RootUser_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Query_RootUser_Feedback_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Feedback_ItemArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Query_RootUser_Feedback_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Query_RootUser_Feedback_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

export type Query_RootUser_Feedback_TypeArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Query_RootUser_Feedback_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Query_RootUser_Feedback_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_FlagsArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Query_RootUser_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Query_RootUser_Flags_By_PkArgs = {
  user_id: Scalars['String']
}

export type Query_RootUser_ImpersonationArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Query_RootUser_Impersonation_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Query_RootUser_Impersonation_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Ip_AddressArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Query_RootUser_Ip_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Query_RootUser_Ip_Address_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Items_FollowedArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Query_RootUser_Items_Followed_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Query_RootUser_Items_Followed_By_PkArgs = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

export type Query_RootUser_LinksArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Query_RootUser_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Query_RootUser_Links_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Onboarding_SourceArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Query_RootUser_Onboarding_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Query_RootUser_Onboarding_Source_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Onboarding_StateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Query_RootUser_Onboarding_State_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Query_RootUser_Onboarding_State_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_Phone_ContactArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Query_RootUser_Phone_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Query_RootUser_Phone_Contact_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Points_Redeemed_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Query_RootUser_Points_Redeemed_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Query_RootUser_Points_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Query_RootUser_Points_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Query_RootUser_Purchase_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Query_RootUser_Purchase_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Query_RootUser_Purchase_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_PurchasesArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Query_RootUser_Purchases_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Query_RootUser_Purchases_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Query_RootUser_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Query_RootUser_Push_Notification_TokenArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Query_RootUser_Push_Notification_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Query_RootUser_Push_Notification_Token_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_ReferralArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Query_RootUser_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Query_RootUser_Referral_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Reward_Multiplier_ConfigArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Query_RootUser_Reward_Multiplier_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Query_RootUser_Reward_Multiplier_Config_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Reward_RedemptionArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Query_RootUser_Reward_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Query_RootUser_Reward_Redemption_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Reward_ReferenceArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Query_RootUser_Reward_Reference_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Query_RootUser_Reward_Reference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Shop_FavoriteArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Query_RootUser_Shop_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Query_RootUser_Shop_Favorite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Shopping_PreferencesArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Query_RootUser_Shopping_Preferences_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Query_RootUser_Shopping_Preferences_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Query_RootUser_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Query_RootUser_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUser_Subscription_StatusArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Query_RootUser_Subscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Query_RootUser_Subscription_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootUser_TempArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Query_RootUser_Temp_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Query_RootUser_Temp_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootUsers_SearchArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootUsers_Search_AggregateArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Query_RootWorflow_TypeArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Query_RootWorflow_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Query_RootWorflow_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Query_RootWorkflowArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Query_RootWorkflow_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Query_RootWorkflow_By_PkArgs = {
  id: Scalars['uuid']
}

export type Query_RootWorkflow_StatusArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Query_RootWorkflow_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Query_RootWorkflow_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Random_Discovery_Feed_Items_Args = {
  discover_feed_ids?: Maybe<Scalars['_uuid']>
  offset?: Maybe<Scalars['Int']>
  seed?: Maybe<Scalars['seed_float']>
}

export type Recommended_Shops_By_User_Args = {
  user_id?: Maybe<Scalars['String']>
}

/** This table stores the cid and uid of each redirect transaction */
export type Redirect_Ids = {
  __typename?: 'redirect_ids'
  cid: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "redirect_ids" */
export type Redirect_Ids_Aggregate = {
  __typename?: 'redirect_ids_aggregate'
  aggregate?: Maybe<Redirect_Ids_Aggregate_Fields>
  nodes: Array<Redirect_Ids>
}

/** aggregate fields of "redirect_ids" */
export type Redirect_Ids_Aggregate_Fields = {
  __typename?: 'redirect_ids_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Redirect_Ids_Max_Fields>
  min?: Maybe<Redirect_Ids_Min_Fields>
}

/** aggregate fields of "redirect_ids" */
export type Redirect_Ids_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Redirect_Ids_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "redirect_ids". All fields are combined with a logical 'AND'. */
export type Redirect_Ids_Bool_Exp = {
  _and?: Maybe<Array<Redirect_Ids_Bool_Exp>>
  _not?: Maybe<Redirect_Ids_Bool_Exp>
  _or?: Maybe<Array<Redirect_Ids_Bool_Exp>>
  cid?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<String_Comparison_Exp>
  uid?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "redirect_ids" */
export enum Redirect_Ids_Constraint {
  /** unique or primary key constraint on columns "id" */
  AffiliateCidUidPkey = 'affiliate_cid_uid_pkey',
}

/** input type for inserting data into table "redirect_ids" */
export type Redirect_Ids_Insert_Input = {
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Redirect_Ids_Max_Fields = {
  __typename?: 'redirect_ids_max_fields'
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Redirect_Ids_Min_Fields = {
  __typename?: 'redirect_ids_min_fields'
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "redirect_ids" */
export type Redirect_Ids_Mutation_Response = {
  __typename?: 'redirect_ids_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Redirect_Ids>
}

/** on_conflict condition type for table "redirect_ids" */
export type Redirect_Ids_On_Conflict = {
  constraint: Redirect_Ids_Constraint
  update_columns?: Array<Redirect_Ids_Update_Column>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

/** Ordering options when selecting data from "redirect_ids". */
export type Redirect_Ids_Order_By = {
  cid?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  uid?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: redirect_ids */
export type Redirect_Ids_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "redirect_ids" */
export enum Redirect_Ids_Select_Column {
  /** column name */
  Cid = 'cid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "redirect_ids" */
export type Redirect_Ids_Set_Input = {
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "redirect_ids" */
export type Redirect_Ids_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Redirect_Ids_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Redirect_Ids_Stream_Cursor_Value_Input = {
  cid?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<Scalars['String']>
  uid?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "redirect_ids" */
export enum Redirect_Ids_Update_Column {
  /** column name */
  Cid = 'cid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Redirect_Ids_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Redirect_Ids_Set_Input>
  /** filter the rows which have to be updated */
  where: Redirect_Ids_Bool_Exp
}

export type Related_Shops_By_Category_Args = {
  shop_key?: Maybe<Scalars['String']>
}

export type Related_Users_For_Shop_Item_Args = {
  shop_item_id?: Maybe<Scalars['uuid']>
}

/** Enum table for Cart Item removal reasons */
export type Remove_Reason = {
  __typename?: 'remove_reason'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "remove_reason" */
export type Remove_Reason_Aggregate = {
  __typename?: 'remove_reason_aggregate'
  aggregate?: Maybe<Remove_Reason_Aggregate_Fields>
  nodes: Array<Remove_Reason>
}

/** aggregate fields of "remove_reason" */
export type Remove_Reason_Aggregate_Fields = {
  __typename?: 'remove_reason_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Remove_Reason_Max_Fields>
  min?: Maybe<Remove_Reason_Min_Fields>
}

/** aggregate fields of "remove_reason" */
export type Remove_Reason_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Remove_Reason_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "remove_reason". All fields are combined with a logical 'AND'. */
export type Remove_Reason_Bool_Exp = {
  _and?: Maybe<Array<Remove_Reason_Bool_Exp>>
  _not?: Maybe<Remove_Reason_Bool_Exp>
  _or?: Maybe<Array<Remove_Reason_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "remove_reason" */
export enum Remove_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */
  RemoveReasonPkey = 'remove_reason_pkey',
}

export enum Remove_Reason_Enum {
  /** Not longer interested in item */
  NotInterested = 'not_interested',
  /** Item was purchased */
  Purchased = 'purchased',
}

/** Boolean expression to compare columns of type "remove_reason_enum". All fields are combined with logical 'AND'. */
export type Remove_Reason_Enum_Comparison_Exp = {
  _eq?: Maybe<Remove_Reason_Enum>
  _in?: Maybe<Array<Remove_Reason_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Remove_Reason_Enum>
  _nin?: Maybe<Array<Remove_Reason_Enum>>
}

/** input type for inserting data into table "remove_reason" */
export type Remove_Reason_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Remove_Reason_Max_Fields = {
  __typename?: 'remove_reason_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Remove_Reason_Min_Fields = {
  __typename?: 'remove_reason_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "remove_reason" */
export type Remove_Reason_Mutation_Response = {
  __typename?: 'remove_reason_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Remove_Reason>
}

/** input type for inserting object relation for remote table "remove_reason" */
export type Remove_Reason_Obj_Rel_Insert_Input = {
  data: Remove_Reason_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Remove_Reason_On_Conflict>
}

/** on_conflict condition type for table "remove_reason" */
export type Remove_Reason_On_Conflict = {
  constraint: Remove_Reason_Constraint
  update_columns?: Array<Remove_Reason_Update_Column>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

/** Ordering options when selecting data from "remove_reason". */
export type Remove_Reason_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: remove_reason */
export type Remove_Reason_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "remove_reason" */
export enum Remove_Reason_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "remove_reason" */
export type Remove_Reason_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "remove_reason" */
export type Remove_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Remove_Reason_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Remove_Reason_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "remove_reason" */
export enum Remove_Reason_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Remove_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Remove_Reason_Set_Input>
  /** filter the rows which have to be updated */
  where: Remove_Reason_Bool_Exp
}

/** Rewardable user actions enum table */
export type Rewardable_Action_Names = {
  __typename?: 'rewardable_action_names'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "rewardable_action_names" */
export type Rewardable_Action_Names_Aggregate = {
  __typename?: 'rewardable_action_names_aggregate'
  aggregate?: Maybe<Rewardable_Action_Names_Aggregate_Fields>
  nodes: Array<Rewardable_Action_Names>
}

/** aggregate fields of "rewardable_action_names" */
export type Rewardable_Action_Names_Aggregate_Fields = {
  __typename?: 'rewardable_action_names_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Rewardable_Action_Names_Max_Fields>
  min?: Maybe<Rewardable_Action_Names_Min_Fields>
}

/** aggregate fields of "rewardable_action_names" */
export type Rewardable_Action_Names_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "rewardable_action_names". All fields are combined with a logical 'AND'. */
export type Rewardable_Action_Names_Bool_Exp = {
  _and?: Maybe<Array<Rewardable_Action_Names_Bool_Exp>>
  _not?: Maybe<Rewardable_Action_Names_Bool_Exp>
  _or?: Maybe<Array<Rewardable_Action_Names_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "rewardable_action_names" */
export enum Rewardable_Action_Names_Constraint {
  /** unique or primary key constraint on columns "value" */
  RewardableUserActionsPkey = 'rewardable_user_actions_pkey',
}

export enum Rewardable_Action_Names_Enum {
  BrandSelection = 'brand_selection',
  CategorySelection = 'category_selection',
  CompleteProfile = 'complete_profile',
  EnableDesktopExtension = 'enable_desktop_extension',
  EnablePushNotifications = 'enable_push_notifications',
  EnableSafariExtension = 'enable_safari_extension',
  /** 100 */
  OnboardingPromo = 'onboarding_promo',
  ReferFriend = 'refer_friend',
  /** 5 */
  RevealDeal = 'reveal_deal',
  Transaction = 'transaction',
  UpgradePro = 'upgrade_pro',
}

/** Boolean expression to compare columns of type "rewardable_action_names_enum". All fields are combined with logical 'AND'. */
export type Rewardable_Action_Names_Enum_Comparison_Exp = {
  _eq?: Maybe<Rewardable_Action_Names_Enum>
  _in?: Maybe<Array<Rewardable_Action_Names_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Rewardable_Action_Names_Enum>
  _nin?: Maybe<Array<Rewardable_Action_Names_Enum>>
}

/** input type for inserting data into table "rewardable_action_names" */
export type Rewardable_Action_Names_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Rewardable_Action_Names_Max_Fields = {
  __typename?: 'rewardable_action_names_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Rewardable_Action_Names_Min_Fields = {
  __typename?: 'rewardable_action_names_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "rewardable_action_names" */
export type Rewardable_Action_Names_Mutation_Response = {
  __typename?: 'rewardable_action_names_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rewardable_Action_Names>
}

/** input type for inserting object relation for remote table "rewardable_action_names" */
export type Rewardable_Action_Names_Obj_Rel_Insert_Input = {
  data: Rewardable_Action_Names_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Rewardable_Action_Names_On_Conflict>
}

/** on_conflict condition type for table "rewardable_action_names" */
export type Rewardable_Action_Names_On_Conflict = {
  constraint: Rewardable_Action_Names_Constraint
  update_columns?: Array<Rewardable_Action_Names_Update_Column>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

/** Ordering options when selecting data from "rewardable_action_names". */
export type Rewardable_Action_Names_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: rewardable_action_names */
export type Rewardable_Action_Names_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "rewardable_action_names" */
export enum Rewardable_Action_Names_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "rewardable_action_names" */
export type Rewardable_Action_Names_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "rewardable_action_names" */
export type Rewardable_Action_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewardable_Action_Names_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Rewardable_Action_Names_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "rewardable_action_names" */
export enum Rewardable_Action_Names_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Rewardable_Action_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rewardable_Action_Names_Set_Input>
  /** filter the rows which have to be updated */
  where: Rewardable_Action_Names_Bool_Exp
}

/** Enum table of rewardable item types */
export type Rewardable_Item_Type = {
  __typename?: 'rewardable_item_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "rewardable_item_type" */
export type Rewardable_Item_Type_Aggregate = {
  __typename?: 'rewardable_item_type_aggregate'
  aggregate?: Maybe<Rewardable_Item_Type_Aggregate_Fields>
  nodes: Array<Rewardable_Item_Type>
}

/** aggregate fields of "rewardable_item_type" */
export type Rewardable_Item_Type_Aggregate_Fields = {
  __typename?: 'rewardable_item_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Rewardable_Item_Type_Max_Fields>
  min?: Maybe<Rewardable_Item_Type_Min_Fields>
}

/** aggregate fields of "rewardable_item_type" */
export type Rewardable_Item_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "rewardable_item_type". All fields are combined with a logical 'AND'. */
export type Rewardable_Item_Type_Bool_Exp = {
  _and?: Maybe<Array<Rewardable_Item_Type_Bool_Exp>>
  _not?: Maybe<Rewardable_Item_Type_Bool_Exp>
  _or?: Maybe<Array<Rewardable_Item_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "rewardable_item_type" */
export enum Rewardable_Item_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  RewardableItemTypePkey = 'rewardable_item_type_pkey',
}

export enum Rewardable_Item_Type_Enum {
  AffiliateTransaction = 'affiliate_transaction',
  CartItem = 'cart_item',
  CollectionCartItem = 'collection_cart_item',
  User = 'user',
}

/** Boolean expression to compare columns of type "rewardable_item_type_enum". All fields are combined with logical 'AND'. */
export type Rewardable_Item_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Rewardable_Item_Type_Enum>
  _in?: Maybe<Array<Rewardable_Item_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Rewardable_Item_Type_Enum>
  _nin?: Maybe<Array<Rewardable_Item_Type_Enum>>
}

/** input type for inserting data into table "rewardable_item_type" */
export type Rewardable_Item_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Rewardable_Item_Type_Max_Fields = {
  __typename?: 'rewardable_item_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Rewardable_Item_Type_Min_Fields = {
  __typename?: 'rewardable_item_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "rewardable_item_type" */
export type Rewardable_Item_Type_Mutation_Response = {
  __typename?: 'rewardable_item_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Rewardable_Item_Type>
}

/** on_conflict condition type for table "rewardable_item_type" */
export type Rewardable_Item_Type_On_Conflict = {
  constraint: Rewardable_Item_Type_Constraint
  update_columns?: Array<Rewardable_Item_Type_Update_Column>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

/** Ordering options when selecting data from "rewardable_item_type". */
export type Rewardable_Item_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: rewardable_item_type */
export type Rewardable_Item_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "rewardable_item_type" */
export enum Rewardable_Item_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "rewardable_item_type" */
export type Rewardable_Item_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "rewardable_item_type" */
export type Rewardable_Item_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Rewardable_Item_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Rewardable_Item_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "rewardable_item_type" */
export enum Rewardable_Item_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Rewardable_Item_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Rewardable_Item_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Rewardable_Item_Type_Bool_Exp
}

/** A table to keep tabs on what searches our users are making */
export type Search_Query = {
  __typename?: 'search_query'
  bookmark?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  last_fetched_at: Scalars['timestamptz']
  page: Scalars['Int']
  /** An object relationship */
  previous_page?: Maybe<Search_Query>
  previous_page_id?: Maybe<Scalars['uuid']>
  query: Scalars['String']
  /** An array relationship */
  results: Array<Search_Query_Result>
  /** An aggregate relationship */
  results_aggregate: Search_Query_Result_Aggregate
  source: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** A table to keep tabs on what searches our users are making */
export type Search_QueryResultsArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

/** A table to keep tabs on what searches our users are making */
export type Search_QueryResults_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

/** aggregated selection of "search_query" */
export type Search_Query_Aggregate = {
  __typename?: 'search_query_aggregate'
  aggregate?: Maybe<Search_Query_Aggregate_Fields>
  nodes: Array<Search_Query>
}

/** aggregate fields of "search_query" */
export type Search_Query_Aggregate_Fields = {
  __typename?: 'search_query_aggregate_fields'
  avg?: Maybe<Search_Query_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Search_Query_Max_Fields>
  min?: Maybe<Search_Query_Min_Fields>
  stddev?: Maybe<Search_Query_Stddev_Fields>
  stddev_pop?: Maybe<Search_Query_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Search_Query_Stddev_Samp_Fields>
  sum?: Maybe<Search_Query_Sum_Fields>
  var_pop?: Maybe<Search_Query_Var_Pop_Fields>
  var_samp?: Maybe<Search_Query_Var_Samp_Fields>
  variance?: Maybe<Search_Query_Variance_Fields>
}

/** aggregate fields of "search_query" */
export type Search_Query_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Search_Query_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Search_Query_Avg_Fields = {
  __typename?: 'search_query_avg_fields'
  page?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "search_query". All fields are combined with a logical 'AND'. */
export type Search_Query_Bool_Exp = {
  _and?: Maybe<Array<Search_Query_Bool_Exp>>
  _not?: Maybe<Search_Query_Bool_Exp>
  _or?: Maybe<Array<Search_Query_Bool_Exp>>
  bookmark?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  last_fetched_at?: Maybe<Timestamptz_Comparison_Exp>
  page?: Maybe<Int_Comparison_Exp>
  previous_page?: Maybe<Search_Query_Bool_Exp>
  previous_page_id?: Maybe<Uuid_Comparison_Exp>
  query?: Maybe<String_Comparison_Exp>
  results?: Maybe<Search_Query_Result_Bool_Exp>
  results_aggregate?: Maybe<Search_Query_Result_Aggregate_Bool_Exp>
  source?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "search_query" */
export enum Search_Query_Constraint {
  /** unique or primary key constraint on columns "id" */
  SearchQueryPkey = 'search_query_pkey',
  /** unique or primary key constraint on columns "query", "source", "page" */
  SearchQueryQueryPageSourceKey = 'search_query_query_page_source_key',
}

/** input type for incrementing numeric columns in table "search_query" */
export type Search_Query_Inc_Input = {
  page?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "search_query" */
export type Search_Query_Insert_Input = {
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page?: Maybe<Search_Query_Obj_Rel_Insert_Input>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  results?: Maybe<Search_Query_Result_Arr_Rel_Insert_Input>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Search_Query_Max_Fields = {
  __typename?: 'search_query_max_fields'
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Search_Query_Min_Fields = {
  __typename?: 'search_query_min_fields'
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "search_query" */
export type Search_Query_Mutation_Response = {
  __typename?: 'search_query_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Search_Query>
}

/** input type for inserting object relation for remote table "search_query" */
export type Search_Query_Obj_Rel_Insert_Input = {
  data: Search_Query_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Search_Query_On_Conflict>
}

/** on_conflict condition type for table "search_query" */
export type Search_Query_On_Conflict = {
  constraint: Search_Query_Constraint
  update_columns?: Array<Search_Query_Update_Column>
  where?: Maybe<Search_Query_Bool_Exp>
}

/** Ordering options when selecting data from "search_query". */
export type Search_Query_Order_By = {
  bookmark?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  last_fetched_at?: Maybe<Order_By>
  page?: Maybe<Order_By>
  previous_page?: Maybe<Search_Query_Order_By>
  previous_page_id?: Maybe<Order_By>
  query?: Maybe<Order_By>
  results_aggregate?: Maybe<Search_Query_Result_Aggregate_Order_By>
  source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: search_query */
export type Search_Query_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** A table to keep track of all search results */
export type Search_Query_Result = {
  __typename?: 'search_query_result'
  created_at: Scalars['timestamptz']
  order: Scalars['Int']
  search_query_id: Scalars['uuid']
  /** An object relationship */
  shop_item: Shop_Item
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "search_query_result" */
export type Search_Query_Result_Aggregate = {
  __typename?: 'search_query_result_aggregate'
  aggregate?: Maybe<Search_Query_Result_Aggregate_Fields>
  nodes: Array<Search_Query_Result>
}

export type Search_Query_Result_Aggregate_Bool_Exp = {
  count?: Maybe<Search_Query_Result_Aggregate_Bool_Exp_Count>
}

export type Search_Query_Result_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Search_Query_Result_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Search_Query_Result_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "search_query_result" */
export type Search_Query_Result_Aggregate_Fields = {
  __typename?: 'search_query_result_aggregate_fields'
  avg?: Maybe<Search_Query_Result_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Search_Query_Result_Max_Fields>
  min?: Maybe<Search_Query_Result_Min_Fields>
  stddev?: Maybe<Search_Query_Result_Stddev_Fields>
  stddev_pop?: Maybe<Search_Query_Result_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Search_Query_Result_Stddev_Samp_Fields>
  sum?: Maybe<Search_Query_Result_Sum_Fields>
  var_pop?: Maybe<Search_Query_Result_Var_Pop_Fields>
  var_samp?: Maybe<Search_Query_Result_Var_Samp_Fields>
  variance?: Maybe<Search_Query_Result_Variance_Fields>
}

/** aggregate fields of "search_query_result" */
export type Search_Query_Result_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Search_Query_Result_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "search_query_result" */
export type Search_Query_Result_Aggregate_Order_By = {
  avg?: Maybe<Search_Query_Result_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Search_Query_Result_Max_Order_By>
  min?: Maybe<Search_Query_Result_Min_Order_By>
  stddev?: Maybe<Search_Query_Result_Stddev_Order_By>
  stddev_pop?: Maybe<Search_Query_Result_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Search_Query_Result_Stddev_Samp_Order_By>
  sum?: Maybe<Search_Query_Result_Sum_Order_By>
  var_pop?: Maybe<Search_Query_Result_Var_Pop_Order_By>
  var_samp?: Maybe<Search_Query_Result_Var_Samp_Order_By>
  variance?: Maybe<Search_Query_Result_Variance_Order_By>
}

/** input type for inserting array relation for remote table "search_query_result" */
export type Search_Query_Result_Arr_Rel_Insert_Input = {
  data: Array<Search_Query_Result_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Search_Query_Result_On_Conflict>
}

/** aggregate avg on columns */
export type Search_Query_Result_Avg_Fields = {
  __typename?: 'search_query_result_avg_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "search_query_result" */
export type Search_Query_Result_Avg_Order_By = {
  order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "search_query_result". All fields are combined with a logical 'AND'. */
export type Search_Query_Result_Bool_Exp = {
  _and?: Maybe<Array<Search_Query_Result_Bool_Exp>>
  _not?: Maybe<Search_Query_Result_Bool_Exp>
  _or?: Maybe<Array<Search_Query_Result_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  order?: Maybe<Int_Comparison_Exp>
  search_query_id?: Maybe<Uuid_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "search_query_result" */
export enum Search_Query_Result_Constraint {
  /** unique or primary key constraint on columns "search_query_id", "shop_item_id" */
  SearchQueryResultsPkey = 'search_query_results_pkey',
}

/** input type for incrementing numeric columns in table "search_query_result" */
export type Search_Query_Result_Inc_Input = {
  order?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "search_query_result" */
export type Search_Query_Result_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Search_Query_Result_Max_Fields = {
  __typename?: 'search_query_result_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "search_query_result" */
export type Search_Query_Result_Max_Order_By = {
  created_at?: Maybe<Order_By>
  order?: Maybe<Order_By>
  search_query_id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Search_Query_Result_Min_Fields = {
  __typename?: 'search_query_result_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "search_query_result" */
export type Search_Query_Result_Min_Order_By = {
  created_at?: Maybe<Order_By>
  order?: Maybe<Order_By>
  search_query_id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "search_query_result" */
export type Search_Query_Result_Mutation_Response = {
  __typename?: 'search_query_result_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Search_Query_Result>
}

/** on_conflict condition type for table "search_query_result" */
export type Search_Query_Result_On_Conflict = {
  constraint: Search_Query_Result_Constraint
  update_columns?: Array<Search_Query_Result_Update_Column>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

/** Ordering options when selecting data from "search_query_result". */
export type Search_Query_Result_Order_By = {
  created_at?: Maybe<Order_By>
  order?: Maybe<Order_By>
  search_query_id?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: search_query_result */
export type Search_Query_Result_Pk_Columns_Input = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

/** select columns of table "search_query_result" */
export enum Search_Query_Result_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Order = 'order',
  /** column name */
  SearchQueryId = 'search_query_id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "search_query_result" */
export type Search_Query_Result_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Search_Query_Result_Stddev_Fields = {
  __typename?: 'search_query_result_stddev_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "search_query_result" */
export type Search_Query_Result_Stddev_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Search_Query_Result_Stddev_Pop_Fields = {
  __typename?: 'search_query_result_stddev_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "search_query_result" */
export type Search_Query_Result_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Search_Query_Result_Stddev_Samp_Fields = {
  __typename?: 'search_query_result_stddev_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "search_query_result" */
export type Search_Query_Result_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** Streaming cursor of the table "search_query_result" */
export type Search_Query_Result_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Query_Result_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Search_Query_Result_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  order?: Maybe<Scalars['Int']>
  search_query_id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Search_Query_Result_Sum_Fields = {
  __typename?: 'search_query_result_sum_fields'
  order?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "search_query_result" */
export type Search_Query_Result_Sum_Order_By = {
  order?: Maybe<Order_By>
}

/** update columns of table "search_query_result" */
export enum Search_Query_Result_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Order = 'order',
  /** column name */
  SearchQueryId = 'search_query_id',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Search_Query_Result_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Search_Query_Result_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Search_Query_Result_Set_Input>
  /** filter the rows which have to be updated */
  where: Search_Query_Result_Bool_Exp
}

/** aggregate var_pop on columns */
export type Search_Query_Result_Var_Pop_Fields = {
  __typename?: 'search_query_result_var_pop_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "search_query_result" */
export type Search_Query_Result_Var_Pop_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Search_Query_Result_Var_Samp_Fields = {
  __typename?: 'search_query_result_var_samp_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "search_query_result" */
export type Search_Query_Result_Var_Samp_Order_By = {
  order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Search_Query_Result_Variance_Fields = {
  __typename?: 'search_query_result_variance_fields'
  order?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "search_query_result" */
export type Search_Query_Result_Variance_Order_By = {
  order?: Maybe<Order_By>
}

/** select columns of table "search_query" */
export enum Search_Query_Select_Column {
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetchedAt = 'last_fetched_at',
  /** column name */
  Page = 'page',
  /** column name */
  PreviousPageId = 'previous_page_id',
  /** column name */
  Query = 'query',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "search_query" */
export type Search_Query_Set_Input = {
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Search_Query_Stddev_Fields = {
  __typename?: 'search_query_stddev_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Search_Query_Stddev_Pop_Fields = {
  __typename?: 'search_query_stddev_pop_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Search_Query_Stddev_Samp_Fields = {
  __typename?: 'search_query_stddev_samp_fields'
  page?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "search_query" */
export type Search_Query_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Query_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Search_Query_Stream_Cursor_Value_Input = {
  bookmark?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  last_fetched_at?: Maybe<Scalars['timestamptz']>
  page?: Maybe<Scalars['Int']>
  previous_page_id?: Maybe<Scalars['uuid']>
  query?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Search_Query_Sum_Fields = {
  __typename?: 'search_query_sum_fields'
  page?: Maybe<Scalars['Int']>
}

/** update columns of table "search_query" */
export enum Search_Query_Update_Column {
  /** column name */
  Bookmark = 'bookmark',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastFetchedAt = 'last_fetched_at',
  /** column name */
  Page = 'page',
  /** column name */
  PreviousPageId = 'previous_page_id',
  /** column name */
  Query = 'query',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Search_Query_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Search_Query_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Search_Query_Set_Input>
  /** filter the rows which have to be updated */
  where: Search_Query_Bool_Exp
}

/** aggregate var_pop on columns */
export type Search_Query_Var_Pop_Fields = {
  __typename?: 'search_query_var_pop_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Search_Query_Var_Samp_Fields = {
  __typename?: 'search_query_var_samp_fields'
  page?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Search_Query_Variance_Fields = {
  __typename?: 'search_query_variance_fields'
  page?: Maybe<Scalars['Float']>
}

export type Search_Shop_Items_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type Search_Shops_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

export type Search_Users_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop" */
export type Shop = {
  __typename?: 'shop'
  affiliate?: Maybe<Affiliate_Enum>
  /** An object relationship */
  associated_affiliate?: Maybe<Affiliate>
  banner_image?: Maybe<Scalars['String']>
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  cart_path?: Maybe<Scalars['String']>
  /** An array relationship */
  carts: Array<Cart>
  /** An aggregate relationship */
  carts_aggregate: Cart_Aggregate
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_display_name" */
  display_name?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured: Scalars['Boolean']
  /** An object relationship */
  featured_shop?: Maybe<Featured_Shop>
  has_deals?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "shop_should_show_pdp" */
  has_pdp?: Maybe<Scalars['Boolean']>
  id: Scalars['uuid']
  /** An array relationship */
  items: Array<Shop_Item>
  /** An aggregate relationship */
  items_aggregate: Shop_Item_Aggregate
  key: Scalars['String']
  keywords?: Maybe<Array<Scalars['String']>>
  metadata: Scalars['jsonb']
  name: Scalars['name']
  name_override?: Maybe<Scalars['String']>
  /** An object relationship */
  parent_shop?: Maybe<Shop>
  parent_shop_key?: Maybe<Scalars['String']>
  parsed_take_rate?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  /** An array relationship */
  shop_affiliates: Array<Shop_Affiliate>
  /** An aggregate relationship */
  shop_affiliates_aggregate: Shop_Affiliate_Aggregate
  /** An array relationship */
  shop_categorizations: Array<Shop_Categorization>
  /** An aggregate relationship */
  shop_categorizations_aggregate: Shop_Categorization_Aggregate
  shop_faqs?: Maybe<Scalars['jsonb']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop_popularity?: Maybe<Shop_Popularity>
  /** Determines if we should show price drops in the UI */
  show_price_drops?: Maybe<Scalars['Boolean']>
  slug: Scalars['name']
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "shop" */
export type ShopCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopCartsArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopCarts_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopItemsArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopItems_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop" */
export type ShopParsed_Take_RateArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop" */
export type ShopShop_AffiliatesArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_Affiliates_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_CategorizationsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_Categorizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** columns and relationships of "shop" */
export type ShopShop_FaqsArgs = {
  path?: Maybe<Scalars['String']>
}

/** This table has the relationship between one shop and its affiliates */
export type Shop_Affiliate = {
  __typename?: 'shop_affiliate'
  /** An object relationship */
  affiliate: Affiliate
  affiliate_value: Affiliate_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "shop_affiliate" */
export type Shop_Affiliate_Aggregate = {
  __typename?: 'shop_affiliate_aggregate'
  aggregate?: Maybe<Shop_Affiliate_Aggregate_Fields>
  nodes: Array<Shop_Affiliate>
}

export type Shop_Affiliate_Aggregate_Bool_Exp = {
  count?: Maybe<Shop_Affiliate_Aggregate_Bool_Exp_Count>
}

export type Shop_Affiliate_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Affiliate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Affiliate_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_affiliate" */
export type Shop_Affiliate_Aggregate_Fields = {
  __typename?: 'shop_affiliate_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Affiliate_Max_Fields>
  min?: Maybe<Shop_Affiliate_Min_Fields>
}

/** aggregate fields of "shop_affiliate" */
export type Shop_Affiliate_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Affiliate_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_affiliate" */
export type Shop_Affiliate_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Affiliate_Max_Order_By>
  min?: Maybe<Shop_Affiliate_Min_Order_By>
}

/** input type for inserting array relation for remote table "shop_affiliate" */
export type Shop_Affiliate_Arr_Rel_Insert_Input = {
  data: Array<Shop_Affiliate_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Affiliate_On_Conflict>
}

/** Boolean expression to filter rows from the table "shop_affiliate". All fields are combined with a logical 'AND'. */
export type Shop_Affiliate_Bool_Exp = {
  _and?: Maybe<Array<Shop_Affiliate_Bool_Exp>>
  _not?: Maybe<Shop_Affiliate_Bool_Exp>
  _or?: Maybe<Array<Shop_Affiliate_Bool_Exp>>
  affiliate?: Maybe<Affiliate_Bool_Exp>
  affiliate_value?: Maybe<Affiliate_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_affiliate" */
export enum Shop_Affiliate_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopAffiliatePkey = 'shop_affiliate_pkey',
  /** unique or primary key constraint on columns "shop_key", "affiliate_value" */
  ShopAffiliateShopKeyAffiliateKey = 'shop_affiliate_shop_key_affiliate_key',
}

/** input type for inserting data into table "shop_affiliate" */
export type Shop_Affiliate_Insert_Input = {
  affiliate?: Maybe<Affiliate_Obj_Rel_Insert_Input>
  affiliate_value?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Shop_Affiliate_Max_Fields = {
  __typename?: 'shop_affiliate_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "shop_affiliate" */
export type Shop_Affiliate_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Affiliate_Min_Fields = {
  __typename?: 'shop_affiliate_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "shop_affiliate" */
export type Shop_Affiliate_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_affiliate" */
export type Shop_Affiliate_Mutation_Response = {
  __typename?: 'shop_affiliate_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Affiliate>
}

/** on_conflict condition type for table "shop_affiliate" */
export type Shop_Affiliate_On_Conflict = {
  constraint: Shop_Affiliate_Constraint
  update_columns?: Array<Shop_Affiliate_Update_Column>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

/** Ordering options when selecting data from "shop_affiliate". */
export type Shop_Affiliate_Order_By = {
  affiliate?: Maybe<Affiliate_Order_By>
  affiliate_value?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: shop_affiliate */
export type Shop_Affiliate_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_affiliate" */
export enum Shop_Affiliate_Select_Column {
  /** column name */
  AffiliateValue = 'affiliate_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shop_affiliate" */
export type Shop_Affiliate_Set_Input = {
  affiliate_value?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "shop_affiliate" */
export type Shop_Affiliate_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Affiliate_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Affiliate_Stream_Cursor_Value_Input = {
  affiliate_value?: Maybe<Affiliate_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "shop_affiliate" */
export enum Shop_Affiliate_Update_Column {
  /** column name */
  AffiliateValue = 'affiliate_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shop_Affiliate_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Affiliate_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Affiliate_Bool_Exp
}

export type Shop_Aggregate = {
  __typename?: 'shop_aggregate'
  aggregate?: Maybe<Shop_Aggregate_Fields>
  nodes: Array<Shop>
}

/** aggregate fields of "shop" */
export type Shop_Aggregate_Fields = {
  __typename?: 'shop_aggregate_fields'
  avg?: Maybe<Shop_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Max_Fields>
  min?: Maybe<Shop_Min_Fields>
  stddev?: Maybe<Shop_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Sum_Fields>
  var_pop?: Maybe<Shop_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Var_Samp_Fields>
  variance?: Maybe<Shop_Variance_Fields>
}

/** aggregate fields of "shop" */
export type Shop_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  shop_faqs?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Shop_Avg_Fields = {
  __typename?: 'shop_avg_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** Boolean expression to filter rows from the table "shop". All fields are combined with a logical 'AND'. */
export type Shop_Bool_Exp = {
  _and?: Maybe<Array<Shop_Bool_Exp>>
  _not?: Maybe<Shop_Bool_Exp>
  _or?: Maybe<Array<Shop_Bool_Exp>>
  affiliate?: Maybe<Affiliate_Enum_Comparison_Exp>
  associated_affiliate?: Maybe<Affiliate_Bool_Exp>
  banner_image?: Maybe<String_Comparison_Exp>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  cart_items_count?: Maybe<Numeric_Comparison_Exp>
  cart_path?: Maybe<String_Comparison_Exp>
  carts?: Maybe<Cart_Bool_Exp>
  carts_aggregate?: Maybe<Cart_Aggregate_Bool_Exp>
  cover_image?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  display_name?: Maybe<String_Comparison_Exp>
  domains?: Maybe<String_Array_Comparison_Exp>
  editorial_shop_logo?: Maybe<String_Comparison_Exp>
  favicon?: Maybe<String_Comparison_Exp>
  featured?: Maybe<Boolean_Comparison_Exp>
  featured_shop?: Maybe<Featured_Shop_Bool_Exp>
  has_deals?: Maybe<Boolean_Comparison_Exp>
  has_pdp?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  items?: Maybe<Shop_Item_Bool_Exp>
  items_aggregate?: Maybe<Shop_Item_Aggregate_Bool_Exp>
  key?: Maybe<String_Comparison_Exp>
  keywords?: Maybe<String_Array_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  name?: Maybe<Name_Comparison_Exp>
  name_override?: Maybe<String_Comparison_Exp>
  parent_shop?: Maybe<Shop_Bool_Exp>
  parent_shop_key?: Maybe<String_Comparison_Exp>
  parsed_take_rate?: Maybe<Jsonb_Comparison_Exp>
  platform?: Maybe<String_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  potential_spam?: Maybe<Boolean_Comparison_Exp>
  search_tokens?: Maybe<String_Array_Comparison_Exp>
  searchable_slug?: Maybe<String_Comparison_Exp>
  shop_affiliates?: Maybe<Shop_Affiliate_Bool_Exp>
  shop_affiliates_aggregate?: Maybe<Shop_Affiliate_Aggregate_Bool_Exp>
  shop_categorizations?: Maybe<Shop_Categorization_Bool_Exp>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Bool_Exp>
  shop_faqs?: Maybe<Jsonb_Comparison_Exp>
  shop_items_count?: Maybe<Numeric_Comparison_Exp>
  shop_popularity?: Maybe<Shop_Popularity_Bool_Exp>
  show_price_drops?: Maybe<Boolean_Comparison_Exp>
  slug?: Maybe<Name_Comparison_Exp>
  type?: Maybe<Name_Comparison_Exp>
  unknown?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** columns and relationships of "shop_categorization" */
export type Shop_Categorization = {
  __typename?: 'shop_categorization'
  category: Shop_Category_Enum
  created_at: Scalars['timestamptz']
  curation_score: Scalars['Int']
  id: Scalars['uuid']
  /** An object relationship */
  shop: Shop
  /** An object relationship */
  shop_category: Shop_Category
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
  weight: Scalars['Int']
}

/** aggregated selection of "shop_categorization" */
export type Shop_Categorization_Aggregate = {
  __typename?: 'shop_categorization_aggregate'
  aggregate?: Maybe<Shop_Categorization_Aggregate_Fields>
  nodes: Array<Shop_Categorization>
}

export type Shop_Categorization_Aggregate_Bool_Exp = {
  count?: Maybe<Shop_Categorization_Aggregate_Bool_Exp_Count>
}

export type Shop_Categorization_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Categorization_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Categorization_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_categorization" */
export type Shop_Categorization_Aggregate_Fields = {
  __typename?: 'shop_categorization_aggregate_fields'
  avg?: Maybe<Shop_Categorization_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Categorization_Max_Fields>
  min?: Maybe<Shop_Categorization_Min_Fields>
  stddev?: Maybe<Shop_Categorization_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Categorization_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Categorization_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Categorization_Sum_Fields>
  var_pop?: Maybe<Shop_Categorization_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Categorization_Var_Samp_Fields>
  variance?: Maybe<Shop_Categorization_Variance_Fields>
}

/** aggregate fields of "shop_categorization" */
export type Shop_Categorization_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Categorization_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_categorization" */
export type Shop_Categorization_Aggregate_Order_By = {
  avg?: Maybe<Shop_Categorization_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Categorization_Max_Order_By>
  min?: Maybe<Shop_Categorization_Min_Order_By>
  stddev?: Maybe<Shop_Categorization_Stddev_Order_By>
  stddev_pop?: Maybe<Shop_Categorization_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Shop_Categorization_Stddev_Samp_Order_By>
  sum?: Maybe<Shop_Categorization_Sum_Order_By>
  var_pop?: Maybe<Shop_Categorization_Var_Pop_Order_By>
  var_samp?: Maybe<Shop_Categorization_Var_Samp_Order_By>
  variance?: Maybe<Shop_Categorization_Variance_Order_By>
}

/** input type for inserting array relation for remote table "shop_categorization" */
export type Shop_Categorization_Arr_Rel_Insert_Input = {
  data: Array<Shop_Categorization_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Categorization_On_Conflict>
}

/** aggregate avg on columns */
export type Shop_Categorization_Avg_Fields = {
  __typename?: 'shop_categorization_avg_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "shop_categorization" */
export type Shop_Categorization_Avg_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "shop_categorization". All fields are combined with a logical 'AND'. */
export type Shop_Categorization_Bool_Exp = {
  _and?: Maybe<Array<Shop_Categorization_Bool_Exp>>
  _not?: Maybe<Shop_Categorization_Bool_Exp>
  _or?: Maybe<Array<Shop_Categorization_Bool_Exp>>
  category?: Maybe<Shop_Category_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  curation_score?: Maybe<Int_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_category?: Maybe<Shop_Category_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  weight?: Maybe<Int_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_categorization" */
export enum Shop_Categorization_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopCategorizationPkey = 'shop_categorization_pkey',
  /** unique or primary key constraint on columns "shop_key", "weight", "category" */
  ShopCategorizationShopKeyCategoryWeightKey = 'shop_categorization_shop_key_category_weight_key',
  /** unique or primary key constraint on columns "shop_key", "weight" */
  ShopCategorizationShopKeyWeightKey = 'shop_categorization_shop_key_weight_key',
}

/** input type for incrementing numeric columns in table "shop_categorization" */
export type Shop_Categorization_Inc_Input = {
  curation_score?: Maybe<Scalars['Int']>
  weight?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "shop_categorization" */
export type Shop_Categorization_Insert_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_category?: Maybe<Shop_Category_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** aggregate max on columns */
export type Shop_Categorization_Max_Fields = {
  __typename?: 'shop_categorization_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "shop_categorization" */
export type Shop_Categorization_Max_Order_By = {
  created_at?: Maybe<Order_By>
  curation_score?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Categorization_Min_Fields = {
  __typename?: 'shop_categorization_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "shop_categorization" */
export type Shop_Categorization_Min_Order_By = {
  created_at?: Maybe<Order_By>
  curation_score?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_categorization" */
export type Shop_Categorization_Mutation_Response = {
  __typename?: 'shop_categorization_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Categorization>
}

/** on_conflict condition type for table "shop_categorization" */
export type Shop_Categorization_On_Conflict = {
  constraint: Shop_Categorization_Constraint
  update_columns?: Array<Shop_Categorization_Update_Column>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** Ordering options when selecting data from "shop_categorization". */
export type Shop_Categorization_Order_By = {
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  curation_score?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_category?: Maybe<Shop_Category_Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** primary key columns input for table: shop_categorization */
export type Shop_Categorization_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_categorization" */
export enum Shop_Categorization_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight',
}

/** input type for updating data in table "shop_categorization" */
export type Shop_Categorization_Set_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** aggregate stddev on columns */
export type Shop_Categorization_Stddev_Fields = {
  __typename?: 'shop_categorization_stddev_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "shop_categorization" */
export type Shop_Categorization_Stddev_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Shop_Categorization_Stddev_Pop_Fields = {
  __typename?: 'shop_categorization_stddev_pop_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "shop_categorization" */
export type Shop_Categorization_Stddev_Pop_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Shop_Categorization_Stddev_Samp_Fields = {
  __typename?: 'shop_categorization_stddev_samp_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "shop_categorization" */
export type Shop_Categorization_Stddev_Samp_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** Streaming cursor of the table "shop_categorization" */
export type Shop_Categorization_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Categorization_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Categorization_Stream_Cursor_Value_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  curation_score?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  weight?: Maybe<Scalars['Int']>
}

/** aggregate sum on columns */
export type Shop_Categorization_Sum_Fields = {
  __typename?: 'shop_categorization_sum_fields'
  curation_score?: Maybe<Scalars['Int']>
  weight?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "shop_categorization" */
export type Shop_Categorization_Sum_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** update columns of table "shop_categorization" */
export enum Shop_Categorization_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurationScore = 'curation_score',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Weight = 'weight',
}

export type Shop_Categorization_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Categorization_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Categorization_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Categorization_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Categorization_Var_Pop_Fields = {
  __typename?: 'shop_categorization_var_pop_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "shop_categorization" */
export type Shop_Categorization_Var_Pop_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Shop_Categorization_Var_Samp_Fields = {
  __typename?: 'shop_categorization_var_samp_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "shop_categorization" */
export type Shop_Categorization_Var_Samp_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Shop_Categorization_Variance_Fields = {
  __typename?: 'shop_categorization_variance_fields'
  curation_score?: Maybe<Scalars['Float']>
  weight?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "shop_categorization" */
export type Shop_Categorization_Variance_Order_By = {
  curation_score?: Maybe<Order_By>
  weight?: Maybe<Order_By>
}

/** columns and relationships of "shop_categorized_deals" */
export type Shop_Categorized_Deals = {
  __typename?: 'shop_categorized_deals'
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop?: Maybe<Shop>
}

/** aggregated selection of "shop_categorized_deals" */
export type Shop_Categorized_Deals_Aggregate = {
  __typename?: 'shop_categorized_deals_aggregate'
  aggregate?: Maybe<Shop_Categorized_Deals_Aggregate_Fields>
  nodes: Array<Shop_Categorized_Deals>
}

/** aggregate fields of "shop_categorized_deals" */
export type Shop_Categorized_Deals_Aggregate_Fields = {
  __typename?: 'shop_categorized_deals_aggregate_fields'
  avg?: Maybe<Shop_Categorized_Deals_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Categorized_Deals_Max_Fields>
  min?: Maybe<Shop_Categorized_Deals_Min_Fields>
  stddev?: Maybe<Shop_Categorized_Deals_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Categorized_Deals_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Categorized_Deals_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Categorized_Deals_Sum_Fields>
  var_pop?: Maybe<Shop_Categorized_Deals_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Categorized_Deals_Var_Samp_Fields>
  variance?: Maybe<Shop_Categorized_Deals_Variance_Fields>
}

/** aggregate fields of "shop_categorized_deals" */
export type Shop_Categorized_Deals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Categorized_Deals_Avg_Fields = {
  __typename?: 'shop_categorized_deals_avg_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_categorized_deals". All fields are combined with a logical 'AND'. */
export type Shop_Categorized_Deals_Bool_Exp = {
  _and?: Maybe<Array<Shop_Categorized_Deals_Bool_Exp>>
  _not?: Maybe<Shop_Categorized_Deals_Bool_Exp>
  _or?: Maybe<Array<Shop_Categorized_Deals_Bool_Exp>>
  categories?: Maybe<String_Array_Comparison_Exp>
  deals_count?: Maybe<Bigint_Comparison_Exp>
  key?: Maybe<String_Comparison_Exp>
  popularity_rank?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
}

/** aggregate max on columns */
export type Shop_Categorized_Deals_Max_Fields = {
  __typename?: 'shop_categorized_deals_max_fields'
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate min on columns */
export type Shop_Categorized_Deals_Min_Fields = {
  __typename?: 'shop_categorized_deals_min_fields'
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** Ordering options when selecting data from "shop_categorized_deals". */
export type Shop_Categorized_Deals_Order_By = {
  categories?: Maybe<Order_By>
  deals_count?: Maybe<Order_By>
  key?: Maybe<Order_By>
  popularity_rank?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
}

/** select columns of table "shop_categorized_deals" */
export enum Shop_Categorized_Deals_Select_Column {
  /** column name */
  Categories = 'categories',
  /** column name */
  DealsCount = 'deals_count',
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
}

/** aggregate stddev on columns */
export type Shop_Categorized_Deals_Stddev_Fields = {
  __typename?: 'shop_categorized_deals_stddev_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Categorized_Deals_Stddev_Pop_Fields = {
  __typename?: 'shop_categorized_deals_stddev_pop_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Categorized_Deals_Stddev_Samp_Fields = {
  __typename?: 'shop_categorized_deals_stddev_samp_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_categorized_deals" */
export type Shop_Categorized_Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Categorized_Deals_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Categorized_Deals_Stream_Cursor_Value_Input = {
  categories?: Maybe<Array<Scalars['String']>>
  deals_count?: Maybe<Scalars['bigint']>
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate sum on columns */
export type Shop_Categorized_Deals_Sum_Fields = {
  __typename?: 'shop_categorized_deals_sum_fields'
  deals_count?: Maybe<Scalars['bigint']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Shop_Categorized_Deals_Var_Pop_Fields = {
  __typename?: 'shop_categorized_deals_var_pop_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Categorized_Deals_Var_Samp_Fields = {
  __typename?: 'shop_categorized_deals_var_samp_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Categorized_Deals_Variance_Fields = {
  __typename?: 'shop_categorized_deals_variance_fields'
  deals_count?: Maybe<Scalars['Float']>
  popularity_rank?: Maybe<Scalars['Float']>
}

/** columns and relationships of "shop_category" */
export type Shop_Category = {
  __typename?: 'shop_category'
  label?: Maybe<Scalars['String']>
  /** An object relationship */
  onboarding_category?: Maybe<Onboarding_Categories>
  /** An array relationship */
  shop_categorizations: Array<Shop_Categorization>
  /** An aggregate relationship */
  shop_categorizations_aggregate: Shop_Categorization_Aggregate
  value: Scalars['String']
}

/** columns and relationships of "shop_category" */
export type Shop_CategoryShop_CategorizationsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** columns and relationships of "shop_category" */
export type Shop_CategoryShop_Categorizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

/** aggregated selection of "shop_category" */
export type Shop_Category_Aggregate = {
  __typename?: 'shop_category_aggregate'
  aggregate?: Maybe<Shop_Category_Aggregate_Fields>
  nodes: Array<Shop_Category>
}

/** aggregate fields of "shop_category" */
export type Shop_Category_Aggregate_Fields = {
  __typename?: 'shop_category_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Category_Max_Fields>
  min?: Maybe<Shop_Category_Min_Fields>
}

/** aggregate fields of "shop_category" */
export type Shop_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Category_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_category". All fields are combined with a logical 'AND'. */
export type Shop_Category_Bool_Exp = {
  _and?: Maybe<Array<Shop_Category_Bool_Exp>>
  _not?: Maybe<Shop_Category_Bool_Exp>
  _or?: Maybe<Array<Shop_Category_Bool_Exp>>
  label?: Maybe<String_Comparison_Exp>
  onboarding_category?: Maybe<Onboarding_Categories_Bool_Exp>
  shop_categorizations?: Maybe<Shop_Categorization_Bool_Exp>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Bool_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_category" */
export enum Shop_Category_Constraint {
  /** unique or primary key constraint on columns "value" */
  ShopCategoryPkey = 'shop_category_pkey',
}

export enum Shop_Category_Enum {
  /** Arts & Crafts */
  ArtsCrafts = 'arts_crafts',
  /** Baby & Kids */
  BabyKids = 'baby_kids',
  /** Beauty & Cosmetics */
  BeautyCosmetics = 'beauty_cosmetics',
  /** Books */
  Books = 'books',
  /** DIY */
  Dyi = 'dyi',
  /** Electronics & Media */
  ElectronicsMedia = 'electronics_media',
  /** Eyewear */
  Eyewear = 'eyewear',
  /** Fashion & Apparel */
  FashionApparel = 'fashion_apparel',
  /** Food & Beverage */
  FoodBeverage = 'food_beverage',
  /** Footwear & Shoes */
  FootwearShoes = 'footwear_shoes',
  /** Gaming & Entertainment */
  GamingEntertainment = 'gaming_entertainment',
  /** Haute Couture */
  HauteCouture = 'haute_couture',
  /** Health, Fitness & Nutrition */
  HealthFitnessNutrition = 'health_fitness_nutrition',
  /** Home, Kitchen & Decor */
  HomeKitchenDecor = 'home_kitchen_decor',
  /** Intimates */
  Intimates = 'intimates',
  /** Jewelry */
  Jewelry = 'jewelry',
  /** Office */
  Office = 'office',
  /** Omnishop */
  Omnishop = 'omnishop',
  /** Outdoor & Adventure */
  OutdoorAdventure = 'outdoor_adventure',
  /** Pets & Animals */
  PetsAnimals = 'pets_animals',
  /** Plants & Flowers */
  PlantsFlowers = 'plants_flowers',
  /** Sleep */
  Sleep = 'sleep',
  /** Social Platform */
  SocialPlatform = 'social_platform',
  /** Sports & Athletics */
  SportsAthletics = 'sports_athletics',
  /** Tobacco and Related Products */
  TobaccoRelatedProducts = 'tobacco_related_products',
  /** Travel */
  Travel = 'travel',
  /** Well-being */
  Wellbeing = 'wellbeing',
}

/** Boolean expression to compare columns of type "shop_category_enum". All fields are combined with logical 'AND'. */
export type Shop_Category_Enum_Comparison_Exp = {
  _eq?: Maybe<Shop_Category_Enum>
  _in?: Maybe<Array<Shop_Category_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Shop_Category_Enum>
  _nin?: Maybe<Array<Shop_Category_Enum>>
}

/** input type for inserting data into table "shop_category" */
export type Shop_Category_Insert_Input = {
  label?: Maybe<Scalars['String']>
  onboarding_category?: Maybe<Onboarding_Categories_Obj_Rel_Insert_Input>
  shop_categorizations?: Maybe<Shop_Categorization_Arr_Rel_Insert_Input>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Category_Max_Fields = {
  __typename?: 'shop_category_max_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Category_Min_Fields = {
  __typename?: 'shop_category_min_fields'
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_category" */
export type Shop_Category_Mutation_Response = {
  __typename?: 'shop_category_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Category>
}

/** input type for inserting object relation for remote table "shop_category" */
export type Shop_Category_Obj_Rel_Insert_Input = {
  data: Shop_Category_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Category_On_Conflict>
}

/** on_conflict condition type for table "shop_category" */
export type Shop_Category_On_Conflict = {
  constraint: Shop_Category_Constraint
  update_columns?: Array<Shop_Category_Update_Column>
  where?: Maybe<Shop_Category_Bool_Exp>
}

/** Ordering options when selecting data from "shop_category". */
export type Shop_Category_Order_By = {
  label?: Maybe<Order_By>
  onboarding_category?: Maybe<Onboarding_Categories_Order_By>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: shop_category */
export type Shop_Category_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "shop_category" */
export enum Shop_Category_Select_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "shop_category" */
export type Shop_Category_Set_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_category" */
export type Shop_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Category_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Category_Stream_Cursor_Value_Input = {
  label?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "shop_category" */
export enum Shop_Category_Update_Column {
  /** column name */
  Label = 'label',
  /** column name */
  Value = 'value',
}

export type Shop_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Category_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Category_Bool_Exp
}

export type Shop_Collections_Args = {
  shop_key?: Maybe<Scalars['String']>
}

/** unique or primary key constraints on table "shop" */
export enum Shop_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopIdKey = 'shop_id_key',
  /** unique or primary key constraint on columns "key" */
  ShopPkey = 'shop_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
  shop_faqs?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
  shop_faqs?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
  shop_faqs?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "shop" */
export type Shop_Insert_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  associated_affiliate?: Maybe<Affiliate_Obj_Rel_Insert_Input>
  banner_image?: Maybe<Scalars['String']>
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  cart_path?: Maybe<Scalars['String']>
  carts?: Maybe<Cart_Arr_Rel_Insert_Input>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  featured_shop?: Maybe<Featured_Shop_Obj_Rel_Insert_Input>
  has_deals?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  items?: Maybe<Shop_Item_Arr_Rel_Insert_Input>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  name_override?: Maybe<Scalars['String']>
  parent_shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  shop_affiliates?: Maybe<Shop_Affiliate_Arr_Rel_Insert_Input>
  shop_categorizations?: Maybe<Shop_Categorization_Arr_Rel_Insert_Input>
  shop_faqs?: Maybe<Scalars['jsonb']>
  shop_popularity?: Maybe<Shop_Popularity_Obj_Rel_Insert_Input>
  slug?: Maybe<Scalars['name']>
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "shop_item" */
export type Shop_Item = {
  __typename?: 'shop_item'
  availability?: Maybe<Shop_Item_Availability_Enum>
  /** The generated cached image url that is based on our Imgix web proxy source */
  cached_image?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_cached_placeholder_image" */
  cached_placeholder_image?: Maybe<Scalars['String']>
  /** An array relationship */
  cart_items: Array<Cart_Item>
  /** An aggregate relationship */
  cart_items_aggregate: Cart_Item_Aggregate
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "shop_item_indexed" */
  computed_should_index?: Maybe<Scalars['Boolean']>
  created_at: Scalars['timestamptz']
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_display_title" */
  display_title?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  /** A computed field, executes function "shop_item_has_broken_image" */
  has_broken_image?: Maybe<Scalars['Boolean']>
  hashtags?: Maybe<Array<Scalars['String']>>
  id: Scalars['uuid']
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate: Scalars['Boolean']
  item_id: Scalars['String']
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
  pricing?: Maybe<Scalars['jsonb']>
  /** A function used to determine if a `shop_item` has been saved by the requesting user. */
  saved_by_current_user?: Maybe<Scalars['Boolean']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  /** An object relationship */
  shop: Shop
  /** An object relationship */
  shop_item_availability?: Maybe<Shop_Item_Availability>
  /** An array relationship */
  shop_item_histories: Array<Shop_Item_History>
  /** An aggregate relationship */
  shop_item_histories_aggregate: Shop_Item_History_Aggregate
  /** An array relationship */
  shop_item_images: Array<Shop_Item_Image>
  /** An aggregate relationship */
  shop_item_images_aggregate: Shop_Item_Image_Aggregate
  shop_key: Scalars['String']
  /** An array relationship */
  similar_products_histories: Array<Similar_Products_History>
  /** An aggregate relationship */
  similar_products_histories_aggregate: Similar_Products_History_Aggregate
  /** An object relationship */
  similar_products_history?: Maybe<Similar_Products_History>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  url?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemCart_ItemsArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemCart_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemImage_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemParsed_PricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSchema_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSerapi_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSerpapi_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_HistoriesArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_ImagesArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemShop_Item_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSimilar_Products_HistoriesArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "shop_item" */
export type Shop_ItemSimilar_Products_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** aggregated selection of "shop_item" */
export type Shop_Item_Aggregate = {
  __typename?: 'shop_item_aggregate'
  aggregate?: Maybe<Shop_Item_Aggregate_Fields>
  nodes: Array<Shop_Item>
}

export type Shop_Item_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Shop_Item_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Shop_Item_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Shop_Item_Aggregate_Bool_Exp_Count>
}

export type Shop_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_item" */
export type Shop_Item_Aggregate_Fields = {
  __typename?: 'shop_item_aggregate_fields'
  avg?: Maybe<Shop_Item_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Max_Fields>
  min?: Maybe<Shop_Item_Min_Fields>
  stddev?: Maybe<Shop_Item_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Item_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Item_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Item_Sum_Fields>
  var_pop?: Maybe<Shop_Item_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Item_Var_Samp_Fields>
  variance?: Maybe<Shop_Item_Variance_Fields>
}

/** aggregate fields of "shop_item" */
export type Shop_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_item" */
export type Shop_Item_Aggregate_Order_By = {
  avg?: Maybe<Shop_Item_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Item_Max_Order_By>
  min?: Maybe<Shop_Item_Min_Order_By>
  stddev?: Maybe<Shop_Item_Stddev_Order_By>
  stddev_pop?: Maybe<Shop_Item_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Shop_Item_Stddev_Samp_Order_By>
  sum?: Maybe<Shop_Item_Sum_Order_By>
  var_pop?: Maybe<Shop_Item_Var_Pop_Order_By>
  var_samp?: Maybe<Shop_Item_Var_Samp_Order_By>
  variance?: Maybe<Shop_Item_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Append_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "shop_item" */
export type Shop_Item_Arr_Rel_Insert_Input = {
  data: Array<Shop_Item_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** Shop item availability Enum table */
export type Shop_Item_Availability = {
  __typename?: 'shop_item_availability'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "shop_item_availability" */
export type Shop_Item_Availability_Aggregate = {
  __typename?: 'shop_item_availability_aggregate'
  aggregate?: Maybe<Shop_Item_Availability_Aggregate_Fields>
  nodes: Array<Shop_Item_Availability>
}

/** aggregate fields of "shop_item_availability" */
export type Shop_Item_Availability_Aggregate_Fields = {
  __typename?: 'shop_item_availability_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Availability_Max_Fields>
  min?: Maybe<Shop_Item_Availability_Min_Fields>
}

/** aggregate fields of "shop_item_availability" */
export type Shop_Item_Availability_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_item_availability". All fields are combined with a logical 'AND'. */
export type Shop_Item_Availability_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Availability_Bool_Exp>>
  _not?: Maybe<Shop_Item_Availability_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Availability_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_availability" */
export enum Shop_Item_Availability_Constraint {
  /** unique or primary key constraint on columns "value" */
  ShopItemAvailabilityPkey = 'shop_item_availability_pkey',
}

export enum Shop_Item_Availability_Enum {
  /** Back Order */
  BackOrder = 'BackOrder',
  /** Discontinued */
  Discontinued = 'Discontinued',
  /** In Stock */
  InStock = 'InStock',
  /** In Store Only */
  InStoreOnly = 'InStoreOnly',
  /** Limited Availability */
  LimitedAvailability = 'LimitedAvailability',
  /** Online Only */
  OnlineOnly = 'OnlineOnly',
  /** Out Of Stock */
  OutOfStock = 'OutOfStock',
  /** Pre-Order */
  PreOrder = 'PreOrder',
  /** Pre-Sale */
  PreSale = 'PreSale',
  /** Sold Out */
  SoldOut = 'SoldOut',
  /** Unknown */
  Unknown = 'Unknown',
}

/** Boolean expression to compare columns of type "shop_item_availability_enum". All fields are combined with logical 'AND'. */
export type Shop_Item_Availability_Enum_Comparison_Exp = {
  _eq?: Maybe<Shop_Item_Availability_Enum>
  _in?: Maybe<Array<Shop_Item_Availability_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Shop_Item_Availability_Enum>
  _nin?: Maybe<Array<Shop_Item_Availability_Enum>>
}

/** input type for inserting data into table "shop_item_availability" */
export type Shop_Item_Availability_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Availability_Max_Fields = {
  __typename?: 'shop_item_availability_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Item_Availability_Min_Fields = {
  __typename?: 'shop_item_availability_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_item_availability" */
export type Shop_Item_Availability_Mutation_Response = {
  __typename?: 'shop_item_availability_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Availability>
}

/** input type for inserting object relation for remote table "shop_item_availability" */
export type Shop_Item_Availability_Obj_Rel_Insert_Input = {
  data: Shop_Item_Availability_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_Availability_On_Conflict>
}

/** on_conflict condition type for table "shop_item_availability" */
export type Shop_Item_Availability_On_Conflict = {
  constraint: Shop_Item_Availability_Constraint
  update_columns?: Array<Shop_Item_Availability_Update_Column>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_availability". */
export type Shop_Item_Availability_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_availability */
export type Shop_Item_Availability_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "shop_item_availability" */
export enum Shop_Item_Availability_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "shop_item_availability" */
export type Shop_Item_Availability_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_availability" */
export type Shop_Item_Availability_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Availability_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Availability_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "shop_item_availability" */
export enum Shop_Item_Availability_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Shop_Item_Availability_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Availability_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Availability_Bool_Exp
}

/** aggregate avg on columns */
export type Shop_Item_Avg_Fields = {
  __typename?: 'shop_item_avg_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by avg() on columns of table "shop_item" */
export type Shop_Item_Avg_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "shop_item". All fields are combined with a logical 'AND'. */
export type Shop_Item_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Bool_Exp>>
  _not?: Maybe<Shop_Item_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Bool_Exp>>
  availability?: Maybe<Shop_Item_Availability_Enum_Comparison_Exp>
  cached_image?: Maybe<String_Comparison_Exp>
  cached_placeholder_image?: Maybe<String_Comparison_Exp>
  cart_items?: Maybe<Cart_Item_Bool_Exp>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Bool_Exp>
  categories?: Maybe<String_Array_Comparison_Exp>
  color_thief_processed?: Maybe<Boolean_Comparison_Exp>
  computed_should_index?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  display_title?: Maybe<String_Comparison_Exp>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum_Comparison_Exp>
  has_broken_image?: Maybe<Boolean_Comparison_Exp>
  hashtags?: Maybe<String_Array_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  image_color_palette?: Maybe<Smallint_Array_Comparison_Exp>
  image_highres?: Maybe<String_Comparison_Exp>
  image_metadata?: Maybe<Jsonb_Comparison_Exp>
  image_primary_b?: Maybe<Smallint_Comparison_Exp>
  image_primary_g?: Maybe<Smallint_Comparison_Exp>
  image_primary_r?: Maybe<Smallint_Comparison_Exp>
  is_inappropriate?: Maybe<Boolean_Comparison_Exp>
  item_id?: Maybe<String_Comparison_Exp>
  keywords?: Maybe<String_Array_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  number_of_collections?: Maybe<Int_Comparison_Exp>
  onboarding_categories?: Maybe<String_Array_Comparison_Exp>
  open_ai_attempts?: Maybe<Smallint_Comparison_Exp>
  parsed_pricing?: Maybe<Jsonb_Comparison_Exp>
  pdp_views?: Maybe<Int_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  saved_by_current_user?: Maybe<Boolean_Comparison_Exp>
  schema_metadata?: Maybe<Jsonb_Comparison_Exp>
  serapi_metadata?: Maybe<Jsonb_Comparison_Exp>
  serpapi_metadata?: Maybe<Jsonb_Comparison_Exp>
  sharesheet_html?: Maybe<String_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_item_availability?: Maybe<Shop_Item_Availability_Bool_Exp>
  shop_item_histories?: Maybe<Shop_Item_History_Bool_Exp>
  shop_item_histories_aggregate?: Maybe<Shop_Item_History_Aggregate_Bool_Exp>
  shop_item_images?: Maybe<Shop_Item_Image_Bool_Exp>
  shop_item_images_aggregate?: Maybe<Shop_Item_Image_Aggregate_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  similar_products_histories?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Bool_Exp>
  similar_products_history?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_history_id?: Maybe<Uuid_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  to_be_scraped?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item" */
export enum Shop_Item_Constraint {
  /** unique or primary key constraint on columns "shop_key", "id" */
  ShopItemIdShopKeyKey = 'shop_item_id_shop_key_key',
  /** unique or primary key constraint on columns "id" */
  ShopItemPkey = 'shop_item_pkey',
  /** unique or primary key constraint on columns "shop_key", "item_id" */
  ShopItemShopKeyItemIdKey = 'shop_item_shop_key_item_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_Delete_At_Path_Input = {
  image_metadata?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Array<Scalars['String']>>
  parsed_pricing?: Maybe<Array<Scalars['String']>>
  pricing?: Maybe<Array<Scalars['String']>>
  schema_metadata?: Maybe<Array<Scalars['String']>>
  serapi_metadata?: Maybe<Array<Scalars['String']>>
  serpapi_metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_Delete_Elem_Input = {
  image_metadata?: Maybe<Scalars['Int']>
  metadata?: Maybe<Scalars['Int']>
  parsed_pricing?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['Int']>
  schema_metadata?: Maybe<Scalars['Int']>
  serapi_metadata?: Maybe<Scalars['Int']>
  serpapi_metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_Delete_Key_Input = {
  image_metadata?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['String']>
  pricing?: Maybe<Scalars['String']>
  schema_metadata?: Maybe<Scalars['String']>
  serapi_metadata?: Maybe<Scalars['String']>
  serpapi_metadata?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status = {
  __typename?: 'shop_item_enrichment_status'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Aggregate = {
  __typename?: 'shop_item_enrichment_status_aggregate'
  aggregate?: Maybe<Shop_Item_Enrichment_Status_Aggregate_Fields>
  nodes: Array<Shop_Item_Enrichment_Status>
}

/** aggregate fields of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Aggregate_Fields = {
  __typename?: 'shop_item_enrichment_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Enrichment_Status_Max_Fields>
  min?: Maybe<Shop_Item_Enrichment_Status_Min_Fields>
}

/** aggregate fields of "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_item_enrichment_status". All fields are combined with a logical 'AND'. */
export type Shop_Item_Enrichment_Status_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Enrichment_Status_Bool_Exp>>
  _not?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Enrichment_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_enrichment_status" */
export enum Shop_Item_Enrichment_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnrichmentStatusPkey = 'enrichment_status_pkey',
}

export enum Shop_Item_Enrichment_Status_Enum {
  Failed = 'failed',
  /** A status to let the custom database trigger know to set either the succeeded or failed status */
  FinishedScraperApi = 'finished_scraper_api',
  NoOp = 'no_op',
  Pending = 'pending',
  /** A status to indicate that we are awaiting results from scraper API */
  PendingScraperApi = 'pending_scraper_api',
  Processing = 'processing',
  Succeeded = 'succeeded',
  /** Used to trigger a shop item enrichment upon insertion into the database */
  Trigger = 'trigger',
}

/** Boolean expression to compare columns of type "shop_item_enrichment_status_enum". All fields are combined with logical 'AND'. */
export type Shop_Item_Enrichment_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Shop_Item_Enrichment_Status_Enum>
  _in?: Maybe<Array<Shop_Item_Enrichment_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Shop_Item_Enrichment_Status_Enum>
  _nin?: Maybe<Array<Shop_Item_Enrichment_Status_Enum>>
}

/** input type for inserting data into table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Enrichment_Status_Max_Fields = {
  __typename?: 'shop_item_enrichment_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Item_Enrichment_Status_Min_Fields = {
  __typename?: 'shop_item_enrichment_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Mutation_Response = {
  __typename?: 'shop_item_enrichment_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Enrichment_Status>
}

/** on_conflict condition type for table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_On_Conflict = {
  constraint: Shop_Item_Enrichment_Status_Constraint
  update_columns?: Array<Shop_Item_Enrichment_Status_Update_Column>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_enrichment_status". */
export type Shop_Item_Enrichment_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_enrichment_status */
export type Shop_Item_Enrichment_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "shop_item_enrichment_status" */
export enum Shop_Item_Enrichment_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_enrichment_status" */
export type Shop_Item_Enrichment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Enrichment_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Enrichment_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "shop_item_enrichment_status" */
export enum Shop_Item_Enrichment_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Shop_Item_Enrichment_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Enrichment_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Enrichment_Status_Bool_Exp
}

/** columns and relationships of "shop_item_history" */
export type Shop_Item_History = {
  __typename?: 'shop_item_history'
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at: Scalars['timestamptz']
  http_status_code?: Maybe<Scalars['Int']>
  id: Scalars['uuid']
  metadata: Scalars['jsonb']
  possible_unavailable: Scalars['Boolean']
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  /** An object relationship */
  shop_item: Shop_Item
  /** An object relationship */
  shop_item_availability?: Maybe<Shop_Item_Availability>
  shop_item_id: Scalars['uuid']
  strategy: Scalars['String']
  updated_at: Scalars['timestamptz']
  valid: Scalars['Boolean']
}

/** columns and relationships of "shop_item_history" */
export type Shop_Item_HistoryMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_history" */
export type Shop_Item_HistoryPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "shop_item_history" */
export type Shop_Item_History_Aggregate = {
  __typename?: 'shop_item_history_aggregate'
  aggregate?: Maybe<Shop_Item_History_Aggregate_Fields>
  nodes: Array<Shop_Item_History>
}

export type Shop_Item_History_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Shop_Item_History_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<Shop_Item_History_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<Shop_Item_History_Aggregate_Bool_Exp_Count>
}

export type Shop_Item_History_Aggregate_Bool_Exp_Bool_And = {
  arguments: Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_History_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_History_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_History_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type Shop_Item_History_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Item_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_History_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_item_history" */
export type Shop_Item_History_Aggregate_Fields = {
  __typename?: 'shop_item_history_aggregate_fields'
  avg?: Maybe<Shop_Item_History_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Item_History_Max_Fields>
  min?: Maybe<Shop_Item_History_Min_Fields>
  stddev?: Maybe<Shop_Item_History_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Item_History_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Item_History_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Item_History_Sum_Fields>
  var_pop?: Maybe<Shop_Item_History_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Item_History_Var_Samp_Fields>
  variance?: Maybe<Shop_Item_History_Variance_Fields>
}

/** aggregate fields of "shop_item_history" */
export type Shop_Item_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_item_history" */
export type Shop_Item_History_Aggregate_Order_By = {
  avg?: Maybe<Shop_Item_History_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Item_History_Max_Order_By>
  min?: Maybe<Shop_Item_History_Min_Order_By>
  stddev?: Maybe<Shop_Item_History_Stddev_Order_By>
  stddev_pop?: Maybe<Shop_Item_History_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Shop_Item_History_Stddev_Samp_Order_By>
  sum?: Maybe<Shop_Item_History_Sum_Order_By>
  var_pop?: Maybe<Shop_Item_History_Var_Pop_Order_By>
  var_samp?: Maybe<Shop_Item_History_Var_Samp_Order_By>
  variance?: Maybe<Shop_Item_History_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_History_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "shop_item_history" */
export type Shop_Item_History_Arr_Rel_Insert_Input = {
  data: Array<Shop_Item_History_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_History_On_Conflict>
}

/** aggregate avg on columns */
export type Shop_Item_History_Avg_Fields = {
  __typename?: 'shop_item_history_avg_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "shop_item_history" */
export type Shop_Item_History_Avg_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "shop_item_history". All fields are combined with a logical 'AND'. */
export type Shop_Item_History_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_History_Bool_Exp>>
  _not?: Maybe<Shop_Item_History_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_History_Bool_Exp>>
  availability?: Maybe<Shop_Item_Availability_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  http_status_code?: Maybe<Int_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  possible_unavailable?: Maybe<Boolean_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  scraper_http_status_code?: Maybe<Int_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_availability?: Maybe<Shop_Item_Availability_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  strategy?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  valid?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_history" */
export enum Shop_Item_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopItemHistoryPkey = 'shop_item_history_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_History_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
  pricing?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_History_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_History_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
  pricing?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "shop_item_history" */
export type Shop_Item_History_Inc_Input = {
  http_status_code?: Maybe<Scalars['Int']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "shop_item_history" */
export type Shop_Item_History_Insert_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  possible_unavailable?: Maybe<Scalars['Boolean']>
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_availability?: Maybe<Shop_Item_Availability_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  valid?: Maybe<Scalars['Boolean']>
}

/** aggregate max on columns */
export type Shop_Item_History_Max_Fields = {
  __typename?: 'shop_item_history_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "shop_item_history" */
export type Shop_Item_History_Max_Order_By = {
  created_at?: Maybe<Order_By>
  http_status_code?: Maybe<Order_By>
  id?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  strategy?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Item_History_Min_Fields = {
  __typename?: 'shop_item_history_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "shop_item_history" */
export type Shop_Item_History_Min_Order_By = {
  created_at?: Maybe<Order_By>
  http_status_code?: Maybe<Order_By>
  id?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  strategy?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_item_history" */
export type Shop_Item_History_Mutation_Response = {
  __typename?: 'shop_item_history_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_History>
}

/** on_conflict condition type for table "shop_item_history" */
export type Shop_Item_History_On_Conflict = {
  constraint: Shop_Item_History_Constraint
  update_columns?: Array<Shop_Item_History_Update_Column>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_history". */
export type Shop_Item_History_Order_By = {
  availability?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  http_status_code?: Maybe<Order_By>
  id?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  possible_unavailable?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_availability?: Maybe<Shop_Item_Availability_Order_By>
  shop_item_id?: Maybe<Order_By>
  strategy?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  valid?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_history */
export type Shop_Item_History_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_History_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item_history" */
export enum Shop_Item_History_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HttpStatusCode = 'http_status_code',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  ScraperHttpStatusCode = 'scraper_http_status_code',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  Strategy = 'strategy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Valid = 'valid',
}

/** select "shop_item_history_aggregate_bool_exp_bool_and_arguments_columns" columns of table "shop_item_history" */
export enum Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Valid = 'valid',
}

/** select "shop_item_history_aggregate_bool_exp_bool_or_arguments_columns" columns of table "shop_item_history" */
export enum Shop_Item_History_Select_Column_Shop_Item_History_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Valid = 'valid',
}

/** input type for updating data in table "shop_item_history" */
export type Shop_Item_History_Set_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  possible_unavailable?: Maybe<Scalars['Boolean']>
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  valid?: Maybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type Shop_Item_History_Stddev_Fields = {
  __typename?: 'shop_item_history_stddev_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "shop_item_history" */
export type Shop_Item_History_Stddev_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Shop_Item_History_Stddev_Pop_Fields = {
  __typename?: 'shop_item_history_stddev_pop_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "shop_item_history" */
export type Shop_Item_History_Stddev_Pop_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Shop_Item_History_Stddev_Samp_Fields = {
  __typename?: 'shop_item_history_stddev_samp_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "shop_item_history" */
export type Shop_Item_History_Stddev_Samp_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** Streaming cursor of the table "shop_item_history" */
export type Shop_Item_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_History_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_History_Stream_Cursor_Value_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  http_status_code?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['uuid']>
  metadata?: Maybe<Scalars['jsonb']>
  possible_unavailable?: Maybe<Scalars['Boolean']>
  pricing?: Maybe<Scalars['jsonb']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
  shop_item_id?: Maybe<Scalars['uuid']>
  strategy?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  valid?: Maybe<Scalars['Boolean']>
}

/** aggregate sum on columns */
export type Shop_Item_History_Sum_Fields = {
  __typename?: 'shop_item_history_sum_fields'
  http_status_code?: Maybe<Scalars['Int']>
  scraper_http_status_code?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "shop_item_history" */
export type Shop_Item_History_Sum_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** update columns of table "shop_item_history" */
export enum Shop_Item_History_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HttpStatusCode = 'http_status_code',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PossibleUnavailable = 'possible_unavailable',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  ScraperHttpStatusCode = 'scraper_http_status_code',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  Strategy = 'strategy',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Valid = 'valid',
}

export type Shop_Item_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_History_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_History_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_History_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_History_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Item_History_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_History_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_History_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_History_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Item_History_Var_Pop_Fields = {
  __typename?: 'shop_item_history_var_pop_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "shop_item_history" */
export type Shop_Item_History_Var_Pop_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Shop_Item_History_Var_Samp_Fields = {
  __typename?: 'shop_item_history_var_samp_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "shop_item_history" */
export type Shop_Item_History_Var_Samp_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Shop_Item_History_Variance_Fields = {
  __typename?: 'shop_item_history_variance_fields'
  http_status_code?: Maybe<Scalars['Float']>
  scraper_http_status_code?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "shop_item_history" */
export type Shop_Item_History_Variance_Order_By = {
  http_status_code?: Maybe<Order_By>
  scraper_http_status_code?: Maybe<Order_By>
}

/** This table contains the list of images of one specifi item */
export type Shop_Item_Image = {
  __typename?: 'shop_item_image'
  /** A computed field, executes function "shop_item_image_cached" */
  cached?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata: Scalars['jsonb']
  /** An object relationship */
  shop_item: Shop_Item
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  url: Scalars['String']
}

/** This table contains the list of images of one specifi item */
export type Shop_Item_ImageImage_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** This table contains the list of images of one specifi item */
export type Shop_Item_ImageMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "shop_item_image" */
export type Shop_Item_Image_Aggregate = {
  __typename?: 'shop_item_image_aggregate'
  aggregate?: Maybe<Shop_Item_Image_Aggregate_Fields>
  nodes: Array<Shop_Item_Image>
}

export type Shop_Item_Image_Aggregate_Bool_Exp = {
  count?: Maybe<Shop_Item_Image_Aggregate_Bool_Exp_Count>
}

export type Shop_Item_Image_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shop_Item_Image_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Shop_Item_Image_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "shop_item_image" */
export type Shop_Item_Image_Aggregate_Fields = {
  __typename?: 'shop_item_image_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Image_Max_Fields>
  min?: Maybe<Shop_Item_Image_Min_Fields>
}

/** aggregate fields of "shop_item_image" */
export type Shop_Item_Image_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Image_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "shop_item_image" */
export type Shop_Item_Image_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Shop_Item_Image_Max_Order_By>
  min?: Maybe<Shop_Item_Image_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Image_Append_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "shop_item_image" */
export type Shop_Item_Image_Arr_Rel_Insert_Input = {
  data: Array<Shop_Item_Image_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_Image_On_Conflict>
}

/** Boolean expression to filter rows from the table "shop_item_image". All fields are combined with a logical 'AND'. */
export type Shop_Item_Image_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Image_Bool_Exp>>
  _not?: Maybe<Shop_Item_Image_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Image_Bool_Exp>>
  cached?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image_metadata?: Maybe<Jsonb_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_image" */
export enum Shop_Item_Image_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopItemImagePkey = 'shop_item_image_pkey',
  /** unique or primary key constraint on columns "shop_item_id", "url" */
  ShopItemImageShopItemIdUrlKey = 'shop_item_image_shop_item_id_url_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_Image_Delete_At_Path_Input = {
  image_metadata?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_Image_Delete_Elem_Input = {
  image_metadata?: Maybe<Scalars['Int']>
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_Image_Delete_Key_Input = {
  image_metadata?: Maybe<Scalars['String']>
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "shop_item_image" */
export type Shop_Item_Image_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Image_Max_Fields = {
  __typename?: 'shop_item_image_max_fields'
  /** A computed field, executes function "shop_item_image_cached" */
  cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "shop_item_image" */
export type Shop_Item_Image_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Item_Image_Min_Fields = {
  __typename?: 'shop_item_image_min_fields'
  /** A computed field, executes function "shop_item_image_cached" */
  cached?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "shop_item_image" */
export type Shop_Item_Image_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_item_image" */
export type Shop_Item_Image_Mutation_Response = {
  __typename?: 'shop_item_image_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Image>
}

/** on_conflict condition type for table "shop_item_image" */
export type Shop_Item_Image_On_Conflict = {
  constraint: Shop_Item_Image_Constraint
  update_columns?: Array<Shop_Item_Image_Update_Column>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_image". */
export type Shop_Item_Image_Order_By = {
  cached?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_metadata?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_image */
export type Shop_Item_Image_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Image_Prepend_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item_image" */
export enum Shop_Item_Image_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "shop_item_image" */
export type Shop_Item_Image_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_image" */
export type Shop_Item_Image_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Image_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Image_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** update columns of table "shop_item_image" */
export enum Shop_Item_Image_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

export type Shop_Item_Image_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_Image_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_Image_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_Image_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_Image_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_Image_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Image_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Image_Bool_Exp
}

/** input type for incrementing numeric columns in table "shop_item" */
export type Shop_Item_Inc_Input = {
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
}

/** Table to determine if a shop item PDP can & has been indexed  */
export type Shop_Item_Index = {
  __typename?: 'shop_item_index'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  index: Scalars['Boolean']
  indexable: Scalars['Boolean']
  shop_item_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "shop_item_index" */
export type Shop_Item_Index_Aggregate = {
  __typename?: 'shop_item_index_aggregate'
  aggregate?: Maybe<Shop_Item_Index_Aggregate_Fields>
  nodes: Array<Shop_Item_Index>
}

/** aggregate fields of "shop_item_index" */
export type Shop_Item_Index_Aggregate_Fields = {
  __typename?: 'shop_item_index_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_Index_Max_Fields>
  min?: Maybe<Shop_Item_Index_Min_Fields>
}

/** aggregate fields of "shop_item_index" */
export type Shop_Item_Index_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_Index_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "shop_item_index". All fields are combined with a logical 'AND'. */
export type Shop_Item_Index_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_Index_Bool_Exp>>
  _not?: Maybe<Shop_Item_Index_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_Index_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  index?: Maybe<Boolean_Comparison_Exp>
  indexable?: Maybe<Boolean_Comparison_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_item_index" */
export enum Shop_Item_Index_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopItemIndexPkey = 'shop_item_index_pkey',
  /** unique or primary key constraint on columns "shop_item_id" */
  ShopItemIndexShopItemIdKey = 'shop_item_index_shop_item_id_key',
}

/** input type for inserting data into table "shop_item_index" */
export type Shop_Item_Index_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  index?: Maybe<Scalars['Boolean']>
  indexable?: Maybe<Scalars['Boolean']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Shop_Item_Index_Max_Fields = {
  __typename?: 'shop_item_index_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Shop_Item_Index_Min_Fields = {
  __typename?: 'shop_item_index_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "shop_item_index" */
export type Shop_Item_Index_Mutation_Response = {
  __typename?: 'shop_item_index_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_Index>
}

/** on_conflict condition type for table "shop_item_index" */
export type Shop_Item_Index_On_Conflict = {
  constraint: Shop_Item_Index_Constraint
  update_columns?: Array<Shop_Item_Index_Update_Column>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item_index". */
export type Shop_Item_Index_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  index?: Maybe<Order_By>
  indexable?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item_index */
export type Shop_Item_Index_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_item_index" */
export enum Shop_Item_Index_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Indexable = 'indexable',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shop_item_index" */
export type Shop_Item_Index_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  index?: Maybe<Scalars['Boolean']>
  indexable?: Maybe<Scalars['Boolean']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "shop_item_index" */
export type Shop_Item_Index_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Index_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Index_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  index?: Maybe<Scalars['Boolean']>
  indexable?: Maybe<Scalars['Boolean']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "shop_item_index" */
export enum Shop_Item_Index_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Index = 'index',
  /** column name */
  Indexable = 'indexable',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shop_Item_Index_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Index_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Index_Bool_Exp
}

/** input type for inserting data into table "shop_item" */
export type Shop_Item_Insert_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  cart_items?: Maybe<Cart_Item_Arr_Rel_Insert_Input>
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate?: Maybe<Scalars['Boolean']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_item_availability?: Maybe<Shop_Item_Availability_Obj_Rel_Insert_Input>
  shop_item_histories?: Maybe<Shop_Item_History_Arr_Rel_Insert_Input>
  shop_item_images?: Maybe<Shop_Item_Image_Arr_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  similar_products_histories?: Maybe<Similar_Products_History_Arr_Rel_Insert_Input>
  similar_products_history?: Maybe<Similar_Products_History_Obj_Rel_Insert_Input>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_Max_Fields = {
  __typename?: 'shop_item_max_fields'
  /** The generated cached image url that is based on our Imgix web proxy source */
  cached_image?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_cached_placeholder_image" */
  cached_placeholder_image?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_display_title" */
  display_title?: Maybe<Scalars['String']>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "shop_item" */
export type Shop_Item_Max_Order_By = {
  categories?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  hashtags?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  onboarding_categories?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
  sharesheet_html?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_history_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Shop_Item_Min_Fields = {
  __typename?: 'shop_item_min_fields'
  /** The generated cached image url that is based on our Imgix web proxy source */
  cached_image?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_cached_placeholder_image" */
  cached_placeholder_image?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['String']>>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_item_display_title" */
  display_title?: Maybe<Scalars['String']>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "shop_item" */
export type Shop_Item_Min_Order_By = {
  categories?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  hashtags?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  onboarding_categories?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
  sharesheet_html?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_history_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** response of any mutation on the table "shop_item" */
export type Shop_Item_Mutation_Response = {
  __typename?: 'shop_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item>
}

/** input type for inserting object relation for remote table "shop_item" */
export type Shop_Item_Obj_Rel_Insert_Input = {
  data: Shop_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Item_On_Conflict>
}

/** on_conflict condition type for table "shop_item" */
export type Shop_Item_On_Conflict = {
  constraint: Shop_Item_Constraint
  update_columns?: Array<Shop_Item_Update_Column>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** Ordering options when selecting data from "shop_item". */
export type Shop_Item_Order_By = {
  availability?: Maybe<Order_By>
  cached_image?: Maybe<Order_By>
  cached_placeholder_image?: Maybe<Order_By>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  categories?: Maybe<Order_By>
  color_thief_processed?: Maybe<Order_By>
  computed_should_index?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_title?: Maybe<Order_By>
  enrichment_status?: Maybe<Order_By>
  has_broken_image?: Maybe<Order_By>
  hashtags?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_color_palette?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  image_metadata?: Maybe<Order_By>
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  is_inappropriate?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  number_of_collections?: Maybe<Order_By>
  onboarding_categories?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  parsed_pricing?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
  points?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  saved_by_current_user?: Maybe<Order_By>
  schema_metadata?: Maybe<Order_By>
  serapi_metadata?: Maybe<Order_By>
  serpapi_metadata?: Maybe<Order_By>
  sharesheet_html?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_item_availability?: Maybe<Shop_Item_Availability_Order_By>
  shop_item_histories_aggregate?: Maybe<Shop_Item_History_Aggregate_Order_By>
  shop_item_images_aggregate?: Maybe<Shop_Item_Image_Aggregate_Order_By>
  shop_key?: Maybe<Order_By>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Order_By>
  similar_products_history?: Maybe<Similar_Products_History_Order_By>
  similar_products_history_id?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  to_be_scraped?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** primary key columns input for table: shop_item */
export type Shop_Item_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_Prepend_Input = {
  image_metadata?: Maybe<Scalars['jsonb']>
  metadata?: Maybe<Scalars['jsonb']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item" */
export enum Shop_Item_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  Categories = 'categories',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnrichmentStatus = 'enrichment_status',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageHighres = 'image_highres',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnboardingCategories = 'onboarding_categories',
  /** column name */
  OpenAiAttempts = 'open_ai_attempts',
  /** column name */
  ParsedPricing = 'parsed_pricing',
  /** column name */
  PdpViews = 'pdp_views',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  SchemaMetadata = 'schema_metadata',
  /** column name */
  SerapiMetadata = 'serapi_metadata',
  /** column name */
  SerpapiMetadata = 'serpapi_metadata',
  /** column name */
  SharesheetHtml = 'sharesheet_html',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  SimilarProductsHistoryId = 'similar_products_history_id',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeScraped = 'to_be_scraped',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** select "shop_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "shop_item" */
export enum Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ToBeScraped = 'to_be_scraped',
}

/** select "shop_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "shop_item" */
export enum Shop_Item_Select_Column_Shop_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ToBeScraped = 'to_be_scraped',
}

/** input type for updating data in table "shop_item" */
export type Shop_Item_Set_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate?: Maybe<Scalars['Boolean']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Shop_Item_Stddev_Fields = {
  __typename?: 'shop_item_stddev_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by stddev() on columns of table "shop_item" */
export type Shop_Item_Stddev_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Shop_Item_Stddev_Pop_Fields = {
  __typename?: 'shop_item_stddev_pop_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by stddev_pop() on columns of table "shop_item" */
export type Shop_Item_Stddev_Pop_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Shop_Item_Stddev_Samp_Fields = {
  __typename?: 'shop_item_stddev_samp_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by stddev_samp() on columns of table "shop_item" */
export type Shop_Item_Stddev_Samp_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** Streaming cursor of the table "shop_item" */
export type Shop_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_Stream_Cursor_Value_Input = {
  availability?: Maybe<Shop_Item_Availability_Enum>
  categories?: Maybe<Array<Scalars['String']>>
  color_thief_processed?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  enrichment_status?: Maybe<Shop_Item_Enrichment_Status_Enum>
  hashtags?: Maybe<Array<Scalars['String']>>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_color_palette?: Maybe<Array<Scalars['smallint']>>
  image_highres?: Maybe<Scalars['String']>
  image_metadata?: Maybe<Scalars['jsonb']>
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  is_inappropriate?: Maybe<Scalars['Boolean']>
  item_id?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  onboarding_categories?: Maybe<Array<Scalars['String']>>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pdp_views?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  serapi_metadata?: Maybe<Scalars['jsonb']>
  serpapi_metadata?: Maybe<Scalars['jsonb']>
  sharesheet_html?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  similar_products_history_id?: Maybe<Scalars['uuid']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Shop_Item_Sum_Fields = {
  __typename?: 'shop_item_sum_fields'
  image_primary_b?: Maybe<Scalars['smallint']>
  image_primary_g?: Maybe<Scalars['smallint']>
  image_primary_r?: Maybe<Scalars['smallint']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['smallint']>
  pdp_views?: Maybe<Scalars['Int']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "shop_item" */
export type Shop_Item_Sum_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped = {
  __typename?: 'shop_item_to_be_scraped'
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_ScrapedParsed_PricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_ScrapedPricingArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_ScrapedSchema_MetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Aggregate = {
  __typename?: 'shop_item_to_be_scraped_aggregate'
  aggregate?: Maybe<Shop_Item_To_Be_Scraped_Aggregate_Fields>
  nodes: Array<Shop_Item_To_Be_Scraped>
}

/** aggregate fields of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Aggregate_Fields = {
  __typename?: 'shop_item_to_be_scraped_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Shop_Item_To_Be_Scraped_Max_Fields>
  min?: Maybe<Shop_Item_To_Be_Scraped_Min_Fields>
}

/** aggregate fields of "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_To_Be_Scraped_Append_Input = {
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "shop_item_to_be_scraped". All fields are combined with a logical 'AND'. */
export type Shop_Item_To_Be_Scraped_Bool_Exp = {
  _and?: Maybe<Array<Shop_Item_To_Be_Scraped_Bool_Exp>>
  _not?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
  _or?: Maybe<Array<Shop_Item_To_Be_Scraped_Bool_Exp>>
  availability?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image?: Maybe<String_Comparison_Exp>
  image_highres?: Maybe<String_Comparison_Exp>
  item_id?: Maybe<String_Comparison_Exp>
  parsed_pricing?: Maybe<Jsonb_Comparison_Exp>
  pricing?: Maybe<Jsonb_Comparison_Exp>
  schema_metadata?: Maybe<Jsonb_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  source?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  to_be_scraped?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Shop_Item_To_Be_Scraped_Delete_At_Path_Input = {
  parsed_pricing?: Maybe<Array<Scalars['String']>>
  pricing?: Maybe<Array<Scalars['String']>>
  schema_metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Shop_Item_To_Be_Scraped_Delete_Elem_Input = {
  parsed_pricing?: Maybe<Scalars['Int']>
  pricing?: Maybe<Scalars['Int']>
  schema_metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Shop_Item_To_Be_Scraped_Delete_Key_Input = {
  parsed_pricing?: Maybe<Scalars['String']>
  pricing?: Maybe<Scalars['String']>
  schema_metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Insert_Input = {
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Shop_Item_To_Be_Scraped_Max_Fields = {
  __typename?: 'shop_item_to_be_scraped_max_fields'
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Shop_Item_To_Be_Scraped_Min_Fields = {
  __typename?: 'shop_item_to_be_scraped_min_fields'
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Mutation_Response = {
  __typename?: 'shop_item_to_be_scraped_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Item_To_Be_Scraped>
}

/** Ordering options when selecting data from "shop_item_to_be_scraped". */
export type Shop_Item_To_Be_Scraped_Order_By = {
  availability?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image?: Maybe<Order_By>
  image_highres?: Maybe<Order_By>
  item_id?: Maybe<Order_By>
  parsed_pricing?: Maybe<Order_By>
  pricing?: Maybe<Order_By>
  schema_metadata?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  source?: Maybe<Order_By>
  title?: Maybe<Order_By>
  to_be_scraped?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Item_To_Be_Scraped_Prepend_Input = {
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "shop_item_to_be_scraped" */
export enum Shop_Item_To_Be_Scraped_Select_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageHighres = 'image_highres',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ParsedPricing = 'parsed_pricing',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  SchemaMetadata = 'schema_metadata',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeScraped = 'to_be_scraped',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Set_Input = {
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "shop_item_to_be_scraped" */
export type Shop_Item_To_Be_Scraped_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Item_To_Be_Scraped_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Item_To_Be_Scraped_Stream_Cursor_Value_Input = {
  availability?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image?: Maybe<Scalars['String']>
  image_highres?: Maybe<Scalars['String']>
  item_id?: Maybe<Scalars['String']>
  parsed_pricing?: Maybe<Scalars['jsonb']>
  pricing?: Maybe<Scalars['jsonb']>
  schema_metadata?: Maybe<Scalars['jsonb']>
  shop_key?: Maybe<Scalars['String']>
  source?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  to_be_scraped?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
}

export type Shop_Item_To_Be_Scraped_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_To_Be_Scraped_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_To_Be_Scraped_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_To_Be_Scraped_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_To_Be_Scraped_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_To_Be_Scraped_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_To_Be_Scraped_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_To_Be_Scraped_Bool_Exp
}

/** update columns of table "shop_item" */
export enum Shop_Item_Update_Column {
  /** column name */
  Availability = 'availability',
  /** column name */
  Categories = 'categories',
  /** column name */
  ColorThiefProcessed = 'color_thief_processed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnrichmentStatus = 'enrichment_status',
  /** column name */
  Hashtags = 'hashtags',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  ImageColorPalette = 'image_color_palette',
  /** column name */
  ImageHighres = 'image_highres',
  /** column name */
  ImageMetadata = 'image_metadata',
  /** column name */
  ImagePrimaryB = 'image_primary_b',
  /** column name */
  ImagePrimaryG = 'image_primary_g',
  /** column name */
  ImagePrimaryR = 'image_primary_r',
  /** column name */
  IsInappropriate = 'is_inappropriate',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OnboardingCategories = 'onboarding_categories',
  /** column name */
  OpenAiAttempts = 'open_ai_attempts',
  /** column name */
  ParsedPricing = 'parsed_pricing',
  /** column name */
  PdpViews = 'pdp_views',
  /** column name */
  Pricing = 'pricing',
  /** column name */
  SchemaMetadata = 'schema_metadata',
  /** column name */
  SerapiMetadata = 'serapi_metadata',
  /** column name */
  SerpapiMetadata = 'serpapi_metadata',
  /** column name */
  SharesheetHtml = 'sharesheet_html',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  SimilarProductsHistoryId = 'similar_products_history_id',
  /** column name */
  Source = 'source',
  /** column name */
  Title = 'title',
  /** column name */
  ToBeScraped = 'to_be_scraped',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

export type Shop_Item_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Item_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Item_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Item_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Item_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Item_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Item_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Item_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Item_Var_Pop_Fields = {
  __typename?: 'shop_item_var_pop_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by var_pop() on columns of table "shop_item" */
export type Shop_Item_Var_Pop_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Shop_Item_Var_Samp_Fields = {
  __typename?: 'shop_item_var_samp_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by var_samp() on columns of table "shop_item" */
export type Shop_Item_Var_Samp_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Shop_Item_Variance_Fields = {
  __typename?: 'shop_item_variance_fields'
  image_primary_b?: Maybe<Scalars['Float']>
  image_primary_g?: Maybe<Scalars['Float']>
  image_primary_r?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_number_of_collections" */
  number_of_collections?: Maybe<Scalars['Int']>
  open_ai_attempts?: Maybe<Scalars['Float']>
  pdp_views?: Maybe<Scalars['Float']>
  /** A computed field, executes function "shop_item_points" */
  points?: Maybe<Scalars['numeric']>
}

/** order by variance() on columns of table "shop_item" */
export type Shop_Item_Variance_Order_By = {
  image_primary_b?: Maybe<Order_By>
  image_primary_g?: Maybe<Order_By>
  image_primary_r?: Maybe<Order_By>
  open_ai_attempts?: Maybe<Order_By>
  pdp_views?: Maybe<Order_By>
}

/** aggregate max on columns */
export type Shop_Max_Fields = {
  __typename?: 'shop_max_fields'
  banner_image?: Maybe<Scalars['String']>
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_display_name" */
  display_name?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Shop_Min_Fields = {
  __typename?: 'shop_min_fields'
  banner_image?: Maybe<Scalars['String']>
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  /** A computed field, executes function "shop_display_name" */
  display_name?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "shop" */
export type Shop_Mutation_Response = {
  __typename?: 'shop_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop>
}

/** input type for inserting object relation for remote table "shop" */
export type Shop_Obj_Rel_Insert_Input = {
  data: Shop_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_On_Conflict>
}

/** on_conflict condition type for table "shop" */
export type Shop_On_Conflict = {
  constraint: Shop_Constraint
  update_columns?: Array<Shop_Update_Column>
  where?: Maybe<Shop_Bool_Exp>
}

/** Ordering options when selecting data from "shop". */
export type Shop_Order_By = {
  affiliate?: Maybe<Order_By>
  associated_affiliate?: Maybe<Affiliate_Order_By>
  banner_image?: Maybe<Order_By>
  cart_items_aggregate?: Maybe<Cart_Item_Aggregate_Order_By>
  cart_items_count?: Maybe<Order_By>
  cart_path?: Maybe<Order_By>
  carts_aggregate?: Maybe<Cart_Aggregate_Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  display_name?: Maybe<Order_By>
  domains?: Maybe<Order_By>
  editorial_shop_logo?: Maybe<Order_By>
  favicon?: Maybe<Order_By>
  featured?: Maybe<Order_By>
  featured_shop?: Maybe<Featured_Shop_Order_By>
  has_deals?: Maybe<Order_By>
  has_pdp?: Maybe<Order_By>
  id?: Maybe<Order_By>
  items_aggregate?: Maybe<Shop_Item_Aggregate_Order_By>
  key?: Maybe<Order_By>
  keywords?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  name?: Maybe<Order_By>
  name_override?: Maybe<Order_By>
  parent_shop?: Maybe<Shop_Order_By>
  parent_shop_key?: Maybe<Order_By>
  parsed_take_rate?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  points?: Maybe<Order_By>
  potential_spam?: Maybe<Order_By>
  search_tokens?: Maybe<Order_By>
  searchable_slug?: Maybe<Order_By>
  shop_affiliates_aggregate?: Maybe<Shop_Affiliate_Aggregate_Order_By>
  shop_categorizations_aggregate?: Maybe<Shop_Categorization_Aggregate_Order_By>
  shop_faqs?: Maybe<Order_By>
  shop_items_count?: Maybe<Order_By>
  shop_popularity?: Maybe<Shop_Popularity_Order_By>
  show_price_drops?: Maybe<Order_By>
  slug?: Maybe<Order_By>
  type?: Maybe<Order_By>
  unknown?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: shop */
export type Shop_Pk_Columns_Input = {
  key: Scalars['String']
}

/** columns and relationships of "shop_popularity" */
export type Shop_Popularity = {
  __typename?: 'shop_popularity'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop?: Maybe<Shop>
}

/** aggregated selection of "shop_popularity" */
export type Shop_Popularity_Aggregate = {
  __typename?: 'shop_popularity_aggregate'
  aggregate?: Maybe<Shop_Popularity_Aggregate_Fields>
  nodes: Array<Shop_Popularity>
}

/** aggregate fields of "shop_popularity" */
export type Shop_Popularity_Aggregate_Fields = {
  __typename?: 'shop_popularity_aggregate_fields'
  avg?: Maybe<Shop_Popularity_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Popularity_Max_Fields>
  min?: Maybe<Shop_Popularity_Min_Fields>
  stddev?: Maybe<Shop_Popularity_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Popularity_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Popularity_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Popularity_Sum_Fields>
  var_pop?: Maybe<Shop_Popularity_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Popularity_Var_Samp_Fields>
  variance?: Maybe<Shop_Popularity_Variance_Fields>
}

/** aggregate fields of "shop_popularity" */
export type Shop_Popularity_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Popularity_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Popularity_Avg_Fields = {
  __typename?: 'shop_popularity_avg_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_popularity". All fields are combined with a logical 'AND'. */
export type Shop_Popularity_Bool_Exp = {
  _and?: Maybe<Array<Shop_Popularity_Bool_Exp>>
  _not?: Maybe<Shop_Popularity_Bool_Exp>
  _or?: Maybe<Array<Shop_Popularity_Bool_Exp>>
  key?: Maybe<String_Comparison_Exp>
  popularity_rank?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
}

/** unique or primary key constraints on table "shop_popularity" */
export enum Shop_Popularity_Constraint {
  /** unique or primary key constraint on columns "key" */
  ShopPopularityKeyIdx = 'shop_popularity_key_idx',
}

/** input type for inserting data into table "shop_popularity" */
export type Shop_Popularity_Insert_Input = {
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Shop_Popularity_Max_Fields = {
  __typename?: 'shop_popularity_max_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate min on columns */
export type Shop_Popularity_Min_Fields = {
  __typename?: 'shop_popularity_min_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** input type for inserting object relation for remote table "shop_popularity" */
export type Shop_Popularity_Obj_Rel_Insert_Input = {
  data: Shop_Popularity_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Shop_Popularity_On_Conflict>
}

/** on_conflict condition type for table "shop_popularity" */
export type Shop_Popularity_On_Conflict = {
  constraint: Shop_Popularity_Constraint
  update_columns?: Array<Shop_Popularity_Update_Column>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

/** Ordering options when selecting data from "shop_popularity". */
export type Shop_Popularity_Order_By = {
  key?: Maybe<Order_By>
  popularity_rank?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
}

/** select columns of table "shop_popularity" */
export enum Shop_Popularity_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
}

/** aggregate stddev on columns */
export type Shop_Popularity_Stddev_Fields = {
  __typename?: 'shop_popularity_stddev_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Popularity_Stddev_Pop_Fields = {
  __typename?: 'shop_popularity_stddev_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Popularity_Stddev_Samp_Fields = {
  __typename?: 'shop_popularity_stddev_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_popularity" */
export type Shop_Popularity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Popularity_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Popularity_Stream_Cursor_Value_Input = {
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** aggregate sum on columns */
export type Shop_Popularity_Sum_Fields = {
  __typename?: 'shop_popularity_sum_fields'
  popularity_rank?: Maybe<Scalars['numeric']>
}

/** update columns of table "shop_popularity" */
export enum Shop_Popularity_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
}

/** aggregate var_pop on columns */
export type Shop_Popularity_Var_Pop_Fields = {
  __typename?: 'shop_popularity_var_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Popularity_Var_Samp_Fields = {
  __typename?: 'shop_popularity_var_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Popularity_Variance_Fields = {
  __typename?: 'shop_popularity_variance_fields'
  popularity_rank?: Maybe<Scalars['Float']>
}

/** columns and relationships of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points = {
  __typename?: 'shop_popularity_with_points'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  /** An object relationship */
  shop?: Maybe<Shop>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregated selection of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Aggregate = {
  __typename?: 'shop_popularity_with_points_aggregate'
  aggregate?: Maybe<Shop_Popularity_With_Points_Aggregate_Fields>
  nodes: Array<Shop_Popularity_With_Points>
}

/** aggregate fields of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Aggregate_Fields = {
  __typename?: 'shop_popularity_with_points_aggregate_fields'
  avg?: Maybe<Shop_Popularity_With_Points_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Popularity_With_Points_Max_Fields>
  min?: Maybe<Shop_Popularity_With_Points_Min_Fields>
  stddev?: Maybe<Shop_Popularity_With_Points_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Popularity_With_Points_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Popularity_With_Points_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Popularity_With_Points_Sum_Fields>
  var_pop?: Maybe<Shop_Popularity_With_Points_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Popularity_With_Points_Var_Samp_Fields>
  variance?: Maybe<Shop_Popularity_With_Points_Variance_Fields>
}

/** aggregate fields of "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Popularity_With_Points_Avg_Fields = {
  __typename?: 'shop_popularity_with_points_avg_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_popularity_with_points". All fields are combined with a logical 'AND'. */
export type Shop_Popularity_With_Points_Bool_Exp = {
  _and?: Maybe<Array<Shop_Popularity_With_Points_Bool_Exp>>
  _not?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
  _or?: Maybe<Array<Shop_Popularity_With_Points_Bool_Exp>>
  key?: Maybe<String_Comparison_Exp>
  popularity_rank?: Maybe<Numeric_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_points?: Maybe<Numeric_Comparison_Exp>
}

/** aggregate max on columns */
export type Shop_Popularity_With_Points_Max_Fields = {
  __typename?: 'shop_popularity_with_points_max_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregate min on columns */
export type Shop_Popularity_With_Points_Min_Fields = {
  __typename?: 'shop_popularity_with_points_min_fields'
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** Ordering options when selecting data from "shop_popularity_with_points". */
export type Shop_Popularity_With_Points_Order_By = {
  key?: Maybe<Order_By>
  popularity_rank?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_points?: Maybe<Order_By>
}

/** select columns of table "shop_popularity_with_points" */
export enum Shop_Popularity_With_Points_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  PopularityRank = 'popularity_rank',
  /** column name */
  ShopPoints = 'shop_points',
}

/** aggregate stddev on columns */
export type Shop_Popularity_With_Points_Stddev_Fields = {
  __typename?: 'shop_popularity_with_points_stddev_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Popularity_With_Points_Stddev_Pop_Fields = {
  __typename?: 'shop_popularity_with_points_stddev_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Popularity_With_Points_Stddev_Samp_Fields = {
  __typename?: 'shop_popularity_with_points_stddev_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_popularity_with_points" */
export type Shop_Popularity_With_Points_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Popularity_With_Points_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Popularity_With_Points_Stream_Cursor_Value_Input = {
  key?: Maybe<Scalars['String']>
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregate sum on columns */
export type Shop_Popularity_With_Points_Sum_Fields = {
  __typename?: 'shop_popularity_with_points_sum_fields'
  popularity_rank?: Maybe<Scalars['numeric']>
  shop_points?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type Shop_Popularity_With_Points_Var_Pop_Fields = {
  __typename?: 'shop_popularity_with_points_var_pop_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Popularity_With_Points_Var_Samp_Fields = {
  __typename?: 'shop_popularity_with_points_var_samp_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Popularity_With_Points_Variance_Fields = {
  __typename?: 'shop_popularity_with_points_variance_fields'
  popularity_rank?: Maybe<Scalars['Float']>
  shop_points?: Maybe<Scalars['Float']>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Shop_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
  shop_faqs?: Maybe<Scalars['jsonb']>
}

/** Shop rating and review data sourced from the web */
export type Shop_Rating = {
  __typename?: 'shop_rating'
  business_unit_id?: Maybe<Scalars['String']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews: Scalars['numeric']
  shop_key: Scalars['String']
  stars: Scalars['numeric']
  trust_score: Scalars['numeric']
  updated_at: Scalars['timestamptz']
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregated selection of "shop_rating" */
export type Shop_Rating_Aggregate = {
  __typename?: 'shop_rating_aggregate'
  aggregate?: Maybe<Shop_Rating_Aggregate_Fields>
  nodes: Array<Shop_Rating>
}

/** aggregate fields of "shop_rating" */
export type Shop_Rating_Aggregate_Fields = {
  __typename?: 'shop_rating_aggregate_fields'
  avg?: Maybe<Shop_Rating_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Shop_Rating_Max_Fields>
  min?: Maybe<Shop_Rating_Min_Fields>
  stddev?: Maybe<Shop_Rating_Stddev_Fields>
  stddev_pop?: Maybe<Shop_Rating_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Shop_Rating_Stddev_Samp_Fields>
  sum?: Maybe<Shop_Rating_Sum_Fields>
  var_pop?: Maybe<Shop_Rating_Var_Pop_Fields>
  var_samp?: Maybe<Shop_Rating_Var_Samp_Fields>
  variance?: Maybe<Shop_Rating_Variance_Fields>
}

/** aggregate fields of "shop_rating" */
export type Shop_Rating_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shop_Rating_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Shop_Rating_Avg_Fields = {
  __typename?: 'shop_rating_avg_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "shop_rating". All fields are combined with a logical 'AND'. */
export type Shop_Rating_Bool_Exp = {
  _and?: Maybe<Array<Shop_Rating_Bool_Exp>>
  _not?: Maybe<Shop_Rating_Bool_Exp>
  _or?: Maybe<Array<Shop_Rating_Bool_Exp>>
  business_unit_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  is_claimed?: Maybe<Boolean_Comparison_Exp>
  num_reviews?: Maybe<Numeric_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  stars?: Maybe<Numeric_Comparison_Exp>
  trust_score?: Maybe<Numeric_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  verified_business?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "shop_rating" */
export enum Shop_Rating_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShopRatingPkey = 'shop_rating_pkey',
  /** unique or primary key constraint on columns "shop_key" */
  ShopRatingShopKeyKey = 'shop_rating_shop_key_key',
}

/** input type for incrementing numeric columns in table "shop_rating" */
export type Shop_Rating_Inc_Input = {
  num_reviews?: Maybe<Scalars['numeric']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "shop_rating" */
export type Shop_Rating_Insert_Input = {
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregate max on columns */
export type Shop_Rating_Max_Fields = {
  __typename?: 'shop_rating_max_fields'
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Shop_Rating_Min_Fields = {
  __typename?: 'shop_rating_min_fields'
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "shop_rating" */
export type Shop_Rating_Mutation_Response = {
  __typename?: 'shop_rating_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Shop_Rating>
}

/** on_conflict condition type for table "shop_rating" */
export type Shop_Rating_On_Conflict = {
  constraint: Shop_Rating_Constraint
  update_columns?: Array<Shop_Rating_Update_Column>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

/** Ordering options when selecting data from "shop_rating". */
export type Shop_Rating_Order_By = {
  business_unit_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  is_claimed?: Maybe<Order_By>
  num_reviews?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  stars?: Maybe<Order_By>
  trust_score?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  verified_business?: Maybe<Order_By>
}

/** primary key columns input for table: shop_rating */
export type Shop_Rating_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "shop_rating" */
export enum Shop_Rating_Select_Column {
  /** column name */
  BusinessUnitId = 'business_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsClaimed = 'is_claimed',
  /** column name */
  NumReviews = 'num_reviews',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Stars = 'stars',
  /** column name */
  TrustScore = 'trust_score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedBusiness = 'verified_business',
}

/** input type for updating data in table "shop_rating" */
export type Shop_Rating_Set_Input = {
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type Shop_Rating_Stddev_Fields = {
  __typename?: 'shop_rating_stddev_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Shop_Rating_Stddev_Pop_Fields = {
  __typename?: 'shop_rating_stddev_pop_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Shop_Rating_Stddev_Samp_Fields = {
  __typename?: 'shop_rating_stddev_samp_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "shop_rating" */
export type Shop_Rating_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Rating_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Rating_Stream_Cursor_Value_Input = {
  business_unit_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  is_claimed?: Maybe<Scalars['Boolean']>
  num_reviews?: Maybe<Scalars['numeric']>
  shop_key?: Maybe<Scalars['String']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  verified_business?: Maybe<Scalars['Boolean']>
}

/** aggregate sum on columns */
export type Shop_Rating_Sum_Fields = {
  __typename?: 'shop_rating_sum_fields'
  num_reviews?: Maybe<Scalars['numeric']>
  stars?: Maybe<Scalars['numeric']>
  trust_score?: Maybe<Scalars['numeric']>
}

/** update columns of table "shop_rating" */
export enum Shop_Rating_Update_Column {
  /** column name */
  BusinessUnitId = 'business_unit_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsClaimed = 'is_claimed',
  /** column name */
  NumReviews = 'num_reviews',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  Stars = 'stars',
  /** column name */
  TrustScore = 'trust_score',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerifiedBusiness = 'verified_business',
}

export type Shop_Rating_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shop_Rating_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Rating_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Rating_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Rating_Var_Pop_Fields = {
  __typename?: 'shop_rating_var_pop_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Shop_Rating_Var_Samp_Fields = {
  __typename?: 'shop_rating_var_samp_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Shop_Rating_Variance_Fields = {
  __typename?: 'shop_rating_variance_fields'
  num_reviews?: Maybe<Scalars['Float']>
  stars?: Maybe<Scalars['Float']>
  trust_score?: Maybe<Scalars['Float']>
}

/** select columns of table "shop" */
export enum Shop_Select_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  BannerImage = 'banner_image',
  /** column name */
  CartPath = 'cart_path',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Domains = 'domains',
  /** column name */
  EditorialShopLogo = 'editorial_shop_logo',
  /** column name */
  Favicon = 'favicon',
  /** column name */
  Featured = 'featured',
  /** column name */
  HasDeals = 'has_deals',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NameOverride = 'name_override',
  /** column name */
  ParentShopKey = 'parent_shop_key',
  /** column name */
  ParsedTakeRate = 'parsed_take_rate',
  /** column name */
  Platform = 'platform',
  /** column name */
  PotentialSpam = 'potential_spam',
  /** column name */
  SearchTokens = 'search_tokens',
  /** column name */
  SearchableSlug = 'searchable_slug',
  /** column name */
  ShopFaqs = 'shop_faqs',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  Unknown = 'unknown',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shop" */
export type Shop_Set_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  banner_image?: Maybe<Scalars['String']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  has_deals?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  shop_faqs?: Maybe<Scalars['jsonb']>
  slug?: Maybe<Scalars['name']>
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Shop_Stddev_Fields = {
  __typename?: 'shop_stddev_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate stddev_pop on columns */
export type Shop_Stddev_Pop_Fields = {
  __typename?: 'shop_stddev_pop_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate stddev_samp on columns */
export type Shop_Stddev_Samp_Fields = {
  __typename?: 'shop_stddev_samp_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** Streaming cursor of the table "shop" */
export type Shop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shop_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Shop_Stream_Cursor_Value_Input = {
  affiliate?: Maybe<Affiliate_Enum>
  banner_image?: Maybe<Scalars['String']>
  cart_path?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  editorial_shop_logo?: Maybe<Scalars['String']>
  favicon?: Maybe<Scalars['String']>
  featured?: Maybe<Scalars['Boolean']>
  has_deals?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  key?: Maybe<Scalars['String']>
  keywords?: Maybe<Array<Scalars['String']>>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['name']>
  name_override?: Maybe<Scalars['String']>
  parent_shop_key?: Maybe<Scalars['String']>
  parsed_take_rate?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  potential_spam?: Maybe<Scalars['Boolean']>
  search_tokens?: Maybe<Array<Scalars['String']>>
  searchable_slug?: Maybe<Scalars['String']>
  shop_faqs?: Maybe<Scalars['jsonb']>
  slug?: Maybe<Scalars['name']>
  type?: Maybe<Scalars['name']>
  unknown?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Shop_Sum_Fields = {
  __typename?: 'shop_sum_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** update columns of table "shop" */
export enum Shop_Update_Column {
  /** column name */
  Affiliate = 'affiliate',
  /** column name */
  BannerImage = 'banner_image',
  /** column name */
  CartPath = 'cart_path',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Domains = 'domains',
  /** column name */
  EditorialShopLogo = 'editorial_shop_logo',
  /** column name */
  Favicon = 'favicon',
  /** column name */
  Featured = 'featured',
  /** column name */
  HasDeals = 'has_deals',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Keywords = 'keywords',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  NameOverride = 'name_override',
  /** column name */
  ParentShopKey = 'parent_shop_key',
  /** column name */
  Platform = 'platform',
  /** column name */
  PotentialSpam = 'potential_spam',
  /** column name */
  SearchTokens = 'search_tokens',
  /** column name */
  ShopFaqs = 'shop_faqs',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  Unknown = 'unknown',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Shop_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Shop_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Shop_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Shop_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Shop_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Shop_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shop_Set_Input>
  /** filter the rows which have to be updated */
  where: Shop_Bool_Exp
}

/** aggregate var_pop on columns */
export type Shop_Var_Pop_Fields = {
  __typename?: 'shop_var_pop_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate var_samp on columns */
export type Shop_Var_Samp_Fields = {
  __typename?: 'shop_var_samp_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** aggregate variance on columns */
export type Shop_Variance_Fields = {
  __typename?: 'shop_variance_fields'
  /** Count of cart items on a particular shop */
  cart_items_count?: Maybe<Scalars['numeric']>
  /** This function returns the number of points for a shop */
  points?: Maybe<Scalars['numeric']>
  /** Count of total shop items on a particular shop */
  shop_items_count?: Maybe<Scalars['numeric']>
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_History = {
  __typename?: 'similar_products_history'
  /** An object relationship */
  cart_item?: Maybe<Cart_Item>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  image_url: Scalars['String']
  /** An object relationship */
  shop_item?: Maybe<Shop_Item>
  shop_item_id?: Maybe<Scalars['uuid']>
  /** An array relationship */
  shop_items: Array<Shop_Item>
  /** An aggregate relationship */
  shop_items_aggregate: Shop_Item_Aggregate
  similar_products: Scalars['jsonb']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id: Scalars['String']
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_HistoryShop_ItemsArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_HistoryShop_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

/** This table contains the history of the similar_producst api call */
export type Similar_Products_HistorySimilar_ProductsArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "similar_products_history" */
export type Similar_Products_History_Aggregate = {
  __typename?: 'similar_products_history_aggregate'
  aggregate?: Maybe<Similar_Products_History_Aggregate_Fields>
  nodes: Array<Similar_Products_History>
}

export type Similar_Products_History_Aggregate_Bool_Exp = {
  count?: Maybe<Similar_Products_History_Aggregate_Bool_Exp_Count>
}

export type Similar_Products_History_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Similar_Products_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Similar_Products_History_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "similar_products_history" */
export type Similar_Products_History_Aggregate_Fields = {
  __typename?: 'similar_products_history_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Similar_Products_History_Max_Fields>
  min?: Maybe<Similar_Products_History_Min_Fields>
}

/** aggregate fields of "similar_products_history" */
export type Similar_Products_History_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Similar_Products_History_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "similar_products_history" */
export type Similar_Products_History_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Similar_Products_History_Max_Order_By>
  min?: Maybe<Similar_Products_History_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Similar_Products_History_Append_Input = {
  similar_products?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "similar_products_history" */
export type Similar_Products_History_Arr_Rel_Insert_Input = {
  data: Array<Similar_Products_History_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** Boolean expression to filter rows from the table "similar_products_history". All fields are combined with a logical 'AND'. */
export type Similar_Products_History_Bool_Exp = {
  _and?: Maybe<Array<Similar_Products_History_Bool_Exp>>
  _not?: Maybe<Similar_Products_History_Bool_Exp>
  _or?: Maybe<Array<Similar_Products_History_Bool_Exp>>
  cart_item?: Maybe<Cart_Item_Bool_Exp>
  cart_item_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  image_url?: Maybe<String_Comparison_Exp>
  shop_item?: Maybe<Shop_Item_Bool_Exp>
  shop_item_id?: Maybe<Uuid_Comparison_Exp>
  shop_items?: Maybe<Shop_Item_Bool_Exp>
  shop_items_aggregate?: Maybe<Shop_Item_Aggregate_Bool_Exp>
  similar_products?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "similar_products_history" */
export enum Similar_Products_History_Constraint {
  /** unique or primary key constraint on columns "id" */
  SimilarProductsHistoryPkey = 'similar_products_history_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Similar_Products_History_Delete_At_Path_Input = {
  similar_products?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Similar_Products_History_Delete_Elem_Input = {
  similar_products?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Similar_Products_History_Delete_Key_Input = {
  similar_products?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "similar_products_history" */
export type Similar_Products_History_Insert_Input = {
  cart_item?: Maybe<Cart_Item_Obj_Rel_Insert_Input>
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item?: Maybe<Shop_Item_Obj_Rel_Insert_Input>
  shop_item_id?: Maybe<Scalars['uuid']>
  shop_items?: Maybe<Shop_Item_Arr_Rel_Insert_Input>
  similar_products?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Similar_Products_History_Max_Fields = {
  __typename?: 'similar_products_history_max_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "similar_products_history" */
export type Similar_Products_History_Max_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_url?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Similar_Products_History_Min_Fields = {
  __typename?: 'similar_products_history_min_fields'
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "similar_products_history" */
export type Similar_Products_History_Min_Order_By = {
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_url?: Maybe<Order_By>
  shop_item_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "similar_products_history" */
export type Similar_Products_History_Mutation_Response = {
  __typename?: 'similar_products_history_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Similar_Products_History>
}

/** input type for inserting object relation for remote table "similar_products_history" */
export type Similar_Products_History_Obj_Rel_Insert_Input = {
  data: Similar_Products_History_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Similar_Products_History_On_Conflict>
}

/** on_conflict condition type for table "similar_products_history" */
export type Similar_Products_History_On_Conflict = {
  constraint: Similar_Products_History_Constraint
  update_columns?: Array<Similar_Products_History_Update_Column>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** Ordering options when selecting data from "similar_products_history". */
export type Similar_Products_History_Order_By = {
  cart_item?: Maybe<Cart_Item_Order_By>
  cart_item_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  image_url?: Maybe<Order_By>
  shop_item?: Maybe<Shop_Item_Order_By>
  shop_item_id?: Maybe<Order_By>
  shop_items_aggregate?: Maybe<Shop_Item_Aggregate_Order_By>
  similar_products?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: similar_products_history */
export type Similar_Products_History_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Similar_Products_History_Prepend_Input = {
  similar_products?: Maybe<Scalars['jsonb']>
}

/** select columns of table "similar_products_history" */
export enum Similar_Products_History_Select_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  SimilarProducts = 'similar_products',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "similar_products_history" */
export type Similar_Products_History_Set_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  similar_products?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "similar_products_history" */
export type Similar_Products_History_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Similar_Products_History_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Similar_Products_History_Stream_Cursor_Value_Input = {
  cart_item_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  image_url?: Maybe<Scalars['String']>
  shop_item_id?: Maybe<Scalars['uuid']>
  similar_products?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

export type Similar_Products_History_Unique_Args = {
  limit?: Maybe<Scalars['Int']>
  user_id_arg?: Maybe<Scalars['String']>
}

/** update columns of table "similar_products_history" */
export enum Similar_Products_History_Update_Column {
  /** column name */
  CartItemId = 'cart_item_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  ShopItemId = 'shop_item_id',
  /** column name */
  SimilarProducts = 'similar_products',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Similar_Products_History_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Similar_Products_History_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Similar_Products_History_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Similar_Products_History_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Similar_Products_History_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Similar_Products_History_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Similar_Products_History_Set_Input>
  /** filter the rows which have to be updated */
  where: Similar_Products_History_Bool_Exp
}

export type Single_Unread_Notification_Banner_Item_For_User_Args = {
  notifiable_type?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars['smallint']>>
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars['smallint']>>
  _eq?: Maybe<Array<Scalars['smallint']>>
  _gt?: Maybe<Array<Scalars['smallint']>>
  _gte?: Maybe<Array<Scalars['smallint']>>
  _in?: Maybe<Array<Array<Scalars['smallint']>>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Array<Scalars['smallint']>>
  _lte?: Maybe<Array<Scalars['smallint']>>
  _neq?: Maybe<Array<Scalars['smallint']>>
  _nin?: Maybe<Array<Array<Scalars['smallint']>>>
}

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>
  _gt?: Maybe<Scalars['smallint']>
  _gte?: Maybe<Scalars['smallint']>
  _in?: Maybe<Array<Scalars['smallint']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['smallint']>
  _lte?: Maybe<Scalars['smallint']>
  _neq?: Maybe<Scalars['smallint']>
  _nin?: Maybe<Array<Scalars['smallint']>>
}

export type Steal_Their_Looks_Random_Args = {
  seed?: Maybe<Scalars['seed_float']>
}

export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "account_deletion_reason" */
  account_deletion_reason: Array<Account_Deletion_Reason>
  /** fetch aggregated fields from the table: "account_deletion_reason" */
  account_deletion_reason_aggregate: Account_Deletion_Reason_Aggregate
  /** fetch data from the table: "account_deletion_reason" using primary key columns */
  account_deletion_reason_by_pk?: Maybe<Account_Deletion_Reason>
  /** fetch data from the table in a streaming manner: "account_deletion_reason" */
  account_deletion_reason_stream: Array<Account_Deletion_Reason>
  /** fetch data from the table: "affiliate" */
  affiliate: Array<Affiliate>
  /** fetch data from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id: Array<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate_advertiser_id" */
  affiliate_advertiser_id_aggregate: Affiliate_Advertiser_Id_Aggregate
  /** fetch data from the table: "affiliate_advertiser_id" using primary key columns */
  affiliate_advertiser_id_by_pk?: Maybe<Affiliate_Advertiser_Id>
  /** fetch data from the table in a streaming manner: "affiliate_advertiser_id" */
  affiliate_advertiser_id_stream: Array<Affiliate_Advertiser_Id>
  /** fetch aggregated fields from the table: "affiliate" */
  affiliate_aggregate: Affiliate_Aggregate
  /** fetch data from the table: "affiliate" using primary key columns */
  affiliate_by_pk?: Maybe<Affiliate>
  /** fetch data from the table: "affiliate_commission_summary" */
  affiliate_commission_summary: Array<Affiliate_Commission_Summary>
  /** fetch aggregated fields from the table: "affiliate_commission_summary" */
  affiliate_commission_summary_aggregate: Affiliate_Commission_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "affiliate_commission_summary" */
  affiliate_commission_summary_stream: Array<Affiliate_Commission_Summary>
  /** fetch data from the table: "affiliate_meta" */
  affiliate_meta: Array<Affiliate_Meta>
  /** fetch aggregated fields from the table: "affiliate_meta" */
  affiliate_meta_aggregate: Affiliate_Meta_Aggregate
  /** fetch data from the table: "affiliate_meta" using primary key columns */
  affiliate_meta_by_pk?: Maybe<Affiliate_Meta>
  /** fetch data from the table in a streaming manner: "affiliate_meta" */
  affiliate_meta_stream: Array<Affiliate_Meta>
  /** fetch data from the table in a streaming manner: "affiliate" */
  affiliate_stream: Array<Affiliate>
  /** fetch data from the table: "affiliate_transaction" */
  affiliate_transaction: Array<Affiliate_Transaction>
  /** fetch aggregated fields from the table: "affiliate_transaction" */
  affiliate_transaction_aggregate: Affiliate_Transaction_Aggregate
  /** fetch data from the table: "affiliate_transaction" using primary key columns */
  affiliate_transaction_by_pk?: Maybe<Affiliate_Transaction>
  /** fetch data from the table in a streaming manner: "affiliate_transaction" */
  affiliate_transaction_stream: Array<Affiliate_Transaction>
  /** execute function "all_shops_for_shop_page" which returns "shop" */
  all_shops_for_shop_page: Array<Shop>
  /** execute function "all_shops_for_shop_page" and query aggregates on result of table type "shop" */
  all_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "amazon_categories" */
  amazon_categories: Array<Amazon_Categories>
  /** fetch aggregated fields from the table: "amazon_categories" */
  amazon_categories_aggregate: Amazon_Categories_Aggregate
  /** fetch data from the table in a streaming manner: "amazon_categories" */
  amazon_categories_stream: Array<Amazon_Categories>
  /** fetch data from the table: "billing_mode" */
  billing_mode: Array<Billing_Mode>
  /** fetch aggregated fields from the table: "billing_mode" */
  billing_mode_aggregate: Billing_Mode_Aggregate
  /** fetch data from the table: "billing_mode" using primary key columns */
  billing_mode_by_pk?: Maybe<Billing_Mode>
  /** fetch data from the table in a streaming manner: "billing_mode" */
  billing_mode_stream: Array<Billing_Mode>
  /** fetch data from the table: "billing_platform_connected_account" */
  billing_platform_connected_account: Array<Billing_Platform_Connected_Account>
  /** fetch aggregated fields from the table: "billing_platform_connected_account" */
  billing_platform_connected_account_aggregate: Billing_Platform_Connected_Account_Aggregate
  /** fetch data from the table: "billing_platform_connected_account" using primary key columns */
  billing_platform_connected_account_by_pk?: Maybe<Billing_Platform_Connected_Account>
  /** fetch data from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status: Array<Billing_Platform_Connected_Account_Status>
  /** fetch aggregated fields from the table: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status_aggregate: Billing_Platform_Connected_Account_Status_Aggregate
  /** fetch data from the table: "billing_platform_connected_account_status" using primary key columns */
  billing_platform_connected_account_status_by_pk?: Maybe<Billing_Platform_Connected_Account_Status>
  /** fetch data from the table in a streaming manner: "billing_platform_connected_account_status" */
  billing_platform_connected_account_status_stream: Array<Billing_Platform_Connected_Account_Status>
  /** fetch data from the table in a streaming manner: "billing_platform_connected_account" */
  billing_platform_connected_account_stream: Array<Billing_Platform_Connected_Account>
  /** fetch data from the table: "billing_platform_customer" */
  billing_platform_customer: Array<Billing_Platform_Customer>
  /** fetch aggregated fields from the table: "billing_platform_customer" */
  billing_platform_customer_aggregate: Billing_Platform_Customer_Aggregate
  /** fetch data from the table: "billing_platform_customer" using primary key columns */
  billing_platform_customer_by_pk?: Maybe<Billing_Platform_Customer>
  /** fetch data from the table in a streaming manner: "billing_platform_customer" */
  billing_platform_customer_stream: Array<Billing_Platform_Customer>
  /** fetch data from the table: "cart" */
  cart: Array<Cart>
  /** fetch aggregated fields from the table: "cart" */
  cart_aggregate: Cart_Aggregate
  /** fetch data from the table: "cart" using primary key columns */
  cart_by_pk?: Maybe<Cart>
  /** fetch data from the table: "cart_item" */
  cart_item: Array<Cart_Item>
  /** fetch aggregated fields from the table: "cart_item" */
  cart_item_aggregate: Cart_Item_Aggregate
  /** fetch data from the table: "cart_item" using primary key columns */
  cart_item_by_pk?: Maybe<Cart_Item>
  /** fetch data from the table: "cart_item_like" */
  cart_item_like: Array<Cart_Item_Like>
  /** fetch aggregated fields from the table: "cart_item_like" */
  cart_item_like_aggregate: Cart_Item_Like_Aggregate
  /** fetch data from the table: "cart_item_like" using primary key columns */
  cart_item_like_by_pk?: Maybe<Cart_Item_Like>
  /** fetch data from the table in a streaming manner: "cart_item_like" */
  cart_item_like_stream: Array<Cart_Item_Like>
  /** fetch data from the table: "cart_item_note" */
  cart_item_note: Array<Cart_Item_Note>
  /** fetch aggregated fields from the table: "cart_item_note" */
  cart_item_note_aggregate: Cart_Item_Note_Aggregate
  /** fetch data from the table: "cart_item_note" using primary key columns */
  cart_item_note_by_pk?: Maybe<Cart_Item_Note>
  /** fetch data from the table in a streaming manner: "cart_item_note" */
  cart_item_note_stream: Array<Cart_Item_Note>
  /** fetch data from the table in a streaming manner: "cart_item" */
  cart_item_stream: Array<Cart_Item>
  /** fetch data from the table in a streaming manner: "cart" */
  cart_stream: Array<Cart>
  /** fetch data from the table: "categories" */
  categories: Array<Categories>
  /** fetch aggregated fields from the table: "categories" */
  categories_aggregate: Categories_Aggregate
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>
  /** fetch data from the table: "category_shop_item" */
  category_shop_item: Array<Category_Shop_Item>
  /** fetch aggregated fields from the table: "category_shop_item" */
  category_shop_item_aggregate: Category_Shop_Item_Aggregate
  /** fetch data from the table: "category_shop_item" using primary key columns */
  category_shop_item_by_pk?: Maybe<Category_Shop_Item>
  /** fetch data from the table in a streaming manner: "category_shop_item" */
  category_shop_item_stream: Array<Category_Shop_Item>
  /** fetch data from the table: "checkout" */
  checkout: Array<Checkout>
  /** fetch aggregated fields from the table: "checkout" */
  checkout_aggregate: Checkout_Aggregate
  /** fetch data from the table: "checkout" using primary key columns */
  checkout_by_pk?: Maybe<Checkout>
  /** fetch data from the table in a streaming manner: "checkout" */
  checkout_stream: Array<Checkout>
  /** fetch data from the table: "collection" */
  collection: Array<Collection>
  /** fetch aggregated fields from the table: "collection" */
  collection_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection" using primary key columns */
  collection_by_pk?: Maybe<Collection>
  /** fetch data from the table: "collection_cart_item" */
  collection_cart_item: Array<Collection_Cart_Item>
  /** fetch aggregated fields from the table: "collection_cart_item" */
  collection_cart_item_aggregate: Collection_Cart_Item_Aggregate
  /** fetch data from the table: "collection_cart_item" using primary key columns */
  collection_cart_item_by_pk?: Maybe<Collection_Cart_Item>
  /** fetch data from the table in a streaming manner: "collection_cart_item" */
  collection_cart_item_stream: Array<Collection_Cart_Item>
  /** fetch data from the table: "collection_collaborator" */
  collection_collaborator: Array<Collection_Collaborator>
  /** fetch aggregated fields from the table: "collection_collaborator" */
  collection_collaborator_aggregate: Collection_Collaborator_Aggregate
  /** fetch data from the table: "collection_collaborator" using primary key columns */
  collection_collaborator_by_pk?: Maybe<Collection_Collaborator>
  /** fetch data from the table in a streaming manner: "collection_collaborator" */
  collection_collaborator_stream: Array<Collection_Collaborator>
  /** fetch data from the table: "collection_invite" */
  collection_invite: Array<Collection_Invite>
  /** fetch aggregated fields from the table: "collection_invite" */
  collection_invite_aggregate: Collection_Invite_Aggregate
  /** fetch data from the table: "collection_invite" using primary key columns */
  collection_invite_by_pk?: Maybe<Collection_Invite>
  /** fetch data from the table: "collection_invite_status" */
  collection_invite_status: Array<Collection_Invite_Status>
  /** fetch aggregated fields from the table: "collection_invite_status" */
  collection_invite_status_aggregate: Collection_Invite_Status_Aggregate
  /** fetch data from the table: "collection_invite_status" using primary key columns */
  collection_invite_status_by_pk?: Maybe<Collection_Invite_Status>
  /** fetch data from the table in a streaming manner: "collection_invite_status" */
  collection_invite_status_stream: Array<Collection_Invite_Status>
  /** fetch data from the table in a streaming manner: "collection_invite" */
  collection_invite_stream: Array<Collection_Invite>
  /** fetch data from the table: "collection_item_claim" */
  collection_item_claim: Array<Collection_Item_Claim>
  /** fetch aggregated fields from the table: "collection_item_claim" */
  collection_item_claim_aggregate: Collection_Item_Claim_Aggregate
  /** fetch data from the table: "collection_item_claim" using primary key columns */
  collection_item_claim_by_pk?: Maybe<Collection_Item_Claim>
  /** fetch data from the table: "collection_item_claim_status" */
  collection_item_claim_status: Array<Collection_Item_Claim_Status>
  /** fetch aggregated fields from the table: "collection_item_claim_status" */
  collection_item_claim_status_aggregate: Collection_Item_Claim_Status_Aggregate
  /** fetch data from the table: "collection_item_claim_status" using primary key columns */
  collection_item_claim_status_by_pk?: Maybe<Collection_Item_Claim_Status>
  /** fetch data from the table in a streaming manner: "collection_item_claim_status" */
  collection_item_claim_status_stream: Array<Collection_Item_Claim_Status>
  /** fetch data from the table in a streaming manner: "collection_item_claim" */
  collection_item_claim_stream: Array<Collection_Item_Claim>
  /** execute function "collection_random" which returns "collection" */
  collection_random: Array<Collection>
  /** execute function "collection_random" and query aggregates on result of table type "collection" */
  collection_random_aggregate: Collection_Aggregate
  /** fetch data from the table: "collection_section" */
  collection_section: Array<Collection_Section>
  /** fetch aggregated fields from the table: "collection_section" */
  collection_section_aggregate: Collection_Section_Aggregate
  /** fetch data from the table: "collection_section" using primary key columns */
  collection_section_by_pk?: Maybe<Collection_Section>
  /** fetch data from the table: "collection_section_cart_item" */
  collection_section_cart_item: Array<Collection_Section_Cart_Item>
  /** fetch aggregated fields from the table: "collection_section_cart_item" */
  collection_section_cart_item_aggregate: Collection_Section_Cart_Item_Aggregate
  /** fetch data from the table: "collection_section_cart_item" using primary key columns */
  collection_section_cart_item_by_pk?: Maybe<Collection_Section_Cart_Item>
  /** fetch data from the table in a streaming manner: "collection_section_cart_item" */
  collection_section_cart_item_stream: Array<Collection_Section_Cart_Item>
  /** fetch data from the table in a streaming manner: "collection_section" */
  collection_section_stream: Array<Collection_Section>
  /** fetch data from the table: "collection_slug_history" */
  collection_slug_history: Array<Collection_Slug_History>
  /** fetch aggregated fields from the table: "collection_slug_history" */
  collection_slug_history_aggregate: Collection_Slug_History_Aggregate
  /** fetch data from the table: "collection_slug_history" using primary key columns */
  collection_slug_history_by_pk?: Maybe<Collection_Slug_History>
  /** fetch data from the table in a streaming manner: "collection_slug_history" */
  collection_slug_history_stream: Array<Collection_Slug_History>
  /** fetch data from the table: "collection_state" */
  collection_state: Array<Collection_State>
  /** fetch aggregated fields from the table: "collection_state" */
  collection_state_aggregate: Collection_State_Aggregate
  /** fetch data from the table: "collection_state" using primary key columns */
  collection_state_by_pk?: Maybe<Collection_State>
  /** fetch data from the table in a streaming manner: "collection_state" */
  collection_state_stream: Array<Collection_State>
  /** fetch data from the table in a streaming manner: "collection" */
  collection_stream: Array<Collection>
  /** fetch data from the table: "collection_type" */
  collection_type: Array<Collection_Type>
  /** fetch aggregated fields from the table: "collection_type" */
  collection_type_aggregate: Collection_Type_Aggregate
  /** fetch data from the table: "collection_type" using primary key columns */
  collection_type_by_pk?: Maybe<Collection_Type>
  /** fetch data from the table in a streaming manner: "collection_type" */
  collection_type_stream: Array<Collection_Type>
  /** fetch data from the table: "comment" */
  comment: Array<Comment>
  /** fetch aggregated fields from the table: "comment" */
  comment_aggregate: Comment_Aggregate
  /** fetch data from the table: "comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>
  /** fetch data from the table in a streaming manner: "comment" */
  comment_stream: Array<Comment>
  /** fetch data from the table: "commentable_item" */
  commentable_item: Array<Commentable_Item>
  /** fetch aggregated fields from the table: "commentable_item" */
  commentable_item_aggregate: Commentable_Item_Aggregate
  /** fetch data from the table: "commentable_item" using primary key columns */
  commentable_item_by_pk?: Maybe<Commentable_Item>
  /** fetch data from the table in a streaming manner: "commentable_item" */
  commentable_item_stream: Array<Commentable_Item>
  /** fetch data from the table: "commentable_item_type" */
  commentable_item_type: Array<Commentable_Item_Type>
  /** fetch aggregated fields from the table: "commentable_item_type" */
  commentable_item_type_aggregate: Commentable_Item_Type_Aggregate
  /** fetch data from the table: "commentable_item_type" using primary key columns */
  commentable_item_type_by_pk?: Maybe<Commentable_Item_Type>
  /** fetch data from the table in a streaming manner: "commentable_item_type" */
  commentable_item_type_stream: Array<Commentable_Item_Type>
  /** fetch data from the table: "communication_template" */
  communication_template: Array<Communication_Template>
  /** fetch aggregated fields from the table: "communication_template" */
  communication_template_aggregate: Communication_Template_Aggregate
  /** fetch data from the table: "communication_template" using primary key columns */
  communication_template_by_pk?: Maybe<Communication_Template>
  /** fetch data from the table: "communication_template_full_list" */
  communication_template_full_list: Array<Communication_Template_Full_List>
  /** fetch aggregated fields from the table: "communication_template_full_list" */
  communication_template_full_list_aggregate: Communication_Template_Full_List_Aggregate
  /** fetch data from the table: "communication_template_full_list" using primary key columns */
  communication_template_full_list_by_pk?: Maybe<Communication_Template_Full_List>
  /** fetch data from the table in a streaming manner: "communication_template_full_list" */
  communication_template_full_list_stream: Array<Communication_Template_Full_List>
  /** fetch data from the table in a streaming manner: "communication_template" */
  communication_template_stream: Array<Communication_Template>
  /** fetch data from the table: "contact_info_type" */
  contact_info_type: Array<Contact_Info_Type>
  /** fetch aggregated fields from the table: "contact_info_type" */
  contact_info_type_aggregate: Contact_Info_Type_Aggregate
  /** fetch data from the table: "contact_info_type" using primary key columns */
  contact_info_type_by_pk?: Maybe<Contact_Info_Type>
  /** fetch data from the table in a streaming manner: "contact_info_type" */
  contact_info_type_stream: Array<Contact_Info_Type>
  /** fetch data from the table: "contact_invite" */
  contact_invite: Array<Contact_Invite>
  /** fetch aggregated fields from the table: "contact_invite" */
  contact_invite_aggregate: Contact_Invite_Aggregate
  /** fetch data from the table: "contact_invite" using primary key columns */
  contact_invite_by_pk?: Maybe<Contact_Invite>
  /** fetch data from the table: "contact_invite_status" */
  contact_invite_status: Array<Contact_Invite_Status>
  /** fetch aggregated fields from the table: "contact_invite_status" */
  contact_invite_status_aggregate: Contact_Invite_Status_Aggregate
  /** fetch data from the table: "contact_invite_status" using primary key columns */
  contact_invite_status_by_pk?: Maybe<Contact_Invite_Status>
  /** fetch data from the table in a streaming manner: "contact_invite_status" */
  contact_invite_status_stream: Array<Contact_Invite_Status>
  /** fetch data from the table in a streaming manner: "contact_invite" */
  contact_invite_stream: Array<Contact_Invite>
  /** fetch data from the table: "contact_invite_type" */
  contact_invite_type: Array<Contact_Invite_Type>
  /** fetch aggregated fields from the table: "contact_invite_type" */
  contact_invite_type_aggregate: Contact_Invite_Type_Aggregate
  /** fetch data from the table: "contact_invite_type" using primary key columns */
  contact_invite_type_by_pk?: Maybe<Contact_Invite_Type>
  /** fetch data from the table in a streaming manner: "contact_invite_type" */
  contact_invite_type_stream: Array<Contact_Invite_Type>
  /** execute function "creator_subscribers_random" which returns "user" */
  creator_subscribers_random: Array<User>
  /** execute function "creator_subscribers_random" and query aggregates on result of table type "user" */
  creator_subscribers_random_aggregate: User_Aggregate
  /** fetch data from the table: "creator_user_subscriptions" */
  creator_user_subscriptions: Array<Creator_User_Subscriptions>
  /** fetch aggregated fields from the table: "creator_user_subscriptions" */
  creator_user_subscriptions_aggregate: Creator_User_Subscriptions_Aggregate
  /** fetch data from the table in a streaming manner: "creator_user_subscriptions" */
  creator_user_subscriptions_stream: Array<Creator_User_Subscriptions>
  /** fetch data from the table: "deal_hop_gate" */
  deal_hop_gate: Array<Deal_Hop_Gate>
  /** fetch aggregated fields from the table: "deal_hop_gate" */
  deal_hop_gate_aggregate: Deal_Hop_Gate_Aggregate
  /** fetch data from the table: "deal_hop_gate" using primary key columns */
  deal_hop_gate_by_pk?: Maybe<Deal_Hop_Gate>
  /** fetch data from the table in a streaming manner: "deal_hop_gate" */
  deal_hop_gate_stream: Array<Deal_Hop_Gate>
  /** fetch data from the table: "device_client_data" */
  device_client_data: Array<Device_Client_Data>
  /** fetch aggregated fields from the table: "device_client_data" */
  device_client_data_aggregate: Device_Client_Data_Aggregate
  /** fetch data from the table: "device_client_data" using primary key columns */
  device_client_data_by_pk?: Maybe<Device_Client_Data>
  /** fetch data from the table in a streaming manner: "device_client_data" */
  device_client_data_stream: Array<Device_Client_Data>
  /** fetch data from the table: "discovery_backlog" */
  discovery_backlog: Array<Discovery_Backlog>
  /** fetch aggregated fields from the table: "discovery_backlog" */
  discovery_backlog_aggregate: Discovery_Backlog_Aggregate
  /** fetch data from the table: "discovery_backlog" using primary key columns */
  discovery_backlog_by_pk?: Maybe<Discovery_Backlog>
  /** fetch data from the table in a streaming manner: "discovery_backlog" */
  discovery_backlog_stream: Array<Discovery_Backlog>
  /** fetch data from the table: "discovery_feed" */
  discovery_feed: Array<Discovery_Feed>
  /** fetch aggregated fields from the table: "discovery_feed" */
  discovery_feed_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table: "discovery_feed" using primary key columns */
  discovery_feed_by_pk?: Maybe<Discovery_Feed>
  /** execute function "discovery_feed_random" which returns "discovery_feed" */
  discovery_feed_random: Array<Discovery_Feed>
  /** execute function "discovery_feed_random" and query aggregates on result of table type "discovery_feed" */
  discovery_feed_random_aggregate: Discovery_Feed_Aggregate
  /** fetch data from the table in a streaming manner: "discovery_feed" */
  discovery_feed_stream: Array<Discovery_Feed>
  /** fetch data from the table: "discovery_staging" */
  discovery_staging: Array<Discovery_Staging>
  /** fetch aggregated fields from the table: "discovery_staging" */
  discovery_staging_aggregate: Discovery_Staging_Aggregate
  /** fetch data from the table: "discovery_staging" using primary key columns */
  discovery_staging_by_pk?: Maybe<Discovery_Staging>
  /** fetch data from the table in a streaming manner: "discovery_staging" */
  discovery_staging_stream: Array<Discovery_Staging>
  /** fetch data from the table: "featured_shop" */
  featured_shop: Array<Featured_Shop>
  /** fetch aggregated fields from the table: "featured_shop" */
  featured_shop_aggregate: Featured_Shop_Aggregate
  /** fetch data from the table: "featured_shop" using primary key columns */
  featured_shop_by_pk?: Maybe<Featured_Shop>
  /** fetch data from the table: "featured_shop_item" */
  featured_shop_item: Array<Featured_Shop_Item>
  /** fetch aggregated fields from the table: "featured_shop_item" */
  featured_shop_item_aggregate: Featured_Shop_Item_Aggregate
  /** fetch data from the table: "featured_shop_item" using primary key columns */
  featured_shop_item_by_pk?: Maybe<Featured_Shop_Item>
  /** fetch data from the table in a streaming manner: "featured_shop_item" */
  featured_shop_item_stream: Array<Featured_Shop_Item>
  /** fetch data from the table in a streaming manner: "featured_shop" */
  featured_shop_stream: Array<Featured_Shop>
  /** execute function "featured_shops_for_onboarding" which returns "shop" */
  featured_shops_for_onboarding: Array<Shop>
  /** execute function "featured_shops_for_onboarding" and query aggregates on result of table type "shop" */
  featured_shops_for_onboarding_aggregate: Shop_Aggregate
  /** fetch data from the table: "feed_item" */
  feed_item: Array<Feed_Item>
  /** fetch aggregated fields from the table: "feed_item" */
  feed_item_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "feed_item" using primary key columns */
  feed_item_by_pk?: Maybe<Feed_Item>
  /** fetch data from the table: "feed_item_like" */
  feed_item_like: Array<Feed_Item_Like>
  /** fetch aggregated fields from the table: "feed_item_like" */
  feed_item_like_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table: "feed_item_like" using primary key columns */
  feed_item_like_by_pk?: Maybe<Feed_Item_Like>
  /** execute function "feed_item_like_random" which returns "feed_item_like" */
  feed_item_like_random: Array<Feed_Item_Like>
  /** execute function "feed_item_like_random" and query aggregates on result of table type "feed_item_like" */
  feed_item_like_random_aggregate: Feed_Item_Like_Aggregate
  /** fetch data from the table in a streaming manner: "feed_item_like" */
  feed_item_like_stream: Array<Feed_Item_Like>
  /** fetch data from the table in a streaming manner: "feed_item" */
  feed_item_stream: Array<Feed_Item>
  /** fetch data from the table: "feed_item_type" */
  feed_item_type: Array<Feed_Item_Type>
  /** fetch aggregated fields from the table: "feed_item_type" */
  feed_item_type_aggregate: Feed_Item_Type_Aggregate
  /** fetch data from the table: "feed_item_type" using primary key columns */
  feed_item_type_by_pk?: Maybe<Feed_Item_Type>
  /** fetch data from the table in a streaming manner: "feed_item_type" */
  feed_item_type_stream: Array<Feed_Item_Type>
  /** execute function "feed_items_for_style_accounts" which returns "feed_item" */
  feed_items_for_style_accounts: Array<Feed_Item>
  /** execute function "feed_items_for_style_accounts" and query aggregates on result of table type "feed_item" */
  feed_items_for_style_accounts_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user" which returns "feed_item" */
  feed_items_for_user: Array<Feed_Item>
  /** execute function "feed_items_for_user" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_aggregate: Feed_Item_Aggregate
  /** execute function "feed_items_for_user_subscriptions" which returns "feed_item" */
  feed_items_for_user_subscriptions: Array<Feed_Item>
  /** execute function "feed_items_for_user_subscriptions" and query aggregates on result of table type "feed_item" */
  feed_items_for_user_subscriptions_aggregate: Feed_Item_Aggregate
  /** fetch data from the table: "fmtc_deal" */
  fmtc_deal: Array<Fmtc_Deal>
  /** fetch aggregated fields from the table: "fmtc_deal" */
  fmtc_deal_aggregate: Fmtc_Deal_Aggregate
  /** fetch data from the table: "fmtc_deal" using primary key columns */
  fmtc_deal_by_pk?: Maybe<Fmtc_Deal>
  /** fetch data from the table in a streaming manner: "fmtc_deal" */
  fmtc_deal_stream: Array<Fmtc_Deal>
  /** fetch data from the table: "followable_item" */
  followable_item: Array<Followable_Item>
  /** fetch aggregated fields from the table: "followable_item" */
  followable_item_aggregate: Followable_Item_Aggregate
  /** fetch data from the table: "followable_item" using primary key columns */
  followable_item_by_pk?: Maybe<Followable_Item>
  /** fetch data from the table in a streaming manner: "followable_item" */
  followable_item_stream: Array<Followable_Item>
  /** fetch data from the table: "followable_item_type" */
  followable_item_type: Array<Followable_Item_Type>
  /** fetch aggregated fields from the table: "followable_item_type" */
  followable_item_type_aggregate: Followable_Item_Type_Aggregate
  /** fetch data from the table: "followable_item_type" using primary key columns */
  followable_item_type_by_pk?: Maybe<Followable_Item_Type>
  /** fetch data from the table in a streaming manner: "followable_item_type" */
  followable_item_type_stream: Array<Followable_Item_Type>
  /** fetch data from the table: "leaderboard_event" */
  leaderboard_event: Array<Leaderboard_Event>
  /** fetch aggregated fields from the table: "leaderboard_event" */
  leaderboard_event_aggregate: Leaderboard_Event_Aggregate
  /** fetch data from the table: "leaderboard_event" using primary key columns */
  leaderboard_event_by_pk?: Maybe<Leaderboard_Event>
  /** fetch data from the table: "leaderboard_event_name" */
  leaderboard_event_name: Array<Leaderboard_Event_Name>
  /** fetch aggregated fields from the table: "leaderboard_event_name" */
  leaderboard_event_name_aggregate: Leaderboard_Event_Name_Aggregate
  /** fetch data from the table: "leaderboard_event_name" using primary key columns */
  leaderboard_event_name_by_pk?: Maybe<Leaderboard_Event_Name>
  /** fetch data from the table in a streaming manner: "leaderboard_event_name" */
  leaderboard_event_name_stream: Array<Leaderboard_Event_Name>
  /** fetch data from the table in a streaming manner: "leaderboard_event" */
  leaderboard_event_stream: Array<Leaderboard_Event>
  /** fetch data from the table: "link_type" */
  link_type: Array<Link_Type>
  /** fetch aggregated fields from the table: "link_type" */
  link_type_aggregate: Link_Type_Aggregate
  /** fetch data from the table: "link_type" using primary key columns */
  link_type_by_pk?: Maybe<Link_Type>
  /** fetch data from the table in a streaming manner: "link_type" */
  link_type_stream: Array<Link_Type>
  /** fetch data from the table: "media_enabled_item" */
  media_enabled_item: Array<Media_Enabled_Item>
  /** fetch aggregated fields from the table: "media_enabled_item" */
  media_enabled_item_aggregate: Media_Enabled_Item_Aggregate
  /** fetch data from the table: "media_enabled_item" using primary key columns */
  media_enabled_item_by_pk?: Maybe<Media_Enabled_Item>
  /** fetch data from the table in a streaming manner: "media_enabled_item" */
  media_enabled_item_stream: Array<Media_Enabled_Item>
  /** fetch data from the table: "media_item" */
  media_item: Array<Media_Item>
  /** fetch aggregated fields from the table: "media_item" */
  media_item_aggregate: Media_Item_Aggregate
  /** fetch data from the table: "media_item" using primary key columns */
  media_item_by_pk?: Maybe<Media_Item>
  /** fetch data from the table in a streaming manner: "media_item" */
  media_item_stream: Array<Media_Item>
  /** fetch data from the table: "media_item_type" */
  media_item_type: Array<Media_Item_Type>
  /** fetch aggregated fields from the table: "media_item_type" */
  media_item_type_aggregate: Media_Item_Type_Aggregate
  /** fetch data from the table: "media_item_type" using primary key columns */
  media_item_type_by_pk?: Maybe<Media_Item_Type>
  /** fetch data from the table in a streaming manner: "media_item_type" */
  media_item_type_stream: Array<Media_Item_Type>
  /** An array relationship */
  media_items: Array<Media_Items>
  /** An aggregate relationship */
  media_items_aggregate: Media_Items_Aggregate
  /** fetch data from the table: "media_items" using primary key columns */
  media_items_by_pk?: Maybe<Media_Items>
  /** fetch data from the table in a streaming manner: "media_items" */
  media_items_stream: Array<Media_Items>
  /** fetch data from the table: "media_type" */
  media_type: Array<Media_Type>
  /** fetch aggregated fields from the table: "media_type" */
  media_type_aggregate: Media_Type_Aggregate
  /** fetch data from the table: "media_type" using primary key columns */
  media_type_by_pk?: Maybe<Media_Type>
  /** fetch data from the table in a streaming manner: "media_type" */
  media_type_stream: Array<Media_Type>
  /** execute function "mention_search_users" which returns "user" */
  mention_search_users: Array<User>
  /** execute function "mention_search_users" and query aggregates on result of table type "user" */
  mention_search_users_aggregate: User_Aggregate
  /** fetch data from the table: "mobile_app_home" */
  mobile_app_home: Array<Mobile_App_Home>
  /** fetch aggregated fields from the table: "mobile_app_home" */
  mobile_app_home_aggregate: Mobile_App_Home_Aggregate
  /** fetch data from the table: "mobile_app_home" using primary key columns */
  mobile_app_home_by_pk?: Maybe<Mobile_App_Home>
  /** fetch data from the table in a streaming manner: "mobile_app_home" */
  mobile_app_home_stream: Array<Mobile_App_Home>
  /** fetch data from the table: "mobile_home" */
  mobile_home: Array<Mobile_Home>
  /** fetch aggregated fields from the table: "mobile_home" */
  mobile_home_aggregate: Mobile_Home_Aggregate
  /** fetch data from the table: "mobile_home" using primary key columns */
  mobile_home_by_pk?: Maybe<Mobile_Home>
  /** fetch data from the table in a streaming manner: "mobile_home" */
  mobile_home_stream: Array<Mobile_Home>
  /** fetch data from the table: "mobile_push_notification" */
  mobile_push_notification: Array<Mobile_Push_Notification>
  /** fetch aggregated fields from the table: "mobile_push_notification" */
  mobile_push_notification_aggregate: Mobile_Push_Notification_Aggregate
  /** fetch data from the table: "mobile_push_notification" using primary key columns */
  mobile_push_notification_by_pk?: Maybe<Mobile_Push_Notification>
  /** fetch data from the table: "mobile_push_notification_status" */
  mobile_push_notification_status: Array<Mobile_Push_Notification_Status>
  /** fetch aggregated fields from the table: "mobile_push_notification_status" */
  mobile_push_notification_status_aggregate: Mobile_Push_Notification_Status_Aggregate
  /** fetch data from the table: "mobile_push_notification_status" using primary key columns */
  mobile_push_notification_status_by_pk?: Maybe<Mobile_Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "mobile_push_notification_status" */
  mobile_push_notification_status_stream: Array<Mobile_Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "mobile_push_notification" */
  mobile_push_notification_stream: Array<Mobile_Push_Notification>
  /** fetch data from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user: Array<Notifiable_Feed_Item_User>
  /** fetch aggregated fields from the table: "notifiable_feed_item_user" */
  notifiable_feed_item_user_aggregate: Notifiable_Feed_Item_User_Aggregate
  /** fetch data from the table in a streaming manner: "notifiable_feed_item_user" */
  notifiable_feed_item_user_stream: Array<Notifiable_Feed_Item_User>
  /** fetch data from the table: "notifiable_item" */
  notifiable_item: Array<Notifiable_Item>
  /** fetch aggregated fields from the table: "notifiable_item" */
  notifiable_item_aggregate: Notifiable_Item_Aggregate
  /** fetch data from the table: "notifiable_item" using primary key columns */
  notifiable_item_by_pk?: Maybe<Notifiable_Item>
  /** fetch data from the table in a streaming manner: "notifiable_item" */
  notifiable_item_stream: Array<Notifiable_Item>
  /** fetch data from the table: "notifiable_item_type" */
  notifiable_item_type: Array<Notifiable_Item_Type>
  /** fetch aggregated fields from the table: "notifiable_item_type" */
  notifiable_item_type_aggregate: Notifiable_Item_Type_Aggregate
  /** fetch data from the table: "notifiable_item_type" using primary key columns */
  notifiable_item_type_by_pk?: Maybe<Notifiable_Item_Type>
  /** fetch data from the table in a streaming manner: "notifiable_item_type" */
  notifiable_item_type_stream: Array<Notifiable_Item_Type>
  /** fetch data from the table: "notification" */
  notification: Array<Notification>
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>
  /** execute function "notification_center_feed_for_user" which returns "notification_center_item" */
  notification_center_feed_for_user: Array<Notification_Center_Item>
  /** execute function "notification_center_feed_for_user" and query aggregates on result of table type "notification_center_item" */
  notification_center_feed_for_user_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" */
  notification_center_item: Array<Notification_Center_Item>
  /** fetch aggregated fields from the table: "notification_center_item" */
  notification_center_item_aggregate: Notification_Center_Item_Aggregate
  /** fetch data from the table: "notification_center_item" using primary key columns */
  notification_center_item_by_pk?: Maybe<Notification_Center_Item>
  /** fetch data from the table: "notification_center_item_read_status" */
  notification_center_item_read_status: Array<Notification_Center_Item_Read_Status>
  /** fetch aggregated fields from the table: "notification_center_item_read_status" */
  notification_center_item_read_status_aggregate: Notification_Center_Item_Read_Status_Aggregate
  /** fetch data from the table: "notification_center_item_read_status" using primary key columns */
  notification_center_item_read_status_by_pk?: Maybe<Notification_Center_Item_Read_Status>
  /** fetch data from the table in a streaming manner: "notification_center_item_read_status" */
  notification_center_item_read_status_stream: Array<Notification_Center_Item_Read_Status>
  /** fetch data from the table in a streaming manner: "notification_center_item" */
  notification_center_item_stream: Array<Notification_Center_Item>
  /** fetch data from the table: "notification_center_item_type" */
  notification_center_item_type: Array<Notification_Center_Item_Type>
  /** fetch aggregated fields from the table: "notification_center_item_type" */
  notification_center_item_type_aggregate: Notification_Center_Item_Type_Aggregate
  /** fetch data from the table: "notification_center_item_type" using primary key columns */
  notification_center_item_type_by_pk?: Maybe<Notification_Center_Item_Type>
  /** fetch data from the table in a streaming manner: "notification_center_item_type" */
  notification_center_item_type_stream: Array<Notification_Center_Item_Type>
  /** fetch data from the table: "notification_preference" */
  notification_preference: Array<Notification_Preference>
  /** fetch aggregated fields from the table: "notification_preference" */
  notification_preference_aggregate: Notification_Preference_Aggregate
  /** fetch data from the table: "notification_preference" using primary key columns */
  notification_preference_by_pk?: Maybe<Notification_Preference>
  /** fetch data from the table in a streaming manner: "notification_preference" */
  notification_preference_stream: Array<Notification_Preference>
  /** An array relationship */
  notification_status: Array<Notification_Status>
  /** An aggregate relationship */
  notification_status_aggregate: Notification_Status_Aggregate
  /** fetch data from the table: "notification_status" using primary key columns */
  notification_status_by_pk?: Maybe<Notification_Status>
  /** fetch data from the table in a streaming manner: "notification_status" */
  notification_status_stream: Array<Notification_Status>
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<Notification>
  /** fetch data from the table: "onboarding_categories" */
  onboarding_categories: Array<Onboarding_Categories>
  /** fetch aggregated fields from the table: "onboarding_categories" */
  onboarding_categories_aggregate: Onboarding_Categories_Aggregate
  /** fetch data from the table: "onboarding_categories" using primary key columns */
  onboarding_categories_by_pk?: Maybe<Onboarding_Categories>
  /** fetch data from the table in a streaming manner: "onboarding_categories" */
  onboarding_categories_stream: Array<Onboarding_Categories>
  /** fetch data from the table: "onboarding_sources" */
  onboarding_sources: Array<Onboarding_Sources>
  /** fetch aggregated fields from the table: "onboarding_sources" */
  onboarding_sources_aggregate: Onboarding_Sources_Aggregate
  /** fetch data from the table: "onboarding_sources" using primary key columns */
  onboarding_sources_by_pk?: Maybe<Onboarding_Sources>
  /** fetch data from the table in a streaming manner: "onboarding_sources" */
  onboarding_sources_stream: Array<Onboarding_Sources>
  /** fetch data from the table: "one_off_email" */
  one_off_email: Array<One_Off_Email>
  /** fetch aggregated fields from the table: "one_off_email" */
  one_off_email_aggregate: One_Off_Email_Aggregate
  /** fetch data from the table: "one_off_email" using primary key columns */
  one_off_email_by_pk?: Maybe<One_Off_Email>
  /** fetch data from the table in a streaming manner: "one_off_email" */
  one_off_email_stream: Array<One_Off_Email>
  /** execute function "other_shops_for_shop_page" which returns "shop" */
  other_shops_for_shop_page: Array<Shop>
  /** execute function "other_shops_for_shop_page" and query aggregates on result of table type "shop" */
  other_shops_for_shop_page_aggregate: Shop_Aggregate
  /** fetch data from the table: "pageview_event" */
  pageview_event: Array<Pageview_Event>
  /** fetch aggregated fields from the table: "pageview_event" */
  pageview_event_aggregate: Pageview_Event_Aggregate
  /** fetch data from the table: "pageview_event" using primary key columns */
  pageview_event_by_pk?: Maybe<Pageview_Event>
  /** fetch data from the table in a streaming manner: "pageview_event" */
  pageview_event_stream: Array<Pageview_Event>
  /** fetch data from the table: "pageview_event_summary" */
  pageview_event_summary: Array<Pageview_Event_Summary>
  /** fetch aggregated fields from the table: "pageview_event_summary" */
  pageview_event_summary_aggregate: Pageview_Event_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "pageview_event_summary" */
  pageview_event_summary_stream: Array<Pageview_Event_Summary>
  /** fetch data from the table: "promo_period" */
  promo_period: Array<Promo_Period>
  /** fetch aggregated fields from the table: "promo_period" */
  promo_period_aggregate: Promo_Period_Aggregate
  /** fetch data from the table: "promo_period" using primary key columns */
  promo_period_by_pk?: Maybe<Promo_Period>
  /** fetch data from the table in a streaming manner: "promo_period" */
  promo_period_stream: Array<Promo_Period>
  /** fetch data from the table: "push_notification" */
  push_notification: Array<Push_Notification>
  /** fetch aggregated fields from the table: "push_notification" */
  push_notification_aggregate: Push_Notification_Aggregate
  /** fetch data from the table: "push_notification" using primary key columns */
  push_notification_by_pk?: Maybe<Push_Notification>
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  /** fetch data from the table: "push_notification_status" using primary key columns */
  push_notification_status_by_pk?: Maybe<Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "push_notification_status" */
  push_notification_status_stream: Array<Push_Notification_Status>
  /** fetch data from the table in a streaming manner: "push_notification" */
  push_notification_stream: Array<Push_Notification>
  /** execute function "random_discovery_feed_items" which returns "discovery_feed" */
  random_discovery_feed_items: Array<Discovery_Feed>
  /** execute function "random_discovery_feed_items" and query aggregates on result of table type "discovery_feed" */
  random_discovery_feed_items_aggregate: Discovery_Feed_Aggregate
  /** execute function "recommended_shops_by_user" which returns "shop" */
  recommended_shops_by_user: Array<Shop>
  /** execute function "recommended_shops_by_user" and query aggregates on result of table type "shop" */
  recommended_shops_by_user_aggregate: Shop_Aggregate
  /** fetch data from the table: "redirect_ids" */
  redirect_ids: Array<Redirect_Ids>
  /** fetch aggregated fields from the table: "redirect_ids" */
  redirect_ids_aggregate: Redirect_Ids_Aggregate
  /** fetch data from the table: "redirect_ids" using primary key columns */
  redirect_ids_by_pk?: Maybe<Redirect_Ids>
  /** fetch data from the table in a streaming manner: "redirect_ids" */
  redirect_ids_stream: Array<Redirect_Ids>
  /** execute function "related_shops_by_category" which returns "shop" */
  related_shops_by_category: Array<Shop>
  /** execute function "related_shops_by_category" and query aggregates on result of table type "shop" */
  related_shops_by_category_aggregate: Shop_Aggregate
  /** execute function "related_users_for_shop_item" which returns "user" */
  related_users_for_shop_item: Array<User>
  /** execute function "related_users_for_shop_item" and query aggregates on result of table type "user" */
  related_users_for_shop_item_aggregate: User_Aggregate
  /** fetch data from the table: "remove_reason" */
  remove_reason: Array<Remove_Reason>
  /** fetch aggregated fields from the table: "remove_reason" */
  remove_reason_aggregate: Remove_Reason_Aggregate
  /** fetch data from the table: "remove_reason" using primary key columns */
  remove_reason_by_pk?: Maybe<Remove_Reason>
  /** fetch data from the table in a streaming manner: "remove_reason" */
  remove_reason_stream: Array<Remove_Reason>
  /** fetch data from the table: "rewardable_action_names" */
  rewardable_action_names: Array<Rewardable_Action_Names>
  /** fetch aggregated fields from the table: "rewardable_action_names" */
  rewardable_action_names_aggregate: Rewardable_Action_Names_Aggregate
  /** fetch data from the table: "rewardable_action_names" using primary key columns */
  rewardable_action_names_by_pk?: Maybe<Rewardable_Action_Names>
  /** fetch data from the table in a streaming manner: "rewardable_action_names" */
  rewardable_action_names_stream: Array<Rewardable_Action_Names>
  /** fetch data from the table: "rewardable_item_type" */
  rewardable_item_type: Array<Rewardable_Item_Type>
  /** fetch aggregated fields from the table: "rewardable_item_type" */
  rewardable_item_type_aggregate: Rewardable_Item_Type_Aggregate
  /** fetch data from the table: "rewardable_item_type" using primary key columns */
  rewardable_item_type_by_pk?: Maybe<Rewardable_Item_Type>
  /** fetch data from the table in a streaming manner: "rewardable_item_type" */
  rewardable_item_type_stream: Array<Rewardable_Item_Type>
  /** fetch data from the table: "search_query" */
  search_query: Array<Search_Query>
  /** fetch aggregated fields from the table: "search_query" */
  search_query_aggregate: Search_Query_Aggregate
  /** fetch data from the table: "search_query" using primary key columns */
  search_query_by_pk?: Maybe<Search_Query>
  /** fetch data from the table: "search_query_result" */
  search_query_result: Array<Search_Query_Result>
  /** fetch aggregated fields from the table: "search_query_result" */
  search_query_result_aggregate: Search_Query_Result_Aggregate
  /** fetch data from the table: "search_query_result" using primary key columns */
  search_query_result_by_pk?: Maybe<Search_Query_Result>
  /** fetch data from the table in a streaming manner: "search_query_result" */
  search_query_result_stream: Array<Search_Query_Result>
  /** fetch data from the table in a streaming manner: "search_query" */
  search_query_stream: Array<Search_Query>
  /** execute function "search_shop_items" which returns "shop_item" */
  search_shop_items: Array<Shop_Item>
  /** execute function "search_shop_items" and query aggregates on result of table type "shop_item" */
  search_shop_items_aggregate: Shop_Item_Aggregate
  /** execute function "search_shops" which returns "shop" */
  search_shops: Array<Shop>
  /** execute function "search_shops" and query aggregates on result of table type "shop" */
  search_shops_aggregate: Shop_Aggregate
  /** execute function "search_users" which returns "user" */
  search_users: Array<User>
  /** execute function "search_users" and query aggregates on result of table type "user" */
  search_users_aggregate: User_Aggregate
  /** fetch data from the table: "shop" */
  shop: Array<Shop>
  /** fetch data from the table: "shop_affiliate" */
  shop_affiliate: Array<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop_affiliate" */
  shop_affiliate_aggregate: Shop_Affiliate_Aggregate
  /** fetch data from the table: "shop_affiliate" using primary key columns */
  shop_affiliate_by_pk?: Maybe<Shop_Affiliate>
  /** fetch data from the table in a streaming manner: "shop_affiliate" */
  shop_affiliate_stream: Array<Shop_Affiliate>
  /** fetch aggregated fields from the table: "shop" */
  shop_aggregate: Shop_Aggregate
  /** fetch data from the table: "shop" using primary key columns */
  shop_by_pk?: Maybe<Shop>
  /** fetch data from the table: "shop_categorization" */
  shop_categorization: Array<Shop_Categorization>
  /** fetch aggregated fields from the table: "shop_categorization" */
  shop_categorization_aggregate: Shop_Categorization_Aggregate
  /** fetch data from the table: "shop_categorization" using primary key columns */
  shop_categorization_by_pk?: Maybe<Shop_Categorization>
  /** fetch data from the table in a streaming manner: "shop_categorization" */
  shop_categorization_stream: Array<Shop_Categorization>
  /** fetch data from the table: "shop_categorized_deals" */
  shop_categorized_deals: Array<Shop_Categorized_Deals>
  /** fetch aggregated fields from the table: "shop_categorized_deals" */
  shop_categorized_deals_aggregate: Shop_Categorized_Deals_Aggregate
  /** fetch data from the table in a streaming manner: "shop_categorized_deals" */
  shop_categorized_deals_stream: Array<Shop_Categorized_Deals>
  /** fetch data from the table: "shop_category" */
  shop_category: Array<Shop_Category>
  /** fetch aggregated fields from the table: "shop_category" */
  shop_category_aggregate: Shop_Category_Aggregate
  /** fetch data from the table: "shop_category" using primary key columns */
  shop_category_by_pk?: Maybe<Shop_Category>
  /** fetch data from the table in a streaming manner: "shop_category" */
  shop_category_stream: Array<Shop_Category>
  /** execute function "shop_collections" which returns "collection" */
  shop_collections: Array<Collection>
  /** execute function "shop_collections" and query aggregates on result of table type "collection" */
  shop_collections_aggregate: Collection_Aggregate
  /** fetch data from the table: "shop_item" */
  shop_item: Array<Shop_Item>
  /** fetch aggregated fields from the table: "shop_item" */
  shop_item_aggregate: Shop_Item_Aggregate
  /** fetch data from the table: "shop_item_availability" */
  shop_item_availability: Array<Shop_Item_Availability>
  /** fetch aggregated fields from the table: "shop_item_availability" */
  shop_item_availability_aggregate: Shop_Item_Availability_Aggregate
  /** fetch data from the table: "shop_item_availability" using primary key columns */
  shop_item_availability_by_pk?: Maybe<Shop_Item_Availability>
  /** fetch data from the table in a streaming manner: "shop_item_availability" */
  shop_item_availability_stream: Array<Shop_Item_Availability>
  /** fetch data from the table: "shop_item" using primary key columns */
  shop_item_by_pk?: Maybe<Shop_Item>
  /** fetch data from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status: Array<Shop_Item_Enrichment_Status>
  /** fetch aggregated fields from the table: "shop_item_enrichment_status" */
  shop_item_enrichment_status_aggregate: Shop_Item_Enrichment_Status_Aggregate
  /** fetch data from the table: "shop_item_enrichment_status" using primary key columns */
  shop_item_enrichment_status_by_pk?: Maybe<Shop_Item_Enrichment_Status>
  /** fetch data from the table in a streaming manner: "shop_item_enrichment_status" */
  shop_item_enrichment_status_stream: Array<Shop_Item_Enrichment_Status>
  /** fetch data from the table: "shop_item_history" */
  shop_item_history: Array<Shop_Item_History>
  /** fetch aggregated fields from the table: "shop_item_history" */
  shop_item_history_aggregate: Shop_Item_History_Aggregate
  /** fetch data from the table: "shop_item_history" using primary key columns */
  shop_item_history_by_pk?: Maybe<Shop_Item_History>
  /** fetch data from the table in a streaming manner: "shop_item_history" */
  shop_item_history_stream: Array<Shop_Item_History>
  /** fetch data from the table: "shop_item_image" */
  shop_item_image: Array<Shop_Item_Image>
  /** fetch aggregated fields from the table: "shop_item_image" */
  shop_item_image_aggregate: Shop_Item_Image_Aggregate
  /** fetch data from the table: "shop_item_image" using primary key columns */
  shop_item_image_by_pk?: Maybe<Shop_Item_Image>
  /** fetch data from the table in a streaming manner: "shop_item_image" */
  shop_item_image_stream: Array<Shop_Item_Image>
  /** fetch data from the table: "shop_item_index" */
  shop_item_index: Array<Shop_Item_Index>
  /** fetch aggregated fields from the table: "shop_item_index" */
  shop_item_index_aggregate: Shop_Item_Index_Aggregate
  /** fetch data from the table: "shop_item_index" using primary key columns */
  shop_item_index_by_pk?: Maybe<Shop_Item_Index>
  /** fetch data from the table in a streaming manner: "shop_item_index" */
  shop_item_index_stream: Array<Shop_Item_Index>
  /** fetch data from the table in a streaming manner: "shop_item" */
  shop_item_stream: Array<Shop_Item>
  /** fetch data from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped: Array<Shop_Item_To_Be_Scraped>
  /** fetch aggregated fields from the table: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped_aggregate: Shop_Item_To_Be_Scraped_Aggregate
  /** fetch data from the table in a streaming manner: "shop_item_to_be_scraped" */
  shop_item_to_be_scraped_stream: Array<Shop_Item_To_Be_Scraped>
  /** fetch data from the table: "shop_popularity" */
  shop_popularity: Array<Shop_Popularity>
  /** fetch aggregated fields from the table: "shop_popularity" */
  shop_popularity_aggregate: Shop_Popularity_Aggregate
  /** fetch data from the table in a streaming manner: "shop_popularity" */
  shop_popularity_stream: Array<Shop_Popularity>
  /** fetch data from the table: "shop_popularity_with_points" */
  shop_popularity_with_points: Array<Shop_Popularity_With_Points>
  /** fetch aggregated fields from the table: "shop_popularity_with_points" */
  shop_popularity_with_points_aggregate: Shop_Popularity_With_Points_Aggregate
  /** fetch data from the table in a streaming manner: "shop_popularity_with_points" */
  shop_popularity_with_points_stream: Array<Shop_Popularity_With_Points>
  /** fetch data from the table: "shop_rating" */
  shop_rating: Array<Shop_Rating>
  /** fetch aggregated fields from the table: "shop_rating" */
  shop_rating_aggregate: Shop_Rating_Aggregate
  /** fetch data from the table: "shop_rating" using primary key columns */
  shop_rating_by_pk?: Maybe<Shop_Rating>
  /** fetch data from the table in a streaming manner: "shop_rating" */
  shop_rating_stream: Array<Shop_Rating>
  /** fetch data from the table in a streaming manner: "shop" */
  shop_stream: Array<Shop>
  /** fetch data from the table: "similar_products_history" */
  similar_products_history: Array<Similar_Products_History>
  /** fetch aggregated fields from the table: "similar_products_history" */
  similar_products_history_aggregate: Similar_Products_History_Aggregate
  /** fetch data from the table: "similar_products_history" using primary key columns */
  similar_products_history_by_pk?: Maybe<Similar_Products_History>
  /** fetch data from the table in a streaming manner: "similar_products_history" */
  similar_products_history_stream: Array<Similar_Products_History>
  /** execute function "similar_products_history_unique" which returns "similar_products_history" */
  similar_products_history_unique: Array<Similar_Products_History>
  /** execute function "similar_products_history_unique" and query aggregates on result of table type "similar_products_history" */
  similar_products_history_unique_aggregate: Similar_Products_History_Aggregate
  /** execute function "single_unread_notification_banner_item_for_user" which returns "notification_center_item" */
  single_unread_notification_banner_item_for_user?: Maybe<Notification_Center_Item>
  /** execute function "single_unread_notification_banner_item_for_user" and query aggregates on result of table type "notification_center_item" */
  single_unread_notification_banner_item_for_user_aggregate: Notification_Center_Item_Aggregate
  /** execute function "steal_their_looks" which returns "collection" */
  steal_their_looks: Array<Collection>
  /** execute function "steal_their_looks" and query aggregates on result of table type "collection" */
  steal_their_looks_aggregate: Collection_Aggregate
  /** execute function "steal_their_looks_random" which returns "collection" */
  steal_their_looks_random: Array<Collection>
  /** execute function "steal_their_looks_random" and query aggregates on result of table type "collection" */
  steal_their_looks_random_aggregate: Collection_Aggregate
  /** execute function "used_creator_redirect" which returns "user" */
  used_creator_redirect: Array<User>
  /** execute function "used_creator_redirect" and query aggregates on result of table type "user" */
  used_creator_redirect_aggregate: User_Aggregate
  /** fetch data from the table: "user" */
  user: Array<User>
  /** fetch data from the table: "user_account_type" */
  user_account_type: Array<User_Account_Type>
  /** fetch aggregated fields from the table: "user_account_type" */
  user_account_type_aggregate: User_Account_Type_Aggregate
  /** fetch data from the table: "user_account_type" using primary key columns */
  user_account_type_by_pk?: Maybe<User_Account_Type>
  /** fetch data from the table in a streaming manner: "user_account_type" */
  user_account_type_stream: Array<User_Account_Type>
  /** fetch data from the table: "user_action" */
  user_action: Array<User_Action>
  /** fetch aggregated fields from the table: "user_action" */
  user_action_aggregate: User_Action_Aggregate
  /** fetch data from the table: "user_action" using primary key columns */
  user_action_by_pk?: Maybe<User_Action>
  /** fetch data from the table in a streaming manner: "user_action" */
  user_action_stream: Array<User_Action>
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate
  /** fetch data from the table: "user_app_version" */
  user_app_version: Array<User_App_Version>
  /** fetch aggregated fields from the table: "user_app_version" */
  user_app_version_aggregate: User_App_Version_Aggregate
  /** fetch data from the table: "user_app_version" using primary key columns */
  user_app_version_by_pk?: Maybe<User_App_Version>
  /** fetch data from the table in a streaming manner: "user_app_version" */
  user_app_version_stream: Array<User_App_Version>
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>
  /** fetch data from the table: "user_carts_onboarding" */
  user_carts_onboarding: Array<User_Carts_Onboarding>
  /** fetch aggregated fields from the table: "user_carts_onboarding" */
  user_carts_onboarding_aggregate: User_Carts_Onboarding_Aggregate
  /** fetch data from the table: "user_carts_onboarding" using primary key columns */
  user_carts_onboarding_by_pk?: Maybe<User_Carts_Onboarding>
  /** fetch data from the table in a streaming manner: "user_carts_onboarding" */
  user_carts_onboarding_stream: Array<User_Carts_Onboarding>
  /** fetch data from the table: "user_category" */
  user_category: Array<User_Category>
  /** fetch aggregated fields from the table: "user_category" */
  user_category_aggregate: User_Category_Aggregate
  /** fetch data from the table: "user_category" using primary key columns */
  user_category_by_pk?: Maybe<User_Category>
  /** fetch data from the table in a streaming manner: "user_category" */
  user_category_stream: Array<User_Category>
  /** fetch data from the table: "user_deletion" */
  user_deletion: Array<User_Deletion>
  /** fetch aggregated fields from the table: "user_deletion" */
  user_deletion_aggregate: User_Deletion_Aggregate
  /** fetch data from the table: "user_deletion" using primary key columns */
  user_deletion_by_pk?: Maybe<User_Deletion>
  /** fetch data from the table in a streaming manner: "user_deletion" */
  user_deletion_stream: Array<User_Deletion>
  /** fetch data from the table: "user_feedback" */
  user_feedback: Array<User_Feedback>
  /** fetch aggregated fields from the table: "user_feedback" */
  user_feedback_aggregate: User_Feedback_Aggregate
  /** fetch data from the table: "user_feedback" using primary key columns */
  user_feedback_by_pk?: Maybe<User_Feedback>
  /** fetch data from the table: "user_feedback_item" */
  user_feedback_item: Array<User_Feedback_Item>
  /** fetch aggregated fields from the table: "user_feedback_item" */
  user_feedback_item_aggregate: User_Feedback_Item_Aggregate
  /** fetch data from the table: "user_feedback_item" using primary key columns */
  user_feedback_item_by_pk?: Maybe<User_Feedback_Item>
  /** fetch data from the table in a streaming manner: "user_feedback_item" */
  user_feedback_item_stream: Array<User_Feedback_Item>
  /** fetch data from the table in a streaming manner: "user_feedback" */
  user_feedback_stream: Array<User_Feedback>
  /** fetch data from the table: "user_feedback_type" */
  user_feedback_type: Array<User_Feedback_Type>
  /** fetch aggregated fields from the table: "user_feedback_type" */
  user_feedback_type_aggregate: User_Feedback_Type_Aggregate
  /** fetch data from the table: "user_feedback_type" using primary key columns */
  user_feedback_type_by_pk?: Maybe<User_Feedback_Type>
  /** fetch data from the table in a streaming manner: "user_feedback_type" */
  user_feedback_type_stream: Array<User_Feedback_Type>
  /** fetch data from the table: "user_flags" */
  user_flags: Array<User_Flags>
  /** fetch aggregated fields from the table: "user_flags" */
  user_flags_aggregate: User_Flags_Aggregate
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>
  /** fetch data from the table in a streaming manner: "user_flags" */
  user_flags_stream: Array<User_Flags>
  /** fetch data from the table: "user_impersonation" */
  user_impersonation: Array<User_Impersonation>
  /** fetch aggregated fields from the table: "user_impersonation" */
  user_impersonation_aggregate: User_Impersonation_Aggregate
  /** fetch data from the table: "user_impersonation" using primary key columns */
  user_impersonation_by_pk?: Maybe<User_Impersonation>
  /** fetch data from the table in a streaming manner: "user_impersonation" */
  user_impersonation_stream: Array<User_Impersonation>
  /** fetch data from the table: "user_ip_address" */
  user_ip_address: Array<User_Ip_Address>
  /** fetch aggregated fields from the table: "user_ip_address" */
  user_ip_address_aggregate: User_Ip_Address_Aggregate
  /** fetch data from the table: "user_ip_address" using primary key columns */
  user_ip_address_by_pk?: Maybe<User_Ip_Address>
  /** fetch data from the table in a streaming manner: "user_ip_address" */
  user_ip_address_stream: Array<User_Ip_Address>
  /** fetch data from the table: "user_items_followed" */
  user_items_followed: Array<User_Items_Followed>
  /** fetch aggregated fields from the table: "user_items_followed" */
  user_items_followed_aggregate: User_Items_Followed_Aggregate
  /** fetch data from the table: "user_items_followed" using primary key columns */
  user_items_followed_by_pk?: Maybe<User_Items_Followed>
  /** fetch data from the table in a streaming manner: "user_items_followed" */
  user_items_followed_stream: Array<User_Items_Followed>
  /** fetch data from the table: "user_links" */
  user_links: Array<User_Links>
  /** fetch aggregated fields from the table: "user_links" */
  user_links_aggregate: User_Links_Aggregate
  /** fetch data from the table: "user_links" using primary key columns */
  user_links_by_pk?: Maybe<User_Links>
  /** fetch data from the table in a streaming manner: "user_links" */
  user_links_stream: Array<User_Links>
  /** fetch data from the table: "user_onboarding_source" */
  user_onboarding_source: Array<User_Onboarding_Source>
  /** fetch aggregated fields from the table: "user_onboarding_source" */
  user_onboarding_source_aggregate: User_Onboarding_Source_Aggregate
  /** fetch data from the table: "user_onboarding_source" using primary key columns */
  user_onboarding_source_by_pk?: Maybe<User_Onboarding_Source>
  /** fetch data from the table in a streaming manner: "user_onboarding_source" */
  user_onboarding_source_stream: Array<User_Onboarding_Source>
  /** fetch data from the table: "user_onboarding_state" */
  user_onboarding_state: Array<User_Onboarding_State>
  /** fetch aggregated fields from the table: "user_onboarding_state" */
  user_onboarding_state_aggregate: User_Onboarding_State_Aggregate
  /** fetch data from the table: "user_onboarding_state" using primary key columns */
  user_onboarding_state_by_pk?: Maybe<User_Onboarding_State>
  /** fetch data from the table in a streaming manner: "user_onboarding_state" */
  user_onboarding_state_stream: Array<User_Onboarding_State>
  /** fetch data from the table: "user_phone_contact" */
  user_phone_contact: Array<User_Phone_Contact>
  /** fetch aggregated fields from the table: "user_phone_contact" */
  user_phone_contact_aggregate: User_Phone_Contact_Aggregate
  /** fetch data from the table: "user_phone_contact" using primary key columns */
  user_phone_contact_by_pk?: Maybe<User_Phone_Contact>
  /** fetch data from the table in a streaming manner: "user_phone_contact" */
  user_phone_contact_stream: Array<User_Phone_Contact>
  /** fetch data from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary: Array<User_Points_Redeemed_Summary>
  /** fetch aggregated fields from the table: "user_points_redeemed_summary" */
  user_points_redeemed_summary_aggregate: User_Points_Redeemed_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "user_points_redeemed_summary" */
  user_points_redeemed_summary_stream: Array<User_Points_Redeemed_Summary>
  /** fetch data from the table: "user_points_summary" */
  user_points_summary: Array<User_Points_Summary>
  /** fetch aggregated fields from the table: "user_points_summary" */
  user_points_summary_aggregate: User_Points_Summary_Aggregate
  /** fetch data from the table in a streaming manner: "user_points_summary" */
  user_points_summary_stream: Array<User_Points_Summary>
  /** fetch data from the table: "user_purchase_subscription" */
  user_purchase_subscription: Array<User_Purchase_Subscription>
  /** fetch aggregated fields from the table: "user_purchase_subscription" */
  user_purchase_subscription_aggregate: User_Purchase_Subscription_Aggregate
  /** fetch data from the table: "user_purchase_subscription" using primary key columns */
  user_purchase_subscription_by_pk?: Maybe<User_Purchase_Subscription>
  /** fetch data from the table in a streaming manner: "user_purchase_subscription" */
  user_purchase_subscription_stream: Array<User_Purchase_Subscription>
  /** fetch data from the table: "user_purchases" */
  user_purchases: Array<User_Purchases>
  /** fetch aggregated fields from the table: "user_purchases" */
  user_purchases_aggregate: User_Purchases_Aggregate
  /** fetch data from the table: "user_purchases" using primary key columns */
  user_purchases_by_pk?: Maybe<User_Purchases>
  /** fetch data from the table in a streaming manner: "user_purchases" */
  user_purchases_stream: Array<User_Purchases>
  /** fetch data from the table: "user_push_notification" */
  user_push_notification: Array<User_Push_Notification>
  /** fetch aggregated fields from the table: "user_push_notification" */
  user_push_notification_aggregate: User_Push_Notification_Aggregate
  /** fetch data from the table in a streaming manner: "user_push_notification" */
  user_push_notification_stream: Array<User_Push_Notification>
  /** fetch data from the table: "user_push_notification_token" */
  user_push_notification_token: Array<User_Push_Notification_Token>
  /** fetch aggregated fields from the table: "user_push_notification_token" */
  user_push_notification_token_aggregate: User_Push_Notification_Token_Aggregate
  /** fetch data from the table: "user_push_notification_token" using primary key columns */
  user_push_notification_token_by_pk?: Maybe<User_Push_Notification_Token>
  /** fetch data from the table in a streaming manner: "user_push_notification_token" */
  user_push_notification_token_stream: Array<User_Push_Notification_Token>
  /** fetch data from the table: "user_referral" */
  user_referral: Array<User_Referral>
  /** fetch aggregated fields from the table: "user_referral" */
  user_referral_aggregate: User_Referral_Aggregate
  /** fetch data from the table: "user_referral" using primary key columns */
  user_referral_by_pk?: Maybe<User_Referral>
  /** fetch data from the table in a streaming manner: "user_referral" */
  user_referral_stream: Array<User_Referral>
  /** fetch data from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config: Array<User_Reward_Multiplier_Config>
  /** fetch aggregated fields from the table: "user_reward_multiplier_config" */
  user_reward_multiplier_config_aggregate: User_Reward_Multiplier_Config_Aggregate
  /** fetch data from the table: "user_reward_multiplier_config" using primary key columns */
  user_reward_multiplier_config_by_pk?: Maybe<User_Reward_Multiplier_Config>
  /** fetch data from the table in a streaming manner: "user_reward_multiplier_config" */
  user_reward_multiplier_config_stream: Array<User_Reward_Multiplier_Config>
  /** fetch data from the table: "user_reward_redemption" */
  user_reward_redemption: Array<User_Reward_Redemption>
  /** fetch aggregated fields from the table: "user_reward_redemption" */
  user_reward_redemption_aggregate: User_Reward_Redemption_Aggregate
  /** fetch data from the table: "user_reward_redemption" using primary key columns */
  user_reward_redemption_by_pk?: Maybe<User_Reward_Redemption>
  /** fetch data from the table in a streaming manner: "user_reward_redemption" */
  user_reward_redemption_stream: Array<User_Reward_Redemption>
  /** fetch data from the table: "user_reward_reference" */
  user_reward_reference: Array<User_Reward_Reference>
  /** fetch aggregated fields from the table: "user_reward_reference" */
  user_reward_reference_aggregate: User_Reward_Reference_Aggregate
  /** fetch data from the table: "user_reward_reference" using primary key columns */
  user_reward_reference_by_pk?: Maybe<User_Reward_Reference>
  /** fetch data from the table in a streaming manner: "user_reward_reference" */
  user_reward_reference_stream: Array<User_Reward_Reference>
  /** fetch data from the table: "user_shop_favorite" */
  user_shop_favorite: Array<User_Shop_Favorite>
  /** fetch aggregated fields from the table: "user_shop_favorite" */
  user_shop_favorite_aggregate: User_Shop_Favorite_Aggregate
  /** fetch data from the table: "user_shop_favorite" using primary key columns */
  user_shop_favorite_by_pk?: Maybe<User_Shop_Favorite>
  /** fetch data from the table in a streaming manner: "user_shop_favorite" */
  user_shop_favorite_stream: Array<User_Shop_Favorite>
  /** fetch data from the table: "user_shopping_preferences" */
  user_shopping_preferences: Array<User_Shopping_Preferences>
  /** fetch aggregated fields from the table: "user_shopping_preferences" */
  user_shopping_preferences_aggregate: User_Shopping_Preferences_Aggregate
  /** fetch data from the table: "user_shopping_preferences" using primary key columns */
  user_shopping_preferences_by_pk?: Maybe<User_Shopping_Preferences>
  /** fetch data from the table in a streaming manner: "user_shopping_preferences" */
  user_shopping_preferences_stream: Array<User_Shopping_Preferences>
  /** fetch data from the table in a streaming manner: "user" */
  user_stream: Array<User>
  /** fetch data from the table: "user_subscription" */
  user_subscription: Array<User_Subscription>
  /** fetch aggregated fields from the table: "user_subscription" */
  user_subscription_aggregate: User_Subscription_Aggregate
  /** fetch data from the table: "user_subscription" using primary key columns */
  user_subscription_by_pk?: Maybe<User_Subscription>
  /** fetch data from the table: "user_subscription_status" */
  user_subscription_status: Array<User_Subscription_Status>
  /** fetch aggregated fields from the table: "user_subscription_status" */
  user_subscription_status_aggregate: User_Subscription_Status_Aggregate
  /** fetch data from the table: "user_subscription_status" using primary key columns */
  user_subscription_status_by_pk?: Maybe<User_Subscription_Status>
  /** fetch data from the table in a streaming manner: "user_subscription_status" */
  user_subscription_status_stream: Array<User_Subscription_Status>
  /** fetch data from the table in a streaming manner: "user_subscription" */
  user_subscription_stream: Array<User_Subscription>
  /** fetch data from the table: "user_temp" */
  user_temp: Array<User_Temp>
  /** fetch aggregated fields from the table: "user_temp" */
  user_temp_aggregate: User_Temp_Aggregate
  /** fetch data from the table: "user_temp" using primary key columns */
  user_temp_by_pk?: Maybe<User_Temp>
  /** fetch data from the table in a streaming manner: "user_temp" */
  user_temp_stream: Array<User_Temp>
  /** execute function "users_search" which returns "user" */
  users_search: Array<User>
  /** execute function "users_search" and query aggregates on result of table type "user" */
  users_search_aggregate: User_Aggregate
  /** fetch data from the table: "worflow_type" */
  worflow_type: Array<Worflow_Type>
  /** fetch aggregated fields from the table: "worflow_type" */
  worflow_type_aggregate: Worflow_Type_Aggregate
  /** fetch data from the table: "worflow_type" using primary key columns */
  worflow_type_by_pk?: Maybe<Worflow_Type>
  /** fetch data from the table in a streaming manner: "worflow_type" */
  worflow_type_stream: Array<Worflow_Type>
  /** fetch data from the table: "workflow" */
  workflow: Array<Workflow>
  /** fetch aggregated fields from the table: "workflow" */
  workflow_aggregate: Workflow_Aggregate
  /** fetch data from the table: "workflow" using primary key columns */
  workflow_by_pk?: Maybe<Workflow>
  /** fetch data from the table: "workflow_status" */
  workflow_status: Array<Workflow_Status>
  /** fetch aggregated fields from the table: "workflow_status" */
  workflow_status_aggregate: Workflow_Status_Aggregate
  /** fetch data from the table: "workflow_status" using primary key columns */
  workflow_status_by_pk?: Maybe<Workflow_Status>
  /** fetch data from the table in a streaming manner: "workflow_status" */
  workflow_status_stream: Array<Workflow_Status>
  /** fetch data from the table in a streaming manner: "workflow" */
  workflow_stream: Array<Workflow>
}

export type Subscription_RootAccount_Deletion_ReasonArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Subscription_RootAccount_Deletion_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Deletion_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Account_Deletion_Reason_Order_By>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Subscription_RootAccount_Deletion_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootAccount_Deletion_Reason_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Account_Deletion_Reason_Stream_Cursor_Input>>
  where?: Maybe<Account_Deletion_Reason_Bool_Exp>
}

export type Subscription_RootAffiliateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Subscription_RootAffiliate_Advertiser_IdArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Subscription_RootAffiliate_Advertiser_Id_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Advertiser_Id_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Advertiser_Id_Order_By>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Subscription_RootAffiliate_Advertiser_Id_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAffiliate_Advertiser_Id_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Advertiser_Id_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Advertiser_Id_Bool_Exp>
}

export type Subscription_RootAffiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Order_By>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Subscription_RootAffiliate_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootAffiliate_Commission_SummaryArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Subscription_RootAffiliate_Commission_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Commission_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Commission_Summary_Order_By>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Subscription_RootAffiliate_Commission_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Commission_Summary_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Commission_Summary_Bool_Exp>
}

export type Subscription_RootAffiliate_MetaArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Subscription_RootAffiliate_Meta_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Meta_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Meta_Order_By>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Subscription_RootAffiliate_Meta_By_PkArgs = {
  affiliate: Affiliate_Enum
}

export type Subscription_RootAffiliate_Meta_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Meta_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Meta_Bool_Exp>
}

export type Subscription_RootAffiliate_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Bool_Exp>
}

export type Subscription_RootAffiliate_TransactionArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Subscription_RootAffiliate_Transaction_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Subscription_RootAffiliate_Transaction_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootAffiliate_Transaction_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Affiliate_Transaction_Stream_Cursor_Input>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

export type Subscription_RootAll_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootAll_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootAmazon_CategoriesArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Subscription_RootAmazon_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Amazon_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Amazon_Categories_Order_By>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Subscription_RootAmazon_Categories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Amazon_Categories_Stream_Cursor_Input>>
  where?: Maybe<Amazon_Categories_Bool_Exp>
}

export type Subscription_RootBilling_ModeArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Subscription_RootBilling_Mode_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Mode_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Mode_Order_By>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Subscription_RootBilling_Mode_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootBilling_Mode_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Billing_Mode_Stream_Cursor_Input>>
  where?: Maybe<Billing_Mode_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Connected_AccountArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Connected_Account_AggregateArgs =
  {
    distinct_on?: Maybe<Array<Billing_Platform_Connected_Account_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Billing_Platform_Connected_Account_Order_By>>
    where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
  }

export type Subscription_RootBilling_Platform_Connected_Account_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootBilling_Platform_Connected_Account_StatusArgs = {
  distinct_on?: Maybe<
    Array<Billing_Platform_Connected_Account_Status_Select_Column>
  >
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
  where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Connected_Account_Status_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Billing_Platform_Connected_Account_Status_Select_Column>
    >
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Billing_Platform_Connected_Account_Status_Order_By>>
    where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  }

export type Subscription_RootBilling_Platform_Connected_Account_Status_By_PkArgs =
  {
    value: Scalars['String']
  }

export type Subscription_RootBilling_Platform_Connected_Account_Status_StreamArgs =
  {
    batch_size: Scalars['Int']
    cursor: Array<
      Maybe<Billing_Platform_Connected_Account_Status_Stream_Cursor_Input>
    >
    where?: Maybe<Billing_Platform_Connected_Account_Status_Bool_Exp>
  }

export type Subscription_RootBilling_Platform_Connected_Account_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Billing_Platform_Connected_Account_Stream_Cursor_Input>>
  where?: Maybe<Billing_Platform_Connected_Account_Bool_Exp>
}

export type Subscription_RootBilling_Platform_CustomerArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Customer_AggregateArgs = {
  distinct_on?: Maybe<Array<Billing_Platform_Customer_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Billing_Platform_Customer_Order_By>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Subscription_RootBilling_Platform_Customer_By_PkArgs = {
  billing_mode: Billing_Mode_Enum
  platform_type: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootBilling_Platform_Customer_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Billing_Platform_Customer_Stream_Cursor_Input>>
  where?: Maybe<Billing_Platform_Customer_Bool_Exp>
}

export type Subscription_RootCartArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Subscription_RootCart_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Subscription_RootCart_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCart_ItemArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Order_By>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCart_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Subscription_RootCart_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Subscription_RootCart_Item_Like_By_PkArgs = {
  cart_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Subscription_RootCart_Item_Like_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Item_Like_Stream_Cursor_Input>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

export type Subscription_RootCart_Item_NoteArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Subscription_RootCart_Item_Note_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Note_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Note_Order_By>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Subscription_RootCart_Item_Note_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCart_Item_Note_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Item_Note_Stream_Cursor_Input>>
  where?: Maybe<Cart_Item_Note_Bool_Exp>
}

export type Subscription_RootCart_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Item_Stream_Cursor_Input>>
  where?: Maybe<Cart_Item_Bool_Exp>
}

export type Subscription_RootCart_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Cart_Stream_Cursor_Input>>
  where?: Maybe<Cart_Bool_Exp>
}

export type Subscription_RootCategoriesArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: Maybe<Array<Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Categories_Order_By>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Categories_Stream_Cursor_Input>>
  where?: Maybe<Categories_Bool_Exp>
}

export type Subscription_RootCategory_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Subscription_RootCategory_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Category_Shop_Item_Order_By>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Subscription_RootCategory_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCategory_Shop_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Category_Shop_Item_Stream_Cursor_Input>>
  where?: Maybe<Category_Shop_Item_Bool_Exp>
}

export type Subscription_RootCheckoutArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Subscription_RootCheckout_AggregateArgs = {
  distinct_on?: Maybe<Array<Checkout_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Checkout_Order_By>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Subscription_RootCheckout_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCheckout_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Checkout_Stream_Cursor_Input>>
  where?: Maybe<Checkout_Bool_Exp>
}

export type Subscription_RootCollectionArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Cart_Item_Order_By>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Cart_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Cart_Item_Stream_Cursor_Input>>
  where?: Maybe<Collection_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_CollaboratorArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Subscription_RootCollection_Collaborator_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Subscription_RootCollection_Collaborator_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Collaborator_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Collaborator_Stream_Cursor_Input>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

export type Subscription_RootCollection_InviteArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Subscription_RootCollection_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Order_By>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Subscription_RootCollection_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Subscription_RootCollection_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Invite_Status_Order_By>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Subscription_RootCollection_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_Invite_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Invite_Status_Stream_Cursor_Input>>
  where?: Maybe<Collection_Invite_Status_Bool_Exp>
}

export type Subscription_RootCollection_Invite_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Invite_Stream_Cursor_Input>>
  where?: Maybe<Collection_Invite_Bool_Exp>
}

export type Subscription_RootCollection_Item_ClaimArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Item_Claim_StatusArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Status_Order_By>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_Item_Claim_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Item_Claim_Status_Stream_Cursor_Input>>
  where?: Maybe<Collection_Item_Claim_Status_Bool_Exp>
}

export type Subscription_RootCollection_Item_Claim_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Item_Claim_Stream_Cursor_Input>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

export type Subscription_RootCollection_RandomArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_Random_AggregateArgs = {
  args?: Maybe<Collection_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_SectionArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Subscription_RootCollection_Section_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Order_By>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Subscription_RootCollection_Section_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Section_Cart_ItemArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Section_Cart_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Section_Cart_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Section_Cart_Item_Order_By>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Section_Cart_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootCollection_Section_Cart_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Section_Cart_Item_Stream_Cursor_Input>>
  where?: Maybe<Collection_Section_Cart_Item_Bool_Exp>
}

export type Subscription_RootCollection_Section_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Section_Stream_Cursor_Input>>
  where?: Maybe<Collection_Section_Bool_Exp>
}

export type Subscription_RootCollection_Slug_HistoryArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Subscription_RootCollection_Slug_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Slug_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Slug_History_Order_By>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Subscription_RootCollection_Slug_History_By_PkArgs = {
  collection_id: Scalars['uuid']
  slug: Scalars['name']
}

export type Subscription_RootCollection_Slug_History_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Slug_History_Stream_Cursor_Input>>
  where?: Maybe<Collection_Slug_History_Bool_Exp>
}

export type Subscription_RootCollection_StateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Subscription_RootCollection_State_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_State_Order_By>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Subscription_RootCollection_State_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_State_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_State_Stream_Cursor_Input>>
  where?: Maybe<Collection_State_Bool_Exp>
}

export type Subscription_RootCollection_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Stream_Cursor_Input>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootCollection_TypeArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Subscription_RootCollection_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Type_Order_By>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Subscription_RootCollection_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCollection_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Collection_Type_Stream_Cursor_Input>>
  where?: Maybe<Collection_Type_Bool_Exp>
}

export type Subscription_RootCommentArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Subscription_RootComment_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Comment_Order_By>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Subscription_RootComment_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootComment_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Comment_Stream_Cursor_Input>>
  where?: Maybe<Comment_Bool_Exp>
}

export type Subscription_RootCommentable_ItemArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Subscription_RootCommentable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Order_By>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Subscription_RootCommentable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Commentable_Item_Type_Enum
}

export type Subscription_RootCommentable_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Commentable_Item_Stream_Cursor_Input>>
  where?: Maybe<Commentable_Item_Bool_Exp>
}

export type Subscription_RootCommentable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Subscription_RootCommentable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Commentable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Commentable_Item_Type_Order_By>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Subscription_RootCommentable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCommentable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Commentable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Commentable_Item_Type_Bool_Exp>
}

export type Subscription_RootCommunication_TemplateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Subscription_RootCommunication_Template_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Order_By>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Subscription_RootCommunication_Template_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCommunication_Template_Full_ListArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Subscription_RootCommunication_Template_Full_List_AggregateArgs = {
  distinct_on?: Maybe<Array<Communication_Template_Full_List_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Communication_Template_Full_List_Order_By>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Subscription_RootCommunication_Template_Full_List_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootCommunication_Template_Full_List_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Communication_Template_Full_List_Stream_Cursor_Input>>
  where?: Maybe<Communication_Template_Full_List_Bool_Exp>
}

export type Subscription_RootCommunication_Template_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Communication_Template_Stream_Cursor_Input>>
  where?: Maybe<Communication_Template_Bool_Exp>
}

export type Subscription_RootContact_Info_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Subscription_RootContact_Info_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Info_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Info_Type_Order_By>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Subscription_RootContact_Info_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootContact_Info_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Info_Type_Stream_Cursor_Input>>
  where?: Maybe<Contact_Info_Type_Bool_Exp>
}

export type Subscription_RootContact_InviteArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Subscription_RootContact_Invite_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Order_By>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Subscription_RootContact_Invite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootContact_Invite_StatusArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Subscription_RootContact_Invite_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Status_Order_By>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Subscription_RootContact_Invite_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootContact_Invite_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Invite_Status_Stream_Cursor_Input>>
  where?: Maybe<Contact_Invite_Status_Bool_Exp>
}

export type Subscription_RootContact_Invite_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Invite_Stream_Cursor_Input>>
  where?: Maybe<Contact_Invite_Bool_Exp>
}

export type Subscription_RootContact_Invite_TypeArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Subscription_RootContact_Invite_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Contact_Invite_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Contact_Invite_Type_Order_By>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Subscription_RootContact_Invite_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootContact_Invite_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Contact_Invite_Type_Stream_Cursor_Input>>
  where?: Maybe<Contact_Invite_Type_Bool_Exp>
}

export type Subscription_RootCreator_Subscribers_RandomArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootCreator_Subscribers_Random_AggregateArgs = {
  args?: Maybe<Creator_Subscribers_Random_Args>
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootCreator_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Subscription_RootCreator_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Creator_User_Subscriptions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Creator_User_Subscriptions_Order_By>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Subscription_RootCreator_User_Subscriptions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Creator_User_Subscriptions_Stream_Cursor_Input>>
  where?: Maybe<Creator_User_Subscriptions_Bool_Exp>
}

export type Subscription_RootDeal_Hop_GateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Subscription_RootDeal_Hop_Gate_AggregateArgs = {
  distinct_on?: Maybe<Array<Deal_Hop_Gate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Deal_Hop_Gate_Order_By>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Subscription_RootDeal_Hop_Gate_By_PkArgs = {
  user_id: Scalars['String']
}

export type Subscription_RootDeal_Hop_Gate_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Deal_Hop_Gate_Stream_Cursor_Input>>
  where?: Maybe<Deal_Hop_Gate_Bool_Exp>
}

export type Subscription_RootDevice_Client_DataArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Subscription_RootDevice_Client_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Device_Client_Data_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Device_Client_Data_Order_By>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Subscription_RootDevice_Client_Data_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDevice_Client_Data_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Device_Client_Data_Stream_Cursor_Input>>
  where?: Maybe<Device_Client_Data_Bool_Exp>
}

export type Subscription_RootDiscovery_BacklogArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Subscription_RootDiscovery_Backlog_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Backlog_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Backlog_Order_By>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Subscription_RootDiscovery_Backlog_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDiscovery_Backlog_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Discovery_Backlog_Stream_Cursor_Input>>
  where?: Maybe<Discovery_Backlog_Bool_Exp>
}

export type Subscription_RootDiscovery_FeedArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDiscovery_Feed_RandomArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_Random_AggregateArgs = {
  args?: Maybe<Discovery_Feed_Random_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_Feed_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Discovery_Feed_Stream_Cursor_Input>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootDiscovery_StagingArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Subscription_RootDiscovery_Staging_AggregateArgs = {
  distinct_on?: Maybe<Array<Discovery_Staging_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Staging_Order_By>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Subscription_RootDiscovery_Staging_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootDiscovery_Staging_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Discovery_Staging_Stream_Cursor_Input>>
  where?: Maybe<Discovery_Staging_Bool_Exp>
}

export type Subscription_RootFeatured_ShopArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Order_By>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFeatured_Shop_ItemArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Featured_Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Featured_Shop_Item_Order_By>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFeatured_Shop_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Featured_Shop_Item_Stream_Cursor_Input>>
  where?: Maybe<Featured_Shop_Item_Bool_Exp>
}

export type Subscription_RootFeatured_Shop_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Featured_Shop_Stream_Cursor_Input>>
  where?: Maybe<Featured_Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shops_For_OnboardingArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootFeatured_Shops_For_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootFeed_ItemArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFeed_Item_LikeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_By_PkArgs = {
  feed_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Subscription_RootFeed_Item_Like_RandomArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_Random_AggregateArgs = {
  args?: Maybe<Feed_Item_Like_Random_Args>
  distinct_on?: Maybe<Array<Feed_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Like_Order_By>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_Like_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Feed_Item_Like_Stream_Cursor_Input>>
  where?: Maybe<Feed_Item_Like_Bool_Exp>
}

export type Subscription_RootFeed_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Feed_Item_Stream_Cursor_Input>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Subscription_RootFeed_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Type_Order_By>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Subscription_RootFeed_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootFeed_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Feed_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Feed_Item_Type_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_Style_AccountsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_Style_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_UserArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_User_AggregateArgs = {
  args: Feed_Items_For_User_Args
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_User_SubscriptionsArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFeed_Items_For_User_Subscriptions_AggregateArgs = {
  distinct_on?: Maybe<Array<Feed_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Feed_Item_Order_By>>
  where?: Maybe<Feed_Item_Bool_Exp>
}

export type Subscription_RootFmtc_DealArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Subscription_RootFmtc_Deal_AggregateArgs = {
  distinct_on?: Maybe<Array<Fmtc_Deal_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Fmtc_Deal_Order_By>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Subscription_RootFmtc_Deal_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootFmtc_Deal_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Fmtc_Deal_Stream_Cursor_Input>>
  where?: Maybe<Fmtc_Deal_Bool_Exp>
}

export type Subscription_RootFollowable_ItemArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Subscription_RootFollowable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Order_By>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Subscription_RootFollowable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Followable_Item_Type_Enum
}

export type Subscription_RootFollowable_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Followable_Item_Stream_Cursor_Input>>
  where?: Maybe<Followable_Item_Bool_Exp>
}

export type Subscription_RootFollowable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Subscription_RootFollowable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Followable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Followable_Item_Type_Order_By>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Subscription_RootFollowable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootFollowable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Followable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Followable_Item_Type_Bool_Exp>
}

export type Subscription_RootLeaderboard_EventArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Order_By>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootLeaderboard_Event_NameArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Leaderboard_Event_Name_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Leaderboard_Event_Name_Order_By>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_Name_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootLeaderboard_Event_Name_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Leaderboard_Event_Name_Stream_Cursor_Input>>
  where?: Maybe<Leaderboard_Event_Name_Bool_Exp>
}

export type Subscription_RootLeaderboard_Event_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Leaderboard_Event_Stream_Cursor_Input>>
  where?: Maybe<Leaderboard_Event_Bool_Exp>
}

export type Subscription_RootLink_TypeArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Subscription_RootLink_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Link_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Link_Type_Order_By>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Subscription_RootLink_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootLink_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Link_Type_Stream_Cursor_Input>>
  where?: Maybe<Link_Type_Bool_Exp>
}

export type Subscription_RootMedia_Enabled_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Subscription_RootMedia_Enabled_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Enabled_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Enabled_Item_Order_By>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Subscription_RootMedia_Enabled_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Media_Item_Type_Enum
}

export type Subscription_RootMedia_Enabled_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Enabled_Item_Stream_Cursor_Input>>
  where?: Maybe<Media_Enabled_Item_Bool_Exp>
}

export type Subscription_RootMedia_ItemArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Subscription_RootMedia_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Order_By>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Subscription_RootMedia_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMedia_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Item_Stream_Cursor_Input>>
  where?: Maybe<Media_Item_Bool_Exp>
}

export type Subscription_RootMedia_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Subscription_RootMedia_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Item_Type_Order_By>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Subscription_RootMedia_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootMedia_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Media_Item_Type_Bool_Exp>
}

export type Subscription_RootMedia_ItemsArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Subscription_RootMedia_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Items_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Items_Order_By>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Subscription_RootMedia_Items_By_PkArgs = {
  media_enabled_item_id: Scalars['uuid']
  media_enabled_item_type: Scalars['String']
  media_id: Scalars['uuid']
}

export type Subscription_RootMedia_Items_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Items_Stream_Cursor_Input>>
  where?: Maybe<Media_Items_Bool_Exp>
}

export type Subscription_RootMedia_TypeArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Subscription_RootMedia_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Media_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Media_Type_Order_By>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Subscription_RootMedia_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootMedia_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Media_Type_Stream_Cursor_Input>>
  where?: Maybe<Media_Type_Bool_Exp>
}

export type Subscription_RootMention_Search_UsersArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootMention_Search_Users_AggregateArgs = {
  args: Mention_Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootMobile_App_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Subscription_RootMobile_App_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_App_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_App_Home_Order_By>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Subscription_RootMobile_App_Home_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootMobile_App_Home_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_App_Home_Stream_Cursor_Input>>
  where?: Maybe<Mobile_App_Home_Bool_Exp>
}

export type Subscription_RootMobile_HomeArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Subscription_RootMobile_Home_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Home_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Home_Order_By>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Subscription_RootMobile_Home_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMobile_Home_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_Home_Stream_Cursor_Input>>
  where?: Maybe<Mobile_Home_Bool_Exp>
}

export type Subscription_RootMobile_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootMobile_Push_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Mobile_Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Mobile_Push_Notification_Status_Order_By>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootMobile_Push_Notification_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_Push_Notification_Status_Stream_Cursor_Input>>
  where?: Maybe<Mobile_Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootMobile_Push_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Mobile_Push_Notification_Stream_Cursor_Input>>
  where?: Maybe<Mobile_Push_Notification_Bool_Exp>
}

export type Subscription_RootNotifiable_Feed_Item_UserArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Subscription_RootNotifiable_Feed_Item_User_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Feed_Item_User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Feed_Item_User_Order_By>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Subscription_RootNotifiable_Feed_Item_User_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notifiable_Feed_Item_User_Stream_Cursor_Input>>
  where?: Maybe<Notifiable_Feed_Item_User_Bool_Exp>
}

export type Subscription_RootNotifiable_ItemArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Order_By>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: Notifiable_Item_Type_Enum
}

export type Subscription_RootNotifiable_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notifiable_Item_Stream_Cursor_Input>>
  where?: Maybe<Notifiable_Item_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notifiable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notifiable_Item_Type_Order_By>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Subscription_RootNotifiable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootNotifiable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notifiable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Notifiable_Item_Type_Bool_Exp>
}

export type Subscription_RootNotificationArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Subscription_RootNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Order_By>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Center_Feed_For_UserArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Feed_For_User_AggregateArgs = {
  args: Notification_Center_Feed_For_User_Args
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_ItemArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Center_Item_Read_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Read_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_Read_Status_AggregateArgs =
  {
    distinct_on?: Maybe<
      Array<Notification_Center_Item_Read_Status_Select_Column>
    >
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Read_Status_Order_By>>
    where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
  }

export type Subscription_RootNotification_Center_Item_Read_Status_By_PkArgs = {
  notification_center_item_id: Scalars['uuid']
  user_id: Scalars['String']
}

export type Subscription_RootNotification_Center_Item_Read_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Center_Item_Read_Status_Stream_Cursor_Input>>
  where?: Maybe<Notification_Center_Item_Read_Status_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Center_Item_Stream_Cursor_Input>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Type_Order_By>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Subscription_RootNotification_Center_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootNotification_Center_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Center_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Notification_Center_Item_Type_Bool_Exp>
}

export type Subscription_RootNotification_PreferenceArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Subscription_RootNotification_Preference_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Subscription_RootNotification_Preference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Preference_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Preference_Stream_Cursor_Input>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

export type Subscription_RootNotification_StatusArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Subscription_RootNotification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Status_Order_By>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Subscription_RootNotification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootNotification_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Status_Stream_Cursor_Input>>
  where?: Maybe<Notification_Status_Bool_Exp>
}

export type Subscription_RootNotification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Notification_Stream_Cursor_Input>>
  where?: Maybe<Notification_Bool_Exp>
}

export type Subscription_RootOnboarding_CategoriesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Subscription_RootOnboarding_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Categories_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Categories_Order_By>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Subscription_RootOnboarding_Categories_By_PkArgs = {
  category: Shop_Category_Enum
}

export type Subscription_RootOnboarding_Categories_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Onboarding_Categories_Stream_Cursor_Input>>
  where?: Maybe<Onboarding_Categories_Bool_Exp>
}

export type Subscription_RootOnboarding_SourcesArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Subscription_RootOnboarding_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<Onboarding_Sources_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Onboarding_Sources_Order_By>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Subscription_RootOnboarding_Sources_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootOnboarding_Sources_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Onboarding_Sources_Stream_Cursor_Input>>
  where?: Maybe<Onboarding_Sources_Bool_Exp>
}

export type Subscription_RootOne_Off_EmailArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Subscription_RootOne_Off_Email_AggregateArgs = {
  distinct_on?: Maybe<Array<One_Off_Email_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<One_Off_Email_Order_By>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Subscription_RootOne_Off_Email_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootOne_Off_Email_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<One_Off_Email_Stream_Cursor_Input>>
  where?: Maybe<One_Off_Email_Bool_Exp>
}

export type Subscription_RootOther_Shops_For_Shop_PageArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootOther_Shops_For_Shop_Page_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootPageview_EventArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Subscription_RootPageview_Event_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Order_By>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Subscription_RootPageview_Event_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPageview_Event_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Pageview_Event_Stream_Cursor_Input>>
  where?: Maybe<Pageview_Event_Bool_Exp>
}

export type Subscription_RootPageview_Event_SummaryArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Subscription_RootPageview_Event_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<Pageview_Event_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Pageview_Event_Summary_Order_By>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Subscription_RootPageview_Event_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Pageview_Event_Summary_Stream_Cursor_Input>>
  where?: Maybe<Pageview_Event_Summary_Bool_Exp>
}

export type Subscription_RootPromo_PeriodArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Subscription_RootPromo_Period_AggregateArgs = {
  distinct_on?: Maybe<Array<Promo_Period_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Promo_Period_Order_By>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Subscription_RootPromo_Period_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPromo_Period_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Promo_Period_Stream_Cursor_Input>>
  where?: Maybe<Promo_Period_Bool_Exp>
}

export type Subscription_RootPush_NotificationArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Subscription_RootPush_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Order_By>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Subscription_RootPush_Notification_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootPush_Notification_Status_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootPush_Notification_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Push_Notification_Status_Stream_Cursor_Input>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

export type Subscription_RootPush_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Push_Notification_Stream_Cursor_Input>>
  where?: Maybe<Push_Notification_Bool_Exp>
}

export type Subscription_RootRandom_Discovery_Feed_ItemsArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootRandom_Discovery_Feed_Items_AggregateArgs = {
  args?: Maybe<Random_Discovery_Feed_Items_Args>
  distinct_on?: Maybe<Array<Discovery_Feed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Discovery_Feed_Order_By>>
  where?: Maybe<Discovery_Feed_Bool_Exp>
}

export type Subscription_RootRecommended_Shops_By_UserArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRecommended_Shops_By_User_AggregateArgs = {
  args: Recommended_Shops_By_User_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRedirect_IdsArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Subscription_RootRedirect_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Redirect_Ids_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Redirect_Ids_Order_By>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Subscription_RootRedirect_Ids_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootRedirect_Ids_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Redirect_Ids_Stream_Cursor_Input>>
  where?: Maybe<Redirect_Ids_Bool_Exp>
}

export type Subscription_RootRelated_Shops_By_CategoryArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRelated_Shops_By_Category_AggregateArgs = {
  args: Related_Shops_By_Category_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootRelated_Users_For_Shop_ItemArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootRelated_Users_For_Shop_Item_AggregateArgs = {
  args: Related_Users_For_Shop_Item_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootRemove_ReasonArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Subscription_RootRemove_Reason_AggregateArgs = {
  distinct_on?: Maybe<Array<Remove_Reason_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Remove_Reason_Order_By>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Subscription_RootRemove_Reason_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootRemove_Reason_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Remove_Reason_Stream_Cursor_Input>>
  where?: Maybe<Remove_Reason_Bool_Exp>
}

export type Subscription_RootRewardable_Action_NamesArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Subscription_RootRewardable_Action_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Action_Names_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Action_Names_Order_By>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Subscription_RootRewardable_Action_Names_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootRewardable_Action_Names_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Rewardable_Action_Names_Stream_Cursor_Input>>
  where?: Maybe<Rewardable_Action_Names_Bool_Exp>
}

export type Subscription_RootRewardable_Item_TypeArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Subscription_RootRewardable_Item_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Rewardable_Item_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Rewardable_Item_Type_Order_By>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Subscription_RootRewardable_Item_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootRewardable_Item_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Rewardable_Item_Type_Stream_Cursor_Input>>
  where?: Maybe<Rewardable_Item_Type_Bool_Exp>
}

export type Subscription_RootSearch_QueryArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Subscription_RootSearch_Query_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Order_By>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Subscription_RootSearch_Query_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSearch_Query_ResultArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Subscription_RootSearch_Query_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Search_Query_Result_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Search_Query_Result_Order_By>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Subscription_RootSearch_Query_Result_By_PkArgs = {
  search_query_id: Scalars['uuid']
  shop_item_id: Scalars['uuid']
}

export type Subscription_RootSearch_Query_Result_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Search_Query_Result_Stream_Cursor_Input>>
  where?: Maybe<Search_Query_Result_Bool_Exp>
}

export type Subscription_RootSearch_Query_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Search_Query_Stream_Cursor_Input>>
  where?: Maybe<Search_Query_Bool_Exp>
}

export type Subscription_RootSearch_Shop_ItemsArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootSearch_Shop_Items_AggregateArgs = {
  args: Search_Shop_Items_Args
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootSearch_ShopsArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootSearch_Shops_AggregateArgs = {
  args: Search_Shops_Args
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootSearch_UsersArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootSearch_Users_AggregateArgs = {
  args: Search_Users_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootShopArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootShop_AffiliateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Subscription_RootShop_Affiliate_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Affiliate_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Affiliate_Order_By>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Subscription_RootShop_Affiliate_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Affiliate_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Affiliate_Stream_Cursor_Input>>
  where?: Maybe<Shop_Affiliate_Bool_Exp>
}

export type Subscription_RootShop_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Order_By>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootShop_By_PkArgs = {
  key: Scalars['String']
}

export type Subscription_RootShop_CategorizationArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Subscription_RootShop_Categorization_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorization_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorization_Order_By>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Subscription_RootShop_Categorization_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Categorization_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Categorization_Stream_Cursor_Input>>
  where?: Maybe<Shop_Categorization_Bool_Exp>
}

export type Subscription_RootShop_Categorized_DealsArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Subscription_RootShop_Categorized_Deals_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Categorized_Deals_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Categorized_Deals_Order_By>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Subscription_RootShop_Categorized_Deals_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Categorized_Deals_Stream_Cursor_Input>>
  where?: Maybe<Shop_Categorized_Deals_Bool_Exp>
}

export type Subscription_RootShop_CategoryArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Subscription_RootShop_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Category_Order_By>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Subscription_RootShop_Category_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootShop_Category_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Category_Stream_Cursor_Input>>
  where?: Maybe<Shop_Category_Bool_Exp>
}

export type Subscription_RootShop_CollectionsArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootShop_Collections_AggregateArgs = {
  args: Shop_Collections_Args
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootShop_ItemArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootShop_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Order_By>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootShop_Item_AvailabilityArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Subscription_RootShop_Item_Availability_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Availability_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Availability_Order_By>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Subscription_RootShop_Item_Availability_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootShop_Item_Availability_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Availability_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Availability_Bool_Exp>
}

export type Subscription_RootShop_Item_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_Enrichment_StatusArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Subscription_RootShop_Item_Enrichment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Enrichment_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Enrichment_Status_Order_By>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Subscription_RootShop_Item_Enrichment_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootShop_Item_Enrichment_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Enrichment_Status_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Enrichment_Status_Bool_Exp>
}

export type Subscription_RootShop_Item_HistoryArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Subscription_RootShop_Item_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_History_Order_By>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Subscription_RootShop_Item_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_History_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_History_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_History_Bool_Exp>
}

export type Subscription_RootShop_Item_ImageArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Subscription_RootShop_Item_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Image_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Image_Order_By>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Subscription_RootShop_Item_Image_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_Image_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Image_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Image_Bool_Exp>
}

export type Subscription_RootShop_Item_IndexArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Subscription_RootShop_Item_Index_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_Index_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_Index_Order_By>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Subscription_RootShop_Item_Index_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Item_Index_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Index_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Index_Bool_Exp>
}

export type Subscription_RootShop_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_Bool_Exp>
}

export type Subscription_RootShop_Item_To_Be_ScrapedArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Subscription_RootShop_Item_To_Be_Scraped_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Item_To_Be_Scraped_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Item_To_Be_Scraped_Order_By>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Subscription_RootShop_Item_To_Be_Scraped_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Item_To_Be_Scraped_Stream_Cursor_Input>>
  where?: Maybe<Shop_Item_To_Be_Scraped_Bool_Exp>
}

export type Subscription_RootShop_PopularityArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Subscription_RootShop_Popularity_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_Order_By>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Subscription_RootShop_Popularity_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Popularity_Stream_Cursor_Input>>
  where?: Maybe<Shop_Popularity_Bool_Exp>
}

export type Subscription_RootShop_Popularity_With_PointsArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Subscription_RootShop_Popularity_With_Points_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Popularity_With_Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Popularity_With_Points_Order_By>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Subscription_RootShop_Popularity_With_Points_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Popularity_With_Points_Stream_Cursor_Input>>
  where?: Maybe<Shop_Popularity_With_Points_Bool_Exp>
}

export type Subscription_RootShop_RatingArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Subscription_RootShop_Rating_AggregateArgs = {
  distinct_on?: Maybe<Array<Shop_Rating_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Shop_Rating_Order_By>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Subscription_RootShop_Rating_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootShop_Rating_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Rating_Stream_Cursor_Input>>
  where?: Maybe<Shop_Rating_Bool_Exp>
}

export type Subscription_RootShop_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Shop_Stream_Cursor_Input>>
  where?: Maybe<Shop_Bool_Exp>
}

export type Subscription_RootSimilar_Products_HistoryArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootSimilar_Products_History_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Similar_Products_History_Stream_Cursor_Input>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_UniqueArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSimilar_Products_History_Unique_AggregateArgs = {
  args: Similar_Products_History_Unique_Args
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

export type Subscription_RootSingle_Unread_Notification_Banner_Item_For_UserArgs =
  {
    args: Single_Unread_Notification_Banner_Item_For_User_Args
    distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
    where?: Maybe<Notification_Center_Item_Bool_Exp>
  }

export type Subscription_RootSingle_Unread_Notification_Banner_Item_For_User_AggregateArgs =
  {
    args: Single_Unread_Notification_Banner_Item_For_User_Args
    distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
    limit?: Maybe<Scalars['Int']>
    offset?: Maybe<Scalars['Int']>
    order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
    where?: Maybe<Notification_Center_Item_Bool_Exp>
  }

export type Subscription_RootSteal_Their_LooksArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootSteal_Their_Looks_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootSteal_Their_Looks_RandomArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootSteal_Their_Looks_Random_AggregateArgs = {
  args?: Maybe<Steal_Their_Looks_Random_Args>
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

export type Subscription_RootUsed_Creator_RedirectArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUsed_Creator_Redirect_AggregateArgs = {
  args: Used_Creator_Redirect_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_Account_TypeArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Subscription_RootUser_Account_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Account_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Account_Type_Order_By>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Subscription_RootUser_Account_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Account_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Account_Type_Stream_Cursor_Input>>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

export type Subscription_RootUser_ActionArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Subscription_RootUser_Action_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Action_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Action_Order_By>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Subscription_RootUser_Action_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Action_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Action_Stream_Cursor_Input>>
  where?: Maybe<User_Action_Bool_Exp>
}

export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_App_VersionArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Subscription_RootUser_App_Version_AggregateArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Subscription_RootUser_App_Version_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_App_Version_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_App_Version_Stream_Cursor_Input>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['String']
}

export type Subscription_RootUser_Carts_OnboardingArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Subscription_RootUser_Carts_Onboarding_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Subscription_RootUser_Carts_Onboarding_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Carts_Onboarding_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Carts_Onboarding_Stream_Cursor_Input>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

export type Subscription_RootUser_CategoryArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Subscription_RootUser_Category_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Subscription_RootUser_Category_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Category_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Category_Stream_Cursor_Input>>
  where?: Maybe<User_Category_Bool_Exp>
}

export type Subscription_RootUser_DeletionArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Subscription_RootUser_Deletion_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Deletion_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Deletion_Order_By>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Subscription_RootUser_Deletion_By_PkArgs = {
  user_id: Scalars['String']
}

export type Subscription_RootUser_Deletion_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Deletion_Stream_Cursor_Input>>
  where?: Maybe<User_Deletion_Bool_Exp>
}

export type Subscription_RootUser_FeedbackArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Subscription_RootUser_Feedback_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Order_By>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Subscription_RootUser_Feedback_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Feedback_ItemArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Item_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Item_Order_By>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Item_By_PkArgs = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

export type Subscription_RootUser_Feedback_Item_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Feedback_Item_Stream_Cursor_Input>>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

export type Subscription_RootUser_Feedback_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Feedback_Stream_Cursor_Input>>
  where?: Maybe<User_Feedback_Bool_Exp>
}

export type Subscription_RootUser_Feedback_TypeArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Feedback_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Feedback_Type_Order_By>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Subscription_RootUser_Feedback_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Feedback_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Feedback_Type_Stream_Cursor_Input>>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

export type Subscription_RootUser_FlagsArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Subscription_RootUser_Flags_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Flags_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Flags_Order_By>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Subscription_RootUser_Flags_By_PkArgs = {
  user_id: Scalars['String']
}

export type Subscription_RootUser_Flags_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Flags_Stream_Cursor_Input>>
  where?: Maybe<User_Flags_Bool_Exp>
}

export type Subscription_RootUser_ImpersonationArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Subscription_RootUser_Impersonation_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Impersonation_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Impersonation_Order_By>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Subscription_RootUser_Impersonation_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Impersonation_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Impersonation_Stream_Cursor_Input>>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

export type Subscription_RootUser_Ip_AddressArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Subscription_RootUser_Ip_Address_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Subscription_RootUser_Ip_Address_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Ip_Address_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Ip_Address_Stream_Cursor_Input>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

export type Subscription_RootUser_Items_FollowedArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Subscription_RootUser_Items_Followed_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Subscription_RootUser_Items_Followed_By_PkArgs = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

export type Subscription_RootUser_Items_Followed_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Items_Followed_Stream_Cursor_Input>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

export type Subscription_RootUser_LinksArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Subscription_RootUser_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Subscription_RootUser_Links_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Links_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Links_Stream_Cursor_Input>>
  where?: Maybe<User_Links_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_SourceArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_Source_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_Source_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Onboarding_Source_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Onboarding_Source_Stream_Cursor_Input>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_StateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_State_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_State_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_State_Order_By>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Subscription_RootUser_Onboarding_State_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Onboarding_State_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Onboarding_State_Stream_Cursor_Input>>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

export type Subscription_RootUser_Phone_ContactArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Subscription_RootUser_Phone_Contact_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Phone_Contact_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Phone_Contact_Order_By>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Subscription_RootUser_Phone_Contact_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Phone_Contact_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Phone_Contact_Stream_Cursor_Input>>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

export type Subscription_RootUser_Points_Redeemed_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Redeemed_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Redeemed_Summary_Order_By>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Redeemed_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Points_Redeemed_Summary_Stream_Cursor_Input>>
  where?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_SummaryArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Summary_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Points_Summary_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Points_Summary_Order_By>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Subscription_RootUser_Points_Summary_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Points_Summary_Stream_Cursor_Input>>
  where?: Maybe<User_Points_Summary_Bool_Exp>
}

export type Subscription_RootUser_Purchase_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Purchase_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchase_Subscription_Order_By>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Purchase_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Purchase_Subscription_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Purchase_Subscription_Stream_Cursor_Input>>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

export type Subscription_RootUser_PurchasesArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Subscription_RootUser_Purchases_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Purchases_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Purchases_Order_By>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Subscription_RootUser_Purchases_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Purchases_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Purchases_Stream_Cursor_Input>>
  where?: Maybe<User_Purchases_Bool_Exp>
}

export type Subscription_RootUser_Push_NotificationArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Order_By>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Push_Notification_Stream_Cursor_Input>>
  where?: Maybe<User_Push_Notification_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_TokenArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Subscription_RootUser_Push_Notification_Token_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Push_Notification_Token_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Push_Notification_Token_Stream_Cursor_Input>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

export type Subscription_RootUser_ReferralArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Subscription_RootUser_Referral_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Subscription_RootUser_Referral_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Referral_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Referral_Stream_Cursor_Input>>
  where?: Maybe<User_Referral_Bool_Exp>
}

export type Subscription_RootUser_Reward_Multiplier_ConfigArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Subscription_RootUser_Reward_Multiplier_Config_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Multiplier_Config_Order_By>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Subscription_RootUser_Reward_Multiplier_Config_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Reward_Multiplier_Config_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Reward_Multiplier_Config_Stream_Cursor_Input>>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

export type Subscription_RootUser_Reward_RedemptionArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Subscription_RootUser_Reward_Redemption_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Redemption_Order_By>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Subscription_RootUser_Reward_Redemption_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Reward_Redemption_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Reward_Redemption_Stream_Cursor_Input>>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

export type Subscription_RootUser_Reward_ReferenceArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Subscription_RootUser_Reward_Reference_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Subscription_RootUser_Reward_Reference_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Reward_Reference_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Reward_Reference_Stream_Cursor_Input>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

export type Subscription_RootUser_Shop_FavoriteArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Subscription_RootUser_Shop_Favorite_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shop_Favorite_Order_By>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Subscription_RootUser_Shop_Favorite_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Shop_Favorite_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Shop_Favorite_Stream_Cursor_Input>>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

export type Subscription_RootUser_Shopping_PreferencesArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Subscription_RootUser_Shopping_Preferences_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Shopping_Preferences_Order_By>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Subscription_RootUser_Shopping_Preferences_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Shopping_Preferences_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Shopping_Preferences_Stream_Cursor_Input>>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

export type Subscription_RootUser_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Stream_Cursor_Input>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUser_SubscriptionArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Subscription_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Order_By>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Subscription_RootUser_Subscription_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Subscription_StatusArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Subscription_RootUser_Subscription_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Subscription_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Subscription_Status_Order_By>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Subscription_RootUser_Subscription_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootUser_Subscription_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Subscription_Status_Stream_Cursor_Input>>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

export type Subscription_RootUser_Subscription_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Subscription_Stream_Cursor_Input>>
  where?: Maybe<User_Subscription_Bool_Exp>
}

export type Subscription_RootUser_TempArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Subscription_RootUser_Temp_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Temp_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Temp_Order_By>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Subscription_RootUser_Temp_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootUser_Temp_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<User_Temp_Stream_Cursor_Input>>
  where?: Maybe<User_Temp_Bool_Exp>
}

export type Subscription_RootUsers_SearchArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootUsers_Search_AggregateArgs = {
  args: Users_Search_Args
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

export type Subscription_RootWorflow_TypeArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Subscription_RootWorflow_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Worflow_Type_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Worflow_Type_Order_By>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Subscription_RootWorflow_Type_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootWorflow_Type_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Worflow_Type_Stream_Cursor_Input>>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

export type Subscription_RootWorkflowArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Subscription_RootWorkflow_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

export type Subscription_RootWorkflow_By_PkArgs = {
  id: Scalars['uuid']
}

export type Subscription_RootWorkflow_StatusArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Subscription_RootWorkflow_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Status_Order_By>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Subscription_RootWorkflow_Status_By_PkArgs = {
  value: Scalars['String']
}

export type Subscription_RootWorkflow_Status_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Workflow_Status_Stream_Cursor_Input>>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

export type Subscription_RootWorkflow_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<Maybe<Workflow_Stream_Cursor_Input>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>
  _gt?: Maybe<Scalars['timestamp']>
  _gte?: Maybe<Scalars['timestamp']>
  _in?: Maybe<Array<Scalars['timestamp']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamp']>
  _lte?: Maybe<Scalars['timestamp']>
  _neq?: Maybe<Scalars['timestamp']>
  _nin?: Maybe<Array<Scalars['timestamp']>>
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>
  _gt?: Maybe<Scalars['timestamptz']>
  _gte?: Maybe<Scalars['timestamptz']>
  _in?: Maybe<Array<Scalars['timestamptz']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamptz']>
  _lte?: Maybe<Scalars['timestamptz']>
  _neq?: Maybe<Scalars['timestamptz']>
  _nin?: Maybe<Array<Scalars['timestamptz']>>
}

export type Update_Last_Seen_Notification_Center_Item_Sequential_Id_Args = {
  user_id?: Maybe<Scalars['String']>
}

export type Upsert_Notification_Preference_Args = {
  allowed?: Maybe<Scalars['Boolean']>
  allowed_communication?: Maybe<Scalars['_text']>
  communication_template?: Maybe<Scalars['String']>
  notifiable_id?: Maybe<Scalars['uuid']>
  notifiable_type?: Maybe<Scalars['String']>
}

export type Used_Creator_Redirect_Args = {
  current_shop_key?: Maybe<Scalars['String']>
}

/** columns and relationships of "user" */
export type User = {
  __typename?: 'user'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** An array relationship */
  affiliate_transactions: Array<Affiliate_Transaction>
  /** An aggregate relationship */
  affiliate_transactions_aggregate: Affiliate_Transaction_Aggregate
  anonymous?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  app_versions: Array<User_App_Version>
  /** An aggregate relationship */
  app_versions_aggregate: User_App_Version_Aggregate
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  /** Computed field on `user` to determine if the user can be credited for a referral (ie, extensions are installed, etc). */
  can_be_credited?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  cart_item_likes: Array<Cart_Item_Like>
  /** An aggregate relationship */
  cart_item_likes_aggregate: Cart_Item_Like_Aggregate
  /** An array relationship */
  carts: Array<Cart>
  /** An aggregate relationship */
  carts_aggregate: Cart_Aggregate
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** An array relationship */
  collection_item_claims: Array<Collection_Item_Claim>
  /** An aggregate relationship */
  collection_item_claims_aggregate: Collection_Item_Claim_Aggregate
  /** An array relationship */
  collections: Array<Collection>
  /** An aggregate relationship */
  collections_aggregate: Collection_Aggregate
  /** An array relationship */
  collections_collaborating: Array<Collection_Collaborator>
  /** An aggregate relationship */
  collections_collaborating_aggregate: Collection_Collaborator_Aggregate
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_display_name" */
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_email_md5_hash" */
  email_md5?: Maybe<Scalars['String']>
  /** A computed field, executes function "extension_version" */
  extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_first_app_install" */
  first_app_install?: Maybe<Scalars['String']>
  /** An object relationship */
  flags?: Maybe<User_Flags>
  /** An array relationship */
  follows: Array<User_Items_Followed>
  /** An aggregate relationship */
  follows_aggregate: User_Items_Followed_Aggregate
  /** Computed field on `user` to calculate if the user has an `^accounts+foo@addtocarrot.com` email. */
  has_accounts_email_computed?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "user_has_billing_account" */
  has_billing_account?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "has_claimed_username_computed" */
  has_claimed_username_computed?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` to calculate if the user has an email set. */
  has_email_set?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` that returns whether the user has onboarded. */
  has_onboarded?: Maybe<Scalars['Boolean']>
  /** A computed field on a `user` that is a shorthand for determining whether a user has set their porfile picture. */
  has_picture_set?: Maybe<Scalars['Boolean']>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  /** A computed field on a `user` that is a shorthand for determining whether a user has set their name. */
  has_set_name_computed?: Maybe<Scalars['Boolean']>
  icon_order: Scalars['numeric']
  id: Scalars['String']
  in_on_campus_contest?: Maybe<Scalars['Boolean']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  ios_version?: Maybe<Scalars['String']>
  /** Computed field on `user` to calculate if the user is a "creator" */
  is_creator_computed?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` to calculate if the user is a "curator" */
  is_curator_computed?: Maybe<Scalars['Boolean']>
  /** Computed field on `user` to calculate if the user is an "icon". */
  is_icon_computed?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  links: Array<User_Links>
  /** An aggregate relationship */
  links_aggregate: User_Links_Aggregate
  metadata?: Maybe<Scalars['jsonb']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** An array relationship */
  notification_center_items: Array<Notification_Center_Item>
  /** An aggregate relationship */
  notification_center_items_aggregate: Notification_Center_Item_Aggregate
  /** An array relationship */
  notification_preferences: Array<Notification_Preference>
  /** An aggregate relationship */
  notification_preferences_aggregate: Notification_Preference_Aggregate
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  /** An object relationship */
  orphan_parent?: Maybe<User>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  /** An array relationship */
  orphans: Array<User>
  /** An aggregate relationship */
  orphans_aggregate: User_Aggregate
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  probably_real?: Maybe<Scalars['Boolean']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** An array relationship */
  referred_users: Array<User>
  /** An aggregate relationship */
  referred_users_aggregate: User_Aggregate
  /** An object relationship */
  referrer_user?: Maybe<User>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  safari_extension_version?: Maybe<Scalars['String']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id: Scalars['name']
  show_curations: Scalars['Boolean']
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole: Scalars['Boolean']
  /** An array relationship */
  similar_products_histories: Array<Similar_Products_History>
  /** An aggregate relationship */
  similar_products_histories_aggregate: Similar_Products_History_Aggregate
  /** A computed field, executes function "user_subscription_status" */
  subscription_status?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** An object relationship */
  user_account_type?: Maybe<User_Account_Type>
  /** An array relationship */
  user_carts_onboardings: Array<User_Carts_Onboarding>
  /** An aggregate relationship */
  user_carts_onboardings_aggregate: User_Carts_Onboarding_Aggregate
  /** An array relationship */
  user_categories: Array<User_Category>
  /** An aggregate relationship */
  user_categories_aggregate: User_Category_Aggregate
  /** An array relationship */
  user_ip_addresses: Array<User_Ip_Address>
  /** An aggregate relationship */
  user_ip_addresses_aggregate: User_Ip_Address_Aggregate
  /** An array relationship */
  user_onboarding_sources: Array<User_Onboarding_Source>
  /** An aggregate relationship */
  user_onboarding_sources_aggregate: User_Onboarding_Source_Aggregate
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
  /** An array relationship */
  user_push_notification_tokens: Array<User_Push_Notification_Token>
  /** An aggregate relationship */
  user_push_notification_tokens_aggregate: User_Push_Notification_Token_Aggregate
  /** An array relationship */
  user_referrals: Array<User_Referral>
  /** An aggregate relationship */
  user_referrals_aggregate: User_Referral_Aggregate
  /** An object relationship */
  user_shopping_preference?: Maybe<User_Shopping_Preferences>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
  /** An array relationship */
  workflows: Array<Workflow>
  /** An aggregate relationship */
  workflows_aggregate: Workflow_Aggregate
}

/** columns and relationships of "user" */
export type UserAffiliate_TransactionsArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserAffiliate_Transactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Affiliate_Transaction_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Affiliate_Transaction_Order_By>>
  where?: Maybe<Affiliate_Transaction_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserApp_VersionsArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserApp_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<User_App_Version_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_App_Version_Order_By>>
  where?: Maybe<User_App_Version_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCart_Item_LikesArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCart_Item_Likes_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Item_Like_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Item_Like_Order_By>>
  where?: Maybe<Cart_Item_Like_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCartsArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCarts_AggregateArgs = {
  distinct_on?: Maybe<Array<Cart_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Cart_Order_By>>
  where?: Maybe<Cart_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollection_Item_ClaimsArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollection_Item_Claims_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Item_Claim_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Item_Claim_Order_By>>
  where?: Maybe<Collection_Item_Claim_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollectionsArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollections_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Order_By>>
  where?: Maybe<Collection_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollections_CollaboratingArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserCollections_Collaborating_AggregateArgs = {
  distinct_on?: Maybe<Array<Collection_Collaborator_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Collection_Collaborator_Order_By>>
  where?: Maybe<Collection_Collaborator_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserFollowsArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserFollows_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Items_Followed_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Items_Followed_Order_By>>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserLinksArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserLinks_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Links_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Links_Order_By>>
  where?: Maybe<User_Links_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user" */
export type UserNotification_Center_ItemsArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserNotification_Center_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Center_Item_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Center_Item_Order_By>>
  where?: Maybe<Notification_Center_Item_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserNotification_PreferencesArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserNotification_Preferences_AggregateArgs = {
  distinct_on?: Maybe<Array<Notification_Preference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Notification_Preference_Order_By>>
  where?: Maybe<Notification_Preference_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserOrphansArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserOrphans_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserReferred_UsersArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserReferred_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Order_By>>
  where?: Maybe<User_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserSimilar_Products_HistoriesArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserSimilar_Products_Histories_AggregateArgs = {
  distinct_on?: Maybe<Array<Similar_Products_History_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Similar_Products_History_Order_By>>
  where?: Maybe<Similar_Products_History_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Carts_OnboardingsArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Carts_Onboardings_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Carts_Onboarding_Order_By>>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_CategoriesArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Categories_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Category_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Category_Order_By>>
  where?: Maybe<User_Category_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Ip_AddressesArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Ip_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Ip_Address_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Ip_Address_Order_By>>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Onboarding_SourcesArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Onboarding_Sources_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Onboarding_Source_Order_By>>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Push_Notification_TokensArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Push_Notification_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Push_Notification_Token_Order_By>>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_ReferralsArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserUser_Referrals_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Referral_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Referral_Order_By>>
  where?: Maybe<User_Referral_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserWorkflowsArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** columns and relationships of "user" */
export type UserWorkflows_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** User account type enum table */
export type User_Account_Type = {
  __typename?: 'user_account_type'
  description: Scalars['String']
  value: Scalars['String']
}

/** aggregated selection of "user_account_type" */
export type User_Account_Type_Aggregate = {
  __typename?: 'user_account_type_aggregate'
  aggregate?: Maybe<User_Account_Type_Aggregate_Fields>
  nodes: Array<User_Account_Type>
}

/** aggregate fields of "user_account_type" */
export type User_Account_Type_Aggregate_Fields = {
  __typename?: 'user_account_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Account_Type_Max_Fields>
  min?: Maybe<User_Account_Type_Min_Fields>
}

/** aggregate fields of "user_account_type" */
export type User_Account_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Account_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_account_type". All fields are combined with a logical 'AND'. */
export type User_Account_Type_Bool_Exp = {
  _and?: Maybe<Array<User_Account_Type_Bool_Exp>>
  _not?: Maybe<User_Account_Type_Bool_Exp>
  _or?: Maybe<Array<User_Account_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_account_type" */
export enum User_Account_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountTypePkey = 'account_type_pkey',
}

export enum User_Account_Type_Enum {
  /** Creator account type */
  Creator = 'creator',
  /** Curator user type */
  Curator = 'curator',
  /** Regular user account */
  Regular = 'regular',
}

/** Boolean expression to compare columns of type "user_account_type_enum". All fields are combined with logical 'AND'. */
export type User_Account_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Account_Type_Enum>
  _in?: Maybe<Array<User_Account_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Account_Type_Enum>
  _nin?: Maybe<Array<User_Account_Type_Enum>>
}

/** input type for inserting data into table "user_account_type" */
export type User_Account_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Account_Type_Max_Fields = {
  __typename?: 'user_account_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Account_Type_Min_Fields = {
  __typename?: 'user_account_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_account_type" */
export type User_Account_Type_Mutation_Response = {
  __typename?: 'user_account_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Account_Type>
}

/** input type for inserting object relation for remote table "user_account_type" */
export type User_Account_Type_Obj_Rel_Insert_Input = {
  data: User_Account_Type_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Account_Type_On_Conflict>
}

/** on_conflict condition type for table "user_account_type" */
export type User_Account_Type_On_Conflict = {
  constraint: User_Account_Type_Constraint
  update_columns?: Array<User_Account_Type_Update_Column>
  where?: Maybe<User_Account_Type_Bool_Exp>
}

/** Ordering options when selecting data from "user_account_type". */
export type User_Account_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_account_type */
export type User_Account_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_account_type" */
export enum User_Account_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_account_type" */
export type User_Account_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_account_type" */
export type User_Account_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Account_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Account_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_account_type" */
export enum User_Account_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Account_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Account_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Account_Type_Bool_Exp
}

/** Table to keep track of rewardable user actions */
export type User_Action = {
  __typename?: 'user_action'
  action: Rewardable_Action_Names_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  multiplier: Scalars['numeric']
  pending: Scalars['Boolean']
  points: Scalars['numeric']
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_action" */
export type User_Action_Aggregate = {
  __typename?: 'user_action_aggregate'
  aggregate?: Maybe<User_Action_Aggregate_Fields>
  nodes: Array<User_Action>
}

/** aggregate fields of "user_action" */
export type User_Action_Aggregate_Fields = {
  __typename?: 'user_action_aggregate_fields'
  avg?: Maybe<User_Action_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Action_Max_Fields>
  min?: Maybe<User_Action_Min_Fields>
  stddev?: Maybe<User_Action_Stddev_Fields>
  stddev_pop?: Maybe<User_Action_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Action_Stddev_Samp_Fields>
  sum?: Maybe<User_Action_Sum_Fields>
  var_pop?: Maybe<User_Action_Var_Pop_Fields>
  var_samp?: Maybe<User_Action_Var_Samp_Fields>
  variance?: Maybe<User_Action_Variance_Fields>
}

/** aggregate fields of "user_action" */
export type User_Action_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Action_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Action_Avg_Fields = {
  __typename?: 'user_action_avg_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_action". All fields are combined with a logical 'AND'. */
export type User_Action_Bool_Exp = {
  _and?: Maybe<Array<User_Action_Bool_Exp>>
  _not?: Maybe<User_Action_Bool_Exp>
  _or?: Maybe<Array<User_Action_Bool_Exp>>
  action?: Maybe<Rewardable_Action_Names_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  multiplier?: Maybe<Numeric_Comparison_Exp>
  pending?: Maybe<Boolean_Comparison_Exp>
  points?: Maybe<Numeric_Comparison_Exp>
  reference_id?: Maybe<String_Comparison_Exp>
  reference_type?: Maybe<Rewardable_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_action" */
export enum User_Action_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserActionPkey = 'user_action_pkey',
  /** unique or primary key constraint on columns "action", "user_id", "reference_type", "points", "reference_id" */
  UserActionUserIdActionReferenceTypeReferenceIdPointsK = 'user_action_user_id_action_reference_type_reference_id_points_k',
  /** unique or primary key constraint on columns "action", "user_id", "reference_type", "reference_id" */
  UserActionUserIdActionRewardableItemTypeRewardableItem = 'user_action_user_id_action_rewardable_item_type_rewardable_item',
}

/** input type for incrementing numeric columns in table "user_action" */
export type User_Action_Inc_Input = {
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_action" */
export type User_Action_Insert_Input = {
  action?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  pending?: Maybe<Scalars['Boolean']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Action_Max_Fields = {
  __typename?: 'user_action_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Action_Min_Fields = {
  __typename?: 'user_action_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_action" */
export type User_Action_Mutation_Response = {
  __typename?: 'user_action_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Action>
}

/** on_conflict condition type for table "user_action" */
export type User_Action_On_Conflict = {
  constraint: User_Action_Constraint
  update_columns?: Array<User_Action_Update_Column>
  where?: Maybe<User_Action_Bool_Exp>
}

/** Ordering options when selecting data from "user_action". */
export type User_Action_Order_By = {
  action?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  multiplier?: Maybe<Order_By>
  pending?: Maybe<Order_By>
  points?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  reference_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_action */
export type User_Action_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_action" */
export enum User_Action_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Pending = 'pending',
  /** column name */
  Points = 'points',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  ReferenceType = 'reference_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_action" */
export type User_Action_Set_Input = {
  action?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  pending?: Maybe<Scalars['Boolean']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Action_Stddev_Fields = {
  __typename?: 'user_action_stddev_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Action_Stddev_Pop_Fields = {
  __typename?: 'user_action_stddev_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Action_Stddev_Samp_Fields = {
  __typename?: 'user_action_stddev_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_action" */
export type User_Action_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Action_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Action_Stream_Cursor_Value_Input = {
  action?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  pending?: Maybe<Scalars['Boolean']>
  points?: Maybe<Scalars['numeric']>
  reference_id?: Maybe<Scalars['String']>
  reference_type?: Maybe<Rewardable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Action_Sum_Fields = {
  __typename?: 'user_action_sum_fields'
  multiplier?: Maybe<Scalars['numeric']>
  points?: Maybe<Scalars['numeric']>
}

/** update columns of table "user_action" */
export enum User_Action_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  Pending = 'pending',
  /** column name */
  Points = 'points',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  ReferenceType = 'reference_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Action_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Action_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Action_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Action_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Action_Var_Pop_Fields = {
  __typename?: 'user_action_var_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Action_Var_Samp_Fields = {
  __typename?: 'user_action_var_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Action_Variance_Fields = {
  __typename?: 'user_action_variance_fields'
  multiplier?: Maybe<Scalars['Float']>
  points?: Maybe<Scalars['Float']>
}

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate'
  aggregate?: Maybe<User_Aggregate_Fields>
  nodes: Array<User>
}

export type User_Aggregate_Bool_Exp = {
  bool_and?: Maybe<User_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<User_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<User_Aggregate_Bool_Exp_Count>
}

export type User_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields'
  avg?: Maybe<User_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Max_Fields>
  min?: Maybe<User_Min_Fields>
  stddev?: Maybe<User_Stddev_Fields>
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>
  sum?: Maybe<User_Sum_Fields>
  var_pop?: Maybe<User_Var_Pop_Fields>
  var_samp?: Maybe<User_Var_Samp_Fields>
  variance?: Maybe<User_Variance_Fields>
}

/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  avg?: Maybe<User_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Max_Order_By>
  min?: Maybe<User_Min_Order_By>
  stddev?: Maybe<User_Stddev_Order_By>
  stddev_pop?: Maybe<User_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Stddev_Samp_Order_By>
  sum?: Maybe<User_Sum_Order_By>
  var_pop?: Maybe<User_Var_Pop_Order_By>
  var_samp?: Maybe<User_Var_Samp_Order_By>
  variance?: Maybe<User_Variance_Order_By>
}

/** A table to keep track of what versions that our users are on for our apps */
export type User_App_Version = {
  __typename?: 'user_app_version'
  build_number: Scalars['numeric']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  name: Scalars['String']
  permissions?: Maybe<Scalars['jsonb']>
  platform: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
  version: Scalars['String']
}

/** A table to keep track of what versions that our users are on for our apps */
export type User_App_VersionPermissionsArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_app_version" */
export type User_App_Version_Aggregate = {
  __typename?: 'user_app_version_aggregate'
  aggregate?: Maybe<User_App_Version_Aggregate_Fields>
  nodes: Array<User_App_Version>
}

export type User_App_Version_Aggregate_Bool_Exp = {
  count?: Maybe<User_App_Version_Aggregate_Bool_Exp_Count>
}

export type User_App_Version_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_App_Version_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_App_Version_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_app_version" */
export type User_App_Version_Aggregate_Fields = {
  __typename?: 'user_app_version_aggregate_fields'
  avg?: Maybe<User_App_Version_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_App_Version_Max_Fields>
  min?: Maybe<User_App_Version_Min_Fields>
  stddev?: Maybe<User_App_Version_Stddev_Fields>
  stddev_pop?: Maybe<User_App_Version_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_App_Version_Stddev_Samp_Fields>
  sum?: Maybe<User_App_Version_Sum_Fields>
  var_pop?: Maybe<User_App_Version_Var_Pop_Fields>
  var_samp?: Maybe<User_App_Version_Var_Samp_Fields>
  variance?: Maybe<User_App_Version_Variance_Fields>
}

/** aggregate fields of "user_app_version" */
export type User_App_Version_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_App_Version_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_app_version" */
export type User_App_Version_Aggregate_Order_By = {
  avg?: Maybe<User_App_Version_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_App_Version_Max_Order_By>
  min?: Maybe<User_App_Version_Min_Order_By>
  stddev?: Maybe<User_App_Version_Stddev_Order_By>
  stddev_pop?: Maybe<User_App_Version_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_App_Version_Stddev_Samp_Order_By>
  sum?: Maybe<User_App_Version_Sum_Order_By>
  var_pop?: Maybe<User_App_Version_Var_Pop_Order_By>
  var_samp?: Maybe<User_App_Version_Var_Samp_Order_By>
  variance?: Maybe<User_App_Version_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_App_Version_Append_Input = {
  permissions?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "user_app_version" */
export type User_App_Version_Arr_Rel_Insert_Input = {
  data: Array<User_App_Version_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_App_Version_On_Conflict>
}

/** aggregate avg on columns */
export type User_App_Version_Avg_Fields = {
  __typename?: 'user_app_version_avg_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_app_version" */
export type User_App_Version_Avg_Order_By = {
  build_number?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_app_version". All fields are combined with a logical 'AND'. */
export type User_App_Version_Bool_Exp = {
  _and?: Maybe<Array<User_App_Version_Bool_Exp>>
  _not?: Maybe<User_App_Version_Bool_Exp>
  _or?: Maybe<Array<User_App_Version_Bool_Exp>>
  build_number?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  permissions?: Maybe<Jsonb_Comparison_Exp>
  platform?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  version?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_app_version" */
export enum User_App_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  VersionPkey = 'version_pkey',
  /** unique or primary key constraint on columns "user_id", "build_number", "platform", "version", "name" */
  VersionUserIdNameVersionBuildNumberPlatformKey = 'version_user_id_name_version_build_number_platform_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_App_Version_Delete_At_Path_Input = {
  permissions?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_App_Version_Delete_Elem_Input = {
  permissions?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_App_Version_Delete_Key_Input = {
  permissions?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "user_app_version" */
export type User_App_Version_Inc_Input = {
  build_number?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_app_version" */
export type User_App_Version_Insert_Input = {
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_App_Version_Max_Fields = {
  __typename?: 'user_app_version_max_fields'
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_app_version" */
export type User_App_Version_Max_Order_By = {
  build_number?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_App_Version_Min_Fields = {
  __typename?: 'user_app_version_min_fields'
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_app_version" */
export type User_App_Version_Min_Order_By = {
  build_number?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** response of any mutation on the table "user_app_version" */
export type User_App_Version_Mutation_Response = {
  __typename?: 'user_app_version_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_App_Version>
}

/** on_conflict condition type for table "user_app_version" */
export type User_App_Version_On_Conflict = {
  constraint: User_App_Version_Constraint
  update_columns?: Array<User_App_Version_Update_Column>
  where?: Maybe<User_App_Version_Bool_Exp>
}

/** Ordering options when selecting data from "user_app_version". */
export type User_App_Version_Order_By = {
  build_number?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  name?: Maybe<Order_By>
  permissions?: Maybe<Order_By>
  platform?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** primary key columns input for table: user_app_version */
export type User_App_Version_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_App_Version_Prepend_Input = {
  permissions?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_app_version" */
export enum User_App_Version_Select_Column {
  /** column name */
  BuildNumber = 'build_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "user_app_version" */
export type User_App_Version_Set_Input = {
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_App_Version_Stddev_Fields = {
  __typename?: 'user_app_version_stddev_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_app_version" */
export type User_App_Version_Stddev_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_App_Version_Stddev_Pop_Fields = {
  __typename?: 'user_app_version_stddev_pop_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_app_version" */
export type User_App_Version_Stddev_Pop_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_App_Version_Stddev_Samp_Fields = {
  __typename?: 'user_app_version_stddev_samp_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_app_version" */
export type User_App_Version_Stddev_Samp_Order_By = {
  build_number?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_app_version" */
export type User_App_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_App_Version_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_App_Version_Stream_Cursor_Value_Input = {
  build_number?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  name?: Maybe<Scalars['String']>
  permissions?: Maybe<Scalars['jsonb']>
  platform?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_App_Version_Sum_Fields = {
  __typename?: 'user_app_version_sum_fields'
  build_number?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user_app_version" */
export type User_App_Version_Sum_Order_By = {
  build_number?: Maybe<Order_By>
}

/** update columns of table "user_app_version" */
export enum User_App_Version_Update_Column {
  /** column name */
  BuildNumber = 'build_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permissions = 'permissions',
  /** column name */
  Platform = 'platform',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Version = 'version',
}

export type User_App_Version_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_App_Version_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_App_Version_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_App_Version_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_App_Version_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_App_Version_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_App_Version_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_App_Version_Set_Input>
  /** filter the rows which have to be updated */
  where: User_App_Version_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_App_Version_Var_Pop_Fields = {
  __typename?: 'user_app_version_var_pop_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_app_version" */
export type User_App_Version_Var_Pop_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_App_Version_Var_Samp_Fields = {
  __typename?: 'user_app_version_var_samp_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_app_version" */
export type User_App_Version_Var_Samp_Order_By = {
  build_number?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_App_Version_Variance_Fields = {
  __typename?: 'user_app_version_variance_fields'
  build_number?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_app_version" */
export type User_App_Version_Variance_Order_By = {
  build_number?: Maybe<Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "user" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>
}

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'user_avg_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by avg() on columns of table "user" */
export type User_Avg_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>
  _not?: Maybe<User_Bool_Exp>
  _or?: Maybe<Array<User_Bool_Exp>>
  active_cart_items_quantity?: Maybe<Numeric_Comparison_Exp>
  affiliate_transactions?: Maybe<Affiliate_Transaction_Bool_Exp>
  affiliate_transactions_aggregate?: Maybe<Affiliate_Transaction_Aggregate_Bool_Exp>
  anonymous?: Maybe<Boolean_Comparison_Exp>
  app_versions?: Maybe<User_App_Version_Bool_Exp>
  app_versions_aggregate?: Maybe<User_App_Version_Aggregate_Bool_Exp>
  applied_as_creator_at?: Maybe<Timestamptz_Comparison_Exp>
  bio?: Maybe<String_Comparison_Exp>
  bio_link?: Maybe<String_Comparison_Exp>
  can_be_credited?: Maybe<Boolean_Comparison_Exp>
  cart_item_likes?: Maybe<Cart_Item_Like_Bool_Exp>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Bool_Exp>
  carts?: Maybe<Cart_Bool_Exp>
  carts_aggregate?: Maybe<Cart_Aggregate_Bool_Exp>
  carts_count?: Maybe<Numeric_Comparison_Exp>
  collection_item_claims?: Maybe<Collection_Item_Claim_Bool_Exp>
  collection_item_claims_aggregate?: Maybe<Collection_Item_Claim_Aggregate_Bool_Exp>
  collections?: Maybe<Collection_Bool_Exp>
  collections_aggregate?: Maybe<Collection_Aggregate_Bool_Exp>
  collections_collaborating?: Maybe<Collection_Collaborator_Bool_Exp>
  collections_collaborating_aggregate?: Maybe<Collection_Collaborator_Aggregate_Bool_Exp>
  commissions_cleared?: Maybe<Numeric_Comparison_Exp>
  commissions_pending?: Maybe<Numeric_Comparison_Exp>
  cover_image?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_reason?: Maybe<String_Comparison_Exp>
  disabled_at?: Maybe<Timestamptz_Comparison_Exp>
  disabled_reason?: Maybe<String_Comparison_Exp>
  display_name?: Maybe<String_Comparison_Exp>
  email?: Maybe<String_Comparison_Exp>
  email_md5?: Maybe<String_Comparison_Exp>
  extension_version?: Maybe<String_Comparison_Exp>
  first_app_install?: Maybe<String_Comparison_Exp>
  flags?: Maybe<User_Flags_Bool_Exp>
  follows?: Maybe<User_Items_Followed_Bool_Exp>
  follows_aggregate?: Maybe<User_Items_Followed_Aggregate_Bool_Exp>
  has_accounts_email_computed?: Maybe<Boolean_Comparison_Exp>
  has_billing_account?: Maybe<Boolean_Comparison_Exp>
  has_claimed_username_computed?: Maybe<Boolean_Comparison_Exp>
  has_email_set?: Maybe<Boolean_Comparison_Exp>
  has_onboarded?: Maybe<Boolean_Comparison_Exp>
  has_picture_set?: Maybe<Boolean_Comparison_Exp>
  has_premium_subscription?: Maybe<Boolean_Comparison_Exp>
  has_set_name_computed?: Maybe<Boolean_Comparison_Exp>
  icon_order?: Maybe<Numeric_Comparison_Exp>
  id?: Maybe<String_Comparison_Exp>
  in_on_campus_contest?: Maybe<Boolean_Comparison_Exp>
  installed_at?: Maybe<Timestamptz_Comparison_Exp>
  internal_account?: Maybe<Boolean_Comparison_Exp>
  ios_version?: Maybe<String_Comparison_Exp>
  is_creator_computed?: Maybe<Boolean_Comparison_Exp>
  is_curator_computed?: Maybe<Boolean_Comparison_Exp>
  is_icon_computed?: Maybe<Boolean_Comparison_Exp>
  links?: Maybe<User_Links_Bool_Exp>
  links_aggregate?: Maybe<User_Links_Aggregate_Bool_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  mobile_badge_count?: Maybe<Int_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  new_discovery_feed_items_count?: Maybe<Int_Comparison_Exp>
  notification_center_items?: Maybe<Notification_Center_Item_Bool_Exp>
  notification_center_items_aggregate?: Maybe<Notification_Center_Item_Aggregate_Bool_Exp>
  notification_preferences?: Maybe<Notification_Preference_Bool_Exp>
  notification_preferences_aggregate?: Maybe<Notification_Preference_Aggregate_Bool_Exp>
  ogimage_fingerprint?: Maybe<String_Comparison_Exp>
  ogimage_fingerprint_updated?: Maybe<String_Comparison_Exp>
  onboarded?: Maybe<Boolean_Comparison_Exp>
  orphan_parent?: Maybe<User_Bool_Exp>
  orphaned_at?: Maybe<Timestamptz_Comparison_Exp>
  orphaned_by?: Maybe<String_Comparison_Exp>
  orphans?: Maybe<User_Bool_Exp>
  orphans_aggregate?: Maybe<User_Aggregate_Bool_Exp>
  phone?: Maybe<String_Comparison_Exp>
  picture?: Maybe<String_Comparison_Exp>
  possible_email?: Maybe<String_Comparison_Exp>
  probably_real?: Maybe<Boolean_Comparison_Exp>
  referral_count_credited?: Maybe<Int_Comparison_Exp>
  referral_count_total?: Maybe<Int_Comparison_Exp>
  referral_count_within_promo_period?: Maybe<Int_Comparison_Exp>
  referred_users?: Maybe<User_Bool_Exp>
  referred_users_aggregate?: Maybe<User_Aggregate_Bool_Exp>
  referrer_user?: Maybe<User_Bool_Exp>
  referrer_user_id?: Maybe<String_Comparison_Exp>
  role?: Maybe<Name_Comparison_Exp>
  safari_extension_version?: Maybe<String_Comparison_Exp>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum_Comparison_Exp>
  short_id?: Maybe<Name_Comparison_Exp>
  show_curations?: Maybe<Boolean_Comparison_Exp>
  show_onboarding?: Maybe<Boolean_Comparison_Exp>
  show_rabbithole?: Maybe<Boolean_Comparison_Exp>
  similar_products_histories?: Maybe<Similar_Products_History_Bool_Exp>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Bool_Exp>
  subscription_status?: Maybe<String_Comparison_Exp>
  unread_notification_center_items?: Maybe<Int_Comparison_Exp>
  unseen_cart_items?: Maybe<Int_Comparison_Exp>
  unviewed_notification_center_items?: Maybe<Int_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_account_type?: Maybe<User_Account_Type_Bool_Exp>
  user_carts_onboardings?: Maybe<User_Carts_Onboarding_Bool_Exp>
  user_carts_onboardings_aggregate?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp>
  user_categories?: Maybe<User_Category_Bool_Exp>
  user_categories_aggregate?: Maybe<User_Category_Aggregate_Bool_Exp>
  user_ip_addresses?: Maybe<User_Ip_Address_Bool_Exp>
  user_ip_addresses_aggregate?: Maybe<User_Ip_Address_Aggregate_Bool_Exp>
  user_onboarding_sources?: Maybe<User_Onboarding_Source_Bool_Exp>
  user_onboarding_sources_aggregate?: Maybe<User_Onboarding_Source_Aggregate_Bool_Exp>
  user_points_multiplier?: Maybe<Numeric_Comparison_Exp>
  user_push_notification_tokens?: Maybe<User_Push_Notification_Token_Bool_Exp>
  user_push_notification_tokens_aggregate?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp>
  user_referrals?: Maybe<User_Referral_Bool_Exp>
  user_referrals_aggregate?: Maybe<User_Referral_Aggregate_Bool_Exp>
  user_shopping_preference?: Maybe<User_Shopping_Preferences_Bool_Exp>
  user_type?: Maybe<User_Account_Type_Enum_Comparison_Exp>
  username?: Maybe<String_Comparison_Exp>
  verified?: Maybe<Boolean_Comparison_Exp>
  version?: Maybe<String_Comparison_Exp>
  waitlist?: Maybe<Boolean_Comparison_Exp>
  workflows?: Maybe<Workflow_Bool_Exp>
  workflows_aggregate?: Maybe<Workflow_Aggregate_Bool_Exp>
}

/** columns and relationships of "user_carts_onboarding" */
export type User_Carts_Onboarding = {
  __typename?: 'user_carts_onboarding'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  processed_at?: Maybe<Scalars['timestamptz']>
  processing: Scalars['Boolean']
  sequential_id: Scalars['Int']
  /** An object relationship */
  shop: Shop
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate = {
  __typename?: 'user_carts_onboarding_aggregate'
  aggregate?: Maybe<User_Carts_Onboarding_Aggregate_Fields>
  nodes: Array<User_Carts_Onboarding>
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp = {
  bool_and?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<User_Carts_Onboarding_Aggregate_Bool_Exp_Count>
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Carts_Onboarding_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Carts_Onboarding_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Carts_Onboarding_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Carts_Onboarding_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate_Fields = {
  __typename?: 'user_carts_onboarding_aggregate_fields'
  avg?: Maybe<User_Carts_Onboarding_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Carts_Onboarding_Max_Fields>
  min?: Maybe<User_Carts_Onboarding_Min_Fields>
  stddev?: Maybe<User_Carts_Onboarding_Stddev_Fields>
  stddev_pop?: Maybe<User_Carts_Onboarding_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Carts_Onboarding_Stddev_Samp_Fields>
  sum?: Maybe<User_Carts_Onboarding_Sum_Fields>
  var_pop?: Maybe<User_Carts_Onboarding_Var_Pop_Fields>
  var_samp?: Maybe<User_Carts_Onboarding_Var_Samp_Fields>
  variance?: Maybe<User_Carts_Onboarding_Variance_Fields>
}

/** aggregate fields of "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Carts_Onboarding_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Aggregate_Order_By = {
  avg?: Maybe<User_Carts_Onboarding_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Carts_Onboarding_Max_Order_By>
  min?: Maybe<User_Carts_Onboarding_Min_Order_By>
  stddev?: Maybe<User_Carts_Onboarding_Stddev_Order_By>
  stddev_pop?: Maybe<User_Carts_Onboarding_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Carts_Onboarding_Stddev_Samp_Order_By>
  sum?: Maybe<User_Carts_Onboarding_Sum_Order_By>
  var_pop?: Maybe<User_Carts_Onboarding_Var_Pop_Order_By>
  var_samp?: Maybe<User_Carts_Onboarding_Var_Samp_Order_By>
  variance?: Maybe<User_Carts_Onboarding_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_carts_onboarding" */
export type User_Carts_Onboarding_Arr_Rel_Insert_Input = {
  data: Array<User_Carts_Onboarding_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Carts_Onboarding_On_Conflict>
}

/** aggregate avg on columns */
export type User_Carts_Onboarding_Avg_Fields = {
  __typename?: 'user_carts_onboarding_avg_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Avg_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_carts_onboarding". All fields are combined with a logical 'AND'. */
export type User_Carts_Onboarding_Bool_Exp = {
  _and?: Maybe<Array<User_Carts_Onboarding_Bool_Exp>>
  _not?: Maybe<User_Carts_Onboarding_Bool_Exp>
  _or?: Maybe<Array<User_Carts_Onboarding_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  processed_at?: Maybe<Timestamptz_Comparison_Exp>
  processing?: Maybe<Boolean_Comparison_Exp>
  sequential_id?: Maybe<Int_Comparison_Exp>
  shop?: Maybe<Shop_Bool_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCartsOnboardingPkey = 'user_carts_onboarding_pkey',
  /** unique or primary key constraint on columns "sequential_id" */
  UserCartsOnboardingSequentialIdKey = 'user_carts_onboarding_sequential_id_key',
  /** unique or primary key constraint on columns "shop_key", "user_id" */
  UserCartsOnboardingUserIdShopKeyKey = 'user_carts_onboarding_user_id_shop_key_key',
}

/** input type for incrementing numeric columns in table "user_carts_onboarding" */
export type User_Carts_Onboarding_Inc_Input = {
  sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "user_carts_onboarding" */
export type User_Carts_Onboarding_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  processing?: Maybe<Scalars['Boolean']>
  sequential_id?: Maybe<Scalars['Int']>
  shop?: Maybe<Shop_Obj_Rel_Insert_Input>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Carts_Onboarding_Max_Fields = {
  __typename?: 'user_carts_onboarding_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  processed_at?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Carts_Onboarding_Min_Fields = {
  __typename?: 'user_carts_onboarding_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  processed_at?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_carts_onboarding" */
export type User_Carts_Onboarding_Mutation_Response = {
  __typename?: 'user_carts_onboarding_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Carts_Onboarding>
}

/** on_conflict condition type for table "user_carts_onboarding" */
export type User_Carts_Onboarding_On_Conflict = {
  constraint: User_Carts_Onboarding_Constraint
  update_columns?: Array<User_Carts_Onboarding_Update_Column>
  where?: Maybe<User_Carts_Onboarding_Bool_Exp>
}

/** Ordering options when selecting data from "user_carts_onboarding". */
export type User_Carts_Onboarding_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  processed_at?: Maybe<Order_By>
  processing?: Maybe<Order_By>
  sequential_id?: Maybe<Order_By>
  shop?: Maybe<Shop_Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_carts_onboarding */
export type User_Carts_Onboarding_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Processing = 'processing',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_carts_onboarding_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Processing = 'processing',
}

/** select "user_carts_onboarding_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Select_Column_User_Carts_Onboarding_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Processing = 'processing',
}

/** input type for updating data in table "user_carts_onboarding" */
export type User_Carts_Onboarding_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  processing?: Maybe<Scalars['Boolean']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Carts_Onboarding_Stddev_Fields = {
  __typename?: 'user_carts_onboarding_stddev_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stddev_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Carts_Onboarding_Stddev_Pop_Fields = {
  __typename?: 'user_carts_onboarding_stddev_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stddev_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Carts_Onboarding_Stddev_Samp_Fields = {
  __typename?: 'user_carts_onboarding_stddev_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stddev_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_carts_onboarding" */
export type User_Carts_Onboarding_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Carts_Onboarding_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Carts_Onboarding_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  processed_at?: Maybe<Scalars['timestamptz']>
  processing?: Maybe<Scalars['Boolean']>
  sequential_id?: Maybe<Scalars['Int']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Carts_Onboarding_Sum_Fields = {
  __typename?: 'user_carts_onboarding_sum_fields'
  sequential_id?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Sum_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** update columns of table "user_carts_onboarding" */
export enum User_Carts_Onboarding_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProcessedAt = 'processed_at',
  /** column name */
  Processing = 'processing',
  /** column name */
  SequentialId = 'sequential_id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Carts_Onboarding_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Carts_Onboarding_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Carts_Onboarding_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Carts_Onboarding_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Carts_Onboarding_Var_Pop_Fields = {
  __typename?: 'user_carts_onboarding_var_pop_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Var_Pop_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Carts_Onboarding_Var_Samp_Fields = {
  __typename?: 'user_carts_onboarding_var_samp_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Var_Samp_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Carts_Onboarding_Variance_Fields = {
  __typename?: 'user_carts_onboarding_variance_fields'
  sequential_id?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_carts_onboarding" */
export type User_Carts_Onboarding_Variance_Order_By = {
  sequential_id?: Maybe<Order_By>
}

/** columns and relationships of "user_category" */
export type User_Category = {
  __typename?: 'user_category'
  category: Shop_Category_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  shop_category: Shop_Category
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_category" */
export type User_Category_Aggregate = {
  __typename?: 'user_category_aggregate'
  aggregate?: Maybe<User_Category_Aggregate_Fields>
  nodes: Array<User_Category>
}

export type User_Category_Aggregate_Bool_Exp = {
  count?: Maybe<User_Category_Aggregate_Bool_Exp_Count>
}

export type User_Category_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Category_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Category_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_category" */
export type User_Category_Aggregate_Fields = {
  __typename?: 'user_category_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Category_Max_Fields>
  min?: Maybe<User_Category_Min_Fields>
}

/** aggregate fields of "user_category" */
export type User_Category_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Category_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_category" */
export type User_Category_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Category_Max_Order_By>
  min?: Maybe<User_Category_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_category" */
export type User_Category_Arr_Rel_Insert_Input = {
  data: Array<User_Category_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Category_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_category". All fields are combined with a logical 'AND'. */
export type User_Category_Bool_Exp = {
  _and?: Maybe<Array<User_Category_Bool_Exp>>
  _not?: Maybe<User_Category_Bool_Exp>
  _or?: Maybe<Array<User_Category_Bool_Exp>>
  category?: Maybe<Shop_Category_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_category?: Maybe<Shop_Category_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_category" */
export enum User_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCategoryPkey = 'user_category_pkey',
  /** unique or primary key constraint on columns "user_id", "category" */
  UserCategoryUserIdCategoryKey = 'user_category_user_id_category_key',
}

/** input type for inserting data into table "user_category" */
export type User_Category_Insert_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_category?: Maybe<Shop_Category_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Category_Max_Fields = {
  __typename?: 'user_category_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_category" */
export type User_Category_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Category_Min_Fields = {
  __typename?: 'user_category_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_category" */
export type User_Category_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_category" */
export type User_Category_Mutation_Response = {
  __typename?: 'user_category_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Category>
}

/** on_conflict condition type for table "user_category" */
export type User_Category_On_Conflict = {
  constraint: User_Category_Constraint
  update_columns?: Array<User_Category_Update_Column>
  where?: Maybe<User_Category_Bool_Exp>
}

/** Ordering options when selecting data from "user_category". */
export type User_Category_Order_By = {
  category?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_category?: Maybe<Shop_Category_Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_category */
export type User_Category_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_category" */
export enum User_Category_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_category" */
export type User_Category_Set_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_category" */
export type User_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Category_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Category_Stream_Cursor_Value_Input = {
  category?: Maybe<Shop_Category_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_category" */
export enum User_Category_Update_Column {
  /** column name */
  Category = 'category',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Category_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Category_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Category_Bool_Exp
}

/** unique or primary key constraints on table "user" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserEmailKey = 'user_email_key',
  /** unique or primary key constraint on columns "phone" */
  UserPhoneKey = 'user_phone_key',
  /** unique or primary key constraint on columns "id" */
  UserPkey = 'user_pkey',
  /** unique or primary key constraint on columns "short_id" */
  UserShortIdKey = 'user_short_id_key',
  /** unique or primary key constraint on columns "username" */
  UserUsernameKey = 'user_username_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** Table to keep track of account deletions */
export type User_Deletion = {
  __typename?: 'user_deletion'
  created_at: Scalars['timestamptz']
  metadata: Scalars['jsonb']
  reason?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** Table to keep track of account deletions */
export type User_DeletionMetadataArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_deletion" */
export type User_Deletion_Aggregate = {
  __typename?: 'user_deletion_aggregate'
  aggregate?: Maybe<User_Deletion_Aggregate_Fields>
  nodes: Array<User_Deletion>
}

/** aggregate fields of "user_deletion" */
export type User_Deletion_Aggregate_Fields = {
  __typename?: 'user_deletion_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Deletion_Max_Fields>
  min?: Maybe<User_Deletion_Min_Fields>
}

/** aggregate fields of "user_deletion" */
export type User_Deletion_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Deletion_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Deletion_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "user_deletion". All fields are combined with a logical 'AND'. */
export type User_Deletion_Bool_Exp = {
  _and?: Maybe<Array<User_Deletion_Bool_Exp>>
  _not?: Maybe<User_Deletion_Bool_Exp>
  _or?: Maybe<Array<User_Deletion_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  metadata?: Maybe<Jsonb_Comparison_Exp>
  reason?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_deletion" */
export enum User_Deletion_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserDeletedPkey = 'user_deleted_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Deletion_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Deletion_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Deletion_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "user_deletion" */
export type User_Deletion_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Deletion_Max_Fields = {
  __typename?: 'user_deletion_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Deletion_Min_Fields = {
  __typename?: 'user_deletion_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_deletion" */
export type User_Deletion_Mutation_Response = {
  __typename?: 'user_deletion_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Deletion>
}

/** on_conflict condition type for table "user_deletion" */
export type User_Deletion_On_Conflict = {
  constraint: User_Deletion_Constraint
  update_columns?: Array<User_Deletion_Update_Column>
  where?: Maybe<User_Deletion_Bool_Exp>
}

/** Ordering options when selecting data from "user_deletion". */
export type User_Deletion_Order_By = {
  created_at?: Maybe<Order_By>
  metadata?: Maybe<Order_By>
  reason?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_deletion */
export type User_Deletion_Pk_Columns_Input = {
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Deletion_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_deletion" */
export enum User_Deletion_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_deletion" */
export type User_Deletion_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_deletion" */
export type User_Deletion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Deletion_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Deletion_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  metadata?: Maybe<Scalars['jsonb']>
  reason?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_deletion" */
export enum User_Deletion_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Reason = 'reason',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Deletion_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Deletion_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Deletion_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Deletion_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Deletion_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Deletion_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Deletion_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Deletion_Bool_Exp
}

/** columns and relationships of "user_feedback" */
export type User_Feedback = {
  __typename?: 'user_feedback'
  created_at: Scalars['timestamptz']
  custom: Scalars['String']
  id: Scalars['uuid']
  reaction: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  /** An object relationship */
  user_feedback_item: User_Feedback_Item
  user_feedback_item_id: Scalars['uuid']
  user_feedback_type: User_Feedback_Type_Enum
  user_id: Scalars['String']
}

/** aggregated selection of "user_feedback" */
export type User_Feedback_Aggregate = {
  __typename?: 'user_feedback_aggregate'
  aggregate?: Maybe<User_Feedback_Aggregate_Fields>
  nodes: Array<User_Feedback>
}

/** aggregate fields of "user_feedback" */
export type User_Feedback_Aggregate_Fields = {
  __typename?: 'user_feedback_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Feedback_Max_Fields>
  min?: Maybe<User_Feedback_Min_Fields>
}

/** aggregate fields of "user_feedback" */
export type User_Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Feedback_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_feedback". All fields are combined with a logical 'AND'. */
export type User_Feedback_Bool_Exp = {
  _and?: Maybe<Array<User_Feedback_Bool_Exp>>
  _not?: Maybe<User_Feedback_Bool_Exp>
  _or?: Maybe<Array<User_Feedback_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  custom?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  reaction?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_feedback_item?: Maybe<User_Feedback_Item_Bool_Exp>
  user_feedback_item_id?: Maybe<Uuid_Comparison_Exp>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_feedback" */
export enum User_Feedback_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFeedbackPkey = 'user_feedback_pkey',
  /** unique or primary key constraint on columns "user_id", "user_feedback_item_id" */
  UserFeedbackUserFeedbackItemIdUserIdKey = 'user_feedback_user_feedback_item_id_user_id_key',
}

/** input type for inserting data into table "user_feedback" */
export type User_Feedback_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reaction?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_feedback_item?: Maybe<User_Feedback_Item_Obj_Rel_Insert_Input>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum>
  user_id?: Maybe<Scalars['String']>
}

/** A table to keep track of all the allowable polymorphic user_feedback items */
export type User_Feedback_Item = {
  __typename?: 'user_feedback_item'
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
  /** An object relationship */
  similar_product_history?: Maybe<Similar_Products_History>
}

/** aggregated selection of "user_feedback_item" */
export type User_Feedback_Item_Aggregate = {
  __typename?: 'user_feedback_item_aggregate'
  aggregate?: Maybe<User_Feedback_Item_Aggregate_Fields>
  nodes: Array<User_Feedback_Item>
}

/** aggregate fields of "user_feedback_item" */
export type User_Feedback_Item_Aggregate_Fields = {
  __typename?: 'user_feedback_item_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Feedback_Item_Max_Fields>
  min?: Maybe<User_Feedback_Item_Min_Fields>
}

/** aggregate fields of "user_feedback_item" */
export type User_Feedback_Item_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Feedback_Item_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_feedback_item". All fields are combined with a logical 'AND'. */
export type User_Feedback_Item_Bool_Exp = {
  _and?: Maybe<Array<User_Feedback_Item_Bool_Exp>>
  _not?: Maybe<User_Feedback_Item_Bool_Exp>
  _or?: Maybe<Array<User_Feedback_Item_Bool_Exp>>
  item_id?: Maybe<Uuid_Comparison_Exp>
  item_type?: Maybe<User_Feedback_Type_Enum_Comparison_Exp>
  similar_product_history?: Maybe<Similar_Products_History_Bool_Exp>
}

/** unique or primary key constraints on table "user_feedback_item" */
export enum User_Feedback_Item_Constraint {
  /** unique or primary key constraint on columns "item_id", "item_type" */
  UserFeedbackItemItemIdItemTypeKey = 'user_feedback_item_item_id_item_type_key',
  /** unique or primary key constraint on columns "item_id" */
  UserFeedbackItemItemIdKey = 'user_feedback_item_item_id_key',
  /** unique or primary key constraint on columns "item_id", "item_type" */
  UserFeedbackItemPkey = 'user_feedback_item_pkey',
}

/** input type for inserting data into table "user_feedback_item" */
export type User_Feedback_Item_Insert_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<User_Feedback_Type_Enum>
  similar_product_history?: Maybe<Similar_Products_History_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Feedback_Item_Max_Fields = {
  __typename?: 'user_feedback_item_max_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type User_Feedback_Item_Min_Fields = {
  __typename?: 'user_feedback_item_min_fields'
  item_id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "user_feedback_item" */
export type User_Feedback_Item_Mutation_Response = {
  __typename?: 'user_feedback_item_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Feedback_Item>
}

/** input type for inserting object relation for remote table "user_feedback_item" */
export type User_Feedback_Item_Obj_Rel_Insert_Input = {
  data: User_Feedback_Item_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Feedback_Item_On_Conflict>
}

/** on_conflict condition type for table "user_feedback_item" */
export type User_Feedback_Item_On_Conflict = {
  constraint: User_Feedback_Item_Constraint
  update_columns?: Array<User_Feedback_Item_Update_Column>
  where?: Maybe<User_Feedback_Item_Bool_Exp>
}

/** Ordering options when selecting data from "user_feedback_item". */
export type User_Feedback_Item_Order_By = {
  item_id?: Maybe<Order_By>
  item_type?: Maybe<Order_By>
  similar_product_history?: Maybe<Similar_Products_History_Order_By>
}

/** primary key columns input for table: user_feedback_item */
export type User_Feedback_Item_Pk_Columns_Input = {
  item_id: Scalars['uuid']
  item_type: User_Feedback_Type_Enum
}

/** select columns of table "user_feedback_item" */
export enum User_Feedback_Item_Select_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

/** input type for updating data in table "user_feedback_item" */
export type User_Feedback_Item_Set_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<User_Feedback_Type_Enum>
}

/** Streaming cursor of the table "user_feedback_item" */
export type User_Feedback_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Feedback_Item_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Feedback_Item_Stream_Cursor_Value_Input = {
  item_id?: Maybe<Scalars['uuid']>
  item_type?: Maybe<User_Feedback_Type_Enum>
}

/** update columns of table "user_feedback_item" */
export enum User_Feedback_Item_Update_Column {
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemType = 'item_type',
}

export type User_Feedback_Item_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Feedback_Item_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Feedback_Item_Bool_Exp
}

/** aggregate max on columns */
export type User_Feedback_Max_Fields = {
  __typename?: 'user_feedback_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Feedback_Min_Fields = {
  __typename?: 'user_feedback_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_feedback" */
export type User_Feedback_Mutation_Response = {
  __typename?: 'user_feedback_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Feedback>
}

/** on_conflict condition type for table "user_feedback" */
export type User_Feedback_On_Conflict = {
  constraint: User_Feedback_Constraint
  update_columns?: Array<User_Feedback_Update_Column>
  where?: Maybe<User_Feedback_Bool_Exp>
}

/** Ordering options when selecting data from "user_feedback". */
export type User_Feedback_Order_By = {
  created_at?: Maybe<Order_By>
  custom?: Maybe<Order_By>
  id?: Maybe<Order_By>
  reaction?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_feedback_item?: Maybe<User_Feedback_Item_Order_By>
  user_feedback_item_id?: Maybe<Order_By>
  user_feedback_type?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_feedback */
export type User_Feedback_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_feedback" */
export enum User_Feedback_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Custom = 'custom',
  /** column name */
  Id = 'id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserFeedbackItemId = 'user_feedback_item_id',
  /** column name */
  UserFeedbackType = 'user_feedback_type',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_feedback" */
export type User_Feedback_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reaction?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_feedback" */
export type User_Feedback_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Feedback_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Feedback_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  custom?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reaction?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_feedback_item_id?: Maybe<Scalars['uuid']>
  user_feedback_type?: Maybe<User_Feedback_Type_Enum>
  user_id?: Maybe<Scalars['String']>
}

/** An enum table to hold the different user feedback item types */
export type User_Feedback_Type = {
  __typename?: 'user_feedback_type'
  value: Scalars['String']
}

/** aggregated selection of "user_feedback_type" */
export type User_Feedback_Type_Aggregate = {
  __typename?: 'user_feedback_type_aggregate'
  aggregate?: Maybe<User_Feedback_Type_Aggregate_Fields>
  nodes: Array<User_Feedback_Type>
}

/** aggregate fields of "user_feedback_type" */
export type User_Feedback_Type_Aggregate_Fields = {
  __typename?: 'user_feedback_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Feedback_Type_Max_Fields>
  min?: Maybe<User_Feedback_Type_Min_Fields>
}

/** aggregate fields of "user_feedback_type" */
export type User_Feedback_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Feedback_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_feedback_type". All fields are combined with a logical 'AND'. */
export type User_Feedback_Type_Bool_Exp = {
  _and?: Maybe<Array<User_Feedback_Type_Bool_Exp>>
  _not?: Maybe<User_Feedback_Type_Bool_Exp>
  _or?: Maybe<Array<User_Feedback_Type_Bool_Exp>>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_feedback_type" */
export enum User_Feedback_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserFeedbackTypePkey = 'user_feedback_type_pkey',
}

export enum User_Feedback_Type_Enum {
  SimilarProducts = 'similar_products',
}

/** Boolean expression to compare columns of type "user_feedback_type_enum". All fields are combined with logical 'AND'. */
export type User_Feedback_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Feedback_Type_Enum>
  _in?: Maybe<Array<User_Feedback_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Feedback_Type_Enum>
  _nin?: Maybe<Array<User_Feedback_Type_Enum>>
}

/** input type for inserting data into table "user_feedback_type" */
export type User_Feedback_Type_Insert_Input = {
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Feedback_Type_Max_Fields = {
  __typename?: 'user_feedback_type_max_fields'
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Feedback_Type_Min_Fields = {
  __typename?: 'user_feedback_type_min_fields'
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_feedback_type" */
export type User_Feedback_Type_Mutation_Response = {
  __typename?: 'user_feedback_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Feedback_Type>
}

/** on_conflict condition type for table "user_feedback_type" */
export type User_Feedback_Type_On_Conflict = {
  constraint: User_Feedback_Type_Constraint
  update_columns?: Array<User_Feedback_Type_Update_Column>
  where?: Maybe<User_Feedback_Type_Bool_Exp>
}

/** Ordering options when selecting data from "user_feedback_type". */
export type User_Feedback_Type_Order_By = {
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_feedback_type */
export type User_Feedback_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_feedback_type" */
export enum User_Feedback_Type_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_feedback_type" */
export type User_Feedback_Type_Set_Input = {
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_feedback_type" */
export type User_Feedback_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Feedback_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Feedback_Type_Stream_Cursor_Value_Input = {
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_feedback_type" */
export enum User_Feedback_Type_Update_Column {
  /** column name */
  Value = 'value',
}

export type User_Feedback_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Feedback_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Feedback_Type_Bool_Exp
}

/** update columns of table "user_feedback" */
export enum User_Feedback_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Custom = 'custom',
  /** column name */
  Id = 'id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserFeedbackItemId = 'user_feedback_item_id',
  /** column name */
  UserFeedbackType = 'user_feedback_type',
  /** column name */
  UserId = 'user_id',
}

export type User_Feedback_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Feedback_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Feedback_Bool_Exp
}

/** columns and relationships of "user_flags" */
export type User_Flags = {
  __typename?: 'user_flags'
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated: Scalars['Boolean']
  checked_history: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id: Scalars['Int']
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding: Scalars['Boolean']
  texting_waitlist: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id: Scalars['String']
  /** An object relationship */
  user_onboarding_state?: Maybe<User_Onboarding_State>
}

/** columns and relationships of "user_flags" */
export type User_FlagsDeal_Hop_InvitesArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user_flags" */
export type User_FlagsFeaturesArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_flags" */
export type User_Flags_Aggregate = {
  __typename?: 'user_flags_aggregate'
  aggregate?: Maybe<User_Flags_Aggregate_Fields>
  nodes: Array<User_Flags>
}

/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_Fields = {
  __typename?: 'user_flags_aggregate_fields'
  avg?: Maybe<User_Flags_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Flags_Max_Fields>
  min?: Maybe<User_Flags_Min_Fields>
  stddev?: Maybe<User_Flags_Stddev_Fields>
  stddev_pop?: Maybe<User_Flags_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Flags_Stddev_Samp_Fields>
  sum?: Maybe<User_Flags_Sum_Fields>
  var_pop?: Maybe<User_Flags_Var_Pop_Fields>
  var_samp?: Maybe<User_Flags_Var_Samp_Fields>
  variance?: Maybe<User_Flags_Variance_Fields>
}

/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Flags_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Flags_Append_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  features?: Maybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type User_Flags_Avg_Fields = {
  __typename?: 'user_flags_avg_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_flags". All fields are combined with a logical 'AND'. */
export type User_Flags_Bool_Exp = {
  _and?: Maybe<Array<User_Flags_Bool_Exp>>
  _not?: Maybe<User_Flags_Bool_Exp>
  _or?: Maybe<Array<User_Flags_Bool_Exp>>
  auto_cashback_enabled?: Maybe<Boolean_Comparison_Exp>
  can_be_impersonated?: Maybe<Boolean_Comparison_Exp>
  checked_history?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gate_pending?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_gated?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invited?: Maybe<Timestamptz_Comparison_Exp>
  deal_hop_invites?: Maybe<Jsonb_Comparison_Exp>
  deal_hop_unlocked?: Maybe<Boolean_Comparison_Exp>
  discovery_feed_sequential_id?: Maybe<Int_Comparison_Exp>
  discovery_feed_sequential_id_last_set_at?: Maybe<Timestamptz_Comparison_Exp>
  features?: Maybe<Jsonb_Comparison_Exp>
  granted_full_host_permissions_current?: Maybe<Boolean_Comparison_Exp>
  granted_full_host_permissions_ever?: Maybe<Boolean_Comparison_Exp>
  installed_safari_extension?: Maybe<Boolean_Comparison_Exp>
  ios_device_version?: Maybe<String_Comparison_Exp>
  last_cart_onboarding?: Maybe<Timestamptz_Comparison_Exp>
  last_safari_extension_permission_check?: Maybe<Timestamptz_Comparison_Exp>
  last_seen_cart_items_at?: Maybe<Timestamptz_Comparison_Exp>
  notification_center_last_viewed_at?: Maybe<Timestamptz_Comparison_Exp>
  notification_center_last_viewed_sequential_id?: Maybe<Int_Comparison_Exp>
  onboarding_state?: Maybe<User_Onboarding_State_Enum_Comparison_Exp>
  opted_into_cart_onboarding?: Maybe<Boolean_Comparison_Exp>
  texting_waitlist?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_onboarding_state?: Maybe<User_Onboarding_State_Bool_Exp>
}

/** unique or primary key constraints on table "user_flags" */
export enum User_Flags_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  UserFlagsPkey = 'user_flags_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Flags_Delete_At_Path_Input = {
  deal_hop_invites?: Maybe<Array<Scalars['String']>>
  features?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Flags_Delete_Elem_Input = {
  deal_hop_invites?: Maybe<Scalars['Int']>
  features?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Flags_Delete_Key_Input = {
  deal_hop_invites?: Maybe<Scalars['String']>
  features?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "user_flags" */
export type User_Flags_Inc_Input = {
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "user_flags" */
export type User_Flags_Insert_Input = {
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated?: Maybe<Scalars['Boolean']>
  checked_history?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding?: Maybe<Scalars['Boolean']>
  texting_waitlist?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_onboarding_state?: Maybe<User_Onboarding_State_Obj_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Flags_Max_Fields = {
  __typename?: 'user_flags_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Flags_Min_Fields = {
  __typename?: 'user_flags_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_flags" */
export type User_Flags_Mutation_Response = {
  __typename?: 'user_flags_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Flags>
}

/** input type for inserting object relation for remote table "user_flags" */
export type User_Flags_Obj_Rel_Insert_Input = {
  data: User_Flags_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Flags_On_Conflict>
}

/** on_conflict condition type for table "user_flags" */
export type User_Flags_On_Conflict = {
  constraint: User_Flags_Constraint
  update_columns?: Array<User_Flags_Update_Column>
  where?: Maybe<User_Flags_Bool_Exp>
}

/** Ordering options when selecting data from "user_flags". */
export type User_Flags_Order_By = {
  auto_cashback_enabled?: Maybe<Order_By>
  can_be_impersonated?: Maybe<Order_By>
  checked_history?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deal_hop_gate_pending?: Maybe<Order_By>
  deal_hop_gated?: Maybe<Order_By>
  deal_hop_invited?: Maybe<Order_By>
  deal_hop_invites?: Maybe<Order_By>
  deal_hop_unlocked?: Maybe<Order_By>
  discovery_feed_sequential_id?: Maybe<Order_By>
  discovery_feed_sequential_id_last_set_at?: Maybe<Order_By>
  features?: Maybe<Order_By>
  granted_full_host_permissions_current?: Maybe<Order_By>
  granted_full_host_permissions_ever?: Maybe<Order_By>
  installed_safari_extension?: Maybe<Order_By>
  ios_device_version?: Maybe<Order_By>
  last_cart_onboarding?: Maybe<Order_By>
  last_safari_extension_permission_check?: Maybe<Order_By>
  last_seen_cart_items_at?: Maybe<Order_By>
  notification_center_last_viewed_at?: Maybe<Order_By>
  notification_center_last_viewed_sequential_id?: Maybe<Order_By>
  onboarding_state?: Maybe<Order_By>
  opted_into_cart_onboarding?: Maybe<Order_By>
  texting_waitlist?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_onboarding_state?: Maybe<User_Onboarding_State_Order_By>
}

/** primary key columns input for table: user_flags */
export type User_Flags_Pk_Columns_Input = {
  user_id: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Flags_Prepend_Input = {
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  features?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_flags" */
export enum User_Flags_Select_Column {
  /** column name */
  AutoCashbackEnabled = 'auto_cashback_enabled',
  /** column name */
  CanBeImpersonated = 'can_be_impersonated',
  /** column name */
  CheckedHistory = 'checked_history',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  DiscoveryFeedSequentialId = 'discovery_feed_sequential_id',
  /** column name */
  DiscoveryFeedSequentialIdLastSetAt = 'discovery_feed_sequential_id_last_set_at',
  /** column name */
  Features = 'features',
  /** column name */
  GrantedFullHostPermissionsCurrent = 'granted_full_host_permissions_current',
  /** column name */
  GrantedFullHostPermissionsEver = 'granted_full_host_permissions_ever',
  /** column name */
  InstalledSafariExtension = 'installed_safari_extension',
  /** column name */
  IosDeviceVersion = 'ios_device_version',
  /** column name */
  LastCartOnboarding = 'last_cart_onboarding',
  /** column name */
  LastSafariExtensionPermissionCheck = 'last_safari_extension_permission_check',
  /** column name */
  LastSeenCartItemsAt = 'last_seen_cart_items_at',
  /** column name */
  NotificationCenterLastViewedAt = 'notification_center_last_viewed_at',
  /** column name */
  NotificationCenterLastViewedSequentialId = 'notification_center_last_viewed_sequential_id',
  /** column name */
  OnboardingState = 'onboarding_state',
  /** column name */
  OptedIntoCartOnboarding = 'opted_into_cart_onboarding',
  /** column name */
  TextingWaitlist = 'texting_waitlist',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_flags" */
export type User_Flags_Set_Input = {
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated?: Maybe<Scalars['Boolean']>
  checked_history?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding?: Maybe<Scalars['Boolean']>
  texting_waitlist?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Flags_Stddev_Fields = {
  __typename?: 'user_flags_stddev_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Flags_Stddev_Pop_Fields = {
  __typename?: 'user_flags_stddev_pop_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Flags_Stddev_Samp_Fields = {
  __typename?: 'user_flags_stddev_samp_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_flags" */
export type User_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Flags_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Flags_Stream_Cursor_Value_Input = {
  auto_cashback_enabled?: Maybe<Scalars['Boolean']>
  can_be_impersonated?: Maybe<Scalars['Boolean']>
  checked_history?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  deal_hop_gate_pending?: Maybe<Scalars['timestamptz']>
  deal_hop_gated?: Maybe<Scalars['timestamptz']>
  deal_hop_invited?: Maybe<Scalars['timestamptz']>
  deal_hop_invites?: Maybe<Scalars['jsonb']>
  deal_hop_unlocked?: Maybe<Scalars['Boolean']>
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  discovery_feed_sequential_id_last_set_at?: Maybe<Scalars['timestamptz']>
  features?: Maybe<Scalars['jsonb']>
  granted_full_host_permissions_current?: Maybe<Scalars['Boolean']>
  granted_full_host_permissions_ever?: Maybe<Scalars['Boolean']>
  installed_safari_extension?: Maybe<Scalars['Boolean']>
  ios_device_version?: Maybe<Scalars['String']>
  last_cart_onboarding?: Maybe<Scalars['timestamptz']>
  last_safari_extension_permission_check?: Maybe<Scalars['timestamptz']>
  last_seen_cart_items_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_at?: Maybe<Scalars['timestamptz']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
  onboarding_state?: Maybe<User_Onboarding_State_Enum>
  opted_into_cart_onboarding?: Maybe<Scalars['Boolean']>
  texting_waitlist?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Flags_Sum_Fields = {
  __typename?: 'user_flags_sum_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Int']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Int']>
}

/** update columns of table "user_flags" */
export enum User_Flags_Update_Column {
  /** column name */
  AutoCashbackEnabled = 'auto_cashback_enabled',
  /** column name */
  CanBeImpersonated = 'can_be_impersonated',
  /** column name */
  CheckedHistory = 'checked_history',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DealHopGatePending = 'deal_hop_gate_pending',
  /** column name */
  DealHopGated = 'deal_hop_gated',
  /** column name */
  DealHopInvited = 'deal_hop_invited',
  /** column name */
  DealHopInvites = 'deal_hop_invites',
  /** column name */
  DealHopUnlocked = 'deal_hop_unlocked',
  /** column name */
  DiscoveryFeedSequentialId = 'discovery_feed_sequential_id',
  /** column name */
  DiscoveryFeedSequentialIdLastSetAt = 'discovery_feed_sequential_id_last_set_at',
  /** column name */
  Features = 'features',
  /** column name */
  GrantedFullHostPermissionsCurrent = 'granted_full_host_permissions_current',
  /** column name */
  GrantedFullHostPermissionsEver = 'granted_full_host_permissions_ever',
  /** column name */
  InstalledSafariExtension = 'installed_safari_extension',
  /** column name */
  IosDeviceVersion = 'ios_device_version',
  /** column name */
  LastCartOnboarding = 'last_cart_onboarding',
  /** column name */
  LastSafariExtensionPermissionCheck = 'last_safari_extension_permission_check',
  /** column name */
  LastSeenCartItemsAt = 'last_seen_cart_items_at',
  /** column name */
  NotificationCenterLastViewedAt = 'notification_center_last_viewed_at',
  /** column name */
  NotificationCenterLastViewedSequentialId = 'notification_center_last_viewed_sequential_id',
  /** column name */
  OnboardingState = 'onboarding_state',
  /** column name */
  OptedIntoCartOnboarding = 'opted_into_cart_onboarding',
  /** column name */
  TextingWaitlist = 'texting_waitlist',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Flags_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Flags_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Flags_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Flags_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Flags_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Flags_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Flags_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Flags_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Flags_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Flags_Var_Pop_Fields = {
  __typename?: 'user_flags_var_pop_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Flags_Var_Samp_Fields = {
  __typename?: 'user_flags_var_samp_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Flags_Variance_Fields = {
  __typename?: 'user_flags_variance_fields'
  discovery_feed_sequential_id?: Maybe<Scalars['Float']>
  notification_center_last_viewed_sequential_id?: Maybe<Scalars['Float']>
}

/** Internal audit table to keep track of users being impersonated */
export type User_Impersonation = {
  __typename?: 'user_impersonation'
  /** An object relationship */
  admin_user: User
  admin_user_id: Scalars['String']
  created_at: Scalars['timestamptz']
  deleted_user_id?: Maybe<Scalars['String']>
  id: Scalars['uuid']
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user_being_impersonated?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_impersonation" */
export type User_Impersonation_Aggregate = {
  __typename?: 'user_impersonation_aggregate'
  aggregate?: Maybe<User_Impersonation_Aggregate_Fields>
  nodes: Array<User_Impersonation>
}

/** aggregate fields of "user_impersonation" */
export type User_Impersonation_Aggregate_Fields = {
  __typename?: 'user_impersonation_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Impersonation_Max_Fields>
  min?: Maybe<User_Impersonation_Min_Fields>
}

/** aggregate fields of "user_impersonation" */
export type User_Impersonation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Impersonation_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_impersonation". All fields are combined with a logical 'AND'. */
export type User_Impersonation_Bool_Exp = {
  _and?: Maybe<Array<User_Impersonation_Bool_Exp>>
  _not?: Maybe<User_Impersonation_Bool_Exp>
  _or?: Maybe<Array<User_Impersonation_Bool_Exp>>
  admin_user?: Maybe<User_Bool_Exp>
  admin_user_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  deleted_user_id?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  reason?: Maybe<String_Comparison_Exp>
  signed_out_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_being_impersonated?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_impersonation" */
export enum User_Impersonation_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserImpersonationPkey = 'user_impersonation_pkey',
}

/** input type for inserting data into table "user_impersonation" */
export type User_Impersonation_Insert_Input = {
  admin_user?: Maybe<User_Obj_Rel_Insert_Input>
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_being_impersonated?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Impersonation_Max_Fields = {
  __typename?: 'user_impersonation_max_fields'
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Impersonation_Min_Fields = {
  __typename?: 'user_impersonation_min_fields'
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_impersonation" */
export type User_Impersonation_Mutation_Response = {
  __typename?: 'user_impersonation_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Impersonation>
}

/** on_conflict condition type for table "user_impersonation" */
export type User_Impersonation_On_Conflict = {
  constraint: User_Impersonation_Constraint
  update_columns?: Array<User_Impersonation_Update_Column>
  where?: Maybe<User_Impersonation_Bool_Exp>
}

/** Ordering options when selecting data from "user_impersonation". */
export type User_Impersonation_Order_By = {
  admin_user?: Maybe<User_Order_By>
  admin_user_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_user_id?: Maybe<Order_By>
  id?: Maybe<Order_By>
  reason?: Maybe<Order_By>
  signed_out_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_being_impersonated?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_impersonation */
export type User_Impersonation_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_impersonation" */
export enum User_Impersonation_Select_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedUserId = 'deleted_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  SignedOutAt = 'signed_out_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_impersonation" */
export type User_Impersonation_Set_Input = {
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_impersonation" */
export type User_Impersonation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Impersonation_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Impersonation_Stream_Cursor_Value_Input = {
  admin_user_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_user_id?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  reason?: Maybe<Scalars['String']>
  signed_out_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_impersonation" */
export enum User_Impersonation_Update_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedUserId = 'deleted_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  SignedOutAt = 'signed_out_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Impersonation_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Impersonation_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Impersonation_Bool_Exp
}

/** input type for incrementing numeric columns in table "user" */
export type User_Inc_Input = {
  icon_order?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user" */
export type User_Insert_Input = {
  affiliate_transactions?: Maybe<Affiliate_Transaction_Arr_Rel_Insert_Input>
  anonymous?: Maybe<Scalars['Boolean']>
  app_versions?: Maybe<User_App_Version_Arr_Rel_Insert_Input>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  cart_item_likes?: Maybe<Cart_Item_Like_Arr_Rel_Insert_Input>
  carts?: Maybe<Cart_Arr_Rel_Insert_Input>
  collection_item_claims?: Maybe<Collection_Item_Claim_Arr_Rel_Insert_Input>
  collections?: Maybe<Collection_Arr_Rel_Insert_Input>
  collections_collaborating?: Maybe<Collection_Collaborator_Arr_Rel_Insert_Input>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  flags?: Maybe<User_Flags_Obj_Rel_Insert_Input>
  follows?: Maybe<User_Items_Followed_Arr_Rel_Insert_Input>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  links?: Maybe<User_Links_Arr_Rel_Insert_Input>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['String']>
  notification_center_items?: Maybe<Notification_Center_Item_Arr_Rel_Insert_Input>
  notification_preferences?: Maybe<Notification_Preference_Arr_Rel_Insert_Input>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  orphan_parent?: Maybe<User_Obj_Rel_Insert_Input>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  orphans?: Maybe<User_Arr_Rel_Insert_Input>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  referred_users?: Maybe<User_Arr_Rel_Insert_Input>
  referrer_user?: Maybe<User_Obj_Rel_Insert_Input>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id?: Maybe<Scalars['name']>
  show_curations?: Maybe<Scalars['Boolean']>
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole?: Maybe<Scalars['Boolean']>
  similar_products_histories?: Maybe<Similar_Products_History_Arr_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_account_type?: Maybe<User_Account_Type_Obj_Rel_Insert_Input>
  user_carts_onboardings?: Maybe<User_Carts_Onboarding_Arr_Rel_Insert_Input>
  user_categories?: Maybe<User_Category_Arr_Rel_Insert_Input>
  user_ip_addresses?: Maybe<User_Ip_Address_Arr_Rel_Insert_Input>
  user_onboarding_sources?: Maybe<User_Onboarding_Source_Arr_Rel_Insert_Input>
  user_push_notification_tokens?: Maybe<User_Push_Notification_Token_Arr_Rel_Insert_Input>
  user_referrals?: Maybe<User_Referral_Arr_Rel_Insert_Input>
  user_shopping_preference?: Maybe<User_Shopping_Preferences_Obj_Rel_Insert_Input>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
  workflows?: Maybe<Workflow_Arr_Rel_Insert_Input>
}

/** To keep track of the ip addresses of users to help with debugging an issue */
export type User_Ip_Address = {
  __typename?: 'user_ip_address'
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  ip_address: Scalars['cidr']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_ip_address" */
export type User_Ip_Address_Aggregate = {
  __typename?: 'user_ip_address_aggregate'
  aggregate?: Maybe<User_Ip_Address_Aggregate_Fields>
  nodes: Array<User_Ip_Address>
}

export type User_Ip_Address_Aggregate_Bool_Exp = {
  count?: Maybe<User_Ip_Address_Aggregate_Bool_Exp_Count>
}

export type User_Ip_Address_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Ip_Address_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Ip_Address_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_ip_address" */
export type User_Ip_Address_Aggregate_Fields = {
  __typename?: 'user_ip_address_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Ip_Address_Max_Fields>
  min?: Maybe<User_Ip_Address_Min_Fields>
}

/** aggregate fields of "user_ip_address" */
export type User_Ip_Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Ip_Address_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_ip_address" */
export type User_Ip_Address_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Ip_Address_Max_Order_By>
  min?: Maybe<User_Ip_Address_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_ip_address" */
export type User_Ip_Address_Arr_Rel_Insert_Input = {
  data: Array<User_Ip_Address_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Ip_Address_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_ip_address". All fields are combined with a logical 'AND'. */
export type User_Ip_Address_Bool_Exp = {
  _and?: Maybe<Array<User_Ip_Address_Bool_Exp>>
  _not?: Maybe<User_Ip_Address_Bool_Exp>
  _or?: Maybe<Array<User_Ip_Address_Bool_Exp>>
  analytics_anonymous_id?: Maybe<Uuid_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  ip_address?: Maybe<Cidr_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_ip_address" */
export enum User_Ip_Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  IpAddressesPkey = 'ip_addresses_pkey',
  /** unique or primary key constraint on columns "ip_address", "user_id", "analytics_anonymous_id" */
  IpAddressesUserIdAnalyticsAnonymousIdIpAddressKey = 'ip_addresses_user_id_analytics_anonymous_id_ip_address_key',
}

/** input type for inserting data into table "user_ip_address" */
export type User_Ip_Address_Insert_Input = {
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  ip_address?: Maybe<Scalars['cidr']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Ip_Address_Max_Fields = {
  __typename?: 'user_ip_address_max_fields'
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_ip_address" */
export type User_Ip_Address_Max_Order_By = {
  analytics_anonymous_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Ip_Address_Min_Fields = {
  __typename?: 'user_ip_address_min_fields'
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_ip_address" */
export type User_Ip_Address_Min_Order_By = {
  analytics_anonymous_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_ip_address" */
export type User_Ip_Address_Mutation_Response = {
  __typename?: 'user_ip_address_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Ip_Address>
}

/** on_conflict condition type for table "user_ip_address" */
export type User_Ip_Address_On_Conflict = {
  constraint: User_Ip_Address_Constraint
  update_columns?: Array<User_Ip_Address_Update_Column>
  where?: Maybe<User_Ip_Address_Bool_Exp>
}

/** Ordering options when selecting data from "user_ip_address". */
export type User_Ip_Address_Order_By = {
  analytics_anonymous_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  ip_address?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_ip_address */
export type User_Ip_Address_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_ip_address" */
export enum User_Ip_Address_Select_Column {
  /** column name */
  AnalyticsAnonymousId = 'analytics_anonymous_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_ip_address" */
export type User_Ip_Address_Set_Input = {
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  ip_address?: Maybe<Scalars['cidr']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_ip_address" */
export type User_Ip_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Ip_Address_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Ip_Address_Stream_Cursor_Value_Input = {
  analytics_anonymous_id?: Maybe<Scalars['uuid']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  ip_address?: Maybe<Scalars['cidr']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_ip_address" */
export enum User_Ip_Address_Update_Column {
  /** column name */
  AnalyticsAnonymousId = 'analytics_anonymous_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IpAddress = 'ip_address',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Ip_Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Ip_Address_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Ip_Address_Bool_Exp
}

/** A table to track what items a user is following */
export type User_Items_Followed = {
  __typename?: 'user_items_followed'
  created_at: Scalars['timestamptz']
  /** An object relationship */
  followable_item?: Maybe<Followable_Item>
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  id: Scalars['uuid']
  notifiable_item_type: Notifiable_Item_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_items_followed" */
export type User_Items_Followed_Aggregate = {
  __typename?: 'user_items_followed_aggregate'
  aggregate?: Maybe<User_Items_Followed_Aggregate_Fields>
  nodes: Array<User_Items_Followed>
}

export type User_Items_Followed_Aggregate_Bool_Exp = {
  count?: Maybe<User_Items_Followed_Aggregate_Bool_Exp_Count>
}

export type User_Items_Followed_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Items_Followed_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Items_Followed_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_items_followed" */
export type User_Items_Followed_Aggregate_Fields = {
  __typename?: 'user_items_followed_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Items_Followed_Max_Fields>
  min?: Maybe<User_Items_Followed_Min_Fields>
}

/** aggregate fields of "user_items_followed" */
export type User_Items_Followed_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Items_Followed_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_items_followed" */
export type User_Items_Followed_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Items_Followed_Max_Order_By>
  min?: Maybe<User_Items_Followed_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_items_followed" */
export type User_Items_Followed_Arr_Rel_Insert_Input = {
  data: Array<User_Items_Followed_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_items_followed". All fields are combined with a logical 'AND'. */
export type User_Items_Followed_Bool_Exp = {
  _and?: Maybe<Array<User_Items_Followed_Bool_Exp>>
  _not?: Maybe<User_Items_Followed_Bool_Exp>
  _or?: Maybe<Array<User_Items_Followed_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  followable_item?: Maybe<Followable_Item_Bool_Exp>
  followable_item_id?: Maybe<Uuid_Comparison_Exp>
  followable_item_type?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_items_followed" */
export enum User_Items_Followed_Constraint {
  /** unique or primary key constraint on columns "user_id", "followable_item_id", "followable_item_type" */
  UserFollowPkey = 'user_follow_pkey',
  /** unique or primary key constraint on columns "id" */
  UserItemsFollowedIdKey = 'user_items_followed_id_key',
}

/** input type for inserting data into table "user_items_followed" */
export type User_Items_Followed_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item?: Maybe<Followable_Item_Obj_Rel_Insert_Input>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Items_Followed_Max_Fields = {
  __typename?: 'user_items_followed_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_items_followed" */
export type User_Items_Followed_Max_Order_By = {
  created_at?: Maybe<Order_By>
  followable_item_id?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Items_Followed_Min_Fields = {
  __typename?: 'user_items_followed_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_items_followed" */
export type User_Items_Followed_Min_Order_By = {
  created_at?: Maybe<Order_By>
  followable_item_id?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_items_followed" */
export type User_Items_Followed_Mutation_Response = {
  __typename?: 'user_items_followed_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Items_Followed>
}

/** input type for inserting object relation for remote table "user_items_followed" */
export type User_Items_Followed_Obj_Rel_Insert_Input = {
  data: User_Items_Followed_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Items_Followed_On_Conflict>
}

/** on_conflict condition type for table "user_items_followed" */
export type User_Items_Followed_On_Conflict = {
  constraint: User_Items_Followed_Constraint
  update_columns?: Array<User_Items_Followed_Update_Column>
  where?: Maybe<User_Items_Followed_Bool_Exp>
}

/** Ordering options when selecting data from "user_items_followed". */
export type User_Items_Followed_Order_By = {
  created_at?: Maybe<Order_By>
  followable_item?: Maybe<Followable_Item_Order_By>
  followable_item_id?: Maybe<Order_By>
  followable_item_type?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_items_followed */
export type User_Items_Followed_Pk_Columns_Input = {
  followable_item_id: Scalars['uuid']
  followable_item_type: Scalars['String']
  user_id: Scalars['String']
}

/** select columns of table "user_items_followed" */
export enum User_Items_Followed_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowableItemId = 'followable_item_id',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_items_followed" */
export type User_Items_Followed_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_items_followed" */
export type User_Items_Followed_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Items_Followed_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Items_Followed_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  followable_item_id?: Maybe<Scalars['uuid']>
  followable_item_type?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notifiable_item_type?: Maybe<Notifiable_Item_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_items_followed" */
export enum User_Items_Followed_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FollowableItemId = 'followable_item_id',
  /** column name */
  FollowableItemType = 'followable_item_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableItemType = 'notifiable_item_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Items_Followed_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Items_Followed_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Items_Followed_Bool_Exp
}

/** Used to capture user social links */
export type User_Links = {
  __typename?: 'user_links'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  link_type?: Maybe<Link_Type_Enum>
  /** An object relationship */
  type?: Maybe<Link_Type>
  updated_at: Scalars['timestamptz']
  url: Scalars['String']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_links" */
export type User_Links_Aggregate = {
  __typename?: 'user_links_aggregate'
  aggregate?: Maybe<User_Links_Aggregate_Fields>
  nodes: Array<User_Links>
}

export type User_Links_Aggregate_Bool_Exp = {
  count?: Maybe<User_Links_Aggregate_Bool_Exp_Count>
}

export type User_Links_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Links_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Links_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_links" */
export type User_Links_Aggregate_Fields = {
  __typename?: 'user_links_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Links_Max_Fields>
  min?: Maybe<User_Links_Min_Fields>
}

/** aggregate fields of "user_links" */
export type User_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Links_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_links" */
export type User_Links_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Links_Max_Order_By>
  min?: Maybe<User_Links_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_links" */
export type User_Links_Arr_Rel_Insert_Input = {
  data: Array<User_Links_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Links_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_links". All fields are combined with a logical 'AND'. */
export type User_Links_Bool_Exp = {
  _and?: Maybe<Array<User_Links_Bool_Exp>>
  _not?: Maybe<User_Links_Bool_Exp>
  _or?: Maybe<Array<User_Links_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  link_type?: Maybe<Link_Type_Enum_Comparison_Exp>
  type?: Maybe<Link_Type_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  url?: Maybe<String_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_links" */
export enum User_Links_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserLinksPkey = 'user_links_pkey',
  /** unique or primary key constraint on columns "user_id", "link_type" */
  UserLinksUserIdLinkTypeKey = 'user_links_user_id_link_type_key',
}

/** input type for inserting data into table "user_links" */
export type User_Links_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_type?: Maybe<Link_Type_Enum>
  type?: Maybe<Link_Type_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Links_Max_Fields = {
  __typename?: 'user_links_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_links" */
export type User_Links_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Links_Min_Fields = {
  __typename?: 'user_links_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_links" */
export type User_Links_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_links" */
export type User_Links_Mutation_Response = {
  __typename?: 'user_links_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Links>
}

/** on_conflict condition type for table "user_links" */
export type User_Links_On_Conflict = {
  constraint: User_Links_Constraint
  update_columns?: Array<User_Links_Update_Column>
  where?: Maybe<User_Links_Bool_Exp>
}

/** Ordering options when selecting data from "user_links". */
export type User_Links_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  link_type?: Maybe<Order_By>
  type?: Maybe<Link_Type_Order_By>
  updated_at?: Maybe<Order_By>
  url?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_links */
export type User_Links_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_links" */
export enum User_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_links" */
export type User_Links_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_type?: Maybe<Link_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_links" */
export type User_Links_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Links_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Links_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  link_type?: Maybe<Link_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  url?: Maybe<Scalars['String']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_links" */
export enum User_Links_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LinkType = 'link_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'user_id',
}

export type User_Links_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Links_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Links_Bool_Exp
}

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_display_name" */
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_email_md5_hash" */
  email_md5?: Maybe<Scalars['String']>
  /** A computed field, executes function "extension_version" */
  extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_first_app_install" */
  first_app_install?: Maybe<Scalars['String']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  ios_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  referrer_user_id?: Maybe<Scalars['String']>
  safari_extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_status" */
  subscription_status?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
  username?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  applied_as_creator_at?: Maybe<Order_By>
  bio?: Maybe<Order_By>
  bio_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  deleted_reason?: Maybe<Order_By>
  disabled_at?: Maybe<Order_By>
  disabled_reason?: Maybe<Order_By>
  email?: Maybe<Order_By>
  icon_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  installed_at?: Maybe<Order_By>
  name?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  orphaned_at?: Maybe<Order_By>
  orphaned_by?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  picture?: Maybe<Order_By>
  possible_email?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  username?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_display_name" */
  display_name?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_email_md5_hash" */
  email_md5?: Maybe<Scalars['String']>
  /** A computed field, executes function "extension_version" */
  extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_first_app_install" */
  first_app_install?: Maybe<Scalars['String']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  ios_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  referrer_user_id?: Maybe<Scalars['String']>
  safari_extension_version?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_status" */
  subscription_status?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
  username?: Maybe<Scalars['String']>
  version?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  applied_as_creator_at?: Maybe<Order_By>
  bio?: Maybe<Order_By>
  bio_link?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  deleted_reason?: Maybe<Order_By>
  disabled_at?: Maybe<Order_By>
  disabled_reason?: Maybe<Order_By>
  email?: Maybe<Order_By>
  icon_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  installed_at?: Maybe<Order_By>
  name?: Maybe<Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  orphaned_at?: Maybe<Order_By>
  orphaned_by?: Maybe<Order_By>
  phone?: Maybe<Order_By>
  picture?: Maybe<Order_By>
  possible_email?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  username?: Maybe<Order_By>
  version?: Maybe<Order_By>
}

/** response of any mutation on the table "user" */
export type User_Mutation_Response = {
  __typename?: 'user_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User>
}

/** input type for inserting object relation for remote table "user" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_On_Conflict>
}

/** on_conflict condition type for table "user" */
export type User_On_Conflict = {
  constraint: User_Constraint
  update_columns?: Array<User_Update_Column>
  where?: Maybe<User_Bool_Exp>
}

/** Onboarding source (how did you hear about us) selection by user */
export type User_Onboarding_Source = {
  __typename?: 'user_onboarding_source'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  onboarding_source: Onboarding_Sources_Enum
  other_source?: Maybe<Scalars['String']>
  /** An object relationship */
  source: Onboarding_Sources
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate = {
  __typename?: 'user_onboarding_source_aggregate'
  aggregate?: Maybe<User_Onboarding_Source_Aggregate_Fields>
  nodes: Array<User_Onboarding_Source>
}

export type User_Onboarding_Source_Aggregate_Bool_Exp = {
  count?: Maybe<User_Onboarding_Source_Aggregate_Bool_Exp_Count>
}

export type User_Onboarding_Source_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Onboarding_Source_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate_Fields = {
  __typename?: 'user_onboarding_source_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Onboarding_Source_Max_Fields>
  min?: Maybe<User_Onboarding_Source_Min_Fields>
}

/** aggregate fields of "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Onboarding_Source_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_onboarding_source" */
export type User_Onboarding_Source_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Onboarding_Source_Max_Order_By>
  min?: Maybe<User_Onboarding_Source_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_onboarding_source" */
export type User_Onboarding_Source_Arr_Rel_Insert_Input = {
  data: Array<User_Onboarding_Source_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Onboarding_Source_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_onboarding_source". All fields are combined with a logical 'AND'. */
export type User_Onboarding_Source_Bool_Exp = {
  _and?: Maybe<Array<User_Onboarding_Source_Bool_Exp>>
  _not?: Maybe<User_Onboarding_Source_Bool_Exp>
  _or?: Maybe<Array<User_Onboarding_Source_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  onboarding_source?: Maybe<Onboarding_Sources_Enum_Comparison_Exp>
  other_source?: Maybe<String_Comparison_Exp>
  source?: Maybe<Onboarding_Sources_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_onboarding_source" */
export enum User_Onboarding_Source_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserOnboardingSourcePkey = 'user_onboarding_source_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserOnboardingSourceUserIdKey = 'user_onboarding_source_user_id_key',
}

/** input type for inserting data into table "user_onboarding_source" */
export type User_Onboarding_Source_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  onboarding_source?: Maybe<Onboarding_Sources_Enum>
  other_source?: Maybe<Scalars['String']>
  source?: Maybe<Onboarding_Sources_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Onboarding_Source_Max_Fields = {
  __typename?: 'user_onboarding_source_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_onboarding_source" */
export type User_Onboarding_Source_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  other_source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Onboarding_Source_Min_Fields = {
  __typename?: 'user_onboarding_source_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_onboarding_source" */
export type User_Onboarding_Source_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  other_source?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_onboarding_source" */
export type User_Onboarding_Source_Mutation_Response = {
  __typename?: 'user_onboarding_source_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Onboarding_Source>
}

/** on_conflict condition type for table "user_onboarding_source" */
export type User_Onboarding_Source_On_Conflict = {
  constraint: User_Onboarding_Source_Constraint
  update_columns?: Array<User_Onboarding_Source_Update_Column>
  where?: Maybe<User_Onboarding_Source_Bool_Exp>
}

/** Ordering options when selecting data from "user_onboarding_source". */
export type User_Onboarding_Source_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  onboarding_source?: Maybe<Order_By>
  other_source?: Maybe<Order_By>
  source?: Maybe<Onboarding_Sources_Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_onboarding_source */
export type User_Onboarding_Source_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_onboarding_source" */
export enum User_Onboarding_Source_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnboardingSource = 'onboarding_source',
  /** column name */
  OtherSource = 'other_source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_onboarding_source" */
export type User_Onboarding_Source_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  onboarding_source?: Maybe<Onboarding_Sources_Enum>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_onboarding_source" */
export type User_Onboarding_Source_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Onboarding_Source_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Onboarding_Source_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  onboarding_source?: Maybe<Onboarding_Sources_Enum>
  other_source?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_onboarding_source" */
export enum User_Onboarding_Source_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnboardingSource = 'onboarding_source',
  /** column name */
  OtherSource = 'other_source',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Onboarding_Source_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Onboarding_Source_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Onboarding_Source_Bool_Exp
}

/** columns and relationships of "user_onboarding_state" */
export type User_Onboarding_State = {
  __typename?: 'user_onboarding_state'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "user_onboarding_state" */
export type User_Onboarding_State_Aggregate = {
  __typename?: 'user_onboarding_state_aggregate'
  aggregate?: Maybe<User_Onboarding_State_Aggregate_Fields>
  nodes: Array<User_Onboarding_State>
}

/** aggregate fields of "user_onboarding_state" */
export type User_Onboarding_State_Aggregate_Fields = {
  __typename?: 'user_onboarding_state_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Onboarding_State_Max_Fields>
  min?: Maybe<User_Onboarding_State_Min_Fields>
}

/** aggregate fields of "user_onboarding_state" */
export type User_Onboarding_State_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Onboarding_State_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_onboarding_state". All fields are combined with a logical 'AND'. */
export type User_Onboarding_State_Bool_Exp = {
  _and?: Maybe<Array<User_Onboarding_State_Bool_Exp>>
  _not?: Maybe<User_Onboarding_State_Bool_Exp>
  _or?: Maybe<Array<User_Onboarding_State_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_onboarding_state" */
export enum User_Onboarding_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  OnboardingStatePkey = 'onboarding_state_pkey',
}

export enum User_Onboarding_State_Enum {
  /** Product added to Carrot via cart */
  AddedToCart = 'added_to_cart',
  /** Opened Carrot extension after add to cart */
  OpenedExtension = 'opened_extension',
}

/** Boolean expression to compare columns of type "user_onboarding_state_enum". All fields are combined with logical 'AND'. */
export type User_Onboarding_State_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Onboarding_State_Enum>
  _in?: Maybe<Array<User_Onboarding_State_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Onboarding_State_Enum>
  _nin?: Maybe<Array<User_Onboarding_State_Enum>>
}

/** input type for inserting data into table "user_onboarding_state" */
export type User_Onboarding_State_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Onboarding_State_Max_Fields = {
  __typename?: 'user_onboarding_state_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Onboarding_State_Min_Fields = {
  __typename?: 'user_onboarding_state_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_onboarding_state" */
export type User_Onboarding_State_Mutation_Response = {
  __typename?: 'user_onboarding_state_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Onboarding_State>
}

/** input type for inserting object relation for remote table "user_onboarding_state" */
export type User_Onboarding_State_Obj_Rel_Insert_Input = {
  data: User_Onboarding_State_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Onboarding_State_On_Conflict>
}

/** on_conflict condition type for table "user_onboarding_state" */
export type User_Onboarding_State_On_Conflict = {
  constraint: User_Onboarding_State_Constraint
  update_columns?: Array<User_Onboarding_State_Update_Column>
  where?: Maybe<User_Onboarding_State_Bool_Exp>
}

/** Ordering options when selecting data from "user_onboarding_state". */
export type User_Onboarding_State_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_onboarding_state */
export type User_Onboarding_State_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_onboarding_state" */
export enum User_Onboarding_State_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_onboarding_state" */
export type User_Onboarding_State_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_onboarding_state" */
export type User_Onboarding_State_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Onboarding_State_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Onboarding_State_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_onboarding_state" */
export enum User_Onboarding_State_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Onboarding_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Onboarding_State_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Onboarding_State_Bool_Exp
}

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  active_cart_items_quantity?: Maybe<Order_By>
  affiliate_transactions_aggregate?: Maybe<Affiliate_Transaction_Aggregate_Order_By>
  anonymous?: Maybe<Order_By>
  app_versions_aggregate?: Maybe<User_App_Version_Aggregate_Order_By>
  applied_as_creator_at?: Maybe<Order_By>
  bio?: Maybe<Order_By>
  bio_link?: Maybe<Order_By>
  can_be_credited?: Maybe<Order_By>
  cart_item_likes_aggregate?: Maybe<Cart_Item_Like_Aggregate_Order_By>
  carts_aggregate?: Maybe<Cart_Aggregate_Order_By>
  carts_count?: Maybe<Order_By>
  collection_item_claims_aggregate?: Maybe<Collection_Item_Claim_Aggregate_Order_By>
  collections_aggregate?: Maybe<Collection_Aggregate_Order_By>
  collections_collaborating_aggregate?: Maybe<Collection_Collaborator_Aggregate_Order_By>
  commissions_cleared?: Maybe<Order_By>
  commissions_pending?: Maybe<Order_By>
  cover_image?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  deleted_reason?: Maybe<Order_By>
  disabled_at?: Maybe<Order_By>
  disabled_reason?: Maybe<Order_By>
  display_name?: Maybe<Order_By>
  email?: Maybe<Order_By>
  email_md5?: Maybe<Order_By>
  extension_version?: Maybe<Order_By>
  first_app_install?: Maybe<Order_By>
  flags?: Maybe<User_Flags_Order_By>
  follows_aggregate?: Maybe<User_Items_Followed_Aggregate_Order_By>
  has_accounts_email_computed?: Maybe<Order_By>
  has_billing_account?: Maybe<Order_By>
  has_claimed_username_computed?: Maybe<Order_By>
  has_email_set?: Maybe<Order_By>
  has_onboarded?: Maybe<Order_By>
  has_picture_set?: Maybe<Order_By>
  has_premium_subscription?: Maybe<Order_By>
  has_set_name_computed?: Maybe<Order_By>
  icon_order?: Maybe<Order_By>
  id?: Maybe<Order_By>
  in_on_campus_contest?: Maybe<Order_By>
  installed_at?: Maybe<Order_By>
  internal_account?: Maybe<Order_By>
  ios_version?: Maybe<Order_By>
  is_creator_computed?: Maybe<Order_By>
  is_curator_computed?: Maybe<Order_By>
  is_icon_computed?: Maybe<Order_By>
  links_aggregate?: Maybe<User_Links_Aggregate_Order_By>
  metadata?: Maybe<Order_By>
  mobile_badge_count?: Maybe<Order_By>
  name?: Maybe<Order_By>
  new_discovery_feed_items_count?: Maybe<Order_By>
  notification_center_items_aggregate?: Maybe<Notification_Center_Item_Aggregate_Order_By>
  notification_preferences_aggregate?: Maybe<Notification_Preference_Aggregate_Order_By>
  ogimage_fingerprint?: Maybe<Order_By>
  ogimage_fingerprint_updated?: Maybe<Order_By>
  onboarded?: Maybe<Order_By>
  orphan_parent?: Maybe<User_Order_By>
  orphaned_at?: Maybe<Order_By>
  orphaned_by?: Maybe<Order_By>
  orphans_aggregate?: Maybe<User_Aggregate_Order_By>
  phone?: Maybe<Order_By>
  picture?: Maybe<Order_By>
  possible_email?: Maybe<Order_By>
  probably_real?: Maybe<Order_By>
  referral_count_credited?: Maybe<Order_By>
  referral_count_total?: Maybe<Order_By>
  referral_count_within_promo_period?: Maybe<Order_By>
  referred_users_aggregate?: Maybe<User_Aggregate_Order_By>
  referrer_user?: Maybe<User_Order_By>
  referrer_user_id?: Maybe<Order_By>
  role?: Maybe<Order_By>
  safari_extension_version?: Maybe<Order_By>
  shopping_preference?: Maybe<Order_By>
  short_id?: Maybe<Order_By>
  show_curations?: Maybe<Order_By>
  show_onboarding?: Maybe<Order_By>
  show_rabbithole?: Maybe<Order_By>
  similar_products_histories_aggregate?: Maybe<Similar_Products_History_Aggregate_Order_By>
  subscription_status?: Maybe<Order_By>
  unread_notification_center_items?: Maybe<Order_By>
  unseen_cart_items?: Maybe<Order_By>
  unviewed_notification_center_items?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_account_type?: Maybe<User_Account_Type_Order_By>
  user_carts_onboardings_aggregate?: Maybe<User_Carts_Onboarding_Aggregate_Order_By>
  user_categories_aggregate?: Maybe<User_Category_Aggregate_Order_By>
  user_ip_addresses_aggregate?: Maybe<User_Ip_Address_Aggregate_Order_By>
  user_onboarding_sources_aggregate?: Maybe<User_Onboarding_Source_Aggregate_Order_By>
  user_points_multiplier?: Maybe<Order_By>
  user_push_notification_tokens_aggregate?: Maybe<User_Push_Notification_Token_Aggregate_Order_By>
  user_referrals_aggregate?: Maybe<User_Referral_Aggregate_Order_By>
  user_shopping_preference?: Maybe<User_Shopping_Preferences_Order_By>
  user_type?: Maybe<Order_By>
  username?: Maybe<Order_By>
  verified?: Maybe<Order_By>
  version?: Maybe<Order_By>
  waitlist?: Maybe<Order_By>
  workflows_aggregate?: Maybe<Workflow_Aggregate_Order_By>
}

/** A table to hold a user's phone contacts */
export type User_Phone_Contact = {
  __typename?: 'user_phone_contact'
  contact_id: Scalars['String']
  contact_info: Scalars['String']
  contact_info_type: Contact_Info_Type_Enum
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  invited: Scalars['Boolean']
  inviter_id?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_phone_contact" */
export type User_Phone_Contact_Aggregate = {
  __typename?: 'user_phone_contact_aggregate'
  aggregate?: Maybe<User_Phone_Contact_Aggregate_Fields>
  nodes: Array<User_Phone_Contact>
}

/** aggregate fields of "user_phone_contact" */
export type User_Phone_Contact_Aggregate_Fields = {
  __typename?: 'user_phone_contact_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Phone_Contact_Max_Fields>
  min?: Maybe<User_Phone_Contact_Min_Fields>
}

/** aggregate fields of "user_phone_contact" */
export type User_Phone_Contact_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Phone_Contact_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_phone_contact". All fields are combined with a logical 'AND'. */
export type User_Phone_Contact_Bool_Exp = {
  _and?: Maybe<Array<User_Phone_Contact_Bool_Exp>>
  _not?: Maybe<User_Phone_Contact_Bool_Exp>
  _or?: Maybe<Array<User_Phone_Contact_Bool_Exp>>
  contact_id?: Maybe<String_Comparison_Exp>
  contact_info?: Maybe<String_Comparison_Exp>
  contact_info_type?: Maybe<Contact_Info_Type_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  invited?: Maybe<Boolean_Comparison_Exp>
  inviter_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_phone_contact" */
export enum User_Phone_Contact_Constraint {
  /** unique or primary key constraint on columns "contact_id", "inviter_id", "contact_info_type", "contact_info" */
  UserPhoneContactInviterIdContactIdContactInfoTypeCont = 'user_phone_contact_inviter_id_contact_id_contact_info_type_cont',
  /** unique or primary key constraint on columns "id" */
  UserPhoneContactPkey = 'user_phone_contact_pkey',
}

/** input type for inserting data into table "user_phone_contact" */
export type User_Phone_Contact_Insert_Input = {
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  contact_info_type?: Maybe<Contact_Info_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invited?: Maybe<Scalars['Boolean']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Phone_Contact_Max_Fields = {
  __typename?: 'user_phone_contact_max_fields'
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Phone_Contact_Min_Fields = {
  __typename?: 'user_phone_contact_min_fields'
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_phone_contact" */
export type User_Phone_Contact_Mutation_Response = {
  __typename?: 'user_phone_contact_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Phone_Contact>
}

/** on_conflict condition type for table "user_phone_contact" */
export type User_Phone_Contact_On_Conflict = {
  constraint: User_Phone_Contact_Constraint
  update_columns?: Array<User_Phone_Contact_Update_Column>
  where?: Maybe<User_Phone_Contact_Bool_Exp>
}

/** Ordering options when selecting data from "user_phone_contact". */
export type User_Phone_Contact_Order_By = {
  contact_id?: Maybe<Order_By>
  contact_info?: Maybe<Order_By>
  contact_info_type?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  invited?: Maybe<Order_By>
  inviter_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_phone_contact */
export type User_Phone_Contact_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_phone_contact" */
export enum User_Phone_Contact_Select_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  ContactInfoType = 'contact_info_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Invited = 'invited',
  /** column name */
  InviterId = 'inviter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_phone_contact" */
export type User_Phone_Contact_Set_Input = {
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  contact_info_type?: Maybe<Contact_Info_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invited?: Maybe<Scalars['Boolean']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_phone_contact" */
export type User_Phone_Contact_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Phone_Contact_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Phone_Contact_Stream_Cursor_Value_Input = {
  contact_id?: Maybe<Scalars['String']>
  contact_info?: Maybe<Scalars['String']>
  contact_info_type?: Maybe<Contact_Info_Type_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  invited?: Maybe<Scalars['Boolean']>
  inviter_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_phone_contact" */
export enum User_Phone_Contact_Update_Column {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  ContactInfo = 'contact_info',
  /** column name */
  ContactInfoType = 'contact_info_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Invited = 'invited',
  /** column name */
  InviterId = 'inviter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Phone_Contact_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Phone_Contact_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Phone_Contact_Bool_Exp
}

/** primary key columns input for table: user */
export type User_Pk_Columns_Input = {
  id: Scalars['String']
}

/** columns and relationships of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary = {
  __typename?: 'user_points_redeemed_summary'
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Aggregate = {
  __typename?: 'user_points_redeemed_summary_aggregate'
  aggregate?: Maybe<User_Points_Redeemed_Summary_Aggregate_Fields>
  nodes: Array<User_Points_Redeemed_Summary>
}

/** aggregate fields of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Aggregate_Fields = {
  __typename?: 'user_points_redeemed_summary_aggregate_fields'
  avg?: Maybe<User_Points_Redeemed_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Points_Redeemed_Summary_Max_Fields>
  min?: Maybe<User_Points_Redeemed_Summary_Min_Fields>
  stddev?: Maybe<User_Points_Redeemed_Summary_Stddev_Fields>
  stddev_pop?: Maybe<User_Points_Redeemed_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Points_Redeemed_Summary_Stddev_Samp_Fields>
  sum?: Maybe<User_Points_Redeemed_Summary_Sum_Fields>
  var_pop?: Maybe<User_Points_Redeemed_Summary_Var_Pop_Fields>
  var_samp?: Maybe<User_Points_Redeemed_Summary_Var_Samp_Fields>
  variance?: Maybe<User_Points_Redeemed_Summary_Variance_Fields>
}

/** aggregate fields of "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Points_Redeemed_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Points_Redeemed_Summary_Avg_Fields = {
  __typename?: 'user_points_redeemed_summary_avg_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_points_redeemed_summary". All fields are combined with a logical 'AND'. */
export type User_Points_Redeemed_Summary_Bool_Exp = {
  _and?: Maybe<Array<User_Points_Redeemed_Summary_Bool_Exp>>
  _not?: Maybe<User_Points_Redeemed_Summary_Bool_Exp>
  _or?: Maybe<Array<User_Points_Redeemed_Summary_Bool_Exp>>
  points_redeemed?: Maybe<Numeric_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type User_Points_Redeemed_Summary_Max_Fields = {
  __typename?: 'user_points_redeemed_summary_max_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Points_Redeemed_Summary_Min_Fields = {
  __typename?: 'user_points_redeemed_summary_min_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "user_points_redeemed_summary". */
export type User_Points_Redeemed_Summary_Order_By = {
  points_redeemed?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_points_redeemed_summary" */
export enum User_Points_Redeemed_Summary_Select_Column {
  /** column name */
  PointsRedeemed = 'points_redeemed',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type User_Points_Redeemed_Summary_Stddev_Fields = {
  __typename?: 'user_points_redeemed_summary_stddev_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Points_Redeemed_Summary_Stddev_Pop_Fields = {
  __typename?: 'user_points_redeemed_summary_stddev_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Points_Redeemed_Summary_Stddev_Samp_Fields = {
  __typename?: 'user_points_redeemed_summary_stddev_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_points_redeemed_summary" */
export type User_Points_Redeemed_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Points_Redeemed_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Points_Redeemed_Summary_Stream_Cursor_Value_Input = {
  points_redeemed?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Points_Redeemed_Summary_Sum_Fields = {
  __typename?: 'user_points_redeemed_summary_sum_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type User_Points_Redeemed_Summary_Var_Pop_Fields = {
  __typename?: 'user_points_redeemed_summary_var_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Points_Redeemed_Summary_Var_Samp_Fields = {
  __typename?: 'user_points_redeemed_summary_var_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Points_Redeemed_Summary_Variance_Fields = {
  __typename?: 'user_points_redeemed_summary_variance_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** columns and relationships of "user_points_summary" */
export type User_Points_Summary = {
  __typename?: 'user_points_summary'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_points_summary" */
export type User_Points_Summary_Aggregate = {
  __typename?: 'user_points_summary_aggregate'
  aggregate?: Maybe<User_Points_Summary_Aggregate_Fields>
  nodes: Array<User_Points_Summary>
}

/** aggregate fields of "user_points_summary" */
export type User_Points_Summary_Aggregate_Fields = {
  __typename?: 'user_points_summary_aggregate_fields'
  avg?: Maybe<User_Points_Summary_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Points_Summary_Max_Fields>
  min?: Maybe<User_Points_Summary_Min_Fields>
  stddev?: Maybe<User_Points_Summary_Stddev_Fields>
  stddev_pop?: Maybe<User_Points_Summary_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Points_Summary_Stddev_Samp_Fields>
  sum?: Maybe<User_Points_Summary_Sum_Fields>
  var_pop?: Maybe<User_Points_Summary_Var_Pop_Fields>
  var_samp?: Maybe<User_Points_Summary_Var_Samp_Fields>
  variance?: Maybe<User_Points_Summary_Variance_Fields>
}

/** aggregate fields of "user_points_summary" */
export type User_Points_Summary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Points_Summary_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Points_Summary_Avg_Fields = {
  __typename?: 'user_points_summary_avg_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_points_summary". All fields are combined with a logical 'AND'. */
export type User_Points_Summary_Bool_Exp = {
  _and?: Maybe<Array<User_Points_Summary_Bool_Exp>>
  _not?: Maybe<User_Points_Summary_Bool_Exp>
  _or?: Maybe<Array<User_Points_Summary_Bool_Exp>>
  multiplier?: Maybe<Numeric_Comparison_Exp>
  pending_points?: Maybe<Numeric_Comparison_Exp>
  total_points?: Maybe<Numeric_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** aggregate max on columns */
export type User_Points_Summary_Max_Fields = {
  __typename?: 'user_points_summary_max_fields'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Points_Summary_Min_Fields = {
  __typename?: 'user_points_summary_min_fields'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** Ordering options when selecting data from "user_points_summary". */
export type User_Points_Summary_Order_By = {
  multiplier?: Maybe<Order_By>
  pending_points?: Maybe<Order_By>
  total_points?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** select columns of table "user_points_summary" */
export enum User_Points_Summary_Select_Column {
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  PendingPoints = 'pending_points',
  /** column name */
  TotalPoints = 'total_points',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type User_Points_Summary_Stddev_Fields = {
  __typename?: 'user_points_summary_stddev_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Points_Summary_Stddev_Pop_Fields = {
  __typename?: 'user_points_summary_stddev_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Points_Summary_Stddev_Samp_Fields = {
  __typename?: 'user_points_summary_stddev_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_points_summary" */
export type User_Points_Summary_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Points_Summary_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Points_Summary_Stream_Cursor_Value_Input = {
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Points_Summary_Sum_Fields = {
  __typename?: 'user_points_summary_sum_fields'
  multiplier?: Maybe<Scalars['numeric']>
  pending_points?: Maybe<Scalars['numeric']>
  total_points?: Maybe<Scalars['numeric']>
}

/** aggregate var_pop on columns */
export type User_Points_Summary_Var_Pop_Fields = {
  __typename?: 'user_points_summary_var_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Points_Summary_Var_Samp_Fields = {
  __typename?: 'user_points_summary_var_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Points_Summary_Variance_Fields = {
  __typename?: 'user_points_summary_variance_fields'
  multiplier?: Maybe<Scalars['Float']>
  pending_points?: Maybe<Scalars['Float']>
  total_points?: Maybe<Scalars['Float']>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>
}

/** Table to track premium subscription status of a user */
export type User_Purchase_Subscription = {
  __typename?: 'user_purchase_subscription'
  created_at: Scalars['timestamptz']
  environment: Scalars['String']
  id: Scalars['uuid']
  premium: Scalars['Boolean']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "user_purchase_subscription" */
export type User_Purchase_Subscription_Aggregate = {
  __typename?: 'user_purchase_subscription_aggregate'
  aggregate?: Maybe<User_Purchase_Subscription_Aggregate_Fields>
  nodes: Array<User_Purchase_Subscription>
}

/** aggregate fields of "user_purchase_subscription" */
export type User_Purchase_Subscription_Aggregate_Fields = {
  __typename?: 'user_purchase_subscription_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Purchase_Subscription_Max_Fields>
  min?: Maybe<User_Purchase_Subscription_Min_Fields>
}

/** aggregate fields of "user_purchase_subscription" */
export type User_Purchase_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Purchase_Subscription_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_purchase_subscription". All fields are combined with a logical 'AND'. */
export type User_Purchase_Subscription_Bool_Exp = {
  _and?: Maybe<Array<User_Purchase_Subscription_Bool_Exp>>
  _not?: Maybe<User_Purchase_Subscription_Bool_Exp>
  _or?: Maybe<Array<User_Purchase_Subscription_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  environment?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  premium?: Maybe<Boolean_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_purchase_subscription" */
export enum User_Purchase_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPurchaseSubscriptionPkey = 'user_purchase_subscription_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserPurchaseSubscriptionUserIdKey = 'user_purchase_subscription_user_id_key',
}

/** input type for inserting data into table "user_purchase_subscription" */
export type User_Purchase_Subscription_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  premium?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Purchase_Subscription_Max_Fields = {
  __typename?: 'user_purchase_subscription_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Purchase_Subscription_Min_Fields = {
  __typename?: 'user_purchase_subscription_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_purchase_subscription" */
export type User_Purchase_Subscription_Mutation_Response = {
  __typename?: 'user_purchase_subscription_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Purchase_Subscription>
}

/** on_conflict condition type for table "user_purchase_subscription" */
export type User_Purchase_Subscription_On_Conflict = {
  constraint: User_Purchase_Subscription_Constraint
  update_columns?: Array<User_Purchase_Subscription_Update_Column>
  where?: Maybe<User_Purchase_Subscription_Bool_Exp>
}

/** Ordering options when selecting data from "user_purchase_subscription". */
export type User_Purchase_Subscription_Order_By = {
  created_at?: Maybe<Order_By>
  environment?: Maybe<Order_By>
  id?: Maybe<Order_By>
  premium?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_purchase_subscription */
export type User_Purchase_Subscription_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_purchase_subscription" */
export enum User_Purchase_Subscription_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_purchase_subscription" */
export type User_Purchase_Subscription_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  premium?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_purchase_subscription" */
export type User_Purchase_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Purchase_Subscription_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Purchase_Subscription_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  premium?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_purchase_subscription" */
export enum User_Purchase_Subscription_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Purchase_Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Purchase_Subscription_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Purchase_Subscription_Bool_Exp
}

/** columns and relationships of "user_purchases" */
export type User_Purchases = {
  __typename?: 'user_purchases'
  created_at: Scalars['timestamptz']
  environment: Scalars['String']
  id: Scalars['uuid']
  original_transaction_id: Scalars['String']
  product_id: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "user_purchases" */
export type User_Purchases_Aggregate = {
  __typename?: 'user_purchases_aggregate'
  aggregate?: Maybe<User_Purchases_Aggregate_Fields>
  nodes: Array<User_Purchases>
}

/** aggregate fields of "user_purchases" */
export type User_Purchases_Aggregate_Fields = {
  __typename?: 'user_purchases_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Purchases_Max_Fields>
  min?: Maybe<User_Purchases_Min_Fields>
}

/** aggregate fields of "user_purchases" */
export type User_Purchases_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Purchases_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_purchases". All fields are combined with a logical 'AND'. */
export type User_Purchases_Bool_Exp = {
  _and?: Maybe<Array<User_Purchases_Bool_Exp>>
  _not?: Maybe<User_Purchases_Bool_Exp>
  _or?: Maybe<Array<User_Purchases_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  environment?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  original_transaction_id?: Maybe<String_Comparison_Exp>
  product_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_purchases" */
export enum User_Purchases_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPurchasesPkey = 'user_purchases_pkey',
  /** unique or primary key constraint on columns "original_transaction_id", "user_id" */
  UserPurchasesUserIdOriginalTransactionIdKey = 'user_purchases_user_id_original_transaction_id_key',
}

/** input type for inserting data into table "user_purchases" */
export type User_Purchases_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Purchases_Max_Fields = {
  __typename?: 'user_purchases_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Purchases_Min_Fields = {
  __typename?: 'user_purchases_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_purchases" */
export type User_Purchases_Mutation_Response = {
  __typename?: 'user_purchases_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Purchases>
}

/** on_conflict condition type for table "user_purchases" */
export type User_Purchases_On_Conflict = {
  constraint: User_Purchases_Constraint
  update_columns?: Array<User_Purchases_Update_Column>
  where?: Maybe<User_Purchases_Bool_Exp>
}

/** Ordering options when selecting data from "user_purchases". */
export type User_Purchases_Order_By = {
  created_at?: Maybe<Order_By>
  environment?: Maybe<Order_By>
  id?: Maybe<Order_By>
  original_transaction_id?: Maybe<Order_By>
  product_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_purchases */
export type User_Purchases_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_purchases" */
export enum User_Purchases_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalTransactionId = 'original_transaction_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_purchases" */
export type User_Purchases_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_purchases" */
export type User_Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Purchases_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Purchases_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  environment?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  original_transaction_id?: Maybe<Scalars['String']>
  product_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_purchases" */
export enum User_Purchases_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  OriginalTransactionId = 'original_transaction_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Purchases_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Purchases_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Purchases_Bool_Exp
}

/** columns and relationships of "user_push_notification" */
export type User_Push_Notification = {
  __typename?: 'user_push_notification'
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  /** An array relationship */
  push_notification_status: Array<Push_Notification_Status>
  /** An aggregate relationship */
  push_notification_status_aggregate: Push_Notification_Status_Aggregate
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationCriteriaArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationPayloadArgs = {
  path?: Maybe<Scalars['String']>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationPush_Notification_StatusArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** columns and relationships of "user_push_notification" */
export type User_Push_NotificationPush_Notification_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Push_Notification_Status_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Push_Notification_Status_Order_By>>
  where?: Maybe<Push_Notification_Status_Bool_Exp>
}

/** aggregated selection of "user_push_notification" */
export type User_Push_Notification_Aggregate = {
  __typename?: 'user_push_notification_aggregate'
  aggregate?: Maybe<User_Push_Notification_Aggregate_Fields>
  nodes: Array<User_Push_Notification>
}

/** aggregate fields of "user_push_notification" */
export type User_Push_Notification_Aggregate_Fields = {
  __typename?: 'user_push_notification_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Push_Notification_Max_Fields>
  min?: Maybe<User_Push_Notification_Min_Fields>
}

/** aggregate fields of "user_push_notification" */
export type User_Push_Notification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Push_Notification_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Push_Notification_Append_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "user_push_notification". All fields are combined with a logical 'AND'. */
export type User_Push_Notification_Bool_Exp = {
  _and?: Maybe<Array<User_Push_Notification_Bool_Exp>>
  _not?: Maybe<User_Push_Notification_Bool_Exp>
  _or?: Maybe<Array<User_Push_Notification_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  criteria?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notifiable_id?: Maybe<String_Comparison_Exp>
  notifiable_type?: Maybe<String_Comparison_Exp>
  payload?: Maybe<Jsonb_Comparison_Exp>
  push_notification_status?: Maybe<Push_Notification_Status_Bool_Exp>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Bool_Exp>
  show_after?: Maybe<Timestamptz_Comparison_Exp>
  show_before?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Push_Notification_Delete_At_Path_Input = {
  criteria?: Maybe<Array<Scalars['String']>>
  payload?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Push_Notification_Delete_Elem_Input = {
  criteria?: Maybe<Scalars['Int']>
  payload?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Push_Notification_Delete_Key_Input = {
  criteria?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "user_push_notification" */
export type User_Push_Notification_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  push_notification_status?: Maybe<Push_Notification_Status_Arr_Rel_Insert_Input>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type User_Push_Notification_Max_Fields = {
  __typename?: 'user_push_notification_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type User_Push_Notification_Min_Fields = {
  __typename?: 'user_push_notification_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "user_push_notification" */
export type User_Push_Notification_Mutation_Response = {
  __typename?: 'user_push_notification_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Push_Notification>
}

/** Ordering options when selecting data from "user_push_notification". */
export type User_Push_Notification_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  criteria?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notifiable_id?: Maybe<Order_By>
  notifiable_type?: Maybe<Order_By>
  payload?: Maybe<Order_By>
  push_notification_status_aggregate?: Maybe<Push_Notification_Status_Aggregate_Order_By>
  show_after?: Maybe<Order_By>
  show_before?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Push_Notification_Prepend_Input = {
  criteria?: Maybe<Scalars['jsonb']>
  payload?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_push_notification" */
export enum User_Push_Notification_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  ShowAfter = 'show_after',
  /** column name */
  ShowBefore = 'show_before',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_push_notification" */
export type User_Push_Notification_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** Streaming cursor of the table "user_push_notification" */
export type User_Push_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Push_Notification_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Push_Notification_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  criteria?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  notifiable_id?: Maybe<Scalars['String']>
  notifiable_type?: Maybe<Scalars['String']>
  payload?: Maybe<Scalars['jsonb']>
  show_after?: Maybe<Scalars['timestamptz']>
  show_before?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** A table to associate expo push notification tokens with users */
export type User_Push_Notification_Token = {
  __typename?: 'user_push_notification_token'
  active: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  token: Scalars['String']
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
}

/** aggregated selection of "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate = {
  __typename?: 'user_push_notification_token_aggregate'
  aggregate?: Maybe<User_Push_Notification_Token_Aggregate_Fields>
  nodes: Array<User_Push_Notification_Token>
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp = {
  bool_and?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And>
  bool_or?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or>
  count?: Maybe<User_Push_Notification_Token_Aggregate_Bool_Exp_Count>
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Push_Notification_Token_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Push_Notification_Token_Bool_Exp>
  predicate: Boolean_Comparison_Exp
}

export type User_Push_Notification_Token_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Push_Notification_Token_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate_Fields = {
  __typename?: 'user_push_notification_token_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Push_Notification_Token_Max_Fields>
  min?: Maybe<User_Push_Notification_Token_Min_Fields>
}

/** aggregate fields of "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Push_Notification_Token_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_push_notification_token" */
export type User_Push_Notification_Token_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<User_Push_Notification_Token_Max_Order_By>
  min?: Maybe<User_Push_Notification_Token_Min_Order_By>
}

/** input type for inserting array relation for remote table "user_push_notification_token" */
export type User_Push_Notification_Token_Arr_Rel_Insert_Input = {
  data: Array<User_Push_Notification_Token_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** Boolean expression to filter rows from the table "user_push_notification_token". All fields are combined with a logical 'AND'. */
export type User_Push_Notification_Token_Bool_Exp = {
  _and?: Maybe<Array<User_Push_Notification_Token_Bool_Exp>>
  _not?: Maybe<User_Push_Notification_Token_Bool_Exp>
  _or?: Maybe<Array<User_Push_Notification_Token_Bool_Exp>>
  active?: Maybe<Boolean_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  token?: Maybe<String_Comparison_Exp>
  type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_push_notification_token" */
export enum User_Push_Notification_Token_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserPushNotificationTokenPkey = 'user_push_notification_token_pkey',
  /** unique or primary key constraint on columns "user_id", "token" */
  UserPushNotificationTokenUserIdTokenKey = 'user_push_notification_token_user_id_token_key',
}

/** input type for inserting data into table "user_push_notification_token" */
export type User_Push_Notification_Token_Insert_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Push_Notification_Token_Max_Fields = {
  __typename?: 'user_push_notification_token_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "user_push_notification_token" */
export type User_Push_Notification_Token_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  token?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Push_Notification_Token_Min_Fields = {
  __typename?: 'user_push_notification_token_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "user_push_notification_token" */
export type User_Push_Notification_Token_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  token?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_push_notification_token" */
export type User_Push_Notification_Token_Mutation_Response = {
  __typename?: 'user_push_notification_token_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Push_Notification_Token>
}

/** input type for inserting object relation for remote table "user_push_notification_token" */
export type User_Push_Notification_Token_Obj_Rel_Insert_Input = {
  data: User_Push_Notification_Token_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Push_Notification_Token_On_Conflict>
}

/** on_conflict condition type for table "user_push_notification_token" */
export type User_Push_Notification_Token_On_Conflict = {
  constraint: User_Push_Notification_Token_Constraint
  update_columns?: Array<User_Push_Notification_Token_Update_Column>
  where?: Maybe<User_Push_Notification_Token_Bool_Exp>
}

/** Ordering options when selecting data from "user_push_notification_token". */
export type User_Push_Notification_Token_Order_By = {
  active?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  token?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_push_notification_token */
export type User_Push_Notification_Token_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_push_notification_token_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "user_push_notification_token_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Select_Column_User_Push_Notification_Token_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** input type for updating data in table "user_push_notification_token" */
export type User_Push_Notification_Token_Set_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_push_notification_token" */
export type User_Push_Notification_Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Push_Notification_Token_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Push_Notification_Token_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  token?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_push_notification_token" */
export enum User_Push_Notification_Token_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Push_Notification_Token_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Push_Notification_Token_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Push_Notification_Token_Bool_Exp
}

export type User_Push_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Push_Notification_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Push_Notification_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Push_Notification_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Push_Notification_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Push_Notification_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Push_Notification_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Push_Notification_Bool_Exp
}

/** Keep track of user referrals for cash reward payouts */
export type User_Referral = {
  __typename?: 'user_referral'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  promo_period_id?: Maybe<Scalars['uuid']>
  /** An object relationship */
  referred_user: User
  referred_user_id: Scalars['String']
  /** An object relationship */
  referrer_user: User
  referrer_user_id: Scalars['String']
  reward_amount: Scalars['numeric']
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at: Scalars['timestamptz']
  /** Computed field to determine if the referral is within the promo period. */
  within_promo_period?: Maybe<Scalars['Boolean']>
}

/** aggregated selection of "user_referral" */
export type User_Referral_Aggregate = {
  __typename?: 'user_referral_aggregate'
  aggregate?: Maybe<User_Referral_Aggregate_Fields>
  nodes: Array<User_Referral>
}

export type User_Referral_Aggregate_Bool_Exp = {
  count?: Maybe<User_Referral_Aggregate_Bool_Exp_Count>
}

export type User_Referral_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Referral_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Referral_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_referral" */
export type User_Referral_Aggregate_Fields = {
  __typename?: 'user_referral_aggregate_fields'
  avg?: Maybe<User_Referral_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Referral_Max_Fields>
  min?: Maybe<User_Referral_Min_Fields>
  stddev?: Maybe<User_Referral_Stddev_Fields>
  stddev_pop?: Maybe<User_Referral_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Referral_Stddev_Samp_Fields>
  sum?: Maybe<User_Referral_Sum_Fields>
  var_pop?: Maybe<User_Referral_Var_Pop_Fields>
  var_samp?: Maybe<User_Referral_Var_Samp_Fields>
  variance?: Maybe<User_Referral_Variance_Fields>
}

/** aggregate fields of "user_referral" */
export type User_Referral_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Referral_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_referral" */
export type User_Referral_Aggregate_Order_By = {
  avg?: Maybe<User_Referral_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Referral_Max_Order_By>
  min?: Maybe<User_Referral_Min_Order_By>
  stddev?: Maybe<User_Referral_Stddev_Order_By>
  stddev_pop?: Maybe<User_Referral_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Referral_Stddev_Samp_Order_By>
  sum?: Maybe<User_Referral_Sum_Order_By>
  var_pop?: Maybe<User_Referral_Var_Pop_Order_By>
  var_samp?: Maybe<User_Referral_Var_Samp_Order_By>
  variance?: Maybe<User_Referral_Variance_Order_By>
}

/** input type for inserting array relation for remote table "user_referral" */
export type User_Referral_Arr_Rel_Insert_Input = {
  data: Array<User_Referral_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Referral_On_Conflict>
}

/** aggregate avg on columns */
export type User_Referral_Avg_Fields = {
  __typename?: 'user_referral_avg_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_referral" */
export type User_Referral_Avg_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_referral". All fields are combined with a logical 'AND'. */
export type User_Referral_Bool_Exp = {
  _and?: Maybe<Array<User_Referral_Bool_Exp>>
  _not?: Maybe<User_Referral_Bool_Exp>
  _or?: Maybe<Array<User_Referral_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  promo_period_id?: Maybe<Uuid_Comparison_Exp>
  referred_user?: Maybe<User_Bool_Exp>
  referred_user_id?: Maybe<String_Comparison_Exp>
  referrer_user?: Maybe<User_Bool_Exp>
  referrer_user_id?: Maybe<String_Comparison_Exp>
  reward_amount?: Maybe<Numeric_Comparison_Exp>
  reward_redeemed_at?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  within_promo_period?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "user_referral" */
export enum User_Referral_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserReferralPkey = 'user_referral_pkey',
  /** unique or primary key constraint on columns "referred_user_id" */
  UserReferredUserIdKey = 'user_referred_user_id_key',
}

/** input type for incrementing numeric columns in table "user_referral" */
export type User_Referral_Inc_Input = {
  reward_amount?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_referral" */
export type User_Referral_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user?: Maybe<User_Obj_Rel_Insert_Input>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user?: Maybe<User_Obj_Rel_Insert_Input>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type User_Referral_Max_Fields = {
  __typename?: 'user_referral_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "user_referral" */
export type User_Referral_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_period_id?: Maybe<Order_By>
  referred_user_id?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  reward_amount?: Maybe<Order_By>
  reward_redeemed_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Referral_Min_Fields = {
  __typename?: 'user_referral_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "user_referral" */
export type User_Referral_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_period_id?: Maybe<Order_By>
  referred_user_id?: Maybe<Order_By>
  referrer_user_id?: Maybe<Order_By>
  reward_amount?: Maybe<Order_By>
  reward_redeemed_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "user_referral" */
export type User_Referral_Mutation_Response = {
  __typename?: 'user_referral_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Referral>
}

/** on_conflict condition type for table "user_referral" */
export type User_Referral_On_Conflict = {
  constraint: User_Referral_Constraint
  update_columns?: Array<User_Referral_Update_Column>
  where?: Maybe<User_Referral_Bool_Exp>
}

/** Ordering options when selecting data from "user_referral". */
export type User_Referral_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  promo_period_id?: Maybe<Order_By>
  referred_user?: Maybe<User_Order_By>
  referred_user_id?: Maybe<Order_By>
  referrer_user?: Maybe<User_Order_By>
  referrer_user_id?: Maybe<Order_By>
  reward_amount?: Maybe<Order_By>
  reward_redeemed_at?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  within_promo_period?: Maybe<Order_By>
}

/** primary key columns input for table: user_referral */
export type User_Referral_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_referral" */
export enum User_Referral_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoPeriodId = 'promo_period_id',
  /** column name */
  ReferredUserId = 'referred_user_id',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  RewardAmount = 'reward_amount',
  /** column name */
  RewardRedeemedAt = 'reward_redeemed_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_referral" */
export type User_Referral_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type User_Referral_Stddev_Fields = {
  __typename?: 'user_referral_stddev_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_referral" */
export type User_Referral_Stddev_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Referral_Stddev_Pop_Fields = {
  __typename?: 'user_referral_stddev_pop_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_referral" */
export type User_Referral_Stddev_Pop_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Referral_Stddev_Samp_Fields = {
  __typename?: 'user_referral_stddev_samp_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_referral" */
export type User_Referral_Stddev_Samp_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_referral" */
export type User_Referral_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Referral_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Referral_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  promo_period_id?: Maybe<Scalars['uuid']>
  referred_user_id?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  reward_amount?: Maybe<Scalars['numeric']>
  reward_redeemed_at?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type User_Referral_Sum_Fields = {
  __typename?: 'user_referral_sum_fields'
  reward_amount?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user_referral" */
export type User_Referral_Sum_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** update columns of table "user_referral" */
export enum User_Referral_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PromoPeriodId = 'promo_period_id',
  /** column name */
  ReferredUserId = 'referred_user_id',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  RewardAmount = 'reward_amount',
  /** column name */
  RewardRedeemedAt = 'reward_redeemed_at',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type User_Referral_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Referral_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Referral_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Referral_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Referral_Var_Pop_Fields = {
  __typename?: 'user_referral_var_pop_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_referral" */
export type User_Referral_Var_Pop_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Referral_Var_Samp_Fields = {
  __typename?: 'user_referral_var_samp_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_referral" */
export type User_Referral_Var_Samp_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Referral_Variance_Fields = {
  __typename?: 'user_referral_variance_fields'
  reward_amount?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_referral" */
export type User_Referral_Variance_Order_By = {
  reward_amount?: Maybe<Order_By>
}

/** Used for one-off reward multiplier configs per user action */
export type User_Reward_Multiplier_Config = {
  __typename?: 'user_reward_multiplier_config'
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at: Scalars['timestamptz']
  expires_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  multiplier: Scalars['numeric']
  /** An object relationship */
  rewardable_action_name?: Maybe<Rewardable_Action_Names>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Aggregate = {
  __typename?: 'user_reward_multiplier_config_aggregate'
  aggregate?: Maybe<User_Reward_Multiplier_Config_Aggregate_Fields>
  nodes: Array<User_Reward_Multiplier_Config>
}

/** aggregate fields of "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Aggregate_Fields = {
  __typename?: 'user_reward_multiplier_config_aggregate_fields'
  avg?: Maybe<User_Reward_Multiplier_Config_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Reward_Multiplier_Config_Max_Fields>
  min?: Maybe<User_Reward_Multiplier_Config_Min_Fields>
  stddev?: Maybe<User_Reward_Multiplier_Config_Stddev_Fields>
  stddev_pop?: Maybe<User_Reward_Multiplier_Config_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Reward_Multiplier_Config_Stddev_Samp_Fields>
  sum?: Maybe<User_Reward_Multiplier_Config_Sum_Fields>
  var_pop?: Maybe<User_Reward_Multiplier_Config_Var_Pop_Fields>
  var_samp?: Maybe<User_Reward_Multiplier_Config_Var_Samp_Fields>
  variance?: Maybe<User_Reward_Multiplier_Config_Variance_Fields>
}

/** aggregate fields of "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Reward_Multiplier_Config_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Reward_Multiplier_Config_Avg_Fields = {
  __typename?: 'user_reward_multiplier_config_avg_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_reward_multiplier_config". All fields are combined with a logical 'AND'. */
export type User_Reward_Multiplier_Config_Bool_Exp = {
  _and?: Maybe<Array<User_Reward_Multiplier_Config_Bool_Exp>>
  _not?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
  _or?: Maybe<Array<User_Reward_Multiplier_Config_Bool_Exp>>
  action_name?: Maybe<Rewardable_Action_Names_Enum_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  expires_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  multiplier?: Maybe<Numeric_Comparison_Exp>
  rewardable_action_name?: Maybe<Rewardable_Action_Names_Bool_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_reward_multiplier_config" */
export enum User_Reward_Multiplier_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRewardMultiplierConfigPkey = 'user_reward_multiplier_config_pkey',
}

/** input type for incrementing numeric columns in table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Inc_Input = {
  multiplier?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Insert_Input = {
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  rewardable_action_name?: Maybe<Rewardable_Action_Names_Obj_Rel_Insert_Input>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Reward_Multiplier_Config_Max_Fields = {
  __typename?: 'user_reward_multiplier_config_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Reward_Multiplier_Config_Min_Fields = {
  __typename?: 'user_reward_multiplier_config_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Mutation_Response = {
  __typename?: 'user_reward_multiplier_config_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Reward_Multiplier_Config>
}

/** on_conflict condition type for table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_On_Conflict = {
  constraint: User_Reward_Multiplier_Config_Constraint
  update_columns?: Array<User_Reward_Multiplier_Config_Update_Column>
  where?: Maybe<User_Reward_Multiplier_Config_Bool_Exp>
}

/** Ordering options when selecting data from "user_reward_multiplier_config". */
export type User_Reward_Multiplier_Config_Order_By = {
  action_name?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  expires_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  multiplier?: Maybe<Order_By>
  rewardable_action_name?: Maybe<Rewardable_Action_Names_Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_reward_multiplier_config */
export type User_Reward_Multiplier_Config_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_reward_multiplier_config" */
export enum User_Reward_Multiplier_Config_Select_Column {
  /** column name */
  ActionName = 'action_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Set_Input = {
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Reward_Multiplier_Config_Stddev_Fields = {
  __typename?: 'user_reward_multiplier_config_stddev_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Reward_Multiplier_Config_Stddev_Pop_Fields = {
  __typename?: 'user_reward_multiplier_config_stddev_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Reward_Multiplier_Config_Stddev_Samp_Fields = {
  __typename?: 'user_reward_multiplier_config_stddev_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_reward_multiplier_config" */
export type User_Reward_Multiplier_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Reward_Multiplier_Config_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Reward_Multiplier_Config_Stream_Cursor_Value_Input = {
  action_name?: Maybe<Rewardable_Action_Names_Enum>
  created_at?: Maybe<Scalars['timestamptz']>
  expires_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  multiplier?: Maybe<Scalars['numeric']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Reward_Multiplier_Config_Sum_Fields = {
  __typename?: 'user_reward_multiplier_config_sum_fields'
  multiplier?: Maybe<Scalars['numeric']>
}

/** update columns of table "user_reward_multiplier_config" */
export enum User_Reward_Multiplier_Config_Update_Column {
  /** column name */
  ActionName = 'action_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  Multiplier = 'multiplier',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Reward_Multiplier_Config_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Reward_Multiplier_Config_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Reward_Multiplier_Config_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Reward_Multiplier_Config_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Reward_Multiplier_Config_Var_Pop_Fields = {
  __typename?: 'user_reward_multiplier_config_var_pop_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Reward_Multiplier_Config_Var_Samp_Fields = {
  __typename?: 'user_reward_multiplier_config_var_samp_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Reward_Multiplier_Config_Variance_Fields = {
  __typename?: 'user_reward_multiplier_config_variance_fields'
  multiplier?: Maybe<Scalars['Float']>
}

/** Table to keep track of points redeemed by user based on rewards */
export type User_Reward_Redemption = {
  __typename?: 'user_reward_redemption'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  notes?: Maybe<Scalars['String']>
  points_redeemed: Scalars['numeric']
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id: Scalars['uuid']
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  /** An array relationship */
  user_reward_references: Array<User_Reward_Reference>
  /** An aggregate relationship */
  user_reward_references_aggregate: User_Reward_Reference_Aggregate
}

/** Table to keep track of points redeemed by user based on rewards */
export type User_Reward_RedemptionUser_Reward_ReferencesArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

/** Table to keep track of points redeemed by user based on rewards */
export type User_Reward_RedemptionUser_Reward_References_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Reward_Reference_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<User_Reward_Reference_Order_By>>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

/** aggregated selection of "user_reward_redemption" */
export type User_Reward_Redemption_Aggregate = {
  __typename?: 'user_reward_redemption_aggregate'
  aggregate?: Maybe<User_Reward_Redemption_Aggregate_Fields>
  nodes: Array<User_Reward_Redemption>
}

/** aggregate fields of "user_reward_redemption" */
export type User_Reward_Redemption_Aggregate_Fields = {
  __typename?: 'user_reward_redemption_aggregate_fields'
  avg?: Maybe<User_Reward_Redemption_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Reward_Redemption_Max_Fields>
  min?: Maybe<User_Reward_Redemption_Min_Fields>
  stddev?: Maybe<User_Reward_Redemption_Stddev_Fields>
  stddev_pop?: Maybe<User_Reward_Redemption_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Reward_Redemption_Stddev_Samp_Fields>
  sum?: Maybe<User_Reward_Redemption_Sum_Fields>
  var_pop?: Maybe<User_Reward_Redemption_Var_Pop_Fields>
  var_samp?: Maybe<User_Reward_Redemption_Var_Samp_Fields>
  variance?: Maybe<User_Reward_Redemption_Variance_Fields>
}

/** aggregate fields of "user_reward_redemption" */
export type User_Reward_Redemption_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Reward_Redemption_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type User_Reward_Redemption_Avg_Fields = {
  __typename?: 'user_reward_redemption_avg_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "user_reward_redemption". All fields are combined with a logical 'AND'. */
export type User_Reward_Redemption_Bool_Exp = {
  _and?: Maybe<Array<User_Reward_Redemption_Bool_Exp>>
  _not?: Maybe<User_Reward_Redemption_Bool_Exp>
  _or?: Maybe<Array<User_Reward_Redemption_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notes?: Maybe<String_Comparison_Exp>
  points_redeemed?: Maybe<Numeric_Comparison_Exp>
  redeemed_at?: Maybe<Timestamptz_Comparison_Exp>
  reward_reference_id?: Maybe<Uuid_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_reward_references?: Maybe<User_Reward_Reference_Bool_Exp>
  user_reward_references_aggregate?: Maybe<User_Reward_Reference_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "user_reward_redemption" */
export enum User_Reward_Redemption_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRewardRedemptionPkey = 'user_reward_redemption_pkey',
}

/** input type for incrementing numeric columns in table "user_reward_redemption" */
export type User_Reward_Redemption_Inc_Input = {
  points_redeemed?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_reward_redemption" */
export type User_Reward_Redemption_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_reward_references?: Maybe<User_Reward_Reference_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type User_Reward_Redemption_Max_Fields = {
  __typename?: 'user_reward_redemption_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Reward_Redemption_Min_Fields = {
  __typename?: 'user_reward_redemption_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_reward_redemption" */
export type User_Reward_Redemption_Mutation_Response = {
  __typename?: 'user_reward_redemption_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Reward_Redemption>
}

/** input type for inserting object relation for remote table "user_reward_redemption" */
export type User_Reward_Redemption_Obj_Rel_Insert_Input = {
  data: User_Reward_Redemption_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Reward_Redemption_On_Conflict>
}

/** on_conflict condition type for table "user_reward_redemption" */
export type User_Reward_Redemption_On_Conflict = {
  constraint: User_Reward_Redemption_Constraint
  update_columns?: Array<User_Reward_Redemption_Update_Column>
  where?: Maybe<User_Reward_Redemption_Bool_Exp>
}

/** Ordering options when selecting data from "user_reward_redemption". */
export type User_Reward_Redemption_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  points_redeemed?: Maybe<Order_By>
  redeemed_at?: Maybe<Order_By>
  reward_reference_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_reward_references_aggregate?: Maybe<User_Reward_Reference_Aggregate_Order_By>
}

/** primary key columns input for table: user_reward_redemption */
export type User_Reward_Redemption_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_reward_redemption" */
export enum User_Reward_Redemption_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PointsRedeemed = 'points_redeemed',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RewardReferenceId = 'reward_reference_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_reward_redemption" */
export type User_Reward_Redemption_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type User_Reward_Redemption_Stddev_Fields = {
  __typename?: 'user_reward_redemption_stddev_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type User_Reward_Redemption_Stddev_Pop_Fields = {
  __typename?: 'user_reward_redemption_stddev_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type User_Reward_Redemption_Stddev_Samp_Fields = {
  __typename?: 'user_reward_redemption_stddev_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "user_reward_redemption" */
export type User_Reward_Redemption_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Reward_Redemption_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Reward_Redemption_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  points_redeemed?: Maybe<Scalars['numeric']>
  redeemed_at?: Maybe<Scalars['timestamptz']>
  reward_reference_id?: Maybe<Scalars['uuid']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate sum on columns */
export type User_Reward_Redemption_Sum_Fields = {
  __typename?: 'user_reward_redemption_sum_fields'
  points_redeemed?: Maybe<Scalars['numeric']>
}

/** update columns of table "user_reward_redemption" */
export enum User_Reward_Redemption_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  PointsRedeemed = 'points_redeemed',
  /** column name */
  RedeemedAt = 'redeemed_at',
  /** column name */
  RewardReferenceId = 'reward_reference_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Reward_Redemption_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Reward_Redemption_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Reward_Redemption_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Reward_Redemption_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Reward_Redemption_Var_Pop_Fields = {
  __typename?: 'user_reward_redemption_var_pop_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type User_Reward_Redemption_Var_Samp_Fields = {
  __typename?: 'user_reward_redemption_var_samp_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type User_Reward_Redemption_Variance_Fields = {
  __typename?: 'user_reward_redemption_variance_fields'
  points_redeemed?: Maybe<Scalars['Float']>
}

/** Reference table to keep track of rewards issued to user (ie, Gift cards) */
export type User_Reward_Reference = {
  __typename?: 'user_reward_reference'
  amount: Scalars['numeric']
  created_at: Scalars['timestamptz']
  currency: Scalars['String']
  id: Scalars['uuid']
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id: Scalars['String']
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user: User
  user_id: Scalars['String']
  /** An object relationship */
  user_reward_redemption?: Maybe<User_Reward_Redemption>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** Reference table to keep track of rewards issued to user (ie, Gift cards) */
export type User_Reward_ReferenceReward_DetailsArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_reward_reference" */
export type User_Reward_Reference_Aggregate = {
  __typename?: 'user_reward_reference_aggregate'
  aggregate?: Maybe<User_Reward_Reference_Aggregate_Fields>
  nodes: Array<User_Reward_Reference>
}

export type User_Reward_Reference_Aggregate_Bool_Exp = {
  count?: Maybe<User_Reward_Reference_Aggregate_Bool_Exp_Count>
}

export type User_Reward_Reference_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<User_Reward_Reference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<User_Reward_Reference_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "user_reward_reference" */
export type User_Reward_Reference_Aggregate_Fields = {
  __typename?: 'user_reward_reference_aggregate_fields'
  avg?: Maybe<User_Reward_Reference_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<User_Reward_Reference_Max_Fields>
  min?: Maybe<User_Reward_Reference_Min_Fields>
  stddev?: Maybe<User_Reward_Reference_Stddev_Fields>
  stddev_pop?: Maybe<User_Reward_Reference_Stddev_Pop_Fields>
  stddev_samp?: Maybe<User_Reward_Reference_Stddev_Samp_Fields>
  sum?: Maybe<User_Reward_Reference_Sum_Fields>
  var_pop?: Maybe<User_Reward_Reference_Var_Pop_Fields>
  var_samp?: Maybe<User_Reward_Reference_Var_Samp_Fields>
  variance?: Maybe<User_Reward_Reference_Variance_Fields>
}

/** aggregate fields of "user_reward_reference" */
export type User_Reward_Reference_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Reward_Reference_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "user_reward_reference" */
export type User_Reward_Reference_Aggregate_Order_By = {
  avg?: Maybe<User_Reward_Reference_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<User_Reward_Reference_Max_Order_By>
  min?: Maybe<User_Reward_Reference_Min_Order_By>
  stddev?: Maybe<User_Reward_Reference_Stddev_Order_By>
  stddev_pop?: Maybe<User_Reward_Reference_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<User_Reward_Reference_Stddev_Samp_Order_By>
  sum?: Maybe<User_Reward_Reference_Sum_Order_By>
  var_pop?: Maybe<User_Reward_Reference_Var_Pop_Order_By>
  var_samp?: Maybe<User_Reward_Reference_Var_Samp_Order_By>
  variance?: Maybe<User_Reward_Reference_Variance_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Reward_Reference_Append_Input = {
  reward_details?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "user_reward_reference" */
export type User_Reward_Reference_Arr_Rel_Insert_Input = {
  data: Array<User_Reward_Reference_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<User_Reward_Reference_On_Conflict>
}

/** aggregate avg on columns */
export type User_Reward_Reference_Avg_Fields = {
  __typename?: 'user_reward_reference_avg_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Avg_Order_By = {
  amount?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "user_reward_reference". All fields are combined with a logical 'AND'. */
export type User_Reward_Reference_Bool_Exp = {
  _and?: Maybe<Array<User_Reward_Reference_Bool_Exp>>
  _not?: Maybe<User_Reward_Reference_Bool_Exp>
  _or?: Maybe<Array<User_Reward_Reference_Bool_Exp>>
  amount?: Maybe<Numeric_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  currency?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  notes?: Maybe<String_Comparison_Exp>
  provider?: Maybe<String_Comparison_Exp>
  reference_id?: Maybe<String_Comparison_Exp>
  reward_details?: Maybe<Jsonb_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  user_reward_redemption?: Maybe<User_Reward_Redemption_Bool_Exp>
  user_reward_redemption_id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_reward_reference" */
export enum User_Reward_Reference_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRewardReferencePkey = 'user_reward_reference_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Reward_Reference_Delete_At_Path_Input = {
  reward_details?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Reward_Reference_Delete_Elem_Input = {
  reward_details?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Reward_Reference_Delete_Key_Input = {
  reward_details?: Maybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "user_reward_reference" */
export type User_Reward_Reference_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>
}

/** input type for inserting data into table "user_reward_reference" */
export type User_Reward_Reference_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption?: Maybe<User_Reward_Redemption_Obj_Rel_Insert_Input>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Reward_Reference_Max_Fields = {
  __typename?: 'user_reward_reference_max_fields'
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** order by max() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Max_Order_By = {
  amount?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  user_reward_redemption_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type User_Reward_Reference_Min_Fields = {
  __typename?: 'user_reward_reference_min_fields'
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** order by min() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Min_Order_By = {
  amount?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
  user_reward_redemption_id?: Maybe<Order_By>
}

/** response of any mutation on the table "user_reward_reference" */
export type User_Reward_Reference_Mutation_Response = {
  __typename?: 'user_reward_reference_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Reward_Reference>
}

/** on_conflict condition type for table "user_reward_reference" */
export type User_Reward_Reference_On_Conflict = {
  constraint: User_Reward_Reference_Constraint
  update_columns?: Array<User_Reward_Reference_Update_Column>
  where?: Maybe<User_Reward_Reference_Bool_Exp>
}

/** Ordering options when selecting data from "user_reward_reference". */
export type User_Reward_Reference_Order_By = {
  amount?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  currency?: Maybe<Order_By>
  id?: Maybe<Order_By>
  notes?: Maybe<Order_By>
  provider?: Maybe<Order_By>
  reference_id?: Maybe<Order_By>
  reward_details?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  user_reward_redemption?: Maybe<User_Reward_Redemption_Order_By>
  user_reward_redemption_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_reward_reference */
export type User_Reward_Reference_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Reward_Reference_Prepend_Input = {
  reward_details?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_reward_reference" */
export enum User_Reward_Reference_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Provider = 'provider',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  RewardDetails = 'reward_details',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRewardRedemptionId = 'user_reward_redemption_id',
}

/** input type for updating data in table "user_reward_reference" */
export type User_Reward_Reference_Set_Input = {
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** aggregate stddev on columns */
export type User_Reward_Reference_Stddev_Fields = {
  __typename?: 'user_reward_reference_stddev_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Stddev_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Reward_Reference_Stddev_Pop_Fields = {
  __typename?: 'user_reward_reference_stddev_pop_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Stddev_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Reward_Reference_Stddev_Samp_Fields = {
  __typename?: 'user_reward_reference_stddev_samp_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Stddev_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** Streaming cursor of the table "user_reward_reference" */
export type User_Reward_Reference_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Reward_Reference_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Reward_Reference_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['numeric']>
  created_at?: Maybe<Scalars['timestamptz']>
  currency?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  notes?: Maybe<Scalars['String']>
  provider?: Maybe<Scalars['String']>
  reference_id?: Maybe<Scalars['String']>
  reward_details?: Maybe<Scalars['jsonb']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
  user_reward_redemption_id?: Maybe<Scalars['uuid']>
}

/** aggregate sum on columns */
export type User_Reward_Reference_Sum_Fields = {
  __typename?: 'user_reward_reference_sum_fields'
  amount?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Sum_Order_By = {
  amount?: Maybe<Order_By>
}

/** update columns of table "user_reward_reference" */
export enum User_Reward_Reference_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  Provider = 'provider',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  RewardDetails = 'reward_details',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserRewardRedemptionId = 'user_reward_redemption_id',
}

export type User_Reward_Reference_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Reward_Reference_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Reward_Reference_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Reward_Reference_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Reward_Reference_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Reward_Reference_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Reward_Reference_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Reward_Reference_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Reward_Reference_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Reward_Reference_Var_Pop_Fields = {
  __typename?: 'user_reward_reference_var_pop_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Var_Pop_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Reward_Reference_Var_Samp_Fields = {
  __typename?: 'user_reward_reference_var_samp_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Var_Samp_Order_By = {
  amount?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Reward_Reference_Variance_Fields = {
  __typename?: 'user_reward_reference_variance_fields'
  amount?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "user_reward_reference" */
export type User_Reward_Reference_Variance_Order_By = {
  amount?: Maybe<Order_By>
}

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  AppliedAsCreatorAt = 'applied_as_creator_at',
  /** column name */
  Bio = 'bio',
  /** column name */
  BioLink = 'bio_link',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedReason = 'deleted_reason',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledReason = 'disabled_reason',
  /** column name */
  Email = 'email',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  IconOrder = 'icon_order',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  OrphanedAt = 'orphaned_at',
  /** column name */
  OrphanedBy = 'orphaned_by',
  /** column name */
  Phone = 'phone',
  /** column name */
  Picture = 'picture',
  /** column name */
  PossibleEmail = 'possible_email',
  /** column name */
  ProbablyReal = 'probably_real',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  Role = 'role',
  /** column name */
  ShoppingPreference = 'shopping_preference',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserType = 'user_type',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified',
  /** column name */
  Version = 'version',
  /** column name */
  Waitlist = 'waitlist',
}

/** select "user_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  ProbablyReal = 'probably_real',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  Verified = 'verified',
  /** column name */
  Waitlist = 'waitlist',
}

/** select "user_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user" */
export enum User_Select_Column_User_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  ProbablyReal = 'probably_real',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  Verified = 'verified',
  /** column name */
  Waitlist = 'waitlist',
}

/** input type for updating data in table "user" */
export type User_Set_Input = {
  anonymous?: Maybe<Scalars['Boolean']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['String']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id?: Maybe<Scalars['name']>
  show_curations?: Maybe<Scalars['Boolean']>
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
}

/** User favorite shops */
export type User_Shop_Favorite = {
  __typename?: 'user_shop_favorite'
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  shop_key: Scalars['String']
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** aggregated selection of "user_shop_favorite" */
export type User_Shop_Favorite_Aggregate = {
  __typename?: 'user_shop_favorite_aggregate'
  aggregate?: Maybe<User_Shop_Favorite_Aggregate_Fields>
  nodes: Array<User_Shop_Favorite>
}

/** aggregate fields of "user_shop_favorite" */
export type User_Shop_Favorite_Aggregate_Fields = {
  __typename?: 'user_shop_favorite_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Shop_Favorite_Max_Fields>
  min?: Maybe<User_Shop_Favorite_Min_Fields>
}

/** aggregate fields of "user_shop_favorite" */
export type User_Shop_Favorite_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Shop_Favorite_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_shop_favorite". All fields are combined with a logical 'AND'. */
export type User_Shop_Favorite_Bool_Exp = {
  _and?: Maybe<Array<User_Shop_Favorite_Bool_Exp>>
  _not?: Maybe<User_Shop_Favorite_Bool_Exp>
  _or?: Maybe<Array<User_Shop_Favorite_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  shop_key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_shop_favorite" */
export enum User_Shop_Favorite_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserShopFavoritePkey = 'user_shop_favorite_pkey',
  /** unique or primary key constraint on columns "shop_key", "user_id" */
  UserShopFavoriteUserIdShopKeyKey = 'user_shop_favorite_user_id_shop_key_key',
}

/** input type for inserting data into table "user_shop_favorite" */
export type User_Shop_Favorite_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Shop_Favorite_Max_Fields = {
  __typename?: 'user_shop_favorite_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Shop_Favorite_Min_Fields = {
  __typename?: 'user_shop_favorite_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_shop_favorite" */
export type User_Shop_Favorite_Mutation_Response = {
  __typename?: 'user_shop_favorite_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Shop_Favorite>
}

/** on_conflict condition type for table "user_shop_favorite" */
export type User_Shop_Favorite_On_Conflict = {
  constraint: User_Shop_Favorite_Constraint
  update_columns?: Array<User_Shop_Favorite_Update_Column>
  where?: Maybe<User_Shop_Favorite_Bool_Exp>
}

/** Ordering options when selecting data from "user_shop_favorite". */
export type User_Shop_Favorite_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  shop_key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_shop_favorite */
export type User_Shop_Favorite_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_shop_favorite" */
export enum User_Shop_Favorite_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_shop_favorite" */
export type User_Shop_Favorite_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_shop_favorite" */
export type User_Shop_Favorite_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Shop_Favorite_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Shop_Favorite_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  shop_key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_shop_favorite" */
export enum User_Shop_Favorite_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShopKey = 'shop_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Shop_Favorite_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Shop_Favorite_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Shop_Favorite_Bool_Exp
}

/** Enum table to track user shopping preference (ie, Men's, Women's, Anything) */
export type User_Shopping_Preferences = {
  __typename?: 'user_shopping_preferences'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "user_shopping_preferences" */
export type User_Shopping_Preferences_Aggregate = {
  __typename?: 'user_shopping_preferences_aggregate'
  aggregate?: Maybe<User_Shopping_Preferences_Aggregate_Fields>
  nodes: Array<User_Shopping_Preferences>
}

/** aggregate fields of "user_shopping_preferences" */
export type User_Shopping_Preferences_Aggregate_Fields = {
  __typename?: 'user_shopping_preferences_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Shopping_Preferences_Max_Fields>
  min?: Maybe<User_Shopping_Preferences_Min_Fields>
}

/** aggregate fields of "user_shopping_preferences" */
export type User_Shopping_Preferences_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Shopping_Preferences_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_shopping_preferences". All fields are combined with a logical 'AND'. */
export type User_Shopping_Preferences_Bool_Exp = {
  _and?: Maybe<Array<User_Shopping_Preferences_Bool_Exp>>
  _not?: Maybe<User_Shopping_Preferences_Bool_Exp>
  _or?: Maybe<Array<User_Shopping_Preferences_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_shopping_preferences" */
export enum User_Shopping_Preferences_Constraint {
  /** unique or primary key constraint on columns "value" */
  ShoppingPreferencesPkey = 'shopping_preferences_pkey',
}

export enum User_Shopping_Preferences_Enum {
  /** Everything */
  Everything = 'everything',
  /** Men's */
  Mens = 'mens',
  /** Women's */
  Womens = 'womens',
}

/** Boolean expression to compare columns of type "user_shopping_preferences_enum". All fields are combined with logical 'AND'. */
export type User_Shopping_Preferences_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Shopping_Preferences_Enum>
  _in?: Maybe<Array<User_Shopping_Preferences_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Shopping_Preferences_Enum>
  _nin?: Maybe<Array<User_Shopping_Preferences_Enum>>
}

/** input type for inserting data into table "user_shopping_preferences" */
export type User_Shopping_Preferences_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Shopping_Preferences_Max_Fields = {
  __typename?: 'user_shopping_preferences_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Shopping_Preferences_Min_Fields = {
  __typename?: 'user_shopping_preferences_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_shopping_preferences" */
export type User_Shopping_Preferences_Mutation_Response = {
  __typename?: 'user_shopping_preferences_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Shopping_Preferences>
}

/** input type for inserting object relation for remote table "user_shopping_preferences" */
export type User_Shopping_Preferences_Obj_Rel_Insert_Input = {
  data: User_Shopping_Preferences_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<User_Shopping_Preferences_On_Conflict>
}

/** on_conflict condition type for table "user_shopping_preferences" */
export type User_Shopping_Preferences_On_Conflict = {
  constraint: User_Shopping_Preferences_Constraint
  update_columns?: Array<User_Shopping_Preferences_Update_Column>
  where?: Maybe<User_Shopping_Preferences_Bool_Exp>
}

/** Ordering options when selecting data from "user_shopping_preferences". */
export type User_Shopping_Preferences_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_shopping_preferences */
export type User_Shopping_Preferences_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_shopping_preferences" */
export enum User_Shopping_Preferences_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_shopping_preferences" */
export type User_Shopping_Preferences_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_shopping_preferences" */
export type User_Shopping_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Shopping_Preferences_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Shopping_Preferences_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_shopping_preferences" */
export enum User_Shopping_Preferences_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Shopping_Preferences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Shopping_Preferences_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Shopping_Preferences_Bool_Exp
}

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'user_stddev_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by stddev() on columns of table "user" */
export type User_Stddev_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'user_stddev_pop_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by stddev_pop() on columns of table "user" */
export type User_Stddev_Pop_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'user_stddev_samp_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by stddev_samp() on columns of table "user" */
export type User_Stddev_Samp_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** Streaming cursor of the table "user" */
export type User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Stream_Cursor_Value_Input = {
  anonymous?: Maybe<Scalars['Boolean']>
  applied_as_creator_at?: Maybe<Scalars['timestamptz']>
  bio?: Maybe<Scalars['String']>
  bio_link?: Maybe<Scalars['String']>
  cover_image?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  deleted_reason?: Maybe<Scalars['String']>
  disabled_at?: Maybe<Scalars['timestamptz']>
  disabled_reason?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  has_premium_subscription?: Maybe<Scalars['Boolean']>
  icon_order?: Maybe<Scalars['numeric']>
  id?: Maybe<Scalars['String']>
  installed_at?: Maybe<Scalars['timestamptz']>
  internal_account?: Maybe<Scalars['Boolean']>
  metadata?: Maybe<Scalars['jsonb']>
  name?: Maybe<Scalars['String']>
  ogimage_fingerprint?: Maybe<Scalars['String']>
  ogimage_fingerprint_updated?: Maybe<Scalars['String']>
  onboarded?: Maybe<Scalars['Boolean']>
  orphaned_at?: Maybe<Scalars['timestamptz']>
  orphaned_by?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  picture?: Maybe<Scalars['String']>
  possible_email?: Maybe<Scalars['String']>
  probably_real?: Maybe<Scalars['Boolean']>
  referrer_user_id?: Maybe<Scalars['String']>
  role?: Maybe<Scalars['name']>
  shopping_preference?: Maybe<User_Shopping_Preferences_Enum>
  short_id?: Maybe<Scalars['name']>
  show_curations?: Maybe<Scalars['Boolean']>
  show_onboarding?: Maybe<Scalars['Boolean']>
  show_rabbithole?: Maybe<Scalars['Boolean']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_type?: Maybe<User_Account_Type_Enum>
  username?: Maybe<Scalars['String']>
  verified?: Maybe<Scalars['Boolean']>
  version?: Maybe<Scalars['String']>
  waitlist?: Maybe<Scalars['Boolean']>
}

/** A table to hold the relationship of what users are subscribed to other users */
export type User_Subscription = {
  __typename?: 'user_subscription'
  billing_mode: Billing_Mode_Enum
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at: Scalars['timestamptz']
  /** An object relationship */
  creator: User
  /** A computed field, executes function "user_subscription_creator_has_billing_account" */
  creator_has_billing_account?: Maybe<Scalars['Boolean']>
  creator_id: Scalars['String']
  deleted_at?: Maybe<Scalars['timestamptz']>
  id: Scalars['uuid']
  plan: Scalars['jsonb']
  status: User_Subscription_Status_Enum
  /** An object relationship */
  subscriber: User
  subscription_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_type" */
  subscription_type?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
  user_id: Scalars['String']
}

/** A table to hold the relationship of what users are subscribed to other users */
export type User_SubscriptionPlanArgs = {
  path?: Maybe<Scalars['String']>
}

/** aggregated selection of "user_subscription" */
export type User_Subscription_Aggregate = {
  __typename?: 'user_subscription_aggregate'
  aggregate?: Maybe<User_Subscription_Aggregate_Fields>
  nodes: Array<User_Subscription>
}

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_Fields = {
  __typename?: 'user_subscription_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Subscription_Max_Fields>
  min?: Maybe<User_Subscription_Min_Fields>
}

/** aggregate fields of "user_subscription" */
export type User_Subscription_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Subscription_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Subscription_Append_Input = {
  plan?: Maybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "user_subscription". All fields are combined with a logical 'AND'. */
export type User_Subscription_Bool_Exp = {
  _and?: Maybe<Array<User_Subscription_Bool_Exp>>
  _not?: Maybe<User_Subscription_Bool_Exp>
  _or?: Maybe<Array<User_Subscription_Bool_Exp>>
  billing_mode?: Maybe<Billing_Mode_Enum_Comparison_Exp>
  cancel_at?: Maybe<Timestamptz_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  creator?: Maybe<User_Bool_Exp>
  creator_has_billing_account?: Maybe<Boolean_Comparison_Exp>
  creator_id?: Maybe<String_Comparison_Exp>
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  plan?: Maybe<Jsonb_Comparison_Exp>
  status?: Maybe<User_Subscription_Status_Enum_Comparison_Exp>
  subscriber?: Maybe<User_Bool_Exp>
  subscription_id?: Maybe<String_Comparison_Exp>
  subscription_type?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_subscription" */
export enum User_Subscription_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSubscriptionPkey = 'user_subscription_pkey',
  /** unique or primary key constraint on columns "user_id", "creator_id", "billing_mode" */
  UserSubscriptionUserIdCreatorIdBillingModeKey = 'user_subscription_user_id_creator_id_billing_mode_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Subscription_Delete_At_Path_Input = {
  plan?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Subscription_Delete_Elem_Input = {
  plan?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Subscription_Delete_Key_Input = {
  plan?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "user_subscription" */
export type User_Subscription_Insert_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator?: Maybe<User_Obj_Rel_Insert_Input>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  plan?: Maybe<Scalars['jsonb']>
  status?: Maybe<User_Subscription_Status_Enum>
  subscriber?: Maybe<User_Obj_Rel_Insert_Input>
  subscription_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Subscription_Max_Fields = {
  __typename?: 'user_subscription_max_fields'
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  subscription_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_type" */
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Subscription_Min_Fields = {
  __typename?: 'user_subscription_min_fields'
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  subscription_id?: Maybe<Scalars['String']>
  /** A computed field, executes function "user_subscription_type" */
  subscription_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_subscription" */
export type User_Subscription_Mutation_Response = {
  __typename?: 'user_subscription_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Subscription>
}

/** on_conflict condition type for table "user_subscription" */
export type User_Subscription_On_Conflict = {
  constraint: User_Subscription_Constraint
  update_columns?: Array<User_Subscription_Update_Column>
  where?: Maybe<User_Subscription_Bool_Exp>
}

/** Ordering options when selecting data from "user_subscription". */
export type User_Subscription_Order_By = {
  billing_mode?: Maybe<Order_By>
  cancel_at?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  creator?: Maybe<User_Order_By>
  creator_has_billing_account?: Maybe<Order_By>
  creator_id?: Maybe<Order_By>
  deleted_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  plan?: Maybe<Order_By>
  status?: Maybe<Order_By>
  subscriber?: Maybe<User_Order_By>
  subscription_id?: Maybe<Order_By>
  subscription_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** primary key columns input for table: user_subscription */
export type User_Subscription_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Subscription_Prepend_Input = {
  plan?: Maybe<Scalars['jsonb']>
}

/** select columns of table "user_subscription" */
export enum User_Subscription_Select_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_subscription" */
export type User_Subscription_Set_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  plan?: Maybe<Scalars['jsonb']>
  status?: Maybe<User_Subscription_Status_Enum>
  subscription_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** A table to hold the status of a user subscription */
export type User_Subscription_Status = {
  __typename?: 'user_subscription_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "user_subscription_status" */
export type User_Subscription_Status_Aggregate = {
  __typename?: 'user_subscription_status_aggregate'
  aggregate?: Maybe<User_Subscription_Status_Aggregate_Fields>
  nodes: Array<User_Subscription_Status>
}

/** aggregate fields of "user_subscription_status" */
export type User_Subscription_Status_Aggregate_Fields = {
  __typename?: 'user_subscription_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Subscription_Status_Max_Fields>
  min?: Maybe<User_Subscription_Status_Min_Fields>
}

/** aggregate fields of "user_subscription_status" */
export type User_Subscription_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Subscription_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_subscription_status". All fields are combined with a logical 'AND'. */
export type User_Subscription_Status_Bool_Exp = {
  _and?: Maybe<Array<User_Subscription_Status_Bool_Exp>>
  _not?: Maybe<User_Subscription_Status_Bool_Exp>
  _or?: Maybe<Array<User_Subscription_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "user_subscription_status" */
export enum User_Subscription_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  UsersSubscriptionStatusPkey = 'users_subscription_status_pkey',
}

export enum User_Subscription_Status_Enum {
  Active = 'active',
  Deleted = 'deleted',
}

/** Boolean expression to compare columns of type "user_subscription_status_enum". All fields are combined with logical 'AND'. */
export type User_Subscription_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<User_Subscription_Status_Enum>
  _in?: Maybe<Array<User_Subscription_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<User_Subscription_Status_Enum>
  _nin?: Maybe<Array<User_Subscription_Status_Enum>>
}

/** input type for inserting data into table "user_subscription_status" */
export type User_Subscription_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type User_Subscription_Status_Max_Fields = {
  __typename?: 'user_subscription_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type User_Subscription_Status_Min_Fields = {
  __typename?: 'user_subscription_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "user_subscription_status" */
export type User_Subscription_Status_Mutation_Response = {
  __typename?: 'user_subscription_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Subscription_Status>
}

/** on_conflict condition type for table "user_subscription_status" */
export type User_Subscription_Status_On_Conflict = {
  constraint: User_Subscription_Status_Constraint
  update_columns?: Array<User_Subscription_Status_Update_Column>
  where?: Maybe<User_Subscription_Status_Bool_Exp>
}

/** Ordering options when selecting data from "user_subscription_status". */
export type User_Subscription_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: user_subscription_status */
export type User_Subscription_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "user_subscription_status" */
export enum User_Subscription_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_subscription_status" */
export type User_Subscription_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "user_subscription_status" */
export type User_Subscription_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Subscription_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Subscription_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "user_subscription_status" */
export enum User_Subscription_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type User_Subscription_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Subscription_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Subscription_Status_Bool_Exp
}

/** Streaming cursor of the table "user_subscription" */
export type User_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Subscription_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Subscription_Stream_Cursor_Value_Input = {
  billing_mode?: Maybe<Billing_Mode_Enum>
  cancel_at?: Maybe<Scalars['timestamptz']>
  created_at?: Maybe<Scalars['timestamptz']>
  creator_id?: Maybe<Scalars['String']>
  deleted_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  plan?: Maybe<Scalars['jsonb']>
  status?: Maybe<User_Subscription_Status_Enum>
  subscription_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "user_subscription" */
export enum User_Subscription_Update_Column {
  /** column name */
  BillingMode = 'billing_mode',
  /** column name */
  CancelAt = 'cancel_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatorId = 'creator_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Plan = 'plan',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Subscription_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Subscription_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Subscription_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Subscription_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Subscription_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Subscription_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Subscription_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Subscription_Bool_Exp
}

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'user_sum_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by sum() on columns of table "user" */
export type User_Sum_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** Temp table for constraint testing */
export type User_Temp = {
  __typename?: 'user_temp'
  email?: Maybe<Scalars['String']>
  id: Scalars['uuid']
}

/** aggregated selection of "user_temp" */
export type User_Temp_Aggregate = {
  __typename?: 'user_temp_aggregate'
  aggregate?: Maybe<User_Temp_Aggregate_Fields>
  nodes: Array<User_Temp>
}

/** aggregate fields of "user_temp" */
export type User_Temp_Aggregate_Fields = {
  __typename?: 'user_temp_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<User_Temp_Max_Fields>
  min?: Maybe<User_Temp_Min_Fields>
}

/** aggregate fields of "user_temp" */
export type User_Temp_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Temp_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "user_temp". All fields are combined with a logical 'AND'. */
export type User_Temp_Bool_Exp = {
  _and?: Maybe<Array<User_Temp_Bool_Exp>>
  _not?: Maybe<User_Temp_Bool_Exp>
  _or?: Maybe<Array<User_Temp_Bool_Exp>>
  email?: Maybe<String_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
}

/** unique or primary key constraints on table "user_temp" */
export enum User_Temp_Constraint {
  /** unique or primary key constraint on columns "email" */
  UserTempEmailKey = 'user_temp_email_key',
  /** unique or primary key constraint on columns "id" */
  UserTempPkey = 'user_temp_pkey',
}

/** input type for inserting data into table "user_temp" */
export type User_Temp_Insert_Input = {
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** aggregate max on columns */
export type User_Temp_Max_Fields = {
  __typename?: 'user_temp_max_fields'
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** aggregate min on columns */
export type User_Temp_Min_Fields = {
  __typename?: 'user_temp_min_fields'
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** response of any mutation on the table "user_temp" */
export type User_Temp_Mutation_Response = {
  __typename?: 'user_temp_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<User_Temp>
}

/** on_conflict condition type for table "user_temp" */
export type User_Temp_On_Conflict = {
  constraint: User_Temp_Constraint
  update_columns?: Array<User_Temp_Update_Column>
  where?: Maybe<User_Temp_Bool_Exp>
}

/** Ordering options when selecting data from "user_temp". */
export type User_Temp_Order_By = {
  email?: Maybe<Order_By>
  id?: Maybe<Order_By>
}

/** primary key columns input for table: user_temp */
export type User_Temp_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "user_temp" */
export enum User_Temp_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "user_temp" */
export type User_Temp_Set_Input = {
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** Streaming cursor of the table "user_temp" */
export type User_Temp_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Temp_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type User_Temp_Stream_Cursor_Value_Input = {
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
}

/** update columns of table "user_temp" */
export enum User_Temp_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

export type User_Temp_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Temp_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Temp_Bool_Exp
}

/** update columns of table "user" */
export enum User_Update_Column {
  /** column name */
  Anonymous = 'anonymous',
  /** column name */
  AppliedAsCreatorAt = 'applied_as_creator_at',
  /** column name */
  Bio = 'bio',
  /** column name */
  BioLink = 'bio_link',
  /** column name */
  CoverImage = 'cover_image',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeletedReason = 'deleted_reason',
  /** column name */
  DisabledAt = 'disabled_at',
  /** column name */
  DisabledReason = 'disabled_reason',
  /** column name */
  Email = 'email',
  /** column name */
  HasPremiumSubscription = 'has_premium_subscription',
  /** column name */
  IconOrder = 'icon_order',
  /** column name */
  Id = 'id',
  /** column name */
  InstalledAt = 'installed_at',
  /** column name */
  InternalAccount = 'internal_account',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OgimageFingerprint = 'ogimage_fingerprint',
  /** column name */
  OgimageFingerprintUpdated = 'ogimage_fingerprint_updated',
  /** column name */
  Onboarded = 'onboarded',
  /** column name */
  OrphanedAt = 'orphaned_at',
  /** column name */
  OrphanedBy = 'orphaned_by',
  /** column name */
  Phone = 'phone',
  /** column name */
  Picture = 'picture',
  /** column name */
  PossibleEmail = 'possible_email',
  /** column name */
  ReferrerUserId = 'referrer_user_id',
  /** column name */
  Role = 'role',
  /** column name */
  ShoppingPreference = 'shopping_preference',
  /** column name */
  ShortId = 'short_id',
  /** column name */
  ShowCurations = 'show_curations',
  /** column name */
  ShowOnboarding = 'show_onboarding',
  /** column name */
  ShowRabbithole = 'show_rabbithole',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserType = 'user_type',
  /** column name */
  Username = 'username',
  /** column name */
  Verified = 'verified',
  /** column name */
  Version = 'version',
  /** column name */
  Waitlist = 'waitlist',
}

export type User_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<User_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<User_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<User_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<User_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<User_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<User_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<User_Set_Input>
  /** filter the rows which have to be updated */
  where: User_Bool_Exp
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'user_var_pop_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by var_pop() on columns of table "user" */
export type User_Var_Pop_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'user_var_samp_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by var_samp() on columns of table "user" */
export type User_Var_Samp_Order_By = {
  icon_order?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'user_variance_fields'
  /** Compute the sum of cart item quantities on all "active" cart items for the user */
  active_cart_items_quantity?: Maybe<Scalars['numeric']>
  /** A computed field, executes function "user_cart_count" */
  carts_count?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total commission that has been cleared. */
  commissions_cleared?: Maybe<Scalars['numeric']>
  /** Computed field on `user` to calculate total pending commissions. */
  commissions_pending?: Maybe<Scalars['numeric']>
  icon_order?: Maybe<Scalars['Float']>
  /** A computed field, executes function "user_mobile_badge_count" */
  mobile_badge_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_new_discovery_feed_items_count" */
  new_discovery_feed_items_count?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_referral_count_credited" */
  referral_count_credited?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals. */
  referral_count_total?: Maybe<Scalars['Int']>
  /** Computed field on `user` to calculate the total number of referrals within the promo period. */
  referral_count_within_promo_period?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unread_notification_center_items" */
  unread_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unseen_cart_items" */
  unseen_cart_items?: Maybe<Scalars['Int']>
  /** A computed field, executes function "user_unviewed_notification_center_items" */
  unviewed_notification_center_items?: Maybe<Scalars['Int']>
  /** A computed field to get the user points multiplier */
  user_points_multiplier?: Maybe<Scalars['numeric']>
}

/** order by variance() on columns of table "user" */
export type User_Variance_Order_By = {
  icon_order?: Maybe<Order_By>
}

export type Users_Search_Args = {
  limit?: Maybe<Scalars['Int']>
  search?: Maybe<Scalars['String']>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>
  _gt?: Maybe<Scalars['uuid']>
  _gte?: Maybe<Scalars['uuid']>
  _in?: Maybe<Array<Scalars['uuid']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['uuid']>
  _lte?: Maybe<Scalars['uuid']>
  _neq?: Maybe<Scalars['uuid']>
  _nin?: Maybe<Array<Scalars['uuid']>>
}

/** Enum table to hold the different possible workflow types */
export type Worflow_Type = {
  __typename?: 'worflow_type'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "worflow_type" */
export type Worflow_Type_Aggregate = {
  __typename?: 'worflow_type_aggregate'
  aggregate?: Maybe<Worflow_Type_Aggregate_Fields>
  nodes: Array<Worflow_Type>
}

/** aggregate fields of "worflow_type" */
export type Worflow_Type_Aggregate_Fields = {
  __typename?: 'worflow_type_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Worflow_Type_Max_Fields>
  min?: Maybe<Worflow_Type_Min_Fields>
}

/** aggregate fields of "worflow_type" */
export type Worflow_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Worflow_Type_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "worflow_type". All fields are combined with a logical 'AND'. */
export type Worflow_Type_Bool_Exp = {
  _and?: Maybe<Array<Worflow_Type_Bool_Exp>>
  _not?: Maybe<Worflow_Type_Bool_Exp>
  _or?: Maybe<Array<Worflow_Type_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "worflow_type" */
export enum Worflow_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  WorflowTypePkey = 'worflow_type_pkey',
}

export enum Worflow_Type_Enum {
  /** For adding mobile bookmarked items into a collection */
  AddBookmarkToCollection = 'add_bookmark_to_collection',
  /** For controlling the flow of creating of creating a mobile bookmark for a user */
  MobileBookmark = 'mobile_bookmark',
  /** For controlling the flow of creating of creating a web bookmark for a user */
  WebBookmark = 'web_bookmark',
}

/** Boolean expression to compare columns of type "worflow_type_enum". All fields are combined with logical 'AND'. */
export type Worflow_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Worflow_Type_Enum>
  _in?: Maybe<Array<Worflow_Type_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Worflow_Type_Enum>
  _nin?: Maybe<Array<Worflow_Type_Enum>>
}

/** input type for inserting data into table "worflow_type" */
export type Worflow_Type_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Worflow_Type_Max_Fields = {
  __typename?: 'worflow_type_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Worflow_Type_Min_Fields = {
  __typename?: 'worflow_type_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "worflow_type" */
export type Worflow_Type_Mutation_Response = {
  __typename?: 'worflow_type_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Worflow_Type>
}

/** on_conflict condition type for table "worflow_type" */
export type Worflow_Type_On_Conflict = {
  constraint: Worflow_Type_Constraint
  update_columns?: Array<Worflow_Type_Update_Column>
  where?: Maybe<Worflow_Type_Bool_Exp>
}

/** Ordering options when selecting data from "worflow_type". */
export type Worflow_Type_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: worflow_type */
export type Worflow_Type_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "worflow_type" */
export enum Worflow_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "worflow_type" */
export type Worflow_Type_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "worflow_type" */
export type Worflow_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Worflow_Type_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Worflow_Type_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "worflow_type" */
export enum Worflow_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Worflow_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Worflow_Type_Set_Input>
  /** filter the rows which have to be updated */
  where: Worflow_Type_Bool_Exp
}

/** A table to hold the status of pending items that require backend processing */
export type Workflow = {
  __typename?: 'workflow'
  created_at: Scalars['timestamptz']
  data: Scalars['jsonb']
  id: Scalars['uuid']
  /** An object relationship */
  parent_workflow?: Maybe<Workflow>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status: Workflow_Status_Enum
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type: Worflow_Type_Enum
  updated_at: Scalars['timestamptz']
  /** An object relationship */
  user?: Maybe<User>
  user_id?: Maybe<Scalars['String']>
  /** An array relationship */
  workflow_dependents: Array<Workflow>
  /** An aggregate relationship */
  workflow_dependents_aggregate: Workflow_Aggregate
}

/** A table to hold the status of pending items that require backend processing */
export type WorkflowDataArgs = {
  path?: Maybe<Scalars['String']>
}

/** A table to hold the status of pending items that require backend processing */
export type WorkflowWorkflow_DependentsArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** A table to hold the status of pending items that require backend processing */
export type WorkflowWorkflow_Dependents_AggregateArgs = {
  distinct_on?: Maybe<Array<Workflow_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Workflow_Order_By>>
  where?: Maybe<Workflow_Bool_Exp>
}

/** aggregated selection of "workflow" */
export type Workflow_Aggregate = {
  __typename?: 'workflow_aggregate'
  aggregate?: Maybe<Workflow_Aggregate_Fields>
  nodes: Array<Workflow>
}

export type Workflow_Aggregate_Bool_Exp = {
  count?: Maybe<Workflow_Aggregate_Bool_Exp_Count>
}

export type Workflow_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Workflow_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
  filter?: Maybe<Workflow_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "workflow" */
export type Workflow_Aggregate_Fields = {
  __typename?: 'workflow_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Workflow_Max_Fields>
  min?: Maybe<Workflow_Min_Fields>
}

/** aggregate fields of "workflow" */
export type Workflow_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Workflow_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "workflow" */
export type Workflow_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Workflow_Max_Order_By>
  min?: Maybe<Workflow_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Append_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "workflow" */
export type Workflow_Arr_Rel_Insert_Input = {
  data: Array<Workflow_Insert_Input>
  /** upsert condition */
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** Boolean expression to filter rows from the table "workflow". All fields are combined with a logical 'AND'. */
export type Workflow_Bool_Exp = {
  _and?: Maybe<Array<Workflow_Bool_Exp>>
  _not?: Maybe<Workflow_Bool_Exp>
  _or?: Maybe<Array<Workflow_Bool_Exp>>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  data?: Maybe<Jsonb_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  parent_workflow?: Maybe<Workflow_Bool_Exp>
  parent_workflow_id?: Maybe<Uuid_Comparison_Exp>
  status?: Maybe<Workflow_Status_Enum_Comparison_Exp>
  status_details?: Maybe<String_Comparison_Exp>
  success_item_id?: Maybe<Uuid_Comparison_Exp>
  success_item_type?: Maybe<String_Comparison_Exp>
  type?: Maybe<Worflow_Type_Enum_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
  user?: Maybe<User_Bool_Exp>
  user_id?: Maybe<String_Comparison_Exp>
  workflow_dependents?: Maybe<Workflow_Bool_Exp>
  workflow_dependents_aggregate?: Maybe<Workflow_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "workflow" */
export enum Workflow_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkflowPkey = 'workflow_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Workflow_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Workflow_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Workflow_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>
}

/** input type for inserting data into table "workflow" */
export type Workflow_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow?: Maybe<Workflow_Obj_Rel_Insert_Input>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Workflow_Status_Enum>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type?: Maybe<Worflow_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user?: Maybe<User_Obj_Rel_Insert_Input>
  user_id?: Maybe<Scalars['String']>
  workflow_dependents?: Maybe<Workflow_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Workflow_Max_Fields = {
  __typename?: 'workflow_max_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "workflow" */
export type Workflow_Max_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  parent_workflow_id?: Maybe<Order_By>
  status_details?: Maybe<Order_By>
  success_item_id?: Maybe<Order_By>
  success_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Workflow_Min_Fields = {
  __typename?: 'workflow_min_fields'
  created_at?: Maybe<Scalars['timestamptz']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "workflow" */
export type Workflow_Min_Order_By = {
  created_at?: Maybe<Order_By>
  id?: Maybe<Order_By>
  parent_workflow_id?: Maybe<Order_By>
  status_details?: Maybe<Order_By>
  success_item_id?: Maybe<Order_By>
  success_item_type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user_id?: Maybe<Order_By>
}

/** response of any mutation on the table "workflow" */
export type Workflow_Mutation_Response = {
  __typename?: 'workflow_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Workflow>
}

/** input type for inserting object relation for remote table "workflow" */
export type Workflow_Obj_Rel_Insert_Input = {
  data: Workflow_Insert_Input
  /** upsert condition */
  on_conflict?: Maybe<Workflow_On_Conflict>
}

/** on_conflict condition type for table "workflow" */
export type Workflow_On_Conflict = {
  constraint: Workflow_Constraint
  update_columns?: Array<Workflow_Update_Column>
  where?: Maybe<Workflow_Bool_Exp>
}

/** Ordering options when selecting data from "workflow". */
export type Workflow_Order_By = {
  created_at?: Maybe<Order_By>
  data?: Maybe<Order_By>
  id?: Maybe<Order_By>
  parent_workflow?: Maybe<Workflow_Order_By>
  parent_workflow_id?: Maybe<Order_By>
  status?: Maybe<Order_By>
  status_details?: Maybe<Order_By>
  success_item_id?: Maybe<Order_By>
  success_item_type?: Maybe<Order_By>
  type?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
  user?: Maybe<User_Order_By>
  user_id?: Maybe<Order_By>
  workflow_dependents_aggregate?: Maybe<Workflow_Aggregate_Order_By>
}

/** primary key columns input for table: workflow */
export type Workflow_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Workflow_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>
}

/** select columns of table "workflow" */
export enum Workflow_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ParentWorkflowId = 'parent_workflow_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDetails = 'status_details',
  /** column name */
  SuccessItemId = 'success_item_id',
  /** column name */
  SuccessItemType = 'success_item_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "workflow" */
export type Workflow_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Workflow_Status_Enum>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type?: Maybe<Worflow_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** Enum table to hold the different types of workflow statuses */
export type Workflow_Status = {
  __typename?: 'workflow_status'
  description?: Maybe<Scalars['String']>
  value: Scalars['String']
}

/** aggregated selection of "workflow_status" */
export type Workflow_Status_Aggregate = {
  __typename?: 'workflow_status_aggregate'
  aggregate?: Maybe<Workflow_Status_Aggregate_Fields>
  nodes: Array<Workflow_Status>
}

/** aggregate fields of "workflow_status" */
export type Workflow_Status_Aggregate_Fields = {
  __typename?: 'workflow_status_aggregate_fields'
  count: Scalars['Int']
  max?: Maybe<Workflow_Status_Max_Fields>
  min?: Maybe<Workflow_Status_Min_Fields>
}

/** aggregate fields of "workflow_status" */
export type Workflow_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Workflow_Status_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "workflow_status". All fields are combined with a logical 'AND'. */
export type Workflow_Status_Bool_Exp = {
  _and?: Maybe<Array<Workflow_Status_Bool_Exp>>
  _not?: Maybe<Workflow_Status_Bool_Exp>
  _or?: Maybe<Array<Workflow_Status_Bool_Exp>>
  description?: Maybe<String_Comparison_Exp>
  value?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "workflow_status" */
export enum Workflow_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  WorkflowStatusPkey = 'workflow_status_pkey',
}

export enum Workflow_Status_Enum {
  Failed = 'failed',
  Pending = 'pending',
  Succeeded = 'succeeded',
}

/** Boolean expression to compare columns of type "workflow_status_enum". All fields are combined with logical 'AND'. */
export type Workflow_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Workflow_Status_Enum>
  _in?: Maybe<Array<Workflow_Status_Enum>>
  _is_null?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Workflow_Status_Enum>
  _nin?: Maybe<Array<Workflow_Status_Enum>>
}

/** input type for inserting data into table "workflow_status" */
export type Workflow_Status_Insert_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Workflow_Status_Max_Fields = {
  __typename?: 'workflow_status_max_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Workflow_Status_Min_Fields = {
  __typename?: 'workflow_status_min_fields'
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "workflow_status" */
export type Workflow_Status_Mutation_Response = {
  __typename?: 'workflow_status_mutation_response'
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Workflow_Status>
}

/** on_conflict condition type for table "workflow_status" */
export type Workflow_Status_On_Conflict = {
  constraint: Workflow_Status_Constraint
  update_columns?: Array<Workflow_Status_Update_Column>
  where?: Maybe<Workflow_Status_Bool_Exp>
}

/** Ordering options when selecting data from "workflow_status". */
export type Workflow_Status_Order_By = {
  description?: Maybe<Order_By>
  value?: Maybe<Order_By>
}

/** primary key columns input for table: workflow_status */
export type Workflow_Status_Pk_Columns_Input = {
  value: Scalars['String']
}

/** select columns of table "workflow_status" */
export enum Workflow_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "workflow_status" */
export type Workflow_Status_Set_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** Streaming cursor of the table "workflow_status" */
export type Workflow_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Status_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Workflow_Status_Stream_Cursor_Value_Input = {
  description?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

/** update columns of table "workflow_status" */
export enum Workflow_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value',
}

export type Workflow_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Workflow_Status_Set_Input>
  /** filter the rows which have to be updated */
  where: Workflow_Status_Bool_Exp
}

/** Streaming cursor of the table "workflow" */
export type Workflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Workflow_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Workflow_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>
  data?: Maybe<Scalars['jsonb']>
  id?: Maybe<Scalars['uuid']>
  parent_workflow_id?: Maybe<Scalars['uuid']>
  status?: Maybe<Workflow_Status_Enum>
  status_details?: Maybe<Scalars['String']>
  success_item_id?: Maybe<Scalars['uuid']>
  success_item_type?: Maybe<Scalars['String']>
  type?: Maybe<Worflow_Type_Enum>
  updated_at?: Maybe<Scalars['timestamptz']>
  user_id?: Maybe<Scalars['String']>
}

/** update columns of table "workflow" */
export enum Workflow_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ParentWorkflowId = 'parent_workflow_id',
  /** column name */
  Status = 'status',
  /** column name */
  StatusDetails = 'status_details',
  /** column name */
  SuccessItemId = 'success_item_id',
  /** column name */
  SuccessItemType = 'success_item_type',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Workflow_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Workflow_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Workflow_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Workflow_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Workflow_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Workflow_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Workflow_Set_Input>
  /** filter the rows which have to be updated */
  where: Workflow_Bool_Exp
}
