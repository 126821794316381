import { WrappedError } from '@carrotcart/common/lib/errors'
import Observable from 'zen-observable'
import type { GenericStorage } from '@carrotcart/client-common/types'
import isNetworkError from '@carrotcart/common/lib/isNetworkError'
import Sentry from '@carrotcart/common/lib/sentry'

const QUEUEUP_GROUP_KEY_NAMESPACE = '__queue-up-group'

const IGNORED_STORAGE_ERROR_MESSAGES: RegExp[] = [
  /No available storage method found/,
]

const ignoreStorageErrorMessage = (err: Error): boolean => {
  for (let i = 0; i < IGNORED_STORAGE_ERROR_MESSAGES.length; i++) {
    const errMessageRegexp = IGNORED_STORAGE_ERROR_MESSAGES[i]
    if (errMessageRegexp.test(err.message)) return true
  }

  return false
}

export interface Callback<T> {
  (items: T[]): Promise<Error | undefined>
}

export interface OnlineStatusDetails {
  isOnLine: 'yes' | 'no' | 'unknown'
  type?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  details?: object | null
}

export interface GetOnLineStatus {
  (): Promise<OnlineStatusDetails>
}

interface Options<T> {
  callback: Callback<T>
  observable: Observable<OnlineStatusDetails>
  batchSize?: number
  retryDelay?: number
  maxRetries?: number
  batchInterval?: number
  exhaustedRetryInterval?: number
  storage?: GenericStorage
  name: string
}

const DEFAULT_BATCH_SIZE = 50
const DEFAULT_DELAY = 200 // Initial delay of 200ms between retry attempts
const DEFAULT_MAX_RETRIES = 4 // Try a maximum of 5 times
const DEFAULT_BATCH_INTERVAL = 500 // Default batch interval of 500ms
const DEFAULT_EXHAUSTED_RETRY_INTERVAL = 10000 // Default interval to wait before trying to send the logs to the API again

class QueueUpGroup<T> {
  private items: T[] = []
  private callback: Callback<T>
  private onLineStatusDetails: OnlineStatusDetails
  private batchSize: number
  private itemsToProcess: T[] = []
  private retryDelay: number
  private maxRetries: number
  private batchInterval: number
  private timeout?: NodeJS.Timeout | number
  private exhaustedRetryInterval: number
  private _exhaustedRetries = false
  private _processing = false
  private subscription: ZenObservable.Subscription
  private name: string
  private storage?: GenericStorage
  private storeLogs: boolean

  constructor({
    batchSize,
    callback,
    retryDelay,
    maxRetries,
    batchInterval,
    exhaustedRetryInterval,
    observable,
    storage,
    name,
  }: Options<T>) {
    this.callback = callback
    this.onLineStatusDetails = { isOnLine: 'unknown' }
    this.batchSize = Math.max(batchSize ?? DEFAULT_BATCH_SIZE, 1)
    this.retryDelay = Math.max(retryDelay ?? DEFAULT_DELAY, 0)
    this.batchInterval = Math.max(batchInterval ?? DEFAULT_BATCH_INTERVAL, 0)
    this.exhaustedRetryInterval = Math.max(
      exhaustedRetryInterval ?? DEFAULT_EXHAUSTED_RETRY_INTERVAL,
      1000
    )
    this.storage = storage
    this.storeLogs = !!storage
    this.name = name

    this.maxRetries = Math.max(maxRetries ?? DEFAULT_MAX_RETRIES, 0)
    this._processing = false
    // Create the subscription to listen to when the network state of the client changes
    this.subscription = observable.subscribe({
      next: this.next.bind(this),
      error: (err) => {
        Sentry.hub.captureException(
          new WrappedError('BATCH QUEUE ERROR', err, {
            additionalMessage: 'unknown observable subscription error',
          }),
          {
            extra: {
              onLineStatusDetails: this.onLineStatusDetails,
            },
          }
        )
      },
    })
  }

  async init(fn: GetOnLineStatus): Promise<void> {
    this.onLineStatusDetails = await fn()
    this.items = await this.getStoredQueueItems()
  }

  async add(item: T): Promise<void> {
    if (!this.items) {
      this.items = await this.getStoredQueueItems()
    }
    this.items.push(item)
    await this.syncStoredQueueItems()

    // Start processing the queue if not already processing, retries haven't been exhausted,
    // and the client is online
    if (!this.isOnLine()) return
    if (this.exhaustedRetries) return
    await this.process()
  }

  get processing(): boolean {
    return this._processing
  }

  get exhaustedRetries(): boolean {
    return this._exhaustedRetries
  }

  isOnLine(): boolean {
    return this.onLineStatusDetails.isOnLine === 'yes'
  }

  public cleanup(): void {
    if (this.timeout) {
      clearTimeout(this.timeout as number)
      this.timeout = undefined
    }

    this.subscription.unsubscribe()
  }

  async process(bypass = false): Promise<void> {
    if (this.processing && !bypass) return
    this._processing = true

    try {
      this.itemsToProcess = this.getNewBatch()
      if (this.itemsToProcess.length === 0) {
        this._processing = false
        return
      }

      const err = await this.tryCallback()
      if (err !== undefined) {
        this.itemsToProcess = []
        this._processing = false

        const onLineStatusDetails = this.onLineStatusDetails

        // Report this to Sentry only once
        if (!this.exhaustedRetries) {
          if (!isNetworkError(err)) {
            Sentry.hub.captureException(
              new WrappedError('BATCH QUEUE ERROR', err, {
                additionalMessage:
                  'exhausted the maximum number of retry attempts',
              }),
              {
                extra: {
                  onLineStatusDetails,
                },
              }
            )
          }
          this._exhaustedRetries = true
        }

        this.setRetryInterval()
        return
      }
      this.items.splice(0, this.itemsToProcess.length)
      await this.syncStoredQueueItems()

      this.itemsToProcess = []
      await new Promise<void>((resolve) => {
        this.timeout = setTimeout(async () => {
          await this.process(true)
          resolve()
        }, this.batchInterval)
      })
    } catch (err: any) {
      Sentry.hub.captureException(new WrappedError('BATCH QUEUE ERROR', err))
    }
  }

  private async tryCallback(
    retries = 0,
    previousError?: Error
  ): Promise<Error | undefined> {
    // Exit if about to exceed the maximum number of retry attempts
    if (retries > this.maxRetries) {
      return previousError
    }

    const err = await this.callback(this.itemsToProcess)
    if (err === undefined) {
      this._exhaustedRetries = false
      return
    }

    // Only retry once if we have already previously exhausted our retries
    if (this.exhaustedRetries) return err

    // Exponential backoff of retry attempts
    await new Promise((resolve) =>
      setTimeout(resolve, this.retryDelay * 2 ** retries)
    )
    return await this.tryCallback(retries + 1, err)
  }

  private getNewBatch(): T[] {
    return this.items.slice(0, this.batchSize)
  }

  private next(onLineStatusDetails: OnlineStatusDetails) {
    this.onLineStatusDetails = onLineStatusDetails
    if (onLineStatusDetails.isOnLine === 'yes') {
      if (this.timeout) {
        clearTimeout(this.timeout as number)
        this.timeout = undefined
      }

      this.process()
    }
  }

  private setRetryInterval(): void {
    this.timeout = setTimeout(() => {
      // Don't retry if we're not online
      if (this.isOnLine()) {
        this.process()
      } else {
        this.setRetryInterval()
      }
    }, this.exhaustedRetryInterval)
  }

  private storageKey(): string {
    return `${QUEUEUP_GROUP_KEY_NAMESPACE}:${this.name}`
  }

  private async getStoredQueueItems(): Promise<T[]> {
    try {
      if (!this.storeLogs) return []
      const storedItems = await this.storage?.getItem<T[]>(this.storageKey())
      return Array.isArray(storedItems) ? storedItems : []
    } catch (err: any) {
      if (ignoreStorageErrorMessage(err)) {
        this.storeLogs = false
      } else {
        Sentry.hub.captureException(new WrappedError('BATCH QUEUE ERROR', err))
      }
      return []
    }
  }

  private async syncStoredQueueItems(): Promise<void> {
    try {
      if (!this.storeLogs) return

      await this.storage?.setItem<T[]>(this.storageKey(), this.items || [])
    } catch (err: any) {
      if (ignoreStorageErrorMessage(err)) {
        this.storeLogs = false
      } else {
        Sentry.hub.captureException(new WrappedError('BATCH QUEUE ERROR', err))
      }
    }
  }
}

export default QueueUpGroup
