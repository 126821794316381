import type {
  CartItemDataFragment,
  CollectionAndItemsDataFragment,
  ItemDataFragment,
} from '@carrotcart/data/graphql/fragments.generated'
import type { GetUserCreatedCollectionsQuery } from '@carrotcart/data/graphql/getUserCreatedCollections.generated'
import type { GetUserDataForProfilePageQuery } from '@carrotcart/data/graphql/getUserDataForProfilePage.generated'
import type { CollectionPartialForCollectionListFragment } from '@carrotcart/data/graphql/mobile/collection/getUserCollectionsPartial.generated'
import {
  CreateAffiliateRedirectParams,
  createPublicUrl,
} from '@carrotcart/common/lib/helpers'
import { Media_Type_Enum } from '@carrotcart/data/generated/enums'
import type { SearchItemDataFragment } from '@carrotcart/data/graphql/search/fragments.generated'
import type { ProductHitDocument } from '@carrotcart/client-common/hooks/useTypesenseProductSearch'
import { getShopKey, getShopSlug } from '@carrotcart/common/lib/shopUtils'

export {
  createPublicUrl,
  createAffiliateRedirectLink,
  createCollectionUrl,
} from '@carrotcart/common/lib/helpers'

export const isNewerVersion = (currentV: string, newV: string): boolean => {
  const currParts = currentV.split('.')
  const newParts = newV.split('.')
  for (let i = 0; i < newParts.length; i++) {
    const a = ~~newParts[i]
    const b = ~~currParts[i]
    if (a > b) return true
    if (a < b) return false
  }
  return false
}

export const createProfileUrl = (
  username: string | null | undefined,
  {
    local,
    excludeProtocol,
  }: {
    local?: boolean
    excludeProtocol?: boolean
  } = {}
): string | undefined => {
  return username
    ? local
      ? `/${username}`
      : createPublicUrl(`/${username}`, { excludeProtocol })
    : undefined
}

export const getCoverImageFromCollection = (
  collection:
    | CollectionAndItemsDataFragment
    | CollectionPartialForCollectionListFragment
    | GetUserCreatedCollectionsQuery['collections'][0]
    | GetUserDataForProfilePageQuery['collections'][0]
    | undefined,
  { useFallback }: { useFallback?: boolean } = {}
): string => {
  if (useFallback) {
    return cleanImageUrl(
      collection?.cover_image ||
        collection?.collection_items?.[0]?.cart_item?.item?.image
    )
  }

  const coverImage =
    collection?.cover_image_cached ||
    collection?.cover_image ||
    collection?.collection_items?.[0]?.cart_item?.item_image_override ||
    collection?.collection_items?.[0]?.cart_item?.item?.image_highres ||
    collection?.collection_items?.[0]?.cart_item?.item?.cached_image

  return cleanImageUrl(coverImage)
}

export function itemInCollections(
  item: CartItemDataFragment,
  collections: CollectionAndItemsDataFragment[] = []
): boolean {
  const collectionIds = (collections || []).map((c) => c.id)

  return item?.collection_cart_items?.some((cci: any) =>
    collectionIds.includes(cci.collection_id)
  )
}

export const updateImageKitUrl = (url: string | null | undefined): string => {
  if (!url) return ''

  // url starts with  https://ik.imagekit.io/carrot and does not contain a transformation
  if (
    url.startsWith('https://ik.imagekit.io/carrot') &&
    !url.includes('tr=') &&
    !url.includes('tr:') &&
    !url.includes('?')
  ) {
    return `${url}?tr=w-700`
  }

  return url
}

export const cleanImageUrl = (url: string | null | undefined): string =>
  (url || '').replace(/'/g, '%27')

export const itemImageUrl = (
  item: Partial<ItemDataFragment> | null | undefined,
  imageOverride?: string | null | undefined,
  isMobile?: boolean
): string => {
  if (isMobile) {
    return updateImageKitUrl(
      cleanImageUrl(
        imageOverride ||
          item?.cached_image ||
          item?.image_highres ||
          item?.image
      )
    )
  }

  return cleanImageUrl(
    imageOverride || item?.cached_image || item?.image_highres || item?.image
  )
}

export const createDeepLinkToCarrotApp = (
  screen: string,
  id?: string,
  params?: string
): string => {
  const link = process.env.CUSTOM_APP_SCHEME || 'com.addtocarrot.com.carrot'
  return `${link}://${screen}${id ? `/${id}` : ''}${params ? `?${params}` : ''}`
}

export const determineMediaTypeFromUrl = (
  url: string | null | undefined
): Media_Type_Enum | undefined => {
  const youtube = ['youtube.com', 'youtu.be']
  const tiktok = ['tiktok.com', 'vm.tiktok.com']
  const instagram = ['instagram.com']
  const twitch = ['twitch.com', 'twitch.tv']

  if (youtube.some((domain) => url?.includes(domain))) {
    return /\/shorts\//.test(url || '')
      ? Media_Type_Enum.YoutubeShort
      : Media_Type_Enum.Youtube
  }

  if (tiktok.some((domain) => url?.includes(domain))) {
    return Media_Type_Enum.Tiktok
  }

  if (instagram.some((domain) => url?.includes(domain))) {
    return Media_Type_Enum.Instagram
  }

  if (twitch.some((domain) => url?.includes(domain))) {
    return Media_Type_Enum.Twitch
  }

  return
}

export const twitchVideoIdFromUrl = (
  url: string | null | undefined
): string => {
  if (!url) return ''

  const match = url.match(/videos\/(\d+)/)
  return match?.[1] || ''
}

export const currenciesList: Record<string, string> = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  YEN: '¥',
  AUD: '$',
  CAD: '$',
  NZD: '$',
  HKD: '$',
  MXN: '$',
  CHF: 'CHF',
  CNY: '¥',
  JPY: '¥',
  DKK: 'kr',
  INR: '₹',
  KRW: '₩',
  NOK: 'kr',
  RUB: '₽',
  SGD: '$',
  RUP: '₹',
  SEK: 'kr',
}

export const spotifyEmbedUrl = (
  url = '',
  options?: { darkMode?: boolean }
): string => {
  // https://open.spotify.com/embed/playlist/37i9dQZF1DX0XUsuxWHRQd?utm_source=generator&theme=0
  // https://open.spotify.com/playlist/37i9dQZF1DX0XUsuxWHRQd

  if (url?.indexOf('embed') !== -1) return url

  try {
    const urlObj = new URL(url)
    if (options?.darkMode) urlObj.searchParams.set('theme', '0')
    return urlObj.toString().replace('/playlist/', '/embed/playlist/')
  } catch (e) {
    return url
  }
}

export const isCarrotRedirectLink = (
  link: string | null | undefined
): boolean => {
  return link ? link.includes('carrot.link/r/') : false
}

export const applyUserParamsToAffiliateLink = (
  link: string | null | undefined,
  { cid, uid }: CreateAffiliateRedirectParams = {}
): string => {
  try {
    if (!link) return ''
    // check if link already has params
    const hasParams = link.includes('?')
    const params = new URLSearchParams()

    if (hasParams) {
      // update cid and uid if they exist
      const url = new URL(link)
      const urlParams = new URLSearchParams(url.search)
      if (cid) {
        urlParams.set('cid', cid)
      }
      if (uid) {
        urlParams.set('uid', uid)
      }
      // append params to link
      const queryString = urlParams.toString()
      link = `${url.origin}${url.pathname}${
        queryString ? `?${queryString}` : ''
      }`
    } else {
      if (cid) params.append('cid', cid)
      if (uid) params.append('uid', uid)
      // append params to link
      const queryString = params.toString()
      link = `${link}${queryString ? `?${queryString}` : ''}`
    }

    return link
  } catch (e) {
    return link || ''
  }
}

export const mapProductDocumentHitsToSearchItemDataFragments = (
  hits: ProductHitDocument[]
): SearchItemDataFragment[] => {
  return hits
    .filter((h) => !!h.url)
    .map((hit) => ({
      id: hit.id,
      item_id: hit.url as string,
      shop_key: getShopKey(hit.shop?.value),
      display_title: hit.display_title,
      url: hit.url,
      image: hit.image,
      cached_image: hit.image,
      saved_by_current_user: false,
      pricing: hit.pricing,
      is_inappropriate: false,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      shop: {
        id: hit.shop?.value,
        key: getShopKey(hit.shop?.value),
        name: hit.shop?.label,
        display_name: hit.shop?.label,
        slug: getShopSlug(hit.shop?.label),
      },
    }))
}
