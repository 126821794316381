import React from 'react'
import { Logger } from 'pino'

interface State {
  hasError: boolean
}

interface Props {
  logger: Logger
}

// Currently just gonna be using this on the OnPage component so that we properly capture any unhandled errors
class ErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static getDerivedStateFromError(_error: any): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(err: any, errorInfo: any): void {
    this.props.logger.error({
      message: 'Error boundary uncaught error',
      err,
      'error.extras': errorInfo,
    })
  }

  render(): React.ReactNode | React.ReactNodeArray | null {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}

export default ErrorBoundary
