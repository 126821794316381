import Observable from 'zen-observable'
import localforage from 'localforage'
import QueueUpGroup, {
  OnlineStatusDetails,
} from '@carrotcart/client-common/lib/QueueUpGroup'
import sendToLoggingEndpoint from '../transmit'
import base from '../base'
import getOnLineStatus from './getOnLineStatus'
import sign from './sign'

const observable = new Observable<OnlineStatusDetails>((observer) => {
  if (typeof window === 'undefined') {
    observer.complete()
    return
  }

  const listener = () => {
    const onLineStatus = getOnLineStatus()
    observer.next(onLineStatus)
  }

  window.addEventListener('online', listener)
  window.addEventListener('offline', listener)

  return () => {
    window.removeEventListener('online', listener)
    window.removeEventListener('offline', listener)
  }
})

const queue = new QueueUpGroup({
  callback: sendToLoggingEndpoint(sign),
  observable,
  name: 'web-logger',
  storage: localforage,
})

queue.init(async () => getOnLineStatus())

export default base({ queue })
