import dayjs from 'dayjs'
import logger from '@carrotcart/client-common/lib/logger/web'
import { Link_Type_Enum } from '@carrotcart/data/generated/types'
import { FindCollectionType } from '@carrotcart/app/types'
import type { GetCollectionItemsQuery } from '@carrotcart/data/graphql/collections/getCollectionItems.generated'

const SKIP_URL_PARAMS: string[] = ['undefined', 'null']

// re-order the array so the "cards" read left-right
// cols === CSS column-count value
export const columnsSorter = <T = any>(
  arr?: T[] | undefined | null,
  cols = 1
): T[] | undefined | null => {
  if (!arr) return arr

  const out: T[] = []
  let col = 0
  while (col < cols) {
    for (let i = 0; i < arr.length; i += cols) {
      const _val = arr[i + col]
      if (_val !== undefined) out.push(_val)
    }
    col++
  }

  return out
}

export const checkUserAgent = (userAgent: string): string | undefined => {
  // User agent string to iOS version
  const iOSVersion = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/)
  if (iOSVersion) {
    const [major, minor, patch] = iOSVersion.slice(1)
    return [major, minor, patch].filter(Boolean).join('.')
  }
  return undefined
}

export const createApiUrl = (
  path: string,
  params: Record<string, string> = {}
): string => {
  if (!path) return ''

  let urlString = `${(process.env.API_ENDPOINT || '').replace(
    /\/$/,
    ''
  )}/${path.replace(/^\//, '')}`

  try {
    const url = new URL(urlString.replace(/\/$/, ''))

    Object.entries(params).forEach(([key, value]) => {
      if (!SKIP_URL_PARAMS.includes(String(value).toLowerCase()))
        url.searchParams.append(key, value)
    })

    urlString = url.toString()
  } catch (error) {
    logger.error(error as Error)
    return ''
  }

  return urlString
}

export const titleCase = (str: string): string =>
  str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

// Should not be needed once imported description sanitization is
// updated to handle these encoded entities before inserting into the db
export const itemDescription = (str: string | undefined): string => {
  const description = str || ''
  const cleanDesc = description
    .replace(/&glt;/g, '<')
    .replace(/&ggt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')

  // remove all html tags
  let cleanedDesc = cleanDesc.replace(/<[a-zA-Z][^<>&]*>/gim, '')
  cleanedDesc = cleanedDesc.replace(/<\/[a-zA-Z]+>/gim, '')

  return cleanedDesc
}

export const extractPathNameFromUrl = (url: string): string => {
  if (!url) return ''

  try {
    const urlObj = new URL(url)
    return urlObj.pathname.replace(/^\//, '')
  } catch (error) {
    return ''
  }
}

export const sanitizeSocialInput = (
  handle: string,
  type: Link_Type_Enum
): string => {
  const trimmedHandle = handle.trim()
  let url = ''

  if (!trimmedHandle) return ''

  switch (type) {
    case Link_Type_Enum.Youtube:
      url =
        handle.indexOf('@') !== -1
          ? `https://www.youtube.com/${trimmedHandle}`
          : `https://www.youtube.com/@${trimmedHandle}`
      break
    case Link_Type_Enum.Instagram:
      url = `https://www.instagram.com/${trimmedHandle}`
      break
    case Link_Type_Enum.Tiktok:
      url =
        trimmedHandle.indexOf('@') !== -1
          ? `https://www.tiktok.com/${trimmedHandle}`
          : `https://www.tiktok.com/@${trimmedHandle}`
      break
    case Link_Type_Enum.Twitter:
      url = `https://www.twitter.com/${trimmedHandle}`
      break
    default:
      break
  }

  return url
}

export const generateCollectionTitle = (
  collection: FindCollectionType
): string => {
  const collectionName = collection.private
    ? 'Private Collection'
    : collection.name

  return `${collectionName} - ${
    collection.cart_items_count > 4 ? `${collection.cart_items_count} ` : ''
  } suggestions curated by @${collection?.user?.username}`
}

export const generateCollectionOgDescription = ({
  collection,
  collectionItems,
}: {
  collection: FindCollectionType
  collectionItems: GetCollectionItemsQuery['collection_cart_item']
}): string => {
  const lastUpdatedAt = collection.cart_item_max_updated_at
  const featuringShops = Array.from(
    new Set((collectionItems || []).map((ci) => ci.cart_item.shop.display_name))
  ).slice(0, 3)

  return collection.private
    ? 'Private Collection'
    : `${dayjs(lastUpdatedAt).format(
        'MMM DD, YYYY'
      )} — Browse products curated by @${
        collection?.user?.username
      } featuring brands like ${featuringShops.join(', ')} and more`
}
