import Cookies from 'js-cookie'
import { CARROT_SPLIT_DATA_COOKIE_KEY } from '@carrotcart/app/lib/constants'
import { FeatureFlag } from '@carrotcart/common/lib/split'
import { extractServerSideSplitCookie } from '@carrotcart/app/lib/split/helpers'
import logger from '@carrotcart/client-common/lib/logger/web'

const emitSplitTrackingEvents = async (): Promise<void> => {
  const val = Cookies.get(CARROT_SPLIT_DATA_COOKIE_KEY)
  try {
    const splits = extractServerSideSplitCookie(val)
    const split = (await import('@carrotcart/client-common/lib/split')).default
    await new Promise<void>((resolve) => {
      analytics.ready(async () => {
        await Promise.all(
          Object.keys(splits.featureFlags).map(
            async (featureFlag: FeatureFlag) =>
              split.track(featureFlag, splits.featureFlags[featureFlag])
          )
        )
        resolve()
      })
    })
  } catch (err) {
    logger.error({ err })
  }
}

export default emitSplitTrackingEvents
