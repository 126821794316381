import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import useClientCommonAuthUser from '@carrotcart/client-common/hooks/useAuthUser'

export const PERSISTED_AUTHUSER_UID_KEY = '__carrot_persisted_authUser_uid'

type Response = {
  loadingPersistedAuthUserId: boolean
  persistedAuthUserId: string | null | undefined
  clearOutPersistedAuthUserId: () => void
  authUser: ReturnType<typeof useClientCommonAuthUser>['authUser']
  loading: ReturnType<typeof useClientCommonAuthUser>['loading']
  error: ReturnType<typeof useClientCommonAuthUser>['error']
}

const useAuthUser = (): Response => {
  const { authUser, loading, error } = useClientCommonAuthUser()
  const [loadingPersistedAuthUserId, setLoadingPersistedAuthUserId] =
    useState(true)
  const [persistedAuthUserId, setPersistedAuthUserId] =
    useState<string | undefined>()

  const clearOutPersistedAuthUserId = (): void => {
    Cookies.remove(PERSISTED_AUTHUSER_UID_KEY)
  }

  useEffect(() => {
    const cookieValue = Cookies.get(PERSISTED_AUTHUSER_UID_KEY)
    if (cookieValue) {
      setPersistedAuthUserId(cookieValue)
    } else {
      setPersistedAuthUserId(undefined)
    }
    setLoadingPersistedAuthUserId(false)
  }, [])

  useEffect(() => {
    if (loading) return
    if (authUser?.uid) {
      setPersistedAuthUserId(authUser.uid)
      Cookies.set(PERSISTED_AUTHUSER_UID_KEY, authUser.uid)
    } else {
      setPersistedAuthUserId(undefined)
      Cookies.remove(PERSISTED_AUTHUSER_UID_KEY)
    }
  }, [authUser?.uid, loading, setPersistedAuthUserId])

  return {
    loadingPersistedAuthUserId,
    persistedAuthUserId,
    clearOutPersistedAuthUserId,
    authUser,
    loading,
    error,
  }
}

export default useAuthUser
