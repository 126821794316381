// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
  apiKey: 'AIzaSyCx-my4TxbuuCUSW_Co1I6xSOGDUfpO_TY',
  authDomain: 'auth.withcarrot.com', //carrot-301622.firebaseapp.com
  databaseURL: 'https://carrot-301622-default-rtdb.firebaseio.com',
  projectId: 'carrot-301622',
  storageBucket: 'carrot-301622.appspot.com',
  messagingSenderId: '726338359225',
  appId: '1:726338359225:web:a23702736b5d5d7967f068',
  measurementId: 'G-9HR43Z9V66',
}
