import EventEmitter from 'events'
import axios from 'axios'
import type { User as AuthUser } from '@firebase/auth'
import { onAuthStateChanged } from '@firebase/auth'
import {
  getBillingModeFromIdTokenResult,
  getRoleFromIdTokenResult,
} from '@carrotcart/common/lib/hasuraClaimHelpers'
import { TOGGLE_BILLING_MODE_API_ENDPOINT } from '@carrotcart/common/lib/constants'
import {
  auth,
  getUserToken,
  signInWithToken,
} from '@carrotcart/client-common/lib/firebase'
import type { GenericStorage } from '@carrotcart/client-common/types'
import {
  ALLOWABLE_BILLING_MODES,
  DEFAULT_ANONYMOUS_BILLING_MODE,
} from '@carrotcart/common/billingApiSharedConstants'
import type { BillingMode } from '@carrotcart/common/types'
export type { BillingMode } from '@carrotcart/common/types'

// let storage: GenericStorage | undefined = undefined

export interface ListenerObject {
  billingMode: BillingMode
  initialized: boolean
  toggling: boolean
}

export interface BillingModeToggleListener {
  (listenerObject: ListenerObject): void
}

const eventEmitter = new EventEmitter()

export const BILLING_MODE_TOGGLE_EVENT = 'toggle_billing_mode'
export const BILLING_MODE_STORAGE_KEY = '__CARROT_BILLING_MODE_KEY__'
const INIT_TIMEOUT = 3000

export const addListener = (listener: BillingModeToggleListener): void => {
  eventEmitter.addListener(BILLING_MODE_TOGGLE_EVENT, listener)
}

export const removeListener = (listener: BillingModeToggleListener): void => {
  eventEmitter.removeListener(BILLING_MODE_TOGGLE_EVENT, listener)
}

export const waitForBillingModeInit = async (): Promise<void> => {
  if (finishedInit) return

  let listener: BillingModeToggleListener | undefined = undefined

  await new Promise<void>((resolve) => {
    let timeout: NodeJS.Timeout | undefined = undefined

    listener = ({ initialized }) => {
      if (initialized) {
        if (timeout) clearTimeout(timeout)
        resolve()
      }
    }

    addListener(listener)

    // Add a timeout just in case
    timeout = setTimeout(() => {
      resolve()
    }, INIT_TIMEOUT)
  })

  if (listener) removeListener(listener)
}

let billingMode: BillingMode = DEFAULT_ANONYMOUS_BILLING_MODE
// let initAuthorized: boolean
let ranAuthInit = false
let ranLocalStorageInit = false
export let finishedInit = false

const getBillingModeFromAuthUser = async (
  authUser: AuthUser | null
): Promise<{ billingMode: BillingMode; authorized: boolean }> => {
  // The below guard statements are meant to make sure that non-admin users cannot
  // make billing requests outside of the default billing mode.

  if (!authUser)
    return { billingMode: DEFAULT_ANONYMOUS_BILLING_MODE, authorized: false }

  const tokenResult = await getUserToken(authUser)
  if (!tokenResult)
    return { billingMode: DEFAULT_ANONYMOUS_BILLING_MODE, authorized: false }

  const role = getRoleFromIdTokenResult(tokenResult, 'user_admin')
  if (!role)
    return { billingMode: DEFAULT_ANONYMOUS_BILLING_MODE, authorized: false }

  const mode = getBillingModeFromIdTokenResult(tokenResult)
  return { billingMode: mode, authorized: true }
}

const emitEvent = (toggling = false): void => {
  eventEmitter.emit(BILLING_MODE_TOGGLE_EVENT, <ListenerObject>{
    billingMode,
    initialized: finishedInit,
    toggling,
  })
}

interface ToggleBillingModeReturnVal {
  billingMode: BillingMode
  updated: boolean
}

const toggleBillingModeForCurrentUser =
  async (): Promise<ToggleBillingModeReturnVal> => {
    const previousBillingMode = billingMode

    try {
      if (!auth.currentUser) return { billingMode, updated: false }

      const tokenResult = await getUserToken(auth.currentUser)
      if (!tokenResult) return { billingMode, updated: false }

      const res = await axios.post<{
        billing_mode: BillingMode
        custom_token: string
      }>(TOGGLE_BILLING_MODE_API_ENDPOINT, null, {
        headers: { authorization: `Bearer ${tokenResult?.token}` },
        validateStatus: (_status) => true,
      })
      if (res.status !== 200) return { billingMode, updated: false }

      const { billing_mode, custom_token } = res.data
      await signInWithToken(custom_token)

      billingMode = ALLOWABLE_BILLING_MODES.has(billing_mode)
        ? res.data.billing_mode
        : DEFAULT_ANONYMOUS_BILLING_MODE

      // Need to add an artificial timeout so that firebase is able to persist the new user
      // auth creds change that has newly toggled billing mode set from the hasura claims
      await new Promise((resolve) => setTimeout(resolve, 5))
    } catch {
      // no-op
    } finally {
      emitEvent()
    }

    return { billingMode, updated: billingMode !== previousBillingMode }
  }

export const toggleBillingMode =
  async (): Promise<ToggleBillingModeReturnVal> => {
    emitEvent(true)
    return toggleBillingModeForCurrentUser()
  }

export const getBillingMode = (): BillingMode => billingMode
export const getBillingModeState = (): ListenerObject => ({
  billingMode,
  initialized: finishedInit,
  toggling: false,
})

const finishInit = async (): Promise<void> => {
  if (finishedInit) return
  if (!ranAuthInit) return
  if (!ranLocalStorageInit) return

  finishedInit = true
  emitEvent()
}

let processingLocalStorageInit = false

export const init = async (
  _passedInStorage?: GenericStorage
): Promise<void> => {
  if (processingLocalStorageInit) return
  processingLocalStorageInit = true

  ranLocalStorageInit = true
  await finishInit()
}

onAuthStateChanged(auth, async (authUser) => {
  // initAuthorized = false
  try {
    const { billingMode: billingModeFromClaims } =
      await getBillingModeFromAuthUser(authUser)
    billingMode = billingModeFromClaims
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // initAuthorized = authorized
  } finally {
    ranAuthInit = true
    await finishInit()
  }
})
