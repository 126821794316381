import {
  AuthProvider,
  signOut as authSignOut,
  User as AuthUser,
  fetchSignInMethodsForEmail,
  linkWithCredential,
  linkWithPopup,
  OAuthProvider,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithPopup,
  UserCredential,
  EmailAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  RecaptchaVerifier,
  PhoneAuthProvider,
  ConfirmationResult,
  updateEmail,
  updateProfile,
  OAuthCredential,
} from 'firebase/auth'
import { FirebaseError } from '@firebase/util'
import axios from 'axios'
import { Logger } from 'pino'
import * as Sentry from '@sentry/core'
import {
  ANONYMOUS_AUTHORIZATION_HEADER,
  ORPHAN_ANONYMOUS_USER_API_ENDPOINT,
} from '@carrotcart/common/lib/constants'
import auth from '@carrotcart/client-common/lib/firebase/auth'
import { createPublicUrl } from '@carrotcart/client-common/lib/helpers'
import isAnonymousAuthUser from '@carrotcart/common/lib/isAnonymousAuthUser'
import { UserAuthData, UserMetadata } from '@carrotcart/common/types'
import {
  AnonymousProvider,
  AppleProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from '@carrotcart/common/lib/firebaseProviders'
import { createCustomToken } from './createCustomToken'
import getUserToken from './getUserToken'

export { createCustomToken } from './createCustomToken'

declare global {
  interface Window {
    google: any
  }
}

const anonymousProvider = new AnonymousProvider()

const appleProvider = new AppleProvider()

const emailProvider = new EmailAuthProvider()

const phoneProvider =
  typeof window !== 'undefined'
    ? new PhoneAuthProvider(auth)
    : ({} as AuthProvider)

// Google Provider Setup
const providerGoogle = new GoogleAuthProvider()
providerGoogle.setCustomParameters({
  prompt: 'select_account',
})
// https://developers.google.com/identity/protocols/oauth2/scopes
providerGoogle.addScope('https://www.googleapis.com/auth/userinfo.email')
providerGoogle.addScope('https://www.googleapis.com/auth/userinfo.profile')

// Facebook Provider Setup
const providerFacebook = new FacebookAuthProvider()
providerFacebook.addScope('email')

export const CARROT_SIGNIN_EMAIL_KEY = '__carrot_signInEmail'
export enum SignInMode {
  signIn = 'signIn',
}

export type SupportedProviderId =
  | typeof AnonymousProvider.PROVIDER_ID
  | typeof GoogleAuthProvider.PROVIDER_ID
  | typeof FacebookAuthProvider.PROVIDER_ID
  | typeof AppleProvider.PROVIDER_ID
  | typeof EmailAuthProvider.PROVIDER_ID
  | typeof PhoneAuthProvider.PROVIDER_ID

export const SUPPORTED_PROVIDER_MAPPING: {
  [key in SupportedProviderId]: AuthProvider
} = {
  [AnonymousProvider.PROVIDER_ID]: anonymousProvider,
  [GoogleAuthProvider.PROVIDER_ID]: providerGoogle,
  [FacebookAuthProvider.PROVIDER_ID]: providerFacebook,
  [AppleProvider.PROVIDER_ID]: appleProvider,
  [EmailAuthProvider.PROVIDER_ID]: emailProvider,
  [PhoneAuthProvider.PROVIDER_ID]: phoneProvider,
}

const getUserBearerToken = async (
  authUser: AuthUser | null
): Promise<string | undefined> => {
  if (!authUser) return
  const userAuthData = await getUserToken(authUser)
  return userAuthData?.token
}

const sendRequestToOrphanAnonymousUser = async (
  authUser: AuthUser | null,
  anonymousToken: string | undefined,
  logger: Logger,
  signupMetadata?: UserMetadata
): Promise<void> => {
  if (!authUser) return
  try {
    // Pass bearer tokens for both anonymous and existingUser avoids sending raw user id
    const authUserToken = await getUserBearerToken(authUser)
    const REQUEST_HEADERS = {
      Authorization: `Bearer ${authUserToken}`,
      'Content-Type': 'application/json',
      [ANONYMOUS_AUTHORIZATION_HEADER]: `Bearer ${anonymousToken}`,
    }

    const orphanAnon = await axios.post(
      ORPHAN_ANONYMOUS_USER_API_ENDPOINT,
      { signupMetadata: signupMetadata || {} },
      {
        headers: REQUEST_HEADERS,
        timeout: 5000,
      }
    )
    logger.debug({
      message: 'response from orphan anonymous user request',
      data: {
        'orphan.anonymous.user.response': orphanAnon,
      },
    })
  } catch (err) {
    // handle error
    logger.error({
      message: 'error in response from orphan anonymous user request',
      err,
    })
  }
}

export interface SignInReturnValue {
  credential: UserCredential | undefined
  forceExtensionLogin: boolean
}

interface GoogleResponse {
  expires_in: string
  token_type: string
  refresh_token: string
  id_token: string
  user_id: string
  project_id: string
  access_token: string
}

export const signInWithProvider = async ({
  providerId,
  logger,
  signupMetadata,
}: {
  providerId: SupportedProviderId
  logger: Logger
  signupMetadata?: UserMetadata
}): Promise<SignInReturnValue> => {
  try {
    if (providerId === AnonymousProvider.PROVIDER_ID) {
      logger.debug('signing in with anonymous provider')
      const credential = await signInAnonymously(auth)
      return { credential, forceExtensionLogin: false }
    }

    // check if we have an authUser & if anonymous
    const authCurrentUser = auth.currentUser
    const email =
      authCurrentUser?.email || authCurrentUser?.providerData[0]?.email
    const anonymous = !email

    logger.debug({
      message: 'checking if we have an authUser & if anonymous',
      anonymous,
      authCurrentUser,
      shouldAttemptLink:
        authCurrentUser && !authCurrentUser.isAnonymous && anonymous,
    })

    // If we have a current user and it's anonymous, we need to link it with the provider
    // https://firebase.google.com/docs/auth/web/account-linking#link-federated-auth-provider-credentials-to-a-user-account
    if (authCurrentUser && anonymous) {
      const credential = await linkWithPopup(
        authCurrentUser,
        SUPPORTED_PROVIDER_MAPPING[providerId]
      )
      logger.debug('attempt to linkWithPopup, force true')
      return { credential, forceExtensionLogin: true }
    }

    if (providerId === AppleProvider.PROVIDER_ID) {
      const appleProvider = new OAuthProvider('apple.com')
      appleProvider.addScope('email')
      appleProvider.addScope('name')

      const credential = await signInWithPopup(auth, appleProvider)
      logger.debug('attempt to signInWithPopup via apple, force false')
      return { credential, forceExtensionLogin: false }
    }

    // if we do not have a current user and it's not anonymous, sign in normally

    logger.debug('attempt to signInWithPopup, normal flow')
    const credential = await signInWithPopup(
      auth,
      SUPPORTED_PROVIDER_MAPPING[providerId]
    )
    return { credential, forceExtensionLogin: false }
  } catch (error: any) {
    switch (error?.code) {
      case 'auth/cancelled-popup-request':
      case 'auth/popup-closed-by-user':
        return { credential: undefined, forceExtensionLogin: false }
      case 'auth/credential-already-in-use': {
        const pendingCred = OAuthProvider.credentialFromError(error)
        const currentUser = auth.currentUser

        logger.debug('creds already in use, attempt to link')

        if (pendingCred && currentUser) {
          let anonymousUserToken: string | undefined

          // if auth.currentUser is anonymous, we get the token before
          // signing in with email, so that we can use the token to
          // delete the anonymous user afterwards
          const isAnAnonymousAuthUser = isAnonymousAuthUser(currentUser)
          if (isAnAnonymousAuthUser) {
            // delete redundant anonymous user
            // this should be an api call only if the user is anonymous
            anonymousUserToken = await getUserBearerToken(currentUser)
          }

          // sign in to existing account
          const credential = await signInWithCredential(auth, pendingCred)

          //get newly signed in user
          const existingUser = auth.currentUser

          // Send request to delete the anonymous user
          // passing the anonymous user token and existingUsers (AuthUser)
          // Send request to delete the anonymous user

          logger.debug(
            'checking for anonymous token, if missing dont force extension login'
          )
          let forceExtensionLogin = false
          if (anonymousUserToken) {
            await sendRequestToOrphanAnonymousUser(
              existingUser,
              anonymousUserToken,
              logger,
              signupMetadata
            )
            forceExtensionLogin = true

            logger.debug(
              'anon token found, orphan request sent and will force login'
            )
          }

          return { credential, forceExtensionLogin }
        }

        throw error
      }
      case 'auth/email-already-in-use':
      case 'auth/account-exists-with-different-credential': {
        const pendingCred = OAuthProvider.credentialFromError(error)
        const email = error.customData.email as string
        const signInMethods = await fetchSignInMethodsForEmail(auth, email)
        const currentUser = auth.currentUser

        // try to link the social account to the existing account
        const isAnAnonymousAuthUser = isAnonymousAuthUser(currentUser)
        if (pendingCred && currentUser && !isAnAnonymousAuthUser) {
          await linkWithCredential(currentUser, pendingCred)
          return { credential: undefined, forceExtensionLogin: false }
        } else {
          const message = `Account with email (${email}) already exists using ${signInMethods.join(
            ', '
          )}.<br />Please sign in with the existing account.`

          Sentry.captureException(error, {
            user: {
              email,
            },
            extra: {
              signInMethods,
            },
          })

          throw new FirebaseError(error.code, message, {
            ...error.customData,
            signInMethods,
            pendingCred,
          })
        }
      }
      default:
        throw error
    }
  }
}

let recaptchaVerifier: RecaptchaVerifier | undefined

export const signInWithPhoneConfirmationResult = async (
  phoneNumber: string
): Promise<ConfirmationResult> => {
  if (!phoneNumber) {
    throw new FirebaseError('auth/number-not-provided', 'Phone number required')
  }

  recaptchaVerifier =
    recaptchaVerifier ||
    new RecaptchaVerifier(
      'phoneRecaptcha',
      {
        size: 'invisible',
      },
      auth
    )

  // sign in or sign up with email & password
  const confirmationResult = await signInWithPhoneNumber(
    auth,
    phoneNumber,
    recaptchaVerifier
  )

  return confirmationResult
}

export const signInWithPhone = async ({
  code,
  confirmationResult,
  shouldDeleteAnonymous,
  signupMetadata,
  logger,
  callback,
}: {
  code: string
  confirmationResult: ConfirmationResult
  shouldDeleteAnonymous?: boolean
  signupMetadata?: UserMetadata
  logger: Logger
  callback?: () => Promise<void> | void
}): Promise<SignInReturnValue> => {
  if (!code) {
    throw new FirebaseError('auth/code-not-provided', 'Code required')
  }

  let anonymousUserToken: string | undefined

  // if auth.currentUser is anonymous, we get the token before
  // signing in, so that we can use the token to
  // delete the anonymous user afterwards
  const isAnAnonymousAuthUser = isAnonymousAuthUser(auth.currentUser)
  if (isAnAnonymousAuthUser && shouldDeleteAnonymous) {
    anonymousUserToken = await getUserBearerToken(auth.currentUser)
  }

  let credential: UserCredential

  // sign in or sign up with email & password
  try {
    const authCredential = PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      code
    )

    credential = await signInWithCredential(auth, authCredential)
  } catch (err: any) {
    switch (err.code) {
      default:
        throw err
    }
  }

  // get newly signed in user
  const existingUser = auth.currentUser
  let forceExtensionLogin = false

  // Send request to delete the anonymous user
  // passing the anonymous user token and existingUsers (AuthUser)
  if (anonymousUserToken && shouldDeleteAnonymous) {
    await sendRequestToOrphanAnonymousUser(
      existingUser,
      anonymousUserToken,
      logger,
      signupMetadata
    )
    forceExtensionLogin = true
  }

  if (callback) {
    await callback()
  }

  return { credential, forceExtensionLogin }
}

export const signInWithEmailPassword = async ({
  email,
  password,
  shouldDeleteAnonymous,
  createUserIfNotFound,
  signupMetadata,
  logger,
  callback,
}: {
  email: string
  password: string
  shouldDeleteAnonymous?: boolean
  createUserIfNotFound?: boolean
  signupMetadata?: UserMetadata
  logger: Logger
  callback?: () => Promise<void> | void
}): Promise<SignInReturnValue> => {
  if (!email) {
    throw new FirebaseError('auth/email-not-provided', 'Email required')
  }

  const signInMethods = await fetchSignInMethodsForEmail(auth, email)

  let anonymousUserToken: string | undefined

  // if auth.currentUser is anonymous, we get the token before
  // signing in with email, so that we can use the token to
  // delete the anonymous user afterwards
  const isAnAnonymousAuthUser = isAnonymousAuthUser(auth.currentUser)
  if (isAnAnonymousAuthUser && shouldDeleteAnonymous) {
    anonymousUserToken = await getUserBearerToken(auth.currentUser)
  }

  let credential: UserCredential | undefined = undefined

  // sign in or sign up with email & password
  try {
    credential = await signInWithEmailAndPassword(auth, email, password)
  } catch (err: any) {
    switch (err.code) {
      case 'auth/user-not-found':
        if (createUserIfNotFound) {
          credential = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          )
        } else {
          throw new FirebaseError(
            err.code,
            'User not found. Try again, or set new password',
            {
              email,
              signInMethods,
            }
          )
        }
        break
      case 'auth/wrong-password':
        throw new FirebaseError(
          err.code,
          'Incorrect password. Try again, or set new password',
          {
            email,
            signInMethods,
          }
        )
      default:
        throw err
    }
  }

  // get newly signed in user
  const existingUser = auth.currentUser
  let forceExtensionLogin = false
  // Send request to delete the anonymous user
  // passing the anonymous user token and existingUsers (AuthUser)
  if (anonymousUserToken && shouldDeleteAnonymous) {
    await sendRequestToOrphanAnonymousUser(
      existingUser,
      anonymousUserToken,
      logger,
      signupMetadata
    )
    forceExtensionLogin = true
  }

  if (callback) {
    await callback()
  }

  return { credential, forceExtensionLogin }
}

export const sendPasswordReset = async ({
  email,
}: {
  email: string
}): Promise<void> => sendPasswordResetEmail(auth, email)

export const signInWithEmail = async ({
  email,
  emailLink,
  shouldDeleteAnonymous,
  signupMetadata,
  logger,
  callback,
}: {
  email: string
  emailLink: string
  shouldDeleteAnonymous?: boolean
  signupMetadata?: UserMetadata
  logger: Logger
  callback?: () => Promise<void> | void
}): Promise<SignInReturnValue> => {
  if (!email) {
    throw new FirebaseError(
      'auth/email-not-provided',
      'Email is required to sign in.'
    )
  }

  let anonymousUserToken: string | undefined

  // if auth.currentUser is anonymous, we get the token before
  // signing in with email, so that we can use the token to
  // delete the anonymous user afterwards
  const isAnAnonymousAuthUser = isAnonymousAuthUser(auth.currentUser)
  if (isAnAnonymousAuthUser && shouldDeleteAnonymous) {
    anonymousUserToken = await getUserBearerToken(auth.currentUser)
  }

  // sign in with email
  const credential = await signInWithEmailLink(auth, email, emailLink)

  // get newly signed in user
  const existingUser = auth.currentUser
  let forceExtensionLogin = false
  // Send request to delete the anonymous user
  // passing the anonymous user token and existingUsers (AuthUser)
  if (anonymousUserToken && shouldDeleteAnonymous) {
    await sendRequestToOrphanAnonymousUser(
      existingUser,
      anonymousUserToken,
      logger,
      signupMetadata
    )
    forceExtensionLogin = true
  }

  if (callback) {
    await callback()
  }

  return { credential, forceExtensionLogin }
}

export const hasSignedInWithEmailLink = (emailLink: string): boolean => {
  return isSignInWithEmailLink(auth, emailLink)
}

export const sendSignInLink = async ({
  email,
  url,
  callback,
}: {
  email: string
  url?: string
  callback?: () => Promise<void> | void
}): Promise<void> => {
  await sendSignInLinkToEmail(auth, email, {
    url: url || createPublicUrl('/signup-confirm'),
    handleCodeInApp: true, // This must be true.
    iOS: {
      bundleId: 'com.addtocarrot.com.carrot',
    },
  })

  if (callback) {
    await callback()
  }
}

export const signInWithToken = async (
  customToken: string
): Promise<UserCredential> => {
  return await signInWithCustomToken(auth, customToken)
}

export const signOut = async (): Promise<void> => {
  if (typeof window.google !== 'undefined' && window.google.accounts) {
    window.google.accounts?.id?.disableAutoSelect()
  }
  return await authSignOut(auth)
}

export const signInWithRefreshedAccessToken = async (
  accessToken: string,
  uid: string
): Promise<UserCredential | undefined> => {
  const userAuthData: UserAuthData = {
    accessToken,
    uid,
  }
  const customToken = await createCustomToken(userAuthData)
  if (customToken) {
    return await signInWithToken(customToken)
  }
  throw new Error('Failed to create custom token')
}

export const signInWithAuthCredential = async (
  authCredential: OAuthCredential
): Promise<UserCredential> => signInWithCredential(auth, authCredential)

export const accessTokenFromRefreshToken = async (
  refreshToken: string,
  logger: Logger
): Promise<GoogleResponse | undefined> => {
  try {
    const response = await axios.post<GoogleResponse>(
      `https://securetoken.googleapis.com/v1/token?key=${process.env.FIREBASE_WEB_API_KEY}`,
      {
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    if (response.status !== 200) {
      logger.error({
        message: 'Non 200 status code in accessTokenFromRefreshToken',
        'context.status.code': response.status,
      })

      return undefined
    }
    return response.data
  } catch (err) {
    logger.error({
      message: 'Failed to refresh token in accessTokenFromRefreshToken',
      err,
    })
    return undefined
  }
}

export const updateAuthUserDetails = async ({
  email,
  displayName,
  photoURL,
}: {
  email?: string
  displayName?: string
  photoURL?: string
}): Promise<void> => {
  if (!auth.currentUser) return

  if (email) {
    await updateEmail(auth.currentUser, email)
  }

  if (displayName) {
    await updateProfile(auth.currentUser, {
      displayName,
    })
  }

  if (photoURL) {
    await updateProfile(auth.currentUser, {
      photoURL,
    })
  }
}
