import React, { useState, useEffect, useContext, createContext } from 'react'
import useAuthUser from '@carrotcart/client-common/hooks/useAuthUser'
import type { SplitWrapper } from '@carrotcart/client-common/lib/split'
import type { FeatureFlag } from '@carrotcart/common/lib/split'
import type { GenericAnalyticsForSplit } from '@carrotcart/client-common/types'

interface FeatureFlagState {
  on: boolean | undefined
  ttl?: Date
}

type SplitFeatureState = Partial<Record<FeatureFlag, FeatureFlagState>>
interface UpdateSplitFn {
  (feature: FeatureFlag, isOn: boolean): Date
}

export let splitLib: SplitWrapper
export const splits: SplitFeatureState = {}

export const updateSplit: UpdateSplitFn = (feature, on) => {
  const ttl = new Date(Date.now() + 60 * 60 * 1000)
  splits[feature] = { on, ttl }
  return ttl
}

interface ISplitContext {
  userClientReady: boolean
  anonymousClientReady: boolean
}

const defaultSplitContext: ISplitContext = {
  userClientReady: false,
  anonymousClientReady: false,
}

interface Props {
  analytics?: GenericAnalyticsForSplit
  analyticsReady: boolean
  anonymousId?: string
  onAnonymousClientReady?: () => void | Promise<void>
}

export const SplitContext = createContext(defaultSplitContext)

export const useSplitContext = (): ISplitContext => {
  return useContext(SplitContext)
}

const SplitProvider: React.FC<Props> = ({
  children,
  analytics,
  onAnonymousClientReady,
  analyticsReady,
  anonymousId,
}) => {
  const [userClientReady, setUserClientReady] = useState(false)
  const [anonymousClientReady, setAnonymousClientReady] = useState(false)
  const { authUser } = useAuthUser()
  const [splitLibLoaded, setSplitLibLoaded] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      if (!splitLib)
        splitLib = (await import('@carrotcart/client-common/lib/split')).default
      setSplitLibLoaded(true)
    })()
  }, [])

  useEffect(() => {
    if (authUser?.uid && splitLibLoaded) {
      splitLib.updateUserClient(authUser.uid)
      setUserClientReady(true)
    }
  }, [authUser?.uid, splitLibLoaded])

  useEffect(() => {
    if (analyticsReady && !anonymousClientReady && splitLibLoaded) {
      const initArgs: Parameters<SplitWrapper['init']>[0] = {}

      if (analytics) {
        initArgs.analytics = analytics
      }

      if (anonymousId) {
        initArgs.anonymousId = anonymousId
      }
      splitLib.init(initArgs)
      setAnonymousClientReady(true)
    }
  }, [
    analytics,
    analyticsReady,
    anonymousId,
    anonymousClientReady,
    splitLibLoaded,
  ])

  useEffect(() => {
    if (anonymousClientReady && typeof onAnonymousClientReady === 'function') {
      onAnonymousClientReady()
    }
  }, [anonymousClientReady, onAnonymousClientReady])

  return (
    <SplitContext.Provider
      value={{
        userClientReady,
        anonymousClientReady,
      }}
    >
      {children}
    </SplitContext.Provider>
  )
}

export default SplitProvider
