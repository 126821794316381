import { useAuthState } from 'react-firebase-hooks/auth'
import type { User as AuthUser } from 'firebase/auth'
import { auth } from '@carrotcart/client-common/lib/firebase'

type Response = {
  authUser?: AuthUser | null | undefined
  loading: boolean
  error: Error | undefined
}

const useAuthUser = (): Response => {
  const [authUser, loading, error] = useAuthState(auth)

  return {
    authUser,
    loading,
    error,
  }
}

export default useAuthUser
