export const CARROT_SPLIT_FLAGS_COOKIE_KEY = 'carrot_split_flags'
export const CARROT_SPLIT_DATA_COOKIE_KEY = 'carrot_split_data'
export const IS_PREVIEW_ENV = process.env.VERCEL_ENV === 'preview'
export const IS_ANALYZE_BULD = process.env.ANALYZE === 'true'

export const INTERNAL_EMAILS = [
  // "arnabg@gmail.com",
  // "laurenlootens@gmail.com",
  'akiindo123@gmail.com',
  'carrotbrandtester@gmail.com',
  'gyuhba@gmail.com',
  'r.a.quinones@gmail.com',
  'ricardo.a.quinones@gmail.com',
  'russell.j.barnard@googlemail.com',
  'toudai805@gmail.com',
  'i8ramin@gmail.com',
  'carrot.waitlist@gmail.com',
  'ramin@addtocarrot.com',
  'ricky@addtocarrot.com',
  'rusty@addtocarrot.com',
  'russell@addtocarrot.com',
  'monisha@addtocarrot.com',
  'carrotbrandtester@addtocarrot.com',
  'amir@addtocarrot.com',
]

export const PLACEHOLDER_IMAGE_SRC = '/images/placeholder_image.png'
