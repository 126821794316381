import type { BillingMode } from '@carrotcart/common/types'

type EndpointConfig = {
  path: string
  method: 'get' | 'post' | 'put' | 'patch' | 'delete'
}

/**
 * IMPORTANT: Don't change these in the API layer once these have been deployed.
 * The constants are also used client-side and won't reflect until we release a
 * new version of the client.
 */
export const API_NAMESPACE = '/api'
export const ENDPOINTS = {
  CREATE_CHECKOUT_SESSION: <EndpointConfig>{
    path: '/create-checkout-session',
    method: 'post',
  },
  CREATE_PORTAL_SESSION: <EndpointConfig>{
    path: '/create-portal-session',
    method: 'post',
  },
  ACCOUNT_ONBOARDING: <EndpointConfig>{
    path: '/account-onboarding',
    method: 'post',
  },
  ACCOUNT_PORTAL_SESSION: <EndpointConfig>{
    path: '/account-portal-session',
    method: 'post',
  },
}

export const BILLING_MODE_HEADER = 'x-billing-mode'
export const ALLOWABLE_BILLING_MODES = new Set<BillingMode>(['live', 'test'])
export const DEFAULT_ANONYMOUS_BILLING_MODE: BillingMode = 'live'
