import { setTag } from '@sentry/core'
import { ExtraErrorData } from '@sentry/integrations'

const depth = 10
const SENTRY_DSN = process.env.SENTRY_DSN
const ENVIRONMENT = process.env.APP_ENV || process.env.NODE_ENV || 'production'
const DEVELOPER_EMAIL = process.env.DEVELOPER_EMAIL

if (ENVIRONMENT === 'development' && DEVELOPER_EMAIL) {
  setTag('developer', DEVELOPER_EMAIL)
}

export default {
  dsn: SENTRY_DSN,
  integrations: [new ExtraErrorData({ depth })],
  normalizeDepth: depth + 1,
  environment: ENVIRONMENT,
  maxBreadcrumbs: 10,
}
