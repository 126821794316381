import { User as AuthUser } from 'firebase/auth'
import { UserAuthData } from '@carrotcart/common/types'
import getUserToken from './getUserToken'

export const createUserAuthData = async (
  authUser: AuthUser
): Promise<UserAuthData> => {
  const tokenResult = await getUserToken(authUser)

  return {
    uid: authUser.uid,
    accessToken: tokenResult?.token as string,
    tokenResult,
  }
}
