import type * as Types from '../../data/generated/types'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {}
export type UpdateManyImpersonationSignedOutAtMutationVariables = Types.Exact<{
  updates:
    | Array<Types.User_Impersonation_Updates>
    | Types.User_Impersonation_Updates
}>

export type UpdateManyImpersonationSignedOutAtMutation = {
  __typename?: 'mutation_root'
  update_user_impersonation_many?: Types.Maybe<
    Array<
      Types.Maybe<{
        __typename?: 'user_impersonation_mutation_response'
        affected_rows: number
      }>
    >
  >
}

export const UpdateManyImpersonationSignedOutAtDocument = gql`
  mutation UpdateManyImpersonationSignedOutAt(
    $updates: [user_impersonation_updates!]!
  ) {
    update_user_impersonation_many(updates: $updates) {
      affected_rows
    }
  }
`
export type UpdateManyImpersonationSignedOutAtMutationFn =
  Apollo.MutationFunction<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >

/**
 * __useUpdateManyImpersonationSignedOutAtMutation__
 *
 * To run a mutation, you first call `useUpdateManyImpersonationSignedOutAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyImpersonationSignedOutAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyImpersonationSignedOutAtMutation, { data, loading, error }] = useUpdateManyImpersonationSignedOutAtMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateManyImpersonationSignedOutAtMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >(UpdateManyImpersonationSignedOutAtDocument, options)
}
export type UpdateManyImpersonationSignedOutAtMutationHookResult = ReturnType<
  typeof useUpdateManyImpersonationSignedOutAtMutation
>
export type UpdateManyImpersonationSignedOutAtMutationResult =
  Apollo.MutationResult<UpdateManyImpersonationSignedOutAtMutation>
export type UpdateManyImpersonationSignedOutAtMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateManyImpersonationSignedOutAtMutation,
    UpdateManyImpersonationSignedOutAtMutationVariables
  >
