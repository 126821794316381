export {
  createCustomToken,
  signInWithProvider,
  signInWithToken,
  signInWithEmail,
  signInWithEmailPassword,
  signInWithPhoneConfirmationResult,
  signInWithPhone,
  sendPasswordReset,
  signOut,
  sendSignInLink,
  hasSignedInWithEmailLink,
  SignInMode,
  updateAuthUserDetails,
  CARROT_SIGNIN_EMAIL_KEY,
} from './firebaseAuth'
export { default as auth } from './auth'

export * from '@carrotcart/common/lib/firebaseProviders'

export { createUserAuthData } from './createUserAuthData'
export { default as getUserToken } from './getUserToken'

export type { SupportedProviderId } from './firebaseAuth'
export type { SignInReturnValue } from './firebaseAuth'
