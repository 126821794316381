import * as Sentry from '@sentry/core'
import { CaptureContext } from '@sentry/types'
import type { Severity } from '@sentry/types'
import { Hub as SentryHub } from '@sentry/core'
import type { GenericSentryClient } from '@carrotcart/common/types'

class SentryWrapper {
  static client: GenericSentryClient = Sentry

  static get hub(): GenericSentryClient {
    return this.client
  }

  static setHub(sentryHub: SentryHub): void {
    this.client = sentryHub
    sentryHub.startSession()
  }

  // Used for times when there is ambiguity with what type of Sentry hub is being used
  static send(
    method: 'captureException' | 'captureMessage',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    whatToSend: any,
    captureContext: CaptureContext
  ): void {
    if (this.client instanceof SentryHub) {
      method === 'captureException'
        ? this.client[method](whatToSend, { captureContext })
        : this.client[method](whatToSend, 'error' as Severity.Error, {
            captureContext,
          })
    } else {
      Sentry[method](whatToSend, captureContext)
    }
  }
}

export default SentryWrapper
