import { FeatureFlag } from '@carrotcart/common/lib/split'

export type SplitServerSideData = {
  anonymousUserId?: string
  featureFlags: {
    [key in FeatureFlag]?: string
  }
}

interface RandomUUIDFn {
  (): string
}

const generateBackupServerSideSplitCookie = (
  fn?: RandomUUIDFn
): SplitServerSideData => {
  const data: SplitServerSideData = {
    featureFlags: {},
  }

  if (typeof fn === 'function') {
    data.anonymousUserId = fn()
  }

  return data
}

/**
 * Extracts the data from the split cookie
 *
 * @param existingCookie The existing cookie base64 encoded, json string of split server-side data
 * @param randomUuidFn A random uuid generator function that can be passed in
 * @returns SplitServerSideData
 */
export const extractServerSideSplitCookie = (
  existingCookie: string,
  randomUuidFn?: RandomUUIDFn
): SplitServerSideData => {
  try {
    if (!existingCookie) {
      return generateBackupServerSideSplitCookie(randomUuidFn)
    }
    return JSON.parse(atob(existingCookie))
  } catch (error) {
    // should log an error here. will need to work on edge middleware logging first
    return generateBackupServerSideSplitCookie(randomUuidFn)
  }
}

export const createServerSideSplitCookie = (
  splitData: SplitServerSideData
): string => {
  return btoa(JSON.stringify(splitData))
}
