import { useCallback, useState } from 'react'
import axios from 'axios'
import useLocalStorageState from 'use-local-storage-state'
import { UserCredential } from 'firebase/auth'
import {
  getUserToken,
  signInWithToken,
} from '@carrotcart/client-common/lib/firebase'
import logger from '@carrotcart/client-common/lib/logger/web'
import { useUpdateManyImpersonationSignedOutAtMutation } from '@carrotcart/app/data/updateManyImpersonationSignedOutAt.generated'
import { createApiUrl } from '@carrotcart/app/lib/helpers'
import useAuthUser from '@carrotcart/app/hooks/useAuthUser'

type ResponseData = {
  customToken: string
  impersonation_id: string
}

type StoredData = Record<string, string>

export const PERSISTED_IMPERSONATION_DATA_KEY = '__carrot_impersonation_id'
export const IMPERSONATED_VALUE = 'impersonated'

const useImpersonateUser = (): {
  impersonate: (args: {
    userId: string
    reason: string
  }) => Promise<UserCredential>
  impersonated: boolean
  loading: boolean
  data: ResponseData
  error: Error
  setImpersonationSignedOutAt: (userId: string) => void
  updateImpersonationSignedOuts: () => Promise<void>
} => {
  const { authUser } = useAuthUser()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error>(null)
  const [data, setData] = useState<ResponseData>(null)
  const [
    persistedImpersonationData,
    setPersistedImpersonationData,
    { removeItem },
  ] = useLocalStorageState<StoredData>(PERSISTED_IMPERSONATION_DATA_KEY)

  const impersonated = !!(persistedImpersonationData || {})[
    authUser?.uid
  ]?.indexOf(IMPERSONATED_VALUE)

  const [updateMany] = useUpdateManyImpersonationSignedOutAtMutation({
    context: {
      role: 'user_admin',
    },
  })

  const impersonate = useCallback(
    async ({ userId, reason }: { userId: string; reason: string }) => {
      try {
        setLoading(true)

        const idTokenResult = await getUserToken(authUser)
        const { data } = await axios.post<ResponseData>(
          createApiUrl(`/api/impersonate/${userId}`),
          { reason },
          {
            headers: {
              Authorization: `Bearer ${idTokenResult.token}`,
            },
          }
        )

        setData(data)
        setPersistedImpersonationData({
          ...(persistedImpersonationData || {}),
          [userId]: `${data.impersonation_id}||${IMPERSONATED_VALUE}`,
        })

        const customToken = data.customToken
        const userCreds = await signInWithToken(customToken)

        return userCreds
      } catch (error) {
        setError(error)
        logger.error({
          message: 'Error impersonating user',
          userId,
          reason,
          'error.message': error?.message,
          'error.code': error?.code,
        })
      } finally {
        setLoading(false)
      }
    },
    [authUser, persistedImpersonationData, setPersistedImpersonationData]
  )

  const setImpersonationSignedOutAt = useCallback(
    (userId: string) => {
      const impersonationId = (persistedImpersonationData || {})[userId]?.split(
        '||'
      )?.[0]

      if (impersonationId) {
        setPersistedImpersonationData({
          ...(persistedImpersonationData || {}),
          [userId]: `${impersonationId}||${new Date().toISOString()}`,
        })
      }
    },
    [persistedImpersonationData, setPersistedImpersonationData]
  )

  const updateImpersonationSignedOuts = useCallback(async () => {
    const persistedTimestamps = Object.values(
      persistedImpersonationData || {}
    ).reduce((acc, val) => {
      const impersonationId = val?.split('||')?.[0]
      const timestamp = val?.split('||')?.[1]
      if (timestamp && !isNaN(Date.parse(timestamp))) {
        acc[impersonationId] = timestamp
      }

      return acc
    }, {})

    if (!Object.entries(persistedTimestamps || {}).length) return

    const resp = await updateMany({
      variables: {
        updates: Object.entries(persistedTimestamps).map(
          ([impersonationId, timestamp]) => ({
            _set: {
              signed_out_at: timestamp,
            },
            where: {
              id: {
                _eq: impersonationId,
              },
            },
          })
        ),
      },
    })

    if (!resp.errors) {
      setData(null)
      removeItem()
    }
  }, [persistedImpersonationData, removeItem, updateMany])

  return {
    impersonate,
    impersonated,
    loading,
    data,
    error,
    setImpersonationSignedOutAt,
    updateImpersonationSignedOuts,
  }
}

export default useImpersonateUser
