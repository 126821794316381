import pino, { Logger, Bindings, LogEvent, BaseLogger, LogFn } from 'pino'
import merge from 'lodash/merge'
import type { CaptureContext } from '@sentry/types'
import { IS_PROD } from '@carrotcart/common/lib/constants'
import Sentry from '@carrotcart/common/lib/sentry'
import { ErrorWithExtras, WrappedError } from '@carrotcart/common/lib/errors'

export const commonConfig: pino.LoggerOptions = {
  level: process.env.LOG_LEVEL || (IS_PROD ? 'info' : 'debug'),
  prettyPrint: !IS_PROD,
  enabled: process.env.TEST !== 'true',
  messageKey: 'message',
  redact: [
    'http.request.headers["x-api-key"]',
    'http.request.headers["authorization"]',
    'http.request.headers["x-anonymous-authorization"]',
    'http.request.headers["messagebird-signature-jwt"]',
    'http.request.headers["messagebird-signature"]',
    'job.data.deepLinks',
    'job.data.updates',
  ],
}

interface BrowserLogger extends BaseLogger {
  _logEvent: LogEvent
}

const getExistingBindings = (logger: Logger | BrowserLogger): pino.Bindings => {
  if (typeof logger.bindings === 'function') return logger.bindings()

  const bindings: Bindings = {}
  const browserLogger = logger as BrowserLogger
  const bindingsArray: Bindings[] = browserLogger._logEvent?.bindings || []
  for (let i = 0; i < bindingsArray.length; i++) {
    const bindingsItem = bindingsArray[i]
    const keys = Object.keys(bindingsItem)
    for (let j = 0; j < keys.length; j++) {
      const key = keys[j]
      bindings[key] = bindingsItem[key]
    }
  }

  return bindings
}

export interface BrowserMixin {
  (): Bindings
}

// This wrapper is to provide the same functionality as pino's mixin function which does not work client-side
const wrapLoggerFn = (
  method: string,
  logger: Logger,
  mixin?: BrowserMixin
): LogFn => {
  const ogFn: LogFn = logger[method]

  return function LOG(this: Logger, message: any, ...args: any[]) {
    const boundLogFn = ogFn.bind(this)
    if (!mixin) return boundLogFn(message)

    const mixinData = mixin()
    if (typeof message === 'string') {
      mixinData.message = message
      return boundLogFn(mixinData, ...args)
    } else {
      return boundLogFn(merge(mixinData, message))
    }
  }
}

interface Args {
  logger: Logger
  mixin?: BrowserMixin
}

export const sentrifiedLogger = ({ logger: log, mixin }: Args): Logger => {
  const originalError = log.error
  const originalChild = log.child

  // Treat the error log as special always since we are also trying to send errors and messages to Sentry
  // as well
  function newError(
    this: Logger,
    msg: string | Record<string, any>,
    ...args: any[]
  ) {
    let mixinData: Bindings = {}
    if (typeof mixin === 'function') mixinData = mixin() || {}
    const boundErrorLog = originalError.bind(this)
    const existingBindings = merge(mixinData, getExistingBindings(this))
    const userId = existingBindings['user.id'] || existingBindings.user?.id
    const sentryFromBindings = existingBindings.sentry
    // Make sure to clean these up when trying to set the existing bindings
    // as Sentry capture context
    delete existingBindings['user.id']
    delete existingBindings.user
    delete existingBindings.sentry

    const captureContext: CaptureContext = {}
    if (sentryFromBindings && sentryFromBindings.level) {
      captureContext.level = sentryFromBindings.level
    }
    if (userId) captureContext.user = { id: userId }
    if (existingBindings) captureContext.extra = existingBindings

    if (typeof msg === 'string') {
      mixinData.message = msg
      if (userId) mixinData['user.id'] = userId
      boundErrorLog(mixinData, ...args)
      Sentry.send('captureMessage', msg, captureContext)
    } else {
      const { message, err, sentry, ...logData } = merge(mixinData, msg)
      if (
        captureContext &&
        captureContext?.extra &&
        captureContext?.extra?.sentry
      ) {
        delete captureContext.extra.sentry
      }
      const passedInUserId = logData['user.id'] || existingBindings.user?.id
      const sentryContext =
        sentry && sentryFromBindings
          ? merge(sentryFromBindings, sentry)
          : sentry
          ? sentry
          : sentryFromBindings

      delete logData['user.id']
      // Add any other keys from the message log to the Sentry extras
      captureContext.extra = merge(captureContext.extra || {}, logData)
      if (sentryContext && sentryContext.level) {
        captureContext.level = sentryContext.level
      }
      let errorWithExtras: ErrorWithExtras | undefined = undefined
      if (err instanceof ErrorWithExtras) {
        errorWithExtras = err
      }
      // Add error extras to both the error and log context
      if (errorWithExtras?.extras) {
        logData['error.extras'] = errorWithExtras.extras
        captureContext.extra = merge(
          captureContext.extra || {},
          errorWithExtras.extras
        )
      }

      // Always prefer the passed in user id over the one set automatically either via bindings or via the mixin
      const logUserId = passedInUserId || userId
      if (logUserId) {
        if (captureContext.user) {
          captureContext.user.id = logUserId
        } else {
          captureContext.user = { id: userId }
        }
      }

      // Add back the extracted message, err keys, and determined user id to the original log
      if (message) logData.message = message
      if (err) logData.err = err
      if (logUserId) logData['user.id'] = logUserId

      boundErrorLog(logData, ...args)
      const method =
        err instanceof Error ? 'captureException' : 'captureMessage'
      const whatToSend =
        message && err instanceof Error
          ? new WrappedError(message, err)
          : err || message

      Sentry.send(method, whatToSend, captureContext)
    }
  }

  let newFatal: LogFn
  let newWarn: LogFn
  let newInfo: LogFn
  let newDebug: LogFn
  let newTrace: LogFn

  // If there is a browser mixin passed in, we need to monkey patch the methods to be able to pass in
  // the mixin data
  if (typeof mixin === 'function') {
    newFatal = wrapLoggerFn('fatal', log, mixin)
    newWarn = wrapLoggerFn('warn', log, mixin)
    newInfo = wrapLoggerFn('info', log, mixin)
    newDebug = wrapLoggerFn('debug', log, mixin)
    newTrace = wrapLoggerFn('trace', log, mixin)

    log.fatal = newFatal
    log.warn = newWarn
    log.info = newInfo
    log.debug = newDebug
    log.trace = newTrace
  }

  function newChild(this: Logger, bindings: Bindings): Logger {
    const newLogger = originalChild.bind(this)(bindings)
    newLogger.error = newError.bind(newLogger)
    newLogger.child = newChild.bind(newLogger)

    // Check if we need to setup the new bound logger methods as well.
    // Important for child loggers especially.
    if (newFatal) newLogger.fatal = newFatal.bind(newLogger)
    if (newWarn) newLogger.warn = newWarn.bind(newLogger)
    if (newInfo) newLogger.info = newInfo.bind(newLogger)
    if (newDebug) newLogger.debug = newDebug.bind(newLogger)
    if (newTrace) newLogger.trace = newTrace.bind(newLogger)

    return newLogger
  }

  // Bind the newly monkey patched functions
  log.error = newError.bind(log)
  log.child = newChild.bind(log)

  return log
}

export const setUserContext = (
  userId: string | null | undefined,
  parentLogger: Logger
): Logger => {
  if (userId) {
    return parentLogger.child({ 'user.id': userId })
  }

  return parentLogger
}
