import { AuthProvider } from 'firebase/auth'
export { GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth'

/*
AnonymousProvider used to match the AuthProvider interface
from Firebase for the anonymous login option
*/
export class AnonymousProvider implements AuthProvider {
  static readonly PROVIDER_ID = 'anonymous'
  providerId = AnonymousProvider.PROVIDER_ID
}

/* AppleProvider used currently to detect when the user
has an existing Apple login linked to their Firebase account */
export class AppleProvider implements AuthProvider {
  static readonly PROVIDER_ID = 'apple.com'
  providerId = AppleProvider.PROVIDER_ID
}
