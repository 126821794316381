import { Severity } from '@sentry/types'
import { LogEvent } from 'pino'
import axios from 'axios'
import auth from '@carrotcart/client-common/lib/firebase/auth'
import { getUserToken } from '@carrotcart/client-common/lib/firebase'
import Sentry from '@carrotcart/common/lib/sentry'
import createLogEventPayload from './createLogEventPayload'
import { buildSignature } from './hmac'
import { TransmitFn } from './types'

const LOGGING_TRANSPORT_ENDPOINT =
  process.env.LOGGING_TRANSPORT_ENDPOINT ||
  'https://logs.crrt.dev/client-json-logs'

let HMAC_SECRET: string

const createPayload = (logEvents: LogEvent[]): string => {
  return logEvents
    .reduce(
      (memo, logEvent) =>
        `${memo}\n${JSON.stringify(createLogEventPayload(logEvent))}`,
      ''
    )
    .trim()
}

const sendToLoggingEndpoint: TransmitFn = (signFn) => async (logEvents) => {
  try {
    const headers: Record<string, string> = {
      'Content-Type': 'text/plain',
    }
    const payload = createPayload(logEvents)
    // Conditionally add the auth token to the request
    const authUser = auth.currentUser
    if (authUser) {
      const tokenResult = await getUserToken(authUser)
      if (tokenResult) {
        headers.Authorization = `Bearer ${tokenResult.token}`

        if (HMAC_SECRET) {
          const timestamp = Date.now()
          headers['x-hmac-timestamp'] = `${timestamp}`
          headers['x-hmac-signature'] = await signFn(
            buildSignature({
              userId: authUser.uid,
              jwtIssuanceTime: tokenResult.issuedAtTime,
              url: LOGGING_TRANSPORT_ENDPOINT,
              method: 'POST',
              payload,
              timestamp,
            }),
            HMAC_SECRET
          )
        }
      }
    }

    const res = await axios.post(
      LOGGING_TRANSPORT_ENDPOINT as string,
      payload,
      {
        headers,
        validateStatus: (status) => status === 200,
        timeout: 5000,
      }
    )

    // The HMAC secret comes back in the response for authenticated requests. We allow a certain
    // amount of unauthenticated, unsigned requests, but will eventually rate limit to ensure that
    // no malicious users are trying to f*** with our logs. We still want to be able to collect logs
    // from unauthenticated clients as some experiences within our web app our unauthenticated.
    HMAC_SECRET = res.headers['x-hmac-secret']

    return undefined
  } catch (err) {
    Sentry.hub.addBreadcrumb({
      category: 'json_log',
      message: '',
      level: 'error' as Severity.Error,
      data: { err },
    })
    return err as Error
  }
}

export default sendToLoggingEndpoint
