import { GraphQLError as OgGraphQLError } from 'graphql'

type Extras = Record<string, any>
export class ErrorWithExtras extends Error {
  private _extras: Extras

  constructor(message: string, extras?: Record<string, any>) {
    super(message)
    this._extras = extras || {}
  }

  public get extras(): Record<string, any> {
    return this._extras
  }
}

export class WrappedError extends ErrorWithExtras {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  constructor(
    messageWrap: string,
    err: Error | null | undefined,
    options: { name?: string; additionalMessage?: string; extras?: Extras } = {}
  ) {
    let errMessage = `[${messageWrap}]`
    if (err?.message) {
      errMessage = `[${messageWrap}] ${err.message}`
    }
    if (options.additionalMessage) {
      errMessage = `${errMessage}${err?.message ? ':' : ''} ${
        options.additionalMessage
      }`
    }

    const extras = options.extras
      ? { ...options.extras, 'context.original_error': err }
      : { 'context.original_error': err }

    super(errMessage, extras)
    this.name = err?.name || options?.name || 'Error'
    this.message = errMessage

    const message_lines = (this.message.match(/\n/g) || []).length + 1
    this.stack = (this.stack || '')
      .split('\n')
      .slice(0, message_lines + 1)
      .join('\n')

    if (err?.stack) {
      this.stack = this.stack + '\n' + err.stack
    }
  }
}

export class TimeoutError extends ErrorWithExtras {
  constructor(timeout: number, extras?: Extras) {
    super(`Request timed out after ${timeout} milliseconds`, extras)
  }
}

export class TooManyRedirects extends ErrorWithExtras {
  constructor(maxRedirects: number, extras?: Extras) {
    super(
      `The number of redirects exceeded the maximum allowable amount of ${maxRedirects}`,
      extras
    )
  }
}

export class GraphQLError extends ErrorWithExtras {
  constructor(message: string, gqlErrors: readonly OgGraphQLError[]) {
    super(message, { 'context.graphql.errors': gqlErrors })
  }
}
